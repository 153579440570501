import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import {Button, Col, Form, Input, message, Radio, Row, Space, Table, Typography} from 'antd'
import styles from './index.module.less'
import Modal from 'antd/lib/modal/Modal'
import {contractModel} from '@/store/models/Contract'
import {rules} from '@/utils/validate'
import {Permission} from '@/ui/components/Permission'

export default function BlackList() {
  const [visible, setVisible] = useState(false)
  const [deleteVisible, setDeleteVisible] = useState(false)
  const [addForm] = Form.useForm()
  const [form] = Form.useForm()
  const addFormRef = useRef(null)
  const formRef = useRef(null)
  const [blackList, setBlackList] = useState([] as ContractBlcaklistVO[])
  const [total, setTotal] = useState(0)
  const [params, setParams] = useState({pageNum: 1, pageSize: 10} as ContractBlacklistQueryDTO)
  const loading = contractModel.contractBlacklistList.useLoading()
  const addloading = contractModel.contractBlacklistAdd.useLoading()
  const deleteLoading = contractModel.contractBlacklistDelete.useLoading()
  const [blackId, setBlackId] = useState('')

  function onFinish() {
    const initParams = addForm.getFieldsValue()
    contractModel.contractBlacklistAdd(initParams).then(() => {
      message.success('添加成功！')
      setVisible(false)
      addFormRef.current.resetFields()
      queryList(params)
    })
  }

  function search() {
    const formParams = form.getFieldsValue()
    queryList({...params, ...formParams})
  }

  const queryList = useCallback(condo => {
    contractModel.contractBlacklistList(condo).then(({response: {data, total}}) => {
      setBlackList(data)
      setTotal(total)
    })
  }, [])

  useEffect(() => {
    queryList(params)
  }, [params, queryList])

  //移除黑名单
  function deleteBlack(id) {
    contractModel.contractBlacklistDelete(id).then(() => {
      queryList(params)
      setDeleteVisible(false)
    })
  }

  return (
    <div>
      <Breadcrumb
        path={['首页', '设置', '添加黑名单']}
        right={
          <Permission code={'007004002'}>
            <Button
              type={'primary'}
              onClick={() => {
                setVisible(true)
              }}
            >
              添加黑名单
            </Button>
          </Permission>
        }
      />
      <Form className={styles.form} form={form} ref={formRef} onFinish={search}>
        <Row gutter={[114, 0]}>
          <Col>
            <Form.Item label={'姓名'} name={'name'}>
              <Input placeholder={'请输入用户姓名'} style={{minWidth: '210px'}} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label={'手机号'} name={'mobile'}>
              <Input placeholder={'请输入用户手机号'} style={{minWidth: '210px'}} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label={'证件号码'} name={'certNo'}>
              <Input placeholder={'请输入用户证件号码'} style={{minWidth: '210px'}} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify={'end'}>
          <Space size={12}>
            <Button type={'primary'} htmlType={'submit'}>
              查询
            </Button>
            <Button
              onClick={() => {
                formRef.current.resetFields()
                queryList(params)
              }}
            >
              重置
            </Button>
          </Space>
        </Row>
      </Form>
      <div className={styles.table}>
        <Table
          rowKey={'blacklistId'}
          loading={loading}
          columns={[
            {title: '用户姓名', key: 'name', dataIndex: 'name', width: 110},
            {
              title: '性别',
              key: 'gender',
              dataIndex: 'gender',
              width: 110,
              render: text => <Typography.Text>{text === '1' ? '男' : '女'}</Typography.Text>,
            },
            {title: '手机号码', key: 'mobile', dataIndex: 'mobile', width: 140},
            {title: '添加时间', key: 'insertTime', dataIndex: 'insertTime', width: 200},
            {title: '证件号码', key: 'certNo', dataIndex: 'certNo', width: 200},
            {title: '备注', key: 'remark', dataIndex: 'remark'},
            {
              title: '操作',
              key: 'operation',
              dataIndex: 'operation',
              render: (text, record, index) => (
                <Permission code={'007004003'}>
                  <Button
                    type={'link'}
                    onClick={() => {
                      setBlackId(record.blacklistId)
                      setDeleteVisible(true)
                    }}
                    style={{padding: 0}}
                  >
                    移除黑名单
                  </Button>
                </Permission>
              ),
            },
          ]}
          dataSource={blackList}
          pagination={{
            total,
            current: params.pageNum,
            pageSize: params.pageSize,
            defaultCurrent: 1,
            defaultPageSize: 10,
            position: ['bottomCenter'],
            onChange: (page, pageSize) => {
              setParams(state => ({...state, pageNum: page, pageSize}))
            },
            onShowSizeChange: (current, pageSize) => {
              setParams(state => ({...state, pageNum: current, pageSize}))
            },
          }}
        />

        {/* 添加黑名单 */}
        <Modal
          visible={visible}
          title={'添加黑名单'}
          width={445}
          onCancel={() => setVisible(false)}
          footer={null}
          centered
        >
          <Form form={addForm} ref={addFormRef} labelAlign={'right'} labelCol={{span: 6}} onFinish={onFinish}>
            <div className={styles.content}>
              <Form.Item label={'姓名'} name={'name'} rules={[{required: true, message: '请填写姓名'}]}>
                <Input placeholder={'请输入姓名'} />
              </Form.Item>
              <Form.Item label={'性别'} name={'gender'} rules={[{required: true, message: '请选择性别'}]}>
                <Radio.Group>
                  <Radio value={'1'}>男</Radio>
                  <Radio value={'2'}>女</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item label={'手机号码'} name={'mobile'} rules={rules.mobile}>
                <Input placeholder={'请输入手机号'} maxLength={11} />
              </Form.Item>
              <Form.Item label={'证件号码'} name={'certNo'} rules={rules.certNo}>
                <Input placeholder={'请输入证件号码'} maxLength={18} />
              </Form.Item>
              <Form.Item label={'备注'} name={'remark'}>
                <Input.TextArea placeholder={'请输入添加黑名单原因，以便以后查看'} rows={4} />
              </Form.Item>
            </div>

            <Row justify={'end'}>
              <Space>
                <Button
                  onClick={() => {
                    addFormRef.current.resetFields()
                    setVisible(false)
                  }}
                  loading={addloading}
                >
                  取消
                </Button>
                <Button htmlType={'submit'} type={'primary'}>
                  确定
                </Button>
              </Space>
            </Row>
          </Form>
        </Modal>

        <Modal visible={deleteVisible} title={'提示'} onCancel={() => setDeleteVisible(false)} footer={null} centered>
          <div>
            <Typography.Text>是否确认移除黑名单？</Typography.Text>
            <div style={{marginTop: '50px', textAlign: 'right'}}>
              <Space>
                <Button onClick={() => setDeleteVisible(false)}>取消</Button>
                <Button type={'primary'} loading={deleteLoading} onClick={() => deleteBlack(blackId)}>
                  确认
                </Button>
              </Space>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  )
}
