import React, {useCallback, useEffect, useMemo, useState} from 'react'
import styles from './index.module.less'
import {Input, Steps, Typography, AutoComplete, Popover, Button, Modal, Space, Select} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import {propertyModel} from '@/store/models/Property'
import {Link} from 'react-router-dom'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import {InfoCircleOutlined, SearchOutlined} from '@ant-design/icons'
import {userModel} from '@/store/models/User'
import {makeReady} from '@/utils/zhichi'
// import {zhiManager} from '@/utils/zhichi'

/** 搜索结果字符串高亮匹配 */
const highString = (param: string, keyword: string): string => {
  if (param && keyword) {
    const indexOf = param.indexOf(keyword)
    if (indexOf > -1) {
      const before = param.slice(0, indexOf)
      const after = param.slice(indexOf + keyword.length, param.length)
      return before + `,${keyword},` + highString(after, keyword)
    }
    return param
  }
  return ''
}

const placeholderType = {
  1: '请输入小区名称或地址',
  2: '请输入小区名称',
  3: '请输入小区相关地址',
}

export default function SelectCommunity() {
  const [current, setCurrent] = useState(0)
  const [list, setList] = useState<CommunityVO[]>([])
  const [sectionList, setSectionList] = useState<CommunityVO[]>([])
  const [placeholder, setPlaceholder] = useState('请输入小区名称或地址')
  const [cityList, setCityList] = useState([])
  const [params, setParams] = useState({cityCode: '310100', searchType: 1, keyword: ''} as SysCommunitySearchDTO)

  const search = useCallback((args: SysCommunitySearchDTO) => {
    propertyModel.communitySearch(args).then(({response: {data}}) => {
      setSectionList(data)
    })
  }, [])

  useEffect(() => {
    propertyModel.queryCityCode({type: '2'}).then(({response: {data}}) => {
      setCityList(data)
    })
  }, [])

  const loading = propertyModel.communitySearch.useLoading()

  const renderOptions = useMemo(
    () =>
      sectionList.map(item => {
        const nameHighArr = highString(item.name, params.keyword).split(',')
        const addressHighArr = highString(item.address, params.keyword).split(',')
        return {
          value: item.name,
          className: styles.selectItem,
          label: (
            <Link to={`/add/scatter?code=${item.code}`} className={styles.searchResult}>
              <Space direction={'vertical'} size={0} style={{overflow: 'hidden'}}>
                <Typography.Text ellipsis className={styles.searchTitle}>
                  {nameHighArr.map((item, index) => (
                    <Typography.Text key={index} style={{color: item === params.keyword ? '#017BFF' : '#606266'}}>
                      {item}
                    </Typography.Text>
                  ))}
                </Typography.Text>
                <Typography.Text ellipsis className={styles.searchSubTitle}>
                  {addressHighArr.map((item, index) => (
                    <Typography.Text key={index} style={{color: item === params.keyword ? '#017BFF' : '#606266'}}>
                      {item}
                    </Typography.Text>
                  ))}
                </Typography.Text>
              </Space>
              <Typography.Text>{`${item.cityName}-${item.districtName}`}</Typography.Text>
            </Link>
          ),
        }
      }),
    [params.keyword, sectionList]
  )

  useEffect(function () {
    propertyModel.propertyCommunityList({communityType: '1'}).then(({response: {data}}) => {
      setList(data)
    })
  }, [])

  return (
    <>
      <Breadcrumb path={['首页', '房源房态', '添加房源']} />
      <div className={commonStyles.section}>
        <Steps
          current={current}
          labelPlacement={'vertical'}
          style={{padding: '0 149px'}}
          onChange={current1 => setCurrent(current1)}
        >
          <Steps.Step title='选择小区' disabled />
          <Steps.Step title='房源录入' disabled />
        </Steps>
      </div>
      <div className={commonStyles.flexFullSection}>
        <div className={styles.content}>
          <div className={styles.searchContainer}>
            <Input.Group compact>
              <Select
                value={params.cityCode}
                size={'large'}
                onChange={value => {
                  setParams(state => ({...state, cityCode: value}))
                  if (params.keyword?.length > 1) {
                    search({...params, cityCode: value})
                  }
                }}
                style={{width: '20%'}}
                showSearch
                filterOption={(inputValue, option) => {
                  return option?.children?.indexOf(inputValue) > -1
                }}
              >
                {cityList.map(item => (
                  <Select.Option value={item.code} key={item.code}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
              <Select
                value={params.searchType}
                size={'large'}
                onChange={value => {
                  setParams(state => ({...state, searchType: value}))
                  setPlaceholder(placeholderType[value])
                  if (params.keyword?.length > 1) {
                    search({...params, searchType: value})
                  }
                }}
                style={{width: '20%'}}
              >
                <Select.Option value={1}>全部</Select.Option>
                <Select.Option value={2}>小区</Select.Option>
                <Select.Option value={3}>地址</Select.Option>
              </Select>
              <AutoComplete style={{width: '60%'}} options={renderOptions}>
                <Input
                  onChange={({target: {value}}) => {
                    setParams(state => ({...state, keyword: value}))
                    if (value.length > 1) {
                      search({...params, keyword: value})
                    } else {
                      setSectionList([])
                    }
                  }}
                  allowClear
                  size={'large'}
                  placeholder={placeholder}
                  suffix={<SearchOutlined style={{color: 'rgba(0, 0, 0, 0.25)'}} />}
                />
              </AutoComplete>
            </Input.Group>

            <Popover
              trigger={'click'}
              onVisibleChange={visible => visible && makeReady()}
              content={
                <span>
                  请联系
                  <span
                    className={styles.searchLink}
                    onClick={async () => {
                      const {
                        response: {data},
                      } = await userModel.lessorQueryManage()
                      if (data?.[0]) {
                        Modal.info({
                          icon: null,
                          title: '如有问题请咨询客户经理',
                          content: `${data[0].name.substring(0, 1)}经理-${data[0].mobile}`,
                        })
                      }
                    }}
                  >
                    客户经理
                  </span>
                  或<span className={`${styles.searchLink} zhiCustomBtn`}>在线客服</span>
                </span>
              }
            >
              <Button className={styles.searchTip} type={'link'} icon={<InfoCircleOutlined />}>
                找不到小区？
              </Button>
            </Popover>
          </div>
          {params.keyword?.length < 2 && (
            <div className={styles.list}>
              <Typography.Title level={4} className={styles.listTitle}>
                录入过的小区
              </Typography.Title>
              {list.map(item => (
                <Link key={item.code} className={styles.listItem} to={`/add/scatter?code=${item.code}`}>
                  {item.name}
                </Link>
              ))}
            </div>
          )}
          {params.keyword?.length > 1 && !loading && !sectionList.length && (
            <div style={{marginLeft: '20%', marginTop: 20}}>
              <Typography.Text style={{fontSize: 15}}>
                试试切换搜索
                {params.searchType !== 2 && (
                  <Typography.Text
                    style={{color: '#017BFF'}}
                    onClick={() => {
                      setParams(state => ({...state, searchType: 2}))
                      setPlaceholder(placeholderType[2])
                      if (params.keyword?.length > 1) {
                        search({...params, searchType: 2})
                      }
                    }}
                  >
                    {' 小区'}
                  </Typography.Text>
                )}
                {params.searchType === 1 && <Typography.Text> 或 </Typography.Text>}
                {params.searchType !== 3 && (
                  <Typography.Text
                    style={{color: '#017BFF'}}
                    onClick={() => {
                      setParams(state => ({...state, searchType: 3}))
                      setPlaceholder(placeholderType[3])
                      if (params.keyword?.length > 1) {
                        search({...params, searchType: 3})
                      }
                    }}
                  >
                    {' 地址'}
                  </Typography.Text>
                )}
              </Typography.Text>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
