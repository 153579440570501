import React, {useEffect, useMemo, useState} from 'react'
// import {CaretRightOutlined} from '@ant-design/icons'
import {Input, List, Tree} from 'antd'
import {EventDataNode} from 'rc-tree/lib/interface'
import {propertyModel} from '@/store/models/Property'
import {userModel} from '@/store/models/User'
import styles from './index.module.less'

type TeamCommunityVO = {teamId: string; communityType: string; code: string; name: string}
type CustomCommunityVO = CommunityVO & {team: TeamTreeNodeVO}
type TreeDataMore = CommunityVO | TeamTreeNodeVO

export default function MeterTeamCommunity(props: {onChange: (args: TeamCommunityVO) => void; isBill?: boolean}) {
  const {isBill} = props

  // 所有组织列表
  const teamList = userModel.useData(data => data.teamList)
  // 当前组织/团队
  const currentTeam = userModel.useData(data => data.currentTeam)

  const underList = useMemo(() => {
    if (currentTeam.level === 1) {
      // 用户位于组织下，筛选出当前组织下的所有团队
      return teamList.filter(item => item.teamPid === currentTeam.teamPid && item.level === 2)
    }
    // 用户位于团队下，查询这个团队下的小区
    return [currentTeam]
  }, [currentTeam, teamList])

  // const [communityObj, setCommunityObj] = useState<{[key: string]: CommunityVO[]}>({})
  // const [current, setCurrent] = useState({} as TeamCommunityVO)
  const [configObj, setConfigObj] = useState<{[key: string]: boolean}>({})

  const [treeData, setTreeData] = useState([])

  // 当前组织或团队 所有的小区列表
  const [dataList, setDataList] = useState<CustomCommunityVO[]>([])
  // 搜索框查出来的小区
  const [resList, setResList] = useState<CustomCommunityVO[]>([])

  useEffect(
    function () {
      Promise.all(underList.map(item => propertyModel.propertyCommunityList({teamId: item.teamId}))).then(resList => {
        // const obj = {}
        resList.forEach((element, index) => {
          const {
            response: {data},
          } = element
          // 把团队和小区组合起来：既可以给整个团队配置单价，也可以给小区配置单价
          // obj[underList[index].teamId] = data

          // 搜索的时候用到的列表
          setDataList(prevState => [...prevState, ...data.map(item => ({...item, team: underList[index]}))])
        })
        // setCommunityObj(obj)

        // 待生成账单需要过滤出有账单的小区
        if (isBill) {
          // 待生成账单-小区数据统计
          propertyModel.propertyMeterRecordCommunityCount().then(({response: {data}}) => {
            if (data) {
              // keys 是小区code列表
              const keys = Object.keys(data)
              // treeData
              const treeList = []
              resList.forEach((element, index) => {
                const {
                  response: {data},
                } = element
                const team = underList[index]
                // 过滤出有账单的小区
                const filterList = data.filter(elem => keys.includes(elem.code))
                if (filterList.length) {
                  treeList.push({
                    title: team.name,
                    key: team.teamId,
                    more: team,
                    children: filterList.map(ele => ({title: ele.name, key: `${team.teamId}-${ele.code}`, more: ele})),
                  })
                }
              })
              setTreeData(treeList)
            }
          })
        } else {
          // treeData
          const treeList = []
          resList.forEach((element, index) => {
            const {
              response: {data},
            } = element
            const team = underList[index]
            treeList.push({
              title: team.name,
              key: team.teamId,
              more: team,
              children: data.map(ele => ({title: ele.name, key: `${team.teamId}-${ele.code}`, more: ele})),
            })
          })
          setTreeData(treeList)
        }
      })

      // 获取抄表配置 (已配置/未配置)
      Promise.all(underList.map(item => propertyModel.propertyMeterGetConfig({teamId: item.teamId}))).then(resList => {
        const obj = {}
        resList.forEach((element, index) => {
          const {
            response: {data},
          } = element
          obj[underList[index].teamId] = !!data?.configId
        })
        setConfigObj(obj)
      })
    },
    [isBill, underList]
  )

  return (
    <>
      <div style={{margin: '0 20px 10px'}}>
        <Input
          placeholder={'请输入小区名'}
          onChange={({target: {value}}) => {
            if (value) {
              const reg = new RegExp(value)
              setResList(dataList.filter(item => item.name.match(reg)))
            } else setResList([])
          }}
          allowClear
        />
      </div>
      {resList.length ? (
        <div className={styles.listView}>
          <List
            split={false}
            itemLayout='horizontal'
            dataSource={resList}
            renderItem={item => (
              <List.Item
                onClick={() => {
                  props.onChange?.({
                    teamId: item.team?.teamId,
                    code: item.code,
                    communityType: item.communityType,
                    name: item.name,
                  })
                }}
                style={{cursor: 'pointer'}}
              >
                <List.Item.Meta
                  title={<span className={styles.listTitle}>{`${item.districtName}-${item.name}`}</span>}
                  description={
                    <div className={styles.descView}>
                      <span className={styles.descTitle}>{item.team?.name}</span>
                      <span className={styles.descExtra}>{configObj[item.team?.teamId] ? '已配置' : '未配置'}</span>
                    </div>
                  }
                />
              </List.Item>
            )}
          />
        </div>
      ) : (
        <Tree
          defaultExpandAll
          defaultExpandParent
          blockNode
          treeData={treeData}
          onSelect={(selectedKeys, info) => {
            const {selected, node} = info
            if (selected) {
              const {more} = node as EventDataNode & {more: TreeDataMore}
              if ('teamId' in more) {
                props?.onChange({teamId: more.teamId, name: more.name, code: '', communityType: ''})
              } else {
                const [key] = selectedKeys
                const res = (key as string)?.split('-')
                props?.onChange({teamId: res?.[0], name: more.name, code: more.code, communityType: more.communityType})
              }
            }
          }}
          className={styles.tree}
        />
        // <Collapse
        //   accordion
        //   bordered={false}
        //   className={'meterCollapseHeader'}
        //   expandIcon={({isActive}) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        //   onChange={key => {
        //     const name = underList.find(item => item.teamId === key)?.name
        //     const obj = {teamId: key as string, code: '', communityType: '', name}
        //     setCurrent(obj)
        //     props.onChange?.(obj)
        //   }}
        // >
        //   {underList.map(item => (
        //     <Collapse.Panel
        //       key={item.teamId}
        //       header={<span>{item.name}</span>}
        //       className={item.teamId === current.teamId && !current.code ? 'activeCollapse' : ''}
        //       extra={<span style={{color: '#017BFF'}}>{configObj[item.teamId] ? '已配置' : '未配置'}</span>}
        //     >
        //       <List
        //         split={false}
        //         dataSource={communityObj[item.teamId]}
        //         renderItem={elem => (
        //           <List.Item
        //             className={elem.code === current.code ? 'activeItem' : ''}
        //             onClick={() => {
        //               const obj = {code: elem.code, communityType: elem.communityType, name: elem.name}
        //               setCurrent(prevState => ({...prevState, ...obj}))
        //               props.onChange?.({...current, ...obj})
        //             }}
        //           >
        //             <div style={{marginLeft: '40px'}}>{`${elem.districtName}-${elem.name}`}</div>
        //           </List.Item>
        //         )}
        //       />
        //     </Collapse.Panel>
        //   ))}
        // </Collapse>
      )}
    </>
  )
}
