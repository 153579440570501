import React, {useCallback, useEffect, useMemo, useState} from 'react'
import commonStyle from '@/assets/styles/common.module.less'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import {Button, Col, Form, Input, Modal, Row, Select, Space, Table, Typography} from 'antd'
import styles from './index.module.less'
import {tradeModel} from '@/store/models/Trade'
import {propertyModel} from '@/store/models/Property'
import moment from 'moment'
import {downloadBlob} from '@/utils/util'
import {usePermission} from '@/ui/components/Permission'

const {Option} = Select
export default function VacancyCostReport() {
  const [auth] = usePermission()
  const [emptyList, setEmptyList] = useState<PropertyUnrentLostVO[]>([])
  const [form] = Form.useForm()
  const [params, setParams] = useState({pageNum: 1, pageSize: 10} as PropertyUnrentLostListDTO)
  const [total, setTotal] = useState(0)
  const [lostStatistics, setLostStatistics] = useState({} as PropertyUnrentLostVO)
  //查看小区
  const [communityList, setCommunityList] = useState<CommunityVO[]>([])
  const loading = tradeModel.propertyUnrentLostList.useLoading()
  const [visible, setVisible] = useState(false)

  const queryList = useCallback((args: PropertyUnrentLostListDTO) => {
    tradeModel.propertyUnrentLostList(args).then(({response: {data, total}}) => {
      setEmptyList(data)
      setTotal(total)
    })
  }, [])

  useEffect(() => {
    queryList(params)

    tradeModel.propertyUnrentLostStatistics(params).then(({response: {data}}) => {
      setLostStatistics(data)
    })
  }, [params, queryList])

  useEffect(() => {
    propertyModel.propertyCommunityList({}).then(({response: {data}}) => {
      setCommunityList(data)
    })
  }, [])

  const explainList = useMemo(
    () => [
      {title: '当前空置天数', content: '当前空置累计的天数'},
      {title: '累计空置天数', content: '房源自录入起空置的总天数'},
      {title: '当前月租金', content: '房源信息中的租金金额'},
      {title: '每日租金', content: '每日租金≈当前房源租金 / 30天'},
      {title: '损失金额', content: '损失金额≈每天租金*当前空置天数'},
      {title: '累计损失金额', content: '累计损失金额≈每天租金*累计空置天数'},
    ],
    []
  )

  return (
    <>
      <Breadcrumb
        path={['首页', '财务管理', '空置成本报表']}
        right={
          auth('004006002') && (
            <Button onClick={() => downloadBlob({url: '/property/unrent-lost/list-excel', body: params})}>导出</Button>
          )
        }
      />
      <div className={commonStyle.section}>
        <Form
          form={form}
          onFinish={() => {
            const formValues = form.getFieldsValue(true) as PropertyUnrentLostListDTO
            setParams(state => ({...state, ...formValues, pageNum: 1}))
          }}
        >
          <Row>
            <Col>
              <Form.Item label={'小区'} name={'communityCode'}>
                <Select placeholder={'小区'} style={{width: 150}}>
                  {communityList.map(item => (
                    <Option value={item.code} key={item.code}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col offset={1}>
              <Form.Item label={'房源地址'} name={'title'}>
                <Input placeholder={'请输入房源地址'} />
              </Form.Item>
            </Col>
            <Col offset={1}>
              <Form.Item label={'当前空置天数'} style={{marginBottom: 0}}>
                <Input.Group compact>
                  <Form.Item noStyle name={'unrentDayNumMin'}>
                    <Input style={{width: 100, textAlign: 'center'}} placeholder='最小天数' />
                  </Form.Item>
                  <Input className={styles.split} placeholder='→' disabled />
                  <Form.Item noStyle name={'unrentDayNumMax'}>
                    <Input className={styles.right} style={{width: 100, textAlign: 'center'}} placeholder='最大天数' />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'}>
                查询
              </Button>
              <Button
                onClick={() => {
                  form.resetFields()
                  setParams({pageNum: 1, pageSize: 10} as PropertyUnrentLostListDTO)
                }}
              >
                重置
              </Button>
            </Space>
          </Row>
        </Form>
      </div>
      <div className={commonStyle.section}>
        <Table
          className={styles.table}
          rowKey={'propertyCode'}
          loading={loading}
          dataSource={emptyList}
          columns={[
            {title: '房源地址', dataIndex: 'title', key: 'title'},
            {
              title: '房源录入时间',
              dataIndex: 'insertTime',
              key: 'insertTime',
              render: text => moment(text).format('YYYY-MM-DD'),
            },
            {title: '当前空置天数', dataIndex: 'unrentDayNum', key: 'unrentDayNum'},
            {title: '累计空置天数', dataIndex: 'unrentDayTotalNum', key: 'unrentDayTotalNum'},
            {title: '当前月租金', dataIndex: 'rentPrice', key: 'rentPrice'},
            {title: '每日平均租金', dataIndex: 'rentDailyPrice', key: 'rentDailyPrice'},
            {title: '损失金额', dataIndex: 'lostMoney', key: 'lostMoney'},
            {title: '累计损失金额', dataIndex: 'lostTotalMoney', key: 'lostTotalMoney'},
          ]}
          title={() => (
            <Space size={17}>
              <Typography.Text style={{color: '#000000', fontWeight: 500}}>查询结果</Typography.Text>
              <Typography.Text>
                房间数：<Typography.Text style={{color: '#007BFF'}}>{total}</Typography.Text>间
              </Typography.Text>
              <Typography.Text>
                空置总天数：
                <Typography.Text style={{color: '#007BFF'}}>{lostStatistics?.unrentDayNum || 0}</Typography.Text>天
              </Typography.Text>
              <Typography.Text>
                房源损失总金额约：
                <Typography.Text style={{color: '#007BFF'}}>{lostStatistics?.lostMoney || 0}</Typography.Text>元
              </Typography.Text>
              <Typography.Link style={{color: '#007BFF'}} underline onClick={() => setVisible(true)}>
                查看详情
              </Typography.Link>
            </Space>
          )}
          pagination={{
            total,
            current: params.pageNum,
            pageSize: params.pageSize,
            showSizeChanger: false,
            position: ['bottomCenter'],
            onChange: (pageNum, pageSize) => setParams(state => ({...state, pageNum, pageSize})),
          }}
        />
      </div>

      <Modal centered width={643} title={'计算规则'} visible={visible} footer={null} onCancel={() => setVisible(false)}>
        <div>
          <Space direction={'vertical'}>
            <Typography.Text style={{color: '#8C8C8C'}}>
              空置天数算法：<Typography.Text style={{color: ''}}>统计该房源无租约订单时的天数</Typography.Text>
            </Typography.Text>
            <Typography.Text style={{color: '#8C8C8C'}}>
              累计/空置/损失金额：<Typography.Text style={{color: ''}}>统计日期从2021年6月5日开始</Typography.Text>
            </Typography.Text>
          </Space>
          <div style={{marginTop: 15}}>
            <Typography.Text style={{fontWeight: 600}}>各数据仅供参考，计算方法如下：</Typography.Text>
          </div>
          <div
            style={{
              marginTop: 20,
            }}
          >
            {explainList.map((item, index) => (
              <div
                key={index}
                style={{
                  height: 60,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  borderBottom: '1px solid rgba(0, 0, 0, 0.09)',
                }}
              >
                <div style={{flex: 1, paddingLeft: 16}}>
                  <Typography.Text>{item.title}</Typography.Text>
                </div>
                <div style={{flex: 1, paddingLeft: 16}}>
                  <Typography.Text>{item.content}</Typography.Text>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </>
  )
}
