import React from 'react'
import styles from './index.module.less'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import {configModel} from '@/store/models/Config'
import {message, Switch} from 'antd'

export default function OtherSettings() {
  const settingList = configModel.useData(data => data.settingList)

  async function otherSettingSet({key, checked}: {key: string; checked: boolean}) {
    await configModel.lessorConfigOtherSettingSet({key, checked})
    message.success('设置成功')
  }

  return (
    <>
      <Breadcrumb path={['首页', '设置', '其它设置']} />
      <div style={{backgroundColor: '#fff'}}>
        <div className={styles.sectionHeader}>
          <span className={styles.sectionTitle}>其它设置</span>
        </div>
        <div className={styles.content}>
          {settingList.map(item => (
            <div key={item.key} style={{marginBottom: '20px'}}>
              <span className={styles.contentText}>{item.label}</span>
              <Switch defaultChecked={item.checked} onChange={checked => otherSettingSet({key: item.key, checked})} />
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
