import React, {useEffect, useState} from 'react'
import {
  Affix,
  Button,
  Col,
  Divider,
  Form,
  Input,
  List,
  message,
  Row,
  Select,
  Space,
  Steps,
  Tabs,
  Typography,
} from 'antd'
import chartStyles from '@/pages/property/Chart/index.module.less'
import commonStyles from '@/assets/styles/common.module.less'
import addCondoStyles from '@/pages/property/AddCondo/index.module.less'
import styles from './index.module.less'
import {rules} from '@/utils/validate'
import Delete from '@/assets/icons/delete.svg'
import {EditOutlined, PlusOutlined} from '@ant-design/icons/lib'
import {propertyModel} from '@/store/models/Property'
import {useHistory} from 'react-router'
import {useQuery} from '@/common/hooks/router'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import {configModel} from '@/store/models/Config'

const {Text} = Typography
const {Option} = Select

export default function AddRoom() {
  const [current, setCurrent] = useState(0)
  const [form] = Form.useForm()
  const [itemForm] = Form.useForm()

  // 公寓楼层列表
  const [originFloorList, setOriginFloorList] = useState([])
  // 模板
  const [templateList, setTemplateList] = useState([])
  // 房间
  const [dataList, setDataList] = useState([])
  // 选中的房间
  const [selectIds, setSelectIds] = useState([])

  const history = useHistory()
  const query = useQuery()
  const condoId = query.get('condoId')
  const buildingNo = query.get('buildingNo')

  // 是否需要显示房源编号
  const isChecked = configModel.useCheckedStatus()

  useEffect(
    function () {
      propertyModel.condoManageInsertPropertyFloorList({condoId}).then(({response: {data}}) => {
        setOriginFloorList(data.filter(value => value.buildingNo === +buildingNo))

        const floorList = data
          .filter(value => value.buildingNo === +buildingNo)
          .map(item => ({buildingNo, floor: item.floor, extNum: 0, propertyDTOS: []}))

        // console.log('data', data)
        // console.log('floorList', floorList)

        form.setFieldsValue({floorList})
      })

      propertyModel.condoManageInsertPropertyTemplateList({condoId}).then(({response: {data}}) => {
        setTemplateList(data)
      })
    },
    [buildingNo, condoId, form]
  )

  function createDTOItem(houseNo, rentStatus = '0') {
    return {houseNo, rentStatus, id: Math.random(), buildingNo}
  }

  // 添加房间
  function onAddRoom() {
    const floorList = form.getFieldValue('floorList')
    const newList = floorList?.map(item => {
      return {
        ...item,
        extNum: item.propertyDTOS.length,
        propertyDTOS: item.propertyDTOS.map((ele, index) => {
          return {
            ...ele,
            ...createDTOItem(
              `${item.floor ? item.floor * 100 + index + 1 : 101 + index}`,
              ele?.rentStatus ? ele.rentStatus : '0'
            ),
          }
        }),
      }
    })
    form.setFieldsValue({floorList: newList})
  }

  // 删除房间
  function onDeleteRoom() {
    const floorList = form.getFieldValue('floorList')
    const newList = floorList?.map(item => {
      return {...item, extNum: item.propertyDTOS.length}
    })
    form.setFieldsValue({floorList: newList})
  }

  // 改变楼层号楼层号
  function onFloorChange(fieldKey) {
    const floorList = form.getFieldValue('floorList')
    const newList = floorList?.map((item, index) =>
      index === fieldKey
        ? {
            ...item,
            propertyDTOS: item.propertyDTOS.map((_item, _index) => ({
              ..._item,
              houseNo: `${item.floor * 100 + _index + 1}`,
            })),
          }
        : item
    )
    form.setFieldsValue({floorList: newList})
  }

  // 输入房间数
  function onExtNumChange(index) {
    const floorList = form.getFieldValue('floorList')
    if (+originFloorList[index]?.propertyNums + +floorList[index].extNum > 200 || +floorList[index].extNum > 200) {
      return message.warning('每层最多添加200间房！')
    }
    const newList = floorList?.map((item, i) => {
      if (i === index) {
        if (+item.extNum > item.propertyDTOS.length) {
          return {
            ...item,
            propertyDTOS: [
              ...item.propertyDTOS,
              ...Array.from({length: +item.extNum - item.propertyDTOS.length}).map((_, _index) =>
                createDTOItem(
                  `${
                    item.floor
                      ? +item.floor * 100 + item.propertyDTOS.length + _index + 1
                      : 101 + item.propertyDTOS.length + _index
                  }`
                )
              ),
            ],
          }
        } else {
          return {...item, propertyDTOS: item.propertyDTOS.filter((_, n) => n < +item.extNum)}
        }
      } else {
        return item
      }
    })
    form.setFieldsValue({floorList: newList})
  }

  // 添加公寓 下一步
  function nextToTemplate() {
    form.validateFields().then(async () => {
      const formValues = form.getFieldsValue(true)
      const {floorList} = formValues
      if (floorList?.every(item => +item.extNum === 0)) return message.warning('请添加房间！')

      // 检查楼层号是否重复
      const floors = floorList?.map(item => item.floor)
      const isRepeat = floors.length !== new Set(floors).size
      if (isRepeat) return message.warning('楼层号重复!')

      await propertyModel.condoInsertStepCheck({
        step: 1,
        condoId,
        propertyDTOS: floorList?.map(item => item.propertyDTOS?.map(ele => ({...ele, floor: item.floor})))?.flat(),
      })

      setCurrent(1)
    })
  }

  // 房间模板 下一步
  function nextToConfiguration() {
    form.validateFields().then(async () => {
      const formValues = form.getFieldsValue(true)
      const {floorList, templateDTOS = []} = formValues
      setDataList(() =>
        floorList
          ?.filter(item => item.propertyDTOS?.length !== 0)
          ?.map(item => {
            return {title: item.floor, data: item.propertyDTOS}
          })
      )

      await propertyModel.condoInsertStepCheck({
        step: 2,
        condoId,
        templateDTOS,
        propertyDTOS: floorList?.map(item => item.propertyDTOS?.map(ele => ({...ele, floor: item.floor})))?.flat(),
      })

      setCurrent(2)
    })
  }

  // 全选/取消全选
  function toggleId(id) {
    if (selectIds.includes(id)) {
      setSelectIds(prevState => prevState.filter(item => item !== id))
    } else {
      setSelectIds(prevState => [...prevState, id])
    }
  }

  async function onConfirmAdd() {
    const value = form.getFieldsValue(true)
    const propertys = dataList
      .map(item => {
        return item.data.map(_item => {
          return {..._item, floor: item.title}
        })
      })
      .flat()

    if (!propertys.every(item => item.templateName)) return message.warning('请配置房间信息')

    const params = {condoId, propertyDTOS: propertys, templateDTOS: value.templateDTOS ? value.templateDTOS : []}
    // console.log('params =>', params)

    await propertyModel.condoManageInsertProperty(params)
    message.success('添加成功！')
    history.push('/chart')
  }

  return (
    <>
      <Breadcrumb path={['首页', '集中式房态图', '添加房间']} />
      <div className={commonStyles.section}>
        <Steps
          current={current}
          labelPlacement={'vertical'}
          style={{padding: '0 149px'}}
          onChange={current1 => {
            // console.log('current1 =>', current1)
            if (current1 === 0) {
              form.setFieldsValue({templateDTOS: []})
            }
            setCurrent(current1)
          }}
        >
          <Steps.Step title='添加房间' />
          <Steps.Step title='房间模板' disabled={current < 1} />
          <Steps.Step title='配置房间' disabled={current < 2} />
        </Steps>
      </div>
      <Tabs defaultActiveKey='1' activeKey={`${current}`} renderTabBar={() => null}>
        <Tabs.TabPane key='0'>
          <div className={commonStyles.flexFullSection}>
            <Form form={form} colon={false} labelCol={{span: 8}}>
              {/** 添加房间 楼层列表 */}
              <Form.List name={'floorList'}>
                {(fields, {add, remove}) => {
                  return (
                    <>
                      {fields.map((field, index) => (
                        <div className={addCondoStyles.floorList} key={field.key}>
                          {/** 楼层号 && 房间数量 */}
                          <div className={addCondoStyles.floorHeader}>
                            {index < originFloorList.length ? (
                              <Text
                                style={{marginLeft: 20}}
                              >{`楼层：${originFloorList[index].floor}（已有${originFloorList[index].propertyNums}间房）`}</Text>
                            ) : (
                              <Form.Item
                                label={'楼层号'}
                                name={[field.name, 'floor']}
                                fieldKey={[field.fieldKey, 'floor']}
                                rules={rules.floor}
                                className={addCondoStyles.floorFormItem1}
                              >
                                <Input onChange={() => onFloorChange(index)} />
                              </Form.Item>
                            )}
                            <Form.Item
                              name={[field.name, 'extNum']}
                              fieldKey={[field.fieldKey, 'extNum']}
                              label={'房间数量'}
                              rules={[{required: true, message: '房间数不能为空'}]}
                              className={addCondoStyles.floorFormItem2}
                            >
                              <Input
                                maxLength={2}
                                onBlur={() => onExtNumChange(field.fieldKey)}
                                onPressEnter={() => onExtNumChange(field.fieldKey)}
                                placeholder='请输入房间数量'
                                disabled={originFloorList[index]?.propertyNums >= 150}
                              />
                            </Form.Item>
                            {index < originFloorList.length ? null : (
                              <Button danger onClick={() => remove(field.name)} className={addCondoStyles.floorDelete}>
                                删除
                              </Button>
                            )}
                          </div>

                          {/** 房间列表 */}
                          <Form.List name={[field.name, 'propertyDTOS']}>
                            {(innerFields, {add: innerAdd, remove: innerRemove}) => {
                              return (
                                <div className={addCondoStyles.roomList}>
                                  {innerFields.map(innerField => (
                                    <div key={innerField.key} className={addCondoStyles.roomItem}>
                                      <img
                                        src={Delete}
                                        alt={'icon'}
                                        className={addCondoStyles.roomDelete}
                                        onClick={() => {
                                          innerRemove(innerField.name)
                                          onDeleteRoom()
                                        }}
                                      />
                                      <div className={addCondoStyles.roomBorder}>
                                        <Form.Item
                                          name={[innerField.name, 'houseNo']}
                                          fieldKey={[innerField.fieldKey, 'houseNo']}
                                          style={{marginBottom: 0}}
                                          rules={rules.houseNo}
                                        >
                                          <Input style={{border: 'none'}} placeholder={'请输入房间号'} />
                                        </Form.Item>
                                        <Divider className={addCondoStyles.roomDivider} />
                                        <Form.Item
                                          name={[innerField.name, 'rentStatus']}
                                          fieldKey={[innerField.fieldKey, 'rentStatus']}
                                          style={{marginBottom: 0}}
                                        >
                                          <Select placeholder={'请选择出租状态'} bordered={false}>
                                            <Option value={'1'}>已租</Option>
                                            <Option value={'0'}>未租</Option>
                                          </Select>
                                        </Form.Item>
                                        {isChecked && (
                                          <>
                                            <Divider className={addCondoStyles.roomDivider} />
                                            <Form.Item
                                              name={[innerField.name, 'propertyCodeCustom']}
                                              fieldKey={[innerField.fieldKey, 'propertyCodeCustom']}
                                              style={{marginBottom: 0}}
                                            >
                                              <Input style={{border: 'none'}} placeholder={'请输入房源编号(选填)'} />
                                            </Form.Item>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  ))}
                                  <div className={addCondoStyles.roomAdd}>
                                    {(+form.getFieldValue('floorList')[index].extNum +
                                      +originFloorList[index]?.propertyNums <
                                      200 ||
                                      +form.getFieldValue('floorList')[index].extNum < 200) && (
                                      <Button
                                        shape={'circle'}
                                        type='primary'
                                        ghost
                                        icon={<PlusOutlined />}
                                        onClick={() => {
                                          innerAdd()
                                          onAddRoom()
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              )
                            }}
                          </Form.List>
                        </div>
                      ))}

                      <Button
                        type='primary'
                        ghost
                        icon={<PlusOutlined />}
                        onClick={async () => {
                          const result = await form.validateFields(['topFloor'])
                          if (result.topFloor <= result.floorList?.length) {
                            message.warn('楼层数不能超过公寓总高！')
                            return
                          }
                          const floorList = form.getFieldValue('floorList')
                          add({
                            extNum: 0,
                            floor: floorList.length,
                            propertyDTOS: [],
                          })
                        }}
                      >
                        添加楼层
                      </Button>
                    </>
                  )
                }}
              </Form.List>
              <div className={addCondoStyles.next}>
                <Button type={'primary'} style={{width: 120, height: 40}} onClick={nextToTemplate}>
                  下一步
                </Button>
              </div>
            </Form>
          </div>
        </Tabs.TabPane>
        {/** 房间模板 */}
        <Tabs.TabPane key='1'>
          <div className={commonStyles.flexFullSection}>
            <div className={styles.templateList}>
              {templateList.map((item, index) => (
                <div key={item.templateId} className={styles.templateItem}>
                  <span>{`模板${index + 1}`}</span>
                  <span>{item.templateName}</span>
                  <span>{`共有${item.proNums}间房使用此模版`}</span>
                </div>
              ))}
            </div>
            <Form form={form} colon={false} labelAlign={'right'}>
              <Form.List name={'templateDTOS'}>
                {(fields, {add, remove}) => {
                  return (
                    <React.Fragment>
                      {fields.map(field => (
                        <div key={field.key} className={addCondoStyles.template}>
                          <div className={addCondoStyles.templateHeader}>
                            <Form.Item
                              name={[field.name, 'templateName']}
                              fieldKey={[field.fieldKey, 'templateName']}
                              style={{marginBottom: 0}}
                              rules={rules.templateName}
                            >
                              <Input placeholder={'模板名称'} prefix={<EditOutlined />} className={'templateName'} />
                            </Form.Item>
                            <Button danger onClick={() => remove(field.name)}>
                              删除
                            </Button>
                          </div>
                          <Divider className={addCondoStyles.templateDivider} />
                          <div className={addCondoStyles.templateBody}>
                            <Row gutter={38}>
                              <Col span={6}>
                                <Form.Item
                                  label={'房间面积'}
                                  name={[field.name, 'floorage']}
                                  fieldKey={[field.fieldKey, 'floorage']}
                                  rules={rules.floorage}
                                >
                                  <Input placeholder={'请输入房间面积'} />
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item
                                  label={'定金'}
                                  name={[field.name, 'deposit']}
                                  fieldKey={[field.fieldKey, 'deposit']}
                                  rules={rules.deposit}
                                >
                                  <Input placeholder={'请输入定金金额'} />
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item
                                  label={'押金'}
                                  name={[field.name, 'rentPledge']}
                                  fieldKey={[field.fieldKey, 'rentPledge']}
                                  rules={rules.rentPledge}
                                >
                                  <Input placeholder={'请输入押金金额'} />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={38}>
                              <Col span={11}>
                                <Form.Item label={'室/厅/卫'}>
                                  <Row gutter={10}>
                                    <Col span={8}>
                                      <Form.Item
                                        name={[field.name, 'bedroom']}
                                        fieldKey={[field.fieldKey, 'bedroom']}
                                        rules={rules.bedroom}
                                      >
                                        <Input placeholder={'请输入'} suffix={'室'} />
                                      </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                      <Form.Item
                                        name={[field.name, 'parlor']}
                                        fieldKey={[field.fieldKey, 'parlor']}
                                        rules={rules.parlor}
                                      >
                                        <Input placeholder={'请输入'} suffix={'厅'} />
                                      </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                      <Form.Item
                                        name={[field.name, 'bathroom']}
                                        fieldKey={[field.fieldKey, 'bathroom']}
                                        rules={rules.bathroom}
                                      >
                                        <Input placeholder={'请输入'} suffix={'卫'} />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </Form.Item>
                              </Col>
                              <Col span={7}>
                                <Row gutter={10}>
                                  <Col span={12}>
                                    <Form.Item
                                      label={'付'}
                                      name={[field.name, 'rentPayType']}
                                      fieldKey={[field.fieldKey, 'rentPayType']}
                                      rules={rules.rentPayType}
                                    >
                                      <Input placeholder={'请输入'} />
                                    </Form.Item>
                                  </Col>
                                  <Col span={12}>
                                    <Form.Item
                                      label={'押'}
                                      name={[field.name, 'rentPledgeType']}
                                      fieldKey={[field.fieldKey, 'rentPledgeType']}
                                      rules={rules.rentPledgeType}
                                    >
                                      <Input placeholder={'请输入'} />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Col>
                              <Col span={6}>
                                <Form.Item
                                  label={'房租'}
                                  name={[field.name, 'rentPrice']}
                                  fieldKey={[field.fieldKey, 'rentPrice']}
                                  // rules={rules.rentPrice}
                                >
                                  <Input placeholder={'请输入房租金额'} />
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ))}
                      <Button type='primary' ghost icon={<PlusOutlined />} onClick={() => add()}>
                        添加模板
                      </Button>
                    </React.Fragment>
                  )
                }}
              </Form.List>
              <div className={addCondoStyles.next}>
                <Form.Item>
                  <Button type={'primary'} style={{width: 120, height: 40}} onClick={nextToConfiguration}>
                    下一步
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </Tabs.TabPane>

        {/** 配置房间 */}
        <Tabs.TabPane key='2'>
          <Row gutter={20} style={{margin: 0}}>
            <Col flex='1' style={{paddingLeft: 0, paddingRight: 0}}>
              <Form form={itemForm}>
                <Form.Item noStyle shouldUpdate>
                  {() => (
                    <List
                      style={{backgroundColor: '#fff'}}
                      dataSource={dataList}
                      renderItem={item => (
                        <List.Item style={{paddingTop: 0}}>
                          <div className={chartStyles.listSection}>
                            <Space className={addCondoStyles.configListSection}>
                              <Text>{`${item?.title}层`}</Text>

                              <Button
                                onClick={() => {
                                  setSelectIds(prevState =>
                                    Array.from(new Set([...prevState, ...item?.data.map(_item => _item.id)]))
                                  )
                                  itemForm.setFieldsValue(item?.data[0])
                                }}
                                type={'ghost'}
                              >
                                全选
                              </Button>

                              <Button
                                onClick={() => {
                                  const ids = item?.data.map(_item => _item.id)
                                  setSelectIds(prevState => prevState.filter(id => !ids?.includes(id)))
                                }}
                                type={'ghost'}
                              >
                                取消全选
                              </Button>
                            </Space>
                            <div className={addCondoStyles.configListContainer}>
                              {item?.data?.map((_item, n) => {
                                const isActive = selectIds.includes(_item.id)
                                return (
                                  <div
                                    key={`${n}-${_item.houseNo}`}
                                    className={
                                      isActive ? addCondoStyles.configListItemS : addCondoStyles.configListItemN
                                    }
                                    onClick={() => {
                                      toggleId(_item.id)
                                      if (!isActive) itemForm.setFieldsValue(_item)
                                    }}
                                  >
                                    <div
                                      className={
                                        isActive ? addCondoStyles.listItemHeaderS : addCondoStyles.listItemHeaderN
                                      }
                                    >
                                      <div
                                        className={
                                          _item.rentStatus === '0'
                                            ? addCondoStyles.headerSquarG
                                            : addCondoStyles.headerSquarB
                                        }
                                      />
                                      <div>{_item.houseNo}</div>
                                    </div>
                                    <div className={addCondoStyles.listItemBody}>
                                      {_item.templateName ? (
                                        <>
                                          <div>{_item.templateName}</div>
                                          <div>{`${_item.rentPrice}元/月`}</div>
                                        </>
                                      ) : (
                                        <div style={isActive ? {color: '#017BFF'} : {}}>未选择模板</div>
                                      )}
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </List.Item>
                      )}
                      header={
                        <div className={addCondoStyles.configHeader}>
                          <div className={addCondoStyles.headerTitle}>公寓名称</div>
                          <div className={addCondoStyles.configHeader}>
                            <div className={addCondoStyles.configHeader}>
                              <div className={addCondoStyles.headerSquarG} />
                              <div className={addCondoStyles.headerDesc}>未出租</div>
                            </div>
                            <div className={addCondoStyles.configHeader}>
                              <div className={addCondoStyles.headerSquarB} />
                              <div className={addCondoStyles.headerDesc}>已出租</div>
                            </div>
                          </div>
                        </div>
                      }
                    />
                  )}
                </Form.Item>
                <div className={addCondoStyles.confirmAdd}>
                  <Form.Item>
                    <Button type={'primary'} onClick={onConfirmAdd}>
                      确认添加
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </Col>

            <Affix offsetTop={0}>
              <Col flex='280px' className={chartStyles.side}>
                <Form
                  form={itemForm}
                  colon={false}
                  labelCol={{span: 6}}
                  onFinish={value => {
                    setDataList(prevState => {
                      return prevState.map(item => {
                        return {
                          ...item,
                          data: item.data.map(_item => {
                            if (selectIds.includes(_item.id)) {
                              return {
                                ..._item,
                                rentPrice: value.rentPrice || 0,
                                templateName: value.templateName,
                                rentStatus: value.rentStatus,
                              }
                            } else {
                              return _item
                            }
                          }),
                        }
                      })
                    })
                    itemForm.resetFields()
                    setSelectIds([])
                  }}
                >
                  <Form.Item name={'id'} noStyle>
                    <div />
                  </Form.Item>
                  <Col>
                    <Form.Item name={'houseNo'} label={'房间号'}>
                      <Input placeholder={'请输入房间号'} />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item name={'rentStatus'} label={'出租状态'}>
                      <Select placeholder={'请选择'}>
                        <Option value={'1'}>已租</Option>
                        <Option value={'0'}>未租</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      name={'templateName'}
                      label={'模板'}
                      // rules={rules.templateName}
                    >
                      <Select
                        placeholder={'请选择'}
                        onChange={value => {
                          const templateDTOS = form.getFieldValue('templateDTOS')
                          const template = templateDTOS
                            ? [...templateDTOS, ...templateList].find(item => item.templateName === value)
                            : templateList.find(item => item.templateName === value)
                          if (template && template.rentPrice) {
                            itemForm.setFieldsValue({rentPrice: template.rentPrice})
                          }
                        }}
                      >
                        {form.getFieldValue('templateDTOS')
                          ? [...form.getFieldValue('templateDTOS'), ...templateList].map((item, i) => (
                              <Option value={item?.templateName ?? ''} key={i}>
                                {item?.templateName ?? ''}
                              </Option>
                            ))
                          : templateList.map((item, i) => (
                              <Option value={item?.templateName ?? ''} key={i}>
                                {item?.templateName ?? ''}
                              </Option>
                            ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item name={'rentPrice'} label={'租金'}>
                      <Input placeholder={'请输入租金'} />
                    </Form.Item>
                  </Col>
                  <div className={addCondoStyles.configSave}>
                    <Form.Item>
                      <Button type={'primary'} htmlType={'submit'}>
                        保存
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </Col>
            </Affix>
          </Row>
        </Tabs.TabPane>
      </Tabs>
    </>
  )
}
