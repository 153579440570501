export function add(num1 = 0, num2 = 0, toFixed = 2) {
  return +((num1 * 100 + num2 * 100) / 100).toFixed(toFixed)
}

/**
 * 保留小数
 */
export function toFixed(num: number | string) {
  const value = typeof num === 'string' ? +num : num

  if (typeof value === 'number' && !isNaN(value)) {
    const nums = `${value}`.split('.')
    return `${nums[0]}.${(nums[1] || '').padEnd(2, '0').slice(0, 2)}`
  }

  return ''
}
