import React, {useCallback, useEffect, useState} from 'react'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import DeviceTabs from '@/pages/device/components/DeviceTabs'
import {Button, Col, DatePicker, Form, message, Modal, Progress, Row, Space, Switch, Table} from 'antd'
import DeviceTableHeader from '@/pages/device/components/DeviceTableHeader'
import DeviceBoardItem from '@/pages/device/components/DeviceBoardItem'
import DeviceYunDing from '@/pages/device/components/DeviceYunDing'
import deviceStyle from '@/pages/device/styles/device.module.less'
import {deviceModel} from '@/store/models/Device'
import {useLocation} from 'react-router-dom'
import moment from 'moment'
import {useHistory} from 'react-router'

const {RangePicker} = DatePicker

const dateFormat = 'YYYY-MM-DD HH:mm:ss'
const initParams = {
  begin: moment().startOf('month').format(dateFormat),
  end: moment().endOf('month').format(dateFormat),
  pageNum: 1,
  pageSize: 10,
} as DeviceLockRecordDTO

const pwdStateCode = {'1': '初始状态', '2': '已生效', '3': '将在一段时间后生效', '4': '已过期', '5': '已冻结'}

const sourceCode = {'0': '未知', '1': '蓝牙', '2': '密码', '3': '指纹', '5': '钥匙'}

export default function DeviceLockDetail() {
  const history = useHistory()
  const location = useLocation<{propertyCode: string; lesseeName: string; lesseeMobile: string}>()
  const {propertyCode, lesseeName, lesseeMobile} = location.state

  const [lockId, setLockId] = useState('')
  const [tabKey, setTabKey] = useState('1')

  // 门锁列表(内门锁、外门锁)
  const [deviceList, setDeviceList] = useState<DeviceVO[]>([])
  // 门锁详情
  const [lockInfo, setLockInfo] = useState({} as DeviceYunDingLockInfoVO)

  // 解绑门锁
  const [unBind, setUnBind] = useState(false)
  // 密码授权
  const [isAuth, setIsAuth] = useState(false)
  // 获取临时密码
  const [dynamicPwd, setDynamicPwd] = useState<Partial<DeviceYunDingLockPasswordPlaintextVO>>({})

  // 授权记录 && 开门记录 && 授权管理
  const [form] = Form.useForm()
  const [recordParams, setRecordParams] = useState<DeviceLockRecordDTO>({...initParams, lockId})
  const [authCount, setAuthCount] = useState(0)
  const [authList, setAuthList] = useState<DeviceLockPasswordLogVO[]>([])

  const [openCount, setOpenCount] = useState(0)
  const [openList, setOpenList] = useState<YunDingLockEventVO[]>([])

  const [passwordList, setPasswordList] = useState<DeviceYunDingLockPasswordTokenVO[]>([])

  const authLoading = deviceModel.deviceLockPasswordRecord.useLoading()
  const openLoading = deviceModel.deviceLockOpenRecord.useLoading()
  const passwordLoading = deviceModel.deviceLockPasswordList.useLoading()

  // todo: networking
  useEffect(
    function () {
      // 房源绑定的门锁列表
      deviceModel.deviceList({propertyCode, type: '1'}).then(({response: {data}}) => {
        setDeviceList(data)
        setLockId(data[0]?.deviceId)
        setRecordParams(prevState => ({...prevState, lockId: data[0]?.deviceId}))
      })
    },
    [propertyCode]
  )

  const queryLockInfo = useCallback(
    function () {
      // 门锁详情
      if (lockId) {
        deviceModel.deviceYundingLockInfo({lockId}).then(({response: {data}}) => {
          // console.log('datadatadata', data)
          setLockInfo(data)
        })
      }
    },
    [lockId]
  )

  useEffect(() => {
    queryLockInfo()
  }, [queryLockInfo])

  // 授权管理列表
  const queryPasswordList = useCallback(
    function () {
      if (lockId) {
        deviceModel.deviceLockPasswordList({lockId}).then(({response: {data}}) => setPasswordList(data))
      }
    },
    [lockId]
  )

  useEffect(
    function () {
      if (tabKey === '1') {
        // 授权记录
        if (recordParams.lockId) {
          deviceModel.deviceLockPasswordRecord(recordParams).then(({response: {data, total}}) => {
            setAuthCount(total)
            setAuthList(data)
          })
        }
      } else if (tabKey === '2') {
        // 开门记录
        if (recordParams.lockId) {
          deviceModel.deviceLockOpenRecord(recordParams).then(({response: {data, total}}) => {
            setOpenCount(total)
            setOpenList(data)
          })
        }
      } else queryPasswordList()
    },
    [recordParams, tabKey, queryPasswordList]
  )

  // todo: action
  const unBindLoading = deviceModel.deviceYundingDeviceUnbind.useLoading()
  const dynamicLoading = deviceModel.deviceYundingLockDynamicPassword.useLoading()

  // 解绑门锁
  const deviceUnbind = async () => {
    await deviceModel.deviceYundingDeviceUnbind({uuid: lockId})
    message.success('解绑成功')
    history.goBack()
  }
  // 临时密码
  const dynamicPassword = async () => {
    await deviceModel.deviceYundingLockDynamicPassword({lockId}).then(({response: {data}}) => setDynamicPwd(data))
  }
  // 添加授权
  const passwordInsert = async () => {
    await deviceModel.deviceLockPasswordInsert({propertyCode, lockId})
    message.success('操作成功！')
    setIsAuth(false)
    setTimeout(queryPasswordList, 1000)
  }
  // 启用/禁用
  const passwordEnable = async ({pwdId, enable}) => {
    await deviceModel.deviceLockPasswordEnable({pwdId, enable})
    message.success('操作成功！')
    // 接口刷状态会有延时；所有手动更新状态
    setPasswordList(prevState =>
      prevState.map(item => (item.pwdId === pwdId ? {...item, pwdState: item.pwdState === 2 ? 5 : 2} : item))
    )
  }
  // 取消授权
  const passwordDelete = async pwdId => {
    await deviceModel.deviceLockPasswordDelete({pwdId})
    message.success('操作成功！')
    setTimeout(queryPasswordList, 1000)
  }

  return (
    <>
      <Breadcrumb path={['首页', '智能设备', '门锁']} />
      <DeviceTabs
        tabList={deviceList.map(item => ({title: item.sourceType === '1' ? '内门锁' : '外门锁', key: item.deviceId}))}
        onChange={activeKey => setLockId(activeKey)}
      />
      <div className={deviceStyle.deviceSection}>
        <div className={deviceStyle.tx}>
          <DeviceYunDing />
        </div>
        <div style={{flex: 1}}>
          <div style={{display: 'flex'}}>
            <div>
              <Row>
                <span className={deviceStyle.title}>智能门锁</span>
              </Row>
              <Row style={{margin: '12px 0'}}>
                <Space size={30}>
                  <DeviceBoardItem
                    label={'网关状态'}
                    value={lockInfo.onoffLine === 1 ? '在线' : '离线'}
                    status={lockInfo.onoffLine === 1 ? 'success' : 'error'}
                  />
                  <DeviceBoardItem label={'承租人'} value={lesseeName} />
                  <DeviceBoardItem label={'手机号'} value={lesseeMobile} />
                </Space>
              </Row>
              <Row>
                <Space>
                  <DeviceBoardItem label={'房源地址'} value={lockInfo.title} />
                </Space>
              </Row>
            </div>
            <div style={{marginLeft: '50px'}}>
              <Progress
                width={94}
                strokeWidth={7}
                percent={lockInfo.power}
                strokeColor={'#017BFF'}
                type={'circle'}
                format={percent => (
                  <Space direction={'vertical'}>
                    <span style={{fontSize: '16px'}}>{percent}%</span>
                    <span style={{fontSize: '14px'}}>剩余电量</span>
                  </Space>
                )}
              />
            </div>
          </div>
          <Row justify={'end'} style={{margin: '30px 0 0 0'}}>
            <Space>
              <Button type={'primary'} onClick={() => setUnBind(true)} loading={unBindLoading}>
                解绑门锁
              </Button>
              <Button type={'primary'} onClick={() => setIsAuth(true)}>
                密码授权
              </Button>
              <Button onClick={dynamicPassword} loading={dynamicLoading}>
                临时密码
              </Button>
            </Space>
          </Row>
        </div>
      </div>
      <DeviceTabs
        tabList={[
          {title: '授权记录', key: '1'},
          {title: '开门记录', key: '2'},
          {title: '授权管理', key: '3'},
        ]}
        onChange={activeKey => {
          setTabKey(activeKey)
          setRecordParams({...initParams, lockId})
        }}
      />
      {['1', '2'].includes(tabKey) && (
        <div className={deviceStyle.formMargin}>
          <Form
            form={form}
            onFinish={formValues => {
              const {time} = formValues
              if (time) {
                const [begin, end] = time.map(item => moment(item).format(dateFormat))
                setRecordParams(prevState => ({...prevState, begin, end}))
              } else setRecordParams(prevState => ({...prevState, begin: initParams.begin, end: initParams.end}))
            }}
          >
            <Row>
              <Col span={7}>
                <Form.Item name={'time'} label={'查询时间'}>
                  <RangePicker />
                </Form.Item>
              </Col>
            </Row>
            <Row justify={'end'}>
              <Space>
                <Button type={'primary'} htmlType={'submit'}>
                  查询
                </Button>
                <Button
                  htmlType={'reset'}
                  onClick={() => {
                    form.resetFields()
                    setRecordParams({...initParams, lockId})
                  }}
                >
                  重置
                </Button>
              </Space>
            </Row>
          </Form>
        </div>
      )}
      <div className={deviceStyle.tableSection}>
        {tabKey === '1' ? (
          <>
            <DeviceTableHeader total={authCount} />
            <Table
              rowKey={'eventId'}
              columns={[
                {title: '状态', dataIndex: 'status', render: status => (status === '1' ? '有效' : '无效')},
                {title: '被授权人', dataIndex: 'operatorName'},
                {title: '授权时间', dataIndex: 'insertTime'},
                {title: '操作描述', dataIndex: 'desc'},
              ]}
              loading={authLoading}
              dataSource={authList}
              pagination={{
                total: authCount,
                current: recordParams.pageNum,
                pageSize: recordParams.pageSize,
                position: ['bottomCenter'],
                showSizeChanger: true,
                hideOnSinglePage: false,
                onChange: (pageNum, pageSize) =>
                  setRecordParams(prevState => ({
                    ...prevState,
                    pageSize,
                    pageNum: prevState.pageSize === pageSize ? pageNum : 1,
                  })),
              }}
            />
          </>
        ) : tabKey === '2' ? (
          <>
            <DeviceTableHeader total={openCount} />
            <Table
              rowKey={'time'}
              columns={[
                {title: '开门人', dataIndex: 'sourceName'},
                {title: '开门方式', dataIndex: 'source', render: source => sourceCode[source]},
                {title: '授权时间', dataIndex: 'time', render: time => moment(time).format(dateFormat)},
              ]}
              loading={openLoading}
              dataSource={openList}
              pagination={{
                total: openCount,
                current: recordParams.pageNum,
                pageSize: recordParams.pageSize,
                position: ['bottomCenter'],
                showSizeChanger: true,
                hideOnSinglePage: false,
                onChange: (pageNum, pageSize) =>
                  setRecordParams(prevState => ({
                    ...prevState,
                    pageSize,
                    pageNum: prevState.pageSize === pageSize ? pageNum : 1,
                  })),
              }}
            />
          </>
        ) : (
          <>
            <DeviceTableHeader total={passwordList.length} />
            <Table
              rowKey={'time'}
              columns={[
                {title: '状态', dataIndex: 'pwdState', render: state => pwdStateCode[state]},
                {title: '被授权人', dataIndex: 'name'},
                {title: '手机号', dataIndex: 'sendTo'},
                {
                  title: '授权期限',
                  dataIndex: 'begin',
                  render: (begin, record) => {
                    const {end} = record
                    return `${moment(begin).format('YYYY-MM-DD')}至${moment(end).format('YYYY-MM-DD')}`
                  },
                },
                {title: '授权时间', dataIndex: 'time', render: time => moment(time).format(dateFormat)},
                {
                  title: '操作',
                  render: record => {
                    const {pwdState, pwdId} = record
                    return (
                      <Space>
                        <Switch
                          checked={pwdState === 2}
                          onChange={() => passwordEnable({pwdId, enable: pwdState !== 2})}
                        />
                        <Button type={'link'} onClick={() => passwordDelete(pwdId)}>
                          取消授权
                        </Button>
                      </Space>
                    )
                  },
                },
              ]}
              loading={passwordLoading}
              dataSource={passwordList}
              pagination={{position: ['bottomCenter']}}
            />
          </>
        )}
      </div>

      {/* 解绑 */}
      <Modal visible={unBind} centered title={'解绑设备'} onCancel={() => setUnBind(false)} onOk={deviceUnbind}>
        解绑后您将无法在牛油果房东获取该门锁的相关信息，是否确认要将该门锁与牛油果房东对应房源进行解绑
      </Modal>
      {/* 添加授权 */}
      <Modal visible={isAuth} centered title={'添加授权'} onCancel={() => setIsAuth(false)} onOk={passwordInsert}>
        <span style={{color: '#8C8C8C'}}>被授权人：</span>
        <span>{lesseeName}</span>
      </Modal>
      {/* 临时密码 */}
      <Modal
        visible={!!dynamicPwd.password}
        centered
        title={'临时密码'}
        okText={'复制密码'}
        onCancel={() => setDynamicPwd({})}
        onOk={() =>
          navigator.clipboard.writeText(dynamicPwd.password).then(() => {
            message.success('复制成功！')
            setDynamicPwd({})
          })
        }
      >
        <Space direction={'vertical'} align={'center'} style={{width: '100%'}}>
          <span style={{fontSize: '30px', fontWeight: 'bold', letterSpacing: '10px'}}>{dynamicPwd.password}</span>
          <span>失效时间：{dynamicPwd.invalidTime}</span>
        </Space>
      </Modal>
    </>
  )
}
