import React, {useCallback, useEffect, useState} from 'react'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Upload,
} from 'antd'
import MeterTeamCommunity from '@/pages/meter/MeterTeamCommunity'
import {DownOutlined, InfoCircleOutlined} from '@ant-design/icons'
import {propertyModel} from '@/store/models/Property'
import {useCodeSync} from '@/store/models/Common'
import moment from 'moment'
import styles from './index.module.less'
import {downloadBlob} from '@/utils/util'
import {$api} from '@/store/service'

const {TabPane} = Tabs
const {Option} = Select

type MeterFloor = {label: string; id: string | number}
type MeterCondition = {
  monthMeterStatus: string
  meterType: string[]
  buildingNo: string
  floor: number
  houseNo: string
}

// 筛选数据
function dataListFilter(list: any[], condition: MeterCondition) {
  // 1：拿到所有的key  2：过滤出key有值的项
  const keys = Object.keys(condition)?.filter(ele => ![null, undefined, ''].includes(condition[ele]))
  return list.filter(item => {
    // 筛选
    const res = keys.map(t => {
      if (Array.isArray(condition[t])) {
        // 如果点了水电煤，根据水电煤筛选 没有的话直接返回true查全部
        return condition[t].length ? condition[t].includes(item[t]) : true
      } else return condition[t] === item[t]
    })
    // 如果res为空说明没有筛选条件；every用来验证是否满足所有筛选条件，
    return res.length ? res.every(ele => ele) : true
  })
}

// 楼层
function dataListFloor(list: any[], communityType: string) {
  return list
    .reduce((res, item) => {
      const elem = communityType === '1' ? item.buildingNo : item.floor
      if (res.includes(elem)) {
        return res
      } else return [...res, elem]
    }, [])
    .map(item => ({
      label: communityType === '1' ? `${item}栋` : `${item}层`,
      id: item,
    }))
}

// 计算房间用量
function calculateUse(args: {t1: number; t2: number}) {
  const {t1, t2} = args
  if (isNaN(Number(t1)) || isNaN(Number(t2))) {
    return 0
  }
  return Number(t2) - Number(t1)
}

// 计算账单金额
function calculateAmt(args: {t1: number; t2: number; unit: number}): number {
  const {t1, t2, unit} = args
  if (isNaN(Number(t1)) || isNaN(Number(t2)) || isNaN(unit)) {
    return 0
  }
  // 取单价
  const res = (Number(t2) - Number(t1)) * unit
  return Number(res.toFixed(2))
}

function meterToNumber(num: string | number = 0): string {
  const res = isNaN(Number(num)) ? num : Number(num).toFixed(2)
  return `${res}`
}

const MeterInput = React.memo(function (args: {placeholder: string; onChange: (value: string) => void}) {
  const {placeholder, onChange} = args
  return (
    <Col>
      <Input allowClear placeholder={placeholder} onChange={({target: {value}}) => onChange?.(value)} />
    </Col>
  )
})

const MeterSelect = React.memo(function (args: {
  placeholder: string
  selectList: MeterFloor[]
  onChange: (value: string | number) => void
}) {
  const {placeholder, selectList = [], onChange} = args
  return (
    <Col>
      <Select allowClear placeholder={placeholder} onChange={(value: string) => onChange?.(value)}>
        {selectList.map(item => (
          <Option key={item.id} value={item.id}>
            {item.label}
          </Option>
        ))}
      </Select>
    </Col>
  )
})

const MeterCheckbox = React.memo(function (args: {onChange: (value: string[]) => void}) {
  const {onChange} = args
  return (
    <Col>
      <Checkbox.Group onChange={checkedValue => onChange?.(checkedValue as string[])}>
        <Checkbox value={'W'}>水表</Checkbox>
        <Checkbox value={'E'}>电表</Checkbox>
        <Checkbox value={'G'}>燃气表</Checkbox>
        <Checkbox value={'H'}>热水表</Checkbox>
      </Checkbox.Group>
    </Col>
  )
})

const unitTypeCode = {W: '吨', E: '度', G: '立方米', H: '吨'}

export default function MeterReading() {
  const propertyMeterTypeCode = useCodeSync('property.meter.type')
  const propertyMeterRecordStatus = useCodeSync('property.meter.recordStatus')

  const [tabKey, setTabKey] = useState('1')

  // todo: 水电煤抄表
  // 当前团队或小区
  const [readCurrent, setReadCurrent] = useState({} as {communityType: string; code: string; name: string})

  // 栋/层
  const [floorList, setFloorList] = useState<MeterFloor[]>([])
  // 筛选条件
  const [readCondition, setReadCondition] = useState({} as MeterCondition)
  // 原始数据列表
  const [dataList, setDataList] = useState<PropertyMeterVO[]>([])
  // 筛选出的结果列表
  const [meterList, setMeterList] = useState<PropertyMeterVO[]>([])
  // 房源标题
  const [propertyTitle, setPropertyTitle] = useState('')

  // 记录上次读数 本次读数 和 应收款日
  const [form] = Form.useForm<{
    [key: string]: {numLast: number; numNow: number; billDate: string; id: string}
  }>()
  // const [meterRecord, setMeterRecord] = useState<{
  //   [key: string]: {numLast: number; numNow: number; billDate: string; id: string}
  // }>({})

  // 设备列表
  const queryPropertyMeterList = useCallback(
    function () {
      if (readCurrent.code) {
        propertyModel.propertyMeterList({communityCode: readCurrent.code}).then(({response: {data}}) => {
          setDataList(data)

          // setMeterRecord(
          //   data?.reduce((obj, item) => {
          //     obj[item.id] = {numLast: item.numLast, id: item.id}
          //     return obj
          //   }, {})
          // )
          form.setFieldsValue(
            data?.reduce((obj, item) => {
              obj[item.id] = {numLast: item.numLast, id: item.id}
              return obj
            }, {})
          )

          setFloorList(dataListFloor(data, readCurrent.communityType))
        })
      }
    },
    [form, readCurrent.code, readCurrent.communityType]
  )

  useEffect(() => queryPropertyMeterList(), [queryPropertyMeterList])

  useEffect(
    function () {
      const resList = dataListFilter(dataList, readCondition)
      setMeterList(resList)
    },
    [readCondition, dataList]
  )

  // todo: 抄表历史
  // 当前团队或小区
  const [billCurrent, setBillCurrent] = useState({} as {communityType: string})

  const [recordCondition, setRecordCondition] = useState<Partial<PropertyMeterRecordQueryDTO>>({
    pageNum: 1,
    pageSize: 10,
    pageable: true,
  })
  const [recordTotal, setRecordTotal] = useState(0)
  // 抄表记录列表
  const [recordList, setRecordList] = useState<PropertyMeterRecordVO[]>([])

  // 栋/层
  const [billFloor, setBillFloor] = useState<MeterFloor[]>([])
  // 筛选条件
  const [billCondition, setBillCondition] = useState({} as MeterCondition)
  // 筛选出的待生成账单列表
  const [billList, setBillList] = useState<PropertyMeterRecordVO[]>([])

  // 选择的记录
  const [ids, setIds] = useState<string[]>([])

  // 抄表记录列表
  const queryMeterRecordList = useCallback(
    function (cond: Partial<PropertyMeterRecordQueryDTO>) {
      propertyModel.propertyMeterRecordList(cond).then(({response: {data, total}}) => {
        setRecordList(data)
        setRecordTotal(total)

        if (cond.communityCode) {
          setBillFloor(dataListFloor(data, billCurrent.communityType))
        }
      })
    },
    [billCurrent.communityType]
  )

  useEffect(
    function () {
      // propertyCode: 抄表历史 communityCode：待生成账单
      if (recordCondition.propertyCode || recordCondition.communityCode || recordCondition.teamId) {
        queryMeterRecordList(recordCondition)
      }
    },
    [recordCondition, queryMeterRecordList]
  )

  useEffect(
    function () {
      if (tabKey === '2') {
        const resList = dataListFilter(recordList, billCondition)
        setBillList(resList)
      }
    },
    [billCondition, recordList, tabKey]
  )

  // todo: loading
  const recordLoading = propertyModel.propertyMeterRecordList.useLoading()
  const meterLoading = propertyModel.propertyMeterList.useLoading()
  const saveLoading = propertyModel.propertyMeterRecordInsert.useLoading()

  const [upLoading, setUpLoading] = useState(false)

  async function uploadMeterData(info) {
    const {name} = info.file
    if (!['.xlsx', '.xls'].includes(name && name.substring(name.lastIndexOf('.'), name.length).toLowerCase())) {
      message.warning('请上传正确格式Excel')
      return false
    }
    setUpLoading(true)
    const fd = new FormData()
    fd.append('file', info.file)
    const res: any = await $api.postAsync({uri: '/property/meter/record/insert-excel', body: fd}).catch(e => {
      message.error(e.message)
      setUpLoading(false)
    })

    if (res) {
      const {
        response: {code},
      } = res
      if (code === 200) {
        message.success('导入成功，请在待生成账单中查看抄表记录！')
        setUpLoading(false)
        queryPropertyMeterList()
      }
    }
  }

  return (
    <>
      <Breadcrumb
        path={['首页', '财务管理', '水电煤抄表']}
        right={
          <Space>
            <Upload multiple={false} showUploadList={false} customRequest={uploadMeterData}>
              <Button type={'primary'} loading={upLoading}>
                抄表数据导入
              </Button>
            </Upload>
            <Button
              onClick={() => {
                if (readCurrent.code) {
                  downloadBlob({
                    url: '/property/meter/list-excel',
                    body: {communityCode: readCurrent.code},
                  })
                } else message.warn('请选择小区')
              }}
            >
              抄表数据导出
            </Button>
          </Space>
        }
      />
      <Tabs
        defaultActiveKey={'1'}
        tabBarStyle={{paddingLeft: '10px', marginBottom: 0, backgroundColor: 'white'}}
        onChange={value => setTabKey(value)}
      >
        <TabPane tab={'水电煤抄表'} key={'1'} className={styles.tabPane}>
          <Row>
            <Col span={6} style={{borderRight: '1px solid #E9E9E9'}}>
              <MeterTeamCommunity
                onChange={({communityType, code, name}) => setReadCurrent({communityType, code, name})}
              />
            </Col>
            <Col span={18} className={styles.rowTable}>
              <Row gutter={15} align={'middle'}>
                <MeterInput
                  placeholder={'请输入房间号'}
                  onChange={value => setReadCondition(prevState => ({...prevState, houseNo: value}))}
                />
                <MeterSelect
                  placeholder={'抄表状态'}
                  selectList={[
                    {label: '本月未抄', id: '2'},
                    {label: '本月已抄', id: '3'},
                  ]}
                  onChange={value => {
                    const obj = {2: false, 3: true}
                    setReadCondition(prevState => ({...prevState, monthMeterStatus: obj[value]}))
                  }}
                />
                <MeterSelect
                  placeholder={'栋/层'}
                  selectList={floorList}
                  onChange={value => {
                    const obj =
                      readCurrent.communityType === '1' ? {buildingNo: value as string} : {floor: value as number}
                    setReadCondition(prevState => ({...prevState, ...obj}))
                  }}
                />
                <MeterCheckbox onChange={value => setReadCondition(prevState => ({...prevState, meterType: value}))} />
                <Col>
                  <Button
                    type={'primary'}
                    loading={saveLoading}
                    onClick={async () => {
                      const formValues = form.getFieldsValue(true) as {
                        [key: string]: {numLast: number; numNow: number; billDate: string; id: string}
                      }
                      const nowList = Object.values(formValues).filter(item => ![null, undefined].includes(item.numNow))
                      if (nowList.length === 0) return message.warn('请填写本次读数')
                      const paramsList = []
                      for (let i = 0; i < nowList.length; i++) {
                        const nowItem = nowList[i]
                        const originItem = meterList.find(item => item.id === nowItem.id)
                        const house = `${originItem.houseNo}房间`
                        const meter = propertyMeterTypeCode[originItem.meterType]
                        if ([null, undefined].includes(nowItem.numLast) || nowItem.numLast < 0)
                          return message.warn(`请检查 ${house} ${meter} 上次抄表信息`)
                        if (nowItem.numNow < nowItem.numLast)
                          return message.warn(`请检查 ${house} ${meter} 本次抄表信息！`)
                        if ([null, undefined].includes(nowItem.billDate))
                          return message.warn(`请检查 ${house} ${meter} 应收款日信息！`)
                        const obj = {
                          amt: nowItem.numNow - nowItem.numLast,
                          billDate: moment(nowItem.billDate).format('YYYY-MM-DD HH:mm:ss'),
                          contractId: originItem.contractId,
                          lesseeCertName: originItem.lesseeCertName,
                          lesseeId: originItem.lesseeId,
                          meterType: originItem.meterType,
                          numLast: nowItem.numLast,
                          numNow: nowItem.numNow,
                          propertyCode: originItem.propertyCode,
                          timeLast: originItem.timeLast,
                          unit: originItem.typeUnit,
                        }
                        paramsList.push(obj)
                      }
                      await propertyModel.propertyMeterRecordInsert(paramsList)
                      message.success('保存成功！')
                      queryPropertyMeterList()
                    }}
                  >
                    保存
                  </Button>
                </Col>
              </Row>
              <Divider />
              <Form form={form}>
                <Table
                  rowKey={'id'}
                  scroll={{x: 'max-content'}}
                  columns={[
                    {
                      title: '门牌号',
                      dataIndex: 'houseNo',
                      key: 'houseNo',
                      render: (_, record) => {
                        const {buildingNo, floor, houseNo} = record
                        return [buildingNo && `${buildingNo}栋`, floor && `${floor}层`, houseNo]
                          .filter(item => item)
                          .join('-')
                      },
                    },
                    {
                      title: '类型',
                      dataIndex: 'meterType',
                      key: 'meterType',
                      render: meterType => propertyMeterTypeCode[meterType],
                    },
                    {
                      title: '上次读数',
                      dataIndex: 'id',
                      key: 'id',
                      render: id => {
                        return (
                          <Form.Item noStyle name={[id, 'numLast']}>
                            <InputNumber style={{border: 'none'}} placeholder={'请输入'} />
                          </Form.Item>
                        )
                      },
                    },
                    {
                      title: '本次读数',
                      dataIndex: 'id',
                      key: 'id',
                      render: id => {
                        return (
                          <Form.Item noStyle name={[id, 'numNow']}>
                            <InputNumber style={{border: 'none'}} placeholder={'请输入'} />
                          </Form.Item>
                        )
                      },
                    },
                    {
                      title: '用量',
                      dataIndex: 'id',
                      width: 150,
                      key: 'id',
                      render: id => {
                        return (
                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, nextValues) =>
                              prevValues[id]?.['numLast'] !== nextValues[id]?.['numLast'] ||
                              prevValues[id]?.['numNow'] !== nextValues[id]?.['numNow']
                            }
                          >
                            {f =>
                              meterToNumber(
                                calculateUse({
                                  t1: f.getFieldValue([id, 'numLast']),
                                  t2: f.getFieldValue([id, 'numNow']),
                                })
                              )
                            }
                          </Form.Item>
                        )
                      },
                    },
                    {
                      title: '应收',
                      dataIndex: 'id',
                      width: 150,
                      key: 'id',
                      render: (id, record) => {
                        const {typeUnit} = record ?? {}
                        return (
                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, nextValues) =>
                              prevValues[id]?.['numLast'] !== nextValues[id]?.['numLast'] ||
                              prevValues[id]?.['numNow'] !== nextValues[id]?.['numNow']
                            }
                          >
                            {f => {
                              const money = meterToNumber(
                                calculateAmt({
                                  t1: f.getFieldValue([id, 'numLast']),
                                  t2: f.getFieldValue([id, 'numNow']),
                                  unit: typeUnit,
                                })
                              )
                              return (
                                <Space>
                                  <span>{`￥${money}`}</span>
                                  {Number(money) < 0 && <InfoCircleOutlined style={{color: '#FFA02B'}} />}
                                </Space>
                              )
                            }}
                          </Form.Item>
                        )
                      },
                    },
                    {
                      title: '应收款日',
                      dataIndex: 'id',
                      key: 'id',
                      render: id => {
                        return (
                          <Form.Item noStyle name={[id, 'billDate']}>
                            <DatePicker bordered={false} suffixIcon={<DownOutlined />} placeholder={'请选择'} />
                          </Form.Item>
                        )
                      },
                    },
                    {
                      title: '抄表状态',
                      dataIndex: 'monthMeterStatus',
                      key: 'monthMeterStatus',
                      render: monthMeterStatus => (monthMeterStatus ? '本月已抄' : '本月未抄'),
                    },
                    {
                      title: '租客',
                      dataIndex: 'lesseeCertName',
                      key: 'lesseeCertName',
                      render: lesseeCertName => lesseeCertName ?? '无',
                    },
                    {
                      title: '历史',
                      dataIndex: 'propertyCode',
                      key: 'propertyCode',
                      render: (value, record) => {
                        const {buildingNo, floor, unitNo, houseNo} = record ?? {}
                        const title = [
                          buildingNo && `${buildingNo}栋`,
                          unitNo && `${unitNo}单元`,
                          floor && `${floor}层`,
                          houseNo,
                        ]
                          .filter(item => item)
                          .join('')
                        return (
                          <Button
                            type={'link'}
                            style={{padding: 0}}
                            onClick={() => {
                              setRecordCondition(prevState => ({...prevState, propertyCode: value, meterType: 'W'}))
                              setPropertyTitle(title)
                            }}
                          >
                            抄表历史
                          </Button>
                        )
                      },
                    },
                  ]}
                  dataSource={meterList}
                  pagination={false}
                  loading={meterLoading}
                />
              </Form>
            </Col>
          </Row>
        </TabPane>
        <TabPane tab={'待生成账单'} key={'2'} className={styles.tabPane}>
          <Row>
            <Col span={6} style={{borderRight: '1px solid #E9E9E9'}}>
              <MeterTeamCommunity
                isBill={tabKey === '2'}
                onChange={({communityType, code, teamId}) => {
                  setRecordCondition(prevState => ({
                    ...prevState,
                    teamId,
                    communityCode: code,
                    recordStatus: '1',
                    pageable: false,
                  }))
                  setBillCurrent({communityType})
                }}
              />
            </Col>
            <Col span={18} className={styles.rowTable}>
              <Row gutter={15} align={'middle'}>
                <MeterInput
                  placeholder={'请输入房间号'}
                  onChange={value => setBillCondition(prevState => ({...prevState, houseNo: value}))}
                />
                <MeterSelect
                  placeholder={'栋/层'}
                  selectList={billFloor}
                  onChange={value => {
                    const obj =
                      billCurrent.communityType === '1' ? {buildingNo: value as string} : {floor: value as number}
                    setBillCondition(prevState => ({...prevState, ...obj}))
                  }}
                />
                <MeterCheckbox onChange={value => setBillCondition(prevState => ({...prevState, meterType: value}))} />
              </Row>
              <Divider />
              <Table
                rowSelection={{
                  fixed: true,
                  selectedRowKeys: ids,
                  onChange: selectRowKeys => setIds(selectRowKeys as string[]),
                }}
                rowKey={'recordId'}
                scroll={{x: 'max-content'}}
                columns={[
                  {
                    title: '门牌号',
                    dataIndex: 'houseNo',
                    key: 'houseNo',
                    render: (_, record) => {
                      const {buildingNo, floor, houseNo} = record
                      return [buildingNo && `${buildingNo}栋`, floor && `${floor}层`, houseNo]
                        .filter(item => item)
                        .join('-')
                    },
                  },
                  {
                    title: '类型',
                    dataIndex: 'meterType',
                    key: 'meterType',
                    render: meterType => propertyMeterTypeCode[meterType],
                  },
                  {title: '上次读数', dataIndex: 'numLast', key: 'numLast', render: numLast => meterToNumber(numLast)},
                  {title: '本次读数', dataIndex: 'numNow', key: 'numNow', render: numNow => meterToNumber(numNow)},
                  {
                    title: '用量',
                    dataIndex: 'numDiff',
                    key: 'numDiff',
                    render: numDiff => meterToNumber(numDiff),
                  },
                  {title: '应收', dataIndex: 'amt', key: 'amt', render: amt => `￥${meterToNumber(amt)}`},
                  {title: '应收款日', dataIndex: 'billDate', key: 'billDate'},
                  {
                    title: '起止时间',
                    dataIndex: 'timeLast',
                    key: 'timeLast',
                    render: (value, record) => {
                      const {timeNow} = record
                      return `${value ? value : timeNow}至${timeNow}`
                    },
                  },
                  {
                    title: '租客',
                    dataIndex: 'lesseeCertName',
                    key: 'lesseeCertName',
                    render: lesseeCertName => lesseeCertName ?? '无',
                  },
                  {title: '抄表人', dataIndex: 'operatorName', key: 'operatorName'},
                ]}
                dataSource={billList}
                pagination={false}
                loading={recordLoading}
              />
              <Row justify={'center'} style={{margin: '40px 0 160px'}}>
                <Space>
                  <Button
                    onClick={async () => {
                      if (ids.length === 0) return message.warn('请选择要作废的账单')
                      await propertyModel.propertyMeterRecordDiscard(ids)
                      message.success('作废成功！')
                      queryMeterRecordList(recordCondition)
                    }}
                    loading={propertyModel.propertyMeterRecordDiscard.useLoading()}
                  >
                    作废
                  </Button>
                  <Button
                    type={'primary'}
                    onClick={async () => {
                      if (ids.length === 0) return message.warn('请选择要生成的账单')
                      await propertyModel.propertyMeterRecordCreateBill(ids)
                      message.success('操作成功！')
                      queryMeterRecordList(recordCondition)
                    }}
                    loading={propertyModel.propertyMeterRecordCreateBill.useLoading()}
                  >
                    生成账单
                  </Button>
                </Space>
              </Row>
            </Col>
          </Row>
        </TabPane>
      </Tabs>

      <Drawer
        title={'抄表历史'}
        width={1000}
        placement={'right'}
        visible={!!recordCondition.propertyCode && tabKey === '1'}
        onClose={() => setRecordCondition({pageNum: 1, pageSize: 10, pageable: true})}
      >
        <Tabs
          defaultActiveKey={'W'}
          onChange={value => setRecordCondition(prevState => ({...prevState, meterType: value}))}
        >
          <TabPane tab={'水表'} key={'W'} />
          <TabPane tab={'电表'} key={'E'} />
          <TabPane tab={'燃气表'} key={'G'} />
          <TabPane tab={'热水表'} key={'H'} />
        </Tabs>
        <div className={styles.tableName}>
          <span className={styles.nameStyle}>{`${readCurrent.name}-${propertyTitle}`}</span>
        </div>
        <Table
          rowKey={'recordId'}
          scroll={{x: 'max-content'}}
          columns={[
            {title: '抄表时间', dataIndex: 'timeNow', key: 'timeNow'},
            {title: '上次读数', dataIndex: 'numLast', key: 'numLast', render: numLast => meterToNumber(numLast)},
            {title: '本次读数', dataIndex: 'numNow', key: 'numNow', render: numNow => meterToNumber(numNow)},
            {title: '用量', dataIndex: 'numDiff', key: 'numDiff', render: numDiff => meterToNumber(numDiff)},
            {
              title: `单价(元/${unitTypeCode[recordCondition.meterType]})`,
              dataIndex: 'unit',
              key: 'unit',
              render: unit => meterToNumber(unit),
            },
            {title: '账单金额(元)', dataIndex: 'amt', key: 'amt', render: amt => meterToNumber(amt)},
            {
              title: '账单状态',
              dataIndex: 'recordStatus',
              key: 'recordStatus',
              render: value => propertyMeterRecordStatus[value],
            },
            {
              title: '租客',
              dataIndex: 'lesseeCertName',
              key: 'lesseeCertName',
              render: lesseeCertName => lesseeCertName ?? '无',
            },
            {title: '抄表人', dataIndex: 'operatorName', key: 'operatorName'},
          ]}
          dataSource={recordList}
          pagination={{
            total: recordTotal,
            current: recordCondition.pageNum,
            defaultPageSize: 10,
            position: ['bottomCenter'],
            showSizeChanger: true,
            onChange: (pageNum, pageSize) => {
              setRecordCondition(prevState => ({
                ...prevState,
                pageNum: prevState.pageSize === pageSize ? pageNum : 1,
                pageSize,
              }))
            },
          }}
        />
      </Drawer>
    </>
  )
}
