import React, {useEffect, useState} from 'react'
import styles from './index.module.less'
import {Form, Input, Checkbox, Typography, Table, Button, Row, Col, Divider} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import {propertyModel} from '@/store/models/Property'
import {useCodeSync} from '@/store/models/Common'
import {useHistory} from 'react-router-dom'
import {Permission, usePermission} from '@/ui/components/Permission'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import {downloadBlob} from '@/utils/util'
import {AuditButton} from '@/ui/bizComponents/AuditButton'

export default function Quick() {
  const [form] = Form.useForm()
  const history = useHistory()
  const [auth] = usePermission()

  const rentTypeCode = useCodeSync('rentType')

  const [communityList, setCommunityList] = useState<{label: string; value: string}[]>([])
  const [total, setTotal] = useState(0)
  const [dataSource, setDataSource] = useState<PropertyUnrentQuickSearchVO[]>([])

  const tLoading = propertyModel.propertyUnrentQuickSearch.useLoading()

  // 查询录入过的小区（小区列表）
  async function searchCommunity() {
    const {
      response: {data},
    } = await propertyModel.propertyCommunityList({})
    form.setFieldsValue({communityCodeList: []})
    setCommunityList(data.map(item => ({label: item.name, value: item.code})))
  }

  async function search() {
    // const communityCodeList = form.getFieldValue('communityCodeList')
    // if (communityCodeList && communityCodeList.length) {
    //
    // }
    const {
      response: {data, total},
    } = await propertyModel.propertyUnrentQuickSearch(form.getFieldsValue(true) as PropertyUnrentQuickSearchDTO)
    setDataSource(data)
    setTotal(total)
  }

  useEffect(function () {
    // form.setFieldsValue({group: currentTeam.teamId})
    searchCommunity()
    search()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // const timer = useRef(null)
  // function throttleSearch() {
  //   clearTimeout(timer.current)
  //   timer.current = setTimeout(search, 200)
  // }

  return (
    <>
      <Breadcrumb
        path={['首页', '房源房态', '空房快查']}
        right={
          auth('002002002') ? (
            <Button
              onClick={() =>
                downloadBlob({url: '/property/unrent/quick-search-excel', body: form.getFieldsValue(true)})
              }
            >
              导出
            </Button>
          ) : null
        }
      />

      <div className={`${commonStyles.section} quick`}>
        <Form
          form={form}
          initialValues={{
            rentType: '',
            communityCodeList: [],
          }}
          onFinish={() => search()}
        >
          {/** 所属类目 */}
          <Form.Item label={'所属类目'} shouldUpdate style={{marginBottom: 0}}>
            {form1 => (
              <Form.Item noStyle style={{marginBottom: 0}}>
                {[
                  {label: '全部', key: ''},
                  {label: '集中式', key: '3'},
                  {label: '分散整租', key: '1'},
                  {label: '分散合租', key: '2'},
                ].map((item, idx) => (
                  <Button
                    key={idx}
                    type={form1.getFieldValue('rentType') === item.key ? 'primary' : 'text'}
                    size={'small'}
                    onClick={() => {
                      form1.setFieldsValue({rentType: item.key})
                      search()
                    }}
                    style={{minWidth: '70px', margin: '0 10px'}}
                  >
                    {item.label}
                  </Button>
                ))}
              </Form.Item>
            )}
          </Form.Item>
          <Divider dashed style={{margin: '16px 0'}} />
          {/** 空置天数 */}
          <Form.Item label={'空置天数'} style={{marginBottom: 0}}>
            <Input.Group compact>
              <Form.Item noStyle name={'unrentMin'}>
                <Input style={{width: 100, textAlign: 'center'}} placeholder='最小天数' />
              </Form.Item>
              <Input className={styles.split} placeholder='→' disabled />
              <Form.Item noStyle name={'unrentMax'}>
                <Input
                  className={styles.right}
                  style={{width: 100, textAlign: 'center'}}
                  placeholder='最大天数'
                  // onChange={throttleSearch}
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Divider dashed style={{margin: '16px 0'}} />
          {/** 公寓/小区名 */}
          <Form.Item label={'公寓/小区名'} style={{marginBottom: 0}}>
            <Row gutter={20}>
              <Col>
                <Form.Item shouldUpdate style={{marginBottom: 0}}>
                  {form1 => (
                    <Checkbox
                      indeterminate={
                        form1.getFieldValue('communityCodeList')?.length &&
                        form1.getFieldValue('communityCodeList')?.length < communityList.length
                      }
                      onChange={({target: {checked}}) => {
                        if (checked) {
                          form1.setFieldsValue({communityCodeList: communityList.map(item => item.value)})
                          search()
                        } else {
                          form1.setFieldsValue({communityCodeList: []})
                        }
                      }}
                      checked={
                        form1.getFieldValue('communityCodeList')?.length &&
                        form1.getFieldValue('communityCodeList')?.length === communityList.length
                      }
                    >
                      全选
                    </Checkbox>
                  )}
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name={'communityCodeList'} style={{marginBottom: 0}}>
                  <Checkbox.Group
                    onChange={communityCodeList => {
                      form.setFieldsValue({communityCodeList})
                      search()
                    }}
                  >
                    {communityList.map((item, index) => (
                      <Checkbox
                        key={`${item.value}-${index}`}
                        value={item.value}
                        style={{marginRight: '20px', marginLeft: 0}}
                      >
                        {item.label}
                      </Checkbox>
                    ))}
                  </Checkbox.Group>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>

          {/** 查询 & 重置 */}
          <Form.Item style={{textAlign: 'right', marginBottom: 0}}>
            <Button type={'primary'} htmlType={'submit'} style={{marginRight: '12px'}}>
              查询
            </Button>
            <Button
              onClick={() => {
                form.resetFields()
                search()
                // setDataSource([])
              }}
            >
              重置
            </Button>
          </Form.Item>
        </Form>
      </div>

      <div className={`${commonStyles.section}`}>
        <div style={{marginTop: '-24px'}} />
        <Table
          scroll={{x: true}}
          rowKey={record => record.propertyCode}
          rowSelection={{
            fixed: true,
          }}
          pagination={{
            total,
            onChange(pageNum, pageSize) {
              form.setFieldsValue({pageNum, pageSize})
              search()
            },
            onShowSizeChange(pageNum, pageSize) {
              form.setFieldsValue({pageNum: 1, pageSize})
              search()
            },
            position: ['bottomCenter'],
          }}
          title={() => (
            <Typography.Text>
              <Typography.Text strong style={{marginRight: '12px', marginLeft: '-16px'}}>
                查询结果
              </Typography.Text>
              共计
              <Typography.Text strong style={{color: '#017BFF'}}>
                {total}
              </Typography.Text>
              间符合条件的房源
            </Typography.Text>
          )}
          dataSource={dataSource}
          columns={[
            {
              title: '房源地址',
              dataIndex: 'propertyAddr',
              key: 'propertyAddr',
              width: '25%',
              align: 'center',
            },
            {
              title: '出租类型',
              dataIndex: 'rentType',
              key: 'rentType',
              align: 'center',
              render: text => rentTypeCode[text],
            },
            {
              title: '所属团队',
              dataIndex: 'teamName',
              key: 'teamName',
              align: 'center',
            },
            {
              title: '空置天数',
              dataIndex: 'days',
              key: 'days',
              align: 'center',
            },
            {
              title: '租金（元）',
              dataIndex: 'rentPrice',
              key: 'rentPrice',
              align: 'center',
            },
            {
              title: '管家',
              dataIndex: 'stewardName',
              key: 'stewardName',
              align: 'center',
              // render: text => <Tag color={'blue'}>{text}</Tag>,
            },
            {
              title: '操作',
              key: 'propertyCode',
              dataIndex: 'propertyCode',
              fixed: 'right',
              width: 160,
              render: (text, row, index) => (
                <>
                  <Permission code={'002001011'}>
                    <AuditButton
                      type={'link'}
                      size={'small'}
                      style={{padding: '0 5px'}}
                      rentType={row.rentType}
                      communityCode={row.communityCode}
                      propertyCode={row.propertyCode}
                      onClick={() =>
                        history.push('/contract/register', {
                          propertyCode: row.propertyCode,
                          // communityName: row.title,
                          rentPayType: row.rentPayType,
                          rentPledgeType: row.rentPledgeType,
                          rentPrice: row.rentPrice,
                          rentCollectType: row.rentCollectType,
                          rentCollectDay: row.rentCollectDay,
                        })
                      }
                    >
                      租客登记
                    </AuditButton>
                  </Permission>
                  <Permission code={'002001009'}>
                    <Divider type={'vertical'} style={{margin: 0}} />
                    <AuditButton
                      type={'link'}
                      size={'small'}
                      style={{padding: '0 5px'}}
                      rentType={row.rentType}
                      communityCode={row.communityCode}
                      propertyCode={row.propertyCode}
                      onClick={() => {
                        history.push('/contract/reserve', {
                          propertyCode: row.propertyCode,
                          communityName: row.title,
                          rentType: row.rentType,
                          rentPrice: row.rentPrice,
                          from: 'reserve',
                          communityCode: row.communityCode,

                          rentPayType: row.rentPayType,
                          rentPledgeType: row.rentPledgeType,
                          rentCollectType: row.rentCollectType,
                          rentCollectDay: row.rentCollectDay,
                        })
                      }}
                    >
                      预定
                    </AuditButton>
                  </Permission>
                </>
              ),
            },
          ]}
          loading={tLoading}
        />
      </div>
    </>
  )
}
