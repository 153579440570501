import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {AutoComplete, Button, Col, Form, Input, List, message, Row, Select, Tag} from 'antd'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import bindStyles from './bind.module.less'
import {SearchOutlined} from '@ant-design/icons'
import {propertyModel} from '@/store/models/Property'
import {deviceModel} from '@/store/models/Device'
import {usePagination} from '@/common/hooks/pagination'
import {useLocation} from 'react-router-dom'
import {useHistory} from 'react-router'

const {Option} = Select
const {CheckableTag} = Tag

export const BindTitleBar = React.memo(function (props: {title: string}) {
  const {title} = props
  return (
    <div className={bindStyles.titleBar}>
      <div className={bindStyles.titleBar__vLine} />
      <span className={bindStyles.titleBar__title}>{title}</span>
    </div>
  )
})

const typeCode = {lock: 'lock', elec: 'elemeter', water: 'watermeter'}

export default function DeviceBind() {
  const history = useHistory()
  const location = useLocation<{deviceType: 'lock' | 'elec' | 'water'}>()
  const {deviceType} = location.state

  const [form] = Form.useForm()

  const [check, setCheck] = useState<{roomId: string; roomName: string; uuid: string; homeId: string}>({
    roomId: undefined,
    roomName: undefined,
    uuid: undefined,
    homeId: undefined,
  })

  const [searchResult, setSearchResult] = useState<PropertyInfoVO[]>([])

  const renderOptions = useMemo(
    () => searchResult.map(item => ({value: item.title, label: item.title, code: item.propertyCode})),
    [searchResult]
  )

  const {list, finish, loading, searchNextByModel} = usePagination<YunDingHomeBasicInfoVO>()

  const queryLockList = useCallback(
    function (initialSearch = false) {
      searchNextByModel(deviceModel.deviceYundingLockList, {initialSearch})
    },
    [searchNextByModel]
  )

  const dataList = list.map(item => {
    return {
      title: `${item.province} ${item.city} ${item.zone} ${item.location}`,
      data: item.rooms.map(_item => {
        return {
          ..._item,
          homeId: item.homeId,
          devices: item.devices.find(e => e.roomId === _item.roomId && e.type === typeCode[deviceType]),
        }
      }),
    }
  })

  useEffect(
    function () {
      queryLockList(true)
    },
    [queryLockList]
  )

  async function handleInput(keyword: string) {
    if (keyword.length) {
      propertyModel.propertySearchSuggestion({keyword}).then(({response: {data}}) => setSearchResult(data))
    }
  }

  return (
    <>
      <Breadcrumb path={['首页', '智能设备', '绑定设备']} />
      <div className={bindStyles.body}>
        <BindTitleBar title={'房源信息'} />
        <Form form={form} style={{margin: '30px 0 16px 0'}}>
          <Row>
            <Col span={8}>
              <Form.Item name={'title'} label={'房源地址'} rules={[{required: true, message: '请选择房源地址'}]}>
                <AutoComplete dropdownMatchSelectWidth={500} options={renderOptions}>
                  <Input
                    allowClear
                    placeholder='请输入房源地址，门牌号，租客姓名/手机号'
                    suffix={<SearchOutlined />}
                    onChange={({target: {value}}) => handleInput(value)}
                  />
                </AutoComplete>
              </Form.Item>
            </Col>
            {deviceType === 'lock' && (
              <Col span={8} offset={1}>
                <Form.Item name={'sourceType'} label={'门锁'} rules={[{required: true, message: '请选择门锁'}]}>
                  <Select placeholder={'请选择'} allowClear>
                    <Option value={'1'}>内门锁</Option>
                    <Option value={'2'}>外门锁</Option>
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
        </Form>
        <BindTitleBar title={'设备信息'} />
        <List
          dataSource={dataList}
          loading={loading}
          loadMore={
            <div style={{textAlign: 'center'}}>
              {!finish ? (
                <Button type={'link'} loading={loading} onClick={() => queryLockList()}>
                  {loading ? '加载中...' : '点击加载更多'}
                </Button>
              ) : (
                <span>暂无更多...</span>
              )}
            </div>
          }
          renderItem={item => (
            <div>
              <div className={bindStyles.itemTitle}>{item.title}</div>
              <div style={{flexWrap: 'wrap'}}>
                {item.data.map((ele, index) => {
                  const a1 = deviceType === 'lock' && !ele.devices
                  const a2 = ['elec', 'water'].includes(deviceType) && (!ele.devices || ele.roomId === ele.homeId)

                  return a1 || a2 ? (
                    <Tag key={index} className={bindStyles.tagNor}>
                      {ele.roomName}
                    </Tag>
                  ) : (
                    <CheckableTag
                      key={index}
                      checked={ele.devices.uuid === check.uuid}
                      className={bindStyles.itemTag}
                      onClick={() =>
                        setCheck({
                          roomId: ele.roomId,
                          roomName: ele.devices.description,
                          uuid: ele.devices.uuid,
                          homeId: ele.homeId,
                        })
                      }
                    >
                      {ele.roomName}
                    </CheckableTag>
                  )
                })}
              </div>
            </div>
          )}
        />
        <div className={bindStyles.footer}>
          <Form
            form={form}
            onFinish={async formValues => {
              const {title, sourceType} = formValues
              const propertyCode = renderOptions.find(item => item.value === title)?.code
              if (deviceType === 'lock') {
                if (!check.uuid) return message.error('请选择要绑定门锁的房间')
                await deviceModel.deviceYundingLockBind({
                  uuid: check.uuid,
                  name: check.roomName,
                  roomId: check.roomId,
                  homeId: check.homeId,
                  propertyCode,
                  sourceType,
                })
                message.success('绑定成功！')
                history.goBack()
              }

              if (deviceType === 'elec') {
                if (!check.uuid) return message.error('请选择要绑定电表的房间')
                await deviceModel.deviceYundingElemeterBind({
                  uuid: check.uuid,
                  name: check.roomName,
                  roomId: check.roomId,
                  homeId: check.homeId,
                  propertyCode,
                  sourceType: '1',
                })
                message.success('绑定成功！')
                history.goBack()
              }

              if (deviceType === 'water') {
                if (!check.uuid) return message.error('请选择要绑定水表的房间')
                await deviceModel.deviceYundingWatermeterBind({
                  uuid: check.uuid,
                  name: check.roomName,
                  roomId: check.roomId,
                  homeId: check.homeId,
                  propertyCode,
                  sourceType: '1',
                })
                message.success('绑定成功！')
                history.goBack()
              }
            }}
          >
            <Button type={'primary'} htmlType={'submit'}>
              确认
            </Button>
          </Form>
        </div>
      </div>
    </>
  )
}
