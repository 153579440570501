import {applyMiddleware, compose, createReduxStore, Middleware} from '@redux-model/web'
import {createLogger} from 'redux-logger'
import models from '@/store/models'

const rootMiddleWares: Middleware[] =
  process.env.NODE_ENV !== 'production'
    ? [
        createLogger({
          collapsed: true,
          diff: true,
          duration: true,
          colors: false,
          predicate: (_, action) => !['/condo/property-chart/status-detail'].includes(action.uri),
        }),
      ]
    : []

export const store = createReduxStore({
  enhancer: compose(applyMiddleware(...rootMiddleWares)),
  reducers: {
    ...models.tempModel.register(),
    ...models.messageModel.register(),
    ...models.configModel.register(),
  },
  persist: {
    version: '0.0.4',
    key: 'landlord',
    storage: localStorage,
    restoreDelay: 500,
    whitelist: {
      userModel: models.userModel,
      propertyModel: models.propertyModel,
    },
  },
})
