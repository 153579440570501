import React, {CSSProperties} from 'react'
import {ak} from '@/common/config'
import {MapContext} from './mapContext'
import {Script} from './script'
import mapctrls from './mapctrls.gif'

export interface MapviewProps {
  onReady?: () => void
  onMapChange?: (event: GeocoderResult) => any
  style?: CSSProperties
  children?: React.ReactNode
  center?: string | {lat: number; lng: number}
}

export class Mapview extends React.Component<MapviewProps, {ready: boolean}> {
  constructor(props) {
    super(props)
    this.state = {
      ready: false,
    }
  }

  // 外部可用 map 对象
  public map: any

  private geoc: any
  private localSearch: any
  private timer: any
  private searchZoom = 13

  private onGetCenterLocation = () => {
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      this.geoc.getLocation(this.map.getCenter(), rs => {
        const data = {zoom: this.map?.getZoom(), ...rs}
        this.searchZoom = data.zoom
        this.props.onMapChange?.(data)
      })
    }, 80)
  }

  // private get BMapGL() {
  //   return window.BMapGL
  // }

  // private get BMapGLLib() {
  //   return window.BMapGLLib
  // }
  private isLoaded = () => {
    return !!window.BMapGL?.Map && !!window.BMapGLLib
  }

  private cache = []

  // 移动地图
  public panTo = (lng: number, lat: number, zoom?: number) => {
    this.proxyCall(() => {
      if (zoom) this.map.flyTo(new BMapGL.Point(lng, lat), zoom)
      else this.map.panTo(new BMapGL.Point(lng, lat))
    })
  }

  // 移动到指定位置
  public panToByPath = (path: string, zoom?: number) => {
    this.proxyCall(() => {
      if (zoom) this.searchZoom = zoom
      this.localSearch?.search(path)
    })
  }

  // 设置缩放登记
  public setZoom = (zoom: number) => {
    this.proxyCall(() => this.map.setZoom(zoom))
  }

  private proxyCall = (callback: () => void) => {
    if (!this.state.ready) {
      this.cache.push(callback)
      return
    }
    callback?.()
  }

  private loadJSAPI = async () => {
    return Promise.all([
      Script.loadScript(`//api.map.baidu.com/api?type=webgl&v=1.0&ak=${ak}&callback=initMap`, this.isLoaded), // callback 必须存在
      Script.loadScript(`//mapopen.cdn.bcebos.com/github/BMapGLLib/DistanceTool/src/DistanceTool.min.js`),
    ])
      .then(() => Script.loadScript(`//mapopen.cdn.bcebos.com/github/BMapGLLib/RichMarker/src/RichMarker.min.js`)) // BMapGL 必须存在
      .then(() => {
        const initialize = BMapGLLib.RichMarker.prototype.initialize
        BMapGLLib.RichMarker.prototype.initialize = function (map) {
          const div = initialize.call(this, map)
          div.style.background = 'unset'
          return div
        }
      })
  }

  private init = async () => {
    if (!this.isLoaded()) {
      await this.loadJSAPI()
    }

    const {center, onReady} = this.props
    const map = (this.map = new BMapGL.Map('allmap', {enableMapClick: false})) // 创建Map实例
    this.geoc = new BMapGL.Geocoder()

    const scaleCtrl = new BMapGL.ScaleControl() // 添加比例尺控件
    const zoomCtrl = new BMapGL.ZoomControl() // 添加缩放控件

    map.enableScrollWheelZoom() // 启用滚轮放大缩小
    map.addControl(scaleCtrl)
    map.addControl(zoomCtrl)

    map.addEventListener('moveend', this.onGetCenterLocation)
    map.addEventListener('zoomend', this.onGetCenterLocation)

    this.localSearch = new BMapGL.LocalSearch(map, {
      // renderOptions: {map: map},
      onSearchComplete: result => {
        if (result._pois && result._pois.length) {
          map.flyTo(result._pois[0].point, this.searchZoom)
        }
      },
    })

    map.addEventListener('load', () => {
      this.setState({ready: true}, () => {
        onReady?.()
        while (this.cache.length) {
          this.cache.shift()()
        }
      })
    })

    const point = center ? (typeof center === 'object' ? new BMapGL.Point(center.lng, center.lat) : center) : '上海市'
    map.centerAndZoom(point, this.searchZoom)
  }

  componentDidMount() {
    this.init()
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this.map?.removeEventListener('moveend', this.onGetCenterLocation)
    this.map?.removeEventListener('zoomend', this.onGetCenterLocation)
  }

  private dis = null

  public get distanceTool() {
    if (!this.dis) {
      this.dis = new BMapGLLib.DistanceTool(this.map, {
        closeIcon: new BMapGL.Icon(mapctrls, new BMapGL.Size(12, 12), {
          imageSize: new BMapGL.Size(82, 174),
          imageOffset: new BMapGL.Size(0, 14), // 设置icon偏移
        }),
      })
    }

    return {
      open: () => this.dis.open(),
      close: () => this.dis.close(),
    }
  }

  render() {
    const {ready} = this.state

    return (
      <MapContext.Provider value={this.map}>
        <div style={{display: 'flex', flex: 1, width: '100%', height: '100%', ...this.props.style}} id={'allmap'}>
          {ready ? this.props.children : null}
        </div>
      </MapContext.Provider>
    )
  }
}

// export const Mapview = React.forwardRef(Map)
// export const Mapview = Mapview
