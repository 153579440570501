import {Rules} from 'async-validator'
import {$api} from '@/store/service'

export const demoRules: Rules = {
  // Demo Rules
  mobile: {required: true, pattern: /^1[0-9]{10}$/, message: '手机号码不正确'},
  require: [{required: true, message: 'require不能为空'}],
  length: [{min: 1, max: 200, message: '请输入1-200的字符'}],
  enum: {
    type: 'enum',
    enum: ['const', 'let', 'var'],
    message: 'xx不正确',
  },
  object: {
    type: 'object',
    required: true,
    message: 'object不能为空',
    fields: {
      field1: {type: 'string', required: true, message: 'field1不能为空'},
      field2: [
        {type: 'email', message: '邮箱格式不正确'},
        {min: 1, max: 20, message: '请输入1-20的邮箱账号'},
      ],
    },
  },
  array: {
    type: 'array',
    required: true,
    len: 2,
    fields: {
      0: {type: 'string', required: true, message: 'message0'},
      1: {type: 'number', required: true, message: 'message1'},
    },
  },
  dynami: {
    validator(/*rule, value, cb, srouce, opt*/) {
      // console.log(rule)
      // console.log(value)
      // console.log(cb)
      // console.log(srouce)
      // console.log(opt)
      return false
    },
    message: 'dynami error',
  },

  test: {
    pattern: /^\d+$/,
  },
}

const numberReg = /^\d+$/
// 小数正则，最多两位小数
const decimalsReg = /^\d+(\.\d{1,2})?$/
// const amountReg = /^\d+(\.\d+)?$/
const certNoReg = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
const mobileReg = /^1[0-9]{10}$/
const verificateCodeReg = /^\d{6}$/

const multiRules = {
  number: [
    {
      require: true,
      async validator(_, value) {
        if (!/^\d+(\.\d*)?$/.test(value)) throw new Error('格式不正确')
      },
    },
  ],
  houseNo: [
    {required: true, message: '请输入房号'},
    {max: 7, message: '房号不能超过7个字符'},
  ],
  templateName: [
    {required: true, message: '请输入模板名称'},
    {max: 8, message: '模板名称不能超过8个字'},
    {pattern: /^.*[^\d].*$/, message: '模板名称不能是纯数字'},
  ],
  templateNameAsync: {
    required: true,
    asyncValidator(_, templateName, callback) {
      if (templateName) {
        $api
          .postAsync({
            uri: '/condo/insert/check-template',
            body: {templateName},
          })
          .then(
            () => callback(),
            ({response: {data}}) => {
              callback(new Error(data.msg))
            }
          )
      } else {
        callback()
      }
    },
  } as Rules,
  rentPrice: [
    {
      required: true,
      async validator(_, value) {
        if (!value) throw new Error('租金不能为空')
        if (!/^\d+(\.\d+)?$/.test(value)) throw new Error('租金格式不正确')
        else if (!(value >= 10 && value <= 999999.99)) throw new Error('租金10-999999.99')
      },
    },
  ],

  bedroom: [{required: true, pattern: numberReg, message: '请选择户型'}],
  parlor: [{required: true, pattern: numberReg, message: '厅不能为空'}],
  bathroom: [{required: true, pattern: numberReg, message: '卫生间不能为空'}],
  floorage: [
    {pattern: numberReg, message: '请输入整数'},
    {
      required: true,
      async validator(_, value) {
        if (!(value >= 5 && value <= 300)) {
          throw new Error('面积5㎡-300㎡')
        }
      },
    },
  ],
  deposit: [
    {pattern: numberReg, message: '请输入整数'},
    {
      required: true,
      async validator(_, value) {
        if (!(value >= 0 && value <= 999999.99)) {
          throw new Error('定金不大能于999999.99')
        }
      },
    },
  ],
  rentPayType: [{required: true, pattern: numberReg, message: '请选择付款方式'}],
}

export const rules = {
  ...multiRules,
  // add your custom rules here
  // ...

  // floorage: [
  //   {required: true, message: '户型面积不能为空'},
  //   {pattern: numberReg, message: '户型面积不正确'},
  // ],
  // deposit: [
  //   {required: true, message: '定金不能为空'},
  //   {pattern: amountReg, message: '定金格式不正确'},
  // ],
  floor: [{required: true, message: '楼层不能为空'}],
  // deposit: [{required: true, message: '定金不能为空'}],
  // bedroom: [{required: true, message: '卧室不能为空'}],
  propertyType: [{required: true, message: '房源类型不能为空'}],
  rentCollectDay: [{required: true, message: '收租日不能为空'}],
  rentCollectType: [{required: true, message: '收租日类型不能为空'}],
  rentPledge: [
    {required: true, message: '押金不能为空'},
    {pattern: decimalsReg, message: '格式不正确'},
  ],
  // rentPrice: [{required: true, message: '租金不能为空'}],
  // rentPayType: [{required: true, message: '付款方式不能为空'}],
  rentPledgeType: [
    {required: true, message: '付款方式不能为空'},
    {pattern: numberReg, message: '请输入整数'},
  ],
  orientation: [{required: true, message: '朝向不能为空'}],
  roomName: [{required: true, message: '房间名称不能为空'}],

  // 添加新公寓
  condoName: [
    {required: true, message: '请输入公寓名称'},
    // {max: 15, message: '公寓名称不能超过15个字'},
    {pattern: /^.*[^\d].*$/, message: '公寓名称不能是纯数字'},
  ],
  condoNameAsync: {
    required: true,
    asyncValidator(_, condoName, callback) {
      if (condoName) {
        $api.postAsync({uri: '/condo/insert/check', body: {condoName}}).then(
          () => callback(),
          ({response: {data}}) => {
            callback(new Error(data.msg))
          }
        )
      } else {
        callback()
      }
    },
  } as Rules,
  rentStatus: [{required: true, message: '出租状态不能为空'}],
  floorHead: [{required: true, message: '总高不能为空'}],
  topFloor: [{required: true, message: '总高不能为空'}],
  lift: [{required: true, message: '请选择有无电梯'}],
  cityCode: [{required: true, message: '请选择城市'}],
  condoAddress: [{required: true, message: '详细地址不能为空'}],
  districtCode: [{required: true, message: '请选择行政区域'}],
  bizCircleCode: [{required: true, message: '请选择商圈'}],

  extTemplateDTOS: {
    type: 'array',
    required: true,
    defaultField: {
      type: 'object',
      required: true,
      message: '模板信息不能为空',
      fields: {
        templateName: multiRules.templateName,
        bedroom: multiRules.bedroom,
        floorage: multiRules.floorage,
        deposit: multiRules.deposit,
        rentPrice: multiRules.rentPrice,
        rentPayType: multiRules.rentPayType,
      },
    },
  } as Rules,

  propertyDTOS: {
    type: 'array',
    required: true,
    defaultField: {
      type: 'object',
      required: true,
      message: '房间设置不能为空',
      fields: {
        houseNo: multiRules.houseNo,
        templateName: [{...multiRules.templateName[0], message: '请为所有房间选择模板'}, multiRules.templateName[1]],
        rentPrice: multiRules.rentPrice,
      },
    },
  } as Rules,

  // 发布房源
  buildingNo: [
    {required: true, message: '请输入栋/楼号'},
    {max: 6, message: '栋/楼号不能超过6个字符'},
  ],
  unitNo: [
    {required: true, message: '请输入单元号'},
    {max: 6, message: '单元号不能超过6个字符'},
  ],
  roomInfoList: {
    validator(_, value: JointPropertyRoomDTO[]) {
      return value.every(item => item.rentPrice)
    },
    message: '请输入租金',
  },

  // 租客登记
  contractType: [{required: true, message: '请选择合同类型'}],
  lesseeMobile: [
    {required: true, message: '请输入承租人手机号'},
    {pattern: mobileReg, message: '手机号码不正确'},
  ],
  lesseeOrganName: [{required: true, message: '公司名称不能为空'}],
  lesseeOrganCode: [{required: true, message: '公司营业执照不能为空'}],
  lesseeType: [{required: true, message: '承租身份不能为空'}],
  lesseeCertName: [{required: true, message: '请输入承租人姓名'}],
  lesseeCertNo: [
    {
      required: true,
      async validator(_, value) {
        if (!(certNoReg.test(value) || (/^\d+\*+/.test(value) && value.length > 10))) {
          throw new Error('身份证号码不正确')
        }
      },
    },
  ],
  contractBegin: [{required: true, message: '租约起始日期不能为空'}],
  contractEnd: [{required: true, message: '租约结束日期不能为空'}],

  //添加编辑入住人
  checkinTime: [{required: true, message: '入住时间不能为空'}],
  name: [{required: true, message: '姓名不能为空'}],
  gender: [{required: true, message: '性别不能为空'}],
  certNo: [{pattern: certNoReg, required: true, message: '身份证号码不正确'}],
  mobile: [{pattern: mobileReg, required: true, message: '手机号码不正确'}],
  verifyCode: [{required: true, len: 6, message: '请输入6位验证码'}],
  emergPersonMobile: [{pattern: mobileReg, message: '手机号码不正确'}],
  // 租约账单确认收款
  billPayWayCode: [{required: true, message: '收款方式不能为空'}],
  billPayTime: [{required: true, message: '收款日期不能为空'}],

  // 租约退房
  rentableTime: [{required: true, message: '预计可入住时间不能为空'}],
  checkOutReason: [{required: true, message: '退房原因不能为空'}],
  checkOutRemark: [{required: true, message: '退房备注不能为空'}],
  // 租约结账
  billEndDate: [{required: true, message: '财务终止日期不能为空'}],
  rfdPayWayCode: [{required: true, message: '退款途径不能为空'}],
  // 租约结账添加退还
  returnBillTypeCode: [{required: true, message: '账单不能为空'}],
  returnCheckOutAmt: [{required: true, message: '退还金额不能为空'}],
  // 租约结账添加扣款
  cutBillTypeCode: [{required: true, message: '账单不能为空'}],
  cutCheckOutAmt: [{required: true, message: ' 扣款金额不能为空'}],
  // 租约账单
  billTypeName: [{required: true, message: '账单项目不能为空'}],
  billAmtTotal: [{required: true, message: '收款金额不能为空'}],
  billIntervalList: [{required: true, message: '所属账期不能为空'}],

  billCategory: [{required: true, message: '账单项目不能为空'}],
  billDate: [{required: true, message: '应收款日不能为空'}],
  billPeriod: [{required: true, message: '所属账期不能为空'}],

  // 租约账单作废
  verificateCode: [
    {required: true, message: '请输入交易码'},
    {pattern: verificateCodeReg, message: '请输入6位数字交易码'},
  ],

  // 意见反馈
  feedbackType: {required: true, message: '反馈类型不能为空'},
  content: {required: true, message: '反馈内容不能为空'},

  // 佣金
  brokerage: [
    {
      required: true,
      validator: async (_, value) => {
        if (!(value >= 0)) {
          throw new Error('佣金不能小于0')
        }
      },
    },
  ],

  required(message = '该项不能为空') {
    return [{required: true, message}]
  },
}

export function isIdCard(code) {
  const city = {
    11: '北京',
    12: '天津',
    13: '河北',
    14: '山西',
    15: '内蒙古',
    21: '辽宁',
    22: '吉林',
    23: '黑龙江 ',
    31: '上海',
    32: '江苏',
    33: '浙江',
    34: '安徽',
    35: '福建',
    36: '江西',
    37: '山东',
    41: '河南',
    42: '湖北 ',
    43: '湖南',
    44: '广东',
    45: '广西',
    46: '海南',
    50: '重庆',
    51: '四川',
    52: '贵州',
    53: '云南',
    54: '西藏 ',
    61: '陕西',
    62: '甘肃',
    63: '青海',
    64: '宁夏',
    65: '新疆',
    71: '台湾',
    81: '香港',
    82: '澳门',
    91: '国外 ',
  }
  let tip = ''
  let pass = true
  //验证身份证格式（6个地区编码，8位出生日期，3位顺序号，1位校验位）
  if (!code || !/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i.test(code)) {
    tip = '身份证号格式错误'
    pass = false
  } else if (!city[code.substr(0, 2)]) {
    tip = '地址编码错误'
    pass = false
  } else {
    //18位身份证需要验证最后一位校验位
    if (code.length === 18) {
      const codeArr = code.split('')
      //∑(ai×Wi)(mod 11)
      //加权因子
      const factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
      //校验位
      const parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2]
      let sum = 0
      let ai = 0
      let wi = 0
      for (let i = 0; i < 17; i++) {
        ai = codeArr[i]
        wi = factor[i]
        sum += ai * wi
      }
      // var last = parity[sum % 11]
      if (parity[sum % 11] !== codeArr[17]) {
        tip = '校验位错误'
        pass = false
      }
    }
  }
  if (!pass) console.log(tip)
  return pass
}
