import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Button, Col, Form, Input, message, Row, Select, Space, Steps, Table, Tabs} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import contractDetailStyles from '../ContractDetail/index.module.less'
import styles from './index.module.less'
import {InfoCircleOutlined /*, ExclamationCircleFilled*/} from '@ant-design/icons'
import {add, toFixed} from '@/utils/amount'
import ContractCascader from '@/pages/contract/components/ContractCascader'
import {contractModel} from '@/store/models/Contract'
import {useCodeSync} from '@/store/models/Common'
import {delay, getImg, isFalsy} from '@/utils/util'
import {Album} from '@/ui/components/Album'
import dayjs from 'dayjs'
import {rules} from '@/utils/validate'
import {propertyModel} from '@/store/models/Property'
import {useProjectConfig} from '@/common/bizHooks/useIsProjectCondo'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {deviceModel} from '@/store/models/Device'

const lesseeType = [
  {label: '个人承租', value: 'person'},
  {label: '企业承租', value: 'organ'},
]

interface ContractPropertyChangeDTONew extends ContractPropertyChangeDTO {
  propertyTitleNew: string
}

const WalletBalanceTable = React.memo((props: {dataSource: DeviceV2WalletVO[]}) => {
  const {dataSource} = props
  return (
    <Table
      style={{marginBottom: 30}}
      rowKey={'propertyCode'}
      dataSource={dataSource}
      columns={[
        {title: '退费类型', width: 300, render: () => '水电余额'},
        {title: '额度', dataIndex: 'balance', width: 300},
      ]}
      pagination={false}
    />
  )
})

export default function PropertyChange() {
  const location = useAppLocation<'/contract/property-change'>()
  const history = useAppHistory()
  const contractId = location.state?.contractId
  const communityCode = location.state?.communityCode
  const feeTag = location.state?.feeTag

  const [current, setCurrent] = useState(0)

  const contractType = useCodeSync('contract.type')
  const gender = useCodeSync('gender')
  const contractCertType = useCodeSync('contract.certType')
  const rentCollectType = useCodeSync('rent.collect.type')

  const [form] = Form.useForm()
  useEffect(() => {
    form.setFieldsValue({contractIdOrigin: contractId})
  }, [contractId, form])
  // const [property, setProperty] = useState({contractIdOrigin: contractId} as ContractPropertyChangeDTONew)
  const [detail, setDetail] = useState({} as ContractVO)
  const [calculate, setCalculate] = useState({newBillList: [], refundBillList: []} as ContractPropertyChangeCalculateVO)
  const [checkinList, setCheckinList] = useState<ContractPersonCheckinVO[]>([])
  const [checkOutDetail, setCheckOutDetail] = useState({
    debtBillList: [],
    refundBillList: [],
  } as ContractCheckOutDetailVO)

  const debTotal = useMemo(() => checkOutDetail.debtBillList.reduce((prev, item) => add(prev, item.checkOutAmt), 0), [
    checkOutDetail.debtBillList,
  ])
  const refundTotal = useMemo(
    () => checkOutDetail.refundBillList.reduce((prev, item) => add(prev, item.checkOutAmt), 0),
    [checkOutDetail.refundBillList]
  )

  useEffect(() => {
    contractModel.contractQueryContract({contractId}).then(({response: {data}}) => setDetail(data))
    contractModel.contractCheckinPersonList({contractId}).then(({response: {data}}) => {
      setCheckinList(data)
    })
  }, [contractId])

  const [projectConfig, isProjectCondoType] = useProjectConfig({
    rentType: detail.rentType,
    propertyCode: detail.propertyCode,
  })
  const condoProject: CondoProjectVO = projectConfig.project

  // const [condoProject, setCondoProject] = useState<CondoProjectVO>(null)
  // useEffect(() => {
  //   // 只有公寓才有项目配置
  //   if (detail.rentType === '3') {
  //     propertyModel
  //       .condoProjectGetProjectByPropertyCode({propertyCode: detail.propertyCode})
  //       .then(({response: {data}}) => setCondoProject(data))
  //   }
  // }, [detail.rentType, detail.propertyCode])

  const [chartList, setChartList] = useState<PropertyChartVO[]>([])

  useEffect(() => {
    if (communityCode) {
      propertyModel
        .propertyPropertyChartList({communityCode, pageable: false, canMakeContract: true})
        .then(({response: {data}}) => setChartList(data))
    }
  }, [communityCode])

  const [walletBalance, setWalletBalance] = useState({} as DeviceV2WalletVO)

  useEffect(() => {
    // 水电余额
    if (isProjectCondoType) {
      deviceModel.deviceV2MeterWalletBalance({propertyCode: detail.propertyCode}).then(({response: {data}}) => {
        setWalletBalance(data)
      })
    }
  }, [detail.propertyCode, isProjectCondoType])

  const checkOutLoading = contractModel.contractCheckOutDetail.useLoading()
  const changeConfirmLoading = contractModel.contractPropertyChangeConfirm.useLoading()
  const changeCalculateLoading = contractModel.contractPropertyChangeCalculate.useLoading()

  const handle = useCallback(() => {
    const property: ContractPropertyChangeDTONew = form.getFieldsValue(true)
    switch (current) {
      case 0:
        return (
          <Button
            type={'primary'}
            loading={checkOutLoading}
            onClick={async () => {
              const {
                response: {data},
              } = await contractModel.contractCheckOutDetail({
                contractId,
                billEndDate: dayjs().format('YYYY-MM-DD'),
                checkOutReason: '',
                fetchMeterWallet: false,
              })
              setCheckOutDetail(data)
              setCurrent(prev => prev + 1)
            }}
            disabled={!property.propertyCodeNew}
          >
            下一步
          </Button>
        )
      case 1:
        return (
          <>
            <Button onClick={() => setCurrent(prev => prev - 1)}>上一步</Button>
            <Button
              type={'primary'}
              disabled={!!checkOutDetail.debtBillList?.length}
              onClick={async () => {
                setCurrent(prev => prev + 1)
              }}
            >
              下一步
            </Button>
          </>
        )
      case 2:
        return (
          <>
            <Button onClick={() => setCurrent(prev => prev - 1)}>上一步</Button>
            <Button
              type={'primary'}
              loading={changeCalculateLoading}
              disabled={isFalsy(property.rentPrice) || isFalsy(property.rentPledge)}
              onClick={async () => {
                await form.validateFields(['rentPledge', 'rentPrice'])
                const {
                  response: {data},
                } = await contractModel.contractPropertyChangeCalculate({
                  ...property,
                  roomChangeFeeTag: feeTag === '1' ? false : !!condoProject,
                  condoProjectTag: !!condoProject,
                })
                setCalculate(data)
                setCurrent(prev => prev + 1)
              }}
            >
              下一步
            </Button>
          </>
        )
      case 3:
        return (
          <>
            <Button onClick={() => setCurrent(prev => prev - 1)}>上一步</Button>
            <Button
              type={'primary'}
              loading={changeConfirmLoading}
              disabled={
                calculate.newTransferAmtTotal !== calculate.refundCheckoutAmtTotal ||
                calculate.newTransferAmtTotal !== calculate.refundTransferAmtTotal
              }
              onClick={async () => {
                // feeTag === '1' 免收审核通过
                // !!condoProject 是项目管理的公寓收费，否则不收费
                await contractModel.contractPropertyChangeConfirm({
                  ...property,
                  roomChangeFeeTag: feeTag === '1' ? false : !!condoProject,
                  condoProjectTag: !!condoProject,
                })
                message.success('操作成功！')
                await delay()
                history.replace('/chart')
              }}
            >
              确认换房
            </Button>
          </>
        )
    }
  }, [
    calculate.newTransferAmtTotal,
    calculate.refundCheckoutAmtTotal,
    calculate.refundTransferAmtTotal,
    changeCalculateLoading,
    changeConfirmLoading,
    checkOutDetail.debtBillList?.length,
    checkOutLoading,
    condoProject,
    contractId,
    current,
    feeTag,
    form,
    history,
  ])

  const renderCollectType = (vo: ContractVO) => {
    const latest = vo?.contractDetailList?.[vo?.contractDetailList?.length - 1]
    const type = latest?.rentCollectType
    const day = latest?.rentCollectDay
    if (!type) return null

    const typeMap = {'1': '提前天数', '2': '每月'}
    const typeUnitMap = {'1': '天', '2': '日'}

    return (
      <>
        <Col span={9}>
          <Form.Item label={'收租类型'}>{rentCollectType[type]}</Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item label={typeMap[type]}>{`${day}${typeUnitMap[type]}`}</Form.Item>
        </Col>
      </>
    )
  }

  return (
    <>
      <div className={commonStyles.section}>
        <Steps
          current={current}
          labelPlacement={'vertical'}
          style={{padding: '0 149px'}}
          // onChange={current1 => setCurrent(current1)}
        >
          <Steps.Step title='新房选择' />
          <Steps.Step title='原房清算' />
          <Steps.Step title='新房租约' />
          <Steps.Step title='换房完成' />
        </Steps>
      </div>

      <Form form={form}>
        <div className={commonStyles.flexFullSection}>
          <div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
            <Tabs activeKey={`${current}`} renderTabBar={() => null}>
              <Tabs.TabPane tab='新房选择' key='0'>
                <div className={styles.row}>
                  <h4 className={`${commonStyles.t4WithBar} ${styles.title}`}>房间信息</h4>
                  <Row gutter={12}>
                    <Col span={9}>
                      <Form.Item label={'房源地址'}>{detail.propertyTitle}</Form.Item>
                    </Col>
                    <Col span={9}>
                      <Form.Item label={'承租人'}>{`${detail.lesseeCertName}-${detail.lesseeMobile}`}</Form.Item>
                    </Col>
                    <Col span={9}>
                      <Form.Item label={'租约起止'}>
                        {`${detail.contractBegin ?? ''}至${detail.contractEnd ?? ''}`}
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <div className={styles.row}>
                  <h4 className={`${commonStyles.t4WithBar} ${styles.title}`}>详细地址</h4>
                  <Row gutter={12}>
                    <Col span={24}>
                      {communityCode ? (
                        <Form.Item label={'房源地址'}>
                          <Select
                            placeholder={'请选择房源地址'}
                            onChange={(_, option: any) => {
                              form.setFieldsValue({
                                propertyCodeNew: option?.value,
                                propertyTitleNew: option?.label,
                                rentPrice: option?.rentPrice,
                                rentPledge: option?.rentPledge,
                              })
                            }}
                            style={{width: 440}}
                            options={chartList.map(value => ({
                              label: value.title,
                              value: value.propertyCode,
                              rentPrice: value.rentPrice,
                              rentPledge: value.rentPledge,
                            }))}
                          />
                        </Form.Item>
                      ) : (
                        <Form.Item label={'房源地址'}>
                          <ContractCascader
                            placeholder={'请选择房源地址'}
                            onChangeValue={(_, [community, vo]) => {
                              form.setFieldsValue({
                                propertyCodeNew: vo?.propertyCode,
                                propertyTitleNew: vo?.title,
                                rentPrice: vo?.rentPrice,
                                rentPledge: vo?.rentPledge,
                              })
                            }}
                          />
                        </Form.Item>
                      )}
                    </Col>
                    <Col span={9}>
                      <Form.Item label={'承租人'}>{`${detail.lesseeCertName}-${detail.lesseeMobile}`}</Form.Item>
                    </Col>
                  </Row>
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane tab='原房清算' key='1'>
                <Space size={21} className={styles.title}>
                  <h4 className={commonStyles.t4}>应付款（{toFixed(debTotal)}元）</h4>
                  <Space size={5}>
                    <InfoCircleOutlined style={{color: '#FAAD14'}} />
                    <span>应付款为0时才可进行换房</span>
                  </Space>
                </Space>
                <Table
                  rowKey={'billId'}
                  style={{marginBottom: 30}}
                  dataSource={checkOutDetail.debtBillList}
                  columns={[
                    {
                      title: '账单类型',
                      dataIndex: 'billTypeName',
                      key: 'billTypeName',
                      width: 300,
                    },
                    {
                      title: '账单金额',
                      dataIndex: 'checkOutAmt',
                      key: 'checkOutAmt',
                      width: 300,
                      render: value => toFixed(value),
                    },
                    {
                      title: '应付款日',
                      dataIndex: 'billDate',
                      key: 'billDate',
                    },
                  ]}
                  pagination={false}
                />

                <Space size={21} className={styles.title}>
                  <h4 className={commonStyles.t4}>退款费用（{toFixed(refundTotal)}元）</h4>
                  {/*<Space size={5}>
                    <InfoCircleOutlined style={{color: '#FAAD14'}} />
                    <span>退款账单</span>
                  </Space>*/}
                </Space>
                <Table
                  style={{marginBottom: 30}}
                  rowKey={'billId'}
                  dataSource={checkOutDetail.refundBillList}
                  columns={[
                    {
                      title: '账单类型',
                      dataIndex: 'billTypeName',
                      key: 'billTypeName',
                      width: 300,
                    },
                    {
                      title: '账单金额',
                      dataIndex: 'checkOutAmt',
                      key: 'checkOutAmt',
                      width: 300,
                      render: value => toFixed(value),
                    },
                    {
                      title: '应付款日/账单周期',
                      dataIndex: 'billDate',
                      key: 'billDate',
                      render: (text, record) =>
                        [record.billEnd, record.billBegin].every(value => value)
                          ? `${record.billBegin}至${record.billEnd}`
                          : text,
                    },
                  ]}
                  pagination={false}
                />
                {isProjectCondoType && <WalletBalanceTable dataSource={[walletBalance]} />}
              </Tabs.TabPane>
              <Tabs.TabPane tab='新房租约' key='2'>
                <div className={styles.row}>
                  <h4 className={`${commonStyles.t4WithBar} ${styles.title}`}>基本信息</h4>
                  <Row gutter={12}>
                    <Col span={9}>
                      <Form.Item label={'房源标题'} shouldUpdate>
                        {f => f.getFieldValue('propertyTitleNew')}
                      </Form.Item>
                    </Col>
                    <Col span={9}>
                      <Form.Item label={'合同类型'}>{contractType[detail.contractType]}</Form.Item>
                    </Col>
                  </Row>
                </div>
                <div className={styles.row}>
                  <h4 className={`${commonStyles.t4WithBar} ${styles.title}`}>承租人信息</h4>
                  <Row gutter={12}>
                    <Col span={9}>
                      <Form.Item label={'承租身份'}>
                        {lesseeType.find(value => value.value === detail.lesseeType)?.label}
                      </Form.Item>
                    </Col>
                    <Col span={9}>
                      <Form.Item label={'承租人手机号'}>{detail.lesseeMobile}</Form.Item>
                    </Col>
                    <Col span={9}>
                      <Form.Item label={'承租人姓名'}>{detail.lesseeCertName}</Form.Item>
                    </Col>
                    <Col span={9}>
                      <Form.Item label={'承租人性别'}>{gender[detail.lesseeGender]}</Form.Item>
                    </Col>
                    <Col span={9}>
                      <Form.Item label={'证件类型'}>{contractCertType[detail.lesseeCertType]}</Form.Item>
                    </Col>
                    <Col span={9}>
                      <Form.Item label={'证件号码'}>{detail.lesseeCertNo}</Form.Item>
                    </Col>
                    <Col span={9}>
                      <Form.Item label={'证件照片'}>
                        <Space>
                          {!!detail.lesseeCertFront && (
                            <div className={contractDetailStyles.lesseeCert}>
                              <Album
                                disabled
                                fileList={[{uid: 'lesseeCertFront', url: getImg(detail.lesseeCertFront)}]}
                              />
                            </div>
                          )}
                          {!!detail.lesseeCertBack && (
                            <div className={contractDetailStyles.lesseeCert}>
                              <Album
                                disabled
                                fileList={[{uid: 'lesseeCertBack', url: getImg(detail.lesseeCertBack)}]}
                              />
                            </div>
                          )}
                          {!!detail.lesseeCertHold && (
                            <div className={contractDetailStyles.lesseeCert}>
                              <Album
                                disabled
                                fileList={[{uid: 'lesseeCertHold', url: getImg(detail.lesseeCertHold)}]}
                              />
                            </div>
                          )}
                        </Space>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <div className={styles.row}>
                  <h4 className={`${commonStyles.t4WithBar} ${styles.title}`}>入住人信息</h4>
                  <span>入住信息当前在住{checkinList.length}人</span>
                  <Table
                    rowKey={'personId'}
                    style={{marginTop: 15}}
                    size={'middle'}
                    dataSource={checkinList}
                    columns={[
                      {title: '入住人', dataIndex: 'name', key: 'name'},
                      {
                        title: '性别',
                        dataIndex: 'gender',
                        key: 'gender',
                        render: text => gender[text],
                      },
                      {title: '电话', dataIndex: 'mobile', key: 'mobile'},
                      {
                        title: '证件类型',
                        dataIndex: 'certType',
                        key: 'certType',
                        render: text => contractCertType[text],
                      },
                      {title: '证件号码', dataIndex: 'certNo', key: 'certNo'},
                      {title: '入住时间', dataIndex: 'checkinTime', key: 'checkinTime'},
                    ]}
                    pagination={false}
                  />
                </div>
                <div className={styles.row}>
                  <h4 className={`${commonStyles.t4WithBar} ${styles.title}`}>房源信息</h4>
                  <Row gutter={12}>
                    <Col span={9}>
                      <Form.Item label={'房屋租金'} required>
                        <Space>
                          <Form.Item noStyle name={'rentPrice'} rules={rules.number}>
                            <Input allowClear type={'number'} style={{width: 210}} disabled={isProjectCondoType} />
                          </Form.Item>
                          <span>元/月</span>
                        </Space>
                      </Form.Item>
                    </Col>
                    <Col span={9}>
                      <Form.Item label={'房屋押金'} required>
                        <Space>
                          <Form.Item noStyle name={'rentPledge'} rules={rules.rentPledge}>
                            <Input allowClear type={'number'} style={{width: 210}} disabled={isProjectCondoType} />
                          </Form.Item>
                          <span>元/月</span>
                        </Space>
                      </Form.Item>
                    </Col>
                    <Col span={9}>
                      <Form.Item label={'租约起止'}>{`${detail.contractBegin ?? ''}至${
                        detail.contractEnd ?? ''
                      }`}</Form.Item>
                    </Col>
                    <Col span={9}>
                      <Form.Item label={'付款方式'}>{`付${detail?.rentPayType ?? ''}押${
                        detail.rentPledgeType === 0 ? '自定义' : detail.rentPledgeType
                      }`}</Form.Item>
                    </Col>
                    {renderCollectType(detail)}
                    <Col span={9}>
                      <Form.Item label={'房东手机号'}>{detail.lessorMobile}</Form.Item>
                    </Col>
                    {!!condoProject && (
                      <Col span={9}>
                        <Form.Item label={'换房手续费'}>{feeTag === '1' ? 0 : condoProject.roomChangeFee}</Form.Item>
                      </Col>
                    )}
                  </Row>
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane tab='换房完成' key='3'>
                <h4 className={commonStyles.t4}>结转对比</h4>
                <Row gutter={20}>
                  <Col span={12}>
                    <div className={styles.tableTitle}>原房间</div>
                    {isProjectCondoType && <WalletBalanceTable dataSource={[walletBalance]} />}
                    <Table
                      // todo: rowKey deprecated
                      rowKey={(_, index) => index}
                      dataSource={calculate.refundBillList}
                      columns={[
                        {title: '账单类型', dataIndex: 'billTypeName', key: 'billTypeName'},
                        {
                          title: '退款金额',
                          dataIndex: 'checkOutAmt',
                          key: 'checkOutAmt',
                          render: text => toFixed(text),
                        },
                        {
                          title: '转出金额',
                          dataIndex: 'transferAmt',
                          key: 'transferAmt',
                          render: text => toFixed(text),
                        },
                      ]}
                      footer={() => (
                        <div className={styles.between}>
                          <span>总计转出金额</span>
                          <span>{`¥ ${calculate.refundTransferAmtTotal}`}</span>
                        </div>
                      )}
                      pagination={false}
                    />
                  </Col>
                  <Col span={12}>
                    <div className={styles.tableTitle}>新房间</div>
                    {isProjectCondoType && <WalletBalanceTable dataSource={[walletBalance]} />}
                    <Table
                      // todo: rowKey deprecated
                      rowKey={(_, index) => index}
                      dataSource={calculate.newBillList}
                      columns={[
                        {title: '账单类型', dataIndex: 'billTypeName', key: 'billTypeName'},
                        {title: '应收款日', dataIndex: 'billDate', key: 'billDate'},
                        {
                          title: '转入金额',
                          dataIndex: 'transferAmt',
                          key: 'transferAmt',
                          render: text => toFixed(text),
                        },
                      ]}
                      footer={() => (
                        <div className={styles.between}>
                          <span>总计转入金额</span>
                          <span>{`¥ ${calculate.newTransferAmtTotal}`}</span>
                        </div>
                      )}
                      pagination={false}
                    />
                  </Col>
                </Row>
                {/*<Space size={5} style={{marginTop: 20}}>
                  <ExclamationCircleFilled style={{color: '#FAAD14'}} />
                  <span>提示：结转收款</span>
                </Space>*/}
              </Tabs.TabPane>
            </Tabs>
          </div>
          <div className={styles.bottom}>
            <Form.Item noStyle shouldUpdate>
              {() => <Space size={12}>{handle()}</Space>}
            </Form.Item>
          </div>
        </div>
      </Form>
    </>
  )
}
