import React, {useCallback, useEffect, useState} from 'react'
import {Badge, Button, Modal, Space, Table, Tabs, Typography} from 'antd'
import {deviceModel} from '@/store/models/Device'
import dayjs from 'dayjs'
import {useIsProjectCondo} from '@/common/bizHooks/useIsProjectCondo'
import {propertyModel} from '@/store/models/Property'
import {useAppHistory} from '@/common/hooks/useRouter'

export default function NygDeviceAbnormal() {
  const history = useAppHistory()
  const isProjectCondo = useIsProjectCondo()
  const projects = propertyModel.useData(data => data.projects)

  const [visible, setVisible] = useState(false)
  const [tabKey, setTabKey] = useState('1')

  const [elePageNum, setElePageNum] = useState(1)
  const [elePageSize, setElePageSize] = useState(10)
  const [eleMeterTotal, setEleMeterTotal] = useState(0)
  const [eleMeterList, setEleMeterList] = useState<EleMeter[]>([])

  const [waterPageNum, setWaterPageNum] = useState(1)
  const [waterPageSize, setWaterPageSize] = useState(10)
  const [waterMeterTotal, setWaterMeterTotal] = useState(0)
  const [waterMeterList, setWaterMeterList] = useState<WaterMeter[]>([])

  // 待优化：分页会导致重复请求
  const queryMeterList = useCallback(async () => {
    const syncEleTimeEnd = dayjs().add(-1, 'hour').format('YYYY-MM-DD HH:mm:ss')
    const syncWaterTimeEnd = dayjs().add(-6, 'hour').format('YYYY-MM-DD HH:mm:ss')

    // 电表
    const {
      response: {data: data1, total: total1},
    } = await deviceModel.deviceV2EleMeterList({
      pageNum: elePageNum,
      pageSize: elePageSize,
      pageable: true,
      syncTimeEnd: syncEleTimeEnd,
    } as DeviceV2ListDTO)
    setEleMeterTotal(total1)
    setEleMeterList(data1)

    // 水表
    const {
      response: {data: data2, total: total2},
    } = await deviceModel.deviceV2WaterMeterList({
      pageNum: waterPageNum,
      pageSize: waterPageSize,
      pageable: true,
      syncTimeEnd: syncWaterTimeEnd,
    } as DeviceV2ListDTO)
    setWaterMeterTotal(total2)
    setWaterMeterList(data2)

    setVisible(total1 > 0 || total2 > 0)
  }, [elePageNum, elePageSize, waterPageNum, waterPageSize])

  useEffect(() => {
    if (isProjectCondo) {
      queryMeterList()
    }
  }, [isProjectCondo, queryMeterList])

  return (
    <Modal
      title={
        <Space>
          <Typography.Title level={3} style={{marginBottom: 0}}>
            设备异常提醒
          </Typography.Title>
          <Typography.Text style={{color: 'rgba(0,0,0,0.65)', fontSize: '14px', fontWeight: 'normal'}}>
            {`（超过${tabKey === '1' ? 1 : 6}小时无数据返回）`}
          </Typography.Text>
        </Space>
      }
      centered
      visible={visible}
      footer={null}
      width={'875px'}
      onCancel={() => setVisible(false)}
    >
      <Typography.Title level={4}>项目信息：{projects?.[0]?.projectName}</Typography.Title>
      <Tabs onChange={activeKey => setTabKey(activeKey)}>
        <Tabs.TabPane
          tab={
            <Badge count={eleMeterTotal} offset={[10, 0]}>
              电表
            </Badge>
          }
          key={'1'}
        >
          <Table
            rowKey={'deviceId'}
            columns={[
              {
                title: '设备名称/ID',
                dataIndex: 'deviceId',
                render: value => (
                  <span style={{width: '150px', display: 'inline-block', wordWrap: 'break-word'}}>{value}</span>
                ),
              },
              {title: '房源地址', dataIndex: 'propertyTitle'},
              {title: '承租人', dataIndex: 'lesseeName', render: value => value ?? '/'},
              {title: '承租人手机号', dataIndex: 'lesseeMobile', render: value => value ?? '/'},
              {title: '最后一次数据返回时间', dataIndex: 'syncTime'},
              {
                title: '操作',
                dataIndex: 'deviceId',
                render: deviceId => {
                  return (
                    <Button
                      type={'link'}
                      style={{padding: 0}}
                      onClick={() => {
                        history.push('/nyg-device-electric/detail', {deviceId})
                      }}
                    >
                      查看
                    </Button>
                  )
                },
              },
            ]}
            dataSource={eleMeterList}
            pagination={{
              total: eleMeterTotal,
              pageSize: elePageSize,
              current: elePageSize,
              position: ['bottomCenter'],
              hideOnSinglePage: false,
              showSizeChanger: true,
              onChange: (pageNum, pageSize) => {
                setElePageNum(pageNum)
                setElePageSize(pageSize)
              },
            }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <Badge count={waterMeterTotal} offset={[10, 0]}>
              水表
            </Badge>
          }
          key={'2'}
        >
          <Table
            rowKey={'deviceId'}
            columns={[
              {
                title: '设备名称/ID',
                dataIndex: 'deviceId',
                render: value => (
                  <span style={{width: '150px', display: 'inline-block', wordWrap: 'break-word'}}>{value}</span>
                ),
              },
              {title: '房源地址', dataIndex: 'propertyTitle'},
              {title: '承租人', dataIndex: 'lesseeName'},
              {title: '承租人手机号', dataIndex: 'lesseeMobile'},
              {title: '最后一次数据返回时间', dataIndex: 'syncTime'},
              {
                title: '操作',
                dataIndex: 'deviceId',
                render: deviceId => {
                  return (
                    <Button
                      type={'link'}
                      style={{padding: 0}}
                      onClick={() => history.push('/nyg-device-water/detail', {deviceId})}
                    >
                      查看
                    </Button>
                  )
                },
              },
            ]}
            dataSource={waterMeterList}
            pagination={{
              total: waterMeterTotal,
              pageSize: waterPageSize,
              current: waterPageNum,
              position: ['bottomCenter'],
              hideOnSinglePage: false,
              showSizeChanger: true,
              onChange: (pageNum, pageSize) => {
                setWaterPageNum(pageNum)
                setWaterPageSize(pageSize)
              },
            }}
          />
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  )
}
