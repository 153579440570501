import React, {useEffect, useRef, useState} from 'react'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import {Button, Input, Modal, Table, Tabs, Typography, Form, Space, Row, Col, message} from 'antd'
import styles from './index.module.less'
import {PlusOutlined} from '@ant-design/icons/lib/icons'
import {AddItem} from '@/ui/components/AddItem/index'
import EditModal from '@/pages/setting/components/EditModel'
import {contractModel} from '@/store/models/Contract'

const {TabPane} = Tabs
const {Text} = Typography
export default function PropertyDelivery() {
  const [addVisible, setAddVisible] = useState(false)
  const [form] = Form.useForm()
  const formRef = useRef(null)
  const [rentType, setRentType] = useState('2')
  //房间物品
  const [equipmentList, setEquipmentList] = useState([] as ContractEquipmentItemDTO[])
  //公区物品
  const [equipmentPublicList, setEquipmentPublicList] = useState([] as ContractEquipmentItemDTO[])
  const editModalRef = useRef(null)
  //模板名称
  const [deleteVisible, setDeleteVisible] = useState(false)
  //模板Id
  const [templateId, setTemplateId] = useState('')
  //合租模板列表
  const [jointTemplateList, setJointTemplateList] = useState([] as ContractEquipmentConfigVO[])
  const [total1, setTotal1] = useState(0)
  //整租模板列表
  const [entireTemplateList, setEntireTemplateList] = useState([] as ContractEquipmentConfigVO[])
  const [total2, setTotal2] = useState(0)
  //页数
  const [pageNum1, setPageNum1] = useState(1)
  const [pageNum2, setPageNum2] = useState(1)
  //添加的是否为房间物品
  const [isSelf, setIsSelf] = useState(true)

  //查看或编辑物品
  function edit(item, index) {
    editModalRef.current.onDisplay(item, index)
  }
  const submitLoading = contractModel.contractEquipmentConfigSave.useLoading()

  //查询合租模板列表
  function queryJointTemplateList(pageNum) {
    contractModel
      .contractEquipmentConfigList({pageNum, pageSize: 10, pageable: true, rentType: '2'})
      .then(({response: {data, total}}) => {
        setJointTemplateList(data)
        setTotal1(total)
      })
  }

  //查询整租模板列表
  function queryEntireTemplateList(pageNum) {
    contractModel
      .contractEquipmentConfigList({pageNum, pageSize: 10, pageable: true, rentType: '1'})
      .then(({response: {data, total}}) => {
        setEntireTemplateList(data)
        setTotal2(total)
      })
  }

  useEffect(() => {
    queryJointTemplateList(pageNum1)
    queryEntireTemplateList(pageNum2)
  }, [pageNum1, pageNum2])
  const loading = contractModel.contractEquipmentConfigList.useLoading()

  //保存
  function onFinish() {
    const {templateName} = form.getFieldsValue()
    if (!equipmentList.length) {
      message.warn('请添加房间物品')
      return
    } else if (!equipmentList.every(item => item.code)) {
      message.warn('请选择物品')
      return
    }
    if (equipmentPublicList.length && !equipmentPublicList.every(item => item.code)) {
      message.warn('请选择物品')
      return
    }
    contractModel
      .contractEquipmentConfigSave({
        templateId: templateId,
        templateName,
        equipmentList: equipmentList,
        equipmentAmt: equipmentList.length + equipmentPublicList.length,
        rentType,
        equipmentPublicList: equipmentPublicList,
      })
      .then(() => {
        rentType === '1' ? queryEntireTemplateList(pageNum2) : queryJointTemplateList(pageNum1)
        setAddVisible(false)
      })
    formRef.current.resetFields()
  }

  //查看模板详情
  function queryTemplateInfo(templateId: string) {
    contractModel
      .contractEquipmentConfigInfo(templateId)
      .then(({response: {data}}) => {
        setEquipmentList(data.equipmentList)
        setEquipmentPublicList(data.equipmentPublicList || [])
        form.setFieldsValue({templateName: data.templateName})
      })
      .then(() => {
        setAddVisible(true)
      })
  }

  //删除模板
  function deleteTemplate(templateId) {
    contractModel.contractEquipmentConfigDelete(templateId).then(() => {
      rentType === '1' ? queryEntireTemplateList(pageNum2) : queryJointTemplateList(pageNum1)
      setDeleteVisible(false)
    })
  }

  return (
    <>
      <Breadcrumb
        path={['首页', '设置', '物业交割设置']}
        right={
          <Button
            type={'primary'}
            size={'middle'}
            onClick={() => {
              setAddVisible(true)
              setEquipmentList([])
              setEquipmentPublicList([])
              setTemplateId('')
            }}
          >
            添加模板
          </Button>
        }
      />
      <Tabs defaultActiveKey={'2'} className={styles.tabs} onChange={value => setRentType(value)}>
        <TabPane tab={'合租'} key={'2'}>
          <div className={styles.table}>
            <Table
              loading={loading}
              rowKey={'templateId'}
              title={() => (
                <div>
                  共计<Text style={{color: '#017BFF'}}>{jointTemplateList.length}</Text>条数据
                </div>
              )}
              columns={[
                {title: '模板名称', dataIndex: 'templateName', key: 'templateName'},
                {title: '涉及物品数', dataIndex: 'equipmentAmt', key: 'equipmentAmt'},
                {
                  title: '操作',
                  dataIndex: 'operation',
                  key: 'operation',
                  render: (text, record, index) => (
                    <>
                      <Button
                        type={'text'}
                        style={{color: '#1890FF', padding: 0}}
                        onClick={() => {
                          setTemplateId(record.templateId)
                          queryTemplateInfo(record.templateId)
                        }}
                      >
                        编辑
                      </Button>
                      <Text style={{color: 'rgba(0, 0, 0, 0.09)'}}>|</Text>
                      <Button
                        type={'text'}
                        style={{color: '#1890FF', padding: 0}}
                        onClick={() => {
                          setDeleteVisible(true)
                          setTemplateId(record.templateId)
                        }}
                      >
                        删除
                      </Button>
                    </>
                  ),
                },
              ]}
              dataSource={jointTemplateList}
              pagination={{
                total: total1,
                current: pageNum1,
                defaultCurrent: 1,
                defaultPageSize: 10,
                position: ['bottomCenter'],
                showSizeChanger: false,
                onChange: page => setPageNum1(page),
              }}
            />
          </div>
        </TabPane>
        <TabPane tab={'整租'} key={'1'}>
          <div className={styles.table}>
            <Table
              loading={loading}
              rowKey={'templateId'}
              title={() => (
                <div>
                  共计<Text style={{color: '#017BFF'}}>{entireTemplateList.length}</Text>条数据
                </div>
              )}
              columns={[
                {title: '模板名称', dataIndex: 'templateName', key: 'templateName'},
                {title: '涉及物品数', dataIndex: 'equipmentAmt', key: 'equipmentAmt'},
                {
                  title: '操作',
                  dataIndex: 'operation',
                  key: 'operation',
                  render: (text, record, index) => (
                    <>
                      <Button
                        type={'text'}
                        style={{color: '#1890FF', padding: 0}}
                        onClick={() => {
                          queryTemplateInfo(record.templateId)
                          setTemplateId(record.templateId)
                        }}
                      >
                        编辑
                      </Button>
                      <Text style={{color: 'rgba(0, 0, 0, 0.09)'}}>|</Text>
                      <Button
                        type={'text'}
                        style={{color: '#1890FF', padding: 0}}
                        onClick={() => {
                          setTemplateId(record.templateId)
                          setDeleteVisible(true)
                        }}
                      >
                        删除
                      </Button>
                    </>
                  ),
                },
              ]}
              dataSource={entireTemplateList}
              pagination={{
                total: total2,
                current: pageNum2,
                defaultCurrent: 1,
                defaultPageSize: 10,
                position: ['bottomCenter'],
                showSizeChanger: false,
                onChange: page => setPageNum2(page),
              }}
            />
          </div>
        </TabPane>
      </Tabs>

      {/* 添加模板 */}
      <Modal
        visible={addVisible}
        width={831}
        okText={'保存'}
        title={<div style={{textAlign: 'left'}}>{templateId ? '编辑模板' : '添加模板'}</div>}
        footer={null}
        maskClosable={false}
        onCancel={() => {
          setAddVisible(false)
          formRef.current.resetFields()
        }}
      >
        <div>
          <Form form={form} ref={formRef} onFinish={onFinish}>
            <Form.Item
              label={'模板名称'}
              name={'templateName'}
              rules={[{required: true, message: '请输入模板方案名称'}]}
            >
              <Input placeholder={'请输入模板方案名称'} style={{maxWidth: '216px'}} />
            </Form.Item>
            <div>
              <div style={{marginBottom: '10px'}}>
                <Text style={{color: '#595959', fontWeight: 600}}>房间物品</Text>
                <Button
                  type={'text'}
                  icon={<PlusOutlined />}
                  style={{color: '#017BFF'}}
                  onClick={() => {
                    setEquipmentList(state => [
                      ...state,
                      {
                        amt: 0,
                        attach: '',
                        code: '',
                        model: '',
                        name: '',
                        price: 0,
                        remark: '',
                      },
                    ])
                  }}
                >
                  添加
                </Button>
              </div>
              <Row gutter={[22, 16]}>
                {equipmentList.map((item, index) => (
                  <Col key={index} span={8}>
                    <AddItem
                      title={item.name || '请选择'}
                      onClose={() => {
                        equipmentList.splice(index, 1)
                        setEquipmentList(state => [...state])
                      }}
                      onClick={() => {
                        setIsSelf(true)
                        edit(item, index)
                      }}
                      onChange={value =>
                        setEquipmentList(state =>
                          state.map((_item, _index) => (index === _index ? {..._item, amt: Number(value)} : _item))
                        )
                      }
                      value={item.name ? item.amt : 0}
                    />
                  </Col>
                ))}
              </Row>
              {rentType === '2' ? (
                <>
                  <div style={{marginBottom: '10px'}}>
                    <Text style={{color: '#595959', fontWeight: 600}}>公区物品</Text>
                    <Button
                      type={'text'}
                      icon={<PlusOutlined />}
                      style={{color: '#017BFF'}}
                      onClick={() => {
                        setEquipmentPublicList(state => [
                          ...state,
                          {
                            amt: 0,
                            attach: '',
                            code: '',
                            model: '',
                            name: '',
                            price: 0,
                            remark: '',
                          },
                        ])
                      }}
                    >
                      添加
                    </Button>
                  </div>
                  <Row gutter={[22, 16]}>
                    {equipmentPublicList.map((item, index) => (
                      <Col key={index} span={8}>
                        <AddItem
                          title={item.name || '请选择'}
                          onClose={() => {
                            equipmentPublicList.splice(index, 1)
                            setEquipmentPublicList(state => [...state])
                          }}
                          onClick={() => {
                            setIsSelf(false)
                            edit(item, index)
                          }}
                          onChange={value => {
                            setEquipmentPublicList(state =>
                              state.map((_item, _index) => (index === _index ? {..._item, amt: Number(value)} : _item))
                            )
                          }}
                          value={item.name ? item.amt : 0}
                        />
                      </Col>
                    ))}
                  </Row>
                </>
              ) : null}
            </div>
            <Form.Item style={{marginBottom: '0'}}>
              <div style={{textAlign: 'right', marginTop: '15px'}}>
                <Space>
                  <Button
                    onClick={() => {
                      setAddVisible(false)
                      formRef.current.resetFields()
                    }}
                  >
                    取消
                  </Button>
                  <Button type={'primary'} htmlType={'submit'} loading={submitLoading}>
                    保存
                  </Button>
                </Space>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Modal>

      {/* 删除模板 */}
      <Modal visible={deleteVisible} onCancel={() => setDeleteVisible(false)} footer={null} centered>
        <div>
          <Typography.Title level={3}>提示</Typography.Title>
          <Text>是否删除模板？</Text>
          <div style={{marginTop: '50px', textAlign: 'right'}}>
            <Space>
              <Button onClick={() => setDeleteVisible(false)}>取消</Button>
              <Button type={'primary'} onClick={() => deleteTemplate(templateId)}>
                确认
              </Button>
            </Space>
          </div>
        </div>
      </Modal>

      {/* 编辑弹框 */}
      <EditModal
        ref={editModalRef}
        onSuccess={(item, index) => {
          if (isSelf) {
            setEquipmentList(state => state.map((_item, _index) => (index === _index ? item : _item)))
          } else {
            setEquipmentPublicList(state => state.map((_item, _index) => (index === _index ? item : _item)))
          }
        }}
      />
    </>
  )
}
