import React, {useEffect, useState} from 'react'
import commonStyles from '@/assets/styles/common.module.less'
import {Button, Result} from 'antd'
import {Link, useHistory} from 'react-router-dom'
import {teamModel} from '@/store/models/Team'

export default function AddSuccess() {
  const history = useHistory()
  const {chartType, cerType, cerAuthId} = history.location.state as any

  // 企业认证 审核失败原因
  const [remark, setRemark] = useState('')
  // 企业认证 teamId
  const [teamId, setTeamId] = useState(undefined)

  useEffect(
    function () {
      if (cerAuthId) {
        // 企业认证 审核详情
        teamModel.teamAuthRecordDetail({recordId: cerAuthId}).then(({response: {data}}) => {
          console.log('data =>', data)
          setRemark(data.operatorRemark)
          setTeamId(data.teamId)
        })
      }
    },
    [cerAuthId]
  )

  return (
    <div className={commonStyles.flexFullSection}>
      {/**添加房源*/}
      {chartType && (
        <Result
          status='success'
          title='添加成功'
          subTitle={`您可以在${chartType === 'scatter' ? '分散式' : '集中式'}房态图中查看已经添加的房源信息`}
          extra={[
            <Button type='primary' key={'confirm'}>
              <Link to={'/add'}>确认</Link>
            </Button>,
          ]}
        />
      )}
      {/**企业认证*/}
      {cerType && (
        <Result
          status={cerType === '1' ? 'success' : 'error'}
          title={cerType === '1' ? '提交成功' : '认证失败'}
          subTitle={
            cerType === '1'
              ? '您的企业认证信息已成功提交，请等待我们工作人员的审核'
              : `您提交的认证信息被拒绝，拒绝原因：${remark}`
          }
          extra={[
            <Button
              type='primary'
              key={'confirm'}
              onClick={() => (cerType === '1' ? history.push('/framework') : history.push('/enterprise', {teamId}))}
            >
              {cerType === '1' ? '返回列表' : '重新认证'}
            </Button>,
          ]}
        />
      )}
    </div>
  )
}
