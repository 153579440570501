import {userModel} from '@/store/models/User'

export function findNode(event: any, nodeName: string, className?: string): HTMLElement {
  const node = event.path?.find(value => value.nodeName?.toUpperCase() === nodeName?.toUpperCase())

  if (!node) return null
  if (className && !node.classList.contains(className)) return null

  return node
}

export function startAnalysis() {
  const html = document.querySelector('html')
  const callback = (ev: any) => {
    const node = findNode(ev, 'button', 'ant-btn')
    if (node) {
      analysis.add({event: node.innerText})
    }
  }
  analysis.start()
  html.addEventListener('click', callback)

  return () => {
    analysis.stop()
    html.removeEventListener('click', callback)
  }
}

const formatDate = (date: Date) => {
  const pad = value => `${value}`.padStart(2, '0')
  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())} ${pad(date.getHours())}:${pad(
    date.getMinutes()
  )}:${pad(date.getSeconds())}`
}

class Analysis {
  private readonly INTERVAL = 1000 * 10 // 发送事件间隔
  private readonly QUEUE_KEY = 'QueueKey'

  private queue: UserLogDTO[] = []

  constructor() {
    try {
      const value = localStorage.getItem(this.QUEUE_KEY)
      if (value) {
        this.queue = JSON.parse(value)
      }
    } finally {
      // console.log('初始化完成');
    }
  }

  public add(data: Partial<UserLogDTO>) {
    const name = window.location.pathname

    this.queue.push({
      eventTime: formatDate(new Date()),
      path: name,
      userId: userModel.data.userInfo?.userId,
      ...data,
    } as UserLogDTO)
    localStorage.setItem(this.QUEUE_KEY, JSON.stringify(this.queue))
  }

  private clear() {
    this.queue.length = 0
    localStorage.removeItem(this.QUEUE_KEY)
  }

  private _timer = null
  public start = () => {
    clearTimeout(this._timer)
    if (this.queue.length) {
      userModel.userLogInsert([...this.queue])
      this.clear()
    }
    this._timer = setTimeout(this.start, this.INTERVAL)
  }

  public stop = () => {
    clearTimeout(this._timer)
  }
}

export const analysis = new Analysis()
