import {Model} from '@redux-model/web'
import {$api} from '@/store/service'

class Lessor extends Model<any> {
  protected initReducer(): any {
    return null
  }

  // 财务科目设置-查询配置列表(只返回自己配置项)
  lessorConfigContractListSelected = $api.action(() =>
    this.post<ApiResponse<LessorConfigVO>>('/lessor/config/contract/list-selected').throttle(1000 * 10, true)
  )

  // 提交申请
  lessorCompanyApplyInsert = $api.action((args: LessorCompanyApplyInsertDTO) =>
    this.post<ApiResponse<string>>('/lessor/company/apply/insert').body(args)
  )

  // 查询所有的银行
  lessorCompanyApplyListBank = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<string[]>>('/lessor/company/apply/list-bank').body(args)
  )

  // 申请记录列表
  lessorCompanyApplyList = $api.action((args: LessorCompanyApplyQueryDTO) =>
    this.post<ApiResponse<LessorCompanyApplyVO[]>>('/lessor/company/apply/list').body(args)
  )

  // 企业账户列表(不分页)
  lessorCompanyList = $api.action((args: LessorCompanyQueryDTO) =>
    this.post<ApiResponse<LessorCompanyVO[]>>('/lessor/company/list').body(args)
  )

  // 验证是否可以继续提交租约变更  |  object:{contractId:租约编号}
  lessorContractChangeApplyCheck = $api.action((args: {contractId: string}) =>
    this.post<ApiResponse<ContractChangeApplyCheckVO>>('/lessor/contract/change/apply/check').body(args)
  )
}

export const lessorModel = new Lessor()
