import React, {useEffect, useState} from 'react'
import DeviceAccountBind from '@/pages/device/DeviceAccountBind'
import {deviceModel} from '@/store/models/Device'

export interface DeviceAuthProps {}

const DeviceAuth: React.FC<DeviceAuthProps> = props => {
  const [status, setStatus] = useState<'pending' | 'access' | 'unaccess'>('pending')

  useEffect(function () {
    deviceModel
      .deviceYundingAccessStatus()
      .then(({response: {data}}) => setStatus(data ? 'access' : 'unaccess'))
      .catch(() => setStatus('unaccess'))
  }, [])

  if (status === 'pending') return null

  return status === 'access' ? <>{props.children}</> : <DeviceAccountBind onSuccess={() => setStatus('access')} />
}

export default DeviceAuth
