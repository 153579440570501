import React, {HTMLAttributes, useCallback, useEffect, useMemo, useState} from 'react'
import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  List,
  Menu,
  message,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Tag,
  TreeSelect,
  Typography,
  Checkbox,
  Tree,
  Empty,
} from 'antd'
import styles from './index.module.less'
import './index.less'
import {MoreOutlined} from '@ant-design/icons'
import {teamModel} from '@/store/models/Team'
// import {getImg} from '@/utils/util'
import {Album} from '@/ui/components/Album'
import {baseUrl} from '@/common/config'
import {rules} from '@/utils/validate'
import {useHistory} from 'react-router'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import {getImg} from '@/utils/util'
import {CheckboxChangeEventTarget} from 'antd/es/checkbox/Checkbox'
import {userModel} from '@/store/models/User'
import {usePermission} from '@/ui/components/Permission'

const CHECK_ALL_ID = '0'

interface TeamForm {
  teamId: string
  name: string
}

export default function Framework() {
  const history = useHistory()
  const currentTeam = userModel.useData(data => data.currentTeam)
  const userInfo = userModel.useData(data => data.userInfo)
  const [auth] = usePermission()

  // 当前团队是自己
  const isOwner = userInfo.userId === currentTeam.owner
  const isTeam = currentTeam.level === 2

  // console.log('currentTeam-----------', currentTeam)
  // console.log('userInfo-----------', userInfo)

  const authTagType = useMemo(() => ({'0': 'gold', '1': 'green', '2': 'blue', '3': 'red'}), [])
  const authStatusType = useMemo(() => ({'0': '未认证', '1': '已认证', '2': '审核中', '3': '审核失败'}), [])

  const [teamForm] = Form.useForm() // name, teamId,

  const [activeKey, setActiveKey] = useState(auth('006001006') ? '1' : '2')

  // 新增成员表单
  const [memberForm] = Form.useForm()
  const insertLoading = teamModel.teamV2MemberInsert.useLoading()
  const [memberVisible, setMemberVisible] = useState(false)
  const [teamRoleDTO, setTeamRoleDTO] = useState({permissionCodeList: []} as TeamRoleSaveDTO)

  const [, update] = useState({})
  // 团队架构
  const [groupConfig, setGroupConfig] = useState<TeamTreeNodeVO[]>([])
  const [roleList, setRoleList] = useState<TeamRoleVO[]>([])
  // 下拉框岗位权限，过滤超级管理员
  const teamRoleList = useMemo(() => roleList.filter(value => !value.superRole), [roleList])
  const [permissionTree, setPermissionTree] = useState<TeamPermissionTreeNodeVO[]>([])
  // const [childPermissions, setChildPermissions] = useState<string[]>()
  const childPermissions = useMemo(() => {
    const getIds = (permission: TeamPermissionTreeNodeVO, selected = []) => {
      if (permission.id?.length === 9) selected.push(permission.id)
      permission.children?.forEach(value => getIds(value, selected))
      return selected
    }
    return permissionTree.map(value => getIds(value)).flat()
  }, [permissionTree])

  const [permissionTreeVisible, setPermissionTreeVisible] = useState(false)
  const [roleTable, setRoleTable] = useState<TeamUserVO[]>([])
  const [curRole, setCurRole] = useState({} as TeamRoleVO)
  const detailLoading = teamModel.teamV2MemberDetail.useLoading()
  const memberLoading = teamModel.teamV2RoleListMember.useLoading()
  const teamMemberLoading = teamModel.teamV2TeamListMember.useLoading()
  // const permissionTreeLoading = teamModel.teamV2RoleListAllPermissionTree.useLoading()

  // 显示岗位权限树，如果没有请求过权限，则先请求权限
  const showPermissionTree = useCallback(async () => {
    if (!permissionTree?.length) {
      const {
        response: {data},
      } = await teamModel.teamV2RoleListAllPermissionTree({})
      setPermissionTree(data)
    }
    setPermissionTreeVisible(true)
  }, [permissionTree])

  // 表格数据
  const [tableData, setTableData] = useState<TeamUserVO[]>([])
  // 当前选中的组织或团队
  const [current, setCurrent] = useState<{teamPid: string; teamId: string}>({
    teamId: !isOwner && isTeam ? currentTeam.teamId : '',
    teamPid: '',
  })
  // 显示添加组织的弹框
  const [showOrg, setShowOrg] = useState(false)
  // 显示添加团队的弹框
  const [showGroup, setShowGroup] = useState(false)
  // 重命名弹框
  const [showRename, setShowRename] = useState(false)

  // todo: networking
  // 查询团队架构
  const queryTeamTrees = useCallback(function () {
    teamModel.teamV2TeamGetTree({}).then(({response: {data}}) => {
      // console.log('tree-----------------', data)
      setGroupConfig(data)
      // 用来更新tree
      update({})
    })
  }, [])

  useEffect(() => queryTeamTrees(), [queryTeamTrees])

  // 查询团队或组织成员
  const queryTeamMember = useCallback(function (teamId: string) {
    teamModel.teamV2TeamListMember({teamId}).then(({response: {data}}) => {
      setTableData(data)
    })
  }, [])

  useEffect(() => {
    if (current.teamId) queryTeamMember(current.teamId)
  }, [current, queryTeamMember])

  // 查询岗位
  const queryRoleList = useCallback(function () {
    teamModel.teamV2RoleList({}).then(({response: {data}}) => {
      // console.log('data----------------------', data)
      setRoleList(data)
      setCurRole(prevState =>
        prevState?.teamRole ? {...prevState, ...data.find(value => value.id === prevState.id)} : data[0]
      )
    })
  }, [])

  useEffect(() => queryRoleList(), [queryRoleList])

  // 岗位角色-根据岗位查询成员列表
  useEffect(() => {
    if (curRole?.teamRole && isOwner) {
      teamModel.teamV2RoleListMember({teamRole: curRole.teamRole}).then(({response: {data}}) => {
        setRoleTable(data)
      })
    }
  }, [curRole, isOwner])

  // todo: operation
  // 删除组织或团队
  function onTeamDelete(id: string, name: string) {
    Modal.confirm({
      title: '提示',
      content: `确定删除: ${name} ？`,
      onOk() {
        teamModel.teamV2TeamDelete({teamId: id}).then(() => {
          message.success('删除成功！')
          queryTeamTrees()
        })
      },
    })
  }

  // 删除团队成员
  async function handleTeamMemberDelete(userId: string) {
    return new Promise((resolve, reject) => {
      const handle = Modal.confirm({
        title: '提示',
        content: '确定删除？',
        onCancel: () => {
          reject()
          handle.destroy()
        },
        onOk() {
          teamModel.teamV2MemberDelete({userId}).then(() => {
            message.success('删除成功！')
            resolve()
          })
        },
      })
    })
  }

  return (
    <>
      <Breadcrumb
        path={['首页', '团队管理', '团队架构']}
        right={
          <>
            {activeKey === '1' && isOwner && (
              <Button type={'primary'} onClick={() => setShowOrg(true)}>
                新建组织
              </Button>
            )}
            {activeKey === '2' && auth('006001004') && (
              <Button type={'primary'} onClick={showPermissionTree}>
                新建岗位
              </Button>
            )}
          </>
        }
      />
      <Tabs activeKey={activeKey} onChange={setActiveKey} className={'tab-header'}>
        {(isOwner || auth('006001006')) && (
          <Tabs.TabPane tab='组织架构' key='1' className={styles.pane}>
            <Row>
              {(!isTeam || isOwner) && (
                <Col flex={'280px'} style={{borderRight: '1px solid #E9E9E9'}}>
                  <Tree
                    defaultExpandAll
                    defaultExpandParent
                    blockNode
                    treeData={groupConfig as any}
                    className={styles.tree}
                    onSelect={(selectedKeys, info) => {
                      if (info.selected) {
                        const node = info.node as typeof info.node & {prefixCls: string}
                        if (node.prefixCls === 'org') {
                          setCurrent({teamPid: '', teamId: `${node.key}`})
                        } else {
                          setCurrent(prevState => ({
                            teamPid: prevState.teamPid ? prevState.teamPid : prevState.teamId,
                            teamId: `${node.key}`,
                          }))
                        }
                      }
                    }}
                  >
                    {groupConfig.map((org, index1) => (
                      <Tree.TreeNode
                        key={org.teamId}
                        prefixCls={'org'}
                        title={
                          <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <Space>
                              <span>{org.name}</span>
                              {org.authStatus !== '0' && (
                                <Tag color={authTagType[org['authStatus']]}>{authStatusType[org['authStatus']]}</Tag>
                              )}
                            </Space>
                            {isOwner && (
                              <div className={'treeMore'} onClick={event => event.stopPropagation()}>
                                <Dropdown
                                  overlay={
                                    <Menu>
                                      <Menu.Item
                                        onClick={() => {
                                          teamForm.setFieldsValue({teamId: org.teamId})
                                          setShowGroup(true)
                                        }}
                                      >
                                        增加新团队
                                      </Menu.Item>
                                      <Menu.Item
                                        onClick={() => {
                                          teamForm.setFieldsValue({teamId: org.teamId, name: org.name})
                                          setShowRename(true)
                                        }}
                                      >
                                        重命名
                                      </Menu.Item>
                                      <Menu.Item onClick={() => onTeamDelete(org.teamId, org.name)}>删除</Menu.Item>
                                      {(org.authStatus === '0' || org.authStatus === '3') && (
                                        <Menu.Item
                                          onClick={() => {
                                            org.authStatus === '0'
                                              ? history.push('/enterprise', {teamId: org.teamId})
                                              : history.push('/success', {cerType: '2', cerAuthId: org.authRecordId})
                                          }}
                                        >
                                          企业认证
                                        </Menu.Item>
                                      )}
                                    </Menu>
                                  }
                                >
                                  <MoreOutlined
                                    onClick={event => event.stopPropagation()}
                                    rotate={90}
                                    style={{fontSize: 22}}
                                  />
                                </Dropdown>
                              </div>
                            )}
                          </div>
                        }
                        className={styles.treeNode}
                      >
                        {org.children.map((group, index2) => (
                          <Tree.TreeNode
                            key={group.teamId}
                            title={
                              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <span>{group.name}</span>
                                {isOwner && (
                                  <div className='treeMore' onClick={event => event.stopPropagation()}>
                                    <Dropdown
                                      overlay={
                                        <Menu>
                                          <Menu.Item
                                            onClick={() => {
                                              teamForm.setFieldsValue({teamId: group.teamId, name: group.name})
                                              setShowRename(true)
                                            }}
                                          >
                                            重命名
                                          </Menu.Item>
                                          <Menu.Item onClick={() => onTeamDelete(group.teamId, group.name)}>
                                            删除
                                          </Menu.Item>
                                        </Menu>
                                      }
                                    >
                                      <MoreOutlined
                                        onClick={event => event.stopPropagation()}
                                        rotate={90}
                                        style={{fontSize: 22}}
                                      />
                                    </Dropdown>
                                  </div>
                                )}
                              </div>
                            }
                            className={styles.treeNode}
                            prefixCls={'child'}
                          />
                        ))}
                      </Tree.TreeNode>
                    ))}
                  </Tree>
                </Col>
              )}
              <Col flex={'1'} className={styles.paneContent}>
                <div className={styles.between}>
                  <Typography.Title level={3} style={{marginBottom: 0}}>
                    成员列表
                  </Typography.Title>
                  {auth('006001001') && (
                    <Space>
                      <Button onClick={() => setMemberVisible(true)}>添加成员</Button>
                    </Space>
                  )}
                </div>
                <Table
                  rowKey={'userId'}
                  dataSource={tableData}
                  columns={[
                    {
                      title: '成员列表',
                      dataIndex: 'certName',
                      key: 'certName',
                    },
                    {
                      title: '手机号',
                      dataIndex: 'mobile',
                      key: 'mobile',
                    },
                    {
                      title: '岗位角色',
                      dataIndex: 'roleName',
                      key: 'roleName',
                    },
                    {
                      title: '操作',
                      dataIndex: 'userId',
                      key: 'userId',
                      render: (userId, record) =>
                        record.superRole === 1 ? (
                          '/'
                        ) : (
                          <Space>
                            {auth('006001002') && (
                              <Button
                                type='link'
                                size={'small'}
                                loading={detailLoading}
                                style={{marginRight: 10}}
                                onClick={async () => {
                                  const {
                                    response: {data},
                                  } = await teamModel.teamV2MemberDetail({userId})
                                  memberForm.setFieldsValue(data)
                                  setMemberVisible(true)
                                }}
                              >
                                编辑
                              </Button>
                            )}

                            {auth('006001003') && (
                              <Button
                                type='link'
                                size={'small'}
                                onClick={() =>
                                  handleTeamMemberDelete(userId).then(() => setCurrent(prevState => ({...prevState})))
                                }
                              >
                                删除
                              </Button>
                            )}
                          </Space>
                        ),
                    },
                  ]}
                  pagination={false}
                  loading={teamMemberLoading}
                />
              </Col>
            </Row>
          </Tabs.TabPane>
        )}
        {(isOwner || auth('006001007')) && (
          <Tabs.TabPane tab='岗位角色' key='2' className={styles.pane}>
            <Row>
              <Col flex={'280px'} style={{borderRight: '1px solid #E9E9E9'}}>
                <List
                  split={false}
                  dataSource={roleList}
                  renderItem={item => (
                    <List.Item
                      style={{paddingLeft: 60, paddingRight: 16}}
                      className={item.teamRole === curRole.teamRole ? 'activeItem' : ''}
                      onClick={() => setCurRole(item)}
                    >
                      {isOwner ? `${item.roleName}（${item.memberNum ?? 0}）` : `${item.roleName}`}
                    </List.Item>
                  )}
                />
              </Col>
              <Col flex={'1'} className={styles.paneContent}>
                <div style={{display: 'flex', flexDirection: 'row-reverse', marginBottom: '20px'}}>
                  {/*<Typography.Title level={3} style={{marginBottom: 0}}>*/}
                  {/*  成员列表*/}
                  {/*</Typography.Title>*/}
                  {curRole.superRole !== 1 && (
                    <Space>
                      {/*<Button*/}
                      {/*  onClick={() => {*/}
                      {/*    memberForm.setFieldsValue({teamRole: curRole.teamRole})*/}
                      {/*    setMemberVisible(true)*/}
                      {/*  }}*/}
                      {/*>*/}
                      {/*  添加成员*/}
                      {/*</Button>*/}

                      {auth('006001005') && (
                        <Button
                          type={'primary'}
                          onClick={() => {
                            setTeamRoleDTO(curRole)
                            showPermissionTree()
                          }}
                        >
                          编辑岗位
                        </Button>
                      )}
                    </Space>
                  )}
                </div>
                {isOwner ? (
                  <Table
                    rowKey={'userId'}
                    dataSource={roleTable}
                    columns={[
                      {
                        title: '成员列表',
                        dataIndex: 'certName',
                        key: 'certName',
                      },
                      {
                        title: '手机号',
                        dataIndex: 'mobile',
                        key: 'mobile',
                      },
                      {
                        title: '所属范围',
                        dataIndex: 'teamName',
                        key: 'teamName',
                      },
                      {
                        title: '操作',
                        dataIndex: 'userId',
                        key: 'userId',
                        render: (userId, record) =>
                          curRole.superRole === 1 ? (
                            '/'
                          ) : (
                            <Space>
                              <Button
                                type={'link'}
                                size={'small'}
                                onClick={async () => {
                                  const {
                                    response: {data},
                                  } = await teamModel.teamV2MemberDetail({userId})
                                  memberForm.setFieldsValue(data)
                                  setMemberVisible(true)
                                }}
                              >
                                编辑
                              </Button>

                              <Button
                                type={'link'}
                                size={'small'}
                                onClick={() => handleTeamMemberDelete(userId).then(() => queryRoleList())}
                              >
                                删除
                              </Button>
                            </Space>
                          ),
                      },
                    ]}
                    pagination={false}
                    loading={memberLoading}
                  />
                ) : (
                  <Empty
                    image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
                    imageStyle={{
                      height: 60,
                    }}
                    style={{marginTop: '50px'}}
                  />
                )}
              </Col>
            </Row>
          </Tabs.TabPane>
        )}
      </Tabs>

      {!isOwner && !auth('006001006') && !auth('006001007') && (
        <Empty
          image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
          imageStyle={{
            height: 60,
          }}
          description={'您没有操作权限'}
          style={{marginTop: '50px'}}
        />
      )}

      {/** 添加新组织 */}
      <Modal
        title={'添加新组织'}
        confirmLoading={teamModel.teamV2TeamInsert.useLoading()}
        visible={showOrg}
        centered={true}
        onCancel={() => setShowOrg(false)}
        afterClose={() => teamForm.resetFields()}
        destroyOnClose
        onOk={async () => {
          const value = teamForm.getFieldsValue(true) as TeamForm
          if (!value.name) return message.warning('请填写组织名称！')
          await teamModel.teamV2TeamInsert(value)
          setShowOrg(false)
          message.success('添加成功！')
          queryTeamTrees()
        }}
      >
        <Form form={teamForm}>
          <Form.Item label={'组织名称'} name={'name'}>
            <Input placeholder={'请输入组织名称'} />
          </Form.Item>
        </Form>
      </Modal>

      {/** 添加新团队 */}
      <Modal
        title={'添加新团队'}
        visible={showGroup}
        centered={true}
        onCancel={() => setShowGroup(false)}
        afterClose={() => teamForm.resetFields()}
        confirmLoading={teamModel.teamV2TeamInsert.useLoading()}
        destroyOnClose
        onOk={async () => {
          const value = teamForm.getFieldsValue(true) as TeamForm
          if (!value.name) return message.warning('请填写团队名称！')
          await teamModel.teamV2TeamInsert(value)
          setShowGroup(false)
          message.success('添加成功！')
          queryTeamTrees()
        }}
      >
        <Form form={teamForm}>
          <Form.Item label={'团队名称'} name={'name'}>
            <Input placeholder={'请输入团队名称'} />
          </Form.Item>
        </Form>
      </Modal>

      {/** 重命名 */}
      <Modal
        title={'重命名'}
        visible={showRename}
        centered={true}
        onCancel={() => setShowRename(false)}
        afterClose={() => teamForm.resetFields()}
        destroyOnClose
        confirmLoading={teamModel.teamV2TeamRename.useLoading()}
        onOk={async () => {
          const value = teamForm.getFieldsValue(true) as TeamForm
          if (!value.name) return message.warning('请填写新名称！')
          await teamModel.teamV2TeamRename(value)
          setShowRename(false)
          message.success('修改成功！')
          queryTeamTrees()
        }}
      >
        <Form form={teamForm}>
          <Form.Item label={'名称'} name={'name'}>
            <Input placeholder={'请输入名称'} />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={memberForm.getFieldValue('userId') ? '编辑成员' : '添加成员'}
        visible={memberVisible}
        onCancel={() => setMemberVisible(false)}
        afterClose={() => memberForm.resetFields()}
        confirmLoading={insertLoading}
        onOk={() => memberForm.submit()}
        okText={'保存'}
        destroyOnClose
      >
        <Form
          form={memberForm}
          labelCol={{span: 5}}
          labelAlign={'left'}
          onFinish={async () => {
            const member = memberForm.getFieldsValue(true)
            const store = {
              ...member,
              imgIdList: member.imgList?.map(value => value.imgId),
              teamIdList: member.teamIdList.map(value => (typeof value === 'object' ? value.value : value)),
            } as TeamMemberInsertDTO | TeamMemberUpdateDTO

            if ('userId' in store) {
              await teamModel.teamV2MemberEdit(store)
            } else {
              // if (!store.imgList?.length) return message.error('照片凭证不能为空')
              await teamModel.teamV2MemberInsert(store)
            }
            message.success('操作成功')
            if (activeKey === '1') {
              setCurrent(prevState => ({...prevState}))
            } else queryRoleList()
            setMemberVisible(false)
          }}
        >
          <Form.Item label={'姓名'} required>
            <Space>
              <Form.Item noStyle name={'gender'} rules={[{required: true, message: '请选择性别'}]}>
                <Select
                  options={[
                    {label: '男', value: '1'},
                    {label: '女', value: '2'},
                  ]}
                  placeholder={'请选择'}
                />
              </Form.Item>
              <Form.Item noStyle name={'certName'} rules={[{required: true, message: '请输入姓名'}]}>
                <Input placeholder={'请输入'} />
              </Form.Item>
            </Space>
          </Form.Item>
          <Form.Item label={'设置岗位'} name={'teamRole'} rules={[{required: true}]}>
            <Select>
              {teamRoleList.map(value => (
                <Select.Option key={value.teamRole} value={value.teamRole}>
                  {value.roleName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {() => (
              <Form.Item label={'组织/团队'} name={'teamIdList'} rules={[{required: true}]}>
                <TreeSelect
                  style={{width: '100%'}}
                  treeDefaultExpandAll
                  multiple
                  treeCheckable
                  showArrow
                  showSearch={false}
                  treeCheckStrictly
                  showCheckedStrategy={'SHOW_PARENT'}
                  onSelect={(value, option) => {
                    if (option.children?.length) {
                      const ids = option.children.map(value => value.value)
                      const teamIdList = memberForm.getFieldValue('teamIdList')
                      memberForm.setFieldsValue({teamIdList: teamIdList?.filter(item => !ids.includes(item.value))})
                    }
                  }}
                >
                  {!isTeam || isOwner ? (
                    groupConfig.map(group => {
                      const teamIdList = memberForm.getFieldValue('teamIdList')
                      return (
                        <TreeSelect.TreeNode key={group.teamId} value={group.teamId} title={group.name}>
                          {group.children.map(child => {
                            // 因为 treeCheckStrictly 的缘故，会把 value 变为 {value, label} 的形式
                            // 而回显的类型未 string[] 所以要判断两种情况
                            const disableCheckbox = teamIdList?.some(item =>
                              typeof item === 'object' ? item.value === group.teamId : item === group.teamId
                            )

                            return (
                              <TreeSelect.TreeNode
                                disableCheckbox={disableCheckbox}
                                key={child.teamId}
                                value={child.teamId}
                                title={child.name}
                              />
                            )
                          })}
                        </TreeSelect.TreeNode>
                      )
                    })
                  ) : (
                    <TreeSelect.TreeNode key={currentTeam.teamId} value={currentTeam.teamId} title={currentTeam.name} />
                  )}
                </TreeSelect>
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item label={'身份证号'} name={'certNo'}>
            <Input placeholder={'请输入'} />
          </Form.Item>
          <Form.Item label={'登录手机号'} name={'mobile'} rules={rules.mobile}>
            <Input disabled={memberForm.getFieldValue('userId')} placeholder={'请输入'} />
          </Form.Item>
          {!memberForm.getFieldValue('userId') && (
            <Form.Item label={'登录密码'} name={'pwd'} rules={[{required: true}]}>
              <Input placeholder={'请输入'} />
            </Form.Item>
          )}
          <Form.Item label={'照片凭证'}>
            <Album
              limit={9}
              action={baseUrl + '/team-v2/member/upload-file'}
              showUploadList={true}
              fileList={memberForm
                .getFieldValue('imgList')
                ?.map((value: ImgVO) => ({...value, url: getImg(value.imgUrl), uid: value.imgId}))}
              onDone={fileList => {
                memberForm.setFieldsValue({
                  imgList: fileList.map(value => ({imgId: value.imgId, imgUrl: value.imgUrl})),
                })
              }}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={!teamRoleDTO.id ? '新增岗位' : '编辑岗位'}
        visible={permissionTreeVisible}
        onCancel={() => setPermissionTreeVisible(false)}
        confirmLoading={teamModel.teamV2RoleSave.useLoading()}
        onOk={async () => {
          if (!teamRoleDTO.roleName) return message.error('岗位名称不能为空')
          if (!teamRoleDTO.permissionCodeList?.length) return message.error('权限列表不能为空')
          await teamModel.teamV2RoleSave(teamRoleDTO)
          message.success('操作成功')
          // if (!teamRoleDTO.id)
          queryRoleList()
          setPermissionTreeVisible(false)
        }}
        destroyOnClose
        afterClose={() => setTeamRoleDTO({permissionCodeList: []} as TeamRoleSaveDTO)}
        width={1200}
      >
        <Form>
          <Form.Item label={'岗位名称'}>
            <Input
              value={teamRoleDTO.roleName}
              onChange={event => {
                const roleName = event.target.value
                setTeamRoleDTO(prevState => ({...prevState, roleName}))
              }}
              style={{width: 216}}
              disabled={!!teamRoleDTO.id}
            />
          </Form.Item>
        </Form>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <Recursion
            onChang={target => {
              setTeamRoleDTO(prevState => ({...prevState, permissionCodeList: target.checked ? childPermissions : []}))
            }}
            value={teamRoleDTO.permissionCodeList.length === childPermissions.length ? [CHECK_ALL_ID] : []}
            id={''}
            label={'模块'}
            children={[{id: '', label: '权限', children: [{id: CHECK_ALL_ID, label: '全选', children: []}]}]}
          />
          {permissionTree?.map(value => (
            <Recursion
              key={value.id}
              value={teamRoleDTO.permissionCodeList}
              onChang={target => {
                setTeamRoleDTO(prevState => ({
                  ...prevState,
                  permissionCodeList: target.checked
                    ? [...prevState.permissionCodeList, target.value]
                    : prevState.permissionCodeList.filter(item => item !== target.value),
                }))
              }}
              {...value}
            />
          ))}
        </div>
      </Modal>
    </>
  )
}

const RecursionRow: React.FC<{
  span?: number
  width?: number
  unFlex?: boolean
  style?: HTMLAttributes<any>['style']
  className?: string
}> = props => {
  return (
    <div
      className={`${styles.flexBox}${props.className ? ` ${props.className}` : ''}`}
      style={{
        ...(props.span
          ? {width: `${(props.span / 24) * 100}%`}
          : props.width
          ? {width: props.width}
          : !props.unFlex
          ? {flex: 1}
          : {}),
        ...props.style,
      }}
    >
      {props.children}
    </div>
  )
}

/**
 * 递归渲染权限
 */
function Recursion({
  depth = 0,
  ...props
}: TeamPermissionTreeNodeVO & {
  depth?: number
  unFlex?: boolean // todo: 搞懂flex自动撑大
  value: string[]
  onChang?: (target: CheckboxChangeEventTarget) => void
}) {
  const leaf = !props.children?.length
  const isEnd = !props.children?.[0]?.children?.length

  return !leaf ? (
    <RecursionRow unFlex={props.unFlex} className={depth === 0 ? styles.listRow : ''}>
      <RecursionRow className={depth === 0 ? styles.listTitle : ''} width={120}>
        {props.label}
      </RecursionRow>
      <RecursionRow style={!isEnd ? {flexDirection: 'column'} : {}}>
        {props.children.map(value => (
          <Recursion key={value.id} {...value} unFlex value={props.value} onChang={props.onChang} depth={depth + 1} />
        ))}
      </RecursionRow>
    </RecursionRow>
  ) : (
    <RecursionRow span={4} className={styles.listItem}>
      <Checkbox value={props.id} checked={props.value?.includes(props.id)} onChange={e => props.onChang?.(e.target)}>
        {props.label}
      </Checkbox>
    </RecursionRow>
  )
}
