import React, {useEffect, useState} from 'react'
import {Form, Input, InputNumber, message, Modal, Select} from 'antd'
import {deviceModel} from '@/store/models/Device'
import {propertyModel} from '@/store/models/Property'

type NygDeviceRechargeProps = {
  info: {
    lesseeMobile: string // 承租人手机号
    lesseeName: string // 承租人姓名
    propertyTitle: string // 房源标题
    balance: number // 水电余额
    propertyCode: string // 房源code
    communityCode: string // 小区code
    contractId: string // 租约id
  }
  visible: boolean
  onOk: () => void
  onCancel: () => void
}

export default function NygDeviceRecharge(props: NygDeviceRechargeProps) {
  const {info, visible, onOk, onCancel} = props

  const [form] = Form.useForm()

  const [payWayArr, setPayWayArr] = useState<{id: string; label: string}[]>([])

  useEffect(() => {
    if (info.communityCode) {
      propertyModel.projectConfig({condoId: info.communityCode}).then(({response: {data}}) => {
        setPayWayArr(data.payConfigList.map(item => ({id: item.payWay, label: item.payWayName})))
      })
    }
  }, [info.communityCode])

  const chargeLoading = deviceModel.deviceV2MeterWalletCharge.useLoading()

  return (
    <Modal
      visible={visible}
      title={'水电余额充值'}
      centered
      onCancel={onCancel}
      afterClose={() => {
        form.resetFields()
      }}
      onOk={() => form.submit()}
      confirmLoading={chargeLoading}
    >
      <Form
        form={form}
        labelAlign={'left'}
        style={{width: '75%'}}
        onFinish={async () => {
          const formValue = form.getFieldsValue(true)
          // console.log('formValue', formValue)
          await deviceModel.deviceV2MeterWalletCharge({
            ...formValue,
            contractId: info.contractId,
            propertyCode: info.propertyCode,
          })
          message.success('充值成功！')
          onOk?.()
        }}
      >
        <Form.Item noStyle shouldUpdate>
          {() => {
            return (
              <>
                <Form.Item label={'承租人'}>{info.lesseeName}</Form.Item>
                <Form.Item label={'手机号'}>{info.lesseeMobile}</Form.Item>
                <Form.Item label={'房源地址'}>{info.propertyTitle}</Form.Item>
                <Form.Item label={'当前水电余额'}>{info.balance}</Form.Item>
              </>
            )
          }}
        </Form.Item>
        <Form.Item label={'平台'} name={'billPayWayCode'} rules={[{required: true, message: '请选择平台'}]}>
          <Select placeholder={'请选择'} allowClear>
            {payWayArr.map(item => (
              <Select.Option key={item.id} value={item.id}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={'备注'} name={'remark'} rules={[{required: true, message: '请输入备注'}]}>
          <Input.TextArea maxLength={100} placeholder={'请输入'} allowClear />
        </Form.Item>
        <Form.Item
          label={'充值余额'}
          name={'fee'}
          rules={[
            {required: true, message: '请输入充值金额'},
            {type: 'number', min: 0, message: '充值金额不能小于0'},
          ]}
        >
          <InputNumber placeholder={'请输入'} style={{width: '100%'}} min={0} />
        </Form.Item>
      </Form>
    </Modal>
  )
}
