import React, {ForwardRefRenderFunction, useImperativeHandle, useMemo, useState} from 'react'
import {Button, Col, Modal, Row, Typography} from 'antd'
import {userModel} from '@/store/models/User'

const _TeamModal: ForwardRefRenderFunction<any, {}> = (_, ref) => {
  const teamList = userModel.useData(data => data.teamList)
  const currentTeam = userModel.useData(data => data.currentTeam)
  const renderTeamList = useMemo(() => groupTeam(teamList), [teamList])
  const [visible, setVisible] = useState(false)

  useImperativeHandle(ref, () => ({
    show: () => setVisible(true),
  }))

  return (
    <Modal width={643} visible={visible} title={'团队选择'} footer={null} onCancel={() => setVisible(false)}>
      {renderTeamList.map((item, index) => (
        <React.Fragment key={index}>
          <Typography.Title level={4} style={{marginBottom: 20}}>
            {item.title}：
          </Typography.Title>
          <Row gutter={[25, 20]} style={{marginBottom: 20}}>
            {item.data.map(value => {
              const active = currentTeam.teamId === value.teamId
              return (
                <Col key={value.teamId}>
                  <Button
                    size={'small'}
                    type={active ? 'primary' : 'text'}
                    style={{minWidth: 66}}
                    onClick={async () => {
                      if (!active) {
                        // await userModel.setCurrentTeam(value)
                        sessionStorage.setItem(userModel.NEXT_TEAM, value.teamId)
                        // await delay(600)
                        window.location.replace('/')
                      }
                    }}
                  >
                    {value.name}
                  </Button>
                </Col>
              )
            })}
          </Row>
        </React.Fragment>
      ))}
    </Modal>
  )
}

const TeamModal = React.forwardRef(_TeamModal)

export default TeamModal

function groupTeam(teamList: TeamTreeNodeVO[]) {
  const data = teamList.reduce<{[key: string]: {title: string; data: typeof teamList}}>((prev, item) => {
    if (!prev[item.teamPid]) {
      prev[item.teamPid] = {title: item.teamPname, data: []}
    }
    prev[item.teamPid].data.push(item)
    return prev
  }, {})
  return Object.keys(data).map(key => data[key])
}
