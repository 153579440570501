import React, {useEffect, useState} from 'react'
import {message, Modal, Upload} from 'antd'
import {UploadChangeParam} from 'antd/lib/upload'
import {UploadFile} from 'antd/lib/upload/interface'
import {PlusOutlined} from '@ant-design/icons'
import {UploadProps} from 'antd/es/upload'
import {baseUrl} from '@/common/config'
import {userModel} from '@/store/models/User'

function getBase64(file): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = error => reject(error)
  })
}

type FileList = UploadProps['fileList'] | Pick<UploadFile, 'uid' | 'url'>[]

export interface AlbumProps extends Omit<UploadProps, 'fileList' | 'defaultFileList'> {
  title?: string
  limit?: number // 图片数量限制
  fileList?: FileList
  defaultFileList?: FileList
  onDone?: (fileList: (UploadFile & ImageResult)[]) => any
  children?: React.ReactNode
  errorMsg?: string
}

function _Album(
  {title, onChange, limit, fileList, defaultFileList, onDone, children, errorMsg, ...rest}: AlbumProps,
  ref
) {
  const [_fileList, _setFileList] = useState<any[]>([])
  const [previewImage, setPreviewImage] = useState({})
  const [previewVisible, setPreviewVisible] = useState(false)
  const token = userModel.useData(data => data.token)
  const teamId = userModel.useData(data => data.currentTeam?.teamId)

  useEffect(
    function () {
      _setFileList(fileList)
    },
    [fileList]
  )

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    // setPreviewImage(file.url || file.preview)
    setPreviewImage(file)
    setPreviewVisible(true)
    // this.setState({
    //   previewImage: file.url || file.preview,
    //   previewVisible: true,
    //   previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    // })
  }

  // const uploadButton = (
  //   <div>
  //     <PlusOutlined />
  //     <div className='ant-upload-text'>{title || '上传照片'}</div>
  //   </div>
  // )

  function canUpload<T>(file: T, FileList: T[], hitMessage = false) {
    if (limit && _fileList.length + FileList.length > limit) {
      message.destroy()
      message.info(errorMsg ?? `图片不能超过${limit}张`)
      return false
    }
    return true
  }

  function isChange(FileList: UploadChangeParam['fileList']) {
    return !limit || FileList.length <= limit
  }

  return (
    <>
      <Upload
        // action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
        action={baseUrl + '/property/img/upload'}
        listType='picture-card'
        fileList={_fileList}
        onPreview={handlePreview}
        headers={{
          'nyg8-auth': token,
          'NYG8-TEAM-ID': teamId,
        }}
        onChange={(info: UploadChangeParam) => {
          if (isChange(info.fileList)) {
            typeof onChange === 'function' && onChange(info)
            _setFileList(info.fileList)
          }
          if (
            typeof onDone === 'function' &&
            info.fileList.filter(item => !item.url).every(item => item.status === 'done')
          ) {
            onDone(info.fileList.map(item => (item.response ? {...item, ...item.response.data} : item)))
          }
        }}
        beforeUpload={canUpload}
        {...rest}
      >
        {children === undefined ? <UploadButton title={title} /> : children}
      </Upload>
      <Modal
        visible={previewVisible}
        title={'预览'}
        centered
        footer={null}
        destroyOnClose
        onCancel={() => setPreviewVisible(false)}
      >
        {/*@ts-ignore*/}
        {previewImage?.video === '1' ? (
          // @ts-ignore
          <video controls src={previewImage.url} width='100%' autoPlay />
        ) : (
          // @ts-ignore
          <img alt='预览' style={{width: '100%'}} src={previewImage.url || previewImage.preview} />
        )}
      </Modal>
    </>
  )
}

export const Album = React.forwardRef(_Album)

Album.defaultProps = {
  limit: 9,
  fileList: [],
  defaultFileList: [],
  accept: 'image/*', // 'png,jpeg,jpg',
  multiple: true,
  // showUploadList: {
  //   showRemoveIcon: false,
  // },
}

export const UploadButton = (props: {title?: string}) => (
  <div>
    <PlusOutlined />
    <div className='ant-upload-text'>{props.title || '上传照片'}</div>
  </div>
)
