import React, {useState, useImperativeHandle} from 'react'
import {Modal, Form, Space, Row, Input, Button, Select, DatePicker, message} from 'antd'
import {Album} from '@/ui/components/Album'
import {useCodeArraySync} from '@/store/models/Common'
import {baseUrl} from '@/common/config'
import {contractModel} from '@/store/models/Contract'
import {getImg} from '@/utils/util'
import {propertyModel} from '@/store/models/Property'

const {Option} = Select
const {TextArea} = Input

interface Props {
  // index [所属账期[, 当前账期的第几条]]
  onSuccess?: (billId?: string, billConfirm?: Object, index?: number[]) => unknown
  billType?: 'lessee' | 'lessor'
}

function _ConfirmCollection({onSuccess, billType}: Props, ref) {
  const [visible, setVisible] = useState(false)
  const [form] = Form.useForm()
  const [imgList, setImgList] = useState([])
  const [billDetail, setBillDetail] = useState<ContractBill>()
  // todo: 收款传 true？
  const [confirm, setConfirm] = useState(false)
  const [confirmIndex, setConfirmIndex] = useState([])

  const [payWays, setPayWays] = useState<ReturnType<typeof useCodeArraySync>>([])
  // useEffect(function () {
  //   contractModel.lessorConfigContractListSelected().then(({response: {data}}) => {
  //     setPayWays(data.payConfigList.map(item => ({id: item.payWay, label: item.payWayName})))
  //   })
  // }, [])

  const lessorLoading = contractModel.contractLessorBillConfirm.useLoading()
  const lesseeLoading = contractModel.billConfirm.useLoading()
  const loading = billType === 'lessor' ? lessorLoading : lesseeLoading

  useImperativeHandle(ref, () => ({
    onDisplay: () => {
      setVisible(true)
    },
    // 如果不传则去查询 payWays
    onSetBillDetail: async (billDetail: ContractBill) => {
      setBillDetail(billDetail)
      form.setFieldsValue({billPayAmt: billType === 'lessor' ? billDetail.billAmtTotal : billDetail?.billAmtUnpaid})
      const {
        response: {data},
      } = await propertyModel.projectConfig({condoId: billDetail.communityCode})
      setPayWays(data.payConfigList.map(item => ({id: item.payWay, label: item.payWayName})))
    },
    /**
     * @param detail
     * @param confirm
     * @param index [所属账期[, 当前账期的第几条]]
     */
    onSetBillConfirm: async (
      detail: Pick<ContractCalculateVO, 'billAmtUnpaid'> & Pick<ContractBill, 'communityCode'>,
      confirm: boolean,
      index: number[]
    ) => {
      form.setFieldsValue({billPayAmt: detail.billAmtUnpaid})
      setConfirm(confirm)
      setConfirmIndex(index)
      const {
        response: {data},
      } = await propertyModel.projectConfig({condoId: detail.communityCode})
      setPayWays(data.payConfigList.map(item => ({id: item.payWay, label: item.payWayName})))
    },
    onHidden: () => {
      setVisible(false)
      setImgList([])
      form.resetFields()
      setConfirm(false)
      setConfirmIndex([])
    },
  }))

  function onFinish() {
    const {billPayTime, billPayWayCode, billDetailRemark, billPayAmt} = form.getFieldsValue()
    if (confirm) {
      onSuccess?.(
        '',
        {
          billPayTime: billPayTime.format('YYYY-MM-DD'),
          billPayWayCode: billPayWayCode,
          billPayAmt: billPayAmt,
          billDetailRemark: billDetailRemark,
          imgIdList: imgList.map(item => item.imgId),
        },
        confirmIndex
      )
      setTimeout(() => {
        ref.current.onHidden()
      }, 500)
    } else {
      if (billType === 'lessor') {
        contractModel
          .contractLessorBillConfirm({
            billDetailRemark: billDetailRemark,
            billId: billDetail?.billId,
            billPayAmt: billDetail?.billAmtTotal,
            billPayTime: billPayTime.format('YYYY-MM-DD'),
            billPayWayCode: billPayWayCode,
            imgIdList: imgList.map(item => item.imgId),
          })
          .then(() => {
            onSuccess?.(billDetail?.billId)
            ref.current.onHidden()
            message.success('付款成功')
          })
      } else {
        contractModel
          .billConfirm({
            billDetailRemark: billDetailRemark,
            billId: billDetail?.billId,
            billPayAmt: billDetail?.billAmtUnpaid,
            billPayTime: billPayTime.format('YYYY-MM-DD'),
            billPayWayCode: billPayWayCode,
            imgIdList: imgList.map(item => item.imgId),
          })
          .then(() => {
            onSuccess?.(billDetail?.billId)
            ref.current.onHidden()
            message.success('收款成功')
          })
      }
    }
  }

  return (
    <Modal
      title={<div style={{textAlign: 'center'}}>{billType === 'lessor' ? '确认付款' : '确认收款'}</div>}
      destroyOnClose
      visible={visible}
      onCancel={() => ref.current.onHidden()}
      centered={true}
      width={460}
      footer={null}
      afterClose={() => setPayWays([])}
    >
      <Form form={form} onFinish={onFinish} labelCol={{span: 5}}>
        <Form.Item
          label={billType === 'lessor' ? '付款方式' : '收款方式'}
          name={'billPayWayCode'}
          rules={[{required: true, message: billType === 'lessor' ? '请选择付款方式' : '请选择收款方式'}]}
        >
          <Select placeholder={'请选择'} style={{width: '238px'}} allowClear>
            {payWays.map(item => (
              <Option value={item.id} key={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={billType === 'lessor' ? '付款金额' : '收款金额'} name={'billPayAmt'} required>
          <Input style={{width: '238px'}} disabled />
        </Form.Item>
        <Form.Item
          label={billType === 'lessor' ? '付款日期' : '收款日期'}
          name={'billPayTime'}
          rules={[{required: true, message: billType === 'lessor' ? '请选择付款日期' : '请选择收款日期'}]}
        >
          <DatePicker style={{width: '238px'}} />
        </Form.Item>
        <Form.Item label={'照片凭证'}>
          <Album
            fileList={imgList.map(item => ({
              ...item,
              uid: item.imgId,
              url: getImg(item.imgUrl),
            }))}
            action={baseUrl + (billType === 'lessor' ? '/contract-lessor/upload-img' : '/contract/upload-img')}
            data={{imgType: '05'}}
            onDone={fileList => {
              setImgList(fileList)
            }}
          />
        </Form.Item>
        <Form.Item label={billType === 'lessor' ? '付款备注' : '收款备注'} name={'billDetailRemark'}>
          <TextArea autoSize={{minRows: 3, maxRows: 3}} />
        </Form.Item>
        <Row justify={'center'}>
          <Space>
            <Button onClick={() => ref.current.onHidden()}>取消</Button>
            <Button type={'primary'} htmlType={'submit'} loading={loading}>
              保存
            </Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  )
}

const ConfirmCollection = React.forwardRef(_ConfirmCollection)

export default ConfirmCollection
