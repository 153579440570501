import React, {useEffect, useState} from 'react'
import commonStyles from '@/assets/styles/common.module.less'
import styles from './index.module.less'
import {Avatar, Col, Row, Upload} from 'antd'
import {userModel} from '@/store/models/User'
import {getImg} from '@/utils/util'
import {baseUrl} from '@/common/config'
import DefaultPortrait from '@/assets/icons/default_portrait.svg'
import {Breadcrumb} from '@/ui/components/Breadcrumb'

export default function Information() {
  const userInfo = userModel.useData(data => data.userInfo)
  const token = userModel.useData(data => data.token)

  const [lessorManager, setLessorManager] = useState<LessorQueryManage[]>()

  useEffect(function () {
    userModel.lessorQueryManage().then(({response: {data}}) => setLessorManager(data))
  }, [])

  const infoMap = [
    {label: '姓名', value: userInfo.certName},
    {label: '手机号', value: userInfo.mobile},
    {label: '身份证号', value: userInfo.certNo},
    {label: '客户经理', value: lessorManager?.map(item => `${item.name} ${item.mobile}`)},
    // {label: '管家', value: ['userInfouserInfo', 'userInfouserInfo2']},
  ]

  return (
    <>
      <Breadcrumb path={['首页', '个人信息']} />
      <div className={commonStyles.flexFullSection}>
        <div className={styles.content}>
          <Upload
            name='file'
            showUploadList={false}
            action={baseUrl + '/user/update-avatar'}
            onChange={info => {
              if (info.file.status === 'done') userModel.queryUser()
            }}
            accept={'image/*'}
            headers={{'nyg8-auth': token}}
          >
            {userInfo.avatar ? (
              <Avatar size={100} src={getImg(`${userInfo.avatar}?v=${Math.random()}`)} className={styles.avatar} />
            ) : (
              <Avatar size={100} src={DefaultPortrait} className={styles.avatar} />
            )}
          </Upload>
          <Row className={styles.list}>
            {infoMap.map(info => (
              <React.Fragment key={info.label}>
                <Col span={8}>{info.label}</Col>
                <Col span={16}>
                  {Array.isArray(info.value)
                    ? info.value.map(item => (
                        <Col key={item} span={24}>
                          {item}
                        </Col>
                      ))
                    : info.value}
                </Col>
              </React.Fragment>
            ))}
          </Row>
        </div>
      </div>
    </>
  )
}
