import React, {useState, forwardRef, useImperativeHandle} from 'react'
import {Modal, Form, Input, Button, Row, Space, message} from 'antd'
import {contractModel} from '@/store/models/Contract'
import {Model} from '@redux-model/web'
import {userModel} from '@/store/models/User'
import {ExclamationCircleFilled} from '@ant-design/icons'
import {useHistory} from 'react-router-dom'

const {confirm} = Modal

interface Props {
  onSuccess?: (id?: string) => unknown
  contractType?: 'lessee' | 'lessor'
}

function Cancellation({onSuccess, contractType}: Props, ref) {
  const history = useHistory()
  const [visible, setVisible] = useState(false)
  const [form] = Form.useForm()
  const [contractId, setContractId] = useState('')
  const [billId, setBillId] = useState('')
  const [billDetailId, setBillDetailId] = useState('')
  // todo
  const [extra, setExtra] = useState('') // 额外信息 收款作废需要billId
  const loading = Model.isLoading(
    contractModel.contractCancel.useLoading(),
    contractModel.billCancel.useLoading(),
    contractModel.billDetailCancel.useLoading()
  )

  useImperativeHandle(ref, () => ({
    onDisplay: (contractId: string, billId?: string, billDetailId?: string, extra?: string) => {
      userModel.payPwdExist().then(({response: {data}}) => {
        if (data) {
          setVisible(true)
          setContractId(contractId)
          if (billId) {
            setBillId(billId)
          }
          if (billDetailId) {
            setBillDetailId(billDetailId)
          }
          if (extra) {
            setExtra(extra)
          }
        } else {
          confirm({
            centered: true,
            title: '提示',
            icon: <ExclamationCircleFilled />,
            content: '敏感操作需设置支付密码，请前往设置',
            onOk() {
              history.push({pathname: '/paymentCode'})
            },
          })
        }
      })
    },
    onHidden: () => {
      setVisible(false)
      setContractId('')
      setBillId('')
      setBillDetailId('')
      setExtra('')
      form.resetFields()
    },
  }))

  function onFinish() {
    const {code} = form.getFieldsValue()
    if (billDetailId) {
      // 收款作废
      contractModel.billDetailCancel(billDetailId, code).then(() => {
        ref.current.onHidden()
        onSuccess && onSuccess(extra)
        message.success('收款作废成功')
      })
    } else if (billId) {
      if (contractType === 'lessor') {
        //业主账单作废
        contractModel.contractLessorBillCancel(billId).then(() => {
          ref.current.onHidden()
          onSuccess && onSuccess(billId)
          message.success('账单作废成功')
        })
      } else {
        // 租客账单作废
        contractModel.billCancel(billId, code).then(() => {
          ref.current.onHidden()
          onSuccess && onSuccess(billId)
          message.success('账单作废成功')
        })
      }
    } else if (contractType === 'lessor') {
      //业主租约作废
      contractModel.contractLessorCancel(contractId).then(() => {
        ref.current.onHidden()
        onSuccess && onSuccess(contractId)
        message.success('租约作废成功')
      })
    } else {
      // 租客租约作废
      contractModel.contractCancel(contractId, code).then(() => {
        ref.current.onHidden()
        onSuccess && onSuccess(contractId)
        message.success('租约作废成功')
      })
    }
  }

  return (
    <Modal
      title={
        <div style={{textAlign: 'center'}}>
          您正在作废{billId ? '账单' : billDetailId ? (contractType === 'lessor' ? '付款' : '收款') : '租约'}
          ，请输入支付密码
        </div>
      }
      destroyOnClose
      visible={visible}
      onCancel={() => ref.current.onHidden()}
      centered={true}
      width={358}
      footer={null}
    >
      <Form form={form} onFinish={onFinish}>
        <Form.Item name={'code'} rules={[{required: true, message: '请输入支付密码'}]}>
          <Input.Password size={'large'} maxLength={6} placeholder={'请输入支付密码'} />
        </Form.Item>
        <Row justify={'center'} style={{marginTop: '40px'}}>
          <Space>
            <Button onClick={() => ref.current.onHidden()}>取消</Button>
            <Button type={'primary'} htmlType={'submit'} loading={loading}>
              确定
            </Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  )
}

export default forwardRef(Cancellation)
