import {userModel} from '@/store/models/User'
import {message} from 'antd'

// 操作房源权限
export function checkPropertyPermission() {
  if (userModel.canHandleProperty) return true
  if (userModel.data.currentTeam.level === 1) {
    message.warn('请切换团队后重试')
  }
  return false
}
