import React, {useState, useImperativeHandle, useMemo, useEffect} from 'react'
import styles from './index.module.less'
import {
  Modal,
  Form,
  Select,
  Input,
  Checkbox,
  Button,
  Space,
  DatePicker,
  message,
  InputNumber,
  Row,
  Col,
  Typography,
} from 'antd'
import {contractModel} from '@/store/models/Contract'
import dayjs from 'dayjs'
import {renderChargeItem} from '@/utils/render'
import {CloseCircleFilled, PlusOutlined} from '@ant-design/icons'
import moment from 'moment'
import {propertyModel} from '@/store/models/Property'

const {Option} = Select
const {TextArea} = Input

interface Props {
  onSuccess?: () => unknown
}
const {RangePicker} = DatePicker
function AddPeriodBill({onSuccess}: Props, ref) {
  const [form] = Form.useForm()
  const [contractId, setContractId] = useState('')
  const [period, setPeriod] = useState(true)
  const [visible, setVisible] = useState(false)
  const [billIntervalList, setBillIntervalList] = useState([] as ContractBillIntervalVO[])
  // 账单项目
  const [billItems, setBillItems] = useState<ReturnType<typeof renderChargeItem>>([])
  /*useEffect(function () {
    contractModel.lessorConfigContractListSelected().then(({response: {data}}) => {
      setBillItems(renderChargeItem(data.billConfigList))
    })
  }, [])*/
  // 无账期
  // const noBillData = useMemo(
  //   () => ({
  //     billBegin: '',
  //     billDate: '',
  //     billEnd: '',
  //     contractDetailId: '',
  //     contractId: '',
  //     entirety: true,
  //     interval: null,
  //     intervalName: '无账期',
  //   }),
  //   []
  // )
  // 所属账期
  const billDataItems = useMemo(() => [...billIntervalList.filter(item => item.entirety)], [billIntervalList])

  //账单类型
  const billType = useMemo(
    () => [
      {id: '1', label: '周期'},
      {id: '2', label: '自定义'},
    ],
    []
  )

  const [changeType, setChangeType] = useState('1')

  const loading = contractModel.contractBillInsert.useLoading()

  useEffect(() => {
    if (contractId) {
      contractModel.contractBillInterval({contractId}).then(({response: {data}}) => {
        setBillIntervalList(data)
      })
    }
  }, [contractId])

  useImperativeHandle(ref, () => ({
    onDisplay: () => {
      setVisible(true)
    },
    onSetInfo: (contractId: string, communityCode: string) => {
      setContractId(contractId)
      propertyModel.projectConfig({condoId: communityCode}).then(({response: {data}}) => {
        setBillItems(renderChargeItem(data.billConfigList))
      })
    },
    onHidden: () => {
      setVisible(false)
      setPeriod(true)
      setContractId('')
      setBillIntervalList([])
      form.resetFields()
    },
  }))

  function onChange(date) {
    form.setFieldsValue({
      billPeriod: billIntervalList
        .filter(item => item.entirety)
        .find(item => dayjs(date).isBetween(item.billBegin, item.billEnd, null, '[]'))?.intervalName,
    })
  }

  function onFinish() {
    const {
      // billCategory,
      // billAmtTotal,
      billIntervalListInfo,
      billDate,
      billPeriod,
      billRemark,
      billCategorys,
      billTime,
    } = form.getFieldsValue()
    const [billBegin, billEnd] = billTime || []
    if (billCategorys?.length) {
      Promise.all(
        billCategorys.map(({billCategory, billAmtTotal}) => {
          return contractModel.contractBillInsert({
            billAmtTotal: billAmtTotal,
            billCategory: billItems.find(item => item.id === billCategory)?.category,
            billIntervalList: period
              ? billIntervalList.filter(item => billIntervalListInfo.includes(item.intervalName))
              : changeType === '1'
              ? billDataItems
                  .filter(item => item.intervalName === billPeriod)
                  .map(_item => ({..._item, billDate: billDate}))
              : [
                  {
                    billDate: billDate,
                    billBegin: billBegin ? moment(billBegin).format('YYYY-MM-DD HH:mm:ss') : null,
                    billEnd: billEnd ? moment(billEnd).format('YYYY-MM-DD HH:mm:ss') : null,
                    contractDetailId: '',
                  },
                ],
            billRemark,
            billTypeName: billItems.find(item => item.id === billCategory)?.label,
            billTypeCode: billCategory,
            contractId,
            // imgIdList: period ? [] : imgList.map(item => item.imgId),
            imgIdList: [],
          })
        })
      ).then(() => {
        onSuccess && onSuccess()
        ref.current.onHidden()
        message.success('账单添加成功')
      })
    }
  }

  return (
    <Modal
      centered
      title={period ? '添加周期账单' : '添加单个账单'}
      visible={visible}
      footer={null}
      onCancel={() => ref.current.onHidden()}
      width={607}
    >
      <Form form={form} onFinish={onFinish} initialValues={{billCategorys: [{}]}} colon={true}>
        <Form.List name={'billCategorys'}>
          {(fields, {add, remove}) => {
            return (
              <>
                <div className={styles.tableTitle}>
                  <p style={{width: '268px', paddingLeft: '16px', fontSize: '14px', fontWeight: 500, color: '#000000'}}>
                    费用类型
                  </p>
                  <p style={{width: '237px', paddingLeft: '16px', fontSize: '14px', fontWeight: 500, color: '#000000'}}>
                    金额（元）
                  </p>
                </div>
                {fields.map(field => (
                  <Space key={field.key} align={'baseline'}>
                    <Space
                      style={{borderBottom: '1px solid rgba(0, 0, 0, 0.09)', height: '60px', alignItems: 'center'}}
                      align={'baseline'}
                    >
                      <div style={{width: '268px'}}>
                        <Form.Item
                          name={[field.name, 'billCategory']}
                          fieldKey={[field.fieldKey, 'billCategory']}
                          style={{marginBottom: 0, width: '120px'}}
                          rules={[{required: true, message: '请选择账单项目'}]}
                        >
                          <Select placeholder={'请选择'} bordered={false}>
                            {billItems.map(item => (
                              <Option key={item.id} value={item.id}>
                                {item.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>

                      <div style={{width: '230px'}}>
                        <Form.Item
                          name={[field.name, 'billAmtTotal']}
                          fieldKey={[field.fieldKey, 'billAmtTotal']}
                          style={{marginBottom: 0}}
                          rules={[{required: true, message: '请输入应收金额'}]}
                        >
                          <InputNumber
                            min={0.01}
                            max={1000000}
                            step={100}
                            precision={2}
                            style={{width: '100%', border: 'none'}}
                            placeholder={'请输入金额'}
                          />
                        </Form.Item>
                      </div>
                    </Space>
                    {field.key === 0 ? null : (
                      <div style={{marginLeft: '10px'}}>
                        <CloseCircleFilled
                          onClick={() => form.getFieldValue('billCategorys')?.length > 1 && remove(field.name)}
                          style={{fontSize: '18px', color: 'rgba(0, 0, 0, 0.35)'}}
                        />
                      </div>
                    )}
                  </Space>
                ))}
                <Row align={'middle'} justify={'space-between'} style={{margin: '15px 0 20px', width: '505px'}}>
                  <Col>
                    <Form.Item shouldUpdate noStyle>
                      {form1 => {
                        const billCategorys = form1.getFieldValue('billCategorys')
                        const total = billCategorys?.reduce(
                          (result, item) =>
                            item.billAmtTotal ? (+item.billAmtTotal * 100 + result * 100) / 100 : result,
                          0
                        )
                        return (
                          <Space size={0}>
                            <Typography.Text style={{fontSize: '14px', color: '#8C8C8C'}}>总额：</Typography.Text>
                            <Typography.Text>￥{total}</Typography.Text>
                          </Space>
                        )
                      }}
                    </Form.Item>
                  </Col>
                  <Col>
                    <Button
                      type={'text'}
                      className={styles.addBtn}
                      icon={<PlusOutlined style={{fontSize: '11px'}} />}
                      onClick={() => add({})}
                    >
                      添加费用项
                    </Button>
                  </Col>
                </Row>
              </>
            )
          }}
        </Form.List>
        {period ? (
          <>
            <Form.Item
              name={'billIntervalListInfo'}
              label={'账期选择'}
              rules={[{required: true, message: '请选择账期'}]}
            >
              <Checkbox.Group>
                <Row className={styles.billContainer} gutter={[0, 10]}>
                  {billIntervalList
                    .filter(item => item.entirety)
                    .map((item, index) => (
                      <Col key={index} span={6}>
                        <Checkbox value={item.intervalName} style={{width: '150px'}}>
                          {item.intervalName}
                        </Checkbox>
                      </Col>
                    ))}
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item label={'所属账期类型'} style={{marginBottom: 0}}>
              <Form.Item style={{display: 'inline-block'}}>
                <Select
                  placeholder={'请选择所属账期类型'}
                  style={{width: '216px', marginRight: '12px'}}
                  onSelect={value => {
                    setChangeType(value)
                    // form.setFieldsValue({billTime: [moment(new Date()), moment(new Date())]})
                  }}
                  defaultValue={'1'}
                >
                  {billType.map(item => (
                    <Option value={item.id} key={item.id}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name={'billDate'}
                style={{display: 'inline-block'}}
                rules={[{required: true, message: '请选择应收款日'}]}
              >
                <DatePicker onChange={onChange} placeholder={'请选择应收款日'} style={{width: '216px'}} />
              </Form.Item>
            </Form.Item>

            {changeType === '1' ? (
              <Form.Item
                name={'billPeriod'}
                label={'所属账期'}
                wrapperCol={{span: 8}}
                rules={[{required: true, message: '所属账期不能为空'}]}
              >
                <Select allowClear placeholder={'所属账期'}>
                  {billDataItems.map((item, index) => (
                    <Option value={item.intervalName} key={index}>
                      {item.intervalName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ) : (
              <Form.Item name={'billTime'} label={'账单周期'}>
                <RangePicker />
              </Form.Item>
            )}
          </>
        )}
        <Form.Item name={'billRemark'} label={'应收备注'} style={{marginRight: '20px'}}>
          <TextArea allowClear placeholder={'请输入备注'} style={{height: '95px'}} />
        </Form.Item>
        <footer className={styles.footer}>
          <Button
            onClick={() => {
              setPeriod(!period)
              form.resetFields()
            }}
            type={'primary'}
          >
            {period ? '添加单个账单' : '添加周期账单'}
          </Button>
          <Space>
            <Button onClick={() => ref.current.onHidden()}>取消</Button>
            <Button type={'primary'} htmlType={'submit'} loading={loading}>
              确认
            </Button>
          </Space>
        </footer>
      </Form>
    </Modal>
  )
}

export default React.forwardRef(AddPeriodBill)
