import React from 'react'
import commonStyle from '@/assets/styles/common.module.less'
import styles from './index.module.less'
import {userModel} from '@/store/models/User'

export function TeamBar() {
  const currentTeam = userModel.useData(data => data.currentTeam)

  return (
    <div className={`${commonStyle.section} ${styles.header}`} style={{backgroundColor: '#fff'}}>
      选择团队：
      <span className={styles.headerSelect}>{currentTeam.name}</span>
      {/*
      <Dropdown overlay={<TeamMenu />}>
        <span className={styles.headerSelect}>
          {currentTeam.name}
          <DownOutlined />
        </span>
      </Dropdown>
*/}
    </div>
  )
}
