import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {Button, Col, Form, Input, Row, Select, Space, Table, DatePicker, Modal, Typography, message} from 'antd'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {billModel} from '@/store/models/Bill'
import dayjs from 'dayjs'
import {baseUrl} from '@/common/config'
import {Album} from '@/ui/components/Album'
import {downloadBlob} from '@/utils/util'
import StewardBillDetail from '@/pages/steward/components/StewardBillDetail'
import {Permission, usePermission} from '@/ui/components/Permission'

const {Option} = Select
const {RangePicker} = DatePicker

export default function StewardBill() {
  const [auth] = usePermission()
  const billArray = useCodeArraySync('lessor.bill')
  const billCode = useCodeSync('lessor.bill')
  const _billTypeArray = useCodeArraySync('lessor.billType')
  // 过滤额外账单
  const billTypeArray = useMemo(() => _billTypeArray.filter(value => value.id !== '3'), [_billTypeArray])

  const billTypeCode = useCodeSync('lessor.billType')
  const payWayArray = useCodeArraySync('team.chargeSource')
  const payWayCode = useCodeSync('team.chargeSource')

  // 账单列表相关
  const [form] = Form.useForm()
  const [params, setParams] = useState<Partial<LessorBillListDTO>>({pageNum: 1, pageSize: 10})
  const [billList, setBillList] = useState<LessorBillVO[]>([])
  const [billTotal, setBillTotal] = useState(0)
  const [billLatest, setBillLatest] = useState<LessorBillVO[]>([])
  const [billStatistics, setBillStatistics] = useState({} as LessorBillStatisticsVO)

  // 提交付款凭证相关
  const [billForm] = Form.useForm()
  const [unpayParams, setUnpayParams] = useState<Partial<LessorBillListDTO>>({
    billStatus: '1',
    pageNum: 1,
    pageSize: 10,
  })
  const [unpayList, setUnpayList] = useState<LessorBillVO[]>([])
  const [unpayTotal, setUnpayTotal] = useState(0)
  const [imgList, setImgList] = useState([])
  const [firVisible, setFirVisible] = useState(false)
  const [secVisible, setSecVisible] = useState(false)
  const [selectList, setSelectList] = useState<LessorBillVO[]>([])

  const detailRef = useRef(null)

  const billListLoading = billModel.userLessorBillList.useLoading()

  // 最近账单
  const queryBillLatest = useCallback(function () {
    billModel.userLessorBillLatest().then(({response: {data}}) => {
      if (data) {
        setBillLatest([data])
      }
    })
  }, [])

  useEffect(
    function () {
      queryBillLatest()
    },
    [queryBillLatest]
  )

  // 账单列表
  const queryBillList = useCallback(
    function () {
      billModel.userLessorBillList(params).then(({response: {data, total}}) => {
        // console.log('data =>', data)
        setBillList(data)
        setBillTotal(total)
      })
      billModel.userLessorBillStatistics(params).then(({response: {data}}) => setBillStatistics(data))
    },
    [params]
  )

  useEffect(
    function () {
      queryBillList()
    },
    [queryBillList]
  )

  // 查待付款账单列表
  const queryUnpayList = useCallback(function (queryParams: Partial<LessorBillListDTO>) {
    billModel.userLessorBillList(queryParams).then(({response: {data, total}}) => {
      setUnpayList(data)
      setUnpayTotal(total)
    })
  }, [])

  useEffect(
    function () {
      if (firVisible) {
        queryUnpayList(unpayParams)
      }
    },
    [firVisible, queryUnpayList, unpayParams]
  )

  // 提交付款凭证
  async function billConfirm() {
    const values = billForm.getFieldsValue(true)
    const [imgItem] = imgList
    const params = {
      ...values,
      billPayTime: dayjs(values.billPayTime).format('YYYY-MM-DD HH:mm:ss') ?? '',
      billPayWayName: payWayCode[values.billPayWayCode],
      imgUrl: imgItem?.imgUrl ?? '',
    }
    await billModel.userLessorBillConfirm(params)
    setSecVisible(false)
    message.success('提交成功！')
    billForm.resetFields()
    queryBillList()
    queryBillLatest()
  }

  function dateFormat(strDate: string, format = 'YYYY-MM-DD') {
    return dayjs(strDate).format(format) ?? ''
  }

  function renderListOperate({billStatus, billName, billId, billAmtTotal}) {
    if (['1', '4'].includes(billStatus)) {
      const isCheck = billStatus === '1'
      if (isCheck) {
        return (
          <Permission code={'009003002'}>
            <Button
              type={'link'}
              style={{padding: 0}}
              onClick={async event => {
                event.stopPropagation()
                setSecVisible(true)
                billForm.setFieldsValue({
                  billName: billName,
                  billIdList: [billId],
                  billPayAmt: billAmtTotal,
                })
              }}
            >
              {'提交付款凭证'}
            </Button>
          </Permission>
        )
      }

      return (
        <Permission code={'009003003'}>
          <Button
            type={'link'}
            style={{padding: 0}}
            onClick={async event => {
              event.stopPropagation()
              await billModel.userLessorBillPending({billId})
              message.success('审批成功！')
              queryBillList()
            }}
          >
            {'审批通过'}
          </Button>
        </Permission>
      )
    } else return null
  }

  return (
    <>
      <Breadcrumb
        path={['管家服务', '管家账单']}
        right={
          <Space>
            {auth('009003004') && (
              <Button
                onClick={() => {
                  Modal.confirm({
                    title: '提示',
                    icon: null,
                    closable: true,
                    content: (
                      <span>
                        本次导出表格为<span style={{color: '#007BFF'}}> 管家账单 </span>统计报表，共计
                        <span style={{color: '#007BFF'}}> {billTotal} </span>条数据，是否确认导出？
                      </span>
                    ),
                    okText: '确认导出',
                    centered: true,
                    onOk: () => downloadBlob({url: '/user/lessor/bill/list-excel', body: {...params}}),
                  })
                }}
              >
                导出
              </Button>
            )}
            {auth('009003002') && (
              <Button
                onClick={async () => {
                  const {
                    response: {total},
                  } = await billModel.userLessorBillList({billStatus: '1', pageNum: 1, pageSize: 10})
                  if (total) {
                    setFirVisible(true)
                  } else message.warning('暂无待付款账单！')
                }}
              >
                批量提交付款凭证
              </Button>
            )}
          </Space>
        }
      />
      {!!billLatest.length && (
        <div style={{backgroundColor: '#fff', padding: '26px 24px'}}>
          <Table
            dataSource={billLatest}
            columns={[
              {title: '账单名称', dataIndex: 'billName', key: 'billName'},
              {title: '账单类型', dataIndex: 'billType', key: 'billType', render: text => billTypeCode[text] ?? ''},
              {
                title: '账单周期',
                render: (_, record: LessorBillVO) =>
                  [dateFormat(record.billBegin), dateFormat(record.billEnd)].filter(item => item).join('至'),
              },
              {title: '应付金额', dataIndex: 'billAmtTotal', key: 'billAmtTotal'},
              {title: '应付款日', dataIndex: 'billDate', key: 'billDate', render: text => dateFormat(text)},
              {
                title: '状态',
                dataIndex: 'billStatus',
                key: 'billStatus',
                render: (text, record: LessorBillVO) => {
                  if (record.billStatus === '1')
                    return `${billCode[text]}${record.overdueDays ? '（逾期' + record.overdueDays + '天）' : ''}`
                  return billCode[text] ?? ''
                },
              },
              {title: '操作', render: (_, record: LessorBillVO) => renderListOperate({...record})},
            ]}
            onRow={(record, index) => {
              return {
                onClick: function () {
                  detailRef.current?.onOpen(record.billId)
                },
              }
            }}
            pagination={false}
          />
        </div>
      )}
      <div style={{backgroundColor: '#fff', padding: '20px 24px', marginTop: '20px'}}>
        <Form
          form={form}
          onFinish={values => {
            let billCreateTimeBegin, billCreateTimeEnd, billDateBegin, billDateEnd
            if (values.billCreateTime) {
              const [timeBegin, timeEnd] = values.billCreateTime.map(item => dayjs(item).format('YYYY-MM-DD HH:mm:ss'))
              billCreateTimeBegin = timeBegin
              billCreateTimeEnd = timeEnd
            }
            if (values.billDate) {
              const [dateBegin, dateEnd] = values.billDate.map(item => dayjs(item).format('YYYY-MM-DD HH:mm:ss'))
              billDateBegin = dateBegin
              billDateEnd = dateEnd
            }
            setParams(prevState => ({
              ...values,
              billCreateTimeBegin,
              billCreateTimeEnd,
              billDateBegin,
              billDateEnd,
              pageNum: 1,
            }))
          }}
        >
          <Row>
            <Col span={7}>
              <Form.Item name={'billType'} label={'费用类型'}>
                <Select placeholder={'请选择'}>
                  {billTypeArray.map(item => (
                    <Option value={item.id} key={item.id}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name={'billStatus'} label={'账单状态'}>
                <Select placeholder={'请选择'}>
                  {billArray.map(item => (
                    <Option value={item.id} key={item.id}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name={'billDate'} label={'应付款日'}>
                <RangePicker />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <Form.Item name={'billCreateTime'} label={'账单生成日期'}>
                <RangePicker showTime />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'}>
                查询
              </Button>
              <Button
                onClick={() => {
                  form.resetFields()
                  setParams({pageNum: 1, pageSize: 10})
                }}
              >
                重置
              </Button>
            </Space>
          </Row>
        </Form>
      </div>
      <div style={{backgroundColor: '#fff', padding: '20px 25px', marginTop: '20px'}}>
        <Space style={{marginBottom: 20}} size={21}>
          <Typography.Title style={{marginBottom: 0}} level={4}>
            查询结果
          </Typography.Title>
          <Space size={20}>
            <Total
              title={'总账单'}
              amount={billStatistics.totalStatisticsTotal}
              num={billStatistics.totalStatisticsNum}
            />
            <Total
              title={'待审批'}
              amount={billStatistics.customerPendingStatisticsTotal}
              num={billStatistics.customerPendingStatisticsNum}
            />
            <Total
              title={'待付款'}
              amount={billStatistics.waitingStatisticsTotal}
              num={billStatistics.waitingStatisticsNum}
            />
            <Total
              title={'平台审核中'}
              amount={billStatistics.pendingStatisticsTotal}
              num={billStatistics.pendingStatisticsNum}
            />
            <Total
              title={'已付款'}
              amount={billStatistics.paidStatisticsTotal}
              num={billStatistics.paidStatisticsNum}
            />
          </Space>
        </Space>
        <Table
          rowKey={'billId'}
          loading={billListLoading}
          dataSource={billList}
          columns={[
            {title: '账单名称', dataIndex: 'billName', key: 'billName'},
            {
              title: '账单类型',
              dataIndex: 'billType',
              key: 'billType',
              render: text => billTypeCode[text] ?? '',
            },
            {
              title: '账单周期/账单生成日期',
              dataIndex: 'billCreateTime',
              key: 'billCreateTime',
              render: (text, record: LessorBillVO) => {
                if (record.billType === '2') return text ?? ''
                return [dateFormat(record.billBegin), dateFormat(record.billEnd)].filter(item => item).join('至')
              },
            },
            {
              title: '应付金额/应付款日',
              render: (_, record: LessorBillVO) => {
                return (
                  <>
                    <Typography>{record.billAmtTotal}</Typography>
                    <Typography>{dateFormat(record.billDate)}</Typography>
                  </>
                )
              },
            },
            {
              title: '状态',
              dataIndex: 'billStatus',
              key: 'billStatus',
              render: (text, record: LessorBillVO) => {
                if (record.billStatus === '1')
                  return `${billCode[text]}${record.overdueDays ? '（逾期' + record.overdueDays + '天）' : ''}`
                return billCode[text] ?? ''
              },
            },
            {title: '操作', render: (_, record: LessorBillVO) => renderListOperate({...record})},
          ]}
          onRow={(record, index) => {
            return {
              onClick: function () {
                detailRef.current?.onOpen(record.billId, record.billType === '2')
              },
            }
          }}
          pagination={{
            total: billTotal,
            current: params.pageNum,
            pageSize: params.pageSize,
            position: ['bottomCenter'],
            showSizeChanger: true,
            onChange: page => setParams(prevState => ({...prevState, pageNum: page})),
            onShowSizeChange: (_, size) => setParams(prevState => ({...prevState, pageNum: 1, pageSize: size})),
          }}
        />
      </div>

      <StewardBillDetail
        ref={detailRef}
        onSubmit={({billName, billIdList, billPayAmt}) => {
          billForm.setFieldsValue({
            billName,
            billIdList,
            billPayAmt,
          })
          setSecVisible(true)
        }}
        onPending={() => {
          queryBillList()
        }}
      />

      {/* 批量提交付款凭证 */}
      <Modal
        visible={firVisible}
        centered
        title={'批量提交付款凭证'}
        footer={null}
        width={'60%'}
        onCancel={() => setFirVisible(false)}
      >
        <Table
          rowSelection={{
            onChange: (selectedRowKeys, selectedRows) => {
              setSelectList(selectedRows)
            },
            selectedRowKeys: selectList.map(item => item.billId),
          }}
          rowKey={'billId'}
          loading={billListLoading}
          dataSource={unpayList}
          columns={[
            {title: '账单名称', dataIndex: 'billName', key: 'billName'},
            {
              title: '账单类型',
              dataIndex: 'billType',
              key: 'billType',
              render: text => billTypeCode[text] ?? '',
            },
            {
              title: '账单周期/账单生成日期',
              dataIndex: 'billDate',
              key: 'billDate',
              render: (text, record: LessorBillVO) => {
                if (record.billType === '2') return text ?? ''
                return [dateFormat(record.billBegin), dateFormat(record.billEnd)].filter(item => item).join('至')
              },
            },
            {
              title: '应付金额/应付款日',
              render: (_, record: LessorBillVO) => {
                return (
                  <>
                    <Typography>{record.billAmtTotal}</Typography>
                    <Typography>{dateFormat(record.billDate)}</Typography>
                  </>
                )
              },
            },
            {
              title: '状态',
              dataIndex: 'billStatus',
              key: 'billStatus',
              render: (text, record: LessorBillVO) => {
                if (record.billStatus === '1')
                  return `${billCode[text]}${record.overdueDays ? '（逾期' + record.overdueDays + '天）' : ''}`
                return billCode[text] ?? ''
              },
            },
          ]}
          pagination={{
            size: 'default',
            total: unpayTotal,
            current: unpayParams.pageNum,
            pageSize: unpayParams.pageSize,
            position: ['bottomCenter'],
            showSizeChanger: true,
            onChange: page => setUnpayParams(prevState => ({...prevState, pageNum: page})),
            onShowSizeChange: (_, size) => setUnpayParams(prevState => ({...prevState, pageNum: 1, pageSize: size})),
          }}
        />
        <Row justify={'end'}>
          <Button
            type={'primary'}
            onClick={() => {
              if (!selectList.length) return message.warn('请选择账单！')
              billForm.setFieldsValue({
                billPayAmt: selectList.reduce((sum, item) => (sum += item.billAmtTotal), 0),
                billIdList: selectList.map(item => item.billId),
              })
              setFirVisible(false)
              setSecVisible(true)
            }}
          >
            下一步
          </Button>
        </Row>
      </Modal>

      {/* 提交付款凭证 */}
      <Modal
        visible={secVisible}
        centered
        title={<div style={{textAlign: 'center'}}>提交付款凭证</div>}
        footer={null}
        onCancel={() => {
          setSecVisible(false)
          setSelectList([])
        }}
      >
        <Form form={billForm} labelCol={{span: 6}}>
          {!selectList.length && (
            <Col span={16}>
              <Form.Item label={'账单名称'}>
                <Typography.Text>{billForm.getFieldValue('billName')}</Typography.Text>
              </Form.Item>
            </Col>
          )}
          <Col span={16}>
            <Form.Item label={'账单金额'}>
              <Typography.Text>{billForm.getFieldValue('billPayAmt')}</Typography.Text>
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item name={'billPayWayCode'} label={'付款方式'} rules={[{required: true, message: '请选择付款方式'}]}>
              <Select placeholder={'请选择'}>
                {payWayArray.map(item => (
                  <Option value={item.id} key={item.id}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item name={'billPayTime'} label={'付款时间'} rules={[{required: true, message: '请选择付款时间'}]}>
              <DatePicker showTime style={{width: '100%'}} />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item label={'照片凭证'}>
              <Album
                limit={1}
                action={baseUrl + '/user/lessor/bill/upload'}
                onDone={fileList => setImgList(fileList)}
              />
            </Form.Item>
          </Col>
          <Col span={22}>
            <Form.Item name={'billDetailRemark'} label={'备注'} labelCol={{span: 4}}>
              <Input.TextArea />
            </Form.Item>
          </Col>
          <Row justify={'end'}>
            <Space>
              <Button
                onClick={() => {
                  setSecVisible(false)
                  setSelectList([])
                }}
              >
                取消
              </Button>
              <Button type={'primary'} onClick={billConfirm}>
                保存提交
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

const HightLightText: React.FC = props => {
  return <Typography.Text style={{color: '#007BFF'}}>{props.children}</Typography.Text>
}

const transAmount = (value: number) => {
  if (typeof value !== 'number') return [value, '']
  if (value < 9999) return [value, '元']
  if (value < 9999 * 10000) return [Math.round(value / 1000) / 10, '万']
  return [(value / 100000000).toFixed(1), '亿']
}

const Total: React.FC<{num: number; amount: number; title: string}> = props => {
  const [amount, unit] = transAmount(props.amount)
  return (
    <Typography.Text>
      {props.title}
      <HightLightText>{amount}</HightLightText>
      {unit}(<HightLightText>{props.num}</HightLightText>笔)
    </Typography.Text>
  )
}
