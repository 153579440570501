import React, {useEffect, useState} from 'react'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import commonStyle from '@/assets/styles/common.module.less'
import {Button, Col, Divider, Form, Input, message, Modal, Radio, Row, Space, Typography} from 'antd'
import {DownOutlined, UpOutlined} from '@ant-design/icons'
import {contractModel} from '@/store/models/Contract'
import {useHistory, useLocation} from 'react-router'
import {baseUrl} from '@/common/config'

export default function TemplateContractAdd() {
  const history = useHistory()
  const location = useLocation<{templateId: string}>()
  const templateId = location.state?.templateId

  const iframeRef = React.useRef(null)

  const [form] = Form.useForm()
  const [ellipsis, setEllipsis] = useState(true)

  const [edit, setEdit] = useState(false)
  const [preview, setPreview] = useState(false)

  const insertLoading = contractModel.contractTemplateInsert.useLoading()

  useEffect(
    function () {
      if (templateId) {
        contractModel.contractTemplateDetail({templateId}).then(({response: {data}}) => form.setFieldsValue(data))
      }
    },
    [form, templateId]
  )

  return (
    <>
      <Breadcrumb path={['首页', '设置', '合同模板设置']} />
      <div className={commonStyle.section}>
        <h4 style={{fontWeight: 'bold'}}>新增合同模版</h4>
        <Divider dashed />
        <Form form={form} labelCol={{span: 4}} labelAlign={'left'} initialValues={{templateType: 'XQ'}}>
          <Row>
            <Col span={12}>
              <Form.Item name={'templateType'} label={'合同类型'}>
                <Radio.Group>
                  <Radio value={'XQ'}>新签合同</Radio>
                  {/*<Radio value={'XZ'}>续租合同</Radio>*/}
                  {/*<Radio value={'HF'}>换房合同</Radio>*/}
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12} offset={1} pull={1}>
              <Form.Item
                name={'templateName'}
                label={'模板名称'}
                rules={[{required: true, message: '请输入模板名称'}]}
                required={false}
              >
                <Input placeholder={'请输入'} disabled={!!templateId} />
              </Form.Item>
            </Col>
            <Col span={12} offset={1} pull={1}>
              <Form.Item name={'notes'} label={'备注信息'}>
                <Input.TextArea placeholder={'请输入'} maxLength={100} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      <div className={commonStyle.section}>
        <h4 style={{fontWeight: 'bold'}}>房屋租赁合同</h4>
        <Divider dashed />
        <iframe
          frameBorder={0}
          width={'100%'}
          height={ellipsis ? '120px' : '900px'}
          scrolling={'no'}
          src={`${baseUrl}/template/online/v2`}
          ref={iframeRef}
        />
        <Row justify={'center'}>
          <Space size={0}>
            <Button type={'link'} onClick={() => setEllipsis(prevState => !prevState)}>
              {ellipsis ? '点击查看全文' : '点击收回'}
            </Button>
            {ellipsis ? <DownOutlined style={{color: '#017BFF'}} /> : <UpOutlined style={{color: '#017BFF'}} />}
          </Space>
        </Row>
      </div>

      <div className={commonStyle.section}>
        <h4 style={{fontWeight: 'bold'}}>自定义条款</h4>
        <Divider dashed />
        <Form form={form}>
          <Form.Item name={'templateAttach'} rules={[{required: true, message: '请输入自定义条款'}]}>
            <Input.TextArea placeholder={'请输入自定义条款'} maxLength={10000} style={{height: '150px'}} />
          </Form.Item>
        </Form>
        <Row justify={'center'}>
          <Space>
            <Button onClick={() => setEdit(true)}>取消编辑</Button>
            <Button
              type={'primary'}
              onClick={async () => {
                await form.validateFields()
                setPreview(true)
              }}
            >
              预览使用
            </Button>
          </Space>
        </Row>
      </div>

      <Modal visible={edit} title={'提示'} centered onCancel={() => setEdit(false)} onOk={() => history.goBack()}>
        取消编辑后将不保存合同模板内容，是否取消编辑
      </Modal>

      <Modal visible={preview} width={'60%'} footer={null} onCancel={() => setPreview(false)}>
        <iframe frameBorder={0} width={'100%'} height={'600px'} src={`${baseUrl}/template/online/v2`} />
        <Typography.Paragraph style={{whiteSpace: 'pre-wrap', fontFamily: 'SimHei,serif', fontSize: '12px'}}>
          {form.getFieldValue('templateAttach')}
        </Typography.Paragraph>
        <Row justify={'end'}>
          <Space>
            <Button onClick={() => setPreview(false)}>取消</Button>
            <Button
              type={'primary'}
              loading={insertLoading}
              onClick={async () => {
                const formValues = form.getFieldsValue(true)
                if (templateId) {
                  await contractModel.contractTemplateUpdate(formValues)
                  message.success('编辑成功！')
                } else {
                  await contractModel.contractTemplateInsert(formValues)
                  message.success('新增成功！')
                }
                setPreview(false)
                history.goBack()
              }}
            >
              确认使用
            </Button>
          </Space>
        </Row>
      </Modal>
    </>
  )
}
