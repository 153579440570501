import React, {useState, useEffect, useMemo, useCallback} from 'react'
import {Typography, Row, Col, Form, Table, DatePicker, Checkbox, Space, Button, Divider, Select} from 'antd'
import {propertyModel} from '@/store/models/Property'
import commonStyles from '@/assets/styles/common.module.less'
import moment from 'moment'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import {downloadBlob} from '@/utils/util'
import {toFixed} from '@/utils/amount'
import {payModel} from '@/store/models/Pay'
import {useAppHistory} from '@/common/hooks/useRouter'
import {lessorModel} from '@/store/models/Lessor'
import {tradeModel} from '@/store/models/Trade'
import {userModel} from '@/store/models/User'

const {RangePicker} = DatePicker
const CheckboxGroup = Checkbox.Group
const {Text} = Typography

interface AccountListProp {
  id: string
  title: string
}

export default function MyAccount() {
  const history = useAppHistory()
  const [form] = Form.useForm()
  const userInfo = userModel.useData(data => data.userInfo)
  const [userId, setUserId] = useState(userInfo.userId)
  const [params, setParams] = useState({
    pageNum: 1,
    pageSize: 10,
    needStatistics: '0',
    userId: userInfo.userId,
  } as TradeListQueryDTO)
  const [tableList, setTableList] = useState<TradeVO[]>([])
  const [total, setTotal] = useState(0)
  const [allAmount, setAllAmount] = useState<string>('')
  const loading = tradeModel.tradeWalletFlowList.useLoading()
  const options = [
    {label: '出账', value: 'out'},
    {label: '进账', value: 'in'},
  ]
  const [accountList, setAccountList] = useState<AccountListProp[]>([{id: userInfo.userId, title: '我的账户'}])
  //选中的收支类型
  const [defaultFlowType, setDefaultFlowType] = useState([])
  const [checkAll, setCheckAll] = useState(false)
  //查询录入过的小区
  const [communityList, setCommunityList] = useState<CommunityVO[]>([])
  useEffect(() => {
    tradeModel.tradeWalletFlowList(params).then(({response: {data, total}}) => {
      setTableList(data.tradeFlowList)
      setTotal(total)
    })
  }, [params])

  const [companyList, setCompanyList] = useState<LessorCompanyVO[]>([])
  const [applyCompanyList, setApplyCompanyList] = useState<LessorCompanyApplyVO[]>([])

  //查询企业账户
  const queryCompanyList = useCallback(async () => {
    const {
      response: {data: list1},
    } = await lessorModel.lessorCompanyList({queryType: 1} as LessorCompanyQueryDTO)
    setCompanyList(list1)

    if (list1.length) {
      setAccountList(state => state.concat(list1.map(item => ({id: item.companyId, title: item.companyName}))))
    } else {
      const {
        response: {data: list2},
      } = await lessorModel.lessorCompanyApplyList({auditStatus: '1', pageSize: 1} as LessorCompanyApplyQueryDTO)
      setApplyCompanyList(list2)
    }
  }, [])

  useEffect(() => {
    queryCompanyList()
  }, [queryCompanyList])

  //查询账户明细
  const queryBalance = function (id?: string) {
    payModel.payBalance({userId: id}).then(({response: {data}}) => {
      setAllAmount(toFixed(+data.allAmount / 100))
    })
  }

  useEffect(() => {
    //查询小区或公寓
    propertyModel.propertyCommunityList({queryType: 2}).then(({response: {data}}) => {
      setCommunityList(data)
    })

    queryBalance()
  }, [])
  const communityOption = useMemo(() => communityList.map(item => ({label: item.name, value: item.code})), [
    communityList,
  ])
  const communityCodeList = useMemo(() => communityList.map(item => item.code), [communityList])
  //选中小区
  const [defaultCommunity, setDefaultCommunity] = useState([])
  const [communityCheckAll, setCommunityCheckAll] = useState(false)

  return (
    <>
      <Breadcrumb
        path={['首页', '我的账户', '账户明细']}
        right={
          <Space>
            {companyList.length ? null : (
              <Button
                type={'primary'}
                onClick={() => {
                  history.push({pathname: '/company-account'})
                }}
                disabled={!!applyCompanyList.length}
              >
                {applyCompanyList.length ? '审核中' : '开通企业账户'}
              </Button>
            )}
            <Button onClick={() => downloadBlob({url: '/trade/wallet-flow/list-excel', body: params})}>导出</Button>
          </Space>
        }
      />
      <div className={commonStyles.section}>
        <Row justify={'space-between'} align={'middle'}>
          <Col style={{display: 'flex', flexDirection: 'column'}}>
            <Text>账号余额</Text>
            <Text style={{fontSize: '28px', fontWeight: 'bold', color: '#333333'}}>{allAmount ?? '0.00'}</Text>
          </Col>
          <Space>
            <span>当前账户：</span>
            <Select
              style={{width: 200}}
              defaultValue={userInfo.userId}
              onSelect={id => {
                queryBalance(id)
                setUserId(id)
                setParams(state => ({...state, userId: id}))
              }}
            >
              {accountList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.title}
                </Select.Option>
              ))}
            </Select>
          </Space>
        </Row>
      </div>
      <div className={commonStyles.section}>
        <Form
          form={form}
          onFinish={async values => {
            const {time} = values
            const [tradeDateBegin, tradeDateEnd] = time?.length
              ? time.map(item => moment(item).format('YYYY-MM-DD'))
              : []
            setParams(state => ({
              ...state,
              tradeDateBegin,
              tradeDateEnd,
              pageNum: 1,
              tradeFlow: defaultFlowType.length !== options.length ? defaultFlowType.join(',') : '',
              communityCode: defaultCommunity.length !== communityList.length ? defaultCommunity.join(',') : '',
            }))
          }}
        >
          <Form.Item label={'交易日期'} name={'time'}>
            <RangePicker />
          </Form.Item>
          <Divider dashed />
          <Form.Item label={'收支类型'}>
            <Checkbox
              indeterminate={defaultFlowType.length && defaultFlowType.length < options.length}
              checked={checkAll}
              onChange={e => {
                const list = e.target.checked ? ['in', 'out'] : []
                setDefaultFlowType(list)
                setCheckAll(e.target.checked)
              }}
            >
              全部
            </Checkbox>
            <CheckboxGroup
              options={options}
              onChange={checkedList => {
                const checkAllResult = checkedList.length === options.length
                setCheckAll(checkAllResult)
                setDefaultFlowType(checkedList)
              }}
              value={defaultFlowType}
            />
          </Form.Item>
          <Form.Item label={'公寓/小区名'}>
            <Checkbox
              indeterminate={defaultCommunity.length && defaultCommunity.length < communityOption.length}
              checked={communityCheckAll}
              onChange={e => {
                const list = e.target.checked ? communityCodeList : []
                setDefaultCommunity(list)
                setCommunityCheckAll(e.target.checked)
              }}
            >
              全部
            </Checkbox>
            <CheckboxGroup
              options={communityOption}
              value={defaultCommunity}
              onChange={values => {
                const checkAllResult = values.length === communityOption.length
                setCommunityCheckAll(checkAllResult)
                setDefaultCommunity([...values])
              }}
            />
          </Form.Item>
          <Form.Item>
            <Row justify={'end'}>
              <Space>
                <Button type='primary' htmlType={'submit'}>
                  查询
                </Button>
                <Button
                  onClick={() => {
                    form.resetFields()
                    setParams({pageNum: 1, pageSize: 10, userId} as TradeListQueryDTO)
                    setCommunityCheckAll(false)
                    setCheckAll(false)
                    setDefaultCommunity([])
                    setDefaultFlowType([])
                  }}
                >
                  重置
                </Button>
              </Space>
            </Row>
          </Form.Item>
        </Form>
      </div>
      <div className={commonStyles.section}>
        <Table
          rowKey={'tradeId'}
          loading={loading}
          columns={[
            {title: '收支日期', dataIndex: 'tradeTime', key: 'tradeTime'},
            {title: '地址', dataIndex: 'propertyTitle', key: 'propertyTitle'},
            {
              title: '租客姓名',
              dataIndex: 'lesseeCertName',
              key: 'lesseeCertName',
            },
            {
              title: '收支类型',
              dataIndex: 'tradeFlow',
              key: 'tradeFlow',
              render: text => (text === 'in' ? '进账' : '出账'),
            },
            {title: '账单项目', dataIndex: 'tradeTypeName', key: 'tradeTypeName'},
            {title: '收支金额（元）', dataIndex: 'tradeAmt', key: 'tradeAmt'},
          ]}
          dataSource={tableList}
          pagination={{
            total,
            current: params.pageNum,
            pageSize: params.pageSize,
            position: ['bottomCenter'],
            onChange: (pageNum, pageSize) => {
              setParams(prevState => ({...prevState, pageNum, pageSize}))
            },
            showSizeChanger: true,
          }}
        />
      </div>
    </>
  )
}
