import {Model} from '@redux-model/web'
import {$api} from '@/store/service'

export interface TradeModel {}

class Trade extends Model<TradeModel> {
  protected initReducer(): TradeModel {
    return {}
  }

  // 首页交易流水统计
  homepageStatistics = $api.action(() => this.post<ApiResponse<TradeHomepageStatistics>>('/trade/homepage/statistics'))

  //房东web交易流水统计
  tradeWebFlowList = $api.action((args: Partial<TradeListQueryWebDTO>) =>
    this.post<ApiResponse<TradeVO[]>>('/trade/web/flow/list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  //房东web交易流水进出账统计
  tradeWebStatistics = $api.action((args: Partial<TradeListQueryWebDTO>) =>
    this.post<ApiResponse<TradeStatisticsWebVO>>('/trade/web/flow/list/statistics').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  //房东web-交易流水数据Excel文件路径返回
  tradeDataExcelPath = $api.action((args: Partial<TradeListQueryDTO>) =>
    this.post<ApiResponse>('/trade/data-excel-path').body({
      pageNum: 1,
      pageSize: 10,
      pageable: false,
      ...args,
    })
  )

  //房东web-批量选中流水数据Excel文件路径返回
  tradeBatchDataExcelPath = $api.action((ids: string[]) =>
    this.post<ApiResponse>('/trade/batch/data-excel-path').body({ids})
  )

  // 新增交易流水
  tradeInsert = $api.action((args: Partial<TradeInsertBatchDTO>) => this.post('/trade/insert').body(args))

  // 查询空置损失列表
  propertyUnrentLostList = $api.action((args: PropertyUnrentLostListDTO) =>
    this.post<ApiResponse<PropertyUnrentLostVO[]>>('/property/unrent-lost/list').body(args)
  )

  // 查询空置损失统计
  propertyUnrentLostStatistics = $api.action((args: PropertyUnrentLostListDTO) =>
    this.post<ApiResponse<PropertyUnrentLostVO>>('/property/unrent-lost/statistics').body(args)
  )
  // 营业报表-流水报表count
  tradeOperationCount = $api.action((args: TradeOperationListQueryDTO) =>
    this.post<ApiResponse<number>>('/trade/operation/count').body(args)
  )

  // 营业报表-流水报表
  tradeOperationList = $api.action((args: TradeOperationListQueryDTO) =>
    this.post<ApiResponse<TradeOperationListVO[]>>('/trade/operation/list').body({
      pageable: true,
      pageNum: 1,
      pageSize: 10,
      ...args,
    })
  )

  // 营业报表-流水报表sum
  tradeOperationSum = $api.action((args: TradeOperationListQueryDTO) =>
    this.post<ApiResponse<TradeOperationListVO>>('/trade/operation/sum').body(args)
  )

  // 查询房东房源统计
  propertyStatisticsList = $api.action((args: Partial<PropertyStatisticsListDTO>) =>
    this.post<ApiResponse<PropertyStatisticsVO[]>>('/property/statistics/list').body(args)
  )

  // 账单及组织余额提示(展示)
  lessorHomeBillAndBalanceStatic = $api.action(() => this.post<ApiResponse>('/lessor/home/bill-and-balance-static'))

  // 交易流水-核销
  tradeWriteOff = $api.action((args: string[]) => this.post<ApiResponse<string[]>>('/trade/write-off').body(args))

  // 交易流水-删除流水  |  object:{tradeId:流水id,remark:备注,code:安全码}
  tradeDelete = $api.action((args: Record<string, any>) => this.post<ApiResponse<string[]>>('/trade/delete').body(args))

  // 交易流水-恢复流水  |  object:{tradeId:流水id,code:安全码}
  tradeRecover = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<string[]>>('/trade/recover').body(args)
  )

  // 钱包-流水列表(通联)
  tradeWalletFlowList = $api.action((args: TradeListQueryDTO) =>
    this.post<ApiResponse<TradeListAndStatisticVO>>('/trade/wallet-flow/list').body(args)
  )

  // WEB首页-账单收款-统计('07'类型为租客支付)  |  object:{tradeDate:交易日期}
  tradeHomepageStatisticsIncome = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<TradeStatisticsIncomeVO[]>>('/trade/homepage/statistics-income').body(args)
  )

  // WEB首页-流水看板-折线图  |  object:{begin:开始时间,end:结束时间}
  tradeHomepageStatisticsChart = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<TradeStatisticsVO[]>>('/trade/homepage/statistics-chart').body(args)
  )
}

export const tradeModel = new Trade()
