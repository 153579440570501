export const isProd = process.env.REACT_APP_ENV === 'prod'

// export const avocondoService = isProd ? 'https://service.avocadocondo.com' : 'https://service-uat.avocadocondo.com'

export const nyg8Service = isProd ? 'https://service.nyg8.com' : 'https://service-uat.nyg8.com'
export const baseUrl = nyg8Service + '/biz'

// 文件地址
export const fileUrl = isProd
  ? // ? 'https://nyg8-test.oss-cn-zhangjiakou.aliyuncs.com/'
    'https://oss.nyg8.com/'
  : 'https://oss-uat.nyg8.com/'
export const ossUrl = fileUrl

export const photoLimitSize = 10 * 1024 * 1024 // 10m

// 检查更新的地址
export const updateCheckUrl = 'https://static.avocadocondo.com/nyg8/hot-update/landlord.json'

// pdf 文件路径
export const pdfUrl = fileUrl + 'pdfjs/web/viewer.html?file=../../'

export const ak = '2beA3i1trGn6Rib1XgNwOyHWbl8HlPt9'
