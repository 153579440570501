import React from 'react'
import commonStyles from '@/assets/styles/common.module.less'
import {Button, Divider, Form, Input, Select, Typography} from 'antd'
import styles from './index.module.less'
import {Album} from '@/ui/components/Album'
import {baseUrl} from '@/common/config'
import {teamModel} from '@/store/models/Team'
import {useHistory} from 'react-router'

const {Option} = Select

export default function EnterpriseCer() {
  const history = useHistory()
  const {teamId} = history.location.state as any

  const [form] = Form.useForm()
  const loading = teamModel.teamAuthRecordInsert.useLoading()

  async function onFinish() {
    const params = form.getFieldsValue(true)
    console.log('params =>', {...params, teamId})

    await teamModel.teamAuthRecordInsert({...params, teamId} as TeamAuthSubmitDTO)
    history.push('/success', {cerType: '1'})
  }

  return (
    <>
      <div className={commonStyles.flexFullSection}>
        <Typography.Text className={styles.cerTitle}>企业认证</Typography.Text>
        <Divider className={styles.cerDivider} />
        <div className={styles.cerForm}>
          <Typography.Text className={styles.cerDesc}>行业资质认证</Typography.Text>
          <Form form={form} labelCol={{span: 6}} style={{flex: 1}} onFinish={onFinish}>
            <Form.Item label={'商户类别'} name={'teamType'} rules={[{required: true, message: '请选择商户类别'}]}>
              <Select placeholder={'请选择商户类别'}>
                <Option value={'1'}>职业房东</Option>
                <Option value={'2'}>公寓机构</Option>
                <Option value={'3'}>中介机构</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label={'营业执照'}
              shouldUpdate
              name={'bizLicense'}
              rules={[{required: true, message: '请上传营业执照'}]}
            >
              <Album
                limit={1}
                action={baseUrl + '/team/auth/img/upload'}
                showUploadList={true}
                onDone={fileList => {
                  console.log('fileList =>', fileList)
                  form.setFieldsValue({
                    bizLicense: fileList.map(item => item.imgUrl).pop(),
                  })
                }}
              />
            </Form.Item>
            <Form.Item label={'企业名称'} name={'companyName'} rules={[{required: true, message: '请输入企业名称'}]}>
              <Input placeholder={'请输入企业名称'} />
            </Form.Item>
            <Form.Item
              label={'公司法人姓名'}
              name={'legalPerson'}
              rules={[{required: true, message: '请输入公司法人姓名'}]}
            >
              <Input placeholder={'请输入公司法人姓名'} />
            </Form.Item>
            <Form.Item
              label={'统一社会信用代码'}
              name={'socialCreditCode'}
              rules={[{required: true, message: '请输入统一社会信用代码'}]}
            >
              <Input placeholder={'请输入统一社会信用代码'} />
            </Form.Item>
            <div className={styles.cerSubmit}>
              <Button type={'primary'} size={'middle'} htmlType={'submit'} loading={loading}>
                保存并提交
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  )
}
