import React, {useEffect, useMemo, useState} from 'react'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import {Button, Col, Divider, InputNumber, message, Row, Switch, Table} from 'antd'
import MeterTeamCommunity from '@/pages/meter/MeterTeamCommunity'
import {propertyModel} from '@/store/models/Property'
import {useCodeArraySync} from '@/store/models/Common'
import styles from './index.module.less'

type MeterValue = {
  typeOpen1: number
  typeOpen2: number
  typeOpen3: number
  typeOpen4: number
  typeUnit1: number
  typeUnit2: number
  typeUnit3: number
  typeUnit4: number
}

export default function MeterSetting() {
  const propertyMeterTypeArr = useCodeArraySync('property.meter.type')

  const unitCode = useMemo(() => ({W: '元/吨', E: '元/度', G: '元/立方米', H: '元/吨'}), [])

  const [current, setCurrent] = useState<{teamId: string; code: string; name: string}>({
    teamId: '',
    code: '',
    name: '',
  })

  const [meterValue, setMeterValue] = useState({} as MeterValue)

  const configLoading = propertyModel.propertyMeterSetConfig.useLoading()

  useEffect(
    function () {
      if (current.teamId || current.code) {
        propertyModel
          .propertyMeterGetConfig({teamId: current.teamId, communityCode: current.code})
          .then(({response: {data}}) => {
            if (data) {
              const {typeOpen1, typeOpen2, typeOpen3, typeOpen4, typeUnit1, typeUnit2, typeUnit3, typeUnit4} = data
              setMeterValue({typeOpen1, typeOpen2, typeOpen3, typeOpen4, typeUnit1, typeUnit2, typeUnit3, typeUnit4})
            } else setMeterValue({} as MeterValue)
          })
      }
    },
    [current]
  )

  return (
    <>
      <Breadcrumb path={['首页', '设置', '水电煤设置']} />
      <div className={styles.meterBody}>
        <Row>
          <Col flex={'228px'} style={{borderRight: '1px solid #E9E9E9'}}>
            <MeterTeamCommunity
              onChange={({teamId, code, name}) => {
                setCurrent({teamId, code, name})
              }}
            />
          </Col>
          <Col flex={'1'}>
            <div className={styles.meterRead}>
              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <span className={styles.currentTitle}>当前所选：{current.name}</span>
                <Button
                  type={'primary'}
                  loading={configLoading}
                  onClick={async () => {
                    await propertyModel.propertyMeterSetConfig({
                      teamId: current.teamId,
                      communityCode: current.code,
                      configType: current.code ? '2' : '3',
                      ...meterValue,
                    })
                    message.success('保存成功！')
                  }}
                >
                  保存
                </Button>
              </div>
              <Divider />
              <Table
                rowKey={'id'}
                columns={[
                  {title: '表类型', dataIndex: 'label'},
                  {
                    title: '单价',
                    render: (_, record, index) => {
                      const {id} = record
                      const key = 'typeUnit' + (index + 1)
                      return (
                        <InputNumber
                          style={{border: 'none'}}
                          placeholder={unitCode[id]}
                          value={meterValue[key]}
                          onChange={value => setMeterValue(prevState => ({...prevState, [key]: value}))}
                        />
                      )
                    },
                  },
                  {
                    title: '状态',
                    render: (_, __, index) => {
                      const key = 'typeOpen' + (index + 1)
                      return (
                        <Switch
                          checked={!!meterValue[key]}
                          onChange={checked => setMeterValue(prevState => ({...prevState, [key]: checked ? 1 : 0}))}
                        />
                      )
                    },
                  },
                ]}
                dataSource={propertyMeterTypeArr}
                pagination={false}
              />
              <div className={styles.declaration}>
                <span className={styles.declaraText}>
                  特此声明：您承诺将依法定制合理水、电、气、网络等相关费用（费用标准可向国家电网、当地燃气集团、自来水公司、网络服务商等服务提供方咨询）。您知悉，因您违规定制虚高价格导致的一切后果需由您本人承担！
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}
