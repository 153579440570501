import React, {useCallback, useImperativeHandle, useRef, useState} from 'react'
import {useCodeSync} from '@/store/models/Common'
import {Mapview} from '@/ui/components/Map'
import {Col, Form, Input, Modal, Row, Select, Space, Typography} from 'antd'
import {propertyModel} from '@/store/models/Property'
import MapLocation from '@/assets/icons/map_location.svg'
import styles from '@/pages/property/AddCondo/index.module.less'
import {useDebounce} from '@/common/hooks/useDebounce'

type PositionForm = Pick<
  CondoPropertyInsertDTO,
  'cityCode' | 'condoAddress' | 'bizCircleCode' | 'lat' | 'lng' | 'districtCode'
>

interface SelectPositionProps<T = any> {
  onChange?: (value: PositionForm, bizCircle: any[]) => void
}

const _SelectPosition = (props: SelectPositionProps, ref) => {
  const [visible, setVisible] = useState(false)
  const supportCityObj = useCodeSync('city')
  const mapRef = useRef<Mapview>(null)
  const [form] = Form.useForm()

  const [bizCircle, setBizCircle] = useState([])

  const queryBizCircle = useCallback(async (point: Point) => {
    const {
      response: {data},
    } = await propertyModel.commonMapGeocoder(point)
    const {
      response: {data: biz},
    } = await propertyModel.queryCityCode({type: '4', parentCode: data.districtCode})
    return {geoc: data, bizCircle: biz}
  }, [])

  useImperativeHandle(ref, () => ({
    show: async (value = {} as PositionForm) => {
      if (value.lat && value.lng) {
        const {bizCircle} = await queryBizCircle(value)
        setBizCircle(bizCircle)
      }
      form.setFieldsValue(value)
      setVisible(true)
    },
  }))

  const enterSelectAddress = useCallback((condoAddress: string) => {
    if (condoAddress) {
      mapRef.current?.panToByPath(condoAddress, 17)
    }
  }, [])

  const selectAddressDebounce = useDebounce(enterSelectAddress, 800, [])
  const isFocus = useRef(false)

  return (
    <Modal
      title={null}
      width={650}
      visible={visible}
      centered={true}
      closable={false}
      onOk={() => form.submit()}
      onCancel={() => setVisible(false)}
      afterClose={() => form.resetFields()}
      destroyOnClose
    >
      <Form
        form={form}
        labelCol={{span: 5}}
        onFinish={() => {
          props.onChange?.(form.getFieldsValue(true), bizCircle)
          setVisible(false)
        }}
      >
        <div style={{width: 600}}>
          <Typography.Title level={4} style={{textAlign: 'center'}}>
            具体位置
          </Typography.Title>
          <Space size={19} direction={'vertical'} style={{padding: '18px 25px', width: '100%'}}>
            <Row>
              <Col span={16}>
                <Form.Item label='详细地址' name='condoAddress' rules={[{required: true, message: '请输入详细地址!'}]}>
                  <Input
                    placeholder={'输入地址'}
                    onPressEnter={(event: any) => enterSelectAddress(event.target.value)}
                    onChange={event => selectAddressDebounce(event.target.value)}
                    onFocus={() => (isFocus.current = true)}
                    onBlur={() => (isFocus.current = false)}
                  />
                </Form.Item>
                <Form.Item
                  label='商圈'
                  name='bizCircleCode'
                  // rules={[{required: true, message: '请选择商圈!'}]}
                >
                  <Select placeholder={'选择商圈'}>
                    {bizCircle.map(item => (
                      <Select.Option value={item.code} key={item.code}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item noStyle shouldUpdate={(prev, next) => prev.cityCode !== next.cityCode}>
              {f => {
                const cityCode = f.getFieldValue('cityCode')
                const lat = f.getFieldValue('lat')
                const lng = f.getFieldValue('lng')
                return (
                  <div style={{position: 'relative'}}>
                    <Mapview
                      style={{height: 330}}
                      ref={mapRef}
                      center={lat && lng ? {lat, lng} : supportCityObj[cityCode]}
                      onMapChange={async event => {
                        const point = event.point
                        const {geoc, bizCircle} = await queryBizCircle(point)
                        setBizCircle(bizCircle)
                        f.setFieldsValue({
                          cityCode: geoc.cityCode,
                          lat: geoc.lat,
                          lng: geoc.lng,
                          districtCode: geoc.districtCode,
                        })
                        if (!isFocus.current) {
                          f.setFieldsValue({condoAddress: geoc.address})
                        }
                        if (bizCircle.every(value => value.code !== f.getFieldValue('bizCircleCode'))) {
                          f.setFieldsValue({bizCircleCode: undefined})
                        }
                      }}
                    />
                    <img src={MapLocation} alt={'icon'} className={styles.location} />
                  </div>
                )
              }}
            </Form.Item>
          </Space>
        </div>
      </Form>
    </Modal>
  )
}
export const SelectPosition = React.forwardRef(_SelectPosition)
