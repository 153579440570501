import React from 'react'
import {Space} from 'antd'

export default function DeviceTableHeader(props: {total: number}) {
  const {total = 0} = props
  return (
    <Space size={20} style={{margin: '20px 0'}}>
      <span style={{fontWeight: 'bold'}}>查询结果</span>
      <span>
        共计<span style={{color: '#007BFF'}}>{total}</span>条符合条件的结果
      </span>
    </Space>
  )
}
