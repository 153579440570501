import React, {useState} from 'react'
import {TeamBar} from '@/pages/property/components/TeamBar'
import {Button} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import styles from './index.module.less'
import {useHistory} from 'react-router-dom'
import Checked from '@/assets/icons/checked.svg'
import PropertyCondo from '@/assets/icons/property_condo.svg'
import PropertyScatter from '@/assets/icons/property_scatter.svg'
import {checkPropertyPermission} from '@/utils/permissions'
import {Breadcrumb} from '@/ui/components/Breadcrumb'

export default function Add() {
  const history = useHistory()
  const [chartType, setChartType] = useState<ChartType>('condo')

  function goNext() {
    if (checkPropertyPermission()) {
      history.push({
        pathname: chartType === 'condo' ? `/add/condo` : `/add/community`,
      })
    }
  }

  return (
    <>
      <Breadcrumb path={['首页', '房源房态', '添加房源']} />
      <TeamBar />
      <div className={`${commonStyles.flexFullSection} ${styles.content}`}>
        <div className={styles.cardContainer}>
          <div onClick={() => setChartType('condo')} className={styles.card}>
            {chartType === 'condo' && <img src={Checked} alt={'icon'} className={styles.cardActive} />}
            <img src={PropertyCondo} alt={'icon'} style={{marginBottom: 27}} />
            <p className={styles.desc}>添加集中式公寓房源</p>
          </div>
          <div onClick={() => setChartType('scatter')} className={styles.card}>
            {chartType === 'scatter' && <img src={Checked} alt={'icon'} className={styles.cardActive} />}
            <img src={PropertyScatter} alt={'icon'} style={{marginBottom: 27}} />
            <p className={styles.desc}>添加分散式房源</p>
          </div>
        </div>
        <Button type={'primary'} onClick={() => goNext()}>
          下一步
        </Button>
      </div>
    </>
  )
}
