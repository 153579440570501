import React, {useEffect, useState} from 'react'
import {Button, Col, Form, Input, Row, Space, Table} from 'antd'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import deviceStyle from '@/pages/device/styles/device.module.less'
import DeviceTableHeader from '@/pages/device/components/DeviceTableHeader'
import {useHistory} from 'react-router'
import {deviceModel} from '@/store/models/Device'

export default function DeviceElectric() {
  const history = useHistory()

  const [form] = Form.useForm()

  const [elemeterParams, setElemeterParams] = useState<Partial<DeviceListDTO>>({pageNum: 1, pageSize: 10})
  const [elemeterList, setElemeterList] = useState<DeviceListVO[]>([])
  const [elemeterCount, setElemeterCount] = useState(0)

  const elemeterLoading = deviceModel.deviceElemeterList.useLoading()

  useEffect(
    function () {
      deviceModel.deviceElemeterList(elemeterParams).then(({response: {data, total}}) => {
        setElemeterList(data)
        setElemeterCount(total)
      })
    },
    [elemeterParams]
  )

  return (
    <>
      <Breadcrumb
        path={['首页', '智能设备', '电表']}
        right={
          <Button type={'primary'} onClick={() => history.push('/device-bind', {deviceType: 'elec'})}>
            绑定电表
          </Button>
        }
      />
      <div className={deviceStyle.formSection}>
        <Form form={form} onFinish={formValues => setElemeterParams(prevState => ({...prevState, ...formValues}))}>
          <Row>
            <Col span={7}>
              <Form.Item name={'keyword'} label={'房源地址'}>
                <Input placeholder={'小区/公寓名/房间号'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name={'lesseeName'} label={'承租人姓名'}>
                <Input placeholder={'请输入姓名'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name={'lesseeMobile'} label={'承租人电话'}>
                <Input placeholder={'请输入电话'} allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'}>
                查询
              </Button>
              <Button
                htmlType={'reset'}
                onClick={() => {
                  form.resetFields()
                  setElemeterParams({pageNum: 1, pageSize: 10})
                }}
              >
                重置
              </Button>
            </Space>
          </Row>
        </Form>
      </div>
      <div className={deviceStyle.tableSection}>
        <DeviceTableHeader total={elemeterCount} />
        <Table
          rowKey={'lesseeId'}
          columns={[
            {title: '房源地址', dataIndex: 'title'},
            {title: '承租人', dataIndex: 'lesseeName'},
            {title: '承租人手机号', dataIndex: 'lesseeMobile'},
            {title: '价格（元/千瓦时）', dataIndex: 'price', render: price => Number(price).toFixed(2)},
            {title: '使用电量（千瓦·时）', dataIndex: 'amount', render: amount => Number(amount).toFixed(2)},
            {
              title: '操作',
              render: record => {
                const {propertyCode, lesseeName, lesseeMobile} = record
                return (
                  <Button
                    type={'link'}
                    style={{padding: 0}}
                    onClick={() => history.push('/device-electric/detail', {propertyCode, lesseeName, lesseeMobile})}
                  >
                    查看
                  </Button>
                )
              },
            },
          ]}
          loading={elemeterLoading}
          dataSource={elemeterList}
          pagination={{
            total: elemeterCount,
            current: elemeterParams.pageNum,
            pageSize: elemeterParams.pageSize,
            position: ['bottomCenter'],
            showSizeChanger: true,
            hideOnSinglePage: false,
            onChange: (pageNum, pageSize) =>
              setElemeterParams(prevState => ({
                ...prevState,
                pageSize,
                pageNum: prevState.pageSize === pageSize ? pageNum : 1,
              })),
          }}
        />
      </div>
    </>
  )
}
