import React, {useCallback, useEffect, useImperativeHandle, useState} from 'react'
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Tabs,
} from 'antd'
import {CloseCircleFilled, PlusOutlined} from '@ant-design/icons/lib'
import KeepAccountStyle from './index.module.less'
import {contractModel} from '@/store/models/Contract'
import {renderChargeItem} from '@/utils/render'
import dayjs from 'dayjs'
import {propertyModel} from '@/store/models/Property'
import {tradeModel} from '@/store/models/Trade'
import {Model} from '@redux-model/web'
import {useCodeSync} from '@/store/models/Common'

const {TabPane} = Tabs
const {Option} = Select
const {RangePicker} = DatePicker

interface AutoCompleteOptions {
  value: string
  label: string
  keys: string
}

function _KeepAccount(props, ref) {
  const contractStatusCode = useCodeSync('contract.status')

  const [visible, setVisible] = useState(false)
  const [activeKey, setActiveKey] = useState('1')
  const [billForm] = Form.useForm()
  const [turnoverForm] = Form.useForm()

  // 租客租约列表
  const [contractList, setContractList] = useState<AutoCompleteOptions[]>([])
  // 房东租约列表
  const [contractLessorList, setContractLessorList] = useState<AutoCompleteOptions[]>([])
  // 房源列表
  const [propertyList, setPropertyList] = useState<AutoCompleteOptions[]>([])
  const contractListLoading = contractModel.queryContractList.useLoading()
  const contractLessorListLoading = contractModel.contractLessorList.useLoading()

  // 费用类型
  const [chargeList, setChargeList] = useState<OtherCost[]>([])
  // 支付渠道
  const [payWayList, setPayWayList] = useState<{id: string; label: string}[]>([])

  const billLoading = Model.isLoading(
    contractModel.contractBillInsert.useLoading(),
    contractModel.contractLessorBillInsert.useLoading()
  )
  const turnoverLoading = tradeModel.tradeInsert.useLoading()

  useEffect(
    function () {
      billForm.setFieldsValue({isPay: 'a', billList: [{}]})
      turnoverForm.setFieldsValue({tradeFlow: 'in', turnoverList: [{}]})
    },
    [billForm, turnoverForm]
  )

  // 查询配置列表
  // TODO：没有公寓id
  useEffect(function () {
    contractModel.lessorConfigContractListSelected().then(({response: {data}}) => {
      setChargeList(renderChargeItem(data.billConfigList))
      setPayWayList(data.payConfigList?.map(item => ({id: item.payWay, label: item.payWayName})))
    })
  }, [])

  // 查业主租约列表
  const queryContractLessorList = useCallback(
    function (keyword: string) {
      contractModel.contractLessorList({keyword}).then(({response: {data}}) => {
        // console.log('lessor data ===>>', data)
        setContractLessorList(
          data.map(item => ({
            label: `${item.propertyTitle} - ${item.lessorCertName} - ${contractStatusCode[item.contractStatus]}`,
            value: `${item.propertyTitle} - ${item.lessorCertName} - ${contractStatusCode[item.contractStatus]}`,
            keys: item.contractId,
          }))
        )
      })
    },
    [contractStatusCode]
  )

  // 查租客租约列表
  const queryContractList = useCallback(
    function (keyword: string) {
      contractModel.queryContractList({keyword}).then(({response: {data}}) => {
        // console.log('data ===>>', data)
        setContractList(
          data.map(item => ({
            label: `${item.propertyTitle} - ${item.lesseeCertName} - ${contractStatusCode[item.contractStatus]}`,
            value: `${item.propertyTitle} - ${item.lesseeCertName} - ${contractStatusCode[item.contractStatus]}`,
            keys: item.contractId,
          }))
        )
      })
    },
    [contractStatusCode]
  )

  // 查询房源列表
  const queryPropertyList = useCallback(function (keyword: string) {
    propertyModel.propertySearch({keyword}).then(({response: {data}}) => {
      // console.log('property data =>', data)
      setPropertyList(data.map(item => ({label: item.title, value: item.title, keys: item.propertyCode})))
    })
  }, [])

  // todo: 租客账单 & 业主账单
  function renderBill() {
    return (
      <Form form={billForm} onFinish={onBillFinish}>
        <Row>
          <Col span={16}>
            {activeKey === '1' ? (
              <Form.Item name={'propertyTitle'} style={{marginBottom: '30px'}}>
                <Select
                  showSearch
                  options={contractList}
                  loading={contractListLoading}
                  placeholder={'请输入小区名/门牌号/客户名'}
                  onSearch={value => queryContractList(value)}
                  onChange={(value, options) =>
                    billForm.setFieldsValue({contractId: options.keys, propertyTitle: value})
                  }
                />
              </Form.Item>
            ) : (
              <Form.Item name={'propertyTitle'} style={{marginBottom: '30px'}}>
                <Select
                  showSearch
                  loading={contractLessorListLoading}
                  options={contractLessorList}
                  placeholder={'请输入小区名/门牌号/客户名'}
                  onSearch={value => queryContractLessorList(value)}
                  onChange={(value, options) =>
                    billForm.setFieldsValue({contractId: options.keys, propertyTitle: value})
                  }
                />
              </Form.Item>
            )}
          </Col>
        </Row>

        <Row align={'middle'}>
          <Col span={5} className={KeepAccountStyle.titleCol}>
            <span className={KeepAccountStyle.titleStyle}>费用类型</span>
          </Col>
          <Col span={1} className={KeepAccountStyle.titleCol} />
          <Col span={8} className={KeepAccountStyle.titleCol}>
            <span className={KeepAccountStyle.titleStyle}>费用周期</span>
          </Col>
          <Col span={1} className={KeepAccountStyle.titleCol} />
          <Col span={6} className={KeepAccountStyle.titleCol}>
            <span className={KeepAccountStyle.titleStyle}>金额（元）</span>
          </Col>
          <Col span={2} offset={1} />
        </Row>

        <Form.List name={'billList'}>
          {(fields, {add, remove}) => (
            <>
              {fields.map((field, index) => (
                <div key={field.key}>
                  <Row align={'middle'}>
                    <Col span={5}>
                      <Form.Item
                        style={{marginBottom: 0}}
                        name={[field.name, 'chargeType']}
                        fieldKey={[field.fieldKey, 'chargeType']}
                        rules={[{required: true, message: '请选择费用类型'}]}
                      >
                        <Select placeholder={'请选择'} style={{width: '100%'}} bordered={false}>
                          {chargeList.map(item => (
                            <Option key={item.id} value={item.id}>
                              {item.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8} offset={1}>
                      <Form.Item
                        style={{marginBottom: 0}}
                        name={[field.name, 'chargePeriod']}
                        fieldKey={[field.fieldKey, 'chargePeriod']}
                        rules={[{required: true, message: '请输入金额'}]}
                      >
                        <RangePicker bordered={false} />
                      </Form.Item>
                    </Col>
                    <Col span={6} offset={1}>
                      <Form.Item
                        style={{marginBottom: 0}}
                        name={[field.name, 'chargeMoney']}
                        fieldKey={[field.fieldKey, 'chargeMoney']}
                        rules={[{required: true, message: '请选择费用周期'}]}
                      >
                        <InputNumber placeholder={'请输入'} style={{border: 'none', width: '100%'}} />
                      </Form.Item>
                    </Col>
                    <Col span={2} offset={1}>
                      <CloseCircleFilled onClick={() => remove(field.name)} className={KeepAccountStyle.addItem} />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={21}>
                      <Divider style={{margin: '14px 0'}} />
                    </Col>
                  </Row>
                </div>
              ))}
              <Row justify={'space-between'} style={{margin: '6px 0 14px'}}>
                <Col span={21}>
                  <Row justify={'space-between'}>
                    <Col>
                      <Form.Item shouldUpdate noStyle>
                        {form1 => (
                          <span>
                            总额：￥
                            {form1
                              .getFieldValue('billList')
                              ?.reduce(
                                (result, item) => (item?.chargeMoney ? result + item.chargeMoney : result + 0),
                                0
                              )}
                          </span>
                        )}
                      </Form.Item>
                    </Col>
                    <Col>
                      <Button type={'link'} icon={<PlusOutlined />} style={{padding: '4px 0'}} onClick={() => add()}>
                        添加费用项
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col span={3} />
              </Row>
            </>
          )}
        </Form.List>

        <Form.Item shouldUpdate>
          {form1 => (
            <Radio.Group
              value={form1.getFieldValue('isPay')}
              onChange={({target: {value}}) => form1.setFieldsValue({isPay: value})}
            >
              <Radio value='a'>已支付</Radio>
              <Radio value='b'>待付款</Radio>
            </Radio.Group>
          )}
        </Form.Item>

        <Form.Item shouldUpdate noStyle>
          {form1 =>
            form1.getFieldValue('isPay') === 'b' ? (
              <Row>
                <Col span={8}>
                  <Form.Item
                    label={'应付款日'}
                    name={'shouldTime'}
                    rules={[{required: true, message: '请选择应付款日'}]}
                  >
                    <DatePicker style={{width: '100%'}} />
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col span={10}>
                  <Form.Item
                    label={'实际支付时间'}
                    name={'actualTime'}
                    rules={[{required: true, message: '请选择实际支付时间'}]}
                  >
                    <DatePicker style={{width: '100%'}} />
                  </Form.Item>
                </Col>
                <Col span={9} offset={1}>
                  <Form.Item
                    label={'支付方式'}
                    name={'payWay'}
                    style={{width: '100%'}}
                    rules={[{required: true, message: '请选择支付方式'}]}
                  >
                    <Select placeholder={'请选择'}>
                      {payWayList.map(item => (
                        <Option key={item.id} value={item.id}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            )
          }
        </Form.Item>

        <Form.Item label={'账单备注'} name={'billRemark'}>
          <Input.TextArea placeholder={'请输入账单备注'} />
        </Form.Item>

        <Row justify={'end'}>
          <Space>
            <Button
              onClick={() => {
                resetAction()
                setVisible(false)
              }}
            >
              取消
            </Button>
            <Button htmlType={'submit'} type={'primary'} loading={billLoading}>
              确认
            </Button>
          </Space>
        </Row>
      </Form>
    )
  }

  function onBillFinish() {
    const {contractId, billList, isPay, shouldTime, actualTime, payWay, billRemark} = billForm.getFieldsValue(true)

    if (!contractId) return message.warning('请先选择房源或合同')
    if (!billList?.length) return message.warning('请添加费用项！')

    const paramsList = billList.map(({chargeType, chargePeriod, chargeMoney}) => {
      const chargeItem = chargeList.find(item => item.id === chargeType)
      return {
        billAmtTotal: chargeMoney,
        billCategory: chargeItem?.category,
        billConfirm:
          isPay === 'a'
            ? ({
                billPayAmt: chargeMoney,
                billPayTime: dayjs(actualTime).format('YYYY-MM-DD'),
                billPayWayCode: payWay,
              } as ContractBillConfirmDTO)
            : ({} as ContractBillConfirmDTO),
        billIntervalList: [
          {
            billBegin: chargePeriod ? dayjs(chargePeriod[0]).format('YYYY-MM-DD') : undefined,
            billDate: isPay === 'a' ? dayjs(actualTime).format('YYYY-MM-DD') : dayjs(shouldTime).format('YYYY-MM-DD'),
            billEnd: chargePeriod ? dayjs(chargePeriod[1]).format('YYYY-MM-DD') : undefined,
          } as ContractBillInsertDetailDTO,
        ],
        billRemark,
        billTypeCode: chargeType,
        billTypeName: chargeItem?.label,
        confirm: isPay === 'a',
        contractId,
      }
    })

    if (activeKey === '1') {
      Promise.all(paramsList.map(item => contractModel.contractBillInsert(item))).then(() => {
        message.success('添加成功！')
        resetAction()
        setVisible(false)
      })
    }

    if (activeKey === '2') {
      Promise.all(paramsList.map(item => contractModel.contractLessorBillInsert(item))).then(() => {
        message.success('添加成功！')
        resetAction()
        setVisible(false)
      })
    }
  }

  // todo: 房源流水 & 其他流水
  function renderTurnover() {
    return (
      <Form form={turnoverForm} onFinish={onTurnoverFinish}>
        <Form.Item shouldUpdate>
          {form1 => (
            <Radio.Group
              value={form1.getFieldValue('tradeFlow')}
              onChange={({target: {value}}) => form1.setFieldsValue({tradeFlow: value})}
            >
              <Radio value='in'>收</Radio>
              <Radio value='out'>支</Radio>
            </Radio.Group>
          )}
        </Form.Item>

        {activeKey === '3' && (
          <Row>
            <Col span={10}>
              <Form.Item name={'propertyTitle'} style={{marginBottom: '30px'}}>
                <Select
                  showSearch
                  options={propertyList}
                  placeholder={'请输入小区名/门牌号'}
                  onSearch={value => queryPropertyList(value)}
                  onChange={(value, options) =>
                    turnoverForm.setFieldsValue({propertyCode: options.keys, propertyTitle: value})
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        <Row align={'middle'}>
          <Col span={8} className={KeepAccountStyle.titleCol}>
            <span className={KeepAccountStyle.titleStyle}>费用类型</span>
          </Col>
          <Col span={1} className={KeepAccountStyle.titleCol} />
          <Col span={8} className={KeepAccountStyle.titleCol}>
            <span className={KeepAccountStyle.titleStyle}>金额（元）</span>
          </Col>
        </Row>

        <Form.List name={'turnoverList'}>
          {(fields, {add, remove}) => (
            <>
              {fields.map((field, index) => (
                <div key={field.key}>
                  <Row align={'middle'}>
                    <Col span={8}>
                      <Form.Item
                        style={{marginBottom: 0}}
                        name={[field.name, 'tradeTypeCode']}
                        fieldKey={[field.fieldKey, 'tradeTypeCode']}
                        rules={[{required: true, message: '请选择费用类型'}]}
                      >
                        <Select placeholder={'请选择'} style={{width: '100%'}} bordered={false}>
                          {chargeList.map(item => (
                            <Option key={item.id} value={item.id}>
                              {item.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8} offset={1}>
                      <Form.Item
                        style={{marginBottom: 0}}
                        name={[field.name, 'tradeAmt']}
                        fieldKey={[field.fieldKey, 'tradeAmt']}
                        rules={[{required: true, message: '请输入金额'}]}
                      >
                        <InputNumber placeholder={'请输入'} style={{border: 'none', width: '100%'}} />
                      </Form.Item>
                    </Col>
                    <Col span={2} offset={1}>
                      <CloseCircleFilled onClick={() => remove(field.name)} className={KeepAccountStyle.addItem} />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={17}>
                      <Divider style={{margin: '14px 0'}} />
                    </Col>
                  </Row>
                </div>
              ))}
              <Row justify={'space-between'} style={{margin: '6px 0 14px'}}>
                <Col span={21}>
                  <Row justify={'space-between'}>
                    <Col>
                      <Form.Item shouldUpdate noStyle>
                        {form1 => (
                          <span>
                            总额：￥
                            {form1
                              .getFieldValue('turnoverList')
                              ?.reduce((result, item) => (item?.tradeAmt ? result + item.tradeAmt : result + 0), 0)}
                          </span>
                        )}
                      </Form.Item>
                    </Col>
                    <Col>
                      <Button type={'link'} icon={<PlusOutlined />} style={{padding: '4px 0'}} onClick={() => add()}>
                        添加费用项
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col span={3} />
              </Row>
            </>
          )}
        </Form.List>

        <Row>
          <Col span={10}>
            <Form.Item
              label={'实际支付时间'}
              name={'tradeTime'}
              rules={[{required: true, message: '请选择实际支付时间'}]}
            >
              <DatePicker style={{width: '100%'}} />
            </Form.Item>
          </Col>
          <Col span={9} offset={1}>
            <Form.Item
              label={'支付方式'}
              name={'tradePayWayCode'}
              style={{width: '100%'}}
              rules={[{required: true, message: '请选择支付方式'}]}
            >
              <Select placeholder={'请选择'}>
                {payWayList.map(item => (
                  <Option key={item.id} value={item.id}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label={'付款备注'} name={'remark'}>
          <Input.TextArea placeholder={'请输入付款备注'} />
        </Form.Item>

        <Row justify={'end'}>
          <Space>
            <Button
              onClick={() => {
                resetAction()
                setVisible(false)
              }}
            >
              取消
            </Button>
            <Button htmlType={'submit'} type={'primary'} loading={turnoverLoading}>
              确认
            </Button>
          </Space>
        </Row>
      </Form>
    )
  }

  function onTurnoverFinish() {
    const {tradeFlow, propertyCode, turnoverList, tradeTime, tradePayWayCode, remark} = turnoverForm.getFieldsValue(
      true
    )

    if (!turnoverList?.length) return message.warning('请添加费用项！')
    if (activeKey === '3' && !propertyCode) return message.warning('请输入小区名/门牌号！')

    const params = {
      propertyCode,
      remark,
      tradeFlow,
      tradePayWayCode,
      tradePayWayName: payWayList.find(item => item.id === tradePayWayCode)?.label,
      tradeSource: activeKey,
      tradeTime: dayjs(tradeTime).format('YYYY-MM-DD hh:mm:ss'),
      tradeTypeList: turnoverList.map(item => {
        const chargeItem = chargeList.find(_item => _item.id === item.tradeTypeCode)
        return {
          tradeAmt: item.tradeAmt,
          tradeCategory: chargeItem?.category,
          tradeCategoryName: chargeItem?.name,
          tradeTypeCode: item.tradeTypeCode,
          tradeTypeName: chargeItem?.label,
        }
      }),
    }
    tradeModel.tradeInsert(params).then(() => {
      message.success('添加成功！')
      resetAction()
      setVisible(false)
    })
  }

  function resetAction() {
    billForm.resetFields()
    billForm.setFieldsValue({isPay: 'a', billList: [{}]})
    turnoverForm.resetFields()
    turnoverForm.setFieldsValue({tradeFlow: 'in', turnoverList: [{}]})
  }

  useImperativeHandle(ref, () => ({
    onShow: () => {
      setVisible(true)
    },
    onHidden: () => {
      setVisible(false)
    },
  }))

  return (
    <Modal
      visible={visible}
      centered={true}
      title={'记账'}
      footer={null}
      onCancel={() => {
        resetAction()
        setVisible(false)
      }}
      width={'830px'}
      bodyStyle={{padding: '0 0 37px 0'}}
      zIndex={1001}
    >
      <Tabs
        activeKey={activeKey}
        onChange={value => {
          setActiveKey(value)
          resetAction()
        }}
        tabBarStyle={{margin: '0 0 30px 0', paddingLeft: '24px'}}
      >
        <TabPane tab={'租客账单'} key={'1'} style={{padding: '0 67px 0 24px'}}>
          {renderBill()}
        </TabPane>
        <TabPane tab={'业主账单'} key={'2'} style={{padding: '0 67px 0 24px'}}>
          {renderBill()}
        </TabPane>
        <TabPane tab={'房源流水'} key={'3'} style={{padding: '0 67px 0 24px'}}>
          {renderTurnover()}
        </TabPane>
        <TabPane tab={'其他流水'} key={'4'} style={{padding: '0 67px 0 24px'}}>
          {renderTurnover()}
        </TabPane>
      </Tabs>
    </Modal>
  )
}

const KeepAccount = React.forwardRef(_KeepAccount)

export default KeepAccount
