import React, {useEffect, useState} from 'react'
import styles from './index.module.less'
import {
  Form,
  Modal,
  Input,
  Row,
  Col,
  DatePicker,
  Radio,
  Button,
  Space,
  Table,
  Tag,
  Typography,
  Badge,
  Descriptions,
  Skeleton,
  Divider,
} from 'antd'
import {contractModel} from '@/store/models/Contract'
import moment from 'moment'
import {useHistory} from 'react-router-dom'
import {Album} from '@/ui/components/Album'
import {getImg, downloadFile} from '@/utils/util'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import {useCodeSync} from '@/store/models/Common'
import {Permission} from '@/ui/components/Permission'
import {useIsProjectCondo} from '@/common/bizHooks/useIsProjectCondo'
import {ColumnType} from 'antd/lib/table/interface'

const {Text} = Typography
const {RangePicker} = DatePicker
export default function RenterList() {
  const history = useHistory()
  const loading = contractModel.checkinPersonWebList.useLoading()
  const infoLoading = contractModel.checkinPersonInfo.useLoading()
  //参数
  const [params, setParams] = useState({pageNum: 1, pageSize: 10} as ContractCheckinPersonListDTO)
  //table数据
  const [tableList, setTableList] = useState<ContractPersonCheckinVO[]>()
  //总数
  const [total, setTotal] = useState(0)
  const [form] = Form.useForm()
  //对话框
  const [visible, setVisible] = useState(false)
  //入住人信息
  const [renterData, setRenterData] = useState({} as ContractPersonCheckinVO)
  //身份证类型
  const certTypes = useCodeSync('contract.certType')
  //学历
  const educations = useCodeSync('education')
  //星座
  const constellations = useCodeSync('constellation')
  //户籍类型
  const register = useCodeSync('household.register')
  //关系
  const relation = useCodeSync('relation')
  const isProjectCondo = useIsProjectCondo()

  useEffect(() => {
    contractModel.checkinPersonWebList(params).then(({response: {data, total}}) => {
      setTableList(data)
      setTotal(total)
    })
  }, [params])

  //查询
  function onFinish(values) {
    const {propertyTitle, name, status, time, mobile} = values as {
      propertyTitle: string
      name: string
      status: string
      time: string[]
      mobile: string
    }
    const [startTime, endTime] = time ? time.map(item => moment(item).format('YYYY-MM-DD HH:mm:ss')) : []
    setParams(state => ({
      ...state,
      pageNum: 1,
      startTime,
      endTime,
      propertyTitle,
      name,
      status,
      mobile,
    }))
  }

  //查看入住人信息
  async function renterDetail(personId) {
    const {
      response: {data},
    } = await contractModel.checkinPersonInfo(personId)
    setRenterData(data)
    setVisible(true)
  }
  //导出所有结果
  function exportAllResult() {
    contractModel
      .checkinPersonExcelPath({...params, pageable: false})
      .then(({response: {data}}) => downloadFile(data, '入住人列表导出所有结果'))
  }
  //批量导出
  // function exportSelectedResult() {
  //   if (!ids.length) return message.warning('请至少选中一条数据导出')
  //   contractModel.checkinPersonBatchPath(ids).then(({response: {data}}) => downloadFile(data, '批量导出'))
  // }

  return (
    <>
      <Breadcrumb
        path={['首页', '租约管理', '入住人列表']}
        right={
          <Permission code={'003002003'}>
            <Space>
              <Button onClick={exportAllResult}>导出所有结果</Button>
              {/*<Button onClick={exportSelectedResult}>批量导出</Button>*/}
            </Space>
          </Permission>
        }
      />
      <div className={styles.top}>
        <Form
          form={form}
          onFinish={onFinish}
          onReset={() => {
            setParams({pageNum: 1, pageSize: 10} as ContractCheckinPersonListDTO)
          }}
        >
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item label={'房源地址'} name={'propertyTitle'}>
                <Input placeholder={'请输入小区/公寓/房间号'} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'入住人姓名'} name={'name'}>
                <Input placeholder={'请输入承租人姓名'} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'入住人电话'} name={'mobile'}>
                <Input placeholder={'请输入承租人电话'} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item label={'入住时间'} name={'time'} style={{marginBottom: 0}}>
                <RangePicker />
              </Form.Item>
            </Col>
          </Row>
          <Divider dashed style={{margin: '16px 0 10px'}} />
          <Form.Item label={'居住状态'} name={'status'} style={{marginBottom: 0}}>
            <Radio.Group>
              <Radio value={'1'}>在租</Radio>
              <Radio value={'0'}>删除</Radio>
            </Radio.Group>
          </Form.Item>
          <Row justify={'end'}>
            <Space size={'small'}>
              <Button htmlType={'reset'}>重置</Button>
              <Button type={'primary'} htmlType={'submit'}>
                查询
              </Button>
            </Space>
          </Row>
        </Form>
      </div>
      <div className={styles.tableList}>
        <Table
          scroll={{x: 'max-content'}}
          rowKey={'personId'}
          columns={[
            ...(isProjectCondo
              ? [
                  {
                    title: '房源地址',
                    dataIndex: 'propertyTitle',
                    key: 'propertyTitle',
                    width: '16%',
                    align: 'center',
                  } as ColumnType<any>,
                ]
              : []),
            {
              title: '入住人',
              dataIndex: 'name',
              key: 'name',
              align: 'center',
              render: (text, record, index) => {
                return (
                  <Space>
                    <Text>{text}</Text>
                    <Tag color={'blue'}>{record?.personType === '1' ? '承租人' : '非承租人'}</Tag>
                  </Space>
                )
              },
            },
            {
              title: '性别',
              dataIndex: 'gender',
              key: 'gender',
              align: 'center',
              render: text => {
                return text === '1' ? '男' : '女'
              },
            },
            {
              title: '电话号码',
              dataIndex: 'mobile',
              key: 'mobile',
              align: 'center',
            },
            {
              title: '证件类型',
              dataIndex: 'certType',
              key: 'certType',
              align: 'center',
              render: text => {
                return text ? certTypes[text] : '/'
              },
            },
            {
              title: '证件号码',
              dataIndex: 'certNo',
              key: 'certNo',
              align: 'center',
            },
            ...(!isProjectCondo
              ? [
                  {
                    title: '房源地址',
                    dataIndex: 'propertyTitle',
                    key: 'propertyTitle',
                    width: '16%',
                    align: 'center',
                  } as ColumnType<any>,
                ]
              : []),
            {
              title: '入住时间',
              dataIndex: 'checkinTime',
              key: 'checkinTime',
              align: 'center',
              render: text => {
                return moment(text).format('YYYY-MM-DD')
              },
            },
            {
              title: '退住时间',
              dataIndex: 'updateTime',
              align: 'center',
              render: (text, record) => {
                if (record?.status === '1') {
                  return ''
                }
                return moment(text).format('YYYY-MM-DD')
              },
            },
            {
              title: '居住状态',
              dataIndex: 'status',
              key: 'status',
              align: 'center',
              render: text => {
                return text === '1' ? (
                  <div>
                    <Badge color={'#409EFF'} text={'在租'} />
                  </div>
                ) : (
                  <div>
                    <Badge color={'#FFB240'} text={'删除'} />
                  </div>
                )
              },
            },
            {
              title: '操作',
              fixed: 'right',
              render: (text, record, index) => {
                const {personId, contractId} = record
                return (
                  <>
                    <Permission code={'003002002'}>
                      <Button
                        type={'link'}
                        size={'small'}
                        style={{padding: '0 6px'}}
                        onClick={() => renterDetail(personId)}
                      >
                        查看
                      </Button>
                    </Permission>
                    <Permission code={'002001013'}>
                      <Divider type={'vertical'} style={{margin: 0}} />
                      <Button
                        type={'link'}
                        size={'small'}
                        style={{padding: '0 6px'}}
                        onClick={() => {
                          const location = {
                            pathname: './contract/detail',
                            state: {contractId},
                          }
                          history.push(location)
                        }}
                      >
                        查看租约
                      </Button>
                    </Permission>
                  </>
                )
              },
            },
          ]}
          dataSource={tableList}
          loading={loading}
          title={() => (
            <Typography.Text>
              <Typography.Text strong style={{marginRight: '12px', marginLeft: '-16px'}}>
                查询结果
              </Typography.Text>
              共计
              <Typography.Text strong style={{color: '#017BFF'}}>
                {total}
              </Typography.Text>
              条符合条件的结果
            </Typography.Text>
          )}
          pagination={{
            total,
            position: ['bottomCenter'],
            current: params.pageNum,
            defaultPageSize: 10,
            onChange: page => {
              setParams(state => ({...state, pageNum: page}))
            },
            showSizeChanger: false,
          }}
        />
      </div>
      <Modal
        title={
          <div>
            <Col className={styles.modal}>
              <Space>
                <Text>查看入住人</Text>
                <Text>{`(${renterData?.personType === '1' ? '承租人' : '非承租人'})`}</Text>
              </Space>
            </Col>
            <Col></Col>
          </div>
        }
        visible={visible}
        centered
        width={600}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        {infoLoading ? (
          <Skeleton active />
        ) : (
          <>
            <Descriptions title={'基本信息'} column={2}>
              <Descriptions.Item label={'入住人姓名'}>{renterData?.name}</Descriptions.Item>
              <Descriptions.Item label={'入住人性别'}>{renterData?.gender === '1' ? '男' : '女'}</Descriptions.Item>
              <Descriptions.Item label={'证件类型'}>{certTypes[renterData?.certType]}</Descriptions.Item>
              <Descriptions.Item label={'证件号码'}>{renterData?.certNo}</Descriptions.Item>
              <Descriptions.Item label={'证件有效期'}>
                {renterData?.certEffectStart
                  ? `${moment(renterData?.certEffectStart).format('YYYY-MM-DD')}至${moment(
                      renterData?.certEffectEnd
                    ).format('YYYY-MM-DD')}`
                  : ''}
              </Descriptions.Item>
              <Descriptions.Item label={'手机号码'}>{renterData?.mobile}</Descriptions.Item>
              <Descriptions.Item label={'入住时间'}>
                {moment(renterData?.checkinTime).format('YYYY-MM-DD')}
              </Descriptions.Item>
              <Descriptions.Item label={'入住人地址'}>{renterData?.adress}</Descriptions.Item>
            </Descriptions>
            <Descriptions title={'其他资料'} column={2}>
              <Descriptions.Item label={'职业'}>{renterData?.profession}</Descriptions.Item>
              <Descriptions.Item label={'兴趣爱好'}>{renterData?.hobby}</Descriptions.Item>
              <Descriptions.Item label={'星座'}>{constellations[renterData?.constellation]}</Descriptions.Item>
              <Descriptions.Item label={'生日'}>
                {renterData?.birthday ? moment(renterData?.birthday).format('YYYY-MM-DD') : ''}
              </Descriptions.Item>
              <Descriptions.Item span={2}>
                <div className={styles.info}>
                  <p style={{width: '80px'}}>证件照片:</p>
                  <Album
                    disabled
                    fileList={[
                      {url: getImg(renterData?.certFront) ?? '', uid: 'certFront'},
                      {url: getImg(renterData?.certBack) ?? '', uid: 'certBack'},
                      {url: getImg(renterData?.certHold) ?? '', uid: 'certHold'},
                    ]}
                  />
                </div>
              </Descriptions.Item>
              <Descriptions.Item label={'备注'}>{renterData?.remark}</Descriptions.Item>
            </Descriptions>
            <Descriptions title={'补充信息'} column={2}>
              <Descriptions.Item label={'户籍类型'}>{register[renterData?.household]}</Descriptions.Item>
              <Descriptions.Item label={'学历'}>{educations[renterData?.education]}</Descriptions.Item>
              <Descriptions.Item label={'工作单位'}>{renterData?.workplace}</Descriptions.Item>
              <Descriptions.Item label={'劳动合同开始时间'}>
                {renterData?.laborContractStart ? moment(renterData?.laborContractStart).format('YYYY-MM-DD') : ''}
              </Descriptions.Item>
              <Descriptions.Item label={'劳动年限'}>{renterData?.laborContractTerm}</Descriptions.Item>
              <Descriptions.Item label={'申请受理编号'}>{renterData?.acceptCode}</Descriptions.Item>
            </Descriptions>
            <Descriptions title={'紧急联系人'} column={2}>
              <Descriptions.Item label={'姓名'}>{renterData?.emergPersonName}</Descriptions.Item>
              <Descriptions.Item label={'关系'}>{relation[renterData?.emergPersonRelation]}</Descriptions.Item>
              <Descriptions.Item label={'手机'}>{renterData?.emergPersonMobile}</Descriptions.Item>
            </Descriptions>
          </>
        )}
      </Modal>
    </>
  )
}
