/**
 * 应用类型
 */
export const HEADER_CHANNEL = {
  LESSOR: 'lessor', // 房东
  LESSEE: 'lessee', // 租客
  STEWARD: 'steward', // 管家
  ADMIN: 'admin', // 后台
}

/**
 * 平台
 */
export const HEADER_PLATFORM = {
  IOS: 'ios', // iOS
  ANDROID: 'android', // 安卓
  MOBILE: 'mobile', // 移动设备
  WEB: 'web', // 网页
  MINI: 'mini', // 微信小程序
}
