import React from 'react'
import {Modal} from 'antd'
import {getImg} from '@/utils/util'

export default function StewardBillImage(props: {imgUrl: string; visible: boolean; onCancel: () => void}) {
  const {imgUrl, visible, onCancel} = props

  return (
    <Modal title={'预览'} centered={true} visible={visible} onCancel={onCancel} footer={null}>
      <img alt={'预览'} src={getImg(imgUrl) ?? ''} width={'100%'} />
    </Modal>
  )
}
