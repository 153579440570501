import React, {useCallback, useEffect, useState} from 'react'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import {Badge, Button, Descriptions, Form, message, Modal, Progress, Row, Space, Table, Tabs} from 'antd'
import nygDeviceStyle from '@/pages/nygDevice/device.module.less'
import DeviceTableHeader from '@/pages/device/components/DeviceTableHeader'
import NygDeviceTimeForm from '@/pages/nygDevice/components/NygDeviceTimeForm'
import DeviceYunDing from '@/pages/device/components/DeviceYunDing'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {useAppLocation} from '@/common/hooks/useRouter'
import {deviceModel} from '@/store/models/Device'
import './index.css'
import {downloadBlob} from '@/utils/util'

export default function NygDeviceLockDetail() {
  const location = useAppLocation<'/nyg-device-lock/detail'>()
  const deviceId = location.state?.deviceId
  const [recordExcelLoading, setRecordExcelLoading] = useState(false)

  const [lockInfo, setLockInfo] = useState({} as Lock)

  useEffect(() => {
    deviceModel.deviceV2LockInfo({lockId: deviceId}).then(({response: {data}}) => setLockInfo(data))
  }, [deviceId])

  const [authForm] = Form.useForm()
  const [authTotal, setAuthTotal] = useState(0)
  const [authList, setAuthList] = useState<DeviceV2LockPasswordRecordVO[]>([])

  const queryLockPasswordRecord = useCallback(() => {
    const formValues = authForm.getFieldsValue(true)
    // console.log('formValues', formValues)
    deviceModel.deviceV2LockPasswordRecord({lockId: deviceId, ...formValues}).then(({response: {data, total}}) => {
      setAuthList(data)
      setAuthTotal(total)
    })
  }, [authForm, deviceId])

  useEffect(() => {
    queryLockPasswordRecord()
  }, [queryLockPasswordRecord])

  const [openForm] = Form.useForm()
  // const [openTotal, setOpenTotal] = useState(0)
  const [openList, setOpenList] = useState<DeviceV2LockOpenRecordVO[]>([])

  const queryLockOpenRecord = useCallback(() => {
    const formValues = openForm.getFieldsValue(true)
    const {pageNum = 1, pageSize = 10, pageable = true} = formValues ?? {}
    // console.log('formValues', formValues)
    deviceModel
      .deviceV2LockOpenRecord({lockId: deviceId, ...formValues, pageNum, pageSize, pageable})
      .then(({response: {data}}) => {
        setOpenList(data)
        // setOpenTotal(1000)
      })
  }, [deviceId, openForm])

  useEffect(() => {
    queryLockOpenRecord()
  }, [queryLockOpenRecord])

  const [manageForm] = Form.useForm()
  const [manageTotal, setManageTotal] = useState(0)
  const [manageList, setManageList] = useState<DeviceV2LockPasswordVO[]>([])

  const queryLockPasswordList = useCallback(() => {
    const formValues = manageForm.getFieldsValue(true)
    const {pageNum = 1, pageSize = 10, pageable = true} = formValues ?? {}
    // console.log('formValues', formValues)
    deviceModel
      .deviceV2LockPasswordList({lockId: deviceId, ...formValues, pageNum, pageSize, pageable})
      .then(({response: {data, total}}) => {
        setManageTotal(total)
        setManageList(data)
      })
  }, [deviceId, manageForm])

  useEffect(() => {
    queryLockPasswordList()
  }, [queryLockPasswordList])

  const authLoading = deviceModel.deviceV2LockPasswordRecord.useLoading()
  const openLoading = deviceModel.deviceV2LockOpenRecord.useLoading()
  const manageLoading = deviceModel.deviceV2LockPasswordList.useLoading()

  const insertLoading = deviceModel.deviceV2LockPasswordInsert.useLoading()
  const pwdLoading = deviceModel.deviceV2LockDynamicPassword.useLoading()
  const deleteLoading = deviceModel.deviceV2LockPasswordDelete.useLoading()

  return (
    <div>
      <Breadcrumb path={['首页', '智能设备', '门锁']} />
      <div className={nygDeviceStyle.deviceSection}>
        <Row>
          <DeviceYunDing />
          <Descriptions
            title={'牛油果智能门锁'}
            column={3}
            labelStyle={{color: 'rgba(0, 0, 0, 0.45)'}}
            style={{margin: '0 50px 0 25px', width: '50%'}}
          >
            <Descriptions.Item
              label={
                <Space size={0}>
                  <Badge status={lockInfo.status === 1 ? 'success' : 'warning'} />
                  网关状态
                </Space>
              }
            >
              {lockInfo.status === 1 ? '在线' : '离线'}
            </Descriptions.Item>
            <Descriptions.Item label={'承租人'}>{lockInfo.lesseeName ?? '/'}</Descriptions.Item>
            <Descriptions.Item label={'手机号'}>{lockInfo.lesseeMobile ?? '/'}</Descriptions.Item>
            <Descriptions.Item label={'房源地址'}>{lockInfo.propertyTitle}</Descriptions.Item>
          </Descriptions>
          <Progress
            width={94}
            strokeWidth={7}
            percent={lockInfo.power ?? 0}
            strokeColor={'#017BFF'}
            type={'circle'}
            format={percent => (
              <Space direction={'vertical'}>
                <span style={{fontSize: '16px', fontWeight: 'bold', color: '#000'}}>{percent}%</span>
                <span style={{fontSize: '14px', color: 'rgba(0, 0, 0, 0.65)'}}>剩余电量</span>
              </Space>
            )}
          />
        </Row>
        <Row justify={'end'} style={{marginTop: '20px'}}>
          <Space>
            <Button
              type={'primary'}
              onClick={() => {
                Modal.confirm({
                  title: '添加授权',
                  icon: null,
                  closable: true,
                  centered: true,
                  content: (
                    <Space direction={'vertical'} style={{margin: '20px 0'}}>
                      <span>被授权人：{lockInfo.lesseeName}</span>
                      <span>有效期：长期有效</span>
                    </Space>
                  ),
                  okText: (
                    <Button type={'primary'} loading={insertLoading}>
                      确定
                    </Button>
                  ),
                  onOk: async () => {
                    await deviceModel.deviceV2LockPasswordInsert({lockId: deviceId})
                    message.success('操作成功！')
                    queryLockPasswordList()
                  },
                  okButtonProps: {style: {padding: 0}},
                  okType: 'text',
                })
              }}
            >
              密码授权
            </Button>
            <Button
              loading={pwdLoading}
              onClick={async () => {
                const {
                  response: {data},
                } = await deviceModel.deviceV2LockDynamicPassword({lockId: deviceId})
                Modal.confirm({
                  title: '临时密码',
                  icon: null,
                  closable: true,
                  content: (
                    <Space direction={'vertical'} align={'center'} style={{width: '100%', margin: '20px 0'}}>
                      <span style={{fontSize: '30px', fontWeight: 'bold'}}>{data.key}</span>
                      <span>失效时间：{data.value}</span>
                    </Space>
                  ),
                  okText: '复制密码',
                  onOk: () => {
                    navigator.clipboard.writeText(data.key).then(() => {
                      message.success('复制成功！')
                    })
                  },
                })
              }}
            >
              临时密码
            </Button>
          </Space>
        </Row>
      </div>
      <Tabs tabBarStyle={{margin: 0, backgroundColor: 'white', paddingLeft: '20px'}}>
        <Tabs.TabPane tab={'授权记录'} key={'1'}>
          <NygDeviceTimeForm
            form={authForm}
            label={'授权记录时间'}
            onFinish={queryLockPasswordRecord}
            onReset={queryLockPasswordRecord}
          />
          <div className={nygDeviceStyle.tableSection}>
            <DeviceTableHeader total={authTotal} />
            <Table
              rowKey={'recordId'}
              columns={[
                {title: '状态', dataIndex: 'valid', render: valid => (valid ? ['无效', '有效'][valid] : '/')},
                {title: '授权操作', dataIndex: 'operateDesc'},
                {title: '授权时间', dataIndex: 'insertTime'},
                {title: '授权人', dataIndex: 'operator'},
              ]}
              dataSource={authList}
              loading={authLoading}
              pagination={getPaginationProps(authForm, authTotal)}
            />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab={'开门记录'} key={'2'}>
          <NygDeviceTimeForm
            form={openForm}
            label={'开门时间'}
            onFinish={queryLockOpenRecord}
            onReset={queryLockOpenRecord}
            onExport={() => {
              const {begin, end} = openForm.getFieldsValue(true)
              if (!begin) return message.warning('请选择开门时间！')
              setRecordExcelLoading(true)
              downloadBlob({
                url: '/device/v2/lock/open/record-excel',
                body: {begin, end, pageable: false, lockId: deviceId},
              }).finally(() => setRecordExcelLoading(false))
            }}
            exportLoading={recordExcelLoading}
          />
          <div className={nygDeviceStyle.tableSection}>
            <Table
              rowKey={'alertId'}
              columns={[
                {title: '开锁人', dataIndex: 'uname', render: value => value ?? '/'},
                {title: '手机号', dataIndex: 'mobile', render: value => value ?? '/'},
                {title: '开门方式', dataIndex: 'alertDesc'},
                {title: '开门时间', dataIndex: 'alertTime'},
              ]}
              dataSource={openList}
              loading={openLoading}
              pagination={{
                total: 1000,
                pageSize: openForm.getFieldValue('pageSize'),
                current: openForm.getFieldValue('pageNum'),
                position: ['bottomCenter'],
                hideOnSinglePage: false,
                showSizeChanger: true,
                onChange: (pageNum, pageSize) => {
                  openForm.setFieldsValue({pageNum, pageSize})
                  openForm.submit()
                },
                simple: true,
              }}
              style={{paddingTop: '25px'}}
            />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab={'授权管理'} key={'3'}>
          <NygDeviceTimeForm
            form={manageForm}
            label={'授权时间'}
            onFinish={queryLockPasswordList}
            onReset={queryLockPasswordList}
          />
          <div className={nygDeviceStyle.tableSection}>
            <DeviceTableHeader total={manageTotal} />
            <Table
              rowKey={'pwdId'}
              columns={[
                {title: '状态', dataIndex: 'valid', render: valid => (valid ? ['无效', '有效'][valid] : '/')},
                {title: '被授权人', dataIndex: 'pwdUserName'},
                {title: '手机号', dataIndex: 'pwdUserMobile'},
                {title: '授权期限', render: () => '永久'},
                {title: '授权时间', dataIndex: 'insertTime'},
                {
                  title: '操作',
                  dataIndex: 'pwdId',
                  render: pwdId => {
                    return (
                      <Button
                        type={'link'}
                        style={{padding: 0}}
                        onClick={() => {
                          Modal.confirm({
                            title: '提示',
                            centered: true,
                            content: '确认取消授权',
                            onOk: async () => {
                              await deviceModel.deviceV2LockPasswordDelete({pwdId})
                              message.success('操作成功！')
                              queryLockPasswordList()
                            },
                            okText: (
                              <Button type={'primary'} loading={deleteLoading}>
                                确定
                              </Button>
                            ),
                            okButtonProps: {style: {padding: 0}},
                            okType: 'text',
                          })
                        }}
                      >
                        取消授权
                      </Button>
                    )
                  },
                },
              ]}
              dataSource={manageList}
              loading={manageLoading}
              pagination={getPaginationProps(manageForm, manageTotal)}
            />
          </div>
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}
