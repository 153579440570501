import React, {useCallback, useEffect, useState} from 'react'
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  InputNumber,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Typography,
} from 'antd'
import {stewardModel} from '@/store/models/Steward'
import {userModel} from '@/store/models/User'
import {propertyModel} from '@/store/models/Property'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import dayjs from 'dayjs'
import {useHistory} from 'react-router-dom'
import {Permission} from '@/ui/components/Permission'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import type {ColumnsType} from 'antd/es/table'
import {isClean} from '@/utils/util'

const {TabPane} = Tabs
const {Option} = Select
const {RangePicker} = DatePicker

export default function ServiceAccount() {
  const history = useHistory()
  const curTeam = userModel.useData(data => data.currentTeam)
  const chargeCategoryArray = useCodeArraySync('team.chargeCategory')
  const chargeSourceArray = useCodeArraySync('team.chargeSource')
  const billTypeArray = useCodeArraySync('lessor.billType')?.filter(value => value.id !== '3')
  const billTypeCode = useCodeSync('lessor.billType')

  const [segIndex, setSegIndex] = useState('1')

  // 剩余服务费
  const [balance, setBalance] = useState(0)
  // 小区列表
  const [community, setCommunity] = useState<CommunityVO[]>([])

  // 扣款明细 查询条件
  const [deductType, setDeductType] = useState('1')
  const [balanceParams, setBalanceParams] = useState<Partial<TeamWalletRecordQueryDTO>>({
    pageNum: 1,
    pageSize: 10,
    deductType: '1',
  })
  const [balanceForm] = Form.useForm()
  const [balanceTotal, setBalanceTotal] = useState(0)
  const [balanceList, setBalanceList] = useState<TeamWalletRecordVO[]>([])
  const balanceColumn: ColumnsType<TeamWalletRecordVO> =
    deductType === '2'
      ? [
          {title: '服务单号', key: 'stewardOrderId', dataIndex: 'stewardOrderId'},
          {title: '服务房源', key: 'propertyTitle', dataIndex: 'propertyTitle'},
          {title: '扣款方式', key: 'recordPayTypeName', dataIndex: 'recordPayTypeName'},
          {
            title: '费用类型',
            key: 'recordCategoryName',
            dataIndex: 'recordCategoryName',
            render: (value, record) => (
              <span>
                {record.recordTypeName}-{value}
              </span>
            ),
          },
          {
            title: '扣款金额',
            key: 'recordAmt',
            dataIndex: 'recordAmt',
            render: value => <span>{value && value.toFixed(2)}</span>,
          },
          {title: '扣款时间', key: 'insertTime', dataIndex: 'insertTime'},
          {
            title: '操作',
            render: (value, record) => (
              <Permission code={'009002002'}>
                <Button
                  type={'link'}
                  onClick={() => {
                    if (isClean(record.stewardOrderId)) {
                      history.push('/clean/detail', {orderId: record.stewardOrderId})
                    } else {
                      history.push('/steward/detail', {orderId: record.stewardOrderId})
                    }
                  }}
                >
                  查看服务单
                </Button>
              </Permission>
            ),
          },
        ]
      : [
          {title: '账单名称', key: 'billName', dataIndex: 'billName'},
          {title: '账单类型', key: 'billType', dataIndex: 'billType', render: text => billTypeCode[text]},
          {title: '扣款金额', key: 'recordAmt', dataIndex: 'recordAmt', render: text => <span>{text}元</span>},
          {title: '扣款时间', key: 'insertTime', dataIndex: 'insertTime'},
          {
            title: '操作',
            render: (value, record) => (
              <Permission code={'009002002'}>
                <Button type={'link'} onClick={() => history.push('/stewardBill', {orderId: record.stewardOrderId})}>
                  查看账单
                </Button>
              </Permission>
            ),
          },
        ]
  const balanceLoading = stewardModel.teamBalanceRecordList.useLoading()

  // 充值记录 查询条件
  const [chargeParams, setChargeParams] = useState<Partial<TeamWalletChargeQueryDTO>>({pageNum: 1, pageSize: 10})
  const [chargeForm] = Form.useForm()
  const [chargeTotal, setChargeTotal] = useState(0)
  const [chargeList, setChargeList] = useState<TeamBalanceChargeListVO[]>([])
  const chargeColumn = [
    {title: '流水号', key: 'chargeId', dataIndex: 'chargeId'},
    {title: '进账时间', key: 'chargeTime', dataIndex: 'chargeTime'},
    {
      title: '充值金额',
      key: 'chargeAmt',
      dataIndex: 'chargeAmt',
      render: value => <span>{value && value.toFixed(2)}</span>,
    },
    {title: '充值渠道', key: 'chargeTypeName', dataIndex: 'chargeTypeName'},
    {title: '充值账户', key: 'chargeAccount', dataIndex: 'chargeAccount'},
    {title: '充值完成时间', key: 'insertTime', dataIndex: 'insertTime'},
  ]
  const chargeLoading = stewardModel.teamBalanceChargeList.useLoading()

  // 组织账户余额
  useEffect(() => {
    if (curTeam.teamPid) {
      stewardModel.teamBalance({teamPid: curTeam.teamPid}).then(({response: {data}}) => {
        setBalance(data)
      })
    }
  }, [curTeam.teamPid])

  // 小区
  useEffect(function () {
    propertyModel.propertyCommunityList({}).then(({response: {data}}) => {
      console.log('小区 ===>>>', data)
      setCommunity(data)
    })
  }, [])

  // 扣款记录列表
  const queryBalanceList = useCallback(
    function (params: Partial<TeamWalletRecordQueryDTO>) {
      stewardModel.teamBalanceRecordList({...params, teamPid: curTeam.teamPid}).then(({response: {data, total}}) => {
        console.log('balance list =>', data)
        setBalanceList(data)
        setBalanceTotal(total)
      })
    },
    [curTeam.teamPid]
  )

  // 充值记录列表
  const queryChargeList = useCallback(
    function (params: Partial<TeamWalletChargeQueryDTO>) {
      stewardModel.teamBalanceChargeList({...params, teamId: curTeam.teamPid}).then(({response: {data, total}}) => {
        console.log('charge list =>', data)
        setChargeList(data)
        setChargeTotal(total)
      })
    },
    [curTeam.teamPid]
  )

  useEffect(
    function () {
      if (segIndex === '1') {
        queryBalanceList(balanceParams)
      } else {
        queryChargeList(chargeParams)
      }
    },
    [queryBalanceList, balanceParams, segIndex, queryChargeList, chargeParams]
  )

  return (
    <>
      <Breadcrumb path={['管家服务', '服务费账户']} />
      <div>
        <div style={{backgroundColor: '#fff', height: '86px', padding: '12px 26px'}}>
          <Typography>剩余服务费（元）</Typography>
          <Typography style={{color: '#333', fontSize: 28, fontWeight: 600, lineHeight: '40px'}}>
            {balance && balance.toFixed(2)}
          </Typography>
        </div>

        <Tabs
          defaultActiveKey={'1'}
          activeKey={segIndex}
          onChange={key => setSegIndex(key)}
          tabBarStyle={{
            backgroundColor: '#fff',
            margin: '20px 0 0',
            padding: '0 0 0 20px',
          }}
        >
          <TabPane tab={'扣款明细'} key={'1'}>
            <div style={{backgroundColor: '#fff', margin: '20px 0', padding: '20px 24px'}}>
              <Row>
                <Col style={{display: 'flex', alignItems: 'center'}}>
                  <span>扣款类型：</span>
                </Col>
                <Col>
                  <Space>
                    <Button
                      size={'small'}
                      type={deductType === '1' ? 'primary' : 'text'}
                      onClick={() => {
                        setDeductType('1')
                        balanceForm.resetFields()
                        setBalanceParams({pageNum: 1, pageSize: 10, deductType: '1'})
                      }}
                    >
                      账单扣款
                    </Button>
                    <Button
                      size={'small'}
                      type={deductType === '2' ? 'primary' : 'text'}
                      onClick={() => {
                        setDeductType('2')
                        balanceForm.resetFields()
                        setBalanceParams({pageNum: 1, pageSize: 10, deductType: '2'})
                      }}
                    >
                      服务扣费
                    </Button>
                  </Space>
                </Col>
              </Row>
              <Divider dashed style={{margin: '20px 0'}} />
              <Form
                form={balanceForm}
                onFinish={values => {
                  const insertTime = values.insertTime
                  let insertTimeBegin, insertTimeEnd
                  if (insertTime?.length) {
                    const res = insertTime.map(item => dayjs(item).format('YYYY-MM-DD'))
                    insertTimeBegin = res[0]
                    insertTimeEnd = res[1]
                  }
                  setBalanceParams(prevState => ({...prevState, ...values, insertTimeBegin, insertTimeEnd}))
                }}
              >
                <Row>
                  <Col span={7}>
                    {deductType === '1' ? (
                      <Form.Item name={'billType'} label={'账单类型'}>
                        <Select placeholder={'请选择'}>
                          {billTypeArray.map(item => (
                            <Option key={item.id} value={item.id}>
                              {item.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    ) : (
                      <Form.Item name={'recordCategory'} label={'费用类型'}>
                        <Select placeholder={'请选择'}>
                          {chargeCategoryArray.map(item => (
                            <Option key={item.id} value={item.id}>
                              {item.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )}
                  </Col>
                  <Col span={1} />
                  <Col span={7}>
                    <Form.Item label={'扣款金额'}>
                      <Form.Item name={'recordAmtMin'} noStyle>
                        <InputNumber placeholder={'最小金额'} />
                      </Form.Item>
                      {` - `}
                      <Form.Item name={'recordAmtMax'} noStyle>
                        <InputNumber placeholder={'最大金额'} />
                      </Form.Item>
                    </Form.Item>
                  </Col>
                  <Col span={1} />
                  <Col span={7}>
                    <Form.Item name={'insertTime'} label={'扣费日期'}>
                      <RangePicker />
                    </Form.Item>
                  </Col>
                  <Col span={1} />
                </Row>
                {deductType === '2' && (
                  <>
                    <Divider dashed style={{margin: '0 0 20px 0'}} />
                    <Row>
                      <Form.Item name={'communityCodeList'} label={'公寓/小区名：'}>
                        <Checkbox.Group>
                          {community.map(item => (
                            <Checkbox key={item.code} value={item.code}>
                              {item.name}
                            </Checkbox>
                          ))}
                        </Checkbox.Group>
                      </Form.Item>
                    </Row>
                  </>
                )}
                <Row justify={'end'}>
                  <Space>
                    <Button type={'primary'} htmlType={'submit'}>
                      查询
                    </Button>
                    <Button
                      htmlType={'reset'}
                      onClick={() => {
                        balanceForm.resetFields()
                        setBalanceParams({pageNum: 1, pageSize: 10, deductType})
                      }}
                    >
                      重置
                    </Button>
                  </Space>
                </Row>
              </Form>
            </div>

            <div style={{padding: '0 24px', backgroundColor: '#fff'}}>
              <div style={{padding: '18px 0'}}>
                <span style={{color: '#000', fontSize: '16px', fontWeight: 500, lineHeight: '24px'}}>查询结果</span>
                <span style={{color: '#606266', fontSize: '14px', lineHeight: '22px', marginLeft: '12px'}}>
                  共计<span style={{color: '#017BFF'}}>{balanceTotal}</span>条符合条件的结果
                </span>
              </div>
              <Table
                rowKey={'recordId'}
                columns={balanceColumn}
                dataSource={balanceList}
                loading={balanceLoading}
                scroll={{x: 'max-content'}}
                pagination={{
                  total: balanceTotal,
                  current: balanceParams.pageNum,
                  pageSize: balanceParams.pageSize,
                  position: ['bottomCenter'],
                  showSizeChanger: true,
                  onChange: pageNum => {
                    setBalanceParams(prevState => ({...prevState, pageNum: pageNum}))
                  },
                  onShowSizeChange: (_, size) =>
                    setBalanceParams(prevState => ({...prevState, pageSize: size, pageNum: 1})),
                }}
              />
            </div>
          </TabPane>
          <TabPane tab={'充值记录'} key={'2'}>
            <div style={{backgroundColor: '#fff', margin: '20px 0', padding: '20px 24px'}}>
              <Form
                form={chargeForm}
                onFinish={values => {
                  const insertTime = values.insertTime
                  let insertTimeBegin, insertTimeEnd
                  if (insertTime?.length) {
                    const res = insertTime.map(item => dayjs(item).format('YYYY-MM-DD'))
                    insertTimeBegin = res[0]
                    insertTimeEnd = res[1]
                  }
                  setChargeParams(prevState => ({...prevState, ...values, insertTimeBegin, insertTimeEnd}))
                }}
              >
                <Row>
                  <Col span={7}>
                    <Form.Item name={'chargeType'} label={'充值渠道'}>
                      <Select placeholder={'请选择'}>
                        {chargeSourceArray.map(item => (
                          <Option key={item.id} value={item.id}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={1} />
                  <Col span={7}>
                    <Form.Item label={'充值金额'}>
                      <Form.Item name={'chargeAmtMin'} noStyle>
                        <InputNumber placeholder={'最小金额'} />
                      </Form.Item>
                      {` - `}
                      <Form.Item name={'chargeAmtMax'} noStyle>
                        <InputNumber placeholder={'最大金额'} />
                      </Form.Item>
                    </Form.Item>
                  </Col>
                  <Col span={1} />
                  <Col span={7}>
                    <Form.Item name={'insertTime'} label={'充值完成日期'}>
                      <RangePicker />
                    </Form.Item>
                  </Col>
                  <Col span={1} />
                </Row>
                <Row justify={'end'}>
                  <Space>
                    <Button type={'primary'} htmlType={'submit'}>
                      查询
                    </Button>
                    <Button
                      onClick={() => {
                        chargeForm.resetFields()
                        setChargeParams({pageNum: 1, pageSize: 10})
                      }}
                    >
                      重置
                    </Button>
                  </Space>
                </Row>
              </Form>
            </div>

            <div style={{padding: '0 24px', backgroundColor: '#fff'}}>
              <div style={{padding: '18px 0'}}>
                <span style={{color: '#000', fontSize: '16px', fontWeight: 500, lineHeight: '24px'}}>查询结果</span>
                <span style={{color: '#606266', fontSize: '14px', lineHeight: '22px', marginLeft: '12px'}}>
                  共计<span style={{color: '#017BFF'}}>{chargeTotal}</span>条符合条件的结果
                </span>
              </div>
              <Table
                rowKey={'chargeId'}
                columns={chargeColumn}
                dataSource={chargeList}
                loading={chargeLoading}
                pagination={{
                  total: chargeTotal,
                  current: chargeParams.pageNum,
                  pageSize: chargeParams.pageSize,
                  position: ['bottomCenter'],
                  showSizeChanger: true,
                  onChange: pageNum => {
                    setChargeParams(prevState => ({...prevState, pageNum: pageNum}))
                  },
                  onShowSizeChange: (_, size) =>
                    setChargeParams(prevState => ({...prevState, pageSize: size, pageNum: 1})),
                }}
              />
            </div>
          </TabPane>
        </Tabs>
      </div>
    </>
  )
}
