import {useEffect, useState} from 'react'
import {userModel} from '@/store/models/User'

/**
 * 等待 store 回复后，通过请求团队信息和用户信息的方式，确保 token 可用；如果不可用，则清空 token。
 *
 * isReady 判断流程:
 *   无 token -> true
 *   有 token -> false -> 请求用户信息 -> true
 */
const AuthGate = props => {
  const token = userModel.useData(data => !!data.token)
  const [isReady, setIsReady] = useState(!token)

  useEffect(() => {
    if (!isReady) {
      const init = async () => {
        try {
          const team = await userModel.queryMyTeam()
          await userModel.queryUser()
          // 没有团队信息也退出登录
          if (!team.response?.data?.length) {
            userModel.clearUserInfo()
          }
        } catch (reason) {
          if (reason?.response?.data?.code !== 200) {
            userModel.clearUserInfo()
          }
        } finally {
          setIsReady(true)
        }
      }
      init()
    }
  }, [isReady])

  return isReady ? props.children : null // null 可以替换成 loading
}

export default AuthGate
