import React, {useCallback, useEffect, useState} from 'react'
import {Button, Col, DatePicker, Divider, Form, Input, message, Row, Select, Space, Table, Tag, Typography} from 'antd'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {cleanModel} from '@/store/models/Clean'
import {FORMAT_DATE_END, FORMAT_DATE_START} from '@/common/date'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {service} from '@/utils/render'
import {useAppHistory} from '@/common/hooks/useRouter'
import {useRemark} from '@/pages/steward/Clean/CleanServiceDetail'
import {cleanType} from '@/pages/steward/Clean/CleanServiceList/cleanType'
import {downloadBlob} from '@/utils/util'
import {usePermission} from '@/ui/components/Permission'

export default function CleanServiceList() {
  const history = useAppHistory()
  const [auth] = usePermission()

  const [form] = Form.useForm()

  const [dataSource, setDataSource] = useState<CleanOrderVO[]>([])
  const [total, setTotal] = useState(0)

  const orderStatus = useCodeSync('clean.order.status')
  const orderStatusList = useCodeArraySync('clean.order.status', {defaultItem: true})

  const loading = cleanModel.cleanOrderList.useLoading()

  const search = useCallback(async () => {
    const value = form.getFieldsValue(true)
    cleanModel.cleanOrderCount(value).then(({response: {data: value}}) => setTotal(value))
    const {
      response: {data},
    } = await cleanModel.cleanOrderList(value)
    setDataSource(data)
  }, [form])

  useEffect(() => {
    form.submit()
  }, [form])

  const [show] = useRemark()

  return (
    <>
      <Breadcrumb
        path={['首页', '管家服务', '保洁服务单']}
        right={
          auth('005001007') && (
            <Button
              onClick={() => {
                const formValues = form.getFieldsValue(true)
                if (!formValues.insertTimeBegin || !formValues.insertTimeEnd) return message.warning('请选择下单时间')
                downloadBlob({url: '/clean-order/list-export', body: {...formValues, pageable: false}})
              }}
            >
              导出
            </Button>
          )
        }
      />
      <div style={{padding: '25px', backgroundColor: '#ffffff'}}>
        <Form
          form={form}
          onFinish={search}
          onReset={search}
          initialValues={{
            pageNum: 1,
            pageSize: 10,
            pageable: true,
            orderStatus: null,
          }}
        >
          {/** 所属类目 */}
          <Form.Item label={'所属类目'} shouldUpdate style={{marginBottom: 0}}>
            {f => {
              const orderStatus = f.getFieldValue('orderStatus')

              return (
                <Form.Item noStyle style={{marginBottom: 0}}>
                  {orderStatusList.map((item, idx) => (
                    <Button
                      key={idx}
                      type={orderStatus === item.id ? 'primary' : 'text'}
                      size={'small'}
                      onClick={() => {
                        f.setFieldsValue({orderStatus: item.id, pageNum: 1})
                        search()
                      }}
                      style={{minWidth: '70px', margin: '0 10px'}}
                    >
                      {item.label}
                    </Button>
                  ))}
                </Form.Item>
              )
            }}
          </Form.Item>
          <Divider dashed style={{margin: '16px 0'}} />
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item name={'propertyAddr'} label={'服务地址'}>
                <Input placeholder={'小区/公寓/房间号'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'orderMakerName'} label={'下单人姓名'}>
                <Input placeholder={'请输入下单人姓名'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'orderId'} label={'订单编号'}>
                <Input placeholder={'请输入订单编号'} allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item name={'_time'} label={'下单时间'} style={{marginBottom: 0}}>
                <DatePicker.RangePicker
                  format={'YYYY-MM-DD'}
                  allowClear
                  onChange={value => {
                    form.setFieldsValue({
                      insertTimeBegin: value?.[0]?.format(FORMAT_DATE_START),
                      insertTimeEnd: value?.[1]?.format(FORMAT_DATE_END),
                    })
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'categoryThi'} label={'所属服务'} style={{minWidth: 220, marginBottom: 0}}>
                <Select
                  allowClear
                  placeholder={'请选择'}
                  options={cleanType.map(value => ({value: value.id, label: value.label}))}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button htmlType={'reset'}>重置</Button>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div style={{flex: 1, backgroundColor: '#fff', marginTop: 20, padding: '0 25px'}}>
        <Table
          rowKey={'orderId'}
          pagination={getPaginationProps(form, total)}
          dataSource={dataSource}
          loading={loading}
          // rowSelection={{fixed: true}}
          scroll={{x: 1200}}
          title={() => (
            <Typography.Text>
              <Typography.Text strong style={{marginRight: '12px', marginLeft: '-16px'}}>
                查询结果
              </Typography.Text>
              共计
              <Typography.Text strong style={{color: '#017BFF'}}>
                {total}
              </Typography.Text>
              条符合条件的结果
            </Typography.Text>
          )}
          columns={[
            {title: '订单编号', dataIndex: 'orderId', width: 240},
            {
              title: '所属服务',
              dataIndex: 'orderCategory',
              width: 260,
              render: (_, record) => (
                <Space>
                  {service.serviceTitle(record)}
                  {record.orderType === '2' && <Tag color={'blue'}>返</Tag>}
                </Space>
              ),
            },
            {title: '下单人', width: 80, dataIndex: 'orderMakerName'},
            {title: '下单时间', width: 180, dataIndex: 'insertTime'},
            {title: '服务地址', dataIndex: 'propertyAddr'},
            {title: '服务单状态', width: 120, dataIndex: 'orderStatus', render: tags => orderStatus[tags]},
            {
              title: '操作',
              render: (tags, record) => {
                return (
                  <Space>
                    <Button type={'link'} onClick={() => history.push('/clean/detail', {orderId: record.orderId})}>
                      查看详情
                    </Button>
                    {['4', '6'].includes(record.orderStatus) && record.remarkTag === 0 && (
                      <Button
                        type={'link'}
                        onClick={async () => {
                          await show(record.orderId)
                          search()
                        }}
                      >
                        去评价
                      </Button>
                    )}
                  </Space>
                )
              },
            },
          ]}
        />
      </div>
    </>
  )
}
