import React from 'react'
import {Button, Col, Form, FormInstance, Input, Row, Select, Space} from 'antd'
import nygDeviceStyle from '@/pages/nygDevice/device.module.less'

const valveForceArr = [
  {label: '强制通电', id: '1'},
  {label: '强制断电', id: '0'},
  {label: '自动', id: '-1'},
]

export default function NygDeviceTableForm(props: {
  form: FormInstance
  deviceType?: 'lock' | 'electric' | 'water'
  onFinish: () => void
  onReset: () => void
}) {
  const {form, onFinish, onReset, deviceType} = props
  return (
    <div className={nygDeviceStyle.formSection}>
      <Form
        form={form}
        onFinish={onFinish}
        onReset={onReset}
        initialValues={{pageNum: 1, pageSize: 10, pageable: true}}
      >
        <Row>
          <Col span={7}>
            <Form.Item label={'房源地址'} name={'propertyTitle'}>
              <Input placeholder={'小区/公寓名/房间号'} allowClear />
            </Form.Item>
          </Col>
          <Col span={7} offset={1}>
            <Form.Item label={'承租人姓名'} name={'lesseeName'}>
              <Input placeholder={'请输入姓名'} allowClear />
            </Form.Item>
          </Col>
          <Col span={7} offset={1}>
            <Form.Item label={'承租人电话'} name={'lesseeMobile'}>
              <Input placeholder={'请输入电话'} allowClear />
            </Form.Item>
          </Col>
          {deviceType === 'electric' && (
            <Col span={7}>
              <Form.Item label={'控制状态'} name={'valveForce'}>
                <Select placeholder={'请选择'}>
                  {valveForceArr.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row justify={'end'}>
          <Space>
            <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
              查询
            </Button>
            <Button htmlType={'reset'}>重置</Button>
          </Space>
        </Row>
      </Form>
    </div>
  )
}
