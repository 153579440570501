import React, {useCallback, useEffect, useState} from 'react'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import {Button, Col, Form, Modal, Row, Select, Space, Table, Typography} from 'antd'
import commonStyle from '@/assets/styles/common.module.less'
import {useHistory} from 'react-router'
import {contractModel} from '@/store/models/Contract'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {baseUrl} from '@/common/config'
import {Permission} from '@/ui/components/Permission'

const {Option} = Select

export default function TemplateContract() {
  const history = useHistory()
  const contractTemplateTypeCode = useCodeSync('contract.template.type')
  const contractTemplateTypeArray = useCodeArraySync('contract.template.type')

  const [form] = Form.useForm()

  const [preview, setPreview] = useState({visible: false, attach: ''})
  const [total, setTotal] = useState(0)
  const [reqParams, setReqParams] = useState<Partial<ContractTemplateListQueryDTO>>({})
  const [templateList, setTemplateList] = useState<ContractTemplateVO[]>([])
  const listLoading = contractModel.contractTemplateList.useLoading()

  const queryContractTemplateList = useCallback(function (params: Partial<ContractTemplateListQueryDTO>) {
    contractModel.contractTemplateList(params).then(({response: {data, total}}) => {
      setTotal(total)
      setTemplateList(data)
    })
  }, [])

  useEffect(
    function () {
      queryContractTemplateList(reqParams)
    },
    [queryContractTemplateList, reqParams]
  )

  const renderOperate = (args: ContractTemplateVO) => {
    const {templateId, templateStatus} = args
    return [
      {
        when: true,
        title: '预览',
        loading: false,
        onClick: () => setPreview({visible: true, attach: args.templateAttach}),
      },
      {
        when: true,
        title: '编辑',
        loading: false,
        onClick: () => history.push('/template-contract/add', {templateId}),
      },
      {
        when: true,
        title: templateStatus === '0' ? '启用' : '禁用',
        loading: contractModel.contractTemplateUpdateStatus.useLoading(),
        onClick: async () => {
          await contractModel.contractTemplateUpdateStatus({
            templateId,
            templateStatus: templateStatus === '1' ? '0' : '1',
          })
          queryContractTemplateList(reqParams)
        },
      },
      {
        when: templateStatus === '0',
        title: '删除',
        loading: contractModel.contractTemplateDelete.useLoading(),
        onClick: async () => {
          await contractModel.contractTemplateDelete({templateId})
          queryContractTemplateList(reqParams)
        },
      },
    ]
  }

  return (
    <>
      <Breadcrumb
        path={['首页', '设置', '合同模板设置']}
        right={
          <Permission code={'007006002'}>
            <Button type={'primary'} onClick={() => history.push('/template-contract/add')}>
              新增模板
            </Button>
          </Permission>
        }
      />
      <div className={commonStyle.section}>
        <Form
          form={form}
          onFinish={formValues => setReqParams(prevState => ({...prevState, ...formValues, pageNum: 1}))}
          onReset={() => setReqParams({pageNum: 1})}
        >
          <Row>
            <Col span={8}>
              <Form.Item name={'templateType'} label={'合同类型'}>
                <Select placeholder={'请选择'} allowClear>
                  {contractTemplateTypeArray.map(item => (
                    <Option key={item.id} value={item.id}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8} offset={1}>
              <Form.Item name={'templateStatus'} label={'状态'}>
                <Select placeholder={'请选择'} allowClear>
                  <Option value={'0'}>未使用</Option>
                  <Option value={'1'}>使用中</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div className={commonStyle.section}>
        <Table
          rowKey={'templateId'}
          loading={listLoading}
          dataSource={templateList}
          columns={[
            {title: '模板编号', dataIndex: 'templateCode', key: 'templateCode'},
            {
              title: '类型',
              dataIndex: 'templateType',
              key: 'templateType',
              render: templateType => contractTemplateTypeCode[templateType],
            },
            {title: '合同模板名称', dataIndex: 'templateName', key: 'templateName'},
            {title: '创建日期', dataIndex: 'insertTime', key: 'insertTime'},
            {
              title: '状态',
              dataIndex: 'templateStatus',
              key: 'templateStatus',
              render: templateStatus => (templateStatus === '1' ? '使用中' : '未使用'),
            },
            {
              title: '操作',
              render: values => {
                return (
                  <Space>
                    {renderOperate(values)
                      .filter(item => item.when)
                      .map(item => (
                        <Button
                          key={item.title}
                          type={'link'}
                          onClick={item.onClick}
                          style={{padding: 0}}
                          loading={item.loading}
                        >
                          {item.title}
                        </Button>
                      ))}
                  </Space>
                )
              },
            },
          ]}
          title={() => (
            <Typography.Text>
              <Typography.Text strong style={{marginRight: '12px', marginLeft: '-16px'}}>
                查询结果
              </Typography.Text>
              共计
              <Typography.Text strong style={{color: '#017BFF'}}>
                {total}
              </Typography.Text>
              条符合条件的结果
            </Typography.Text>
          )}
          pagination={{
            total,
            defaultPageSize: 10,
            current: reqParams.pageNum,
            position: ['bottomCenter'],
            hideOnSinglePage: false,
            onChange: (page, pageSize) => setReqParams(prevState => ({...prevState, pageNum: page, pageSize})),
          }}
        />
      </div>

      <Modal
        visible={preview.visible}
        width={'60%'}
        footer={null}
        onCancel={() => setPreview({visible: false, attach: ''})}
      >
        <iframe frameBorder={0} width={'100%'} height={'600px'} src={`${baseUrl}/template/online/v2`} />
        <Typography.Paragraph style={{whiteSpace: 'pre-wrap', fontFamily: 'SimHei,serif', fontSize: '12px'}}>
          {preview.attach}
        </Typography.Paragraph>
      </Modal>
    </>
  )
}
