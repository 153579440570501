import React from 'react'
import {Tabs} from 'antd'

const {TabPane} = Tabs

export default function DeviceTabs(props: {
  tabList: {title: string; key: string}[]
  onChange: (activeKey: string) => void
}) {
  const {tabList, onChange} = props

  return (
    <Tabs onChange={onChange} tabBarStyle={{margin: 0, backgroundColor: 'white', paddingLeft: '20px'}}>
      {tabList.map(item => (
        <TabPane tab={item.title} key={item.key} />
      ))}
    </Tabs>
  )
}
