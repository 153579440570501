import {useCallback} from 'react'
import {lessorModel} from '@/store/models/Lessor'
import {Modal} from 'antd'

export function useApplyCheck() {
  const check = useCallback(async (contractId: string) => {
    const {
      response: {data},
    } = await lessorModel.lessorContractChangeApplyCheck({contractId})
    if (data.applyCount > 0) {
      Modal.warning({
        title: '提示',
        content: '当前租约有租客申请未处理，请前往房东APP-我的-租客申请处理完后进行操作',
        zIndex: 10000,
      })
      return Promise.reject()
    }
  }, [])
  return [check]
}
