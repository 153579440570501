import {baseUrl, fileUrl, ossUrl} from '@/common/config'
import {getModels} from '@/utils/globalServer'
import {message} from 'antd'
import {createHeaders} from '@/store/service'
import dayjs from 'dayjs'

export const delay = (ms = 1000, timeoutValue: any = false) =>
  new Promise(resolve => setTimeout(resolve, ms, timeoutValue))

export function getImg(path = '') {
  if (!path || /^(https?|file):\/\//.test(path)) {
    return path
  }
  return `${fileUrl}${path}`
}

export function isImage(path: string) {
  return /\.(jpe?g|png)$/.test(path)
}

export function isVideo(url: string) {
  return [
    /\.mpe?4$/i,
    /\.flv$/i,
    /\.wmv$/i,
    /\.avi$/i,
    /\.mov$/i,
    /\.mkv$/i,
    /\.m4v$/i,
    /\.vob$/i,
    /\.qsv$/i,
    /\.rmvb$/i,
    /\.rm$/i,
    /\.f4v$/i,
    /\.mv$/i,
  ].some(item => item.test(url))
}

export function arrayCreate(length, {labelSuffix = '', labelPrefix = '', renderValue = i => i, idOffset = 0} = {}) {
  return Array.from({length}).map((_, i) => ({
    id: i + 1 + idOffset,
    label: `${labelPrefix ?? ''}${renderValue(i + 1 + idOffset)}${labelSuffix ?? ''}`,
  }))
}

export function objectCreate(length, {labelSuffix = '', labelPrefix = '', renderValue = i => i, idOffset = 0} = {}) {
  return Array.from({length}).reduce<CodeItem>((result, _, i) => {
    result[`${i + 1 + idOffset}`] = `${labelPrefix ?? ''}${renderValue(i + 1 + idOffset)}${labelSuffix ?? ''}`
    return result
  }, {})
}

export function showLabelById(id, options: Option[] = []) {
  return options.find(item => item.id === id)?.label || ''
}

export function showAmount(amount: number | string, toFixed = 2) {
  if (!amount) amount = 0
  else amount = +amount / 100

  return amount.toFixed(toFixed)
}

export function transformCondition(cond) {
  return Object.keys(cond).reduce(
    (result, key) => ({
      [key]: Array.isArray(cond[key]) ? cond[key].join(',') : cond[key],
      ...result,
    }),
    {}
  )
}

export function unique(arr: Array<{[key: string]: any}>, key: string) {
  const map = new Map()
  return arr.filter(item => !map.has(item[key] + '') && map.set(item[key] + '', 1))
}

export function downloadFile(fileurl: string, fileName: string) {
  const a = document.createElement('a')
  a.download = fileName
  a.style.display = 'none'
  a.href = `${ossUrl}${fileurl}`
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

interface DownloadBlobOptions extends Omit<RequestInit, 'body'> {
  url: string
  body?: Record<string, any>
}

export async function downloadBlob({url, body, headers, ...options}: DownloadBlobOptions) {
  url = /^https?:\/\//.test(url) ? url : `${baseUrl}/${url.replace(/^\//, '')}`
  const fetchHeaders = {
    ...createHeaders(),
    'Content-Type': 'application/json',
    ...headers,
  }
  const fetchBody = body || {}

  const response = await fetch(url, {
    method: 'post',
    headers: fetchHeaders,
    body: JSON.stringify(fetchBody),
    ...options,
  })
  const contentType = response.headers.get('content-type')
  if (contentType === 'application/json') {
    const data = await response.json()
    message.warn(data.msg)
    return
  }

  let filename = response.headers.get('nyg8-filename')
  filename = filename ? decodeURIComponent(filename) : '导出.xlsx'
  const blob = await response.blob()
  const a = document.createElement('a')
  a.href = URL.createObjectURL(blob)
  a.download = filename
  a.click()
  URL.revokeObjectURL(a.href)
}

export function createSplit<T>(...fns: ((item: T) => boolean)[]) {
  return (list: T[]) => {
    return (list || []).reduce<T[][]>(
      (prev, item) => {
        for (const [index, fn] of fns.entries()) {
          if (fn(item)) {
            prev[index].push(item)
            break
          }
        }

        return prev
      },
      fns.map(() => [])
    )
  }
}

export function uploadFeedback({url = '/feedback/upload', body}) {
  url = /^https?:\/\//.test(url) ? url : `${baseUrl}/${url.replace(/^\//, '')}`
  const fetchHeaders = {
    'nyg8-auth': getModels()?.userModel.data?.token,
    // 'Content-Type': 'multipart/form-data',
    'NYG8-TEAM-ID': getModels()?.userModel.data?.currentTeam?.teamId,
  }
  return fetch(url, {method: 'post', headers: fetchHeaders, body: body})
}

/**
 * 判断传入值是否是假
 */
export function isFalsy(value) {
  return [null, undefined, NaN, ''].includes(value)
}

export function debounce<F extends (...args) => any>(fn: F, time = 350): (...args: Parameters<F>) => void {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => fn(...args), time)
  }
}

// 格式化为日期之间，例：2022-10-11 14:00-18:00
export function formatRangeDate(begin: string, end: string) {
  if (!begin || !end) return ''
  return `${dayjs(begin).format('YYYY-MM-DD HH:mm')}-${dayjs(end).format('HH:mm')}`
}

// 根据订单 ID 判断是否是保洁
export function isClean(orderId: string) {
  return /^ZH/.test(orderId)
}
