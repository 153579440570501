import React, {useEffect, useState} from 'react'
import styles from '@/pages/setting/ConfigContract/index.module.less'
import {Breadcrumb, Button, Divider, message, Modal, Slider, Tooltip, Typography} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import {contractModel} from '@/store/models/Contract'

export default function PaymentRules() {
  const [rate, setRate] = useState(0)
  const [rateInfo, setRateInfo] = useState({} as LessorOnlineRateVO)

  const [show, setShow] = useState(false)

  useEffect(function () {
    contractModel.lessorConfigOnlineRateInfo().then(({response: {data}}) => {
      // console.log('data =>', data)
      setRate(data.onlineRateLessor)
      setRateInfo(data)
    })
  }, [])

  return (
    <>
      <Breadcrumb className={styles.Breadcrumb}>
        <Breadcrumb.Item>首页</Breadcrumb.Item>
        <Breadcrumb.Item>设置</Breadcrumb.Item>
        <Breadcrumb.Item>支付规则设置</Breadcrumb.Item>
      </Breadcrumb>

      <div className={`${commonStyles.section}`}>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography.Text strong>在线支付费率承担设置</Typography.Text>
          <Button type={'primary'} size={'small'} onClick={() => setShow(true)}>
            保存
          </Button>
        </div>
        <Divider dashed style={{margin: '16px 0'}} />
        <Typography.Text>费率金额为在线支付总额的{rateInfo.onlineRate}%</Typography.Text>
        <div style={{display: 'flex', alignItems: 'center', marginTop: '45px'}}>
          <Typography.Text>费率承担</Typography.Text>
          <div style={{display: 'flex', alignItems: 'center', marginLeft: '60px'}}>
            <Tooltip title={`${rate}%`} visible={true} overlayStyle={{fontSize: '12px', zIndex: 1000}}>
              <span>公寓承担</span>
            </Tooltip>
            <Slider
              value={rate}
              tooltipVisible={false}
              style={{width: 230, margin: '0 15px'}}
              onChange={value => setRate(value as number)}
            />
            <Tooltip title={`${100 - rate}%`} visible={true} overlayStyle={{fontSize: '12px', zIndex: 1000}}>
              <span>租客承担</span>
            </Tooltip>
          </div>
        </div>
      </div>

      <Modal
        title='提示'
        closable={true}
        centered={true}
        visible={show}
        onOk={async () => {
          setShow(false)
          await contractModel.lessorConfigOnlineRateSetting({onlineRateLessee: 100 - rate, onlineRateLessor: rate})
          message.success('保存成功！')
        }}
        onCancel={() => setShow(false)}
      >
        <p>保存后团队内所有房源将以此配置，是否确认保存？</p>
      </Modal>
    </>
  )
}
