import React, {useState, useImperativeHandle, useRef} from 'react'
import {Space, Button, Skeleton, Drawer, Descriptions} from 'antd'
import {Album} from '@/ui/components/Album'
import {getImg} from '@/utils/util'
import {contractModel} from '@/store/models/Contract'
import Cancellation from '@/pages/contract/components/Cancellation'
import {Permission} from '@/ui/components/Permission'
import {ReserveBar} from '../../ContractReserve'

interface Props {
  onSuccess?: (billId?: string) => unknown
}

function CollectionDetail({onSuccess}: Props, ref) {
  const [visible, setVisible] = useState(false)
  const [detail, setDetail] = useState({} as ContractBillDetailVO)
  const loading = contractModel.contractBillDetailQuery.useLoading()
  const cancellationRef = useRef(null)
  const [billInfo, setBillInfo] = useState({} as any)

  useImperativeHandle(ref, () => ({
    onDisplay: (billDetailId: string, info) => {
      setBillInfo(info)
      setVisible(true)
      contractModel.contractBillDetailQuery({billDetailId}).then(({response: {data}}) => {
        setDetail(data)
      })
    },
    onHidden: () => {
      setVisible(false)
      setDetail({} as ContractBillDetailVO)
    },
  }))

  return (
    <>
      <Drawer title={'账单支付明细'} visible={visible} footer={null} onClose={() => ref.current.onHidden()} width={700}>
        {loading ? (
          <Skeleton paragraph={{rows: 4}} active />
        ) : (
          <div>
            <ReserveBar label='账单信息' top={0} bottom={15} />
            <Descriptions column={2}>
              <Descriptions.Item label={<span style={{color: 'rgba(0, 0, 0, 0.65)'}}>账单科目</span>}>
                {billInfo?.billTypeName}
              </Descriptions.Item>
              <Descriptions.Item label={<span style={{color: 'rgba(0, 0, 0, 0.65)'}}>账单周期</span>}>
                {billInfo?.time || '无'}
              </Descriptions.Item>
              <Descriptions.Item label={<span style={{color: 'rgba(0, 0, 0, 0.65)'}}>应付款日</span>}>
                {billInfo?.billDate}
              </Descriptions.Item>
              <Descriptions.Item label={<span style={{color: 'rgba(0, 0, 0, 0.65)'}}>应收金额</span>}>
                {billInfo?.billAmtTotal}元
              </Descriptions.Item>
            </Descriptions>
            <ReserveBar label='支付信息' top={10} bottom={15} />
            <Descriptions column={2}>
              <Descriptions.Item label={<span style={{color: 'rgba(0, 0, 0, 0.65)'}}>收款编号</span>}>
                {detail.billDetailId}
              </Descriptions.Item>
              <Descriptions.Item label={<span style={{color: 'rgba(0, 0, 0, 0.65)'}}>状态</span>}>
                {detail.status === '1' ? `已收款${detail.writeOff === '1' ? '（已核销）' : '（未核销）'}` : '已作废'}
              </Descriptions.Item>
              <Descriptions.Item label={<span style={{color: 'rgba(0, 0, 0, 0.65)'}}>实际收款时间</span>}>
                {detail.insertTime}
              </Descriptions.Item>
              <Descriptions.Item label={<span style={{color: 'rgba(0, 0, 0, 0.65)'}}>收款日期</span>}>
                {detail.billPayTime}
              </Descriptions.Item>
              <Descriptions.Item label={<span style={{color: 'rgba(0, 0, 0, 0.65)'}}>收款方式</span>}>
                {detail.billPayWayName}
              </Descriptions.Item>
              <Descriptions.Item label={<span style={{color: 'rgba(0, 0, 0, 0.65)'}}>实收金额</span>}>
                {detail.billPayAmt}元
              </Descriptions.Item>
              <Descriptions.Item label={<span style={{color: 'rgba(0, 0, 0, 0.65)'}}>收款人</span>}>
                {detail.creatorName}
              </Descriptions.Item>

              {detail.status === '0' && (
                <>
                  <Descriptions.Item label={<span style={{color: 'rgba(0, 0, 0, 0.65)'}}>支付作废时间</span>}>
                    {detail.cancelTime}
                  </Descriptions.Item>
                  <Descriptions.Item label={<span style={{color: 'rgba(0, 0, 0, 0.65)'}}>作废操作人</span>}>
                    {detail.cancelName}
                  </Descriptions.Item>
                </>
              )}
            </Descriptions>
            <Descriptions column={1}>
              <Descriptions.Item label={<span style={{color: 'rgba(0, 0, 0, 0.65)'}}>收款备注</span>}>
                {detail.billDetailRemark ?? '无'}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions column={1}>
              <Descriptions.Item label={<span style={{color: 'rgba(0, 0, 0, 0.65)'}}>图片凭证</span>}>
                {detail.imgList?.length ? (
                  <Album
                    disabled
                    fileList={detail.imgList.map(item => ({...item, url: getImg(item.path), uid: item.path}))}
                  >
                    {null}
                  </Album>
                ) : (
                  '无'
                )}
              </Descriptions.Item>
            </Descriptions>

            <Space>
              {detail.status === '1' && (
                <Permission code={'003001006'}>
                  <Button
                    type={'primary'}
                    onClick={() => {
                      cancellationRef.current.onDisplay(detail.contractId, '', detail.billDetailId, detail.billId)
                      ref.current.onHidden()
                    }}
                  >
                    作废收款
                  </Button>
                </Permission>
              )}

              <Button
                onClick={() => {
                  setVisible(false)
                }}
              >
                关闭
              </Button>
            </Space>
          </div>
        )}
      </Drawer>
      <Cancellation ref={cancellationRef} onSuccess={extra => onSuccess(extra)} />
    </>
  )
}

export default React.forwardRef(CollectionDetail)
