import React, {useEffect, useState} from 'react'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import {Button, Form, Input, message, Select, Space} from 'antd'
import styles from './index.module.less'
import {ReserveBar} from '@/pages/contract/ContractReserve'
import {Album, UploadButton} from '@/ui/components/Album'
// import Modal from 'antd/lib/modal/Modal'
import {lessorModel} from '@/store/models/Lessor'
import {baseUrl} from '@/common/config'
import {getImg} from '@/utils/util'
import {useAppHistory} from '@/common/hooks/useRouter'

export default function CompanyAccount() {
  const [bankList, setBankList] = useState<Array<string>>([])
  const [parentBankList, setParentBankList] = useState<string[]>([])
  const [form] = Form.useForm()
  const history = useAppHistory()

  useEffect(() => {
    lessorModel.lessorCompanyApplyListBank({}).then(({response: {data}}) => {
      setBankList(data)
    })
  }, [])

  return (
    <>
      <div className={styles.breadcrumb}>
        <Breadcrumb path={['房源房态', '账户明细', '开通企业账户']} />
        <h2>开通企业账户</h2>
      </div>
      <div className={styles.body}>
        <ReserveBar label={'公司信息'} top={34} bottom={15} />
        <Form
          form={form}
          onFinish={async () => {
            const {pictureUrl1, pictureUrl8, pictureUrl9, ...formValues} = form.getFieldsValue(true)
            if (!pictureUrl1) return message.warning('请上传营业执照')
            if (!pictureUrl8) return message.warning('请上传正面照')
            if (!pictureUrl9) return message.warning('请上传反面照')
            await lessorModel.lessorCompanyApplyInsert({
              ...formValues,
              pictureUrl1,
              pictureUrl8,
              pictureUrl9,
              identityType: 1,
            })
            message.success('提交成功！')
            history.goBack()
          }}
          labelCol={{span: 3}}
          labelAlign={'left'}
          wrapperCol={{span: 8}}
        >
          <Form.Item label={'公司名称'} name={'companyName'} rules={[{required: true, message: '请输入公司名称'}]}>
            <Input placeholder='请输入' />
          </Form.Item>
          <Form.Item
            label={'统一社会信用代码'}
            name={'uniCredit'}
            rules={[{required: true, message: '请输入统一社会信用代码'}]}
          >
            <Input placeholder='请输入' />
          </Form.Item>
          <Form.Item label={'法人姓名'} name={'legalName'} rules={[{required: true, message: '请输入法人姓名'}]}>
            <Input placeholder='请输入' />
          </Form.Item>
          <Form.Item
            label={'法人身份证号码'}
            name={'legalIds'}
            rules={[{required: true, message: '请输入法人身份证号码'}]}
          >
            <Input placeholder='请输入' />
          </Form.Item>
          <Form.Item label={'法人手机号'} name={'legalPhone'} rules={[{required: true, message: '请输入法人手机号'}]}>
            <Input placeholder='请输入' />
          </Form.Item>
          <Form.Item shouldUpdate label={'营业执照照片'} required>
            {() => (
              <Album
                action={baseUrl + '/lessor/company/apply/upload'}
                showUploadList={false}
                onDone={fileList => {
                  form.setFieldsValue({pictureUrl1: fileList.pop().imgUrl})
                }}
              >
                {form.getFieldValue('pictureUrl1') ? (
                  <img style={{width: 80, height: 80}} src={getImg(form.getFieldValue('pictureUrl1'))} alt='' />
                ) : (
                  <UploadButton title='上传' />
                )}
              </Album>
            )}
          </Form.Item>
          <Form.Item label={'法人身份证照片'} required>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              {[
                {title: '正面', keys: 'pictureUrl8'},
                {title: '反面', keys: 'pictureUrl9'},
              ].map((item, index) => {
                const {title, keys} = item
                return (
                  <Form.Item key={index} shouldUpdate>
                    {() => (
                      <Album
                        action={baseUrl + '/lessor/company/apply/upload'}
                        showUploadList={false}
                        onDone={fileList => {
                          form.setFieldsValue({[keys]: fileList.pop().imgUrl})
                        }}
                      >
                        {form.getFieldValue(keys) ? (
                          <img style={{width: 80, height: 80}} src={getImg(form.getFieldValue(keys))} alt='' />
                        ) : (
                          <UploadButton title={title} />
                        )}
                      </Album>
                    )}
                  </Form.Item>
                )
              })}
            </div>
          </Form.Item>
          <ReserveBar label={'对公账户'} top={34} bottom={15} />
          <Form.Item label={'账户账号'} name={'accountNo'} rules={[{required: true, message: '请输入账户账号'}]}>
            <Input placeholder='请输入' />
          </Form.Item>
          <Form.Item
            label={'开户银行名称'}
            name={'parentBankName'}
            rules={[{required: true, message: '请输入开户银行名称'}]}
          >
            <Select
              placeholder={'请输入开户银行名称'}
              showSearch
              filterOption={false}
              onSearch={value => {
                setParentBankList(bankList.filter(item => item.indexOf(value) !== -1))
              }}
            >
              {parentBankList.map(item => (
                <Select.Option value={item} key={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={'开户银行支行名称'}
            name={'bankName'}
            rules={[{required: true, message: '请输入开户银行支行名称'}]}
          >
            <Input placeholder='请输入' />
          </Form.Item>
          <Form.Item
            label={'开户银行支行行号'}
            name={'unionBank'}
            rules={[{required: true, message: '请输入开户银行支行行号'}]}
          >
            <Input placeholder='请输入' />
          </Form.Item>
          <Form.Item label={' '} colon={false}>
            <Space>
              <Button type={'primary'} htmlType={'submit'}>
                下一步
              </Button>
              <Button onClick={() => history.goBack()}>返回</Button>
            </Space>
          </Form.Item>
        </Form>
      </div>

      {/* 手机号码验证 */}
      {/* <Modal visible={false} title={'手机号验证'} centered cancelText={'取消'} okText={'确认'}>
        <Form wrapperCol={{span: 13}}>
          <Form.Item label={'手机号'}>
            <Input placeholder='请输入' />
          </Form.Item>

          <Form.Item shouldUpdate label={'验证码'}>
            {() => (
              <Input.Group compact>
                <Input placeholder='请输入' />
                <Button>发送验证码</Button>
              </Input.Group>
            )}
          </Form.Item>
        </Form>
      </Modal> */}
    </>
  )
}
