import React, {useCallback, useEffect, useState} from 'react'
import commonStyle from '@/assets/styles/common.module.less'
import {Col, Form, Row, Select, DatePicker, Button, Space, Table, Tabs} from 'antd'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import {propertyModel} from '@/store/models/Property'
import {downloadBlob} from '@/utils/util'
import {tradeModel} from '@/store/models/Trade'
import moment from 'moment'
import {getPaginationProps} from '@/common/biz/getPaginationProps'

const {Option} = Select
const {RangePicker} = DatePicker
interface DistrictProps {
  id: string
  label: string
  data: CommunityVO[]
}
export default function DataStatistics() {
  const [form] = Form.useForm()
  //地区
  const [districtList, setDistrictList] = useState<DistrictProps[]>([])
  //小区
  const [communityList, setCommunityList] = useState<CommunityVO[]>([])
  const [propertyStatisticsList, setPropertyStatisticsList] = useState<PropertyStatisticsVO[]>([])
  const [params, setParams] = useState<Partial<PropertyStatisticsListDTO>>({pageNum: 1, pageSize: 10})
  const [total, setTotal] = useState<number>(0)
  const loading = tradeModel.propertyStatisticsList.useLoading()
  const [tabKey, setTabKey] = useState('')
  useEffect(() => {
    tradeModel.propertyStatisticsList(params).then(({response: {data, total}}) => {
      setPropertyStatisticsList(data)
      setTotal(total)
    })
  }, [params])

  useEffect(() => {
    propertyModel.propertyCommunityList({}).then(({response: {data}}) => {
      if (data.length) {
        const keys = []
        const lists = data.reduce((result, item) => {
          if (keys.includes(item.districtCode)) {
            return result.map(_item =>
              _item.id === item.districtCode ? {..._item, data: [..._item.data, item]} : _item
            )
          } else {
            keys.push(item.districtCode)
            return [...result, {id: item.districtCode, label: item.districtName, data: [item]}]
          }
        }, [])
        setDistrictList(lists)
      }
    })
  }, [])

  const [statisticForm] = Form.useForm()
  const [incomeList, setIncomeList] = useState<PropertyIncomeStatisticsVO[]>([])
  const [incomeTotal, setIncomeTotal] = useState(0)
  const incomeLoading = propertyModel.propertyStatisticsIncomeList.useLoading()
  const statisticSearch = useCallback(() => {
    const values = statisticForm.getFieldsValue(true)
    propertyModel.propertyStatisticsIncomeList(values).then(({response: {data, total}}) => {
      setIncomeList(data)
      setIncomeTotal(total)
    })
  }, [statisticForm])

  useEffect(() => {
    statisticForm.submit()
  }, [statisticForm])

  return (
    <div>
      <Breadcrumb
        path={['首页', '财务管理', '数据统计']}
        right={
          tabKey === '1' && (
            <Button onClick={() => downloadBlob({url: '/property/statistics/list-excel', body: params})}>导出</Button>
          )
        }
      />
      <Tabs className={commonStyle.tabs} onChange={key => setTabKey(key)}>
        <Tabs.TabPane tab={'平均租金'} key={'1'}>
          <div className={commonStyle.section}>
            <Form
              form={form}
              onFinish={() => {
                const {time, ...rest} = form.getFieldsValue(true)
                const [beginDate, endDate] = time?.length ? time.map(item => moment(item).format('YYYY-MM-DD')) : []
                setParams(state => ({...state, pageNum: 1, beginDate, endDate, ...rest}))
              }}
            >
              <Row gutter={[10, 0]}>
                <Col span={4}>
                  <Form.Item label={'小区'} name={''}>
                    <Select
                      placeholder={'地区'}
                      onChange={(value: string) => {
                        setCommunityList(districtList.find(item => item.id === value)?.data || [])
                        form.setFieldsValue({communityCode: undefined})
                      }}
                      allowClear
                    >
                      {districtList.map(item => (
                        <Option key={item.id} value={item.id}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name={'communityCode'}>
                    <Select placeholder={'小区名称'} allowClear>
                      {communityList.map(item => (
                        <Option key={item.code} value={item.code}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col offset={1}>
                  <Form.Item label={'日期'} name={'time'}>
                    <RangePicker />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify={'end'}>
                <Space>
                  <Button type={'primary'} htmlType={'submit'}>
                    查询
                  </Button>
                  <Button
                    onClick={() => {
                      setParams({pageNum: 1, pageSize: 10})
                      form.resetFields()
                    }}
                  >
                    重置
                  </Button>
                </Space>
              </Row>
            </Form>
          </div>
          <div className={commonStyle.section}>
            <Table
              rowKey={'id'}
              loading={loading}
              bordered
              dataSource={propertyStatisticsList}
              pagination={{
                total,
                defaultCurrent: 1,
                defaultPageSize: 10,
                pageSize: params.pageSize,
                current: params.pageNum,
                position: ['bottomCenter'],
                showSizeChanger: true,
                onChange: (pageNum, pageSize) => setParams(state => ({...state, pageNum, pageSize})),
              }}
              columns={[
                {title: '日期', dataIndex: 'date', key: 'date'},
                {title: '团队名称', dataIndex: 'teamName', key: 'teamName'},
                {title: '小区', dataIndex: 'communityName', key: 'communityName'},
                {
                  title: '全部房间',
                  children: [
                    {title: '房间数量（间）', dataIndex: 'propertyNum', key: 'propertyNum'},
                    {title: '平均租金（元）', dataIndex: 'rentPrice', key: 'rentPrice'},
                  ],
                },
                {
                  title: '出租房间',
                  children: [
                    {title: '房间数量（间）', dataIndex: 'rentOutNum', key: 'rentOutNum'},
                    {title: '平均租金（元）', dataIndex: 'rentOutPrice', key: 'rentOutPrice'},
                  ],
                },
                {title: '入住率（%）', dataIndex: 'rentRate', key: 'rentRate'},
              ]}
            />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab={'收入统计'} key={'2'} forceRender>
          <div className={commonStyle.section}>
            <Form
              form={statisticForm}
              onFinish={statisticSearch}
              onReset={statisticSearch}
              initialValues={{pageNum: 1, pageSize: 10}}
            >
              <Row>
                <Col>
                  <Form.Item label={'月份'} name={'_time'}>
                    <DatePicker.RangePicker
                      picker={'month'}
                      onChange={(_, dateStrings) => {
                        const [beginDate, endDate] = dateStrings
                        statisticForm.setFieldsValue({beginDate, endDate})
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify={'end'}>
                <Space>
                  <Button
                    type={'primary'}
                    htmlType={'submit'}
                    onClick={() => statisticForm.setFieldsValue({pageNum: 1})}
                  >
                    查询
                  </Button>
                  <Button htmlType={'reset'}>重置</Button>
                </Space>
              </Row>
            </Form>
          </div>
          <div className={commonStyle.section}>
            <Table
              rowKey={'id'}
              dataSource={incomeList}
              loading={incomeLoading}
              columns={[
                {title: '月份', dataIndex: 'month'},
                {title: '团队名称', dataIndex: 'teamName'},
                {title: '门店', dataIndex: 'communityName'},
                {title: '房间总数量（间）', dataIndex: 'propertyNum'},
                {title: '出租房间数量（间）', dataIndex: 'rentOutNum'},
                {title: '当月应收（元）', dataIndex: 'amount'},
                {title: '当月已收（元）', dataIndex: 'payAmount'},
              ]}
              pagination={getPaginationProps(statisticForm, incomeTotal)}
            />
          </div>
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}
