import React, {useCallback, useEffect, useState} from 'react'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import nygDeviceStyle from '@/pages/nygDevice/device.module.less'
import NygDeviceTableForm from '@/pages/nygDevice/components/NygDeviceTableForm'
import {useAppHistory} from '@/common/hooks/useRouter'
import {Button, DatePicker, Form, message, Modal, Space, Table} from 'antd'
import DeviceTableHeader from '@/pages/device/components/DeviceTableHeader'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {deviceModel} from '@/store/models/Device'
import NygDeviceHistory from '@/pages/nygDevice/components/NygDeviceHistory'
import NygDeviceRecharge from '@/pages/nygDevice/components/NygDeviceRecharge'
import {downloadBlob} from '@/utils/util'

export default function NygDeviceWater() {
  const history = useAppHistory()

  const [form] = Form.useForm()
  const [total, setTotal] = useState(0)
  const [waterList, setWaterList] = useState<WaterMeter[]>([])
  const [visible, setVisible] = useState(false)

  const [deviceId, setDeviceId] = useState('')

  const [isRecharge, setIsRecharge] = useState(false)
  const [waterMeter, setWaterMeter] = useState({} as WaterMeter)

  const queryWaterMeterList = useCallback(() => {
    const formValues = form.getFieldsValue(true)
    // console.log('formValues', formValues)
    deviceModel.deviceV2WaterMeterList({...formValues}).then(({response: {data, total}}) => {
      // console.log('data', data)
      // console.log('total', total)
      setWaterList(data)
      setTotal(total)
    })
  }, [form])

  useEffect(() => {
    queryWaterMeterList()
  }, [queryWaterMeterList])

  const waterLoading = deviceModel.deviceV2WaterMeterList.useLoading()
  const infoLoading = deviceModel.deviceV2WaterMeterInfo.useLoadings()

  return (
    <div>
      <Breadcrumb path={['首页', '智能设备', '水表']} right={<Button onClick={() => setVisible(true)}>导出</Button>} />
      <NygDeviceTableForm form={form} onFinish={queryWaterMeterList} onReset={queryWaterMeterList} />

      <div className={nygDeviceStyle.tableSection}>
        <DeviceTableHeader total={total} />
        <Table
          rowKey={'deviceId'}
          columns={[
            {title: '设备名称/ID', dataIndex: 'deviceId'},
            {title: '房源地址', dataIndex: 'propertyTitle'},
            {title: '承租人', dataIndex: 'lesseeName', render: value => value ?? '/'},
            {title: '承租人手机号', dataIndex: 'lesseeMobile', render: value => value ?? '/'},
            {title: '上次读表时间', dataIndex: 'syncTime'},
            // {title: '价格（元/吨）', dataIndex: 'waterPrice'},
            {
              title: '操作',
              dataIndex: 'deviceId',
              render: (value, record) => {
                return (
                  <Space>
                    <Button
                      type={'link'}
                      style={{padding: 0}}
                      onClick={() => {
                        history.push('/nyg-device-water/detail', {deviceId: value})
                      }}
                    >
                      查看
                    </Button>
                    <Button type={'link'} style={{padding: 0}} onClick={() => setDeviceId(value)}>
                      历史信息
                    </Button>
                    <Button
                      type={'link'}
                      style={{padding: 0}}
                      loading={infoLoading.pick(value)}
                      onClick={async () => {
                        if (!record.contractId) return message.error('当前设备未绑定租约，不能充值！')
                        const {
                          response: {data},
                        } = await deviceModel.deviceV2WaterMeterInfo({waterMeterId: value})
                        setIsRecharge(true)
                        setWaterMeter(data)
                      }}
                    >
                      充值
                    </Button>
                  </Space>
                )
              },
            },
          ]}
          dataSource={waterList}
          loading={waterLoading}
          pagination={getPaginationProps(form, total)}
        />
      </div>

      <NygDeviceRecharge
        info={waterMeter}
        visible={isRecharge}
        onOk={() => {
          setIsRecharge(prevState => !prevState)
          queryWaterMeterList()
        }}
        onCancel={() => setIsRecharge(prevState => !prevState)}
      />

      <NygDeviceHistory deviceId={deviceId} visible={!!deviceId} onCancel={() => setDeviceId('')} />

      <ExportView
        visible={visible}
        onCancel={() => setVisible(false)}
        url={'/device/v2/water-meter/statistics-excel'}
      />
    </div>
  )
}

interface ExportProps {
  visible: boolean
  onCancel: () => void
  url: string
}

export function ExportView({visible, onCancel, url}: ExportProps) {
  const [form] = Form.useForm()
  return (
    <Modal
      visible={visible}
      title={'导出'}
      centered
      destroyOnClose
      afterClose={() => form.resetFields()}
      onCancel={onCancel}
      onOk={async () => {
        await form.validateFields()
        const {begin, end} = form.getFieldsValue(true)
        await downloadBlob({url, body: {begin, end, pageNum: 1, pageSize: 10, pageable: false}})
        onCancel()
      }}
    >
      <Form form={form}>
        <Form.Item label={'统计日期'} name={'_time'} rules={[{required: true, message: '请输入统计日期'}]}>
          <DatePicker.RangePicker
            onChange={(_, dateString) => {
              const [begin, end] = dateString ?? []
              form.setFieldsValue({begin, end})
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
