import {useHistory, useLocation} from 'react-router'
import type * as H from 'history'

type InferParams<Params, Path> = Path extends keyof Params ? Params[Path] : any

interface EnhancerHistory<Params> extends Omit<H.History, 'push' | 'replace'> {
  push<Path extends string>(location: EnhancerLocationDescriptor<Path, InferParams<Params, Path>>): void
  push<Path extends string>(path: Path, state?: InferParams<Params, Path>): void

  replace<Path extends string>(location: EnhancerLocationDescriptor<Path, InferParams<Params, Path>>): void
  replace<Path extends string>(path: Path, state?: InferParams<Params, Path>): void
}

interface EnhancerLocationDescriptor<Path, S> extends Omit<H.LocationDescriptorObject<S>, 'pathname' | 'state'> {
  pathname?: Path
  state?: S
}

/**
 * 用法与 useHistory() 相同
 */
export const useAppHistory: () => EnhancerHistory<RouterParamList> = () => useHistory()

/**
 * 用法与 useLocation() 相同
 */
export function useAppLocation<T = any>() {
  return useLocation<InferParams<RouterParamList, T>>()
}
