import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Button, List, message, Radio} from 'antd'
import {messageModel} from '@/store/models/Message'

export default function MessageList() {
  const msgTypeCode = useMemo(
    () => ({'1': '租约账单', '2': '管家服务', '3': '系统消息', '4': '账户信息', '9': '财务账单', '10': '服务评价'}),
    []
  )

  const [msgType, setMsgType] = useState('1')
  const [msgOption, setMsgOption] = useState<{label: string; value: string}[]>([])

  const [dataList, setDataList] = useState<LessorMessageVO[]>([])
  const [total, setTotal] = useState(0)
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [readStatus, setReadStatus] = useState<'0' | '1'>('0')

  // 类别
  const queryMsgCate = useCallback(
    function () {
      messageModel.lessorMessageHomepage().then(({response: {data}}) => {
        setMsgOption(
          data.map(item => ({
            label: item.unreadNum ? `${msgTypeCode[item.msgType]}（${item.unreadNum}）` : msgTypeCode[item.msgType],
            value: item.msgType,
          }))
        )
      })
    },
    [msgTypeCode]
  )
  useEffect(() => queryMsgCate(), [queryMsgCate])

  // 列表
  const queryMsgTypeList = useCallback(function (params: {
    msgType: string
    pageNum: number
    pageSize: number
    readStatus: string
  }) {
    const {msgType, pageNum, pageSize, readStatus} = params
    messageModel.lessorMessageTypeList({msgType, pageNum, pageSize, readStatus}).then(({response: {data, total}}) => {
      // console.log('total =>', total)
      // console.log('msgType list =>', data)
      setDataList(data)
      setTotal(total)
    })
  },
  [])

  useEffect(
    function () {
      queryMsgTypeList({msgType, pageNum, pageSize, readStatus})
    },
    [msgType, pageNum, pageSize, queryMsgTypeList, readStatus]
  )

  // 标记已读
  async function msgRead(params: {msgType?: string; msgId?: string}) {
    const {msgType, msgId} = params
    await messageModel.lessorMessageRead({msgType, msgId})
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Radio.Group
          value={msgType}
          size={'large'}
          buttonStyle='solid'
          onChange={({target: {value}}) => setMsgType(value)}
        >
          {msgOption.map(item => (
            <Radio.Button key={item.value} value={item.value}>
              {item.label}
            </Radio.Button>
          ))}
        </Radio.Group>
        <div>
          <Radio.Group
            value={readStatus}
            size={'middle'}
            buttonStyle='solid'
            onChange={({target: {value}}) => setReadStatus(value)}
          >
            <Radio.Button value='0'>未读</Radio.Button>
            <Radio.Button value='1'>已读</Radio.Button>
          </Radio.Group>

          <Button
            type={'primary'}
            size={'large'}
            style={{marginLeft: 26}}
            onClick={() => {
              msgRead({msgType})
              queryMsgCate()
              queryMsgTypeList({msgType, pageNum, pageSize, readStatus})
            }}
          >
            全部已读
          </Button>
        </div>
      </div>
      <List
        bordered
        dataSource={dataList}
        renderItem={item => (
          <List.Item
            actions={[
              item.readStatus === '0' ? (
                <div style={{minWidth: 100}}>
                  <a
                    key='edit'
                    style={{textDecorationLine: 'underline'}}
                    onClick={async () => {
                      await msgRead({msgType: item.msgType, msgId: item.msgId})
                      message.success('操作成功！')
                      queryMsgCate()
                      queryMsgTypeList({msgType, pageNum, pageSize, readStatus})
                    }}
                  >
                    标记已读
                  </a>
                </div>
              ) : (
                <div style={{minWidth: 100}}>
                  <span>已读</span>
                </div>
              ),
            ]}
            extra={<span>{item.updateTime}</span>}
          >
            <List.Item.Meta title={item.title} description={item.body} />
          </List.Item>
        )}
        pagination={{
          onChange: (page, size) => {
            if (pageSize !== size) {
              setPageNum(1)
              setPageSize(size)
            } else setPageNum(page)
          },
          pageSize: pageSize,
          total: total,
        }}
        style={{backgroundColor: '#fff', marginTop: 20}}
      />
    </div>
  )
}
