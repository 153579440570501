import React, {useEffect, useMemo, useState} from 'react'
import styles from './index.module.less'
import commonStyles from '@/assets/styles/common.module.less'
import {Button, Checkbox, Col, message, Row, Spin, Typography} from 'antd'
import {contractModel} from '@/store/models/Contract'
import {Breadcrumb} from '@/ui/components/Breadcrumb'

export default function ConfigContract() {
  const [billConfigList, setBillConfigList] = useState<LessorConfigListBillVO[]>([])
  const [payConfigList, setPayConfigList] = useState<LessorConfigListPayVO[]>([])

  useEffect(function () {
    contractModel.lessorConfigContractListAll().then(({response: {data}}) => {
      setPayConfigList(data.payConfigList)
      setBillConfigList(data.billConfigList)
    })
  }, [])

  const renderBillList: {[key: string]: LessorConfigListBillVO[]} = useMemo(
    () =>
      billConfigList.reduce((result, item) => {
        if (!result[item.billCategoryName]) result[item.billCategoryName] = []
        result[item.billCategoryName].push(item)
        return result
      }, {}),
    [billConfigList]
  )

  function togglePay(payWay: string) {
    setPayConfigList(prevState =>
      prevState.map(item => (item.payWay === payWay ? {...item, selected: !item.selected} : item))
    )
  }

  function toggleBill(billTypeCode: string) {
    setBillConfigList(prevState =>
      prevState.map(item => (item.billTypeCode === billTypeCode ? {...item, selected: !item.selected} : item))
    )
  }

  async function savePay() {
    const data = payConfigList.filter(item => item.selected)
    if (data.length) {
      await contractModel.lessorConfigContractSavePayConfig(data)
      message.success('保存成功')
    }
  }
  async function saveBill() {
    const data = billConfigList.filter(item => item.selected)
    if (data.length) {
      await contractModel.lessorConfigContractSaveBillConfig(data)
      message.success('保存成功')
    }
  }

  return (
    <>
      <Breadcrumb path={['首页', '设置', '账单科目设置']} />
      <Spin spinning={contractModel.lessorConfigContractListAll.useLoading()}>
        <div className={`${commonStyles.section} ${styles.section}`}>
          <div className={styles.sectionHeader}>
            <Typography.Text>支付渠道设置</Typography.Text>
            <Button
              type={'primary'}
              ghost
              onClick={savePay}
              loading={contractModel.lessorConfigContractSavePayConfig.useLoading()}
            >
              保存
            </Button>
          </div>
          <div className={styles.sectionContent}>
            <Row style={{padding: '17px 0'}}>
              {payConfigList.map(item => (
                <Col span={6} key={item.payWay}>
                  <Checkbox value={item.payWay} checked={!!item.selected} onChange={e => togglePay(e.target.value)}>
                    {item.payWayName}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </div>
        </div>

        <div className={`${commonStyles.section} ${styles.section}`}>
          <div className={styles.sectionHeader}>
            <Typography.Text>账单科目设置</Typography.Text>
            <Button
              type={'primary'}
              ghost
              onClick={saveBill}
              loading={contractModel.lessorConfigContractSaveBillConfig.useLoading()}
            >
              保存
            </Button>
          </div>
          <div className={styles.sectionContent}>
            {Object.keys(renderBillList).map(key => (
              <Row className={styles.box} key={key}>
                <Col flex='144px' className={styles.boxLeft}>
                  {key}
                </Col>
                <Col flex='auto'>
                  <Row>
                    {renderBillList[key].map(item => (
                      <Col span={6} key={item.billTypeCode}>
                        <Checkbox
                          value={item.billTypeCode}
                          checked={!!item.selected}
                          onChange={e => toggleBill(e.target.value)}
                        >
                          {item.billTypeName}
                        </Checkbox>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            ))}
          </div>
        </div>
      </Spin>
    </>
  )
}
