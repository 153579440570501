import {Model} from '@redux-model/web'
import {$api} from '@/store/service'

interface PayModal {}

class Pay extends Model<PayModal> {
  protected initReducer(): PayModal {
    return {}
  }

  // 查询用户余额(查询当前所选组织的对应客户的余额)
  payBalanceByTeam = $api.action(() => this.post<ApiResponse<QueryBalanceVO>>('/pay/balance-by-team' as const))

  // 查询用户余额  |  object:{userId:客户/企业id}
  payBalance = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<QueryBalanceVO>>('/pay/balance' as const).body(args)
  )

  // 提现申请  |  前提要绑卡,绑定通商云手机号,完成通商云实名
  payWithdraw = $api.action((args: WithdrawApplyDTO) =>
    this.post<ApiResponse<WithdrawApplyVO>>('/pay/withdraw' as const).body(args)
  )

  // 支付确认[快捷支付,余额支付,提现]
  payConfirm = $api.action((args: PayDTO) => this.post<ApiResponse<PayVO>>('/pay/confirm' as const).body(args))
}

export const payModel = new Pay()
