import React, {useCallback, useEffect, useState} from 'react'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import {Badge, Button, Descriptions, Form, message, Row, Space, Table, Tabs} from 'antd'
import nygDeviceStyle from '@/pages/nygDevice/device.module.less'
import DeviceTableHeader from '@/pages/device/components/DeviceTableHeader'
import NygDeviceTimeForm from '@/pages/nygDevice/components/NygDeviceTimeForm'
import NygDeviceWaterElectric from '@/pages/nygDevice/components/NygDeviceWaterElectric'
import DeviceYunDing from '@/pages/device/components/DeviceYunDing'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {useAppLocation} from '@/common/hooks/useRouter'
import {deviceModel} from '@/store/models/Device'
import {downloadBlob} from '@/utils/util'

export default function NygDeviceWaterDetail() {
  const location = useAppLocation<'/nyg-device-water/detail'>()
  const deviceId = location.state?.deviceId

  const [waterInfo, setWaterInfo] = useState({} as WaterMeter)
  const [waterMeterLoading, setWaterMeterLoading] = useState(false)

  useEffect(() => {
    deviceModel.deviceV2WaterMeterInfo({waterMeterId: deviceId}).then(({response: {data}}) => setWaterInfo(data))
  }, [deviceId])

  const [form] = Form.useForm()
  const [total, setTotal] = useState(0)
  const [recordList, setRecordList] = useState<DeviceV2MeterRecordVO[]>([])

  const queryWaterMeterRecord = useCallback(() => {
    const formValues = form.getFieldsValue(true)
    deviceModel.deviceV2WaterMeterRecord({deviceId, ...formValues}).then(({response: {data, total}}) => {
      setTotal(total)
      setRecordList(data)
    })
  }, [deviceId, form])

  useEffect(() => {
    queryWaterMeterRecord()
  }, [queryWaterMeterRecord])

  const recordLoading = deviceModel.deviceV2WaterMeterRecord.useLoading()
  const readLoading = deviceModel.deviceV2WaterMeterRead.useLoading()

  return (
    <div>
      <Breadcrumb path={['首页', '智能设备', '水表']} />
      <div className={nygDeviceStyle.deviceSection}>
        <Row>
          <DeviceYunDing />
          <Descriptions
            title={'牛油果智能水表'}
            column={3}
            labelStyle={{color: 'rgba(0, 0, 0, 0.45)'}}
            style={{margin: '0 50px 0 25px', width: '70%'}}
          >
            <Descriptions.Item
              label={
                <Space size={0}>
                  <Badge status={waterInfo.status === 0 ? 'warning' : 'success'} />
                  水表状态
                </Space>
              }
            >
              {waterInfo.status === 0 ? '离线' : '在线'}
            </Descriptions.Item>
            <Descriptions.Item label={'水费价格'}>{waterInfo.waterPrice}元/吨</Descriptions.Item>
            <Descriptions.Item label={'水电余额'}>{waterInfo.balance}</Descriptions.Item>
            <Descriptions.Item label={'承租人'}>{waterInfo.lesseeName ?? '/'}</Descriptions.Item>
            <Descriptions.Item label={'手机号'}>{waterInfo.lesseeMobile ?? '/'}</Descriptions.Item>
            <Descriptions.Item label={'房源地址'}>{waterInfo.propertyTitle}</Descriptions.Item>
          </Descriptions>
        </Row>
        <Row justify={'end'} style={{marginTop: '20px'}}>
          <Button
            loading={readLoading}
            onClick={async () => {
              await deviceModel.deviceV2WaterMeterRead({waterMeterId: deviceId})
              message.success('操作成功！')
              queryWaterMeterRecord()
            }}
          >
            更新抄表
          </Button>
        </Row>
      </div>
      <Tabs tabBarStyle={{margin: 0, backgroundColor: 'white', paddingLeft: '20px'}}>
        <Tabs.TabPane tab={'用水记录'} key={'1'}>
          <NygDeviceTimeForm
            form={form}
            label={'抄表时间'}
            onFinish={queryWaterMeterRecord}
            onReset={queryWaterMeterRecord}
            onExport={() => {
              const formValues = form.getFieldsValue(true)
              setWaterMeterLoading(true)
              downloadBlob({
                url: '/device/v2/water-meter/record-excel',
                body: {...formValues, pageable: false, deviceId},
              }).finally(() => {
                setWaterMeterLoading(false)
              })
            }}
            exportLoading={waterMeterLoading}
          />
          <div className={nygDeviceStyle.tableSection}>
            <DeviceTableHeader total={total} />
            <Table
              rowKey={'recordId'}
              columns={[
                {title: '时间', dataIndex: 'insertTime'},
                {title: '表读数', dataIndex: 'dataNew'},
                {title: '当次用水量（吨）', dataIndex: 'dataDiff'},
                {title: '抄表类型', dataIndex: 'operateDesc'},
                {title: '抄表人', dataIndex: 'operator'},
              ]}
              dataSource={recordList}
              loading={recordLoading}
              pagination={getPaginationProps(form, total)}
            />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab={'水电余额记录'} key={'2'}>
          <NygDeviceWaterElectric propertyCode={waterInfo.propertyCode} propertyTitle={waterInfo.propertyTitle} />
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}
