import React, {useEffect, useMemo, useState} from 'react'
import {Button, Col, DatePicker, Form, FormInstance, Input, InputNumber, Modal, Row, Select, Switch, Table} from 'antd'
import {contractModel} from '@/store/models/Contract'
import {CloseCircleFilled, PlusOutlined} from '@ant-design/icons'
import styles from './index.module.less'
import moment from 'moment'
import {add} from '@/utils/amount'
import {useCodeArraySync} from '@/store/models/Common'
import {propertyModel} from '@/store/models/Property'

interface CheckOutProps {
  contractVO?: ContractVO
  onCancel?: () => void
  onSuccess?: () => void
}

interface CheckOutAgainDetailDTO extends ContractCheckOutAgainDetailDTO {
  _key: string
  _billBegin: moment.Moment[]
}

interface FormType extends ContractCheckOutAgainDTO {
  cutList: CheckOutAgainDetailDTO[]
  refundList: CheckOutAgainDetailDTO[]
}

// 临时数据的ID
const genTempId = () => `_temp_${Math.random()}`
// 列表数据的ID
const getListId = () => `${Math.random()}`
// 判断是否是临时ID
const isTempId = (id: string) => id.startsWith('_temp_')
// 格式化Moment类型
const getBillData = (value: Pick<ContractCheckOutDetailRecordVO, 'billBegin' | 'billEnd'>) =>
  value.billBegin && value.billEnd ? [moment(value.billBegin), moment(value.billEnd)] : undefined

// 统计总额
const checkoutTotal = (dto: CheckOutAgainDetailDTO[] = [], field = 'checkoutAmt') =>
  dto.reduce((prev, item) => add(prev, item?.[field]), 0)
// 统计扣款总额
const checkoutCutTotal = (f: FormInstance<any>) =>
  checkoutTotal(f.getFieldValue('cutList')?.filter(value => value.billFee !== '1'))
// 统计退款总额
const checkoutRefundTotal = (f: FormInstance<any>) => checkoutTotal(f.getFieldValue('refundList'))

export const CheckOut: React.FC<CheckOutProps> = props => {
  const [form] = Form.useForm()
  const _checkoutType = useCodeArraySync('contract.checkoutType')
  const checkoutType = useMemo(() => _checkoutType.filter(value => !['1', '2'].includes(value.id)), [_checkoutType])

  const contractId = props.contractVO?.contractId
  const communityCode = props.contractVO?.communityCode

  const visible = !!contractId

  useEffect(() => {
    if (contractId) {
      contractModel.contractQueryContract({contractId}).then(({response: {data}}) => {
        const checkoutDetailList: CheckOutAgainDetailDTO[] =
          data?.checkOutDetail?.checkoutDetailList?.map(value => ({
            ...value,
            _key: getListId(),
            _billBegin: getBillData(value), // 回显费用周期
          })) || []
        form.setFieldsValue({
          contractId,
          cutList: checkoutDetailList.filter(value => value.detailType === '1'),
          refundList: checkoutDetailList.filter(value => value.detailType === '2'),
        } as FormType)

        if (!data.checkOutType) {
          contractModel.contractCheckOutAgainPre({contractId})
        }
      })
    }
  }, [form, contractId])

  const [lessorConfig, setLessorConfig] = useState<LessorConfigVO>({billConfigList: [], payConfigList: []})

  useEffect(() => {
    if (communityCode) {
      propertyModel.projectConfig({condoId: communityCode}).then(({response: {data}}) => setLessorConfig(data))
    }
  }, [communityCode])

  function addRow(
    listName: keyof Pick<FormType, 'cutList' | 'refundList'>,
    detailType: CheckOutAgainDetailDTO['detailType']
  ) {
    const list = form.getFieldValue(listName) ?? []
    form.setFieldsValue({
      [listName]: [...list, {_key: genTempId(), detailType} as CheckOutAgainDetailDTO],
    })
  }

  const loading = contractModel.contractCheckOutAgain.useLoading()

  async function submit() {
    await form.validateFields()
    const store: FormType = form.getFieldsValue(true)
    store.detailList = [...store.cutList, ...store.refundList]
    store.cutAmt = checkoutCutTotal(form)
    store.refundAmt = checkoutRefundTotal(form)
    store.totalAmt = add(store.refundAmt, -store.cutAmt)
    await contractModel.contractCheckOutAgain(store)
    props.onSuccess?.()
  }

  return (
    <Modal
      title={'退房确认'}
      width={900}
      afterClose={() => form.resetFields()}
      visible={visible}
      onCancel={props.onCancel}
      className={styles.model}
      footer={
        <Button type={'primary'} loading={loading} onClick={submit}>
          确认结账
        </Button>
      }
    >
      <Form className={styles.form} form={form} initialValues={{cutList: [], refundList: []}}>
        <Form.Item name={'checkOutType'} label={'退房类型'} rules={[{required: true, message: '请选择退房类型'}]}>
          <Select placeholder={'请选择'} style={{width: 200}}>
            {checkoutType.map(value => (
              <Select.Option key={value.id} value={value.id}>
                {value.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(prevValues, nextValues) => prevValues.cutList !== nextValues.cutList}>
          {f => (
            <Table
              className={styles.table}
              rowKey={'_key'} // 自定义的 key
              dataSource={f.getFieldValue('cutList') as FormType['cutList']}
              columns={[
                {
                  title: '扣款费用类型',
                  width: 150,
                  dataIndex: 'billTypeCode',
                  render: (text, record, index) =>
                    isTempId(record._key) ? (
                      <Form.Item
                        name={['cutList', index, 'billTypeCode']}
                        style={{marginBottom: 0}}
                        rules={[{required: true, message: '请选择扣款项目'}]}
                      >
                        <Select
                          placeholder={'请选择'}
                          allowClear
                          onChange={(value, option: any) => {
                            const data: LessorConfigVO['billConfigList'][number] = option?.data
                            f.setFields([
                              {name: ['cutList', index, 'billTypeName'], value: data?.billTypeName},
                              {name: ['cutList', index, 'billCategory'], value: data?.billCategory},
                              {name: ['cutList', index, 'billCategoryName'], value: data?.billCategoryName},
                            ])
                          }}
                        >
                          {lessorConfig?.billConfigList?.map(value => (
                            <Select.Option key={value.billTypeCode} value={value.billTypeCode} data={value}>
                              {value.billTypeName}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    ) : (
                      record.billTypeName
                    ),
                },
                {
                  title: '费用周期',
                  width: 280,
                  render: (text, record, index) =>
                    isTempId(record._key) ? (
                      <Form.Item
                        name={['cutList', index, '_billBegin']}
                        style={{marginBottom: 0}}
                        rules={[{required: true, message: '请选择费用周期'}]}
                      >
                        <DatePicker.RangePicker
                          onChange={(value, format) => {
                            f.setFields([
                              {name: ['cutList', index, 'billBegin'], value: format?.[0]},
                              {name: ['cutList', index, 'billEnd'], value: format?.[1]},
                            ])
                          }}
                        />
                      </Form.Item>
                    ) : record.billBegin && record.billEnd ? (
                      [record.billBegin, record.billEnd].join(' 至 ')
                    ) : (
                      '无账期'
                    ),
                },
                {
                  title: '合计（元）',
                  render: (_, record, index) =>
                    isTempId(record._key) ? (
                      <Form.Item
                        name={['cutList', index, 'checkoutAmt']}
                        style={{marginBottom: 0}}
                        rules={[{required: true, message: '请输入扣款金额'}]}
                      >
                        <InputNumber
                          min={0}
                          step={100}
                          placeholder={'扣款金额'}
                          precision={2}
                          style={{border: 'none'}}
                          onChange={value => form.setFields([{name: ['cutList', index, 'billAmtTotal'], value: value}])}
                        />
                      </Form.Item>
                    ) : (
                      record.checkoutAmt
                    ),
                },
                {
                  title: '操作',
                  width: 150,
                  render: (_, record, index) =>
                    isTempId(record._key) ? (
                      <div className={styles.handle}>
                        /
                        <CloseCircleFilled
                          className={styles.close}
                          onClick={() => {
                            const list: FormType['cutList'] = form.getFieldValue('cutList')
                            form.setFields([{name: 'cutList', value: list.filter(value => value !== record)}])
                          }}
                        />
                      </div>
                    ) : (
                      <Form.Item
                        label={'优惠免收'}
                        colon={false}
                        name={['cutList', index, 'billFee']}
                        style={{marginBottom: 0}}
                        // 组件 -> form
                        normalize={value => (value ? '1' : '0')}
                        // form -> 组件
                        getValueProps={value => ({checked: value === '1'})}
                      >
                        <Switch />
                      </Form.Item>
                    ),
                },
              ]}
              pagination={false}
            />
          )}
        </Form.Item>
        <Row>
          <Col flex={'auto'}>
            <Form.Item label='扣除租客金额' shouldUpdate>
              {() => `￥${checkoutCutTotal(form)}`}
            </Form.Item>
          </Col>
          <Col>
            <Button
              type={'link'}
              icon={<PlusOutlined />}
              style={{paddingRight: 0}}
              onClick={() => addRow('cutList', '1')}
            >
              添加扣款项目
            </Button>
          </Col>
        </Row>

        <Form.Item noStyle shouldUpdate={(prevValues, nextValues) => prevValues.refundList !== nextValues.refundList}>
          {f => (
            <Table
              className={styles.table}
              rowKey={'_key'}
              dataSource={f.getFieldValue('refundList') as FormType['refundList']}
              columns={[
                {
                  title: '退款费用类型',
                  width: 150,
                  render: (_, record, index) =>
                    isTempId(record._key) ? (
                      <Form.Item
                        name={['refundList', index, 'billTypeCode']}
                        style={{marginBottom: 0}}
                        rules={[{required: true, message: '请选择退款费用类型'}]}
                      >
                        <Select
                          placeholder={'请选择'}
                          allowClear
                          onChange={(value, option: any) => {
                            const data: LessorConfigVO['billConfigList'][number] = option?.data
                            f.setFields([
                              {name: ['refundList', index, 'billTypeName'], value: data?.billTypeName},
                              {name: ['refundList', index, 'billCategory'], value: data?.billCategory},
                              {name: ['refundList', index, 'billCategoryName'], value: data?.billCategoryName},
                            ])
                          }}
                        >
                          {lessorConfig?.billConfigList?.map(value => (
                            <Select.Option key={value.billTypeCode} value={value.billTypeCode} data={value}>
                              {value.billTypeName}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    ) : (
                      record.billTypeName
                    ),
                },
                {
                  title: '费用周期',
                  width: 280,
                  render: (_, record, index) =>
                    isTempId(record._key) ? (
                      <Form.Item
                        name={['refundList', index, '_billBegin']}
                        style={{marginBottom: 0}}
                        rules={[{required: true, message: '请选择费用周期'}]}
                      >
                        <DatePicker.RangePicker
                          onChange={(value, format) => {
                            f.setFields([
                              {name: ['refundList', index, 'billBegin'], value: format?.[0]},
                              {name: ['refundList', index, 'billEnd'], value: format?.[1]},
                            ])
                          }}
                        />
                      </Form.Item>
                    ) : record.billBegin && record.billEnd ? (
                      [record.billBegin, record.billEnd].join(' 至 ')
                    ) : (
                      '无账期'
                    ),
                },
                {
                  title: '已收（元）',
                  render: (_, record, index) =>
                    isTempId(record._key) ? (
                      <Form.Item
                        name={['refundList', index, 'billAmtTotal']}
                        style={{marginBottom: 0}}
                        rules={[{required: true, message: '请输入已收金额'}]}
                      >
                        <InputNumber
                          min={0}
                          step={100}
                          placeholder={'已收金额'}
                          precision={2}
                          style={{border: 'none'}}
                        />
                      </Form.Item>
                    ) : (
                      record.billAmtTotal
                    ),
                },
                {
                  title: '退还（元）',
                  render: (_, record, index) => (
                    <Form.Item
                      name={['refundList', index, 'checkoutAmt']}
                      style={{marginBottom: 0}}
                      rules={[{required: true, message: '请输入退还金额'}]}
                    >
                      <InputNumber min={0} step={100} placeholder={'退还金额'} precision={2} style={{border: 'none'}} />
                    </Form.Item>
                  ),
                },
                {
                  title: '',
                  render: (_, record, index) =>
                    isTempId(record._key) ? (
                      <div className={styles.handle}>
                        <CloseCircleFilled
                          className={styles.close}
                          onClick={() => {
                            const list: FormType['refundList'] = form.getFieldValue('refundList')
                            form.setFields([{name: 'refundList', value: list.filter(value => value !== record)}])
                          }}
                        />
                      </div>
                    ) : null,
                },
              ]}
              pagination={false}
            />
          )}
        </Form.Item>

        <Row>
          <Col flex={'auto'}>
            <Form.Item label='应退租客金额' shouldUpdate>
              {() => `￥${checkoutRefundTotal(form)}`}
            </Form.Item>
          </Col>
          <Col>
            <Button
              type={'link'}
              icon={<PlusOutlined />}
              style={{paddingRight: 0}}
              onClick={() => addRow('refundList', '2')}
            >
              添加退款项目
            </Button>
          </Col>
        </Row>

        <Row>
          <Col span={14}>
            <Form.Item label={'总计'} shouldUpdate>
              {() => {
                const refundAmt = checkoutRefundTotal(form)
                const cutAmt = checkoutCutTotal(form)
                const totalAmt = add(refundAmt, -cutAmt)
                return `￥${totalAmt}${
                  totalAmt > 0 ? `（退还给租客${totalAmt}元）` : `（向租客收取${Math.abs(totalAmt)}元）`
                }`
              }}
            </Form.Item>
          </Col>
          <Form.Item noStyle shouldUpdate>
            {() => {
              const refundAmt = checkoutRefundTotal(form)
              const cutAmt = checkoutCutTotal(form)
              const totalAmt = add(refundAmt, -cutAmt)

              return totalAmt > 0 ? (
                <>
                  <Col span={10}>
                    <Form.Item
                      name={'rfdPayWayCode'}
                      label={'退款途径'}
                      rules={[{required: true, message: '请选择退款途径'}]}
                    >
                      <Select
                        allowClear
                        placeholder={'请选择退款途径'}
                        onChange={(value, option) => form.setFieldsValue({rfdPayWayName: option?.['children']})}
                      >
                        {lessorConfig?.payConfigList?.map(value => (
                          <Select.Option value={value.payWay} key={value.payWay}>
                            {value.payWayName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item name={'rfdRemark'} label={'备注信息'}>
                      <Input.TextArea placeholder={'请输入退款备注'} />
                    </Form.Item>
                  </Col>
                </>
              ) : null
            }}
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  )
}
