import {Model} from '@redux-model/web'
import {$api} from '@/store/service'

export interface CleanModel {}

class Clean extends Model<CleanModel> {
  protected initReducer(): CleanModel {
    return {}
  }

  // 服务单列表
  cleanOrderList = $api.action((args: CleanOrderQueryDTO) =>
    this.post<ApiResponse<CleanOrderVO[]>>('/clean-order/list').body(args)
  )

  // 详情  |  object:{orderId:服务单ID}
  cleanOrderGet = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<CleanOrderVO>>('/clean-order/get').body(args)
  )

  // 服务单total
  cleanOrderCount = $api.action((args: CleanOrderQueryDTO) =>
    this.post<ApiResponse<number>>('/clean-order/count').body(args)
  )

  // 评价
  cleanOrderRemark = $api.action((args: CleanOrderRemarkDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/clean-order/remark').body(args)
  )
}

export const cleanModel = new Clean()
