import React, {useCallback, useEffect, useState} from 'react'
import {Button, Col, Divider, Form, Input, Modal, Radio, Row, Select, Space, Table, Tabs, Typography} from 'antd'
import {propertyModel} from '@/store/models/Property'
import styles from '@/pages/property/Chart/index.module.less'
import {rules} from '@/utils/validate'
import {getImg} from '@/utils/util'
import ImgEmpty from '@/assets/imgs/img_empty.svg'
import {PropertyConfigList} from '@/pages/property/components/PropertyConfigList'
import {Cost} from '@/pages/property/components/Cost'
import {Album} from '@/ui/components/Album'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {configModel} from '@/store/models/Config'
import {warehouseModel} from '@/store/models/Warehouse'
import {renderChargeItem} from '@/utils/render'

const {Title} = Typography
const {Option} = Select

const options = [
  {label: '未租', value: '0'},
  {label: '已租', value: '1'},
]
// 电梯
const lift = [
  {label: '是', value: '1'},
  {label: '否', value: '0'},
]

interface PropertyEditProps {
  // property 可以为 null
  property: Pick<PropertyInfoCardVO, 'propertyCode' | 'communityCode'>
  onCancel?: () => void
  onConfirm?: () => void
}

export default function PropertyEdit({property, onConfirm, onCancel}: PropertyEditProps) {
  const propertyCode = property?.propertyCode
  const communityCode = property?.communityCode

  const orientation = useCodeArraySync('property.orientation') // 朝向
  const rentCollectType = useCodeArraySync('rent.collect.type')
  const occupancyStatus = useCodeArraySync('occupancy.status')
  const occupancyStatusObj = useCodeSync('occupancy.status')
  const propertyType = useCodeArraySync('property.type') // 房屋类型

  const [formRoom] = Form.useForm()
  const [tabKey, setTabKey] = useState('1')

  const [materialList, setMaterialList] = useState<LessorWarehouseMaterialVO[]>([])

  // 是否需要显示房源编号
  const isChecked = configModel.useCheckedStatus()

  const queryPropertyDetail = useCallback(
    async (propertyCode: string) => {
      const {
        response: {data: detail},
      } = await propertyModel.propertyDetail({propertyCode})

      let templateList = []
      if (detail.rentType === '3' && !!detail.templateId) {
        const {
          response: {data: _templateList},
        } = await propertyModel.templateList(detail.condoId)
        templateList = _templateList
      }
      formRoom.setFieldsValue({...detail, templateList})

      const {
        response: {data: _materialList},
      } = await warehouseModel.lessorWarehouseMaterialList({warehouseId: propertyCode, warehouseType: '2'})
      setMaterialList(_materialList)
    },
    [formRoom]
  )

  useEffect(
    function () {
      if (propertyCode) {
        queryPropertyDetail(propertyCode)
      }
    },
    [propertyCode, queryPropertyDetail]
  )

  const [billList, setBillList] = useState([])
  useEffect(() => {
    if (communityCode) {
      propertyModel.projectConfig({condoId: communityCode}).then(({response: {data}}) => {
        setBillList(renderChargeItem(data?.billConfigList))
      })
    }
  }, [communityCode])

  return (
    <Modal
      visible={!!propertyCode}
      afterClose={() => {
        formRoom.resetFields()
        setTabKey('1')
      }}
      destroyOnClose
      width={974}
      footer={null}
      onCancel={onCancel}
      bodyStyle={{padding: 0}}
    >
      <Form
        form={formRoom}
        initialValues={{occupancyStatus: '1'}}
        onFinish={() => propertyModel.propertyUpdate(formRoom.getFieldsValue(true)).then(() => onConfirm?.())}
        labelCol={{span: 6}}
      >
        <Form.Item noStyle>
          <Title style={{textAlign: 'center', lineHeight: '56px'}} level={3}>
            {formRoom.getFieldValue('title')}
          </Title>
        </Form.Item>
        <Tabs tabPosition={'left'} activeKey={tabKey} onChange={setTabKey}>
          <Tabs.TabPane tab='房源基本信息' key='1' className={styles.tabContainer}>
            <Row gutter={24}>
              <Col flex='1'>
                {formRoom.getFieldValue('rentType') === '3' ? (
                  <>
                    <Form.Item label={'楼栋号'} name={'buildingNo'} rules={rules.buildingNo}>
                      <Input placeholder={'请输入楼栋号'} />
                    </Form.Item>
                    <Form.Item label={'房间号'} name={'houseNo'} rules={rules.houseNo}>
                      <Input placeholder={'请输入房间号'} />
                    </Form.Item>
                    <Row>
                      <Col span={12}>
                        <Form.Item label={'所在楼层'} name={'floor'} labelCol={{span: 12}} rules={rules.floor}>
                          <Input placeholder={'请输入所在楼层'} />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label={'栋总高'} name={'floorHead'} labelCol={{span: 8}} rules={rules.floorHead}>
                          <Input placeholder={'请输入栋总高'} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item label={'房间模板'} name={'templateId'} rules={rules.required()}>
                      <Select placeholder={'请选择房间模板'}>
                        {formRoom.getFieldValue('templateList')?.map((item: CondoManageTempVO) => (
                          <Select.Option key={item.templateId} value={item.templateId}>
                            {item.templateName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item shouldUpdate={(prev, next) => prev.occupancyStatus !== next.occupancyStatus} noStyle>
                      {f => (
                        <Form.Item label={'出租状态'} name={'rentStatus'} rules={rules.rentStatus}>
                          <Radio.Group
                            disabled={f.getFieldValue('occupancyStatus') !== '1'}
                            options={options}
                            onChange={e => {
                              if (e.target.value === '1') {
                                formRoom.setFieldsValue({occupancyStatus: '1'})
                              }
                            }}
                          />
                        </Form.Item>
                      )}
                    </Form.Item>
                    <Form.Item label={'房源状态'} shouldUpdate required>
                      {f => {
                        const fieldKey = 'occupancyStatus'
                        return (
                          <Select
                            // 预定状态不可更改
                            disabled={f.getFieldValue('rentStatus') !== '0' || f.getFieldValue('reserveStatus') === '1'}
                            value={f.getFieldValue(fieldKey)}
                            onChange={value => {
                              if (['2', '3'].includes(value)) {
                                Modal.confirm({
                                  zIndex: 9999,
                                  title: '提示',
                                  content: `房源状态更改为${occupancyStatusObj[value]}后，将无法进行租客登记，是否确认修改？`,
                                  icon: null,
                                  onOk: () => formRoom.setFieldsValue({[fieldKey]: value}),
                                })
                              } else {
                                formRoom.setFieldsValue({[fieldKey]: value})
                              }
                            }}
                          >
                            {occupancyStatus.map(value => (
                              <Select.Option key={value.id} value={value.id}>
                                {value.label}
                              </Select.Option>
                            ))}
                          </Select>
                        )
                      }}
                    </Form.Item>

                    {isChecked && (
                      <Form.Item label={'自定义房源编号'} name={'propertyCodeCustom'}>
                        <Input placeholder={'请输入自定义房源编号'} />
                      </Form.Item>
                    )}
                  </>
                ) : (
                  <>
                    <Form.Item label={'楼栋号'} name={'buildingNo'} rules={rules.buildingNo}>
                      <Input placeholder={'请输入楼栋号'} />
                    </Form.Item>
                    <Form.Item label={'单元号'} name={'unitNo'}>
                      <Input placeholder={'请输入单元号'} />
                    </Form.Item>
                    <Form.Item label={'房间号'} name={'houseNo'} rules={rules.houseNo}>
                      <Input placeholder={'请输入房间号'} />
                    </Form.Item>
                    <Form.Item label={'出租状态'} name={'rentStatus'} rules={rules.rentStatus}>
                      <Radio.Group options={options} />
                    </Form.Item>
                    {formRoom.getFieldValue('rentType') === '1' ? (
                      <>
                        <Row>
                          <Col span={12}>
                            <Form.Item label={'所在楼层'} name={'floor'} labelCol={{span: 12}}>
                              <Input placeholder={'请输入所在楼层'} />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item label={'总高'} name={'floorHead'} labelCol={{span: 8}}>
                              <Input placeholder={'请输入总高'} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Form.Item label={'室/厅/厨/卫'} required>
                          <Space align={'baseline'}>
                            <Form.Item name={'bedroom'} rules={rules.bedroom}>
                              <Input placeholder={'请输入室'} />
                            </Form.Item>
                            室
                            <Form.Item name={'parlor'} rules={rules.parlor}>
                              <Input placeholder={'请输入厅'} />
                            </Form.Item>
                            厅
                            <Form.Item name={'kitchen'} rules={rules.parlor}>
                              <Input placeholder={'请输入厨'} />
                            </Form.Item>
                            厨
                            <Form.Item name={'bathroom'} rules={rules.bathroom}>
                              <Input placeholder={'请输入卫'} />
                            </Form.Item>
                            卫
                          </Space>
                        </Form.Item>
                      </>
                    ) : (
                      <Form.Item label={'房间名'} name={'roomName'}>
                        <Input placeholder={'请输入房间名'} />
                      </Form.Item>
                    )}
                    <Form.Item label={'房间面积(㎡)'} name={'floorage'}>
                      <Input placeholder={'请输入房间面积'} />
                    </Form.Item>
                    <Form.Item label={'房间朝向'} name={'orientation'}>
                      <Select placeholder={'请选择房间朝向'}>
                        {orientation.map(item => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    {isChecked && (
                      <Form.Item label={'自定义房源编号'} name={'propertyCodeCustom'}>
                        <Input placeholder={'请输入自定义房源编号'} />
                      </Form.Item>
                    )}
                    {formRoom.getFieldValue('rentType') === '2' ? (
                      <>
                        <Form.Item name={'selfKitchen'} label={'独立厨房'}>
                          <Radio.Group>
                            <Radio value={'1'}>有</Radio>
                            <Radio value={'0'}>无</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item name={'selfBathroom'} label={'独立卫生间'}>
                          <Radio.Group>
                            <Radio value={'1'}>有</Radio>
                            <Radio value={'0'}>无</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </>
                    ) : null}
                  </>
                )}
              </Col>
              <Col flex='285px'>
                <Form.Item shouldUpdate noStyle>
                  {() => {
                    const rentType = formRoom.getFieldValue('rentType')
                    // 集中式房源从（房源模板中）或者（公寓公区中）取图片
                    const condoTemplateImgVOList = formRoom.getFieldValue('condoTemplateImgVOList') ?? []
                    const condoInfo = formRoom.getFieldValue(['condoInfo', 'imgList']) ?? []
                    const condoImgList = [...condoTemplateImgVOList, ...condoInfo]
                    // 分散式房源从图片列表中取图片
                    const imgList = formRoom.getFieldValue('imgList') ?? []
                    return (
                      <>
                        {rentType === '3' ? (
                          condoImgList.filter(item => item.video === '0').length > 0 ? (
                            <img
                              src={getImg(condoImgList.filter(item => item.video === '0')[0]?.imgUrl)}
                              alt=''
                              className={styles.formImg}
                            />
                          ) : (
                            <img src={ImgEmpty} alt={'imgEmpty'} className={styles.formImg} />
                          )
                        ) : imgList.filter(item => item.video === '0').length > 0 ? (
                          <img
                            src={getImg(imgList.filter(item => item.video === '0')[0]?.imgUrl)}
                            alt=''
                            className={styles.formImg}
                          />
                        ) : (
                          <img src={ImgEmpty} alt={'imgEmpty'} className={styles.formImg} />
                        )}
                      </>
                    )
                  }}
                </Form.Item>

                <div className={styles.formAlterBtn}>
                  <Button type={'primary'} ghost onClick={() => setTabKey('4')}>
                    {formRoom.getFieldValue('rentType') === '3' ? '查看照片' : '修改照片'}
                  </Button>
                </div>
                <Title level={4} style={{marginTop: '31px'}}>
                  房间配置
                </Title>
                <Form.Item shouldUpdate>
                  {() => (
                    <PropertyConfigList
                      checkList={formRoom.getFieldValue('equipment')}
                      rentType={formRoom.getFieldValue('rentType')}
                      onChecked={checkedList => formRoom.setFieldsValue({equipment: JSON.stringify(checkedList)})}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab='租金与付款方式' key='2' className={styles.tabContainer}>
            <Row>
              <Col span={12}>
                <Form.Item label={'定金'} name={'deposit'}>
                  <Input placeholder={'请输入定金'} />
                </Form.Item>
                <Form.Item
                  name={'rentCollectType'}
                  label={'收租类型'}
                  labelCol={{span: 6}}
                  rules={rules.rentCollectType}
                >
                  <Select placeholder={'请选择收租类型'} onSelect={() => formRoom.setFieldsValue({rentCollectDay: ''})}>
                    {rentCollectType.map(value => (
                      <Option key={value.id} value={value.id}>
                        {value.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                {/*<Form.Item label={'收租日'} style={{marginBottom: 0}}>*/}
                {/*  <Space align={'baseline'}>*/}
                {/*    每期提前*/}
                {/*    <Form.Item name={'rentCollectDay'}>*/}
                {/*      <Input placeholder={'请输入收租日'} />*/}
                {/*    </Form.Item>*/}
                {/*    天*/}
                {/*  </Space>*/}
                {/*</Form.Item>*/}
                <Form.Item shouldUpdate noStyle>
                  {() =>
                    formRoom.getFieldValue('rentCollectType') === '1' && (
                      <Form.Item name={'rentCollectDay'} label={'提前天数'} rules={rules.rentCollectDay}>
                        <Select placeholder={'请选择收租日'}>
                          {Array.from(new Array(32).keys()).map(item => (
                            <Option key={item} value={item}>
                              {item}天
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )
                  }
                </Form.Item>
                <Form.Item shouldUpdate noStyle>
                  {() =>
                    formRoom.getFieldValue('rentCollectType') === '2' && (
                      <Form.Item
                        name={'rentCollectDay'}
                        label={'每月'}
                        rules={[{required: true, message: '该项不能为空'}]}
                      >
                        <Select placeholder={'请选择'}>
                          {Array.from(new Array(28).keys()).map(item => (
                            <Option key={item + 1} value={item + 1}>
                              {item + 1}日
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )
                  }
                </Form.Item>
                <Form.Item label={'付款方式'} style={{marginBottom: 0}}>
                  <Space align={'baseline'}>
                    付
                    <Form.Item name={'rentPayType'}>
                      <Input placeholder={'请输入付款方式'} />
                    </Form.Item>
                    押
                    <Form.Item name={'rentPledgeType'}>
                      <Input placeholder={'请输入付款方式'} />
                    </Form.Item>
                  </Space>
                </Form.Item>
                <Form.Item label={'租金'} name={'rentPrice'}>
                  <Input placeholder={'请输入租金'} />
                </Form.Item>
                <Form.Item label={'押金'} name={'rentPledge'}>
                  <Input placeholder={'请输入押金'} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item shouldUpdate label={'其他费用'} labelCol={{span: 4}}>
                  {() => (
                    <Cost
                      billList={billList}
                      costs={formRoom.getFieldValue('otherCharges')}
                      onCostChange={costs => formRoom.setFieldsValue({otherCharges: JSON.stringify(costs)})}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab='房源描述' key='3' className={styles.tabContainer}>
            <Row>
              <Col span={21}>
                <Form.Item label={'房源描述'} name={'desc'}>
                  <Input.TextArea placeholder={'请输入房源描述'} autoSize={{minRows: 5, maxRows: 15}} />
                </Form.Item>
              </Col>
            </Row>
          </Tabs.TabPane>

          {/*公共区域信息*/}
          {formRoom.getFieldValue('rentType') === '2' && (
            <Tabs.TabPane tab='公共区域信息' key='5' className={styles.tabContainer}>
              <Row gutter={24}>
                <Col flex='1'>
                  <Form.Item label={'室/厅/卫'} style={{marginBottom: 0}} required>
                    <Space align={'baseline'}>
                      <Form.Item name={['propertyPublic', 'bedroom']} rules={rules.bedroom}>
                        <Input placeholder={'请输入室'} />
                      </Form.Item>
                      室
                      <Form.Item name={['propertyPublic', 'parlor']} rules={rules.parlor}>
                        <Input placeholder={'请输入卫'} />
                      </Form.Item>
                      厅
                      <Form.Item name={['propertyPublic', 'bathroom']} rules={rules.bathroom}>
                        <Input placeholder={'请输入厅'} />
                      </Form.Item>
                      卫
                    </Space>
                  </Form.Item>
                  <Form.Item label={'房屋类型'} name={['propertyPublic', 'propertyType']}>
                    <Select placeholder={'请选择房屋类型'}>
                      {propertyType.map(item => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Row>
                    <Col span={12}>
                      <Form.Item label={'所在楼层'} name={['propertyPublic', 'floor']} labelCol={{span: 10}}>
                        <Input placeholder={'请输入所在楼层'} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label={'总高'} name={['propertyPublic', 'topFloor']} labelCol={{span: 10}}>
                        <Input placeholder={'请输入总高'} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item label={'电梯房'} name={['propertyPublic', 'lift']}>
                    {/*<Input />*/}
                    <Select placeholder={'请选择是否是电梯房'}>
                      {lift.map(item => (
                        <Select.Option key={item.value} value={item.value}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label={'房屋面积(m²)'} name={['propertyPublic', 'floorage']}>
                    <Input placeholder={'请输入房屋面积'} />
                  </Form.Item>
                </Col>
                <Col flex='285px'>
                  <Title level={4}>房间配置</Title>
                  <Form.Item shouldUpdate>
                    {() => (
                      <PropertyConfigList
                        checkList={formRoom.getFieldValue(['propertyPublic', 'equipment'])}
                        rentType={formRoom.getFieldValue('rentType')}
                        onChecked={checkedList =>
                          formRoom.setFields([
                            {
                              name: ['propertyPublic', 'equipment'],
                              value: JSON.stringify(checkedList),
                            },
                          ])
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Tabs.TabPane>
          )}

          <Tabs.TabPane tab='照片' key='4' className={styles.tabContainer}>
            <Form.Item shouldUpdate>
              {() => (
                <>
                  {/** 集中式公寓图片：不可编辑不可上传，上传和编辑需要在模板中操作 */}
                  {formRoom.getFieldValue('rentType') === '3' ? (
                    <Album
                      disabled
                      // data={{imageType: 'property', type: '2', video: '0'}}
                      fileList={[
                        ...((formRoom.getFieldValue('condoTemplateImgVOList') as CondoPropertyImgDTO[]).filter(
                          item => item.video === '0'
                        ) || []),
                        ...((formRoom.getFieldValue('condoInfo')?.imgList as CondoAdminImgVO[]) || []),
                      ].map(item => ({...item, uid: item.imgUrl, url: getImg(item.imgUrl)}))}
                      // onDone={fileList => {
                      //   formRoom.setFieldsValue({
                      //     condoTemplateImgVOList: fileList.map(item => ({imgId: item.imgId, imgUrl: item.imgUrl})),
                      //   })
                      // }}
                    >
                      <div
                        style={{
                          width: '104px',
                          height: '104px',
                          margin: '0 -1px',
                          cursor: 'auto',
                          backgroundColor: '#fff',
                        }}
                      />
                    </Album>
                  ) : (
                    // 分散式房源：可上传可编辑（注意：imgList中既有图片又有视频，但是编辑和上传的时候是分开的）
                    <Album
                      limit={30}
                      accept={'image/png, image/jpeg, image/jpg'}
                      data={{imageType: 'property', type: '1', video: '0'}}
                      fileList={(formRoom.getFieldValue('imgList') as PropertyAdminImgVO[])
                        ?.filter(item => item.video === '0')
                        ?.map(item => ({...item, uid: item.imgUrl, url: getImg(item.imgUrl)}))}
                      onDone={fileList => {
                        const imgList = (formRoom.getFieldValue('imgList') as PropertyAdminImgVO[]) || []
                        // console.log('fileListfileList', fileList)
                        // console.log('imgListimgList', imgList)
                        formRoom.setFieldsValue({
                          imgList: [
                            ...fileList.map(item => ({video: '0', imgId: item.imgId, imgUrl: item.imgUrl})),
                            ...imgList.filter(item => item.video === '1'),
                          ],
                        })
                      }}
                    />
                  )}
                  {/*合租才有公共区域*/}
                  {formRoom.getFieldValue('rentType') === '2' && (
                    <>
                      <Divider />
                      <Title level={4}>公共区域图片</Title>
                      <Album
                        accept={'image/png, image/jpeg, image/jpg'}
                        data={{imageType: 'public', type: '1', video: '0'}}
                        fileList={(formRoom.getFieldValue('propertyPublic')?.imgList as PropertyAdminPublicImgVO[]).map(
                          item => ({
                            ...item,
                            uid: item.imgId,
                            url: getImg(item.imgUrl),
                          })
                        )}
                        onDone={fileList => {
                          formRoom.setFields([
                            {
                              name: ['propertyPublic', 'imgList'],
                              value: fileList.map(item => ({imgId: item.imgId, imgUrl: item.imgUrl})),
                            },
                          ])
                        }}
                      />
                    </>
                  )}
                  {/* 视频 */}
                  <>
                    <Divider />
                    <Title level={4}>视频</Title>
                    {/*集中式公寓视频：不可上传不可编辑，在模板中上传和编辑*/}
                    {formRoom.getFieldValue('rentType') === '3' ? (
                      <Album
                        disabled
                        iconRender={() => (
                          <div
                            style={{display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'center'}}
                          >
                            <video
                              src={getImg(
                                (formRoom.getFieldValue('condoTemplateImgVOList') as CondoPropertyImgDTO[]).filter(
                                  item => item.video === '1'
                                )[0]?.imgUrl
                              )}
                            />
                          </div>
                        )}
                        fileList={(formRoom.getFieldValue('condoTemplateImgVOList') as CondoPropertyImgDTO[])
                          ?.filter(item => item.video === '1')
                          ?.map(item => ({...item, uid: item.imgId, imgId: item.imgId, url: getImg(item.imgUrl)}))}
                        title={'上传视频'}
                      >
                        <div
                          style={{
                            width: '104px',
                            height: '104px',
                            margin: '0 -1px',
                            cursor: 'auto',
                            backgroundColor: '#fff',
                          }}
                        />
                      </Album>
                    ) : (
                      // 分散式房源：上传和编辑视频
                      <Album
                        limit={1}
                        accept={'video/mp4'}
                        data={{imageType: 'property', type: '1', video: '1'}}
                        iconRender={() => (
                          <div
                            style={{display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'center'}}
                          >
                            <video
                              src={getImg(
                                (formRoom.getFieldValue('imgList') as PropertyAdminImgVO[]).filter(
                                  item => item.video === '1'
                                )[0]?.imgUrl
                              )}
                            />
                          </div>
                        )}
                        fileList={(formRoom.getFieldValue('imgList') as PropertyAdminImgVO[])
                          ?.filter(item => item.video === '1')
                          ?.map(item => ({...item, uid: item.imgId, imgId: item.imgId, url: getImg(item.imgUrl)}))}
                        onDone={fileList => {
                          // 查出来所有的图片
                          const imgList =
                            (formRoom.getFieldValue('imgList') as PropertyAdminImgVO[])?.filter(
                              item => item.video === '0'
                            ) || []
                          if (fileList.length) {
                            formRoom.setFieldsValue({
                              imgList: [
                                ...imgList,
                                ...fileList.map(item => ({imgId: item.imgId, imgUrl: item.imgUrl, video: '1'})),
                              ],
                            })
                          } else {
                            // 删除
                            formRoom.setFieldsValue({imgList})
                          }
                        }}
                        title={'上传视频'}
                        errorMsg={'只能上传1个视频'}
                      />
                    )}
                  </>
                </>
              )}
            </Form.Item>
          </Tabs.TabPane>

          <Tabs.TabPane tab={'物品信息'} key={'6'} className={styles.tabContainer}>
            <Table
              rowKey={(_, index) => index}
              pagination={false}
              dataSource={materialList}
              columns={[
                {title: '物品设备名称', dataIndex: 'name', key: 'name'},
                {title: '品牌', dataIndex: 'brand', key: 'brand'},
                {title: '型号', dataIndex: 'specification', key: 'specification'},
                {title: '单价（元）', dataIndex: 'price', key: 'price'},
                {title: '数量', dataIndex: 'num', key: 'num'},
                {title: '配置日期', dataIndex: 'updateTime', key: 'updateTime'},
              ]}
            />
          </Tabs.TabPane>
        </Tabs>
        {tabKey !== '6' && (
          <div className={styles.formSaveBtn}>
            {tabKey === '4' && formRoom.getFieldValue('rentType') !== '3' ? (
              <Button
                type={'primary'}
                onClick={async () => {
                  // if (formRoom.getFieldValue('rentType') === '3') {
                  //   const imgParams = formRoom
                  //     .getFieldValue('condoTemplateImgVOList')
                  //     ?.map(item => ({...item, templateId: formRoom.getFieldValue('templateId')}))
                  //   await propertyModel.condoPropertyImgUpdate(imgParams)
                  // } else {
                  //   const imgParams = {
                  //     propertyCode: formRoom.getFieldValue('propertyCode'),
                  //     propertyImgList: formRoom.getFieldValue('imgList') ?? [],
                  //     propertyPublicImgList: formRoom.getFieldValue('propertyPublic')?.imgList ?? [],
                  //     rentType: formRoom.getFieldValue('rentType'),
                  //   }
                  //   await propertyModel.scatterPropertyImgUpload(imgParams)
                  // }
                  const imgParams = {
                    propertyCode: formRoom.getFieldValue('propertyCode'),
                    propertyImgList: formRoom.getFieldValue('imgList') ?? [],
                    propertyPublicImgList: formRoom.getFieldValue('propertyPublic')?.imgList ?? [],
                    rentType: formRoom.getFieldValue('rentType'),
                  }
                  await propertyModel.scatterPropertyImgUpload(imgParams)
                  onConfirm?.()
                }}
              >
                保存并提交
              </Button>
            ) : (
              <Button type={'primary'} htmlType={'submit'}>
                保存并提交
              </Button>
            )}
          </div>
        )}
      </Form>
    </Modal>
  )
}
