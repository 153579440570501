import React, {useEffect, useRef, useState} from 'react'
import {Button, Col, Dropdown, Menu, Row, Skeleton, Space, Statistic, Table, Tabs, Typography, Modal} from 'antd'
import styles from './index.module.less'
import {useLocation} from 'react-router-dom'
import {contractModel} from '@/store/models/Contract'
import {renderProperty} from '@/utils/render'
import {useCodeSync} from '@/store/models/Common'
import dayjs from 'dayjs'
import {add} from '@/utils/amount'
import BillDetailAction from '@/pages/financial/components/BillDetail'
import ConfirmCollectionAction from '@/pages/financial/components/ConfirmCollection'
import {DownOutlined} from '@ant-design/icons'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import {Album} from '@/ui/components/Album'
import {createSplit, getImg} from '@/utils/util'
import {hasPermission, Permission, usePermission} from '@/ui/components/Permission'
import Cancellation from '../components/Cancellation'

const {TabPane} = Tabs
const {Text} = Typography
interface ListDTO {
  title: string
  label: string | number
}

// interface List {
//   billId: string
//   category: string
//   billAmtTotal: number
//   billAmtPaid: number
//   children: object[]
// }

interface LessorBill extends ContractLessorBillVO {
  lessorDetailObj: any
}

interface LessorBillDetail {
  [key: string]: LessorBill
}

interface ContractLessorBillDetail extends ContractLessorBillDetailVO {
  category: string
}

interface GroupList {
  category: string
  billTime?: string
  billStatus?: string
  billAmtTotal?: number
  billAmtPaid?: number
  list: ContractLessorBillVO[]
}

function getBillTime(item: Pick<ContractBillListVO, 'billBegin' | 'billEnd'>) {
  return [item.billBegin, item.billEnd].filter(item => item).join('至')
}

function groupList(
  list: ContractLessorBillVO[],
  {
    renderHeader,
    groupBy,
  }: {
    renderHeader: (item: ContractLessorBillVO, index: number) => string
    groupBy: (item: ContractLessorBillVO) => string
  }
) {
  const res = list.reduce<{
    keys: string[]
    data: {[key: string]: GroupList}
  }>(
    (prev, item) => {
      const key = groupBy(item)
      if (!prev.keys.includes(key)) {
        prev.keys.push(key)
        prev.data[key] = {
          category: renderHeader(item, prev.keys.length),
          billTime: getBillTime(item),
          billAmtTotal: 0,
          billAmtPaid: 0,
          billStatus: '已完成',
          list: [],
        }
      }
      if (item.billStatus === '1') {
        prev.data[key].billStatus = ''
      }
      if (!['3', '6'].includes(item.billStatus)) {
        prev.data[key].billAmtTotal = add(prev.data[key].billAmtTotal, item.billAmtTotal)
        prev.data[key].billAmtPaid = add(prev.data[key].billAmtPaid, item.billAmtPaid)
      }
      prev.data[key].list.push(item)

      return prev
    },
    {
      keys: [] as string[],
      data: {} as {
        [key: string]: GroupList
      },
    }
  )
  return res.keys.map(value => res.data[value])
}

export default function OwnerContract() {
  const [auth] = usePermission()
  // const histroy = useHistory()
  const location = useLocation<any>()
  const contractId = location.state?.contractId
  const [lessorDetail, setLessorDetail] = useState({} as ContractLessorVO)
  const [propertyInfo, setPropertyInfo] = useState({} as PropertyAllInfoVO)
  const [billList, setBillList] = useState<GroupList[]>([])
  const [chargeList, setChargeList] = useState<ContractLessorBillVO[]>([])
  // const [billListCount, setBillListCount] = useState({} as ContractBillListCountVO)
  const [imgList, setImgList] = useState([] as ImgVO[])
  // const [billDetail, setBillDetail] = useState({} as LessorBillDetail)
  // const lessorBillDetailLoading = contractModel.contractLessorBillDetail.useLoading()
  const loading = contractModel.contractLessorDetail.useLoading()
  // 账单详情弹窗ref
  const billDetailRef = useRef(null)
  // 确认收款弹窗ref
  const confirmCollectionRef = useRef(null)
  //账单详情弹框
  const [visible, setVisible] = useState(false)
  //作废账单弹框Ref
  const cancellationRef = useRef(null)
  const [lessorBillDetail, setLessorBillDetail] = useState({} as ContractLessorBillDetail)
  useEffect(() => {
    queryLessorBillDetail(contractId)
  }, [contractId])

  function queryLessorBillDetail(contractId: string) {
    contractModel.contractLessorDetail({contractId, needBillDetail: true}).then(({response: {data}}) => {
      setLessorDetail(data)
      setPropertyInfo(data.propertyInfo)
      setImgList(data.imgList)
      // const list: List[] = []

      const split = createSplit<ContractLessorBillVO>(
        item => item.billCategory === 'b.b', // 押金
        item => item.billCategory !== 'b.b' && item.initBill !== '1', // 记账
        item => !(item.billCategory === 'b.b') && !(item.billCategory !== 'b.b' && item.initBill !== '1') // 周期
      )

      const [pledge, _charge, _period] = split(data.billList)
      const [charge, period] = _charge.reduce(
        (prev, item) => {
          if (prev[1].some(periodItem => getBillTime(periodItem) === getBillTime(item))) {
            prev[1].push(item)
          } else {
            prev[0].push(item)
          }

          return prev
        },
        [[], _period]
      )

      const countPledge = pledge.filter(item => !['3', '6'].includes(item.billStatus))
      const pledgeList: GroupList[] = pledge.length
        ? [
            {
              category: '押金',
              // billTime: '',
              billAmtTotal: countPledge.reduce((prev, item) => add(prev, item.billAmtTotal), 0),
              billAmtPaid: countPledge.reduce((prev, item) => add(prev, item.billAmtPaid), 0),
              billStatus: countPledge.every(item => item.billStatus !== '1') ? '已完成' : '',
              list: pledge,
            },
          ]
        : []
      const periodList = groupList(period, {
        groupBy: item => getBillTime(item),
        renderHeader: (item, i) => `第${i}期`,
      })

      setBillList([...pledgeList, ...periodList])
      setChargeList(charge)

      // if (data.billList.length) {
      //   // 押金
      //   const pledgeList = data.billList.filter(item => item.billCategory === 'b.b')
      //   list.push({
      //     billId: Math.random().toString(36).substr(2),
      //     category: '押金',
      //     billAmtTotal: pledgeList
      //       .filter(item => !['3', '6'].includes(item.billStatus))
      //       .reduce((total, cur) => add(total, cur.billAmtTotal), 0),
      //     billAmtPaid: pledgeList.reduce((total, cur) => add(total, cur.billAmtPaid), 0),
      //     children: pledgeList.map(_item => ({..._item, category: _item.billTypeName, children: []})),
      //   })
      //
      //   // 无账期
      //   const noPeriodList = data.billList.filter(
      //     item => item.billCategory !== 'b.b' && !(item.billBegin && item.billEnd)
      //   )
      //   noPeriodList.length &&
      //     list.push({
      //       billId: Math.random().toString(36).substr(2),
      //       category: '无账期',
      //       billAmtTotal: noPeriodList
      //         .filter(item => !['3', '6'].includes(item.billStatus))
      //         .reduce((total, cur) => add(total, cur.billAmtTotal), 0),
      //       billAmtPaid: noPeriodList.reduce((total, cur) => add(total, cur.billAmtPaid), 0),
      //       children: noPeriodList.map(_item => ({..._item, category: _item.billTypeName, children: []})),
      //     })
      //
      //   // 账期内
      //   const periodList = data.billList.filter(item => item.billCategory !== 'b.b' && item.billBegin && item.billEnd)
      //   const dateInterval = [...new Set(periodList.map(item => `${item.billBegin}至${item.billEnd}`))]
      //
      //   dateInterval.map((key, index) => {
      //     list.push({
      //       billId: Math.random().toString(36).substr(2),
      //       category: `第${index + 1}期 (${key})`,
      //       billAmtTotal: periodList
      //         .filter(item => key === `${item.billBegin}至${item.billEnd}`)
      //         .filter(item => !['3', '6'].includes(item.billStatus))
      //         .reduce((total, cur) => add(total, cur.billAmtTotal), 0),
      //       billAmtPaid: periodList
      //         .filter(item => key === `${item.billBegin}至${item.billEnd}`)
      //         .reduce((total, cur) => add(total, cur.billAmtPaid), 0),
      //       children: periodList
      //         .filter(item => key === `${item.billBegin}至${item.billEnd}`)
      //         .map(_item => ({..._item, category: _item.billTypeName, children: []})),
      //     })
      //   })
      // }
      // setBillList(list)
    })
  }
  const propertyType = {
    '1': '整租',
    '2': '合租',
    '3': '公寓房',
  }
  const contractStatus = {
    '2': '已签约',
    '3': '已到期',
    '5': '已作废',
  }
  // const billStatusColor = useMemo(
  //   () => ({
  //     '1': '#FFB240',
  //     '2': '#409EFF',
  //     '3': '#9EA0A7',
  //     '4': '#9EA0A7',
  //     '5': '#9EA0A7',
  //     '6': '#F56C6C',
  //   }),
  //   []
  // )

  const billStatus = {
    '1': '待付款',
    '2': '已付款',
    '3': '已作废',
  }

  //付款方式
  const payType = {
    '1': '1月一付',
    '2': '2月一付',
    '3': '3月一付',
    '4': '4月一付',
    '6': '半年一付',
    '12': '一年一付',
    '0': '一次性付清',
  }
  //租金分阶
  function rentIncr(type, value) {
    if (type === '0') {
      return '无'
    } else if (type === '1') {
      return `按年自定义`
    } else if (type === '2') {
      return `金额递增${value}元`
    } else {
      return `百分比递增${value}%`
    }
  }
  //支付时间
  function rentCollectDay(type, day) {
    if (type === '1') {
      return `每期提前${day}天`
    } else if (type === '2') {
      return `每期固定`
    } else {
      return `每期提前一个月`
    }
  }
  const lessorCertType = useCodeSync('contract.certType')
  const propertyInfoList: ListDTO[] = [
    {
      title: '房源编号：',
      label: propertyInfo.propertyCode,
    },
    {
      title: '房源概况：',
      label: renderProperty.roomType({
        bathroom: propertyInfo.bathroom,
        bedroom: propertyInfo.bedroom,
        parlor: propertyInfo.parlor,
        kitchen: propertyInfo.kitchen,
      }),
    },
    {
      title: '地址：',
      label: propertyInfo.communityAddress,
    },
    {
      title: '房源类型：',
      label: propertyType[propertyInfo.rentType],
    },
    {
      title: '房源面积：',
      label: propertyInfo.floorage ? `${propertyInfo.floorage}m²` : '',
    },
  ].filter(item => item.label)

  const contractDetailList: ListDTO[] = [
    {
      title: '租约编号：',
      label: lessorDetail.contractId,
    },
    {
      title: '房源标题：',
      label: lessorDetail.propertyTitle,
    },
    {
      title: '租约状态：',
      label: contractStatus[lessorDetail.contractStatus],
    },
    {
      title: '租约周期：',
      label: `${lessorDetail.contractBegin}至${lessorDetail.contractEnd}`,
    },
    {
      title: '租金：',
      label: `${lessorDetail.rentPrice}元`,
    },
    {
      title: '租期：',
      label: `${Math.ceil(dayjs(lessorDetail.contractEnd).diff(dayjs(lessorDetail.contractBegin), 'year', true))}年`,
    },
    {
      title: '租金分阶：',
      label: rentIncr(lessorDetail.rentIncr, lessorDetail.rentIncrValue),
    },
    {
      title: '业主姓名：',
      label: lessorDetail.lessorCertName,
    },
    {
      title: '证件类型：',
      label: lessorCertType[lessorDetail.lessorCertType],
    },
    {
      title: '证件号码：',
      label: lessorDetail.lessorCertNo,
    },
    {
      title: '押金：',
      label: `${lessorDetail.rentPledge}元`,
    },
    {
      title: '业主手机：',
      label: lessorDetail.lessorMobile,
    },
    {
      title: '支付时间：',
      label: rentCollectDay(lessorDetail.rentCollectType, lessorDetail.rentCollectDay),
    },
    {
      title: '签约时间：',
      label: lessorDetail.signTime,
    },
    {
      title: '合同编号：',
      label: lessorDetail.originContractId,
    },
    {
      title: '备注：',
      label: lessorDetail.remark,
    },
  ].filter(item => item.label)

  function onActionItem(vo: ContractLessorBillVO, event, key) {
    // const {key, domEvent} = event

    if (key === 'detail') {
      billDetailRef.current.onDisplay()
      billDetailRef.current.onSetBillDetail(vo, vo.billId)
    }

    if (key === 'confirm') {
      confirmCollectionRef.current.onDisplay()
      confirmCollectionRef.current.onSetBillDetail(vo)
    }
    event.stopPropagation()
  }

  //操作
  // function onAction(billId: string, event) {
  //   if (!(billId in billDetail)) {
  //     queryBillDetail(billId)
  //   }
  //   event.stopPropagation()
  // }

  //账单详情
  // function queryBillDetail(billId: string) {
  //   contractModel.contractLessorBillDetail(billId).then(({response: {data}}) => {
  //     const _lessorDetail = {
  //       付款金额: data.billDetailList.filter(item => item.billPayWayName !== '结算抵扣'),
  //       // 财务结算: data.billDetailList.filter(item => item.billPayWayName === '结算抵扣'),
  //     }
  //     setBillDetail({...billDetail, [billId]: {...data, lessorDetailObj: _lessorDetail}})
  //   })
  // }
  // function onExpand(expanded, record) {
  //   if (expanded && !record.children.length && !(record.billId in billDetail)) {
  //     queryBillDetail(record?.billId)
  //   }
  // }

  /**
   * 账单添加成功 查看 收款
   */
  function onSuccess(billId?: string) {
    queryLessorBillDetail(lessorDetail.contractId)
    // if (billId) {
    //   queryBillDetail(billId)
    // }
  }

  /**
   * 收款明细详情
   */
  async function onCollectionDetail(item: ContractLessorBillDetailVO, category: string) {
    const {
      response: {data},
    } = await contractModel.contractLessorBillDetailQuery({billDetailId: item.billDetailId})
    setLessorBillDetail({...item, category, ...data})
    setVisible(true)
  }

  const renderOperation = (record1: ContractLessorBillVO) => {
    const list = hasPermission('004001003') ? [{key: 'detail', label: '查看'}] : []
    if (record1.billStatus === '1' && hasPermission('003001014')) {
      list.unshift({key: 'confirm', label: '付款'})
    }
    // 取操作的第一项
    const btn = list.shift()

    return record1.billStatus ? (
      list.length ? (
        <Dropdown
          trigger={['hover']}
          overlay={
            <Menu onClick={event => onActionItem(record1, event.domEvent, event.key)}>
              {list.map(item => (
                <Menu.Item key={item.key}>{item.label}</Menu.Item>
              ))}
            </Menu>
          }
        >
          <Button style={{minWidth: 62}} onClick={event => onActionItem(record1, event, btn.key)} size={'small'}>
            {btn.label} <DownOutlined />
          </Button>
        </Dropdown>
      ) : (
        <Button style={{minWidth: 62}} onClick={event => onActionItem(record1, event, btn.key)} size={'small'}>
          {btn.label}
        </Button>
      )
    ) : null
  }

  return (
    <div>
      <Breadcrumb path={['首页', '租约管理', '租约列表', '业主租约详情']} />
      <div>
        <Tabs defaultActiveKey={'1'} className={styles.tabs}>
          <TabPane tab={'合同详情'} key={'1'}>
            {loading ? (
              <Skeleton active />
            ) : (
              <div className={styles.tab1}>
                <div className={styles.header}>
                  <div className={styles.icon} />
                  <Text className={styles.h1}>房源信息</Text>
                </div>
                <div className={styles.content}>
                  <Row className={styles.row}>
                    <Col>
                      <Text className={styles.text}>{lessorDetail.propertyTitle}</Text>
                    </Col>
                  </Row>
                  <Row className={styles.row1}>
                    {propertyInfoList.map(item => (
                      <Col key={item.title} span={12} style={{marginBottom: '10px'}}>
                        <Text className={styles.text}>{item.title}</Text>
                        <Text className={styles.label}>{item.label}</Text>
                      </Col>
                    ))}
                  </Row>
                </div>
                <div className={styles.dash} />
                <div className={styles.header}>
                  <div className={styles.icon} />
                  <Text className={styles.h1}>合同详情</Text>
                </div>
                <div className={styles.content}>
                  <Row className={styles.row1}>
                    {contractDetailList.map(item => (
                      <Col key={item.title} span={12} style={{marginBottom: '10px'}}>
                        <Text className={styles.text}>{item.title}</Text>
                        <Text className={styles.label}>{item.label}</Text>
                      </Col>
                    ))}
                  </Row>
                  {imgList.length ? (
                    <Row>
                      <Col>
                        <Text className={styles.text}>附件：</Text>
                      </Col>
                      <Col className={styles.upload}>
                        <Album disabled fileList={imgList.map(item => ({uid: item.imgId, url: getImg(item.imgUrl)}))} />
                      </Col>
                    </Row>
                  ) : null}
                </div>
              </div>
            )}
          </TabPane>
          <TabPane tab={'账务信息'} key={'2'}>
            <div style={{backgroundColor: '#ffffff', padding: '31px 22px'}}>
              <Text>{lessorDetail.propertyTitle}</Text>
              <div className={styles.ownerInfo}>
                <Row>
                  <Col span={6}>
                    <Text className={styles.text}>业主姓名：{lessorDetail.lessorCertName}</Text>
                  </Col>
                  <Col span={6}>
                    <Text className={styles.text}>业主手机号：{lessorDetail.lessorMobile}</Text>
                  </Col>
                </Row>
                <Row className={styles.row2}>
                  <Col span={6}>
                    <Text className={styles.text}>租金：{lessorDetail.rentPrice}元/月</Text>
                  </Col>
                  <Col span={6}>
                    <Text className={styles.text}>
                      租期：
                      {`${lessorDetail.contractBegin}至${lessorDetail.contractEnd}(${Math.ceil(
                        dayjs(lessorDetail.contractEnd).diff(dayjs(lessorDetail.contractBegin), 'year', true)
                      )}年)`}
                    </Text>
                  </Col>
                  <Col span={6}>
                    <Text className={styles.text}>付款方式：{payType[lessorDetail.rentPayType]}</Text>
                  </Col>
                </Row>
              </div>
              <Text className={styles.bill}>租约账单</Text>
              {/*<Table
                className={styles.table}
                loading={loading}
                // rowKey={'billId'}
                rowKey={'category'}
                // title={() => (
                //   <Space size={21}>
                //     <Typography.Title level={4} style={{marginBottom: 0}}>
                //       租约账单
                //     </Typography.Title>
                //     <Text>
                //       总账单 <span style={{color: '#017BFF'}}>{billListCount.amtTotal}</span> 元
                //     </Text>
                //     <Text>
                //       已收 <span style={{color: '#017BFF'}}>{billListCount.amtPaid}</span> 元
                //     </Text>
                //     <Text>
                //       待收 <span style={{color: '#017BFF'}}>{billListCount.amtUnpaid}</span> 元
                //     </Text>
                //   </Space>
                // )}
                columns={[
                  {
                    title: '项目',
                    dataIndex: 'category',
                    key: 'category',
                  },
                  {
                    title: '应付金额',
                    dataIndex: 'billAmtTotal',
                    key: 'billAmtTotal',
                    render: text => (
                      <Statistic
                        value={text}
                        precision={2}
                        className={styles.statistic}
                      />
                    ),
                  },
                  {
                    title: '应付款日',
                    dataIndex: 'billDate',
                    key: 'billDate',
                  },
                  {
                    title: '已付金额',
                    dataIndex: 'billAmtPaid',
                    key: 'billAmtPaid',
                    render: text => (
                      <Statistic
                        value={text}
                        precision={2}
                        className={styles.statistic}
                      />
                    ),
                  },
                  {
                    title: '付款日期',
                    dataIndex: 'payDate',
                    key: 'payDate',
                  },
                  {
                    title: '状态',
                    dataIndex: 'billStatus',
                    key: 'billStatus',
                    render: (text, record) => {
                      return (
                        <>
                          {text === '1' && record?.overdue ? (
                            <Badge color={billStatusColor['6']} text={`逾期 ${dayjs().from(record?.billDate, true)}`} />
                          ) : text ? (
                            <Badge color={billStatusColor[text]} text={billStatus[text]} />
                          ) : null}
                        </>
                      )
                    },
                  },
                  {
                    title: '操作',
                    dataIndex: 'operation',
                    key: 'operation',
                    render: (text, record, index) => (
                      <>
                        {record?.billStatus ? (
                          <Dropdown
                            trigger={['click']}
                            overlay={
                              record.billStatus === '1' ? (
                                <Menu onClick={event => onActionItem(record.billId, event)}>
                                  <Menu.Item key={'detail'}>查看</Menu.Item>
                                  <Menu.Item key={'confirm'}>收款</Menu.Item>
                                </Menu>
                              ) : (
                                <Menu onClick={event => onActionItem(record.billId, event)}>
                                  <Menu.Item key={'detail'}>查看</Menu.Item>
                                </Menu>
                              )
                            }
                          >
                            <Button onClick={event => onAction(record.billId, event)} size={'small'}>
                              操作 <DownOutlined />
                            </Button>
                          </Dropdown>
                        ) : null}
                      </>
                    ),
                  },
                ]}
                dataSource={billList}
                expandable={{
                  expandRowByClick: true,
                  expandedRowRender: (record, index, indent, expanded) => {
                    return (
                      <>
                        {lessorBillDetailLoading && !(record.billId in billDetail) ? (
                          <Skeleton active />
                        ) : (
                          <div style={{marginLeft: '70px'}}>
                            <Space>
                              <Text>账单金额：</Text>
                              <Text>{billDetail[record.billId]?.billAmtTotal}元</Text>
                            </Space>
                            {billDetail[record.billId]?.lessorDetailObj['付款金额']?.length ? (
                              <div>
                                <Space direction={'vertical'}>
                                  {billDetail[record.billId]?.lessorDetailObj['付款金额'].map(item => (
                                    <Space key={item.billDetailId}>
                                      <Text className={styles.billDetailListTitle}>付款金额：</Text>
                                      <Text>{item.billPayAmt}元</Text>
                                      <Text>({dayjs(item.billPayTime).format('YYYY-MM-DD')}</Text>
                                      <Text>{item.billPayWayName})</Text>
                                      <Link underline onClick={() => onCollectionDetail(item, record.category)}>
                                        详情
                                      </Link>
                                    </Space>
                                  ))}
                                </Space>
                              </div>
                            ) : null}
                          </div>
                        )}
                      </>
                    )
                  },
                }}
                onExpand={onExpand}
                pagination={false}
              />*/}

              <Table
                // loading={loading}
                // className={styles.contractInfoTable}
                rowKey={'category'}
                className={styles.mainTable}
                dataSource={billList}
                columns={[
                  {
                    title: '租约',
                    width: 100,
                    dataIndex: 'category',
                    key: 'category',
                  },
                  {
                    title: '账单周期',
                    width: 220,
                    dataIndex: 'billTime',
                    key: 'billTime',
                    ellipsis: true,
                  },
                  {
                    title: '应付',
                    width: 120,
                    dataIndex: 'billAmtTotal',
                    key: 'billAmtTotal',
                    render: text => <Statistic value={text} precision={2} className={styles.statistic} />,
                  },
                  {
                    title: '已付',
                    width: 120,
                    dataIndex: 'billAmtPaid',
                    key: 'billAmtPaid',
                    render: text => <Statistic value={text} precision={2} className={styles.statistic} />,
                  },
                  {title: '应付款日', width: 120, dataIndex: 'billDate', key: 'billDate'},
                  // {title: '付款款日期', dataIndex: 'billPayTime', key: 'billPayTime'},
                  {
                    title: '状态',
                    width: 160,
                    dataIndex: 'billStatus', // 第一层的 billStatus 已经赋过值
                    key: 'billStatus',
                  },
                  {
                    title: '操作',
                    width: 100,
                  },
                  {title: '备注'},
                ]}
                expandable={{
                  expandRowByClick: true,
                  expandedRowRender: (record, index, indent, expanded) => {
                    const subList = record.list.reduce<ContractLessorBillDetailVO[]>(
                      (prev, item, index) => [
                        ...prev,
                        ...item.billDetailList.map(detail => ({
                          ...detail,
                          billTime: `${index + 1} ${item.billTypeName}`, //
                        })),
                      ],
                      []
                    )

                    return (
                      <div style={{marginRight: -16, paddingLeft: subList.length ? 32 : 0}}>
                        <Table
                          rowKey='billId'
                          className={styles.subTable}
                          showHeader={false}
                          columns={[
                            {
                              title: '租约',
                              width: 100,
                              render: (value, record, index) => {
                                return index === 0 ? '账单明细' : ''
                              },
                            },
                            {
                              title: '账单周期',
                              width: 220,
                              render: (value, record1, index) => {
                                return `${index + 1} ${record1.billTypeName}`
                              },
                            },
                            {
                              title: '应付',
                              width: 120,
                              key: 'billAmtTotal',
                              dataIndex: 'billAmtTotal',
                              render: text => <Statistic value={text} precision={2} className={styles.statistic} />,
                            },
                            {
                              title: '已付',
                              width: 120,
                              key: 'billAmtPaid',
                              dataIndex: 'billAmtPaid',
                              render: text => <Statistic value={text} precision={2} className={styles.statistic} />,
                            },
                            {title: '应付款日', width: 120, dataIndex: 'billDate', key: 'billDate'},
                            // {title: '状态', dataIndex: 'billStatus', key: 'billStatus'},
                            {
                              title: '状态',
                              width: 160,
                              dataIndex: 'billStatus',
                              key: 'billStatus',
                              render: (text, record) => {
                                return billStatus[text] + (text === '1' ? `（${record.billAmtTotal}）` : '')
                              },
                            },
                            {
                              title: '操作',
                              width: 100,
                              render: (value, record1) => renderOperation(record1),
                            },
                            {
                              title: '备注',
                              render: (value, record1) => {
                                return record1.billRemark ? (
                                  <Button
                                    size={'small'}
                                    style={{minWidth: 62}}
                                    onClick={() => {
                                      Modal.info({
                                        content: record1.billRemark,
                                        okText: '关闭',
                                        title: '账单备注',
                                        icon: null,
                                      })
                                    }}
                                  >
                                    查看
                                  </Button>
                                ) : null
                              },
                            },
                          ]}
                          dataSource={record.list}
                          pagination={false}
                        />
                        {!!subList?.length && (
                          <Table
                            rowKey='billDetailId'
                            showHeader={false}
                            className={styles.subTable}
                            columns={[
                              {
                                dataIndex: 'category',
                                key: 'category',
                                width: 100,
                                render: (_, __, index) => (index === 0 ? '支付记录' : ''),
                              },
                              {
                                dataIndex: 'billTime',
                                key: 'billTime',
                                render: (value, record1) => (
                                  <Space size={24}>
                                    <Text>{value}</Text>
                                    <Text>{record1.billPayTime}</Text>
                                    <Text>{record1.billPayWayName}</Text>
                                    <Space size={0}>
                                      <Statistic
                                        value={record1.billPayAmt}
                                        precision={2}
                                        className={styles.statistic}
                                      />
                                      {auth('003001015') && (
                                        <Button
                                          size={'small'}
                                          type={'link'}
                                          onClick={() => {
                                            onCollectionDetail(record1, record.category)
                                          }}
                                        >
                                          详情
                                        </Button>
                                      )}
                                    </Space>
                                  </Space>
                                ),
                              },
                            ]}
                            dataSource={subList}
                            pagination={false}
                          />
                        )}
                      </div>
                    )
                  },
                }}
                // onExpand={onExpand}
                pagination={false}
              />

              {!!chargeList.length && (
                <Table
                  style={{marginTop: 10}}
                  rowKey='billId'
                  className={styles.mainTable}
                  columns={[
                    {title: '记账', width: 100, dataIndex: 'billTypeName', key: 'billTypeName'},
                    {
                      title: '账单周期',
                      width: 220,
                      // dataIndex: 'billTime',
                      // key: 'billTime',
                      render: (_, record) => getBillTime(record),
                    },
                    {
                      title: '应付',
                      width: 120,
                      dataIndex: 'billAmtTotal',
                      key: 'billAmtTotal',
                      render: text => <Statistic value={text} precision={2} className={styles.statistic} />,
                    },
                    {
                      title: '已付',
                      width: 120,
                      dataIndex: 'billAmtPaid',
                      key: 'billAmtPaid',
                      render: text => <Statistic value={text} precision={2} className={styles.statistic} />,
                    },
                    {title: '应付款日', width: 120, dataIndex: 'billDate', key: 'billDate'},
                    {
                      title: '状态',
                      width: 130,
                      dataIndex: 'billStatus',
                      key: 'billStatus',
                      render: text => billStatus[text],
                    },
                    {
                      title: '操作',
                      width: 100,
                      render: (value, record1) => renderOperation(record1),
                    },
                    {
                      title: '备注',
                      // width: 130,
                      render: (value, record1) => {
                        return record1.billRemark ? (
                          <Button
                            size={'small'}
                            style={{minWidth: 62}}
                            onClick={() => {
                              Modal.info({content: record1.billRemark, okText: '确认'})
                            }}
                          >
                            查看
                          </Button>
                        ) : null
                      },
                    },
                  ]}
                  dataSource={chargeList}
                  expandable={{
                    expandRowByClick: true,
                    rowExpandable: record => !!record.billDetailList?.length,
                    expandedRowRender: record => {
                      const subList = record.billDetailList

                      return subList?.length ? (
                        <Table
                          rowKey={'billDetailId'}
                          showHeader={false}
                          className={styles.subTable}
                          expandable={{}}
                          columns={[
                            {
                              dataIndex: 'category',
                              key: 'category',
                              width: 100,
                              render: (_, __, index) => (index === 0 ? '支付记录' : ''),
                            },
                            {
                              dataIndex: 'billPayAmt',
                              key: 'billPayAmt',
                              render: (value, record1) => (
                                <Space size={24}>
                                  <Text>{record1.billPayTime}</Text>
                                  <Text>{record1.billPayWayName}</Text>
                                  <Statistic value={value} precision={2} className={styles.statistic} />
                                  {auth('003001015') && (
                                    <Button
                                      size={'small'}
                                      type={'link'}
                                      onClick={() => {
                                        onCollectionDetail(record1, record.billTypeName)
                                      }}
                                    >
                                      详情
                                    </Button>
                                  )}
                                </Space>
                              ),
                            },
                          ]}
                          dataSource={subList}
                          pagination={false}
                        />
                      ) : null
                    },
                  }}
                />
              )}
            </div>
          </TabPane>
        </Tabs>

        {/* 操作查看 */}
        <BillDetailAction ref={billDetailRef} onSuccess={billId => onSuccess(billId)} billType={'lessor'} />
        {/* 操作收款 */}
        <ConfirmCollectionAction
          ref={confirmCollectionRef}
          onSuccess={billId => onSuccess(billId)}
          billType={'lessor'}
        />
        {/* 作废账单 */}
        <Cancellation ref={cancellationRef} onSuccess={extra => onSuccess(extra)} contractType={'lessor'} />
        {/* 账单明细详情 */}
        <Modal
          visible={visible}
          width={780}
          bodyStyle={{padding: 0}}
          footer={null}
          onCancel={() => setVisible(false)}
          centered
          title={<div style={{textAlign: 'center'}}>查看收支明细</div>}
          className={styles.collectionDetailModal}
          afterClose={() => setLessorBillDetail({} as ContractLessorBillDetail)}
        >
          <>
            <Tabs tabPosition={'left'} activeKey={'1'}>
              <TabPane
                tab={`${dayjs(lessorBillDetail.billPayTime).format('YYYY-MM-DD')}${lessorBillDetail.creatorName}通过${
                  lessorBillDetail.billPayWayName
                }付款${lessorBillDetail.billPayAmt}元`}
                key={'1'}
              >
                <Space direction={'vertical'} style={{marginTop: '12px', color: 'rgba(0, 0, 0, 0.65)'}}>
                  <Text>付款编号：{lessorBillDetail.tradeId}</Text>
                  <Text>付款项目：{lessorBillDetail.category}</Text>
                  <Space align={'start'}>
                    <div style={{width: '70px'}}>图片凭证：</div>
                    {lessorBillDetail.imgList?.length ? (
                      <Album
                        disabled
                        fileList={lessorBillDetail.imgList.map(item => ({
                          ...item,
                          url: getImg(item.imgUrl),
                          uid: item.imgId,
                        }))}
                      />
                    ) : null}
                  </Space>
                  <Text>付款备注：{lessorBillDetail.billDetailRemark}</Text>
                </Space>
              </TabPane>
            </Tabs>

            <Space className={styles.btn}>
              <Permission code={'003001007'}>
                <Button
                  onClick={() => {
                    cancellationRef.current.onDisplay(lessorBillDetail.contractId, lessorBillDetail.billId)
                    setVisible(false)
                  }}
                >
                  作废付款
                </Button>
              </Permission>
            </Space>
          </>
        </Modal>
      </div>
    </div>
  )
}
