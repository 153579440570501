import {InputNumberProps} from 'antd/lib/input-number'
import React from 'react'
import styles from './index.module.less'
import {InputNumber} from 'antd'
import {CloseCircleFilled} from '@ant-design/icons'

export interface AddItemProps extends InputNumberProps {
  title: string
  secondTitle?: string
  closeable?: boolean
  onClose?: () => void
}

export const AddItem: React.FC<AddItemProps> = ({title, secondTitle, closeable, onClose, onClick, ...props}) => {
  return (
    <div className={styles.add}>
      <div className={styles.addText} onClick={onClick}>
        <div className={styles.addTextTextHover}>
          <div>{title}</div>
          <div>{secondTitle}</div>
        </div>
      </div>
      <InputNumber
        defaultValue={props.value ?? 0}
        onChange={props.onChange}
        min={0}
        className={styles.addNumber}
        {...props}
      />

      {closeable && (
        <div className={styles.addClose} onClick={onClose}>
          <CloseCircleFilled />
        </div>
      )}
    </div>
  )
}

AddItem.defaultProps = {
  secondTitle: '查看或编辑物品',
  closeable: true,
}
