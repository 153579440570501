import React, {useState} from 'react'
import styles from './index.module.less'
import {Button, Form, Input, message, Modal, Typography} from 'antd'
import {rules} from '@/utils/validate'
import {userModel} from '@/store/models/User'
import {useCountDown} from '@/common/hooks/countDown'
import {useHistory} from 'react-router-dom'
import {Model} from '@redux-model/web'
import banner from '@/assets/icons/login/banner.svg'
import logo_text from '@/assets/icons/base/logo_text.svg'

export default function Login() {
  const {countDown, startCountDown} = useCountDown()
  const [form] = Form.useForm()
  const [forgetForm] = Form.useForm()
  const history = useHistory()
  const loading = Model.isLoading(
    userModel.login.useLoading(),
    userModel.queryUser.useLoading(),
    userModel.queryMyTeam.useLoading()
  )
  const [isPwd, setIsPwd] = useState(false)
  const [showForget, setShowForget] = useState(false)
  const forgetLoading = Model.isLoading(userModel.userForgetPwd.useLoading())

  const onFinish = async values => {
    console.log('values =>', values)
    await userModel.loginAndReqUserInfo({
      loginType: isPwd ? 'pwd' : 'verifyCode',
      source: 'lessor',
      ...values,
    })
    history.replace('/')
  }

  async function sendVerifySms() {
    await form.validateFields(['mobile'])
    await userModel.sendVerifySms({
      loginType: 'verifyCode',
      source: 'lessor',
      mobile: form.getFieldValue('mobile'),
    } as UserLoginDTO)
    startCountDown()
  }

  const onForget = async values => {
    console.log('forget values =>', values)
    await userModel.userForgetPwd({...values, source: 'lessor'})
    message.success('设置成功！')
    setShowForget(false)
    forgetForm.resetFields()
  }

  async function verifySmsForForget() {
    await forgetForm.validateFields(['mobile'])
    await userModel.sendVerifySms({
      loginType: 'verifyCode',
      source: 'lessor',
      mobile: forgetForm.getFieldValue('mobile'),
    } as UserLoginDTO)
    startCountDown()
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img src={logo_text} alt='' className={styles.brand} />
        <div className={styles.contentLeft}>
          <img src={banner} alt='' style={{width: '100%'}} />
        </div>
        <div className={styles.contentRight}>
          <Typography.Text className={styles.subTitle}>欢迎使用</Typography.Text>
          <h1 className={styles.title}>牛油果房东</h1>
          <Form form={form} labelCol={{span: 8}} onFinish={onFinish} style={{width: '100%'}}>
            <Form.Item name='mobile' rules={rules.mobile}>
              <Input size={'large'} className={styles.input} maxLength={11} autoFocus placeholder={'请输入账号'} />
            </Form.Item>
            {isPwd ? (
              <Form.Item name={'pwd'} rules={[{required: true, message: '请输入密码'}]}>
                <Input.Password
                  size={'large'}
                  minLength={6}
                  maxLength={18}
                  placeholder={'请输入密码'}
                  className={styles.input}
                />
              </Form.Item>
            ) : (
              <Form.Item name='verifyCode' rules={rules.verifyCode}>
                <Input
                  size={'large'}
                  maxLength={6}
                  placeholder={'请输入验证码'}
                  className={styles.input}
                  suffix={
                    <Button type={'link'} size={'small'} onClick={() => countDown < 1 && sendVerifySms()}>
                      {countDown > 0 ? `${countDown}s` : '获取验证码'}
                    </Button>
                  }
                />
              </Form.Item>
            )}
            {/*<Input.Search*/}
            {/*  enterButton={countDown > 0 ? `${countDown}s` : '获取验证码'}*/}
            {/*  onSearch={() => {*/}
            {/*    countDown < 1 && sendVerifySms()*/}
            {/*  }}*/}
            {/*/>*/}

            <Button size={'large'} type='primary' htmlType='submit' loading={loading} block>
              登录
            </Button>
            <div className={isPwd ? styles.loginPhone : styles.loginPwd}>
              <Button size={'large'} type='link' onClick={() => setIsPwd(prevState => !prevState)} style={{padding: 0}}>
                {isPwd ? '手机号登录' : '账号密码登录'}
              </Button>
              {isPwd && (
                <Button
                  size={'large'}
                  type='link'
                  onClick={() => {
                    setShowForget(true)
                    forgetForm.setFieldsValue({mobile: form.getFieldValue('mobile')})
                  }}
                  style={{padding: 0}}
                >
                  忘记密码
                </Button>
              )}
            </div>
          </Form>
        </div>
        {/*header*/}
      </div>

      {/** 忘记密码 */}
      <Modal
        visible={showForget}
        destroyOnClose
        centered={true}
        footer={null}
        closable={true}
        onCancel={() => {
          setShowForget(false)
          forgetForm.resetFields()
        }}
      >
        <div className={styles.forget}>
          <Typography.Text className={styles.forgetTitle}>忘记密码</Typography.Text>
          <Form form={forgetForm} labelCol={{span: 8}} onFinish={onForget} style={{width: '100%'}}>
            <Form.Item name='mobile' rules={rules.mobile}>
              <Input size={'large'} className={styles.input} maxLength={11} autoFocus placeholder={'请输入账号'} />
            </Form.Item>
            <Form.Item name='verifyCode' rules={rules.verifyCode}>
              <Input
                size={'large'}
                maxLength={6}
                placeholder={'请输入验证码'}
                className={styles.input}
                suffix={
                  <Button type={'link'} size={'small'} onClick={() => countDown < 1 && verifySmsForForget()}>
                    {countDown > 0 ? `${countDown}s` : '获取验证码'}
                  </Button>
                }
              />
            </Form.Item>
            <Form.Item name='pwd' rules={[{required: true, message: '请输入密码'}]}>
              <Input.Password
                size={'large'}
                className={styles.input}
                minLength={6}
                maxLength={18}
                placeholder={'请输入新密码'}
              />
            </Form.Item>
            <Button size={'large'} type='primary' htmlType='submit' loading={forgetLoading} block>
              提交
            </Button>
          </Form>
        </div>
      </Modal>
    </div>
  )
}
