import React, {useEffect} from 'react'
import {Button, Form, Input, message} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import {rules} from '@/utils/validate'
import {userModel} from '@/store/models/User'
import {useCountDown} from '@/common/hooks/countDown'
import styles from './index.module.less'
import {useHistory} from 'react-router'
import {Breadcrumb} from '@/ui/components/Breadcrumb'

export default function PaymentCode() {
  const history = useHistory()
  const [form] = Form.useForm()
  const mobile = userModel.useData(data => data.userInfo?.mobile)
  const {countDown, startCountDown} = useCountDown()
  const loading = userModel.payPwdChange.useLoading()

  useEffect(() => {
    form.setFieldsValue({mobile: mobile})
  }, [form, mobile])

  const onUpdate = async values => {
    const {pwd, verifyCode} = values
    await userModel.payPwdChange(pwd, verifyCode)
    message.success('支付密码设置成功')
    history.goBack()
  }

  async function verifySmsForUpdate() {
    await form.validateFields(['mobile'])
    await userModel.sendVerifySms({
      loginType: 'verifyCode',
      source: 'lessor',
      mobile: form.getFieldValue('mobile'),
    } as UserLoginDTO)
    startCountDown()
  }

  return (
    <>
      <Breadcrumb path={['首页', '修改支付密码']} />
      <div className={commonStyles.flexFullSection}>
        <div className={styles.update}>
          <Form form={form} labelCol={{span: 8}} onFinish={onUpdate} style={{width: '100%'}}>
            <Form.Item name='mobile' rules={rules.mobile}>
              <Input size={'large'} maxLength={11} autoFocus disabled placeholder={'请输入账号'} />
            </Form.Item>
            <Form.Item name='verifyCode' rules={rules.verifyCode}>
              <Input
                size={'large'}
                maxLength={6}
                placeholder={'请输入验证码'}
                suffix={
                  <Button type={'link'} size={'small'} onClick={() => countDown < 1 && verifySmsForUpdate()}>
                    {countDown > 0 ? `${countDown}s` : '获取验证码'}
                  </Button>
                }
              />
            </Form.Item>
            <Form.Item
              name='pwd'
              rules={[
                {required: true, message: '请输入六位数字支付密码'},
                ({getFieldValue}) => ({
                  validator(rule, value) {
                    if (!value || /^\d{6}$/.test(value)) {
                      return Promise.resolve()
                    }
                    return Promise.reject('支付密码为六位数字')
                  },
                }),
              ]}
            >
              <Input.Password
                size={'large'}
                type={'number'}
                minLength={6}
                maxLength={6}
                placeholder={'请输入支付密码'}
              />
            </Form.Item>
            <Form.Item
              name='pwdConfirm'
              rules={[
                {required: true, message: '请再次输入六位数字支付密码'},
                ({getFieldValue}) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('pwd') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject('两次密码不相同')
                  },
                }),
              ]}
            >
              <Input.Password
                size={'large'}
                type={'number'}
                minLength={6}
                maxLength={6}
                placeholder={'请再次输入密码'}
              />
            </Form.Item>
            <Button size={'large'} type='primary' htmlType='submit' loading={loading} block>
              保存并提交
            </Button>
          </Form>
        </div>
      </div>
    </>
  )
}
