import React, {useCallback, useEffect, useState} from 'react'
import {Button, Col, DatePicker, Form, Input, message, Row, Select, Space, Table, Tabs} from 'antd'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import {ButtonType} from 'antd/es/button'
import styles from './index.module.less'
import {warehouseModel} from '@/store/models/Warehouse'
import {downloadBlob} from '@/utils/util'
import moment from 'moment'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import GoodsAddStore from '@/pages/goods/GoodsAddStore'
import GoodsAddOperation from '@/pages/goods/GoodsAddOperation'
import {usePermission} from '@/ui/components/Permission'

const {TabPane} = Tabs
const {Option} = Select
const {RangePicker} = DatePicker

export default function GoodsManagement() {
  const [auth] = usePermission()

  const lessorMaterialTypeCode = useCodeSync('lessor.material.type')
  const lessorMaterialTypeArr = useCodeArraySync('lessor.material.type')

  const [tabKey, setTabKey] = useState('1')

  // todo: 新增物品
  // 新增物品
  const [showAddOperation, setShowAddOperation] = useState<'1' | '2'>(undefined)

  // todo: 新增仓库
  const [showAddStore, setShowAddStore] = useState(false)

  // todo: 列表
  // 仓库列表
  const [warehouseList, setWarehouseList] = useState<LessorWarehouseVO[]>([])

  // 物品列表
  const [goodsForm] = Form.useForm()
  const [goodsTotal, setGoodsTotal] = useState(0)
  const [goodsCond, setGoodsCond] = useState<Partial<LessorWarehouseMaterialListDTO>>({
    pageNum: 1,
    pageSize: 10,
  })
  const [goodsList, setGoodsList] = useState<LessorWarehouseMaterialVO[]>([])

  // 仓库物品列表
  const [storeForm] = Form.useForm()
  const [storeGoodsTotal, setStoreGoodsTotal] = useState(0)
  const [storeGoodsCond, setStoreGoodsCond] = useState<Partial<LessorWarehouseMaterialListDTO>>({
    pageNum: 1,
    pageSize: 10,
    warehouseType: '1',
  })
  const [storeGoodsList, setStoreGoodsList] = useState<LessorWarehouseMaterialVO[]>([])

  // todo: loading
  const materialListLoading = warehouseModel.lessorWarehouseMaterialList.useLoading()

  const [curMaterialId, setCurMaterialId] = useState('')

  // todo: networking
  const queryMaterialList = useCallback(function (cond: Partial<LessorWarehouseMaterialListDTO>, tabKey: string) {
    // 房东仓库-物品列表
    warehouseModel.lessorWarehouseMaterialList(cond).then(({response: {data, total}}) => {
      // console.log('data', data)
      if (tabKey === '2') {
        setStoreGoodsList(data)
        setStoreGoodsTotal(total)
      } else {
        setGoodsList(data)
        setGoodsTotal(total)
      }
    })
  }, [])

  useEffect(
    function () {
      // 物品列表
      queryMaterialList(goodsCond, '1')
    },
    [goodsCond, queryMaterialList]
  )

  useEffect(
    function () {
      // 在仓库中的物品列表
      queryMaterialList(storeGoodsCond, '2')
    },
    [queryMaterialList, storeGoodsCond]
  )

  const queryWarehouseList = useCallback(function () {
    // 仓库列表
    warehouseModel.lessorWarehouseList().then(({response: {data}}) => {
      // console.log('data----', data)
      if (data) {
        setWarehouseList(data)
      }
    })
  }, [])

  useEffect(
    function () {
      queryWarehouseList()
    },
    [queryWarehouseList]
  )

  // todo: render
  const renderOperation = useCallback(
    function (args: {warehouseType?: string; materialId?: string}) {
      const {warehouseType, materialId} = args
      const renders: {title: string; render: boolean; type: ButtonType; key: string; onClick: () => void}[] = [
        {
          title: '新增物品设备',
          render: tabKey === '1' && !warehouseType && auth('004004002'),
          type: 'primary',
          key: '1001',
          onClick: () => setShowAddOperation('1'),
        },
        {
          title: '导出',
          render: tabKey === '1' && !warehouseType && auth('004004005'),
          type: 'ghost',
          key: '1002',
          onClick: () =>
            downloadBlob({url: '/lessor/warehouse/material/list-excel', body: {...goodsCond, pageable: true}}),
        },
        {
          title: '新增仓库',
          render: tabKey === '2' && !warehouseType && auth('004004003'),
          type: 'primary',
          key: '1003',
          onClick: () => setShowAddStore(true),
        },
        {
          title: '导出',
          render: tabKey === '2' && !warehouseType && auth('004004005'),
          type: 'ghost',
          key: '1004',
          onClick: () =>
            downloadBlob({url: '/lessor/warehouse/material/list-excel', body: {...storeGoodsCond, pageable: true}}),
        },
        {
          title: '查看',
          render: !!warehouseType && auth('004004004'),
          type: 'link',
          key: '1005',
          onClick: () => {
            setShowAddOperation('1')
            setCurMaterialId(materialId)
          },
        },
        {
          title: '删除',
          render: !!warehouseType && warehouseType !== '3' && auth('004004004'),
          type: 'link',
          key: '1006',
          onClick: async () => {
            await warehouseModel.lessorWarehouseMaterialDelete({materialId})
            message.success('删除成功！')
            queryMaterialList(tabKey === '1' ? goodsCond : storeGoodsCond, tabKey)
          },
        },
        {
          title: '报废',
          render: !!warehouseType && warehouseType !== '3' && auth('004004004'),
          type: 'link',
          key: '1007',
          onClick: async () => {
            await warehouseModel.lessorWarehouseMaterialDestroy({materialId})
            message.success('报废成功！')
            queryMaterialList(tabKey === '1' ? goodsCond : storeGoodsCond, tabKey)
          },
        },
        {
          title: '调拨',
          render: !!warehouseType && warehouseType !== '3' && auth('004004004'),
          type: 'link',
          key: '1008',
          onClick: () => {
            setShowAddOperation('2')
            setCurMaterialId(materialId)
          },
        },
      ]

      return renders.filter(item => item.render)
    },
    [auth, goodsCond, queryMaterialList, storeGoodsCond, tabKey]
  )

  const tableColumns = [
    {title: '物品设备名称', key: 'name', dataIndex: 'name'},
    {title: '品牌', key: 'brand', dataIndex: 'brand', render: brand => brand ?? '/'},
    {title: '型号', key: 'specification', dataIndex: 'specification', render: specification => specification ?? '/'},
    {title: '单价（元）', key: 'price', dataIndex: 'price', render: price => price ?? '/'},
    {title: '数量', key: 'num', dataIndex: 'num'},
    {title: tabKey === '1' ? '位置' : '仓库', key: 'warehouseName', dataIndex: 'warehouseName'},
    {title: '所在地址', key: 'warehouseAddress', dataIndex: 'warehouseAddress'},
    {title: '配置日期', key: 'settingDate', dataIndex: 'settingDate'},
    {
      title: '状态',
      key: 'warehouseType',
      dataIndex: 'warehouseType',
      render: warehouseType => lessorMaterialTypeCode[warehouseType],
    },
    {
      title: '操作',
      key: 'warehouseType',
      dataIndex: 'warehouseType',
      render: (warehouseType, record) => {
        const {materialId} = record ?? {}
        return (
          <Space>
            {renderOperation({warehouseType, materialId})?.map(item => (
              <Button key={item.key} type={item.type} style={{padding: 0}} onClick={item.onClick}>
                {item.title}
              </Button>
            ))}
          </Space>
        )
      },
    },
  ]

  return (
    <>
      <Breadcrumb
        path={['首页', '财务管理', '物品管理']}
        right={
          <Space>
            {renderOperation({}).map(item => (
              <Button key={item.key} type={item.type} size={'middle'} onClick={item.onClick}>
                {item.title}
              </Button>
            ))}
          </Space>
        }
      />
      <Tabs onChange={key => setTabKey(key)} tabBarStyle={{backgroundColor: '#fff', paddingLeft: '16px'}}>
        <TabPane tab={'物品列表'} key={'1'}>
          <div className={styles.formSection}>
            <Form
              form={goodsForm}
              onFinish={formValues => {
                const {updateTime} = formValues
                if (updateTime) {
                  const [settingDateBegin, settingDateEnd] = updateTime.map(item => moment(item).format('YYYY-MM-DD'))
                  setGoodsCond(prevState => ({...prevState, ...formValues, settingDateBegin, settingDateEnd}))
                } else {
                  setGoodsCond(prevState => ({...prevState, ...formValues, settingDateBegin: '', settingDateEnd: ''}))
                }
              }}
            >
              <Row>
                <Col span={7}>
                  <Form.Item name={'name'} label={'物品'}>
                    <Input placeholder={'请输入物品设备名称'} allowClear />
                  </Form.Item>
                </Col>
                <Col span={7} offset={1}>
                  <Form.Item name={'warehouseAddress'} label={'地址'}>
                    <Input placeholder={'请输入所在地址'} allowClear />
                  </Form.Item>
                </Col>
                <Col span={7} offset={1}>
                  <Form.Item name={'warehouseName'} label={'位置'}>
                    <Select placeholder={'请选择'} allowClear>
                      {[{name: '房源', warehouseId: '房源'} as LessorWarehouseVO, ...warehouseList].map(item => (
                        <Option key={item.warehouseId} value={item.name}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={7}>
                  <Form.Item name={'warehouseType'} label={'状态'}>
                    <Select placeholder={'请选择'} allowClear>
                      {lessorMaterialTypeArr.map(item => (
                        <Option key={item.id} value={item.id}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={7} offset={1}>
                  <Form.Item name={'updateTime'} label={'配置日期'}>
                    <RangePicker allowClear />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify={'end'}>
                <Space>
                  <Button type={'primary'} htmlType={'submit'}>
                    查询
                  </Button>
                  <Button
                    onClick={() => {
                      goodsForm.resetFields()
                      setGoodsCond({pageNum: 1, pageSize: 10})
                    }}
                  >
                    重置
                  </Button>
                </Space>
              </Row>
            </Form>
          </div>
          <div className={styles.tableSection}>
            <Table
              scroll={{x: 'max-content'}}
              rowKey={'materialId'}
              columns={tableColumns}
              dataSource={goodsList}
              loading={materialListLoading}
              pagination={{
                total: goodsTotal,
                current: goodsCond.pageNum,
                pageSize: goodsCond.pageSize,
                position: ['bottomCenter'],
                showSizeChanger: true,
                onChange: (pageNum, pageSize) =>
                  setGoodsCond(prevState => ({
                    ...prevState,
                    pageNum: prevState.pageSize === pageSize ? pageNum : 1,
                    pageSize,
                  })),
              }}
            />
          </div>
        </TabPane>
        <TabPane tab={'仓库'} key={'2'}>
          <div className={styles.formSection}>
            <Form
              form={storeForm}
              onFinish={formValues => {
                const {updateTime} = formValues
                if (updateTime) {
                  const [settingDateBegin, settingDateEnd] = updateTime.map(item => moment(item).format('YYYY-MM-DD'))
                  setStoreGoodsCond(prevState => ({...prevState, ...formValues, settingDateBegin, settingDateEnd}))
                } else {
                  setStoreGoodsCond(prevState => ({
                    ...prevState,
                    ...formValues,
                    settingDateBegin: '',
                    settingDateEnd: '',
                  }))
                }
              }}
            >
              <Row>
                <Col span={7}>
                  <Form.Item name={'name'} label={'物品'}>
                    <Input placeholder={'请输入物品设备名称'} />
                  </Form.Item>
                </Col>
                <Col span={7} offset={1}>
                  <Form.Item name={'warehouseId'} label={'仓库'}>
                    <Select placeholder={'请选择'} allowClear>
                      {warehouseList.map(item => (
                        <Option key={item.warehouseId} value={item.warehouseId}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={7} offset={1}>
                  <Form.Item name={'updateTime'} label={'配置日期'}>
                    <RangePicker />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify={'end'}>
                <Space>
                  <Button type={'primary'} htmlType={'submit'}>
                    查询
                  </Button>
                  <Button
                    onClick={() => {
                      storeForm.resetFields()
                      setStoreGoodsCond({pageNum: 1, pageSize: 10, warehouseType: '1'})
                    }}
                  >
                    重置
                  </Button>
                </Space>
              </Row>
            </Form>
          </div>
          <div className={styles.tableSection}>
            <Table
              scroll={{x: 'max-content'}}
              rowKey={'materialId'}
              columns={tableColumns}
              dataSource={storeGoodsList}
              loading={materialListLoading}
              pagination={{
                total: storeGoodsTotal,
                current: storeGoodsCond.pageNum,
                pageSize: storeGoodsCond.pageSize,
                position: ['bottomCenter'],
                showSizeChanger: true,
                onChange: (pageNum, pageSize) =>
                  setStoreGoodsCond(prevState => ({
                    ...prevState,
                    pageNum: prevState.pageSize === pageSize ? pageNum : 1,
                    pageSize,
                  })),
              }}
            />
          </div>
        </TabPane>
      </Tabs>

      {/* 新增物品 || 调拨 */}
      <GoodsAddOperation
        operationType={showAddOperation}
        materialId={curMaterialId}
        warehouseList={warehouseList}
        onCancel={() => {
          setCurMaterialId('')
          setShowAddOperation(undefined)
        }}
        onFinish={() => {
          setCurMaterialId('')
          setShowAddOperation(undefined)
          queryMaterialList(goodsCond, '1')
          queryMaterialList(storeGoodsCond, '2')
        }}
      />

      {/* 新增仓库 */}
      <GoodsAddStore
        showAddStore={showAddStore}
        onCancel={() => setShowAddStore(false)}
        onFinish={() => {
          setShowAddStore(false)
          queryWarehouseList()
        }}
      />
    </>
  )
}
