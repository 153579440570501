import React, {useCallback, useEffect, useState} from 'react'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import {Badge, Button, Descriptions, Form, message, Modal, Row, Space, Table, Tabs} from 'antd'
import nygDeviceStyle from '@/pages/nygDevice/device.module.less'
import DeviceTableHeader from '@/pages/device/components/DeviceTableHeader'
import NygDeviceTimeForm from '@/pages/nygDevice/components/NygDeviceTimeForm'
import NygDeviceWaterElectric from '@/pages/nygDevice/components/NygDeviceWaterElectric'
import DeviceYunDing from '@/pages/device/components/DeviceYunDing'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {useAppLocation} from '@/common/hooks/useRouter'
import {deviceModel} from '@/store/models/Device'
import {downloadBlob} from '@/utils/util'

export default function NygDeviceElectricDetail() {
  const location = useAppLocation<'/nyg-device-electric/detail'>()
  const deviceId = location.state?.deviceId

  const [meterInfo, setMeterInfo] = useState({} as EleMeter)
  const [eleMeterLoading, setEleMeterLoading] = useState(false)

  const queryEleMeterInfo = useCallback(() => {
    deviceModel.deviceV2EleMeterInfo({eleMeterId: deviceId}).then(({response: {data}}) => setMeterInfo(data))
  }, [deviceId])

  useEffect(() => {
    queryEleMeterInfo()
  }, [queryEleMeterInfo])

  const [form] = Form.useForm()
  const [total, setTotal] = useState(0)
  const [recordList, setRecordList] = useState<DeviceV2MeterRecordVO[]>([])

  const queryEleMeterRecord = useCallback(() => {
    const formValues = form.getFieldsValue(true)
    deviceModel.deviceV2EleMeterRecord({deviceId, ...formValues}).then(({response: {data, total}}) => {
      setTotal(total)
      setRecordList(data)
    })
  }, [deviceId, form])

  useEffect(() => {
    queryEleMeterRecord()
  }, [queryEleMeterRecord])

  const meterForce = useCallback(
    async (valveForce: -1 | 1 | 0) => {
      await deviceModel.deviceV2EleMeterForce({eleMeterId: deviceId, valveForce})
      message.success('操作成功！')
      queryEleMeterInfo()
    },
    [deviceId, queryEleMeterInfo]
  )

  const recordLoading = deviceModel.deviceV2EleMeterRecord.useLoading()
  const readLoading = deviceModel.deviceV2EleMeterRead.useLoading()
  const forceLoading = deviceModel.deviceV2EleMeterForce.useLoadings()

  return (
    <div>
      <Breadcrumb path={['首页', '智能设备', '电表']} />
      <div className={nygDeviceStyle.deviceSection}>
        <Row>
          <DeviceYunDing />
          <Descriptions
            title={'牛油果智能电表'}
            column={5}
            labelStyle={{color: 'rgba(0, 0, 0, 0.45)'}}
            style={{margin: '0 50px 0 25px', width: '70%'}}
          >
            <Descriptions.Item
              label={
                <Space size={0}>
                  <Badge status={meterInfo.status === 0 ? 'warning' : 'success'} />
                  电表状态
                </Space>
              }
            >
              {meterInfo.status === 0 ? '离线' : '在线'}
            </Descriptions.Item>
            <Descriptions.Item label={'电费价格'}>{meterInfo.elePrice}元/千瓦时</Descriptions.Item>
            <Descriptions.Item label={'水电余额'}>{meterInfo.balance}</Descriptions.Item>
            <Descriptions.Item label={'控制状态'} span={2}>
              <Space size={0}>
                <Button
                  size={'small'}
                  type={meterInfo.valveForce === -1 ? 'primary' : 'default'}
                  onClick={() => {
                    Modal.confirm({
                      title: '电表',
                      icon: null,
                      closable: true,
                      centered: true,
                      content: '开启自动控制后，设备将根据水电余额剩余余额自动启停用电表，是否确认开启自动控制状态？',
                      onOk: () => meterForce(-1),
                    })
                  }}
                  loading={forceLoading.pick(-1)}
                >
                  自动
                </Button>
                <Button
                  size={'small'}
                  type={meterInfo.valveForce === 1 ? 'primary' : 'default'}
                  onClick={() => {
                    Modal.confirm({
                      title: '电表',
                      icon: null,
                      closable: true,
                      centered: true,
                      content: '开启强制通电后设备将持续通电，欠费时不触发自动断电，是否确认开启强制通电状态？',
                      onOk: () => meterForce(1),
                    })
                  }}
                  loading={forceLoading.pick(1)}
                  style={{borderLeftWidth: 0, borderRightWidth: 0}}
                >
                  强制通电
                </Button>
                <Button
                  size={'small'}
                  type={meterInfo.valveForce === 0 ? 'primary' : 'default'}
                  onClick={() => {
                    Modal.confirm({
                      title: '电表',
                      icon: null,
                      closable: true,
                      centered: true,
                      content: '开启强制断电后设备将立刻断电，需要手动更改才可通电，是否确认开启强制断电状态？',
                      onOk: () => meterForce(0),
                    })
                  }}
                  loading={forceLoading.pick(0)}
                >
                  强制断电
                </Button>
              </Space>
            </Descriptions.Item>
            <Descriptions.Item label={'承租人'}>{meterInfo.lesseeName ?? '/'}</Descriptions.Item>
            <Descriptions.Item label={'手机号'}>{meterInfo.lesseeMobile ?? '/'}</Descriptions.Item>
            <Descriptions.Item label={'房源地址'}>{meterInfo.propertyTitle}</Descriptions.Item>
          </Descriptions>
        </Row>
        <Row justify={'end'} style={{marginTop: '20px'}}>
          <Button
            loading={readLoading}
            onClick={async () => {
              await deviceModel.deviceV2EleMeterRead({eleMeterId: deviceId})
              message.success('操作成功！')
              queryEleMeterRecord()
            }}
          >
            更新抄表
          </Button>
        </Row>
      </div>
      <Tabs tabBarStyle={{margin: 0, backgroundColor: 'white', paddingLeft: '20px'}}>
        <Tabs.TabPane tab={'用电记录'} key={'1'}>
          <NygDeviceTimeForm
            form={form}
            label={'抄表时间'}
            onFinish={queryEleMeterRecord}
            onReset={queryEleMeterRecord}
            onExport={() => {
              const formValues = form.getFieldsValue(true)
              setEleMeterLoading(true)
              downloadBlob({
                url: '/device/v2/ele-meter/record-excel',
                body: {...formValues, pageable: false, deviceId},
              }).finally(() => {
                setEleMeterLoading(false)
              })
            }}
            exportLoading={eleMeterLoading}
          />
          <div className={nygDeviceStyle.tableSection}>
            <DeviceTableHeader total={total} />
            <Table
              rowKey={'recordId'}
              columns={[
                {title: '抄表ID', dataIndex: 'recordId'},
                {title: '抄表时间', dataIndex: 'insertTime'},
                {title: '抄表人', dataIndex: 'operator'},
                {title: '抄表类型', dataIndex: 'operateDesc'},
                {title: '本次用电量（千瓦时）', dataIndex: 'dataDiff'},
                {title: '表读数', dataIndex: 'dataNew'},
              ]}
              dataSource={recordList}
              loading={recordLoading}
              pagination={getPaginationProps(form, total)}
            />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab={'水电余额记录'} key={'2'}>
          <NygDeviceWaterElectric propertyCode={meterInfo.propertyCode} propertyTitle={meterInfo.propertyTitle} />
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}
