import {propertyModel} from '@/store/models/Property'
import {contractModel} from '@/store/models/Contract'
import {Button, ButtonProps, message} from 'antd'
import React from 'react'
import {isProjectCondo} from '@/common/bizHooks/isProjectCondo'

export async function checkContractAudit(args: {
  communityCode: string
  rentType: string
  contractId?: string // contractId 和 propertyCode 二选一
  propertyCode?: string // contractId 和 propertyCode 二选一，租客登记用
}) {
  const {communityCode, contractId, propertyCode, rentType} = args
  if (rentType === '3' && isProjectCondo()) {
    const {
      response: {data: data1},
    } = await propertyModel.condoProjectGetConfigByCondoId({condoId: communityCode})
    const project: CondoProjectVO = data1?.project
    if (project && (contractId || propertyCode)) {
      const {
        response: {data: data2},
      } = await contractModel.contractAuditGetAuditOrPass({contractId, propertyCode})
      return {r1: project, r2: data2}
    }
    return {r1: project, r2: null}
  }
  return {}
}

interface AuditButtonProps extends Omit<ButtonProps, 'onClick'> {
  communityCode: string
  rentType: string
  contractId?: string // contractId 和 propertyCode 二选一
  propertyCode?: string // contractId 和 propertyCode 二选一
  onReject?: () => void
  onOk?: () => void
  preCheck?: () => void
  onClick: (event: React.MouseEvent<HTMLElement>, condoProject?: boolean) => void
}

// propertyCode 全部改为 communityCode(与 condoId 通用)
export function AuditButton(props: AuditButtonProps) {
  const {communityCode, contractId, propertyCode, rentType, preCheck, onReject, onOk, ...rest} = props
  return (
    <Button
      {...rest}
      onClick={async event => {
        await preCheck?.()
        const {r1, r2} = await checkContractAudit({communityCode, rentType, contractId, propertyCode})
        if (r1) {
          switch (r2?.auditStatus) {
            case '1': {
              // 审核中
              return message.warning(`'${r2?.auditTypeName}'正在审核中`)
            }
            case '2': {
              if (onOk) {
                onOk()
              } else props.onClick?.(event, true)
              break
            }
            case '3': {
              onReject?.()
              break
            }
            default: {
              if (onReject) {
                onReject()
              } else props.onClick?.(event, true)
            }
          }
        } else {
          props.onClick?.(event, false)
        }
      }}
    />
  )
}
