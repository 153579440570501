import React, {useEffect, useState} from 'react'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import styles from './index.module.less'
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Typography,
} from 'antd'
import {SwapRightOutlined, WarningFilled} from '@ant-design/icons'
import {contractModel} from '@/store/models/Contract'
import {rules} from '@/utils/validate'
import moment from 'moment'
import dayjs from 'dayjs'
import {useCodeArraySync} from '@/store/models/Common'
import {useProjectConfig} from '@/common/bizHooks/useIsProjectCondo'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {propertyModel} from '@/store/models/Property'

const {Option} = Select

export const ReserveBar = React.memo(function (props: {label: string; top?: number; bottom?: number}) {
  const {label, top = 30, bottom = 30} = props
  return (
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', margin: `${top}px 0 ${bottom}px`}}>
      <div style={{backgroundColor: '#007BFF', width: '6px', height: '14px'}} />
      <h3 style={{margin: '0 5px'}}>{label}</h3>
    </div>
  )
})

const ResFormat = 'YYYY-MM-DD'

// const ResTimeCode = {'1': [1, 'month'], '2': [3, 'month'], '3': [6, 'month'], '4': [1, 'year']}

const monthArr = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]

function leasePeriodVerify({contractBegin, contractEnd}: {contractBegin: string; contractEnd: string}) {
  let isNotWholeMonth = false
  if (contractBegin && contractEnd) {
    const beginDay = dayjs(contractBegin)
    const endDay = dayjs(contractEnd)
    isNotWholeMonth = !beginDay
      .set('year', endDay.year())
      .set('month', endDay.month() + (beginDay.get('date') === 1 ? 1 : 0))
      .subtract(1, 'day')
      .isSame(endDay, 'day')
  }
  return isNotWholeMonth
}

export default function ContractReserve() {
  const history = useAppHistory()
  const location = useAppLocation<'/contract/reserve'>()

  const propertyCode = location.state?.propertyCode
  const communityName = location.state?.communityName
  const rentType = location.state?.rentType
  const rentPrice = location.state?.rentPrice
  const communityCode = location.state?.communityCode
  const from = location.state?.from

  const rentPayType = location.state?.rentPayType
  const rentPledgeType = location.state?.rentPledgeType
  const rentCollectType = location.state?.rentCollectType
  const rentCollectDay = location.state?.rentCollectDay

  const [form] = Form.useForm()

  const contractBrokerage = useCodeArraySync('contract.brokerage')
  // 集中式房源显示获客渠道
  // const showBrokerage = rentType === '3'
  const isBrokerageDisabled = () => false
  // 中介或者分销，显示输入框
  const showBrokerageInput = () => ['1', '2'].includes(form.getFieldValue('brokerageType'))

  const subLoading = contractModel.contractReserveMakeV2.useLoading()

  const [tip, setTip] = useState(false)
  const [payWays, setPayWays] = useState<{id: string; label: string}[]>([])

  useEffect(() => {
    propertyModel
      .projectConfig({condoId: communityCode})
      .then(({response: {data}}) =>
        setPayWays(data.payConfigList.map(item => ({id: item.payWay, label: item.payWayName})))
      )
  }, [communityCode])

  // const [condoProject, setCondoProject] = useState<CondoProjectVO>(null)
  //
  // useEffect(() => {
  //   // 只有公寓才有项目配置
  //   if (rentType === '3') {
  //     propertyModel.condoProjectGetProjectByPropertyCode({propertyCode}).then(({response: {data}}) => {
  //       setCondoProject(data)
  //       if (data) {
  //         form.setFieldsValue({reservePrice: data.reserveAmt})
  //       }
  //     })
  //   }
  // }, [form, propertyCode, rentType])

  const [projectConfig, isProjectCondoType] = useProjectConfig({rentType, propertyCode})

  // const isProjectCondo = useIsProjectCondo()
  // const isProjectCondoType = rentType === '3' && isProjectCondo
  // const [projectConfig, setProjectConfig] = useState({} as CondoProjectConfigAssemblyVO)
  const condoProject: CondoProjectVO = projectConfig.project

  // 新增：付 押；押金 字段
  useEffect(() => {
    if (isProjectCondoType) {
      form.setFieldsValue({
        reservePrice: condoProject?.reserveAmt,
        rentPledgeType: condoProject?.rentPledgeType,
        rentPayType: condoProject?.rentPayType,
      })
    }
  }, [condoProject, form, isProjectCondoType])

  // useEffect(() => {
  //   if (isProjectCondoType && propertyCode) {
  //     propertyModel.propertyDetail({propertyCode}).then(({response: {data: property}}) => {
  //       propertyModel.condoProjectGetConfigByCondoId({condoId: property.condoId}).then(({response: {data}}) => {
  //         setProjectConfig(data)
  //       })
  //     })
  //   }
  // }, [isProjectCondoType, propertyCode])

  //快捷日期选择
  function selectTime(e) {
    const value = e.target.value
    const contractBegin = form.getFieldValue('contractBegin')
    const begin = dayjs(contractBegin || new Date())
    const end = dayjs(begin).add(value, 'month').subtract(1, 'day')
    form.setFieldsValue({contractBegin: begin, contractEnd: end})
  }

  return (
    <>
      <div className={styles.breadcrumb}>
        <Breadcrumb path={['租约管理', '租约详情', '房源预定']} />
        <h2>{from === 'ahead-reverse' ? '超前预定' : '新签预定'}</h2>
      </div>

      <div className={styles.body}>
        <ReserveBar label={'房源信息'} top={34} bottom={15} />
        <span style={{color: '#FF4242'}}>
          *<span style={{color: 'rgba(0, 0, 0, 0.65)'}}>房源地址：{communityName}</span>
        </span>

        <ReserveBar label={'预定信息'} />
        <Form
          form={form}
          // labelCol={{span: 5}}
          labelCol={{flex: '100px'}}
          labelAlign={'left'}
          initialValues={
            isProjectCondoType ? {rentPrice} : {rentPrice, rentPayType, rentPledgeType, rentCollectType, rentCollectDay}
          }
          onFinish={async formValues => {
            let {contractBegin, contractEnd, reserveBillPayTime, reserveTime} = formValues
            if (leasePeriodVerify({contractBegin, contractEnd})) return message.warning('约定租期非整月，不可确认预定')
            const {reserveBillPayWay, reservePricePaid} = formValues
            contractBegin = moment(contractBegin).format(ResFormat)
            contractEnd = moment(contractEnd).format(ResFormat)
            reserveBillPayTime = moment(reserveBillPayTime).format(ResFormat)
            reserveTime = moment(reserveTime).format(ResFormat)
            const params = {
              ...formValues,
              reserveTime,
              contractEnd,
              contractBegin,
              reserveBillPayTime: reservePricePaid === '1' ? reserveBillPayTime : undefined,
              reserveBillPayWay: reservePricePaid === '1' ? reserveBillPayWay : undefined,
              propertyCode,
              reserveType: from === 'ahead-reverse' ? '2' : '1',
            }
            // console.log('params', params)
            await contractModel.contractReserveMakeV2(params)
            message.success('预定成功！')
            history.goBack()
          }}
        >
          <Row>
            <Col span={12}>
              <Form.Item name={'lesseeCertName'} label={'姓名'} rules={rules.lesseeCertName}>
                <Input placeholder={'请输入'} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item name={'lesseeMobile'} label={'手机号'} rules={rules.lesseeMobile}>
                <Input placeholder={'请输入'} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item name={'lesseeCertNo'} label={'身份证号'} rules={rules.lesseeCertNo}>
                <Input placeholder={'请输入'} />
              </Form.Item>
            </Col>
          </Row>

          {condoProject?.displayTag5 === 1 && (
            <Form.Item noStyle shouldUpdate>
              {() => {
                // 新增和预定时候必填
                const isDisabled = isBrokerageDisabled()
                const showInput = showBrokerageInput()
                return (
                  <>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          name={'brokerageType'}
                          label={'获客渠道'}
                          rules={[{required: true, message: '获客渠道不能为空'}]}
                        >
                          <Select placeholder={'请选择'} disabled={isDisabled}>
                            {contractBrokerage.map(value => (
                              <Select.Option key={value.id} value={value.id}>
                                {value.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    {showInput && (
                      <Row>
                        <Col span={12}>
                          <Form.Item name={'brokerage'} label={'佣金'} rules={rules.brokerage}>
                            <InputNumber placeholder={'请输入'} />
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                  </>
                )
              }}
            </Form.Item>
          )}

          <Row>
            <Col span={12}>
              <Form.Item name={'reservePrice'} label={'预定金额'} rules={[{required: true, message: '请输入预定金额'}]}>
                <InputNumber placeholder={'请输入'} disabled={isProjectCondoType} style={{width: '100%'}} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                name={'reservePricePaid'}
                label={'收款状态'}
                rules={[{required: true, message: '请选择收款状态'}]}
              >
                <Select placeholder={'请选择'}>
                  <Option value={'0'}>未收款</Option>
                  <Option value={'1'}>已收款</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item noStyle shouldUpdate>
            {() =>
              form.getFieldValue('reservePricePaid') === '1' && (
                <>
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        name={'reserveBillPayWay'}
                        label={'收款方式'}
                        rules={[{required: true, message: '请选择收款方式'}]}
                      >
                        <Select placeholder={'请选择'}>
                          {payWays.map(item => (
                            <Option key={item.id} value={item.id}>
                              {item.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        name={'reserveBillPayTime'}
                        label={'收款日期'}
                        rules={[{required: true, message: '请选择收款日期'}]}
                      >
                        <DatePicker style={{width: '100%'}} />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )
            }
          </Form.Item>

          <ReserveBar label={'约定信息'} />
          {/*<Row>*/}
          {/*  <Col span={12}>*/}
          {/*    <Form.Item*/}
          {/*      name={'reserveTime'}*/}
          {/*      label={'最晚签约日'}*/}
          {/*      rules={[{required: true, message: '请选择最晚签约日'}]}*/}
          {/*    >*/}
          {/*      <DatePicker style={{width: '100%'}} />*/}
          {/*    </Form.Item>*/}
          {/*  </Col>*/}
          {/*</Row>*/}
          <Row>
            <Col span={12}>
              <Form.Item label={'房屋租金'} required>
                <Space>
                  <Form.Item name={'rentPrice'} noStyle rules={rules.rentPrice}>
                    <InputNumber placeholder={'请输入'} disabled={isProjectCondoType} />
                  </Form.Item>
                  <span>元/月</span>
                </Space>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                noStyle
                shouldUpdate={(prev, next) =>
                  prev.contractBegin !== next.contractBegin || prev.contractEnd !== next.contractEnd
                }
              >
                {f => {
                  const contractBegin = f.getFieldValue('contractBegin')
                  const contractEnd = f.getFieldValue('contractEnd')
                  const isNotWholeMonth = leasePeriodVerify({contractBegin, contractEnd})
                  return (
                    <>
                      <Form.Item
                        label={'约定租期'}
                        // labelCol={{span: 10}}
                        required
                        style={isNotWholeMonth ? {marginBottom: 0} : undefined}
                      >
                        <Space size={12}>
                          <Form.Item
                            name={'contractBegin'}
                            noStyle
                            rules={[{required: true, message: '请选择租约开始日期'}]}
                          >
                            <DatePicker
                              disabledDate={current => {
                                const contractEnd = form.getFieldValue('contractEnd')
                                const contractBeginDay = condoProject?.contractBeginDay
                                  ? dayjs().add(condoProject.contractBeginDay - 1, 'day')
                                  : contractEnd
                                if (current >= contractBeginDay) return true
                                return dayjs().add(-1, 'day') >= current
                              }}
                              style={{width: '100%'}}
                              placeholder={'开始日期'}
                            />
                          </Form.Item>
                          <SwapRightOutlined style={{color: 'rgba(0, 0, 0, 0.25)'}} />
                          <Form.Item
                            name={'contractEnd'}
                            noStyle
                            rules={[{required: true, message: '请选择租约结束日期'}]}
                          >
                            <DatePicker
                              style={{width: '100%'}}
                              disabledDate={current => {
                                const contractBegin = form.getFieldValue('contractBegin')
                                return contractBegin && current < contractBegin
                              }}
                              placeholder={'结束日期'}
                              disabled
                            />
                          </Form.Item>
                          <Form.Item name={'month'} noStyle>
                            <Radio.Group onChange={selectTime}>
                              <Radio.Button value={1}>1个月</Radio.Button>
                              <Radio.Button value={3}>3个月</Radio.Button>
                              <Radio.Button value={6}>6个月</Radio.Button>
                              <Radio.Button value={12}>1年</Radio.Button>
                            </Radio.Group>
                          </Form.Item>
                          <Form.Item name={'month'} noStyle>
                            <Select
                              allowClear
                              style={{minWidth: '100px'}}
                              onSelect={(value: number) => {
                                const begin = dayjs(contractBegin || new Date())
                                const end = dayjs(begin).add(value, 'month').subtract(1, 'day')
                                f.setFieldsValue({contractBegin: begin, contractEnd: end})
                              }}
                            >
                              {monthArr.map(item => (
                                <Option key={item} value={item}>
                                  {item}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                          月
                        </Space>
                      </Form.Item>
                      {isNotWholeMonth && (
                        <Form.Item label={' '} style={{marginBottom: 0}} colon={false}>
                          <div style={{color: 'red'}}>
                            <WarningFilled />
                            <span> 请注意，当前所填出租周期非整月</span>
                          </div>
                        </Form.Item>
                      )}
                    </>
                  )
                }}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item label={'付款方式'} style={{marginBottom: 0}} required>
                <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                  <Form.Item
                    name={'rentPayType'}
                    label={'付'}
                    required={false}
                    rules={[{required: true, message: '请选择付款方式'}]}
                  >
                    <Select allowClear placeholder={'请选择'} style={{minWidth: '120px'}}>
                      {Array.from(new Array(24).keys()).map(item => (
                        <Option key={item + 1} value={item + 1}>
                          {item + 1}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name={'rentPledgeType'}
                    label={'押'}
                    required={false}
                    rules={[{required: true, message: '请选择付款方式'}]}
                    style={{marginLeft: '24px'}}
                  >
                    <Select
                      allowClear
                      placeholder={'请选择'}
                      style={{minWidth: '120px'}}
                      // onSelect={(value: number) => {
                      //   if (form.getFieldValue('rentPrice')) {
                      //     form.setFieldsValue({rentPledge: form.getFieldValue('rentPrice') * value})
                      //   }
                      // }}
                    >
                      {Array.from(new Array(13).keys())
                        .filter(value => (isProjectCondoType ? value !== 0 : true))
                        .map(item => (
                          <Option key={item} value={item}>
                            {item === 0 ? '自定义' : item}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item name={'contractRemark'} label={'备注信息'}>
                <Input.TextArea placeholder={'请输入备注信息，输入后租客可见'} maxLength={100} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item label={' '} colon={false}>
                <Form.Item
                  name={'check'}
                  colon={false}
                  noStyle
                  rules={[{required: true, message: '请勾选《预定须知》再发起预定'}]}
                >
                  <Checkbox.Group>
                    <Checkbox value={'A'}>我已阅读并知晓</Checkbox>
                    <Button type={'link'} style={{padding: 0}} onClick={() => setTip(true)}>
                      《预定须知》
                    </Button>
                  </Checkbox.Group>
                </Form.Item>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item label={' '} colon={false} style={{marginTop: '20px'}}>
                <Space>
                  <Button type={'primary'} htmlType={'submit'} loading={subLoading}>
                    确认预定
                  </Button>
                  <Button onClick={() => history.goBack()}>返回</Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Modal visible={tip} centered title={'预定须知'} onOk={() => setTip(false)} onCancel={() => setTip(false)}>
          <Typography>1、您发起预定后，您的租客可在牛油果管家小程序/牛油果管家APP支付对应定金。</Typography>
          <Typography>
            2、您发起预定后，您的租客未在最晚签约日内支付对应定金的预定租约。您的租客无法在牛油果管家小程序/牛油果管家APP查看已失效的预定租约。
          </Typography>
          <Typography>
            3、您发起预定后，您可在您的租客未支付定金前删除该预定单。您的租客无法在牛油果管家小程序/牛油果管家APP查看已删除的失效预定单。
          </Typography>
          <Typography>
            4、若您的租客已支付定金、但未在最晚签约日前与您就该预定房源签署租赁协议的，您有权不予退还租客已支付的定金。
          </Typography>
          <Typography>
            5、您确认，您对预定租约及定金的各项行为（包括但不限于发起、删除、撤销预定单，收取、退还、赔付定金等）均由您本人自主操作，由此引发的一切后果均由您自行承担。牛油果房东不就此承担任何法律责任。
          </Typography>
        </Modal>
      </div>
    </>
  )
}
