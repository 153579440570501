import {Model} from '@redux-model/web'
import {$api} from '@/store/service'

interface StewardModel {}

class Steward extends Model<StewardModel> {
  protected initReducer(): StewardModel {
    return {}
  }

  // 管家服务单-列表
  stewardList = $api.action((params: Partial<StewardOrderQueryDTO>) =>
    this.post<ApiResponse<StewardOrderQueryVO[]>>('/steward/list').body(params)
  )

  // 管家服务单-详情
  stewardDetail = $api.action((params: {orderId: string}) =>
    this.post<ApiResponse<StewardOrderQueryVO>>('/steward/detail' as const).body(params)
  )

  // 管家服务单-取消订单
  stewardCancel = $api.action((params: {orderId: string; cancelType: string; cancelReason: string}) =>
    this.post('/steward/cancel').body(params)
  )

  // 管家服务单-评价
  stewardRemark = $api.action((params: {orderId: string; remarkScore: string; remarkResult: string}) =>
    this.post('/steward/remark').body(params)
  )

  // 首页管家服务统计看板
  stewardOrderStatistics = $api.action(() =>
    this.post<ApiResponse<StewardOrderStatisticVO>>('/steward/order/statistics').body({})
  )

  //管家服务单-总计
  stewardListTotal = $api.action((args: Partial<StewardOrderQueryDTO>) =>
    this.post<ApiResponse<StewardListTotal>>('/steward/list-total').body({
      pageNum: 1,
      pageSize: 10,
      pageable: false,
      ...args,
    })
  )

  // 查询组织账户余额
  teamBalance = $api.action((args: {teamPid: string}) => this.post<ApiResponse>('/team/balance').body(args))

  // 扣款记录列表
  teamBalanceRecordList = $api.action((args: Partial<TeamWalletRecordQueryDTO>) =>
    this.post<ApiResponse<TeamWalletRecordVO[]>>('/team/balance/record/list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // 充值记录列表
  teamBalanceChargeList = $api.action((args: Partial<TeamWalletChargeQueryDTO>) =>
    this.post<ApiResponse<TeamBalanceChargeListVO[]>>('/team/balance/charge/list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // 保修卡详情  |  object:{orderId:服务单id(保修卡id)}
  stewardOrderWarrantyDetail = $api.action((args: {orderId: string}) =>
    this.post<ApiResponse<StewardOrderWarrantyVO>>('/steward/order/warranty/detail').body(args)
  )
}

export const stewardModel = new Steward()
