import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {
  Badge,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Rate,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Typography,
} from 'antd'
import {stewardModel} from '@/store/models/Steward'
import {ButtonType} from 'antd/lib/button/button'
import {useCodeArraySync} from '@/store/models/Common'
import {userModel} from '@/store/models/User'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import {downloadBlob} from '@/utils/util'
import {hasPermission, Permission, usePermission} from '@/ui/components/Permission'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'

const {Option} = Select
// const {TabPane} = Tabs
const {RangePicker} = DatePicker

const orderStatusStyle = {
  '1': 'orange',
  '2': 'blue',
  '3': 'volcano',
  '4': 'cyan',
  '5': 'green',
  '6': 'gray',
  '7': 'lime',
  '96': 'green',
  '97': 'green',
  '98': 'green',
  '99': 'green',
}

const orderStatusType = {
  '1': '待沟通',
  '2': '已沟通',
  '3': '上门中',
  '4': '服务中',
  '5': '已完成',
  '6': '已取消',
  '7': '待支付',
  '96': '已评价',
  '97': '上门超时',
  '98': '沟通超时',
  '99': '待评价',
}

export default function StewardServiceList() {
  const history = useAppHistory()
  const location = useAppLocation<'/steward'>()
  const orderStatus = location.state?.orderStatus

  // 取消服务单原因
  const cancelReasonList = useCodeArraySync('steward.cancelType')
  // 所属类目
  const statusList = useCodeArraySync('steward.orderStatus', {defaultItem: true})
  // console.log('statusList', statusList)
  const orderStatusList = useMemo(() => statusList.filter(value => !['7', '96'].includes(value.id)), [statusList])
  // 用户id
  const userId = userModel.useData(data => data?.userInfo?.userId)

  const [auth] = usePermission()

  const loading = stewardModel.stewardList.useLoading()
  const [form] = Form.useForm()
  const [params, setParams] = useState<Partial<StewardOrderQueryDTO>>({
    pageNum: 1,
    pageSize: 10,
    sortType: '2',
    pageable: true,
    orderStatus: '',
  })
  const [tableList, setTableList] = useState<StewardOrderQueryVO[]>([])
  const [totals, setTotals] = useState(0)

  // 管家
  const [steward, setSteward] = useState({} as {show: boolean; name: string; mobile: string})

  // 取消服务单表单
  const [steForm] = Form.useForm()
  const [showForm, setShowForm] = useState(false)

  // 服务单评价
  const [steRemark, setSteRemark] = useState({remarkScore: '5'} as {
    show: boolean
    orderId: string
    remarkScore: string
    remarkResult: string
  })

  useEffect(() => {
    if (orderStatus) {
      setParams(state => ({...state, orderStatus}))
    }
  }, [orderStatus])

  const queryStewardList = useCallback(function (param) {
    stewardModel.stewardList(param).then(({response: {data, total}}) => {
      setTotals(total)
      setTableList(
        data.map(item => ({
          ...item,
          orderMaker: item.orderMakerType === 'lessor' ? item.lessorName : item.lesseeName,
          orderCategory: [item.categoryFirName, item.categorySecName, item.categoryThiName]
            .filter(item => item)
            .join('-'),
        }))
      )
    })
  }, [])

  useEffect(
    function () {
      queryStewardList(params)
    },
    [params, queryStewardList]
  )

  const operateRender: {
    when: (StewardOrderQueryVO) => boolean
    title: string
    type: ButtonType
    ghost: boolean
    onClick: (StewardOrderQueryVO) => any
  }[] = [
    {
      when: (record: StewardOrderQueryVO) => hasPermission('005001002'),
      title: '查看详情',
      type: 'link',
      ghost: false,
      onClick: (record: StewardOrderQueryVO) => {
        history.push('/steward/detail', {orderId: record.orderId})
      },
    },
    {
      when: (record: StewardOrderQueryVO) => {
        return ['1', '2', '3', '4'].includes(record.orderStatus)
      },
      title: '联系管家',
      type: 'link',
      ghost: false,
      onClick: (record: StewardOrderQueryVO) => {
        setSteward({show: true, name: record.stewardName, mobile: record.stewardMobile})
      },
    },
    {
      when: (record: StewardOrderQueryVO) => {
        return ['99'].includes(record.orderStatus) && record.orderMakerType === 'lessor' && record.lessorId === userId
      },
      title: '去评价',
      type: 'link',
      ghost: false,
      onClick: (record: StewardOrderQueryVO) => {
        setSteRemark(prevState => ({...prevState, show: true, orderId: record.orderId}))
      },
    },
    {
      when: (record: StewardOrderQueryVO) => {
        return ['1', '2'].includes(record.orderStatus) && auth('010005001')
      },
      title: '取消服务',
      type: 'link',
      ghost: false,
      onClick: (record: StewardOrderQueryVO) => {
        steForm.setFieldsValue({orderId: record.orderId})
        setShowForm(true)
      },
    },
  ]

  return (
    <>
      <Breadcrumb
        path={['首页', '管家服务', '服务单列表']}
        right={
          <Permission code={'005001006'}>
            <Button onClick={() => downloadBlob({url: '/steward/list/excel', body: {...params, pageable: true}})}>
              导出
            </Button>
          </Permission>
        }
      />
      <div style={{padding: '25px', backgroundColor: '#ffffff'}}>
        <Form
          form={form}
          onFinish={values => {
            if (values.time) {
              // const result = values.time.map(item => item.format('YYYY-MM-DD 00:00:00'))
              const timeMakeBegin = values.time[0].format('YYYY-MM-DD 00:00:00')
              const timeMakeEnd = values.time[1].format('YYYY-MM-DD 23:59:59')
              setParams(prevState => ({
                ...prevState,
                ...values,
                timeMakeBegin,
                timeMakeEnd,
                pageNum: 1,
              }))
            } else {
              setParams(prevState => ({
                ...prevState,
                ...values,
                timeMakeBegin: '',
                timeMakeEnd: '',
                pageNum: 1,
              }))
            }
          }}
        >
          {/** 所属类目 */}
          <Form.Item label={'所属类目'} shouldUpdate style={{marginBottom: 0}}>
            {() => (
              <Form.Item noStyle style={{marginBottom: 0}}>
                {orderStatusList.map((item, idx) => (
                  <Button
                    key={idx}
                    type={params.orderStatus === item.id ? 'primary' : 'text'}
                    size={'small'}
                    onClick={() => {
                      setParams(prevState => ({
                        ...prevState,
                        orderStatus: item.id === '0' ? '' : item.id,
                        pageNum: 1,
                      }))
                    }}
                    style={{minWidth: '70px', margin: '0 10px'}}
                  >
                    {item.label}
                  </Button>
                ))}
              </Form.Item>
            )}
          </Form.Item>
          <Divider dashed style={{margin: '16px 0'}} />
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item name={'propertyAddr'} label={'服务地址'}>
                <Input placeholder={'小区/公寓/房间号'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'orderMakerName'} label={'下单人姓名'}>
                <Input placeholder={'请输入下单人姓名'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'orderMakerType'} label={'下单人类型'}>
                <Select placeholder={'请选择下单人类型'} allowClear style={{width: 180}}>
                  <Option value={'lessee'}>租客</Option>
                  <Option value={'lessor'}>房东</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'time'} label={'下单时间'} style={{marginBottom: 0}}>
                <RangePicker format={'YYYY-MM-DD'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'categorySearch'} label={'所属服务'} style={{minWidth: 220}}>
                <Input placeholder={'请输入服务名称'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'orderId'} label={'订单编号'} style={{minWidth: 220}}>
                <Input placeholder={'请输入订单编号'} allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button
                htmlType={'reset'}
                onClick={() => {
                  form.resetFields()
                  setParams({pageNum: 1, pageSize: 10, pageable: true, orderStatus: '', sortType: '2'})
                }}
              >
                重置
              </Button>
              <Button type={'primary'} style={{marginLeft: 10}} htmlType={'submit'}>
                查询
              </Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div style={{flex: 1, backgroundColor: '#fff', marginTop: 20, padding: '0 25px'}}>
        <Table
          rowKey={'orderId'}
          rowSelection={{
            fixed: true,
          }}
          scroll={{x: 1200}}
          title={() => (
            <Typography.Text>
              <Typography.Text strong style={{marginRight: '12px', marginLeft: '-16px'}}>
                查询结果
              </Typography.Text>
              共计
              <Typography.Text strong style={{color: '#017BFF'}}>
                {totals}
              </Typography.Text>
              条符合条件的结果
            </Typography.Text>
          )}
          columns={[
            {title: '订单编号', dataIndex: 'orderId', width: 240, align: 'center'},
            {
              title: '所属服务',
              dataIndex: 'orderCategory',
              key: 'orderCategory',
              width: 240,
              align: 'center',
              render: (value, render) => {
                return (
                  <Space size={9}>
                    <span>{value}</span>
                    {render.cleanTag === 1 && !!render.originOrderId && <Tag color={'blue'}>返工</Tag>}
                    {render.repairTag === 1 && <Tag color={'blue'}>返修</Tag>}
                  </Space>
                )
              },
            },
            {
              title: '下单人',
              dataIndex: 'orderMakerName',
              width: 100,
              align: 'center',
              // render: tags => {
              //   return <Tag color={'blue'}>{tags}</Tag>
              // },
            },
            {
              title: '下单时间',
              dataIndex: 'timeMake',
              key: 'timeMake',
              width: 180,
              align: 'center',
            },
            {
              title: '服务地址',
              dataIndex: 'propertyAddr',
              key: 'propertyAddr',
              width: 220,
              align: 'center',
            },
            {
              title: '服务单状态',
              dataIndex: 'orderStatus',
              key: 'orderStatus',
              width: 120,
              align: 'center',
              render: tags => (
                <>
                  <Badge color={orderStatusStyle[tags]} />
                  {orderStatusType[tags]}
                </>
              ),
            },
            {
              title: '操作',
              dataIndex: 'orderStatus',
              key: 'orderStatus',
              fixed: 'right',
              width: 230,
              render: (tags, record) => {
                return (
                  <>
                    {operateRender
                      .filter(item => item.when(record))
                      .map((_item, index) => (
                        <React.Fragment key={index}>
                          {index !== 0 && <Divider type={'vertical'} style={{margin: 0}} />}
                          <Button
                            key={index}
                            type={_item.type}
                            ghost={_item.ghost}
                            onClick={() => _item.onClick(record)}
                            style={{padding: '0 5px'}}
                          >
                            {_item.title}
                          </Button>
                        </React.Fragment>
                      ))}
                  </>
                )
              },
            },
          ]}
          pagination={{
            total: totals,
            pageSize: params.pageSize,
            current: params.pageNum,
            defaultCurrent: 1,
            defaultPageSize: 10,
            position: ['bottomCenter'],
            hideOnSinglePage: false,
            showSizeChanger: true,
            onChange: page => setParams(prevState => ({...prevState, pageNum: page})),
            onShowSizeChange: (current, size) => setParams(prevState => ({...prevState, pageSize: size, pageNum: 1})),
          }}
          dataSource={tableList}
          loading={loading}
          // onRow={record => {
          //   return {
          //     onClick: () => {
          //       console.log('record =>', record)
          //     },
          //   }
          // }}
        />
      </div>

      {/** 联系管家 */}
      <Modal
        visible={steward.show}
        centered
        footer={null}
        title={'联系管家'}
        onCancel={() => setSteward(prevState => ({...prevState, show: false}))}
      >
        <Typography.Text>
          管家<Typography.Text style={{color: '#017BFF'}}>{steward.name}</Typography.Text>的手机号为
          <Typography.Text style={{color: '#017BFF'}}>{steward.mobile}</Typography.Text>
        </Typography.Text>
      </Modal>

      {/** 取消服务单 */}
      <Modal
        centered
        visible={showForm}
        title={'取消服务单'}
        onCancel={() => setShowForm(false)}
        onOk={async () => {
          await steForm.validateFields()
          setShowForm(false)
          const reqParams = steForm.getFieldsValue(true) as {cancelType: string; cancelReason: string; orderId: string}
          // console.log('params', reqParams)
          await stewardModel.stewardCancel(reqParams)
          message.success('取消成功')
          queryStewardList(params)
        }}
      >
        <Form form={steForm} colon={false} labelCol={{span: 4}}>
          <Form.Item name={'cancelType'} label={'取消原因'} rules={[{required: true, message: '请选择取消原因'}]}>
            <Select placeholder={'请选择取消原因'}>
              {cancelReasonList.map((item, index) => (
                <Option value={item.label} key={index}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name={'cancelReason'} label={'具体原因'}>
            <Input.TextArea placeholder={'请输入具体原因'} />
          </Form.Item>
        </Form>
      </Modal>

      {/** 评价 */}
      <Modal
        centered
        visible={steRemark.show}
        title={'评价'}
        onCancel={() => setSteRemark(prevState => ({...prevState, show: false}))}
        onOk={async () => {
          if (+steRemark.remarkScore <= 3 && !steRemark.remarkResult) {
            message.warning('请输入具体原因！')
            return
          }
          setSteRemark(prevState => ({...prevState, show: false}))
          // console.log('steRemark =>', steRemark)
          await stewardModel.stewardRemark(steRemark)
          message.success('评价成功！')
          queryStewardList(params)
        }}
      >
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <Typography.Text>您对这单管家服务是否满意？</Typography.Text>
          <Rate
            defaultValue={5}
            onChange={value => setSteRemark(prevState => ({...prevState, remarkScore: `${value}`}))}
          />
          <Input.TextArea
            placeholder={'请输入具体原因'}
            style={{marginTop: 20, width: 360, height: 110}}
            onChange={({target: {value}}) => setSteRemark(prevState => ({...prevState, remarkResult: value}))}
          />
        </div>
      </Modal>
    </>
  )
}
