import {userModel} from '@/store/models/User'
import {propertyModel} from '@/store/models/Property'
import {useEffect, useMemo, useState} from 'react'

/**
 * @需求：【【房东web端】无用页面及功能屏蔽】https://www.tapd.cn/40183245/prong/stories/view/1140183245001001760
 *
 * 针对非自身的集中式公寓
 */
export function useIsProjectCondo() {
  const owner = userModel.useData(data => data.currentTeam?.owner)
  const projects = propertyModel.useData(data => data.projects)

  return useMemo(() => (projects || []).some(value => value.lessorId === owner), [owner, projects])
}

/**
 * 获取外部公寓的配置
 *
 * @example
 * ```
 * const [projectConfig, isProjectCondoType] = useProjectConfig({rentType: property.rentType, condoId: property.condoId})
 * ```
 */
export function useProjectConfig({
  rentType,
  condoId, // condoId（优先传入） 和 propertyCode 二选一
  propertyCode,
}: Pick<PropertyAllInfoVO, 'rentType'> & Partial<Pick<PropertyAllInfoVO, 'propertyCode' | 'condoId'>>) {
  const isProjectCondo = useIsProjectCondo()
  const isProjectCondoType = rentType === '3' && isProjectCondo
  const [projectConfig, setProjectConfig] = useState({} as CondoProjectConfigAssemblyVO)

  useEffect(() => {
    // 只有项目公寓才有项目配置
    if (isProjectCondoType && condoId) {
      propertyModel.condoProjectGetConfigByCondoId({condoId: condoId}).then(({response: {data}}) => {
        setProjectConfig(data)
      })
    }
  }, [isProjectCondoType, condoId])

  useEffect(() => {
    // 只有项目公寓才有项目配置
    if (isProjectCondoType && propertyCode) {
      propertyModel.propertyDetail({propertyCode}).then(({response: {data: property}}) => {
        propertyModel.condoProjectGetConfigByCondoId({condoId: property.condoId}).then(({response: {data}}) => {
          setProjectConfig(data)
        })
      })
    }
  }, [isProjectCondoType, propertyCode])

  return [projectConfig, isProjectCondoType] as [CondoProjectConfigAssemblyVO, boolean]
}
