import React, {useCallback, useEffect, useState} from 'react'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import nygDeviceStyle from '@/pages/nygDevice/device.module.less'
import {Button, Form, message, Space, Table} from 'antd'
import NygDeviceTableForm from '@/pages/nygDevice/components/NygDeviceTableForm'
import DeviceTableHeader from '@/pages/device/components/DeviceTableHeader'
import {useAppHistory} from '@/common/hooks/useRouter'
import NygDeviceRecharge from '@/pages/nygDevice/components/NygDeviceRecharge'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {deviceModel} from '@/store/models/Device'
import NygDeviceHistory from '@/pages/nygDevice/components/NygDeviceHistory'
import {ExportView} from '@/pages/nygDevice/NygDeviceWater'

export default function NygDeviceElectric() {
  const history = useAppHistory()

  const [form] = Form.useForm()
  const [total, setTotal] = useState(0)
  const [meterList, setMeterList] = useState<EleMeter[]>([])
  const [visible, setVisible] = useState(false)

  const [deviceId, setDeviceId] = useState('')

  const [isRecharge, setIsRecharge] = useState(false)
  const [eleMeter, setEleMeter] = useState({} as EleMeter)

  const queryEleMeterList = useCallback(() => {
    const formValues = form.getFieldsValue(true)
    // console.log('formValues', formValues)
    deviceModel.deviceV2EleMeterList({...formValues}).then(({response: {data, total}}) => {
      setTotal(total)
      setMeterList(data)
    })
  }, [form])

  useEffect(() => {
    queryEleMeterList()
  }, [queryEleMeterList])

  const meterLoading = deviceModel.deviceV2EleMeterList.useLoading()
  const infoLoading = deviceModel.deviceV2EleMeterInfo.useLoadings()

  return (
    <div>
      <Breadcrumb path={['首页', '智能设备', '电表']} right={<Button onClick={() => setVisible(true)}>导出</Button>} />
      <NygDeviceTableForm
        form={form}
        deviceType={'electric'}
        onFinish={queryEleMeterList}
        onReset={queryEleMeterList}
      />

      <div className={nygDeviceStyle.tableSection}>
        <DeviceTableHeader total={total} />
        <Table
          rowKey={'deviceId'}
          columns={[
            {title: '设备名称/ID', dataIndex: 'deviceId'},
            {title: '房源地址', dataIndex: 'propertyTitle'},
            {title: '承租人', dataIndex: 'lesseeName', render: value => value ?? '/'},
            {title: '承租人手机号', dataIndex: 'lesseeMobile', render: value => value ?? '/'},
            {title: '上次读表时间', dataIndex: 'syncTime'},
            // {title: '电费价格', dataIndex: 'elePrice'},
            {title: '电表读数', dataIndex: 'syncData'},
            {
              title: '控制状态',
              dataIndex: 'valveForce',
              render: value => ({'-1': '自动', '0': '强制断电', '1': '强制通电'}[value]),
            },
            {
              title: '操作',
              dataIndex: 'deviceId',
              render: (value, record) => {
                return (
                  <Space>
                    <Button
                      type={'link'}
                      style={{padding: 0}}
                      onClick={() => {
                        history.push('/nyg-device-electric/detail', {deviceId: value})
                      }}
                    >
                      查看
                    </Button>
                    <Button type={'link'} style={{padding: 0}} onClick={() => setDeviceId(value)}>
                      历史信息
                    </Button>
                    <Button
                      type={'link'}
                      style={{padding: 0}}
                      loading={infoLoading.pick(value)}
                      onClick={async () => {
                        if (!record.contractId) return message.error('当前设备未绑定租约，不能充值！')
                        const {
                          response: {data},
                        } = await deviceModel.deviceV2EleMeterInfo({eleMeterId: value})
                        setIsRecharge(true)
                        setEleMeter(data)
                      }}
                    >
                      充值
                    </Button>
                  </Space>
                )
              },
            },
          ]}
          dataSource={meterList}
          loading={meterLoading}
          pagination={getPaginationProps(form, total)}
        />
      </div>

      <NygDeviceRecharge
        info={eleMeter}
        visible={isRecharge}
        onOk={() => {
          setIsRecharge(prevState => !prevState)
          queryEleMeterList()
        }}
        onCancel={() => setIsRecharge(prevState => !prevState)}
      />

      <NygDeviceHistory deviceId={deviceId} visible={!!deviceId} onCancel={() => setDeviceId('')} />

      <ExportView visible={visible} onCancel={() => setVisible(false)} url={'/device/v2/ele-meter/statistics-excel'} />
    </div>
  )
}
