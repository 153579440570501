import React, {useEffect, useState, useRef} from 'react'
import {
  Form,
  Input,
  Col,
  Row,
  Radio,
  Select,
  DatePicker,
  Table,
  Button,
  message,
  Checkbox,
  Space,
  Typography,
  Modal,
  InputNumber,
  Popover,
} from 'antd'
import {
  UpOutlined,
  DownOutlined,
  CloseOutlined,
  SwapRightOutlined,
  PlusOutlined,
  InfoCircleFilled,
  WarningFilled,
} from '@ant-design/icons'
import {Album, UploadButton} from '@/ui/components/Album'
import styles from './index.module.less'
import commonStyle from '@/assets/styles/common.module.less'
import ContractCascader from '@/pages/contract/components/ContractCascader'
import {rules} from '@/utils/validate'
import moment from 'moment'
import {contractModel} from '@/store/models/Contract'
import {Cost} from '@/pages/property/components/Cost'
import {baseUrl} from '@/common/config'
import {getImg} from '@/utils/util'
import {useHistory} from 'react-router'
import dayjs from 'dayjs'
import {useLocation} from 'react-router-dom'
import ConfirmCollection from '@/pages/financial/components/ConfirmCollection'
import {Model} from '@redux-model/web'
import {useCodeArraySync} from '@/store/models/Common'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import {EquipmentDTOModel, EquipmentModal, EquipmentRef} from './EquipmentModal'
import blackTip from '@/assets/icons/black_tip.svg'
import {AddCheckin} from '@/pages/contract/components/AddCheckin'
import {Permission} from '@/ui/components/Permission'
import {propertyModel} from '@/store/models/Property'
import {useCountDown} from '@/common/hooks/countDown'
import {teamModel} from '@/store/models/Team'
import {
  // useIsProjectCondo,
  useProjectConfig,
} from '@/common/bizHooks/useIsProjectCondo'
import {renderChargeItem} from '@/utils/render'
import Draggable from 'react-draggable'

const {Option} = Select
const {Text} = Typography

interface ContractConfirmVO extends Omit<ContractCalculateGroupVO, 'list'> {
  list: (ContractCalculateGroupVO['list'][number] & {billConfirm?: any})[]
}

const genTempPersonId = () => `_temp_${Math.random()}`
// 是否是临时id
const isTempPersonId = (value = '') => value.startsWith('_temp_')

function format(time, formatString = 'YYYY-MM-DD HH:mm:ss') {
  const date = moment(time)
  return date.isValid() ? date.format(formatString) : ''
}

function mapTableList(data: ContractConfirmVO[]) {
  return data.map(item => ({
    // 提取账单、账单周期、应收款日等信息
    billAmtTotal: item.list.reduce((total, cur) => total + cur.billAmtTotal, 0).toFixed(2),
    billDate: item.list[0].billDate,
    billBegin: item.list[0].billBegin,
    billEnd: item.list[0].billEnd,
    billPeriod: `${dayjs(item?.list[0].billEnd).diff(item?.list[0].billBegin, 'day')}天`,
    ...item,
  }))
}

const discountPrice = (rentPrice: number, discountType: string, discountTypeValue: number) => {
  if (['1', '4'].includes(discountType)) return Math.max(0, rentPrice - discountTypeValue)
  if (['2', '3'].includes(discountType)) return rentPrice * discountTypeValue
  return rentPrice
}

const monthArr = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]

// 每月固定
function leasePeriodVerify({contractBegin, contractEnd}: {contractBegin: string; contractEnd: string}) {
  let isNotWholeMonth = false
  if (!!contractBegin && !!contractEnd) {
    const beginDay = dayjs(contractBegin)
    const endDay = dayjs(contractEnd)
    isNotWholeMonth = !beginDay
      .set('year', endDay.year())
      .set('month', endDay.month() + (beginDay.get('date') === 1 ? 1 : 0))
      .subtract(1, 'day')
      .isSame(endDay, 'day')
  }
  return isNotWholeMonth
}

export default function TenantRegister() {
  const history = useHistory()
  const location = useLocation<{
    contractId?: string // 租约id / 预定租约 id
    propertyCode?: string // 至少作为参数传入到后端
    // communityName?: string
    from?:
      | 'reserve' // 预定
      | 'reserve-lease' // 预定转租约
      | 'renewal' // 续约
      | 'alter' // 修改
    [key: string]: string
  }>()
  const propertyCode = location.state?.propertyCode
  const contractId = location.state?.contractId
  // const communityName = location.state?.communityName

  const {countDown, startCountDown} = useCountDown()

  const from = location.state?.from
  // 是否是转租约状态
  const isReserveLease = from === 'reserve-lease'
  // 是否是续约
  const isRenewal = from === 'renewal'
  const isAlter = from === 'alter'
  const breadcrumbName = isRenewal ? '续约' : '租客登记'

  const isDisabled = isReserveLease || isRenewal
  // 证件号码在续约状态下不可编辑
  // const isCertDisabled = isRenewal
  //续约起始日期
  // const [contractNextBegin, setContractNextBegin] = useState('')
  // //预定转租约起始日期
  // const [reserveContractBegin, setReserveContractBegin] = useState('')
  // //是否为预定修改租约日期
  // const [isChangeTime, setIsChangeTime] = useState(false)
  const [createBillVisible, setCreateBillVisible] = useState(false)
  useEffect(() => {
    if (createBillVisible) {
      setTimeout(() => {
        document.body.style.overflow = 'auto'
      }, 100)
    }
  }, [createBillVisible])

  const rentCollectType = useCodeArraySync('rent.collect.type')

  const [form] = Form.useForm()
  const [expandLessee, setExpandLessee] = useState(false)
  const [expandProperty, setExpandProperty] = useState(false)
  // const [billList, setBillList] = useState<ContractConfirmVO[]>([])
  const [calculateBillList, setCalculateBillList] = useState<ContractCalculateGroupVO[]>([])
  const [tableList, setTableList] = useState<ReturnType<typeof mapTableList>>([])
  // const [title, setTitle] = useState(communityName)
  // const tableLoading = contractModel.contractCalculateGroup.useLoading()
  const submitLoading = Model.isLoading(
    contractModel.generateContract.useLoading(),
    contractModel.contractReserveMake.useLoading(),
    contractModel.contractReserveConvert.useLoading(),
    contractModel.generateContractRenewal.useLoading(),
    contractModel.contractCheckinPersonUpsertBatch.useLoading()
  )

  const [property, setProperty] = useState(
    {propertyCode} as Pick<PropertyAllInfoVO, 'propertyCode' | 'title' | 'rentType' | 'communityCode'> // condoId 在外部集中式公寓，租客登记时用到
  )
  // 集中式房源（非续约）显示获客渠道
  // const showBrokerage = property.rentType === '3' && !isRenewal
  // const isBrokerageDisabled = () => !!contractId
  // 中介或者分销，显示输入框
  const showBrokerageInput = () => ['1', '2'].includes(form.getFieldValue('brokerageType'))
  const contractBrokerage = useCodeArraySync('contract.brokerage')

  useEffect(() => {
    if (propertyCode) {
      propertyModel.propertyDetail({propertyCode}).then(({response: {data}}) => {
        setProperty(data)
        form.setFieldsValue({rentPrice: data.rentPrice, rentPledge: data.rentPledge, propertyFee: data.propertyFee})
      })
    }
  }, [form, propertyCode])

  async function equipmentAdd(contractId: string) {
    // 判断有没有录过交割
    if (contractId && equipment) {
      await contractModel.contractEquipmentAdd({
        ...equipment,
        contractId,
      })
    }
  }

  const [projectConfig, isProjectCondoType] = useProjectConfig({
    rentType: property.rentType,
    condoId: property.communityCode,
  })
  const condoProject: CondoProjectVO = projectConfig?.project
  useEffect(() => {
    if (isProjectCondoType) {
      form.setFieldsValue({
        rentCollectType: condoProject?.billDateType,
        rentCollectDay: condoProject?.billDateAhead,
        rentPayType: condoProject?.rentPayType,
        rentPledgeType: condoProject?.rentPledgeType,
        rentCollectDay2: condoProject?.billDateAhead2,
        // propertyFee: condoProject?.propertyFee, // 从房源上拿，不从公寓拿
      })
    }
  }, [
    condoProject?.billDateAhead,
    condoProject?.billDateAhead2,
    condoProject?.billDateType,
    condoProject?.rentPayType,
    condoProject?.rentPledgeType,
    form,
    isProjectCondoType,
  ])

  // useEffect(() => {
  //   if (isProjectCondoType && property.condoId) {
  //     propertyModel.condoProjectGetConfigByCondoId({condoId: property.condoId}).then(({response: {data}}) => {
  //       setProjectConfig(data)
  //     })
  //   }
  // }, [isProjectCondoType, property.condoId])

  const calculateGroupLoading = contractModel.contractCalculateGroup.useLoading()

  const equipmentModalRef = useRef<EquipmentRef>(null)
  // 物业交割表单
  const [equipment, setEquipment] = useState<EquipmentDTOModel>(null)

  // 入住人信息
  const [checkinList, setCheckinList] = useState([] as ContractPersonCheckinUpsetDTO[])
  // “租约详情/修改” 已有入住人没有操作按钮，只是控制控件的显示
  const [hideSelf, setHideSelf] = useState(false)

  // 证件类型
  let contractCertType = useCodeArraySync('contract.certType')
  if (form.getFieldValue('contractType') === 'elec') {
    contractCertType = contractCertType.filter(item => item.id === '01')
  }

  // 确认收款弹窗ref
  const confirmCollectionRef = useRef(null)

  //是否为黑名单
  const [isBlack, setIsBlack] = useState(false)
  //黑名单备注
  const [blackRemark, setBlackRemark] = useState('')

  useEffect(() => {
    if (contractId) {
      contractModel.contractQueryContract({contractId}).then(async ({response: {data}}) => {
        const {
          response: {data: data1},
        } = await propertyModel.propertyDetail({propertyCode: data.propertyCode})

        let renewBeginTime = ''
        let renewEndTime = ''
        if (data.renewId) {
          const {
            response: {data: data2},
          } = await contractModel.contractRenewDetail({renewId: data.renewId})
          renewBeginTime = data2.renewBeginTime
          renewEndTime = data2.renewEndTime
        }

        // console.log('isRenewal', isRenewal)
        setProperty({
          propertyCode: data.propertyCode,
          title: data.propertyTitle,
          rentType: data.rentType,
          communityCode: data.communityCode,
        })

        // data.rentPrice
        const formValues = {
          ...data,
          propertyFee: data1.propertyFee,
          reserveTime: moment(data.reserveTime),
          billList: data.contractDetailList?.[0]?.billList?.map(item => ({
            interval: item.interval,
            category: item.billCategory,
            id: item.billTypeCode,
            label: item.billTypeName,
            billAmtTotal: item.billAmtTotal,
            type: getTypeName(item),
            intervalFreq: item.intervalFreq,
          })),
          reservePrice: data.reservePricePaid === 1 ? data.reservePrice : 0,
          contractTemplateId: data.contractDetailList?.[0]?.contractTemplateId,
        }
        if (isRenewal) {
          if (data.renewId) {
            Object.assign(formValues, {
              contractBegin: moment(renewBeginTime),
              contractEnd: moment(renewEndTime),
              rentPledge: '0', // 续约不需要押金
              rentPrice: data1.rentPrice, // 续约使用房源的价格
            })
          } else {
            Object.assign(formValues, {
              contractBegin: moment(data.contractEnd).add(1, 'day'),
              contractEnd: undefined,
              // time: [moment(data.contractEnd).add(1, 'day')],
              rentPledge: '0', // 续约不需要押金
              rentPrice: data1.rentPrice, // 续约使用房源的价格
            })
          }
        } else {
          Object.assign(formValues, {
            contractBegin: moment(data.contractBegin),
            contractEnd: moment(data.contractEnd),
            rentPledge: data.rentPledge
              ? data.rentPledge
              : data.rentPrice && data.rentPledgeType
              ? data.rentPrice * data.rentPledgeType
              : 0,
          })
        }

        // data.propertyTitle && setTitle(data.propertyTitle)
        form.setFieldsValue(formValues)
      })

      contractModel.contractCheckinPersonList({contractId}).then(({response: {data}}) => {
        setCheckinList(data)
        if (data.length) setHideSelf(true)
      })
    }
  }, [contractId, form, isRenewal])

  const [templateList, setTemplateList] = useState<ContractTemplateVO[]>([])

  useEffect(function () {
    contractModel
      .contractTemplateList({pageable: false, needDefault: true, templateStatus: '1'})
      .then(({response: {data}}) => setTemplateList(data))
  }, [])

  const [listMember, setListMember] = useState<TeamUserVO[]>([])

  useEffect(() => {
    teamModel.teamV2TeamListMember({}).then(({response: {data}}) => {
      const obj = data.reduce((acc, item) => {
        acc[item.userId] = item
        return acc
      }, {})
      setListMember(Object.values(obj))
    })
  }, [])

  // const [condoProject, setCondoProject] = useState<CondoProjectVO>(null)

  // useEffect(() => {
  //   // 只有公寓才有项目配置
  //   if (property.rentType === '3') {
  //     propertyModel
  //       .condoProjectGetProjectByPropertyCode({propertyCode: property.propertyCode})
  //       .then(({response: {data}}) => {
  //         setCondoProject(data)
  //         if (data) {
  //           form.setFieldsValue({rentCollectType: '1', rentCollectDay: data?.billDateAhead})
  //         }
  //       })
  //   }
  // }, [property.rentType, property.propertyCode, form])

  const infoCheckin = () =>
    new Promise(resolve => {
      Modal.info({content: '请添加入住人', onOk: () => resolve()})
    })

  async function onCreateBill() {
    const bills: ContractCalculateDTO['billList'] = form.getFieldValue('billList')?.map((item: OtherCost) => ({
      billAmtTotal: item.billAmtTotal,
      billCategory: item.category,
      billTypeCode: item.id,
      billTypeName: item.label,
      interval: item.interval,
      intervalFreq: item.intervalFreq,
    }))
    if (bills?.length && bills.some(item => !/^\d+$/.test(String(item.billAmtTotal)))) {
      return message.warning('请填写其他费用的金额')
    }

    await form.validateFields()
    const value = form.getFieldsValue(true)
    const {contractBegin, contractEnd} = value
    if (value.rentCollectType === '2' && leasePeriodVerify({contractBegin, contractEnd}))
      return message.warning('约定租期非整月，不可生成租约')

    const params: ContractCalculateDTO = {
      ...value,
      contractBegin: format(contractBegin),
      contractEnd: format(contractEnd),
      billList: bills,
      reservePrice: value.reservePricePaid === 1 && isReserveLease ? value.reservePrice || 0 : 0,
    }

    // 前置条件：后台集中式项目绑定的房源
    if (isProjectCondoType) {
      if (!checkinList.length && !value.self) {
        await infoCheckin()
      }
    }

    const {
      response: {data},
    } = await contractModel.contractCalculateGroup(params)
    const tableList = mapTableList(data)
    setCalculateBillList(data)
    setTableList(tableList)
    if (!isProjectCondoType) {
      startCountDown(10)
    }
    setCreateBillVisible(true)
  }

  // 收款
  function onSuccess(billId: string, billConfirm: Object, index: number[]) {
    const [interval, confirmIndex] = index

    const data = calculateBillList.map((item, index) =>
      index + 1 === interval
        ? {
            ...item,
            list: item.list.map((_item, _index) => (_index === confirmIndex ? {..._item, billConfirm} : {..._item})),
          }
        : {...item}
    )
    const tableList = mapTableList(data)
    setCalculateBillList(data)
    setTableList(tableList)
  }

  //查验黑名单
  function checkBlackPerson(e) {
    const mobile = e.target.value
    if (mobile.length === 11) {
      contractModel
        .contractBlacklistList({pageNum: 1, pageSize: 10, pageable: false, mobile})
        .then(({response: {data}}) => {
          setIsBlack(data?.length > 0)
          setBlackRemark(data?.[0]?.remark)
        })
    }
  }
  // 预定和租客登记的 onSubmit 分开
  async function onSubmit() {
    const value = form.getFieldsValue(true)
    const billListOfCalculation = value?.billList

    const billList = calculateBillList
    // 租客登记 | 预定转租约
    if (billList.length === 0) {
      return message.warning(`请点击'生成账单计划'生成账单`)
    }
    // const value = form.getFieldsValue(true)
    const {contractBegin, contractEnd} = value

    const params = {
      ...value,
      contractBegin: format(contractBegin),
      contractEnd: format(contractEnd),
      reserveTime: format(value.reserveTime),
      billList: billList
        .map(item => {
          return item.list.map(list => ({
            ...list,
            title: item.title,
            billPeriod: `${dayjs(list.billEnd).diff(list.billBegin, 'day')}天`,
          }))
        })
        .flat(),
      billListOfCalculation: billListOfCalculation?.map((item: OtherCost) => ({
        billAmtTotal: item.billAmtTotal,
        billCategory: item.category,
        billTypeCode: item.id,
        billTypeName: item.label,
        interval: item.interval,
        intervalFreq: item.intervalFreq,
      })),
      contractTemplateId: isProjectCondoType ? 'DEFAULT_4' : value.contractTemplateId,
      templateName: isProjectCondoType ? '牛油果公寓房屋租赁合同' : value.templateName,
      checkinList: checkinList.map(item => ({
        ...item,
        personId: isTempPersonId(item.personId) ? null : item.personId,
      })),
      checkinLessee: !!value.self,
    } as ContractReserveMakeParams & GenerateContractParams & ContractGenerateDTO

    const needDiscountAudit = async () => {
      if (value.discountId) {
        return contractModel
          .contractAuditNeedDiscountAudit({condoId: property.communityCode})
          .then(({response: {data}}) => data)
      }
      return false
    }

    if (isReserveLease) {
      const isNeed = await needDiscountAudit()
      if (isNeed) {
        await contractModel.contractAuditSubmit({
          auditType: '5',
          contractCheckOutObject: null,
          contractId,
          contractThrowObject: null,
          createdReason: null,
          discountName: value.discountName,
          generateContractObject: null,
          propertyCode: value.propertyCode,
          refundAmt: null,
          rentPrice: value.rentPrice,
          rentPriceDiscount: discountPrice(value.rentPrice, value.discountType, value.discountTypeValue),
          reserve2ContractObject: params,
          reserveCancelObject: null,
        } as ContractAuditSubmitDTO)
      } else {
        await contractModel.contractReserveConvert(params)
      }
    } else if (isRenewal) {
      const {
        response: {data},
      } = await contractModel.generateContractRenewal(params)
      equipmentAdd(data)
    } else if (isAlter) {
      await contractModel.contractGenerateContractAlter(params)
    } else if (!from) {
      const isNeed = await needDiscountAudit()
      if (isNeed) {
        await contractModel.contractAuditSubmit({
          auditType: '4',
          contractCheckOutObject: null,
          contractId: null,
          contractThrowObject: null,
          createdReason: null,
          discountName: value.discountName,
          generateContractObject: params,
          propertyCode: value.propertyCode,
          refundAmt: null,
          rentPrice: value.rentPrice,
          rentPriceDiscount: discountPrice(value.rentPrice, value.discountType, value.discountTypeValue),
          reserve2ContractObject: null,
          reserveCancelObject: null,
        } as ContractAuditSubmitDTO)
      } else {
        const {
          response: {data},
        } = await contractModel.generateContract(params)
        equipmentAdd(data)
      }
    }

    // if (contractId) {
    //   if (checkinList?.length) {
    //     await contractModel.contractCheckinPersonUpsertBatch(
    //       checkinList.map(item => ({
    //         ...item,
    //         contractId,
    //         personId: isTempPersonId(item.personId) ? undefined : item.personId,
    //       }))
    //     )
    //   }
    //
    //   if (value.self) {
    //     await contractModel.insertLessee(contractId, '1')
    //   }
    // }

    setCreateBillVisible(false)
    message.warning('操作成功')
    history.goBack()
  }

  // const timeParams = useMemo(() => {
  //   return {
  //     '1': [1, 'month'],
  //     '2': [3, 'month'],
  //     '3': [6, 'month'],
  //     '4': [1, 'year'],
  //   }
  // }, [])

  //快捷日期选择
  function selectTime(e) {
    const value = e.target.value
    const contractBegin = form.getFieldValue('contractBegin')
    if (isRenewal) {
      const contractEnd = dayjs(contractBegin).add(value, 'month').subtract(1, 'day')
      form.setFieldsValue({contractBegin, contractEnd})
    } else {
      const begin = dayjs(contractBegin || new Date())
      const end = dayjs(begin).add(value, 'month').subtract(1, 'day')
      form.setFieldsValue({contractBegin: begin, contractEnd: end})
    }
  }

  function renderPropertyInfo() {
    return (
      <>
        {!!expandProperty && (
          <>
            <Col span={24}>
              <Form.Item name={'originContractId'} label={'原合同编号'} wrapperCol={{span: 10}}>
                <Input allowClear placeholder={'请输入原合同编号'} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                shouldUpdate
                label={'照片凭证'}
                extra={'可上传合同照片、房间物品照片或仪表度数照片等，最多20张'}
              >
                {() => (
                  <Album
                    limit={20}
                    action={baseUrl + '/contract/upload-img'}
                    data={{
                      imgType: '04',
                    }}
                    showUploadList={true}
                    onDone={fileList => {
                      form.setFieldsValue({
                        imgIdList: fileList.map(item => item.imgId),
                      })
                    }}
                  />
                )}
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item name={'contractRemark'} label={'备注信息'} wrapperCol={{span: 10}}>
                <Input.TextArea rows={5} placeholder={'备注信息及附加条款，填写后租客可见'} />
              </Form.Item>
            </Col>
          </>
        )}

        <Col span={24}>
          <Form.Item label={' '}>
            <a onClick={() => setExpandProperty(!expandProperty)}>
              {expandProperty
                ? '收起更多信息（原合同编号、合同照片、备注）'
                : '展开更多信息（原合同编号、合同照片、备注）'}
              {expandProperty ? <UpOutlined /> : <DownOutlined />}
            </a>
          </Form.Item>
        </Col>
      </>
    )
  }

  return (
    <>
      <Breadcrumb path={['首页', '租约管理', '租约列表', `${breadcrumbName}`]} />
      <div className={commonStyle.flexFullSection}>
        <article>
          <Form
            form={form}
            colon={false}
            labelCol={{flex: '100px'}}
            wrapperCol={{style: {flexWrap: 'nowrap'}}}
            onFinish={() => onSubmit()}
            initialValues={{
              ...(!isAlter ? {self: true} : {}),
              lesseeType: 'person',
              lesseeGender: '1',
              contractType: 'paper',
              lesseeCertType: '01',
              // 空房快查进来需要回显 location.state?.rentPayType ...
              rentPayType: location.state?.rentPayType,
              rentPledgeType: location.state?.rentPledgeType,
              rentPrice: location.state?.rentPrice,
              rentCollectType: location.state?.rentCollectType ?? '1',
              rentCollectDay: location.state?.rentCollectDay ?? 7,
              propertyCode,
              contractTemplateId: 'DEFAULT',
              discountId: '', // 非要个无
            }}
          >
            <section className={styles.item}>
              <div className={styles.header}>房源信息</div>
              <div className={styles.content}>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name={'propertyCode'}
                      label={'房源地址'}
                      rules={[{required: true, message: '请选择房源地址'}]}
                    >
                      {contractId || propertyCode ? (
                        <Text>{property.title}</Text>
                      ) : (
                        <ContractCascader
                          onChangeValue={(value, dtos) => {
                            if (dtos?.[1]) {
                              form.setFieldsValue({propertyCode: dtos[1].propertyCode})
                              setProperty(dtos[1])
                            }
                          }}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </section>

            <section className={styles.item}>
              <div className={styles.header}>合同类型</div>
              <div className={styles.content} style={{paddingBottom: 0}}>
                <Row>
                  <Col span={8}>
                    {/*<ContractRadio name={'contractType'} />*/}
                    <Form.Item
                      label={
                        <Space>
                          <span>合同类型</span>
                          <Popover
                            align={{offset: [14]}}
                            placement='topRight'
                            content={
                              <div style={{maxWidth: 472}}>
                                <Typography.Title level={5} style={{fontSize: 14}}>
                                  新签电子合同
                                </Typography.Title>
                                <Typography.Text style={{fontSize: 10}}>
                                  适用于空房新租客签约场景，使用CA电子合同，实名认证租客身份，公证机构颁发云证书，具备法律效力，
                                  同时管理方便。租客若授权芝麻信用，按时在线交租有助积累信用，逾期违约将产生负面记录。
                                </Typography.Text>
                                <Typography.Title level={5} style={{fontSize: 14}}>
                                  纸质合同
                                </Typography.Title>
                                <Typography.Text style={{fontSize: 10}}>
                                  适用于已与租客签约过纸质合同场景，将合同信息补录到系统中，方便统一管理，支持在线收租。
                                </Typography.Text>
                              </div>
                            }
                          >
                            <InfoCircleFilled style={{color: '#FAAD14'}} />
                          </Popover>
                        </Space>
                      }
                      name={'contractType'}
                      wrapperCol={{style: {whiteSpace: 'nowrap'}}}
                      style={{flexWrap: 'nowrap'}}
                    >
                      <Radio.Group
                        onChange={() => {
                          form.setFieldsValue({lesseeCertType: '01'})
                        }}
                      >
                        <Radio value={'elec'}>电子合同</Radio>
                        <Radio value={'paper'}>纸质合同</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Form.Item noStyle shouldUpdate>
                    {form1 =>
                      form1.getFieldValue('contractType') === 'elec' ? (
                        isProjectCondoType ? (
                          <Col span={9}>
                            <Form.Item label={'合同模板'}>
                              <Input readOnly value={'牛油果公寓房屋租赁合同'} />
                            </Form.Item>
                          </Col>
                        ) : (
                          <Col span={14}>
                            <Form.Item label={'合同模板'} required>
                              <Space>
                                <Form.Item
                                  name={'contractTemplateId'}
                                  noStyle
                                  rules={[{required: true, message: '请选择合同模板'}]}
                                >
                                  <Select placeholder={'请选择'}>
                                    {templateList.map(item => (
                                      <Option key={item.templateId} value={item.templateId}>
                                        {item.templateName}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                                <Permission code={'007006002'}>
                                  <Button
                                    type={'link'}
                                    icon={<PlusOutlined style={{color: '#017BFF'}} />}
                                    onClick={() => history.push('/template-contract/add')}
                                  >
                                    创建新的电子合同模版
                                  </Button>
                                </Permission>
                              </Space>
                            </Form.Item>
                          </Col>
                        )
                      ) : null
                    }
                  </Form.Item>
                </Row>
              </div>
            </section>

            <section className={styles.item}>
              <div className={styles.header}>承租人信息</div>
              <div className={styles.content}>
                <Row gutter={14}>
                  <Col span={24}>
                    <Form.Item name={'lesseeType'} label={'承租身份'} rules={rules.lesseeType}>
                      <Radio.Group
                        disabled={isDisabled}
                        options={[
                          {label: '个人承租', value: 'person'},
                          {label: '企业承租', value: 'organ'},
                        ]}
                      />
                    </Form.Item>
                  </Col>

                  <Form.Item noStyle shouldUpdate>
                    {() =>
                      form.getFieldValue('lesseeType') === 'person' ? (
                        <>
                          <Col span={8}>
                            <Form.Item label={'承租人姓名'} name={'lesseeCertName'} rules={rules.lesseeCertName}>
                              <Input
                                disabled={isReserveLease}
                                allowClear
                                placeholder={'请输入承租人姓名'}
                                style={{width: '100%'}}
                                maxLength={30}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={16}>
                            <Form.Item label={'承租人手机'} required>
                              <Space>
                                <Form.Item name={'lesseeMobile'} noStyle rules={rules.lesseeMobile}>
                                  <Input
                                    disabled={isDisabled}
                                    allowClear
                                    placeholder={'请输入承租人手机号'}
                                    onChange={checkBlackPerson}
                                    style={{width: 200, marginRight: 20}}
                                  />
                                </Form.Item>
                                {/*“租约详情/修改” 已有入住人没有操作按钮*/}
                                {!(isAlter && hideSelf) && (
                                  <Form.Item name={'self'} valuePropName={'checked'} noStyle>
                                    <Checkbox>承租人本人入住</Checkbox>
                                  </Form.Item>
                                )}
                              </Space>
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item name={'lesseeGender'} label={'承租人性别'} required>
                              <Radio.Group>
                                <Radio value={'1'}>男</Radio>
                                <Radio value={'2'}>女</Radio>
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col span={8}>
                            <Form.Item name={'lesseeOrganName'} label={'公司名称'} rules={rules.lesseeOrganName}>
                              <Input disabled={isReserveLease} allowClear placeholder={'请输入公司名称'} />
                            </Form.Item>
                          </Col>
                          <Col span={8} offset={1} pull={1}>
                            <Form.Item name={'lesseeOrganCode'} label={'公司营业执照'} rules={rules.lesseeOrganCode}>
                              <Input disabled={isReserveLease} allowClear placeholder={'请输入公司营业执照'} />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item name={'lesseeCertName'} label={'联系人'} rules={rules.lesseeCertName}>
                              <Input disabled={isReserveLease} allowClear placeholder={'请输入联系人'} maxLength={30} />
                            </Form.Item>
                          </Col>
                          <Col span={8} offset={1} pull={1}>
                            <Form.Item name={'lesseeMobile'} label={'联系人手机'} rules={rules.lesseeMobile}>
                              <Input disabled={isDisabled} allowClear placeholder={'请输入联系人手机'} />
                            </Form.Item>
                          </Col>
                        </>
                      )
                    }
                  </Form.Item>

                  <Col span={12}>
                    <Form.Item noStyle shouldUpdate>
                      {form1 => {
                        const contractType = form1.getFieldValue('contractType')
                        const lesseeCertType = form1.getFieldValue('lesseeCertType')
                        const isRequire = lesseeCertType === '01' && contractType === 'elec'

                        return (
                          <Form.Item label={'证件类型'} required={isRequire}>
                            <Input.Group compact style={{whiteSpace: 'nowrap'}}>
                              <Form.Item name={'lesseeCertType'} noStyle>
                                <Select style={{minWidth: 100}}>
                                  {contractCertType
                                    .filter(item => (contractType === 'elec' ? item.id === '01' : true))
                                    .map(item => (
                                      <Option key={item.id} value={item.id}>
                                        {item.label}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                              <Form.Item
                                name={'lesseeCertNo'}
                                // 电子合同的身份证必填，其他情况，其他证件非必填
                                rules={isRequire ? rules.lesseeCertNo : []}
                                noStyle
                              >
                                <Input
                                  allowClear
                                  // addonBefore={'身份证'}
                                  placeholder={'请输入承租人证件号码'}
                                  maxLength={18}
                                />
                              </Form.Item>
                            </Input.Group>
                          </Form.Item>
                        )
                      }}
                    </Form.Item>
                  </Col>
                  {isBlack ? (
                    <Col span={12}>
                      <div>
                        <div
                          style={{
                            backgroundColor: 'rgba(250, 173, 20, 0.1)',
                            padding: '5px 17px 5px 16px',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                          }}
                        >
                          <img src={blackTip} style={{width: '14px', height: '14px', marginRight: '8px'}} />
                          <Typography.Text style={{fontSize: '14px'}}>{`该手机号已被添加为黑名单 ${
                            blackRemark ? `备注：${blackRemark}` : ''
                          }`}</Typography.Text>
                          <CloseOutlined
                            style={{color: 'rgba(0, 0, 0, 0.25)', fontSize: '12px', marginLeft: '2px'}}
                            onClick={() => setIsBlack(false)}
                          />
                        </div>
                      </div>
                    </Col>
                  ) : null}
                  {/*  <Col>*/}
                  {/*    <Form.Item name={'g'} label={'国家或地区'}>*/}
                  {/*      <Select allowClear style={{width: 145}}>*/}
                  {/*        <Option value='a'>a</Option>*/}
                  {/*        <Option value='b'>b</Option>*/}
                  {/*      </Select>*/}
                  {/*    </Form.Item>*/}
                  {/*  </Col>*/}
                  {/*</Row>*/}
                  {/*<Row>*/}
                  {/*  <Col>*/}
                  {/*    <Form.Item name={'g'} label={'住址'}>*/}
                  {/*      <Input allowClear placeholder={'请输入住址'} />*/}
                  {/*    </Form.Item>*/}
                  {/*  </Col>*/}

                  {!!expandLessee && (
                    <Col span={24}>
                      <Form.Item label={'证件照片'} extra={'此处上传身份证正面、背面、手持半身照片，最多3张'}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                          {[
                            {imgType: '01', title: '正面照', keys: 'lesseeCertFront'},
                            {imgType: '02', title: '反面照', keys: 'lesseeCertBack'},
                            {imgType: '03', title: '手持半身', keys: 'lesseeCertHold'},
                          ].map((item, index) => {
                            const {imgType, title, keys} = item
                            return (
                              <Form.Item shouldUpdate key={index} style={{marginBottom: 0}}>
                                {() => (
                                  <Album
                                    disabled={isDisabled}
                                    action={baseUrl + '/contract/upload-img'}
                                    data={{
                                      imgType,
                                    }}
                                    showUploadList={false}
                                    onDone={fileList => {
                                      form.setFieldsValue({
                                        [keys]: fileList[0]?.imgUrl,
                                      })
                                    }}
                                  >
                                    {form.getFieldValue(keys) ? (
                                      <img
                                        style={{width: 80, height: 80}}
                                        src={getImg(form.getFieldValue(keys))}
                                        alt=''
                                      />
                                    ) : (
                                      <UploadButton title={title} />
                                    )}
                                  </Album>
                                )}
                              </Form.Item>
                            )
                          })}
                        </div>
                      </Form.Item>
                    </Col>
                  )}

                  <Col span={24}>
                    <Form.Item label={' '}>
                      <a onClick={() => setExpandLessee(!expandLessee)}>
                        {expandLessee ? '收起更多信息（证件照片）' : '展开更多信息（证件照片）'}
                        {expandLessee ? <UpOutlined /> : <DownOutlined />}
                      </a>
                    </Form.Item>
                  </Col>
                  {condoProject?.displayTag2 === 1 && (
                    <Col span={8}>
                      <Form.Item
                        label={'紧急联络人'}
                        name={'lesseeContactPerson'}
                        rules={[{required: true, message: '请输入紧急联络人'}]}
                      >
                        <Input placeholder={'请输入紧急联络人'} maxLength={10} />
                      </Form.Item>
                    </Col>
                  )}
                  {condoProject?.displayTag3 === 1 && (
                    <Col span={8}>
                      <Form.Item
                        label={'联络电话'}
                        name={'lesseeContactMobile'}
                        rules={[
                          {required: true, message: '请输入联络电话'},
                          {pattern: /^1[0-9]{10}$/, message: '手机号码不正确'},
                        ]}
                      >
                        <Input placeholder={'请输入联络电话'} maxLength={11} />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </div>
            </section>

            <AddCheckin
              checkinList={checkinList}
              onSubmit={value => {
                if (value.personId) {
                  setCheckinList(prev => prev.map(item => (item.personId === value.personId ? value : item)))
                } else {
                  setCheckinList(prev => [...prev, {...value, personId: genTempPersonId()}])
                }
              }}
              onDelete={personId => setCheckinList(prev => prev.filter(value => value.personId !== personId))}
            />

            <section className={styles.item}>
              <div className={styles.header}>签约信息</div>
              <div className={styles.content}>
                <Row>
                  {condoProject?.displayTag4 === 1 && (
                    <Col span={8}>
                      <Form.Item
                        name={'contractOwnerId'}
                        label={'签约人'}
                        rules={[{required: true, message: '请选择签约人'}]}
                      >
                        <Select
                          placeholder={'请选择'}
                          onChange={value => {
                            const item = listMember.find(item => item.userId === value)
                            form.setFieldsValue({contractOwnerMobile: item?.mobile, contractOwnerName: item?.certName})
                          }}
                        >
                          {listMember.map(item => (
                            <Option key={item.userId} value={item.userId}>
                              {item.certName}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}

                  {!isRenewal && condoProject?.displayTag5 === 1 && (
                    <Form.Item noStyle shouldUpdate>
                      {() => {
                        // 新增和预定时候必填
                        // const isDisabled = isBrokerageDisabled()
                        const showInput = showBrokerageInput()
                        return (
                          <>
                            <Col span={8}>
                              <Form.Item
                                label={'获客渠道'}
                                name={'brokerageType'}
                                rules={[{required: true, message: '获客渠道不能为空'}]}
                              >
                                <Select placeholder={'请选择'}>
                                  {contractBrokerage.map(value => (
                                    <Select.Option key={value.id} value={value.id}>
                                      {value.label}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                            {showInput && (
                              <Col span={8}>
                                <Form.Item label={'佣金'} name={'brokerage'} rules={rules.brokerage}>
                                  <InputNumber placeholder={'请输入'} />
                                </Form.Item>
                              </Col>
                            )}
                          </>
                        )
                      }}
                    </Form.Item>
                  )}

                  {/*预定或转租约的时候需要"交付定金"*/}
                  {isReserveLease && (
                    <>
                      <Col span={24}>
                        <Form.Item
                          label={'交付定金'}
                          name={'reservePrice'}
                          rules={[{required: true}]}
                          style={{marginBottom: 0}}
                        >
                          <Input disabled={isDisabled} style={{width: 200}} placeholder={'请输入定金金额'} />
                        </Form.Item>
                      </Col>
                      <Col offset={2} style={{padding: '10px 0'}}>
                        <Form.Item noStyle>
                          <Typography.Text>
                            填入预定金额将形成对应定金账单，预定转租约后将会自动 抵扣租金
                          </Typography.Text>
                        </Form.Item>
                      </Col>
                      {/*<Col span={24}>*/}
                      {/*  <Form.Item*/}
                      {/*    label={'预计签约时间'}*/}
                      {/*    name={'reserveTime'}*/}
                      {/*    // wrapperCol={{span: 7}}*/}
                      {/*  >*/}
                      {/*    <DatePicker disabled={isDisabled} placeholder={'请选择预定时间'} style={{minWidth: 200}} />*/}
                      {/*  </Form.Item>*/}
                      {/*</Col>*/}
                    </>
                  )}

                  <Col span={24}>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prev, next) =>
                        prev.contractBegin !== next.contractBegin || prev.contractEnd !== next.contractEnd
                      }
                    >
                      {f => {
                        const contractBegin = f.getFieldValue('contractBegin')
                        const contractEnd = f.getFieldValue('contractEnd')
                        const isNotWholeMonth = leasePeriodVerify({contractBegin, contractEnd})
                        return (
                          <>
                            <Form.Item
                              label={'租约起止'}
                              required
                              style={isNotWholeMonth ? {marginBottom: 0} : undefined}
                            >
                              <Space size={12}>
                                <Form.Item
                                  noStyle
                                  name={'contractBegin'}
                                  rules={[{required: true, message: '请选择租约开始日期'}]}
                                >
                                  <DatePicker
                                    disabledDate={current => {
                                      const contractEnd = form.getFieldValue('contractEnd')
                                      return contractEnd && current > contractEnd
                                    }}
                                    style={{width: '100%'}}
                                    disabled={isRenewal || isReserveLease}
                                    placeholder={'开始日期'}
                                  />
                                </Form.Item>
                                <SwapRightOutlined style={{color: 'rgba(0, 0, 0, 0.25)'}} />
                                <Form.Item
                                  noStyle
                                  name={'contractEnd'}
                                  // name={'end'}
                                  rules={[{required: true, message: '请选择租约结束日期'}]}
                                >
                                  <DatePicker
                                    style={{width: '100%'}}
                                    disabledDate={current => {
                                      const contractBegin = form.getFieldValue('contractBegin')
                                      return contractBegin && current < contractBegin
                                    }}
                                    placeholder={'结束日期'}
                                    // disabled={isReserveLease}
                                    disabled
                                  />
                                </Form.Item>
                                <Form.Item name={'month'} noStyle>
                                  <Radio.Group onChange={selectTime} disabled={isReserveLease}>
                                    <Radio.Button value={1}>1个月</Radio.Button>
                                    <Radio.Button value={3}>3个月</Radio.Button>
                                    <Radio.Button value={6}>6个月</Radio.Button>
                                    <Radio.Button value={12}>1年</Radio.Button>
                                  </Radio.Group>
                                </Form.Item>
                                <Form.Item name={'month'} noStyle>
                                  <Select
                                    allowClear
                                    style={{minWidth: '100px'}}
                                    disabled={isReserveLease}
                                    onSelect={(value: number) => {
                                      const begin = dayjs(contractBegin || new Date())
                                      const end = dayjs(begin).add(value, 'month').subtract(1, 'day')
                                      f.setFieldsValue({contractBegin: begin, contractEnd: end})
                                    }}
                                  >
                                    {monthArr.map(item => (
                                      <Option key={item} value={item}>
                                        {item}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                                月
                              </Space>
                            </Form.Item>
                            {isNotWholeMonth && (
                              <Form.Item label={' '} style={{marginBottom: 0}} colon={false}>
                                <div style={{color: 'red'}}>
                                  <WarningFilled />
                                  <span> 请注意，当前所填出租周期非整月</span>
                                </div>
                              </Form.Item>
                            )}
                          </>
                        )
                      }}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={'付款方式'}>
                      <Row>
                        <Col span={12}>
                          <Form.Item
                            name={'rentPayType'}
                            label={'付'}
                            rules={[{required: true, message: '请选择付款方式'}]}
                            style={{marginBottom: 0}}
                          >
                            <Select
                              allowClear
                              // style={{width: 60, margin: '0 7px'}}
                              placeholder={'请选择'}
                            >
                              {Array.from(new Array(24).keys()).map(item => (
                                <Option key={item + 1} value={item + 1}>
                                  {item + 1}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name={'rentPledgeType'}
                            label={'押'}
                            rules={[{required: true, message: '请选择付款方式'}]}
                            style={{marginBottom: 0}}
                          >
                            <Select
                              allowClear
                              // style={{width: 87, margin: '0 7px'}}
                              placeholder={'请选择'}
                              onSelect={(value: number) => {
                                if (form.getFieldValue('rentPrice')) {
                                  if (!isRenewal) {
                                    form.setFieldsValue({rentPledge: form.getFieldValue('rentPrice') * value})
                                  }
                                }
                              }}
                            >
                              {Array.from(new Array(13).keys())
                                .filter(value => (isProjectCondoType ? value !== 0 : true))
                                .map(item => (
                                  <Option key={item} value={item}>
                                    {item === 0 ? '自定义' : item}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Col>
                  {isProjectCondoType && (
                    <Col span={9}>
                      <Form.Item
                        shouldUpdate={(prevValues, nextValues) =>
                          prevValues.contractBegin !== nextValues.contractBegin ||
                          prevValues.contractEnd !== nextValues.contractEnd ||
                          prevValues.discountId !== nextValues.discountId
                        }
                        noStyle
                      >
                        {form1 => {
                          const {contractBegin, contractEnd} = form1.getFieldsValue(['contractBegin', 'contractEnd'])
                          let diff = 0
                          if (contractBegin && contractEnd) {
                            diff = dayjs(contractEnd).add(1, 'day').diff(dayjs(contractBegin), 'month', true)
                          }
                          return (
                            <Form.Item name={'discountId'} label={'优惠活动'} required style={{flexWrap: 'nowrap'}}>
                              <Select
                                options={[
                                  {value: '', label: '无'},
                                  ...(projectConfig?.discountList || [])
                                    .filter(item => {
                                      return diff >= item.periodMin && diff <= item.periodMax
                                    })
                                    .map(value => ({
                                      value: value.discountId,
                                      label: value.discountName,
                                      discount_type: value.discountType,
                                      discount_type_value: value.discountTypeValue,
                                    })),
                                ]}
                                onChange={(_, value: any) => {
                                  form.setFieldsValue({
                                    discountName: value?.label,
                                    discountType: value?.discount_type,
                                    discountTypeValue: value?.discount_type_value,
                                  })
                                }}
                              />
                            </Form.Item>
                          )
                        }}
                      </Form.Item>
                    </Col>
                  )}
                </Row>
                <Row>
                  {isProjectCondoType ? (
                    <Col span={6}>
                      <Form.Item label={'房屋租金'} required shouldUpdate>
                        {f => {
                          const rentPrice = f.getFieldValue('rentPrice')
                          const discountType = f.getFieldValue('discountType')
                          const discountTypeValue = f.getFieldValue('discountTypeValue')

                          return (
                            <Space size={20}>
                              {discountType ? (
                                <>
                                  <Typography.Text>
                                    {discountPrice(rentPrice, discountType, discountTypeValue).toFixed(2)}元/月
                                  </Typography.Text>

                                  <Typography.Text style={{color: '#BFBFBF'}} delete>
                                    {rentPrice}元/月
                                  </Typography.Text>
                                </>
                              ) : (
                                <Typography.Text>{rentPrice}元/月</Typography.Text>
                              )}
                            </Space>
                          )
                        }}
                      </Form.Item>
                    </Col>
                  ) : (
                    <Col span={8}>
                      <Form.Item name={'rentPrice'} label={'房屋租金'} rules={rules.rentPrice}>
                        <Input
                          allowClear
                          placeholder={'请输入金额（元/月）'}
                          onChange={({target}: any) => {
                            if (/^\d*$/.test(target.value) && form.getFieldValue('rentPledgeType')) {
                              if (!isRenewal) {
                                form.setFieldsValue({rentPledge: target.value * form.getFieldValue('rentPledgeType')})
                              }
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>
                  )}

                  {isProjectCondoType ? (
                    <Col span={6} offset={1}>
                      <Form.Item label={'物业费'} required shouldUpdate>
                        {f => {
                          const propertyFee = f.getFieldValue('propertyFee')
                          return <Typography.Text>{propertyFee}元/月</Typography.Text>
                        }}
                      </Form.Item>
                    </Col>
                  ) : null}

                  <Col span={6} offset={1} pull={1}>
                    <Form.Item shouldUpdate noStyle>
                      {() =>
                        isRenewal ? (
                          <Form.Item name={'rentPledge'} label={'增收押金'} rules={rules.rentPledge}>
                            <Input
                              // disabled={form.getFieldValue('rentPledgeType') !== 0}
                              allowClear
                              placeholder={'请输入金额（元）'}
                            />
                          </Form.Item>
                        ) : (
                          <Form.Item name={'rentPledge'} label={'房屋押金'} rules={rules.rentPledge}>
                            <Input
                              // disabled={form.getFieldValue('rentPledgeType') !== 0}
                              allowClear
                              placeholder={'请输入金额（元）'}
                              disabled={isProjectCondoType}
                            />
                          </Form.Item>
                        )
                      }
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item name={'rentCollectType'} label={'收租类型'} rules={rules.rentCollectType}>
                      <Select
                        placeholder={'请选择收租类型'}
                        onSelect={() => form.setFieldsValue({rentCollectDay: ''})}
                        disabled={isProjectCondoType}
                      >
                        {rentCollectType.map(value => (
                          <Option key={value.id} value={value.id}>
                            {value.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Form.Item shouldUpdate noStyle>
                    {() =>
                      form.getFieldValue('rentCollectType') === '1' && (
                        <Col span={6} offset={1} pull={1}>
                          <Form.Item name={'rentCollectDay'} label={'提前天数'} rules={rules.rentCollectDay}>
                            <Select placeholder={'请选择收租日'} disabled={isProjectCondoType}>
                              {Array.from(new Array(32).keys()).map(item => (
                                <Option key={item} value={item}>
                                  {item}天
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      )
                    }
                  </Form.Item>
                  <Form.Item shouldUpdate noStyle>
                    {() =>
                      form.getFieldValue('rentCollectType') === '2' && (
                        <Col span={6} offset={1} pull={1}>
                          <Form.Item
                            name={'rentCollectDay'}
                            label={'每月'}
                            rules={[{required: true, message: '该项不能为空'}]}
                          >
                            <Select placeholder={'请选择'} disabled={isProjectCondoType}>
                              {Array.from(new Array(28).keys()).map(item => (
                                <Option key={item + 1} value={item + 1}>
                                  {item + 1}日
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      )
                    }
                  </Form.Item>

                  <Form.Item shouldUpdate noStyle>
                    {() =>
                      form.getFieldValue('rentCollectType') === '2' && (
                        <Col span={6} offset={1} pull={1}>
                          <Form.Item label={'账单收款日'} required style={{marginBottom: 0}}>
                            <Space>
                              <Form.Item>
                                <span>提前</span>
                              </Form.Item>
                              <Form.Item name={'rentCollectDay2'} rules={[{required: true, message: '该项不能为空'}]}>
                                <Select placeholder={'请选择'} style={{width: 60}} disabled={isProjectCondoType}>
                                  {Array.from(new Array(16).keys()).map(item => (
                                    <Option key={item} value={item}>
                                      {item}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                              <Form.Item>
                                <span>天</span>
                              </Form.Item>
                            </Space>
                          </Form.Item>
                        </Col>
                      )
                    }
                  </Form.Item>

                  {/*ID1001934 删除*/}
                  {/*<Col span={24}>
                    <Form.Item noStyle shouldUpdate>
                      {form1 =>
                        !!form1.getFieldValue('propertyCode') &&
                        !isRenewal && (
                          <Form.Item
                            label={'物业交割'}
                          >
                            <Button
                              style={{paddingLeft: 0}}
                              type={'link'}
                              onClick={() => equipmentModalRef.current.show(equipment)}
                            >
                              前往物业交割
                            </Button>
                          </Form.Item>
                        )
                      }
                    </Form.Item>
                  </Col>*/}

                  {!isRenewal && (
                    <Col span={24}>
                      <Form.Item label={'房东手机号'}>
                        <Space>
                          <Form.Item name={'lessorMobile'} noStyle>
                            <Input />
                          </Form.Item>
                          <Typography.Text>注：仅限租客联系房东使用</Typography.Text>
                        </Space>
                      </Form.Item>
                    </Col>
                  )}

                  <Col span={24}>
                    <Form.Item shouldUpdate label={'其他费用'}>
                      {() => (
                        <Cost
                          billList={isProjectCondoType ? renderChargeItem(projectConfig?.billConfigList) : undefined}
                          costs={form.getFieldValue('billList')}
                          onCostChange={costs => form.setFieldsValue({billList: costs})}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                {renderPropertyInfo()}
              </div>
            </section>

            <Form.Item>
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <Button type={'primary'} loading={calculateGroupLoading} onClick={onCreateBill}>
                  生成账单计划
                </Button>
              </div>
            </Form.Item>

            <Form.Item shouldUpdate={(prevValues, nextValues) => prevValues.propertyCode !== nextValues.propertyCode}>
              {form1 => (
                <EquipmentModal
                  ref={equipmentModalRef}
                  // propertyCode={form1.getFieldValue('propertyCode')}
                  rentType={property.rentType}
                  onSuccess={config => {
                    setEquipment(config)
                  }}
                />
              )}
            </Form.Item>
          </Form>
        </article>
        {/* 确认收款 */}
        <ConfirmCollection ref={confirmCollectionRef} onSuccess={onSuccess} />
      </div>

      <Modal
        width={714}
        title={
          <div
            style={{
              width: '100%',
              cursor: 'move',
            }}
          >
            租约确认
          </div>
        }
        centered
        style={{marginBottom: 0}}
        modalRender={node => <Draggable>{node}</Draggable>}
        bodyStyle={{maxHeight: 'calc(70vh - 108px)', overflowY: 'scroll', overflowX: 'hidden'}}
        mask={false}
        maskClosable={false}
        destroyOnClose
        visible={createBillVisible}
        onCancel={() => setCreateBillVisible(false)}
        footer={
          <Row justify={'end'}>
            <Button onClick={() => setCreateBillVisible(false)}>取消</Button>
            <Button
              disabled={countDown > 0}
              loading={submitLoading}
              type={'primary'}
              onClick={() => form.validateFields().then(() => onSubmit())}
            >
              {countDown ? `确认中(${countDown}s)` : '确认账单'}
            </Button>
          </Row>
        }
      >
        <Form component={false}>
          <Row gutter={68}>
            <Col>
              <Form.Item label={'承租人'}>{form.getFieldValue('lesseeCertName')}</Form.Item>
            </Col>
            <Col>
              <Form.Item label={'电话'}>{form.getFieldValue('lesseeMobile')}</Form.Item>
            </Col>
            <Col>
              <Form.Item label={'租约起止'}>{`${format(form.getFieldValue('contractBegin'), 'YYYY-MM-DD')}至${format(
                form.getFieldValue('contractEnd'),
                'YYYY-MM-DD'
              )}`}</Form.Item>
            </Col>
          </Row>
        </Form>
        <Table
          rowKey={'title'}
          dataSource={tableList}
          size={'middle'}
          // loading={tableLoading}
          columns={[
            {title: '期数', dataIndex: 'title', key: 'title'},
            {title: '账单', dataIndex: 'billAmtTotal', key: 'billAmtTotal'},
            {
              title: '账单周期',
              render: (_, record) => {
                const isNoBillDays = record.list?.some(value => value.interval === -1)

                return !isNoBillDays ? (
                  <Space direction={'vertical'} size={0}>
                    <Typography.Text>起：{record.billBegin}</Typography.Text>
                    <Typography.Text>止：{record.billEnd}</Typography.Text>
                  </Space>
                ) : null
              },
            },
            {
              title: '应收款日',
              dataIndex: 'billDate',
              key: 'billDate',
              render: (value, record) => {
                const isNoBillDays = record.list?.some(value => value.interval === -1)
                return isNoBillDays ? '' : value
              },
            },
          ]}
          expandable={{
            defaultExpandedRowKeys: tableList.slice(0, 2).map(value => value.title),
            expandRowByClick: true,
            expandedRowRender: (record, index, indent, expanded) => {
              return (
                <Space direction={'vertical'} style={{marginLeft: '31px'}}>
                  {record.list.map((item, index) => (
                    <Space key={`${record.title}-${index}`}>
                      <Text>{item.billTypeName}：</Text>
                      <Space>
                        <Typography.Text>{item.billAmtTotal}</Typography.Text>
                        {item.billAmtOrigin !== item.billAmtTotal && (
                          <Typography.Text delete>{item.billAmtOrigin}</Typography.Text>
                        )}
                      </Space>
                      <Button
                        disabled={!!item?.billConfirm || !item?.billAmtTotal || !item?.billAmtUnpaid}
                        size={'small'}
                        type={'link'}
                        onClick={() => {
                          confirmCollectionRef.current.onDisplay()
                          confirmCollectionRef.current.onSetBillConfirm(
                            {...item, communityCode: property.communityCode},
                            true,
                            [item?.interval, index]
                          )
                        }}
                      >
                        收款
                      </Button>
                    </Space>
                  ))}
                </Space>
              )
            },
          }}
          pagination={false}
        />
      </Modal>
    </>
  )
}

function getTypeName(condition: {interval: boolean; billTypeCode: string}) {
  return condition.interval ? '周期费用' : '首期一次性费用'
}
