import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import commonStyle from '@/assets/styles/common.module.less'
import {
  Form,
  Space,
  DatePicker,
  Checkbox,
  Row,
  Button,
  Divider,
  Table,
  Select,
  Pagination,
  Modal,
  Input,
  Col,
  message,
} from 'antd'
import {propertyModel} from '@/store/models/Property'
import {toFixed} from '@/utils/amount'
import {downloadBlob} from '@/utils/util'
import {payModel} from '@/store/models/Pay'
import {usePermission} from '@/ui/components/Permission'
import {lessorModel} from '@/store/models/Lessor'
import {userModel} from '@/store/models/User'
import {tradeModel} from '@/store/models/Trade'
import moment from 'moment'

const {RangePicker} = DatePicker

const chargeOptions = [
  {label: '出账', value: 'out'},
  {label: '进账', value: 'in'},
]

const bMobile = ['13621900775', '17621918766']

interface AccountListProp {
  id: string
  title: string
}

export default function AccountDetail() {
  const [auth] = usePermission()
  const [form] = Form.useForm()
  const mobile = userModel.useData(data => data.userInfo?.mobile)

  const [chargeCheck, setChargeCheck] = useState(false)
  //选中的收支类型
  const [defaultFlowType, setDefaultFlowType] = useState([])

  //查询录入过的小区
  const [communityList, setCommunityList] = useState([])

  const [communityCodeList, setCommunityCodeList] = useState([])

  //选中小区
  const [defaultCommunity, setDefaultCommunity] = useState([])
  const [communityCheckAll, setCommunityCheckAll] = useState(false)

  const currentTeam = userModel.useData(data => data.currentTeam)

  const [lessorCompanyList, setLessorCompanyList] = useState<LessorCompanyVO[]>([])
  const [accountList, setAccountList] = useState<AccountListProp[]>([])
  const firstCompany = lessorCompanyList[0]

  const [total, setTotal] = useState(0)
  const [list, setList] = useState<TradeVO[]>([])
  const [allAmount, setAllAmount] = useState<string>('')
  // const userId = useRef(currentTeam.owner)
  const userId = useRef('')

  //查询账户明细
  const queryBalance = useCallback(function (id: string) {
    // 只用这个接口就可查询余额
    payModel.payBalance({userId: id}).then(({response: {data}}) => {
      setAllAmount(toFixed(+data.allAmount / 100))
    })
  }, [])

  const search = useCallback(async () => {
    const value = form.getFieldsValue(true)
    const {
      response: {data, total},
    } = await tradeModel.tradeWalletFlowList({userId: userId.current, needStatistics: '0', ...value})
    setList(data.tradeFlowList)
    setTotal(total)
  }, [form])

  const initSearch = useCallback(
    async (id: string) => {
      //查询余额
      queryBalance(id)

      //查询列表
      search()
    },
    [queryBalance, search]
  )

  //查询企业账户
  useEffect(() => {
    lessorModel.lessorCompanyList({queryType: 2} as LessorCompanyQueryDTO).then(({response: {data}}) => {
      setLessorCompanyList(data)
      const nextAccountList = [
        ...(bMobile.includes(mobile) ? [] : [{id: currentTeam.owner, title: '团队账户'}]),
        ...(data.length ? data.map(item => ({id: item.companyId, title: item.companyName})) : []),
      ]
      setAccountList(nextAccountList)
      userId.current = nextAccountList[0]?.id

      initSearch(userId.current)
    })
  }, [currentTeam.owner, initSearch, mobile])

  useEffect(() => {
    //查询小区或公寓
    propertyModel.propertyCommunityList({queryType: 2}).then(({response: {data}}) => {
      setCommunityList(data.map(item => ({label: item.name, value: item.code})))
      setCommunityCodeList(data.map(item => item.code))
    })
  }, [])

  const loading = tradeModel.tradeWalletFlowList.useLoading()

  const [withdrawForm] = Form.useForm<{amount: string; verificationCode: string; _applyData: WithdrawApplyVO}>()
  const [withdrawVisible, setWithdrawVisible] = useState(false)
  const sendLoading = payModel.payWithdraw.useLoading()
  const confirmLoading = payModel.payConfirm.useLoading()

  return (
    <>
      <Breadcrumb
        path={['首页', '财务管理', '账户明细']}
        right={
          auth('004007002') && (
            <Button
              onClick={() => {
                const value = form.getFieldsValue(true)
                downloadBlob({
                  url: '/trade/wallet-flow/list-excel-by-team',
                  body: {userId: userId.current, needStatistics: '0', ...value},
                })
              }}
            >
              导出
            </Button>
          )
        }
      />
      <Form
        form={form}
        initialValues={{pageNum: 1, pageSize: 10}}
        onFinish={search}
        onReset={() => {
          search()
          setCommunityCheckAll(false)
          setChargeCheck(false)
          setDefaultCommunity([])
          setDefaultFlowType([])
        }}
      >
        <div className={commonStyle.section}>
          <Row justify={'space-between'}>
            <Space direction={'vertical'}>
              <span>账号余额</span>
              <span style={{fontSize: '28px', fontWeight: 'bold', color: '#333333'}}>{allAmount}</span>
            </Space>

            {!!accountList.length && (
              <Form.Item label={'当前账户'}>
                <Select
                  style={{width: 200}}
                  // defaultValue={currentTeam.owner}
                  defaultValue={accountList[0]?.id}
                  onSelect={id => {
                    if (userId.current !== id) {
                      userId.current = id
                      initSearch(id)
                    }
                  }}
                >
                  {accountList.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Row>
        </div>
        <div className={commonStyle.section}>
          <Form.Item label={'交易日期'} name={'time'}>
            <RangePicker
              onChange={value => {
                if (value) {
                  const [flowBeginDate, flowEndDate] = value.map(item => moment(item).format('YYYY-MM-DD'))
                  form.setFieldsValue({flowBeginDate, flowEndDate})
                }
              }}
            />
          </Form.Item>
          <Divider dashed />
          <Form.Item label={'收支类型'}>
            <Checkbox
              indeterminate={defaultFlowType.length && defaultFlowType.length < chargeOptions.length}
              checked={chargeCheck}
              onChange={e => {
                const list = e.target.checked ? ['in', 'out'] : []
                setDefaultFlowType(list)
                form.setFieldsValue({flowType: ''})
                setChargeCheck(e.target.checked)
              }}
            >
              全部
            </Checkbox>
            <Checkbox.Group
              options={chargeOptions}
              onChange={checkedList => {
                const checkAllResult = checkedList.length === chargeOptions.length
                form.setFieldsValue({flowType: checkAllResult ? '' : checkedList.join(',')})
                setChargeCheck(checkAllResult)
                setDefaultFlowType(checkedList)
              }}
              value={defaultFlowType}
            />
          </Form.Item>
          <Form.Item label={'公寓/小区名'}>
            <Checkbox
              indeterminate={defaultCommunity.length && defaultCommunity.length < communityList.length}
              checked={communityCheckAll}
              onChange={e => {
                const list = e.target.checked ? communityCodeList : []
                setDefaultCommunity(list)
                form.setFieldsValue({communityCode: ''})
                setCommunityCheckAll(e.target.checked)
              }}
            >
              全部
            </Checkbox>
            <Checkbox.Group
              options={communityList}
              value={defaultCommunity}
              onChange={values => {
                const checkAllResult = values.length === communityList.length
                form.setFieldsValue({communityCode: checkAllResult ? '' : values.join(',')})
                setCommunityCheckAll(checkAllResult)
                setDefaultCommunity([...values])
              }}
            />
          </Form.Item>
          <Form.Item>
            <Row justify={'end'}>
              <Space>
                {lessorCompanyList.some(value => value.companyId === userId.current) && (
                  <Button type='primary' onClick={() => setWithdrawVisible(true)}>
                    提现
                  </Button>
                )}
                <Button htmlType={'submit'} type='primary'>
                  查询
                </Button>
                <Button htmlType={'reset'}>重置</Button>
              </Space>
            </Row>
          </Form.Item>
        </div>

        <div className={commonStyle.section}>
          <Table
            rowKey={'tradeId'}
            style={{marginBottom: 20}}
            loading={loading}
            title={() => (
              <Space size={20}>
                <span style={{fontWeight: 600, fontSize: 14}}>查询结果</span>
                <>
                  <span>
                    共计
                    <span style={{fontSize: 14, color: '#007BFF'}}>{total}</span>
                    条符合条件的结果
                  </span>
                </>
              </Space>
            )}
            columns={[
              {title: '收支日期', dataIndex: 'tradeTime', key: 'tradeTime'},
              {title: '地址', dataIndex: 'propertyTitle', key: 'propertyTitle', render: text => text ?? '/'},
              {
                title: '租客姓名',
                dataIndex: 'lesseeCertName',
                key: 'lesseeCertName',
                render: text => text ?? '/',
              },
              {
                title: '收支类型',
                dataIndex: 'tradeFlow',
                key: 'tradeFlow',
                render: text => (text === 'in' ? '进账' : '出账'),
              },
              {title: '账单项目', dataIndex: 'tradeTypeName', key: 'tradeTypeName'},
              {title: '收支金额（元）', dataIndex: 'tradeAmt', key: 'tradeAmt'},
            ]}
            dataSource={list}
            pagination={false}
          />

          <Form.Item
            noStyle
            shouldUpdate={(prev, next) => prev.pageSize !== next.pageSize || next.pageNum !== next.pageNum}
          >
            {() => (
              <Pagination
                total={total}
                current={form.getFieldValue('pageNum')}
                pageSize={form.getFieldValue('pageSize')}
                showSizeChanger
                onChange={(pageNum, pageSize) => {
                  form.setFieldsValue({pageNum, pageSize})
                  search()
                }}
                style={{textAlign: 'center'}}
              />
            )}
          </Form.Item>
        </div>
      </Form>

      <Modal
        title={'提现'}
        visible={withdrawVisible}
        okText={'确认提现'}
        onOk={() => withdrawForm.submit()}
        afterClose={() => withdrawForm.resetFields()}
        onCancel={() => setWithdrawVisible(false)}
        confirmLoading={confirmLoading}
      >
        <Form
          form={withdrawForm}
          labelCol={{span: 6}}
          onFinish={async () => {
            const values = withdrawForm.getFieldsValue(true)
            const withdrawApply = values._applyData

            //提现操作
            const {
              response: {data},
            } = await payModel.payConfirm({
              bizOrderNo: withdrawApply?.bizOrderNo,
              verificationCode: values.verificationCode,
              bizUserId: firstCompany?.companyId,
            } as PayDTO)
            if (['pending', 'success'].includes(data.payStatus)) {
              message.success('提现成功！预计30分钟内到账')
              setWithdrawVisible(false)
              initSearch(userId.current)
            } else {
              message.success(data.payFailMessage)
            }
          }}
        >
          <Form.Item label={'对公账户'}>{`${firstCompany?.parentBankName}（${firstCompany?.accountNo?.slice(
            -4
          )}）`}</Form.Item>
          <Form.Item label={'提现金额'} required help={`可用余额${allAmount}元`}>
            <Row>
              <Col flex={1}>
                <Form.Item noStyle label={'提现金额'} name={'amount'} rules={[{required: true}]}>
                  <Input placeholder={'请输入提现金额'} allowClear />
                </Form.Item>
              </Col>
              <Col flex={'115px'}>
                <Button type={'link'} onClick={() => withdrawForm.setFieldsValue({amount: allAmount})}>
                  全部提现
                </Button>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item label={'验证码'} required>
            <Row>
              <Col flex={1}>
                <Form.Item noStyle label={'验证码'} name={'verificationCode'} rules={[{required: true}]}>
                  <Input placeholder={'请输入验证码'} allowClear />
                </Form.Item>
              </Col>
              <Col flex={'115px'}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, nextValues) => prevValues._applyData !== nextValues._applyData}
                >
                  {f => {
                    const _applyData = f.getFieldValue('_applyData')

                    return (
                      <Button
                        disabled={!!_applyData}
                        type={'link'}
                        loading={sendLoading}
                        onClick={async () => {
                          await withdrawForm.validateFields(['amount'])
                          const values = withdrawForm.getFieldsValue(true)
                          const {
                            response: {data},
                          } = await payModel.payWithdraw({
                            amount: values.amount * 100,
                            bankCardNo: firstCompany?.accountNo,
                            bankCardPro: 1,
                            bizUserId: firstCompany?.companyId,
                          } as WithdrawApplyDTO)
                          message.success('已发送')

                          withdrawForm.setFieldsValue({_applyData: data})
                        }}
                      >
                        {_applyData ? '已发送' : '发送验证码'}
                      </Button>
                    )
                  }}
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>

          <p style={{marginBottom: 5}}>提示</p>
          <p style={{marginBottom: 5}}>1、您的提现预计1个工作日内到账</p>
          <p style={{marginBottom: 5}}>2、每天提现不能操作10次，提现操作不可取消，请谨慎操作</p>
        </Form>
      </Modal>
    </>
  )
}
