import React, {useEffect, useState, useMemo, useRef} from 'react'
import styles from './index.module.less'
import commonStyle from '@/assets/styles/common.module.less'
import {
  Tabs,
  Typography,
  Row,
  Col,
  Button,
  Carousel,
  Space,
  Table,
  Statistic,
  Skeleton,
  Modal,
  Form,
  Input,
  message,
  Dropdown,
  Menu,
  Descriptions,
  Select,
} from 'antd'
import {contractModel} from '@/store/models/Contract'
import imgEmpty from '@/assets/imgs/img_empty.svg'
import pdf from '@/assets/imgs/pdf.svg'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {DownOutlined, UpOutlined} from '@ant-design/icons'
import {propertyModel} from '@/store/models/Property'
import {createSplit, downloadBlob, getImg} from '@/utils/util'
import dayjs from 'dayjs'
import CollectionDetail from '@/pages/contract/components/CollectionDetail'
import BillDetailAction from '@/pages/financial/components/BillDetail'
import ConfirmCollectionAction from '@/pages/financial/components/ConfirmCollection'
import {Album} from '@/ui/components/Album'
import AddPeriodBill from '@/pages/contract/components/AddPeriodBill'
import BillDivision from '@/pages/financial/components/BillDivision'
import {baseUrl} from '@/common/config'
import {Model} from '@redux-model/web'
import {Permission, usePermission} from '@/ui/components/Permission'
import {add} from '@/utils/amount'
import PropertyCheckOut from '@/pages/contract/components/PropertyCheckOut'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import {EquipmentDTOModel, EquipmentModal, EquipmentRef} from '@/pages/contract/TenantRegister/EquipmentModal'
import empty from '@/assets/icons/empty.svg'
import {AddCheckin} from '@/pages/contract/components/AddCheckin'
import {renderSectionList} from '@/utils/render'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {rules} from '@/utils/validate'
import {AuditButton} from '@/ui/bizComponents/AuditButton'
import ChargeModal from '@/pages/contract/ContractDetail/ChargeModal'
import ContractDetailAccounting from '@/pages/contract/ContractDetail/ContractDetailAccounting'
import {useIsProjectCondo} from '@/common/bizHooks/useIsProjectCondo'
import {useApplyCheck} from '@/utils/lesseeApplyCheck'
import {ContractLogList} from '@/pages/contract/components/ContractLogList'

// const {Option} = Select
const {TabPane} = Tabs
const {Text, Title, Paragraph} = Typography
// const {RangePicker} = DatePicker

// 3 个 render
interface List {
  type?: '1' | '2'
  billId: string // 租约
  category: string // 租约
  billTime: string // 账单周期
  billAmtTotal: number // 应收金额
  billAmtPaid: number // 已收金额
  list: ContractBillListVO[]
  // children: object[]

  // originData: any
  // secondTable: any[] // 用第一个 table 的 columns
}

function getBillTime(item: Pick<ContractBillListVO, 'billBegin' | 'billEnd'>) {
  return item.billBegin ? [item.billBegin, item.billEnd].filter(item => item).join('至') : '无账期'
}

interface GroupList extends Partial<Pick<ContractBillListVO, 'contractDetailTitle' | 'contractBegin' | 'contractEnd'>> {
  category: string
  billTime?: string
  billStatus?: string
  billAmtTotal?: number
  billAmtPaid?: number
  list: ContractBillListVO[]
}

function groupList(
  list: ContractBillListVO[],
  {
    renderHeader,
    groupBy,
  }: {renderHeader: (item: ContractBillListVO, index: number) => string; groupBy: (item: ContractBillListVO) => string}
) {
  const res = list.reduce<{
    keys: string[]
    data: {[key: string]: GroupList}
  }>(
    (prev, item) => {
      const key = groupBy(item)
      if (!prev.keys.includes(key)) {
        prev.keys.push(key)
        prev.data[key] = {
          category: renderHeader(item, prev.keys.length),
          billTime: getBillTime(item),
          contractDetailTitle: item.contractDetailTitle,
          contractBegin: item.contractBegin,
          contractEnd: item.contractEnd,
          billAmtTotal: 0,
          billAmtPaid: 0,
          billStatus: '已完成',
          list: [],
        }
      }
      if (item.billStatus === '1') {
        prev.data[key].billStatus = ''
      }
      if (!['3', '6'].includes(item.billStatus)) {
        prev.data[key].billAmtTotal = add(prev.data[key].billAmtTotal, item.billAmtTotal)
        prev.data[key].billAmtPaid = add(prev.data[key].billAmtPaid, item.billAmtPaid)
      }
      prev.data[key].list.push(item)

      return prev
    },
    {
      keys: [] as string[],
      data: {} as {
        [key: string]: GroupList
      },
    }
  )
  return res.keys.map(value => res.data[value])
}

interface NewContractBill extends ContractBill {
  billDetailListObj: any
}

// interface BillDetail {
//   [key: string]: NewContractBill
// }

export default function ContractDetail() {
  const [auth] = usePermission()
  const history = useAppHistory()
  const location = useAppLocation<'/contract/detail'>()
  const state_contractId = location.state?.contractId
  const state_activeKey = location.state?.activeKey
  const state_addBill = location.state?.addBill

  // 租约详情
  const [detail, setDetail] = useState({} as ContractVO)
  // 租约结账详情
  const [checkOutDetail, setCheckDetail] = useState({} as ContractCheckOutVO)
  // 入住人信息
  const [checkinList, setCheckinList] = useState([] as ContractPersonCheckinVO[])
  // 房源详情
  const [propertyDetail, setPropertyDetail] = useState({} as PropertyAllInfoVO)
  // 账务信息
  const [_billList, setBillList] = useState<GroupList[]>([])

  const checkoutType = useCodeSync('contract.checkoutType')

  const billList = useMemo(
    () =>
      renderSectionList(_billList, {
        groupBy: item => item.contractDetailTitle,
        renderTitle: item => item.contractDetailTitle,
      }),
    [_billList]
  )

  const [changeLesseeVisible, setChangeLesseeVisible] = useState(false)

  // 账务信息每一项详情
  // const [billDetail, setBillDetail] = useState({} as BillDetail)
  // 收款明细详情弹框
  const collectionDetailRef = useRef(null)
  // 添加账单详情弹框
  const addPeriodBillRef = useRef(null)
  // 物业交割弹窗
  const equipmentModalRef = useRef<EquipmentRef>(null)
  // 物业交割表单, 默认 null
  const [equipment, setEquipment] = useState<EquipmentDTOModel>(null)
  //查看图片
  const [showImage, setShowImage] = useState('')
  //房间物品列表
  const equipmentList = useMemo(() => equipment?.equipmentList?.filter(item => item.amt), [equipment])
  //公区物品列表
  const equipmentPublicList = useMemo(() => equipment?.equipmentPublicList?.filter(item => item.amt), [equipment])
  //水煤电列表
  const meterList = useMemo(() => equipment?.meterList?.filter(item => item.value), [equipment])

  // 添加入住人表单
  // const [form] = Form.useForm()
  // // 是否显示表单
  // const [showForm, setShowForm] = useState(false)
  // // 入住人id
  // const [personId, setPersonId] = useState('')
  // 详情激活状态
  const [activeKey, setActiveKey] = useState('detailInfo')

  // 账单详情弹窗ref
  const billDetailRef = useRef(null)
  // 确认收款弹窗ref
  const confirmCollectionRef = useRef(null)
  // 账单拆分弹窗ref
  const billDivisionRef = useRef(null)
  //扣款列表
  const [cutList, setCutList] = useState([] as ContractCheckOutDetailRecordVO[])
  //退款列表
  const [refundList, setRefundList] = useState([] as ContractCheckOutDetailRecordVO[])
  //查看详情
  const [showDetail, setShowDetail] = useState(false)
  // 修改
  const [changeForm] = Form.useForm()
  const [alterAmt, setAlterAmt] = useState({
    show: false,
    billId: undefined,
    billAmtTotal: undefined,
    propertyTitle: undefined,
  })

  const [accountingForm] = Form.useForm()
  const logRef = useRef(null)

  const showBrokerage = detail.rentType === '3'
  const showBrokerageInput = () => ['1', '2'].includes(detail.brokerageType)

  const contractType = useCodeSync('contract.type')
  const rentType = useCodeSync('rentType')
  const gender = useCodeSync('gender')
  const contractStatus = useCodeSync('contract.status')
  const checkoutReason = useCodeSync('contract.checkOutReason')
  const billStatus = useCodeSync('bill.status')
  const contractBrokerage = useCodeSync('contract.brokerage')
  const [contractRemark, setContractRemark] = useState('')
  const contractRemarkLoading = contractModel.contractUpdateContractRemark.useLoading()
  // const billStatusColor = useMemo(
  //   () => ({
  //     '1': '#FFB240',
  //     '2': '#409EFF',
  //     '3': '#9EA0A7',
  //     '4': '#9EA0A7',
  //     '5': '#9EA0A7',
  //     '6': '#F56C6C',
  //   }),
  //   []
  // )
  const [applyCheck] = useApplyCheck()

  const [cancelable, setCancelable] = useState(false)
  useEffect(() => {
    if (state_contractId) {
      contractModel
        .contractQueryCancelStatus({contractId: state_contractId})
        .then(({response: {data}}) => setCancelable(!!data))
    }
  }, [state_contractId])

  // 证件类型
  const contractCertType = useCodeSync('contract.certType')
  const loading = Model.isLoading(
    contractModel.contractQueryContract.useLoading(),
    contractModel.contractCheckinPersonList.useLoading(),
    propertyModel.propertyDetail.useLoading(),
    contractModel.billQueryByContractId.useLoading()
  )
  // const billDetailLoading = contractModel.billQuery.useLoading()

  // 显示退房操作弹窗
  const [visible, setVisible] = useState(false)
  // 设置contractId
  const [checkValue, setCheckValue] = useState({contractId: '', contractEnd: '', condoProject: false})
  const propertyCheckOutRef = useRef(null)

  // 换房
  const chargeModalRef = useRef(null)

  // 是否是续订锁房
  const [isRenewLock, setIsRenewLock] = useState(false)

  useEffect(() => {
    if (state_contractId) {
      contractModel.contractQueryContract({contractId: state_contractId}).then(({response: {data}}) => {
        setDetail(data)
        setIsRenewLock(!!data.renewId)
        setContractRemark(data.contractRemark)
        setCheckDetail(data.checkOutDetail)
        setCutList(data.checkOutDetail?.checkoutDetailList.filter(item => item.detailType === '1'))
        setRefundList(data.checkOutDetail?.checkoutDetailList.filter(item => item.detailType === '2'))
      })

      contractModel.contractCheckinPersonList({contractId: state_contractId}).then(({response: {data}}) => {
        setCheckinList(data)
      })

      billQueryByContractId(state_contractId)

      queryDeliveryInfo(state_contractId)
    }
  }, [state_contractId])

  useEffect(() => {
    if (detail.propertyCode) {
      propertyModel.propertyDetail({propertyCode: detail.propertyCode}).then(({response: {data}}) => {
        setPropertyDetail(data)
      })
    }
  }, [detail.propertyCode])

  const checkOutDetailList = useMemo(
    () =>
      [
        {
          title: '扣除租客金额：',
          label:
            checkOutDetail?.cutAmt || checkOutDetail?.debtAmt
              ? `${checkOutDetail?.cutAmt + checkOutDetail?.debtAmt}元`
              : '0元',
        },
        {
          title: '实际退房时间：',
          label: checkOutDetail?.billEndDate,
        },
        {
          title: '应退租客金额：',
          label: checkOutDetail?.refundAmt ? `${checkOutDetail?.refundAmt}元` : '0元',
        },
        {
          title: '退房原因：',
          label: checkoutReason[detail?.checkOutReason],
        },
        {
          title: '总计：',
          label: checkOutDetail?.totalAmt
            ? checkOutDetail?.totalAmt > 0
              ? `${checkOutDetail?.totalAmt}元（退给租客${checkOutDetail?.totalAmt}元）`
              : `${Math.abs(checkOutDetail?.totalAmt)}元（向租客收取${Math.abs(checkOutDetail?.totalAmt)}元）`
            : '',
        },
        {
          title: '坏账：',
          label: checkOutDetail?.badAmt ? `${checkOutDetail?.badAmt}元` : '0元',
        },
        {
          title: '退款途径：',
          label: checkOutDetail?.rfdPayWayName,
        },
        {
          title: '退房备注：',
          label: detail.checkOutRemark || '无',
        },
        {
          title: '退款备注：',
          label: checkOutDetail?.rfdRemark || '无',
        },
      ].filter(item => item.label),
    [
      checkOutDetail?.badAmt,
      checkOutDetail?.billEndDate,
      checkOutDetail?.cutAmt,
      checkOutDetail?.debtAmt,
      checkOutDetail?.refundAmt,
      checkOutDetail?.rfdPayWayName,
      checkOutDetail?.rfdRemark,
      checkOutDetail?.totalAmt,
      checkoutReason,
      detail?.checkOutReason,
      detail?.checkOutRemark,
    ]
  )

  /**
   * 处理路由跳转传入参数state
   */
  useEffect(() => {
    if (state_activeKey) {
      setActiveKey(state_activeKey)
      history.replace('/contract/detail', {contractId: state_contractId})
    }
  }, [history, state_activeKey, state_contractId])

  const detail_contractId = detail?.contractId
  const detail_communityCode = detail?.communityCode

  useEffect(() => {
    // 等待 detail 的 contractId 返回
    if (detail_contractId) {
      if (state_addBill) {
        addPeriodBillRef.current.onDisplay()
        addPeriodBillRef.current.onSetInfo(state_contractId, detail_communityCode)
        history.replace('/contract/detail', {contractId: state_contractId})
      }
    }
  }, [detail_contractId, detail_communityCode, history, state_addBill, state_contractId])

  // 记账
  const [chargeList, setChargeList] = useState<ContractBillListVO[]>([])
  const [billListCount, setBillListCount] = useState({} as ContractBillListCountVO)

  /**
   * 查询租约账单列表
   */
  function billQueryByContractId(contractId: string) {
    contractModel.billQueryByContractId(contractId).then(({response: {data}}) => {
      const split = createSplit<ContractBillListVO>(
        item => item.billCategory === 'b.b', // 押金
        item => item.billCategory !== 'b.b' && item.initBill !== '1', // 记账
        item => !(item.billCategory === 'b.b') && !(item.billCategory !== 'b.b' && item.initBill !== '1') // 周期
      )
      const [pledge, _charge, _period] = split(data)
      // charge.forEach()
      const [charge, period] = _charge.reduce(
        (prev, item) => {
          if (prev[1].some(periodItem => getBillTime(periodItem) === getBillTime(item))) {
            prev[1].push(item)
          } else {
            prev[0].push(item)
          }

          return prev
        },
        [[], _period]
      )

      const countPledge = pledge.filter(item => !['3', '6'].includes(item.billStatus))
      const pledgeList: GroupList[] = pledge.length
        ? [
            {
              category: '押金',
              // billTime: '',
              billAmtTotal: countPledge.reduce((prev, item) => add(prev, item.billAmtTotal), 0),
              billAmtPaid: countPledge.reduce((prev, item) => add(prev, item.billAmtPaid), 0),
              billStatus: countPledge.every(item => item.billStatus !== '1') ? '已完成' : '',
              list: pledge,
            },
          ]
        : []
      const periodList = groupList(period, {
        groupBy: item => getBillTime(item),
        renderHeader: (item, i) => `第${i}期`,
      })

      setBillList([...pledgeList, ...periodList])
      setChargeList(charge)

      // if (data.length) {
      //   // 押金
      //   // const pledgeList = data.filter(item => item.billCategory === 'b.b')
      //   list.push({
      //     billId: '押金',
      //     category: '押金',
      //     billTime: getBillTime(pledgeList[0]),
      //     billAmtTotal: pledgeList
      //       .filter(item => !['3', '6'].includes(item.billStatus))
      //       .reduce((total, cur) => add(total, cur.billAmtTotal), 0),
      //     billAmtPaid: pledgeList.reduce((total, cur) => add(total, cur.billAmtPaid), 0),
      //     list: pledgeList,
      //     // children: pledgeList.map(_item => ({..._item, category: _item.billTypeName, children: []})),
      //   })
      //
      //   // 无账期
      //   // const noPeriodList = data.filter(item => item.billCategory !== 'b.b' && !(item.billBegin && item.billEnd))
      //   // noPeriodList.length &&
      //   //   list.push({
      //   //     billId: Math.random().toString(36).substr(2),
      //   //     category: '无账期',
      //   //     billAmtTotal: noPeriodList
      //   //       .filter(item => !['3', '6'].includes(item.billStatus))
      //   //       .reduce((total, cur) => add(total, cur.billAmtTotal), 0),
      //   //     billAmtPaid: noPeriodList.reduce((total, cur) => add(total, cur.billAmtPaid), 0),
      //   //     children: noPeriodList.map(_item => ({..._item, category: _item.billTypeName, children: []})),
      //   //   })
      //
      //   // 账期内
      //   const periodList = data.filter(item => item.billCategory !== 'b.b' && item.initBill === '1')
      //   const dateInterval = [...new Set(periodList.map(item => getBillTime(item)))]
      //
      //   dateInterval.map((key, index) => {
      //     list.push({
      //       // billId: Math.random().toString(36).substr(2),
      //       billId: `第${index + 1}期 (${key})`,
      //       category: `第${index + 1}期 (${key})`,
      //       billTime: getBillTime(pledgeList[0]),
      //       billAmtTotal: periodList
      //         .filter(item => key === getBillTime(item))
      //         .filter(item => !['3', '6'].includes(item.billStatus))
      //         .reduce((total, cur) => add(total, cur.billAmtTotal), 0),
      //       billAmtPaid: periodList
      //         .filter(item => key === getBillTime(item))
      //         .reduce((total, cur) => add(total, cur.billAmtPaid), 0),
      //       children: periodList
      //         .filter(item => key === getBillTime(item))
      //         .map(_item => ({..._item, category: _item.billTypeName, children: []})),
      //     })
      //   })
      // }
      // setBillList(list)
    })

    contractModel.contractBillQueryListCount({contractId, pageable: false}).then(({response: {data}}) => {
      setBillListCount(data)
    })
  }

  /**
   * 账单详情
   */
  // function billQuery(billId: string) {
  //   contractModel.billQuery(billId).then(({response: {data}}) => {
  //     const _billDetailList = {
  //       收款金额: data.billDetailList.filter(item => item.billPayWayName !== '结算抵扣'),
  //       账务结算: data.billDetailList.filter(item => item.billPayWayName === '结算抵扣'),
  //     }
  //     setBillDetail({...billDetail, [billId]: {...data, billDetailListObj: _billDetailList}})
  //   })
  // }

  /**
   * 表格展开
   */
  // function onExpand(expanded, record) {
  //   if (expanded && !record.children.length && !(record.billId in billDetail)) {
  //     billQuery(record?.billId)
  //   }
  // }

  /**
   * 表格展开操作
   */
  // function onAction(billId: string, event) {
  //   if (!(billId in billDetail)) {
  //     billQuery(billId)
  //   }
  //   event.stopPropagation()
  // }

  /**
   * 表格展开操作项
   */
  async function onActionItem(vo: ContractBillListVO, event, key) {
    if (key === 'detail') {
      billDetailRef.current.onDisplay()
      billDetailRef.current.onSetBillDetail(vo, vo.billId)
    }

    if (key === 'confirm') {
      confirmCollectionRef.current.onDisplay()
      confirmCollectionRef.current.onSetBillDetail({
        ...vo,
        communityCode: detail?.communityCode,
      })
    }

    if (key === 'division') {
      billDivisionRef.current.onDisplay()
      billDivisionRef.current.onSetInfo(vo?.contractId, vo?.billId, vo?.billAmtUnpaid, vo?.billBegin)
    }

    if (key === 'change') {
      setAlterAmt({
        show: true,
        billId: vo.billId,
        billAmtTotal: vo?.billAmtTotal,
        propertyTitle: vo?.propertyTitle,
      })
    }
    // }
    event.stopPropagation()
  }

  /**
   * 收款明细详情
   */
  function onCollectionDetail(billDetailId: string, billInfo: any) {
    collectionDetailRef.current.onDisplay(billDetailId, billInfo)
    // collectionDetailRef.current.setCollectionDetail(billDetailId)
  }

  /**
   * 查询入住人列表
   */
  function personList() {
    contractModel.contractCheckinPersonList({contractId: state_contractId}).then(({response: {data}}) => {
      setCheckinList(data)
    })
  }

  /**
   * 添加入住人
   */
  // function onSubmit() {
  //   form.validateFields().then(async () => {
  //     const value = form.getFieldsValue(true)
  //     const [certEffectStart, certEffectEnd] = value.time ?? [undefined, undefined]
  //     const params = {
  //       ...value,
  //       contractId: detail.contractId,
  //       checkinTime: moment(value.checkinTime).format('YYYY-MM-DD HH:mm:ss'),
  //       certEffectStart: certEffectStart ? moment(certEffectStart).format('YYYY-MM-DD HH:mm:ss') : '',
  //       certEffectEnd: certEffectEnd ? moment(certEffectEnd).format('YYYY-MM-DD HH:mm:ss') : '',
  //       birthday: value.birthday ? moment(value.birthday).format('YYYY-MM-DD HH:mm:ss') : '',
  //       laborContractStart: value.laborContractStart
  //         ? moment(value.laborContractStart).format('YYYY-MM-DD HH:mm:ss')
  //         : '',
  //     }
  //     if (personId) {
  //       await contractModel.checkinPersonUpsert({...params, personId})
  //       setPersonId('')
  //       message.success('编辑成功')
  //     } else {
  //       await contractModel.checkinPersonUpsert(params)
  //       message.success('添加成功')
  //     }
  //     setShowForm(false)
  //     personList()
  //   })
  // }

  /**
   * 删除入住人
   */
  // async function onDelete(personId) {
  //   await contractModel.checkinPersonDelete(personId)
  //   message.success('删除成功！')
  //   personList()
  // }

  /**
   * 打开电子合同
   */
  function onOpenPdf(contractUrl: string, contractDetailId: string) {
    window.open(getImg(contractUrl) || `${baseUrl}/template/contract-detail?contractDetailId=${contractDetailId}`)
  }

  /**
   * 更新租约图片
   */
  async function updateSubcontractImages(contractDetailId, list) {
    if (list.every(item => item.imgId) && list.length) {
      await contractModel
        .contractUpdateImg(
          contractDetailId,
          list.map(item => item.imgId)
        )
        .then(() => {
          message.success('更新成功')
        })
    }
  }

  /**
   * 添加账单
   */
  function addBill() {
    addPeriodBillRef.current.onDisplay()
    addPeriodBillRef.current.onSetInfo(state_contractId, detail.communityCode)
  }

  /**
   * 账单添加成功 查看 收款
   */
  function onSuccess(billId?: string) {
    billQueryByContractId(detail.contractId)
    // if (billId) {
    //   billQuery(billId)
    // }
  }

  /**
   * 显示退房操作弹窗
   */
  function onModalShow(args: {
    contractId: string
    contractEnd: string
    condoProject: boolean
    event?: any
    communityCode?: string
  }) {
    const {event, ...value} = args
    setCheckValue(value)
    setVisible(true)
    event && event.stopPropagation()
  }

  /**
   * 退房操作&结账操作
   */
  function onCheckOut(args: {
    type: 'checkout' | 'settleAccounts' | 'all'
    event: React.MouseEvent
    contractId: string
    condoProject: boolean
    contractEnd?: string
    communityCode?: string
  }) {
    const {type, event, ...value} = args
    propertyCheckOutRef.current.onSetValue(value)
    setVisible(false)
    propertyCheckOutRef.current.onDisplay()
    propertyCheckOutRef.current.onShow(type)
    event.stopPropagation()
  }

  /**
   * 查询物业交割详情
   */
  function queryDeliveryInfo(contractId: string) {
    contractModel.contractEquipmentInfo({contractId}).then(({response: {data}}) => {
      // setEquipmentList(data?.equipmentList)
      // setEquipmentPublicList(data?.equipmentPublicList)
      // setMeterList(data?.meterList)
      setEquipment(data as EquipmentDTOModel)
    })
  }

  // 房源详情
  const propertyInfo = (
    <>
      <Title level={4}>{detail.propertyTitle}</Title>
      <div className={styles.propertyInfo}>
        <Row style={{marginBottom: '7px'}}>
          <Col span={8}>
            <Text>承租人：{detail.lesseeCertName}</Text>
          </Col>
          <Col span={8}>
            <Text>承租人手机：{detail.lesseeMobile}</Text>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Text>
              租金：{detail.contractDetailList?.[0]?.rentPrice}
              元/月（{contractType[detail.contractDetailList?.[0]?.contractType]}）
            </Text>
          </Col>
          <Col span={8}>
            <Text>
              租期：{detail.contractBegin}至{detail.contractEnd}（
              {dayjs(detail.contractBegin).from(dayjs(detail.contractEnd), true)}）
            </Text>
          </Col>
          <Col span={8}>
            <Text>
              付款方式：
              {`付${detail.contractDetailList?.[0]?.rentPayType ?? ''}押${
                detail.contractDetailList?.[0]?.rentPledgeType === 0
                  ? '自定义'
                  : detail.contractDetailList?.[0]?.rentPledgeType
              }`}
            </Text>
          </Col>
        </Row>
      </div>
    </>
  )

  const renderOperation = (record1: ContractBillListVO) => {
    if (!auth('003001004')) return null

    let list = [{key: 'detail', label: '查看', permission: '003001004'}]
    if (record1.billStatus === '1') {
      list.unshift(
        {key: 'confirm', label: '收款', permission: '003001022'},
        {key: 'division', label: '拆分', permission: '003001023'},
        {key: 'change', label: '修改', permission: '003001024'}
      )
    }
    list = list.filter(value => auth(value.permission))
    // 取操作的第一项
    const btn = list.shift()

    return record1.billStatus ? (
      list.length ? (
        <Dropdown
          trigger={['hover']}
          overlay={
            <Menu onClick={event => onActionItem(record1, event.domEvent, event.key)}>
              {list.map(item => (
                <Menu.Item key={item.key}>{item.label}</Menu.Item>
              ))}
            </Menu>
          }
        >
          <Button style={{minWidth: 62}} onClick={event => onActionItem(record1, event, btn.key)} size={'small'}>
            {btn.label} <DownOutlined />
          </Button>
        </Dropdown>
      ) : btn ? (
        <Button style={{minWidth: 62}} onClick={event => onActionItem(record1, event, btn.key)} size={'small'}>
          {btn.label}
        </Button>
      ) : null
    ) : null
  }

  const isProjectCondo = useIsProjectCondo()

  return (
    <>
      <Breadcrumb path={['首页', '租约管理', '租约列表', '电子租约详情']} />
      <div className={commonStyle.flexFullSection}>
        <article>
          <Tabs
            activeKey={activeKey}
            onChange={key => setActiveKey(key)}
            tabBarExtraContent={
              <Space>
                {['detailInfo'].includes(activeKey) && detail.contractStatus === '2' && auth('002001024') && (
                  <AuditButton
                    onClick={() => {
                      if (detail.lesseeType !== 'person') return message.warning('只有个人租约可以更换承租人')
                      return setChangeLesseeVisible(true)
                    }}
                    rentType={detail.rentType}
                    communityCode={detail.communityCode}
                    contractId={detail.contractId}
                  >
                    更换承租人
                  </AuditButton>
                )}
                {cancelable && ['detailInfo'].includes(activeKey) && auth('003001016') && !isRenewLock && (
                  <AuditButton
                    preCheck={async () => await applyCheck(detail.contractId)}
                    onClick={event => {
                      event.stopPropagation()
                      history.push('/contract/register', {
                        contractId: detail.contractId,
                        from: 'alter',
                      })
                    }}
                    rentType={detail.rentType}
                    communityCode={detail.communityCode}
                    contractId={detail.contractId}
                  >
                    修改
                  </AuditButton>
                )}
                {['detailInfo'].includes(activeKey) && (
                  <>
                    <Button onClick={() => logRef.current?.setVisible(true)}>操作记录</Button>
                    {!!detail.contractIdOrigin && (
                      <AuditButton
                        onClick={() => history.push('/contract/detail', {contractId: detail.contractIdOrigin})}
                        contractId={detail.contractId}
                        communityCode={detail.communityCode}
                        rentType={detail.rentType}
                      >
                        原租约
                      </AuditButton>
                    )}
                    {detail.contractStatus === '2' &&
                      dayjs().isBefore(dayjs(detail.contractEnd)) &&
                      auth('003001017') &&
                      !isRenewLock && (
                        <Button
                          onClick={async () => {
                            await applyCheck(detail.contractId)
                            chargeModalRef.current.onCharge({
                              contractId: detail.contractId,
                              rentType: detail.rentType,
                              communityCode: detail.communityCode,
                            })
                          }}
                        >
                          换房
                        </Button>
                      )}
                  </>
                )}

                {['delivery'].includes(activeKey) && (
                  <AuditButton
                    onClick={() => {
                      equipmentModalRef.current.show(equipment)
                    }}
                    rentType={detail.rentType}
                    communityCode={detail.communityCode}
                    contractId={detail.contractId}
                  >
                    交割
                  </AuditButton>
                )}
                {['accounting'].includes(activeKey) && (
                  <Button
                    onClick={() => {
                      downloadBlob({url: '/contract/account/list-excel', body: accountingForm.getFieldsValue(true)})
                    }}
                  >
                    导出
                  </Button>
                )}
                {['billInfo'].includes(activeKey) &&
                  ['1', '2', '3'].includes(detail.contractStatus) &&
                  auth('002001021') && (
                    <AuditButton
                      type={'primary'}
                      onClick={() => addBill()}
                      rentType={detail.rentType}
                      communityCode={detail.communityCode}
                      contractId={detail.contractId}
                    >
                      添加账单
                    </AuditButton>
                  )}

                {/*都有续约*/}
                {detail.contractStatus === '2' && auth('002001015') && !isRenewLock && (
                  <AuditButton
                    type={'primary'}
                    preCheck={async () => await applyCheck(detail.contractId)}
                    onClick={(event, condoProject) => {
                      event.stopPropagation()
                      if (condoProject && dayjs().add(60, 'day').isBefore(dayjs(detail.contractEnd))) {
                        return message.warning('距离租期结束超过60天，不可提前续约')
                      }
                      if (propertyDetail.reserveContractId) return message.warning('该房源已超前预订，不可进行续约!')
                      history.push('/contract/register', {
                        from: 'renewal',
                        contractId: detail.contractId,
                      })
                    }}
                    rentType={detail.rentType}
                    communityCode={detail.communityCode}
                    contractId={detail.contractId}
                  >
                    续约
                  </AuditButton>
                )}

                {/*都有退房*/}
                {['1', '2', '3'].includes(detail.contractStatus) && auth('002001016') && !isRenewLock && (
                  <AuditButton
                    type={'primary'}
                    preCheck={async () => await applyCheck(detail.contractId)}
                    onClick={(event, condoProject) =>
                      onModalShow({
                        event,
                        condoProject,
                        contractId: detail.contractId,
                        contractEnd: detail.contractEnd,
                        communityCode: detail.communityCode,
                      })
                    }
                    rentType={detail.rentType}
                    communityCode={detail.communityCode}
                    contractId={detail.contractId}
                  >
                    退房
                  </AuditButton>
                )}
              </Space>
            }
          >
            <TabPane tab={'电子租约详情'} key={'detailInfo'} className={styles.detail}>
              <section style={{width: '100%'}}>
                <div className={styles.header}>基本信息</div>
                <div className={styles.content}>
                  {loading ? (
                    <Skeleton active />
                  ) : (
                    <Row>
                      <Col span={12}>
                        <Text>租约编号：{detail.contractId}</Text>
                      </Col>
                      <Col span={12}>
                        <Text>租约状态：{contractStatus[detail.contractStatus]}</Text>
                      </Col>
                      <Col span={12}>
                        <Text>签约单类型：{contractType[detail.contractType]}</Text>
                      </Col>
                      <Col span={12}>
                        <Text>房源标题：{detail.propertyTitle}</Text>
                      </Col>
                      <Col span={12}>
                        <Text>房东姓名：{detail.lessorName}</Text>
                      </Col>
                      <Col span={12}>
                        <Text>房东手机：{detail.lessorMobile}</Text>
                      </Col>

                      {detail.lesseeType === 'person' ? (
                        <>
                          <Col span={12}>
                            <Text>
                              承租人姓名：{`${detail.lesseeCertName ?? ''}(${gender[detail.lesseeGender] ?? ''})`}
                            </Text>
                          </Col>
                          <Col span={12}>
                            <Text>承租人手机号：{detail.lesseeMobile}</Text>
                          </Col>
                          <Col span={12}>
                            <Text>证件类型：{contractCertType[detail.lesseeCertType]}</Text>
                          </Col>
                          <Col span={12}>
                            <Text>证件号码：{detail.lesseeCertNo}</Text>
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col span={12}>
                            <Text>公司名称：{detail.lesseeOrganName}</Text>
                          </Col>
                          <Col span={12}>
                            <Text>公司营业执照：{detail.lesseeOrganCode}</Text>
                          </Col>
                          <Col span={12}>
                            <Text>联系人：{detail.lesseeCertName}</Text>
                          </Col>
                          <Col span={12}>
                            <Text>联系人手机：{detail.lesseeMobile}</Text>
                          </Col>
                          <Col span={12}>
                            <Text>证件类型：{contractCertType[detail.lesseeCertType]}</Text>
                          </Col>
                          <Col span={12}>
                            <Text>证件号码：{detail.lesseeCertNo}</Text>
                          </Col>
                        </>
                      )}
                      {showBrokerage && (
                        <>
                          <Col span={12}>
                            <Text>获客渠道：{contractBrokerage[detail.brokerageType]}</Text>
                          </Col>
                          {showBrokerageInput && (
                            <Col span={12}>
                              <Text>佣金：{detail.brokerage}</Text>
                            </Col>
                          )}
                        </>
                      )}
                      <Col span={12}>
                        <Text>租约起始日：{`${detail.contractBegin ?? ''}至${detail.contractEnd ?? ''}`}</Text>
                      </Col>
                      <Col span={12}>
                        <Text>租期：{`${dayjs(detail.contractBegin).from(dayjs(detail.contractEnd), true)}`}</Text>
                      </Col>
                      <Col span={12}>
                        <Text>
                          押金条目总额：
                          {detail.contractDetailList?.reduce((total, cur) => {
                            return add(total, cur?.rentPledge)
                          }, 0)}
                          元
                        </Text>
                      </Col>
                    </Row>
                  )}
                </div>
                {detail.checkOut === '1' ? (
                  <>
                    <div className={styles.header}>退房结算单基本信息</div>
                    <div className={styles.content}>
                      {loading ? (
                        <Skeleton active />
                      ) : (
                        <>
                          <Row gutter={[0, 5]}>
                            {checkOutDetailList.map((item, index) => (
                              <Col key={index} span={12}>
                                <Text>{item.title}</Text>
                                <Text>{item.label}</Text>
                              </Col>
                            ))}
                          </Row>
                          {showDetail && cutList?.length ? (
                            <div style={{marginTop: '20px'}}>
                              <Row
                                style={{
                                  backgroundColor: 'rgba(0, 0, 0, 0.02)',
                                  marginRight: '50px',
                                  height: '60px',
                                  borderBottom: '1px solid rgba(0, 0, 0, 0.09)',
                                }}
                              >
                                <Col span={8} style={{display: 'flex', alignItems: 'center'}}>
                                  <Title level={4} style={{paddingLeft: '16px', marginBottom: 0}}>
                                    扣款费用类型
                                  </Title>
                                </Col>
                                <Col span={8} style={{display: 'flex', alignItems: 'center'}}>
                                  <Title level={4} style={{paddingLeft: '16px', marginBottom: 0}}>
                                    费用周期
                                  </Title>
                                </Col>
                                <Col span={8} style={{display: 'flex', alignItems: 'center'}}>
                                  <Title level={4} style={{paddingLeft: '16px', marginBottom: 0}}>
                                    金额（元）
                                  </Title>
                                </Col>
                              </Row>
                              {cutList.map((item, index) => (
                                <Row
                                  key={index}
                                  style={{
                                    marginRight: '50px',
                                    height: '60px',
                                    borderBottom: '1px solid rgba(0, 0, 0, 0.09)',
                                  }}
                                >
                                  <Col span={8} style={{display: 'flex', alignItems: 'center'}}>
                                    <Text style={{paddingLeft: '16px'}}>{item.billTypeName}</Text>
                                  </Col>
                                  <Col span={8} style={{display: 'flex', alignItems: 'center'}}>
                                    <Text style={{paddingLeft: '16px'}}>
                                      {item.billBegin ? `${item.billBegin}至${item.billEnd}` : '无账期'}
                                    </Text>
                                  </Col>
                                  <Col span={8} style={{display: 'flex', alignItems: 'center'}}>
                                    <Text
                                      style={{
                                        paddingLeft: '16px',
                                        textDecoration: item.billFee === '1' ? 'line-through' : '',
                                      }}
                                    >
                                      {item.checkoutAmt.toFixed(2)}
                                    </Text>
                                  </Col>
                                </Row>
                              ))}
                            </div>
                          ) : null}
                          {showDetail && refundList?.length ? (
                            <div style={{marginTop: '16px'}}>
                              <Row
                                style={{
                                  backgroundColor: 'rgba(0, 0, 0, 0.02)',
                                  marginRight: '50px',
                                  height: '60px',
                                  lineHeight: '60px',
                                }}
                              >
                                <Col span={8} style={{display: 'flex', alignItems: 'center'}}>
                                  <Title level={4} style={{paddingLeft: '16px', marginBottom: 0}}>
                                    退款费用类型
                                  </Title>
                                </Col>
                                <Col span={8} style={{display: 'flex', alignItems: 'center'}}>
                                  <Title level={4} style={{paddingLeft: '16px', marginBottom: 0}}>
                                    费用周期
                                  </Title>
                                </Col>
                                <Col span={8} style={{display: 'flex', alignItems: 'center'}}>
                                  <Title level={4} style={{paddingLeft: '16px', marginBottom: 0}}>
                                    金额（元）
                                  </Title>
                                </Col>
                              </Row>
                              {refundList.map((item, index) => (
                                <Row
                                  key={index}
                                  style={{
                                    marginRight: '50px',
                                    height: '60px',
                                    borderBottom: '1px solid rgba(0, 0, 0, 0.09)',
                                  }}
                                >
                                  <Col span={8} style={{display: 'flex', alignItems: 'center'}}>
                                    <Text style={{paddingLeft: '16px'}}>{item.billTypeName}</Text>
                                  </Col>
                                  <Col span={8} style={{display: 'flex', alignItems: 'center'}}>
                                    <Text style={{paddingLeft: '16px'}}>
                                      {item.billBegin ? `${item.billBegin}至${item.billEnd}` : '无账期'}
                                    </Text>
                                  </Col>
                                  <Col span={8} style={{display: 'flex', alignItems: 'center'}}>
                                    <Text style={{paddingLeft: '16px'}}>{item.checkoutAmt.toFixed(2)}</Text>
                                  </Col>
                                </Row>
                              ))}
                            </div>
                          ) : null}
                          <Row style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                            <a onClick={() => setShowDetail(!showDetail)}>
                              {showDetail ? '收起  ' : '查看详情  '}
                              {showDetail ? (
                                <UpOutlined style={{fontSize: '11px'}} />
                              ) : (
                                <DownOutlined style={{fontSize: '11px'}} />
                              )}
                            </a>
                          </Row>
                        </>
                      )}
                    </div>
                  </>
                ) : null}

                {!!detail.checkOutAgainId &&
                  detail.checkOutAgainList?.map((value, index) => {
                    if (index !== 0) return null

                    const cutList = value.detailList.filter(item => item.detailType === '1')
                    const refundList = value.detailList.filter(item => item.detailType === '2')
                    return (
                      <More
                        key={index}
                        title={'退房结算单基本信息（确认）'}
                        more={
                          <>
                            {!!cutList.length && (
                              <Table
                                style={{marginBottom: 10}}
                                rowKey={(_, i) => i}
                                dataSource={cutList}
                                columns={[
                                  {title: '扣款费用类型', width: '33.3%', dataIndex: 'billTypeName'},
                                  {title: '费用周期', width: '33.3%', render: (text, record) => formatBillDate(record)},
                                  {
                                    title: '金额（元）',
                                    width: '33.3%',
                                    render: (_, record) => (
                                      <Text delete={record.billFee === '1'}>{record.checkoutAmt}</Text>
                                    ),
                                  },
                                ]}
                                pagination={false}
                              />
                            )}
                            {!!refundList.length && (
                              <Table
                                style={{marginBottom: 10}}
                                rowKey={(_, i) => i}
                                dataSource={refundList}
                                columns={[
                                  {title: '退款费用类型', width: '33.3%', dataIndex: 'billTypeName'},
                                  {title: '费用周期', width: '33.3%', render: (text, record) => formatBillDate(record)},
                                  {title: '金额（元）', width: '33.3%', dataIndex: 'checkoutAmt'},
                                ]}
                                pagination={false}
                              />
                            )}
                          </>
                        }
                      >
                        <Descriptions title={null} column={2} className={styles.descriptions}>
                          <Descriptions.Item label='扣除租客金额'>{`${value.cutAmt}元`}</Descriptions.Item>
                          <Descriptions.Item label='应退租客金额'>{`${value.refundAmt}元`}</Descriptions.Item>
                          <Descriptions.Item label='总计'>
                            {value.totalAmt}元
                            {value.totalAmt > 0
                              ? `（退还给租客${value.totalAmt}元）`
                              : `（向租客收取${Math.abs(value.totalAmt)}元）`}
                          </Descriptions.Item>
                          <Descriptions.Item label='退款途径'>{value.rfdPayWayName}</Descriptions.Item>
                          <Descriptions.Item label='处理人'>{value.creatorName}</Descriptions.Item>
                          <Descriptions.Item label='退房类型'>{checkoutType[value.checkOutType]}</Descriptions.Item>
                          <Descriptions.Item label='退房备注' span={2}>
                            {value.rfdRemark || '无'}
                          </Descriptions.Item>
                        </Descriptions>
                      </More>
                    )
                  })}

                <AddCheckin
                  contractId={state_contractId}
                  checkinList={checkinList}
                  onSubmit={async value => {
                    await contractModel.checkinPersonUpsert(value)
                    if (value.personId) {
                      message.success('编辑成功')
                    } else {
                      message.success('添加成功')
                    }
                    personList()
                  }}
                  onDelete={async value => {
                    await contractModel.checkinPersonDelete(value)
                    message.success('操作成功！')
                    personList()
                  }}
                />

                <div className={styles.header}>签约信息</div>
                {loading ? (
                  <div className={styles.content}>
                    <Skeleton active />
                  </div>
                ) : (
                  detail.contractDetailList?.map(item => (
                    <div key={item.contractDetailId} className={styles.contentCompact}>
                      <Row>
                        <Col span={24}>
                          <Text strong>{item.contractDetailTitle}</Text>
                        </Col>
                        <Col span={12}>
                          <Text>签约人：{item.contractOwnerName}</Text>
                        </Col>
                        <Col span={12}>
                          <Text>租约起始日：{item.contractBegin}</Text>
                        </Col>
                        <Col span={12}>
                          <Text>租约结束日：{item.contractEnd}</Text>
                        </Col>
                        <Col span={12}>
                          {item.rentPriceDiscount ? (
                            <Text>
                              房屋租金：
                              <Text delete style={{color: '#999'}}>
                                {item.rentPrice}元，
                              </Text>
                              {item.rentPriceDiscount}元
                            </Text>
                          ) : (
                            <Text>房屋租金：{item.rentPrice}元</Text>
                          )}
                        </Col>
                        <Col span={12}>
                          <Text>房屋押金：{item.rentPledge}元</Text>
                        </Col>
                        <Col span={12}>
                          <Text>
                            付款方式：
                            {`付${item?.rentPayType ?? ''}押${
                              item.rentPledgeType === 0 ? '自定义' : item.rentPledgeType
                            }`}
                          </Text>
                        </Col>
                        <Col span={12}>
                          <Text>
                            收租日：
                            {item.rentCollectType === '1'
                              ? `每期提前${item.rentCollectDay}天收租`
                              : `每期${item.rentCollectDay}号收租`}
                          </Text>
                        </Col>
                        <Col span={12}>
                          <Text>合同类型：{contractType[item?.contractType]}</Text>
                        </Col>
                        <Col span={12}>
                          <Text>原合同编号：{item?.originContractId ?? '无'}</Text>
                        </Col>
                        {!!item?.discountId && (
                          <Col span={12}>
                            <Text>优惠活动：{item?.discountName ?? '无'}</Text>
                          </Col>
                        )}
                        <Col span={24}>
                          <Text>签约时间：{item.signTime}</Text>
                        </Col>
                        <Col span={24}>
                          <Text>备注：{item.contractRemark ?? '无'}</Text>
                        </Col>
                      </Row>
                    </div>
                  ))
                )}
                <div>
                  <Row style={{marginTop: 30}}>
                    <Col style={{width: 80}}>
                      <Typography.Text>备注信息</Typography.Text>
                    </Col>
                    <Col span={10}>
                      <Input.TextArea
                        value={contractRemark}
                        rows={4}
                        placeholder={'请填写备注信息'}
                        onChange={e => {
                          setContractRemark(e.target.value)
                        }}
                        allowClear
                        showCount
                        maxLength={500}
                      />
                    </Col>
                  </Row>
                  <Button
                    style={{marginLeft: 80, marginTop: 20}}
                    type={'primary'}
                    onClick={async () => {
                      await contractModel.contractUpdateContractRemark({contractId: state_contractId, contractRemark})
                      message.success('保存成功')
                    }}
                    loading={contractRemarkLoading}
                  >
                    保存
                  </Button>
                </div>
              </section>
              <aside className={styles.aside}>
                {loading ? (
                  <Skeleton active />
                ) : (
                  <>
                    {propertyDetail.imgList?.length ? (
                      <Carousel autoplay>
                        {propertyDetail.imgList.map(img => (
                          <div className={styles.carouselEmpty} key={img.imgId}>
                            <div className={styles.carousel} style={{backgroundImage: `url(${getImg(img?.imgUrl)}`}} />
                          </div>
                        ))}
                      </Carousel>
                    ) : (
                      <div className={styles.carouselEmpty}>
                        <img src={imgEmpty} alt={'nodata'} />
                      </div>
                    )}

                    <Title level={4}>{propertyDetail.title}</Title>
                    <Text>房源编号：{propertyDetail.propertyCode}</Text>
                    <Text>地址：{propertyDetail.communityAddress}</Text>
                    <Text>房东手机：{detail.lessorMobile}</Text>
                    <Text>
                      房源概况：{propertyDetail.bedroom}室{propertyDetail.parlor}厅{propertyDetail.bathroom}卫
                    </Text>
                    <Text>房源面积：{propertyDetail.floorage}平方米</Text>
                    <Text>房源类型：{rentType[propertyDetail.rentType]}</Text>
                  </>
                )}
              </aside>
            </TabPane>
            <TabPane tab={'账务信息'} key={'billInfo'} disabled={!auth('002001020')}>
              {loading ? <Skeleton active /> : propertyInfo}

              <Space size={21} className={styles.billTitle}>
                <Title level={4} style={{marginBottom: 0}}>
                  租约账单
                </Title>
                <Text>
                  总账单 <span style={{color: '#017BFF'}}>{billListCount.amtTotal}</span> 元
                </Text>
                <Text>
                  已收 <span style={{color: '#017BFF'}}>{billListCount.amtPaid}</span> 元
                </Text>
                <Text>
                  待收 <span style={{color: '#017BFF'}}>{billListCount.amtUnpaid}</span> 元
                </Text>
              </Space>

              {billList.map((value, index) => {
                const showHeader = !value.title

                return (
                  <Table
                    key={index}
                    rowKey={'category'}
                    className={value.title ? styles.tableTitle : ''}
                    showHeader={showHeader}
                    title={
                      showHeader
                        ? undefined
                        : data => (
                            <Space size={15}>
                              <span style={{fontWeight: 'bold'}}>{value.title}</span>
                              <span>{`${data[0]?.contractBegin}至${data[0]?.contractEnd}`}</span>
                            </Space>
                          )
                    }
                    dataSource={value.data}
                    columns={[
                      {
                        title: '租约',
                        width: 100,
                        dataIndex: 'category',
                        key: 'category',
                      },
                      // {
                      //   title: '签约记录',
                      //   width: 110,
                      //   dataIndex: 'contractDetailTitle',
                      //   key: 'contractDetailTitle',
                      // },
                      {
                        title: '账单周期',
                        width: 220,
                        dataIndex: 'billTime',
                        key: 'billTime',
                        ellipsis: true,
                      },
                      {
                        title: '应收',
                        width: 120,
                        dataIndex: 'billAmtTotal',
                        key: 'billAmtTotal',
                        render: text => <Statistic value={text} precision={2} className={styles.statistic} />,
                      },
                      {
                        title: '已收',
                        width: 120,
                        dataIndex: 'billAmtPaid',
                        key: 'billAmtPaid',
                        render: text => <Statistic value={text} precision={2} className={styles.statistic} />,
                      },
                      {title: '应付款日', width: 120, dataIndex: 'billDate', key: 'billDate'},
                      // {title: '付款款日期', dataIndex: 'billPayTime', key: 'billPayTime'},
                      {
                        title: '状态',
                        width: 160,
                        dataIndex: 'billStatus', // 第一层的 billStatus 已经赋过值
                        key: 'billStatus',
                      },
                      {
                        title: '操作',
                        width: 100,
                      },
                      {title: '备注'},
                    ]}
                    expandable={{
                      expandRowByClick: true,
                      expandedRowRender: (record, index, indent, expanded) => {
                        const subList = record.list.reduce<ContractBillDetailVO[]>(
                          (prev, item, index) => [
                            ...prev,
                            ...item.billDetailList.map(detail => ({
                              ...detail,
                              billTime: `${index + 1} ${item.billTypeName}`,
                              billDate: item.billDate,
                              billAmtTotal: item.billAmtTotal,
                              billTypeName: item.billTypeName,
                              time: record.billTime,
                            })),
                          ],
                          []
                        )

                        return (
                          <div style={{marginRight: -16, paddingLeft: subList.length ? 32 : 0}}>
                            <Table
                              rowKey='billId'
                              className={styles.subTable}
                              showHeader={false}
                              columns={[
                                {
                                  title: '租约',
                                  width: 100,
                                  render: (value, record1, index) => {
                                    return index === 0 ? '账单明细' : ''
                                  },
                                },
                                {
                                  title: '账单周期',
                                  width: 220,
                                  render: (value, record1, index) => {
                                    return `${index + 1} ${record1.billTypeName}`
                                  },
                                },
                                {
                                  title: '应收',
                                  width: 120,
                                  key: 'billAmtTotal',
                                  dataIndex: 'billAmtTotal',
                                  render: (text, record) => (
                                    <Space>
                                      {!!record.billAmtOrigin && (
                                        <Text delete style={{color: '#999'}}>
                                          {Number(record.billAmtOrigin).toFixed(2)}
                                        </Text>
                                      )}
                                      <Statistic className={styles.statistic} value={text} precision={2} />
                                    </Space>
                                  ),
                                },
                                {
                                  title: '已收',
                                  width: 120,
                                  key: 'billAmtPaid',
                                  dataIndex: 'billAmtPaid',
                                  render: text => <Statistic value={text} precision={2} className={styles.statistic} />,
                                },
                                {title: '应付款日', width: 120, dataIndex: 'billDate', key: 'billDate'},
                                // {title: '状态', dataIndex: 'billStatus', key: 'billStatus'},
                                {
                                  title: '状态',
                                  width: 160,
                                  dataIndex: 'billStatus',
                                  key: 'billStatus',
                                  render: (text, record) => {
                                    return billStatus[text] + (text === '1' ? `（${record.billAmtUnpaid}）` : '')
                                  },
                                },
                                {
                                  title: '操作',
                                  width: 100,
                                  render: (value, record1) => renderOperation(record1),
                                },
                                {
                                  title: '备注',
                                  render: (value, record1) => {
                                    return record1.billRemark ? (
                                      <Button
                                        size={'small'}
                                        style={{minWidth: 62}}
                                        onClick={() => {
                                          Modal.info({
                                            content: record1.billRemark,
                                            okText: '关闭',
                                            title: '账单备注',
                                            icon: null,
                                          })
                                        }}
                                      >
                                        查看
                                      </Button>
                                    ) : null
                                  },
                                },
                              ]}
                              dataSource={record.list}
                              pagination={false}
                            />
                            {!!subList?.length && (
                              <Table
                                rowKey='billDetailId'
                                showHeader={false}
                                className={styles.subTable}
                                columns={[
                                  {
                                    dataIndex: 'category',
                                    key: 'category',
                                    width: 100,
                                    render: (_, __, index) => (index === 0 ? '支付记录' : ''),
                                  },
                                  {
                                    dataIndex: 'billTime',
                                    key: 'billTime',
                                    render: (value, record1) => (
                                      <Space size={24}>
                                        <Text>{value}</Text>
                                        <Text>{record1.billPayTime}</Text>
                                        <Text>{record1.billPayWayName}</Text>
                                        <Space size={0}>
                                          <Statistic
                                            value={record1.billPayAmt}
                                            precision={2}
                                            className={styles.statistic}
                                          />
                                          {{'0': '（未核销）', '1': ' （已核销）'}[record1.writeOff]}
                                          {auth('003001005') && (
                                            <Button
                                              size={'small'}
                                              type={'link'}
                                              onClick={() => {
                                                onCollectionDetail(record1.billDetailId, record1)
                                              }}
                                            >
                                              详情
                                            </Button>
                                          )}
                                        </Space>
                                      </Space>
                                    ),
                                  },
                                ]}
                                dataSource={subList}
                                pagination={false}
                              />
                            )}
                          </div>
                        )
                      },
                    }}
                    // onExpand={onExpand}
                    pagination={false}
                  />
                )
              })}

              {!!chargeList.length && (
                <Table
                  style={{marginTop: 10}}
                  rowKey='billId'
                  columns={[
                    {title: '记账', width: 100, dataIndex: 'billTypeName', key: 'billTypeName'},
                    {
                      title: '账单周期',
                      width: 220,
                      // dataIndex: 'billTime',
                      // key: 'billTime',
                      render: (_, record) => getBillTime(record),
                    },
                    {
                      title: '应收',
                      width: 120,
                      dataIndex: 'billAmtTotal',
                      key: 'billAmtTotal',
                      render: text => <Statistic value={text} precision={2} className={styles.statistic} />,
                    },
                    {
                      title: '已收',
                      width: 120,
                      dataIndex: 'billAmtPaid',
                      key: 'billAmtPaid',
                      render: text => <Statistic value={text} precision={2} className={styles.statistic} />,
                    },
                    {title: '应付款日', width: 120, dataIndex: 'billDate', key: 'billDate'},
                    {
                      title: '状态',
                      width: 130,
                      dataIndex: 'billStatus',
                      key: 'billStatus',
                      render: text => billStatus[text],
                    },
                    {
                      title: '操作',
                      width: 100,
                      render: (value, record1) => renderOperation(record1),
                    },
                    {
                      title: '备注',
                      // width: 130,
                      render: (value, record1) => {
                        return record1.billRemark ? (
                          <Button
                            size={'small'}
                            style={{minWidth: 62}}
                            onClick={() => {
                              Modal.info({content: record1.billRemark, okText: '确认'})
                            }}
                          >
                            查看
                          </Button>
                        ) : null
                      },
                    },
                  ]}
                  dataSource={chargeList}
                  expandable={{
                    expandRowByClick: true,
                    rowExpandable: record => !!record.billDetailList?.length,
                    expandedRowRender: record => {
                      const subList = record.billDetailList

                      return subList?.length ? (
                        <Table
                          rowKey={'billDetailId'}
                          showHeader={false}
                          className={styles.subTable}
                          expandable={{}}
                          columns={[
                            {
                              dataIndex: 'category',
                              key: 'category',
                              width: 100,
                              render: (_, __, index) => (index === 0 ? '支付记录' : ''),
                            },
                            {
                              dataIndex: 'billPayAmt',
                              key: 'billPayAmt',
                              render: (value, record1) => (
                                <Space size={24}>
                                  <Text>{record1.billPayTime}</Text>
                                  <Text>{record1.billPayWayName}</Text>
                                  <Space size={0}>
                                    <Statistic value={value} precision={2} className={styles.statistic} />
                                    {{'0': '（未核销）', '1': ' （已核销）'}[record1.writeOff]}
                                  </Space>
                                  <Permission code={'003001005'}>
                                    <Button
                                      size={'small'}
                                      type={'link'}
                                      onClick={() => {
                                        onCollectionDetail(record1.billDetailId, record)
                                      }}
                                    >
                                      详情
                                    </Button>
                                  </Permission>
                                </Space>
                              ),
                            },
                          ]}
                          dataSource={subList}
                          pagination={false}
                        />
                      ) : null
                    },
                  }}
                />
              )}
            </TabPane>
            <TabPane tab={'合同信息'} key={'contractInfo'} disabled={!auth('002001019')}>
              {loading ? <Skeleton active /> : propertyInfo}
              <div style={{display: 'flex'}}>
                <div className={styles.contractHeaderLeft}>
                  {contractType[detail.contractType]}
                  <Text className={styles.contractTitleText}>
                    {detail.contractBegin}至{detail.contractEnd}（
                    {dayjs(detail.contractBegin).from(dayjs(detail.contractEnd), true)}）
                  </Text>
                </div>
                <div className={styles.contractHeaderRight}>证件照片</div>
              </div>
              <div className={styles.content} style={{position: 'relative'}}>
                {loading ? (
                  <Skeleton active />
                ) : (
                  <>
                    <div className={styles.contractContentLine}></div>
                    <div style={{display: 'flex', alignItems: 'flex-start'}}>
                      <div style={{display: 'flex', flexDirection: 'column', width: '50%'}}>
                        {detail.contractDetailList?.map(item => (
                          <div key={item.contractDetailId} style={{display: 'flex'}}>
                            {/* 电子合同 */}
                            {item.contractType === 'elec' ? (
                              <div
                                className={styles.pdf}
                                onClick={() => onOpenPdf(item?.contractUrl, item?.contractDetailId)}
                              >
                                <img src={pdf} alt={'pdf'} />
                              </div>
                            ) : null}

                            {/* 已作废 || 已退房且已结账 不能传合同照片 */}
                            <Permission code={'003001011'}>
                              <Album
                                action={baseUrl + '/contract/upload-img'}
                                disabled={
                                  !!(
                                    detail.contractStatus === '5' ||
                                    (detail.contractStatus === '4' && detail.checkOut === '1')
                                  )
                                }
                                fileList={item?.imgList.map(item => ({
                                  ...item,
                                  url: getImg(item.path),
                                  uid: item.imgId,
                                }))}
                                onDone={fileList => updateSubcontractImages(item?.contractDetailId, fileList)}
                                data={{imgType: '04'}}
                              />
                            </Permission>
                          </div>
                        ))}
                      </div>
                      <Space style={{width: '50%', paddingLeft: '15px'}}>
                        <div className={styles.lesseeCert}>
                          <Album
                            disabled
                            fileList={[{uid: 'lesseeCertFront', url: getImg(detail.lesseeCertFront ?? '')}]}
                          />
                          <Text>正面照</Text>
                        </div>
                        <div className={styles.lesseeCert}>
                          <Album
                            disabled
                            fileList={[{uid: 'lesseeCertBack', url: getImg(detail.lesseeCertBack ?? '')}]}
                          />
                          <Text>反面照</Text>
                        </div>
                        <div className={styles.lesseeCert}>
                          <Album
                            disabled
                            fileList={[{uid: 'lesseeCertHold', url: getImg(detail.lesseeCertHold ?? '')}]}
                          />
                          <Text>手持照</Text>
                        </div>
                      </Space>
                    </div>
                  </>
                )}
              </div>
            </TabPane>
            <TabPane tab={'物业交割'} key={'delivery'} disabled={!auth('003001012')}>
              <Title level={4}>房间物品</Title>
              {equipmentList?.length ? (
                <Table
                  style={{width: 700, margin: '20px 0'}}
                  rowKey={'code'}
                  dataSource={equipmentList}
                  columns={[
                    {title: '物品', key: 'name', dataIndex: 'name'},
                    {title: '数量', key: 'amt', dataIndex: 'amt'},
                    {title: '单价', key: 'price', dataIndex: 'price', render: text => <Text>{text || '/'}</Text>},
                    {title: '品牌', key: 'model', dataIndex: 'model', render: text => <Text>{text || '/'}</Text>},
                    {
                      title: '附件',
                      key: 'attach',
                      dataIndex: 'attach',
                      render: text =>
                        text ? (
                          <Button style={{paddingLeft: 0}} type={'text'} onClick={() => setShowImage(text)}>
                            查看
                          </Button>
                        ) : (
                          <Text>/</Text>
                        ),
                    },
                    {title: '备注', key: 'remark', dataIndex: 'remark', render: text => <Text>{text || '/'}</Text>},
                  ]}
                  pagination={false}
                />
              ) : null}
              {detail.rentType === '2' ? <Title level={4}>公区物品</Title> : null}
              {equipmentPublicList?.length ? (
                <Table
                  style={{width: 700, margin: '20px 0'}}
                  dataSource={equipmentPublicList}
                  rowKey={'code'}
                  columns={[
                    {title: '物品', key: 'name', dataIndex: 'name'},
                    {title: '数量', key: 'amt', dataIndex: 'amt'},
                    {title: '单价', key: 'price', dataIndex: 'price', render: text => <Text>{text || '/'}</Text>},
                    {title: '品牌', key: 'model', dataIndex: 'model', render: text => <Text>{text || '/'}</Text>},
                    {
                      title: '附件',
                      key: 'attach',
                      dataIndex: 'attach',
                      render: text =>
                        text ? (
                          <Button style={{paddingLeft: 0}} type={'text'} onClick={() => setShowImage(text)}>
                            查看
                          </Button>
                        ) : (
                          <Text>/</Text>
                        ),
                    },
                    {title: '备注', key: 'remark', dataIndex: 'remark', render: text => <Text>{text || '/'}</Text>},
                  ]}
                  pagination={false}
                />
              ) : null}
              <Title level={4}>水电煤读数</Title>
              {meterList?.length ? (
                <Table
                  style={{width: 700, margin: '20px 0'}}
                  dataSource={meterList}
                  rowKey={'code'}
                  columns={[
                    {title: '仪表', key: 'name', dataIndex: 'name'},
                    {title: '位置', key: 'locationName', dataIndex: 'locationName'},
                    {title: '底数', key: 'value', dataIndex: 'value'},
                    {
                      title: '支付方式',
                      key: 'payWay',
                      dataIndex: 'payWay',
                      render: text => <Text>{text === '1' ? '后付费' : '预付费'}</Text>,
                    },
                    {
                      title: '附件',
                      key: 'attach',
                      dataIndex: 'attach',
                      render: text =>
                        text ? (
                          <Button style={{paddingLeft: 0}} type={'text'} onClick={() => setShowImage(text)}>
                            查看
                          </Button>
                        ) : (
                          <Text>/</Text>
                        ),
                    },
                    {title: '备注', key: 'remark', dataIndex: 'remark'},
                  ]}
                  pagination={false}
                />
              ) : null}
              {equipmentList?.length || equipmentPublicList?.length || meterList?.length ? null : (
                <div>
                  <img src={empty} title='' style={{position: 'absolute', top: '50%', left: '50%'}} />
                </div>
              )}
            </TabPane>
            {isProjectCondo && (
              <TabPane tab={'租约账务'} key={'accounting'}>
                <ContractDetailAccounting form={accountingForm} contractId={state_contractId} />
              </TabPane>
            )}
          </Tabs>
        </article>

        {/* 账单明细详情 */}
        <CollectionDetail ref={collectionDetailRef} onSuccess={billId => onSuccess(billId)} />
        {/* 添加账单 */}
        <AddPeriodBill ref={addPeriodBillRef} onSuccess={onSuccess} />
        {/* 操作查看 */}
        <BillDetailAction ref={billDetailRef} onSuccess={billId => onSuccess(billId)} />
        {/* 操作收款 */}
        <ConfirmCollectionAction ref={confirmCollectionRef} onSuccess={billId => onSuccess(billId)} />
        {/* 账单拆分 */}
        <BillDivision ref={billDivisionRef} onSuccess={billId => onSuccess(billId)} />
        {/* 修改 */}
        <Modal
          centered
          visible={alterAmt.show}
          title={alterAmt.propertyTitle}
          onCancel={() => {
            setAlterAmt(prevState => ({...prevState, show: false}))
            changeForm.resetFields()
          }}
          onOk={async () => {
            await changeForm.validateFields()
            const {billId} = alterAmt
            setAlterAmt(prevState => ({...prevState, show: false}))
            await contractModel.contractBillAlterAmt({
              billId: alterAmt.billId,
              billAmt: changeForm.getFieldValue('billAmt'),
              billRemark: changeForm.getFieldValue('billRemark'),
            })
            message.success('修改成功!')
            changeForm.resetFields()
            onSuccess(billId)
          }}
        >
          <Form form={changeForm} labelCol={{span: 6}}>
            <Form.Item label={'原账单金额'}>
              <Typography.Text>{`￥${alterAmt.billAmtTotal}`}</Typography.Text>
            </Form.Item>
            <Row gutter={10}>
              <Col span={18}>
                <Form.Item
                  name={'billAmt'}
                  label={'实际待收金额'}
                  labelCol={{span: 8}}
                  rules={[{required: true, message: '请输入实际待收金额'}]}
                >
                  <Input placeholder={'请输入实际待收金额'} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item shouldUpdate>
                  {form1 => {
                    const billAmt = form1.getFieldValue('billAmt')
                    return (
                      <Typography.Text style={{color: '#FF5151'}}>
                        {!!billAmt &&
                          (alterAmt.billAmtTotal >= billAmt
                            ? `优惠￥${alterAmt.billAmtTotal - billAmt}`
                            : `加收￥${billAmt - alterAmt.billAmtTotal}`)}
                      </Typography.Text>
                    )
                  }}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name={'billRemark'} label={'账单备注'}>
              <Input.TextArea placeholder={'此备注会在租客端显示，请酌情填写'} />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          centered
          title={<div style={{textAlign: 'center'}}>退房操作</div>}
          visible={visible}
          cancelText={'退房，稍后结账'}
          okText={'退房并结账'}
          footer={null}
          onCancel={() => setVisible(false)}
        >
          <Text type='warning'>温馨提示</Text>
          <Paragraph>
            1、退房后，不可撤销；
            <br /> 2、确认退房后根据可入住时间，将释放房源为可租状态；
            <br /> 3、退房后请及时与承租人结算账务。
          </Paragraph>
          <Space style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 30}}>
            <Permission code={'002001018'}>
              <Button onClick={event => onCheckOut({type: 'checkout', event, ...checkValue})}>退房，稍后结账</Button>
            </Permission>
            <Permission code={'002001017'}>
              <Button type={'primary'} onClick={event => onCheckOut({type: 'all', event, ...checkValue})}>
                退房并结账
              </Button>
            </Permission>
          </Space>
        </Modal>
        {/* 结账 */}
        <PropertyCheckOut
          ref={propertyCheckOutRef}
          onSuccess={() => {
            propertyCheckOutRef.current.onHidden()
            contractModel.contractQueryContract({contractId: state_contractId}).then(({response: {data}}) => {
              setDetail(data)
              setCheckDetail(data.checkOutDetail)
              setCutList(data.checkOutDetail?.checkoutDetailList.filter(item => item.detailType === '1'))
              setRefundList(data.checkOutDetail?.checkoutDetailList.filter(item => item.detailType === '2'))
            })
          }}
        />
      </div>

      {/* 物业交割操作 */}
      <EquipmentModal
        ref={equipmentModalRef}
        rentType={detail.rentType}
        contractId={detail.contractId}
        onSuccess={config => {
          setEquipment(config)
          contractModel.contractEquipmentAdd({...config, contractId: state_contractId}).then(() => {
            queryDeliveryInfo(state_contractId)
            message.success('保存成功')
          })
        }}
        title={'物业交割'}
      />

      {/* 查看图片 */}
      <Modal visible={!!showImage} footer={null} onCancel={() => setShowImage('')}>
        {!!showImage && <img alt='预览' style={{width: '100%'}} src={getImg(showImage)} />}
      </Modal>

      <ChangeLessee
        visible={changeLesseeVisible}
        contract={detail}
        onCancel={() => setChangeLesseeVisible(false)}
        onSuccess={() => {
          setChangeLesseeVisible(false)
          message.success('操作成功')
          contractModel.contractQueryContract({contractId: state_contractId}).then(({response: {data}}) => {
            setDetail(data)
          })
        }}
      />

      <ChargeModal ref={chargeModalRef} />
      {/*租约操作记录*/}
      <ContractLogList ref={logRef} contractId={detail.contractId} />
    </>
  )
}

interface MoreProps {
  title: React.ReactNode
  more?: React.ReactNode
}

const More: React.FC<MoreProps> = props => {
  const [showMore, setShowMore] = useState(false)
  return (
    <div>
      <div className={styles.header}>{props.title}</div>
      <div className={styles.content}>
        {props.children}
        {showMore && props.more}
        <div style={{textAlign: 'center'}}>
          <a onClick={() => setShowMore(prev => !prev)}>
            {showMore ? '收起 ' : '查看详情 '}
            {showMore ? <UpOutlined style={{fontSize: '11px'}} /> : <DownOutlined style={{fontSize: '11px'}} />}
          </a>
        </div>
      </div>
    </div>
  )
}

function formatBillDate(value: Pick<ContractCheckOutAgainDetailVO, 'billBegin' | 'billEnd'>) {
  const format = (date: string) => dayjs(date).format('YYYY-MM-DD')
  return value.billBegin && value.billEnd ? [format(value.billBegin), format(value.billEnd)].join(' 至 ') : '无账期'
}

interface ChangeLesseeProps {
  visible: boolean
  contract: Pick<ContractVO, 'contractId' | 'contractType'>
  onCancel?: () => void
  onSuccess?: () => void
}

/**
 * 更换承租人
 */
const ChangeLessee: React.FC<ChangeLesseeProps> = props => {
  const contractType = props.contract?.contractType
  const contractId = props.contract?.contractId

  // 证件类型
  const contractCertType = useCodeArraySync('contract.certType')
  const types = useMemo(
    () => (contractType === 'elec' ? contractCertType.filter(value => value.id === '01') : contractCertType),
    [contractCertType, contractType]
  )

  const [form] = Form.useForm()

  return (
    <Modal
      visible={props.visible}
      title={'更换承租人'}
      okText={'确认更换'}
      confirmLoading={contractModel.contractChangeLessee.useLoading()}
      onCancel={props.onCancel}
      onOk={() => form.submit()}
      afterClose={() => form.resetFields()}
    >
      <Form
        form={form}
        labelCol={{flex: '110px'}}
        onFinish={async () => {
          const dto = form.getFieldsValue(true)
          await contractModel.contractChangeLessee({...dto, contractId})
          props.onSuccess?.()
        }}
      >
        <Form.Item name={'lesseeCertName'} label={'新承租人姓名'} rules={rules.lesseeCertName}>
          <Input maxLength={30} placeholder={'请输入新承租人姓名'} allowClear />
        </Form.Item>
        <Form.Item name={'lesseeMobile'} label={'手机号'} rules={rules.lesseeMobile}>
          <Input maxLength={11} placeholder={'请输入手机号'} allowClear />
        </Form.Item>
        <Form.Item name={'lesseeGender'} label={'性别'} rules={[{required: true}]}>
          <Select placeholder={'请选择'}>
            <Select.Option value={'1'}>男</Select.Option>
            <Select.Option value={'2'}>女</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name={'lesseeCertType'} label={'证件类型'} rules={[{required: true}]}>
          <Select placeholder={'请选择'}>
            {types.map(value => (
              <Select.Option key={value.id} value={value.id}>
                {value.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(prev, next) => prev.lesseeCertType !== next.lesseeCertType}>
          {f => (
            // 电子合同的身份证必填，其他情况，其他证件非必填
            <Form.Item
              name={'lesseeCertNo'}
              label={'证件号码'}
              rules={
                f.getFieldValue('lesseeCertType') === '01' && contractType === 'elec' ? rules.lesseeCertNo : undefined
              }
            >
              <Input placeholder={'请输入证件号码'} allowClear />
            </Form.Item>
          )}
        </Form.Item>
      </Form>
    </Modal>
  )
}
