import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react'
import {Button, Cascader, Form, Input, Modal, Space, Typography} from 'antd'
import {Album} from '@/ui/components/Album'
import {commonModel} from '@/store/models/Common'
import {baseUrl} from '@/common/config'
import {getImg} from '@/utils/util'

const {Text} = Typography
interface Props {
  onSuccess: (item: ContractEquipmentItemDTO, index: number) => unknown
}
function EditModel({onSuccess}: Props, ref) {
  const [form] = Form.useForm()
  const formRef = useRef(null)
  const [visible, setVisible] = useState(false)
  const getOptionChildren = id => {
    const obj = commonModel.getCodeSync(id)
    return Object.keys(obj).map(key => {
      const isLeaf = commonModel.getCodeArraySync(key).length
      return {value: key, label: obj[key], isLeaf: isLeaf ? false : true}
    })
  }
  const [item, setItem] = useState({} as ContractEquipmentItemDTO)

  const [index, setIndex] = useState(0)

  const [options, setOptions] = useState([])

  useEffect(() => {
    setTimeout(() => {
      // todo: getCodeSync 改为 useCodeSync
      setOptions(getOptionChildren('eqpt'))
    }, 1000)
  }, [])

  //加载更多数据
  function loadData(selectedOptions) {
    const targetOption = selectedOptions[selectedOptions.length - 1]
    const {value} = targetOption
    targetOption.loading = false
    targetOption.children = getOptionChildren(value)
    setOptions(state => [...state])
  }

  // 显示
  function displayRender(label) {
    return label[label.length - 1]
  }

  useImperativeHandle(ref, () => ({
    onDisplay: (item: ContractEquipmentItemDTO, index) => {
      setVisible(true)
      setIndex(index)
      setItem(item)
      if (item.code) {
        form.setFieldsValue({
          name: [item.name],
          remark: item.remark || undefined,
          price: item.price || undefined,
          model: item.model || undefined,
        })
      }
    },
    onHidden: () => {
      formRef.current.resetFields()
      setVisible(false)
    },
  }))

  //保存
  function onFinish() {
    const {price, model, remark} = form.getFieldsValue()
    onSuccess && onSuccess({...item, price: price ? Number(price) : null, model, remark}, index)
    formRef.current.resetFields()
    setVisible(false)
  }
  return (
    <Modal
      visible={visible}
      title={<div style={{textAlign: 'left'}}>编辑物品</div>}
      footer={null}
      maskClosable={false}
      onCancel={() => {
        ref.current.onHidden()
      }}
    >
      <Form form={form} ref={formRef} onFinish={onFinish}>
        <Form.Item
          label={'物品'}
          labelCol={{span: 6}}
          wrapperCol={{span: 10}}
          name={'name'}
          rules={[{type: 'array', required: true, message: '请选择物品'}]}
        >
          <Cascader
            onChange={(index, selectedOptions) => {
              if (selectedOptions.length) {
                const {label, value} = selectedOptions[2]
                setItem(state => ({...state, code: String(value), name: String(label)}))
              }
            }}
            options={options}
            displayRender={displayRender}
            loadData={loadData}
            expandTrigger='hover'
          />
        </Form.Item>
        <Form.Item label={'单价'} name={'price'} labelCol={{span: 6}} wrapperCol={{span: 10}}>
          <Input placeholder={'输入价格'} />
        </Form.Item>
        <Form.Item label={'品牌'} name={'model'} labelCol={{span: 6}} wrapperCol={{span: 10}}>
          <Input placeholder={'请输入品牌'} />
        </Form.Item>
        <Form.Item label={'附件'} labelCol={{span: 6}}>
          <Album
            limit={1}
            action={baseUrl + '/contract/equipment/img/upload'}
            onDone={fileList => {
              setItem(state => ({...state, attach: fileList.map(item => item.imgUrl).pop()}))
            }}
            showUploadList={true}
            fileList={item.attach ? [{url: getImg(item.attach), uid: '1'}] : []}
          />
          <Text
            style={{
              color: 'rgba(0, 0, 0, 0.25)',
              fontSize: '12px',
            }}
          >
            最多上传一张照片
          </Text>
        </Form.Item>
        <Form.Item label={'备注'} name={'remark'} labelCol={{span: 6}}>
          <Input.TextArea style={{height: '95px'}} placeholder={'请输入备注'} />
        </Form.Item>
        <Form.Item>
          <div style={{textAlign: 'right'}}>
            <Space>
              <Button
                onClick={() => {
                  ref.current.onHidden()
                }}
              >
                取消
              </Button>
              <Button type={'primary'} htmlType={'submit'}>
                确认
              </Button>
            </Space>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default forwardRef(EditModel)
