import React, {useEffect, useState} from 'react'
import {Button, Col, Divider, Form, Input, message, Radio, Row, Steps} from 'antd'

import styles from './index.module.less'
import commonStyles from '@/assets/styles/common.module.less'
import Delete from '@/assets/icons/delete.svg'
import {rules} from '@/utils/validate'
import {PlusOutlined} from '@ant-design/icons/lib'
import {useQuery} from '@/common/hooks/router'
import {propertyModel} from '@/store/models/Property'
import {useHistory} from 'react-router'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import {configModel} from '@/store/models/Config'

export default function AddScatter() {
  const [rentType, setRentType] = useState<RentType>('1')
  const [form] = Form.useForm()

  const history = useHistory()
  const query = useQuery()
  const communityCode = query.get('code')

  // 是否需要显示房源编号
  const isChecked = configModel.useCheckedStatus()

  useEffect(
    function () {
      form.setFieldsValue({
        bathroom: undefined,
        bedroom: 1,
        buildingNo: undefined,
        houseNo: undefined,
        parlor: undefined,
        rentPrice: undefined,
        rentStatus: '0',
        rentType: '1',
        roomInfoList: [
          {
            code: 'Room-A',
            name: undefined,
            rentPrice: undefined,
            rentStatus: '0',
            selfKitchen: '0',
            selfBathroom: '0',
          },
        ],
        unitNo: undefined,
      })
    },
    [form]
  )

  async function onSubmit(value) {
    const params = {
      ...value,
      rentPrice: value.rentPrice ? value.rentPrice : '0',
      rentStatus: value.rentStatus ? value.rentStatus : '0',
      communityCode,
    }
    // console.log('params =>', params)
    await propertyModel.scatterPropertyInsert(params)
    message.warning('添加成功！')
    history.push('/success', {chartType: 'scatter'})
  }

  // 输入室
  function onBedRoomInput() {
    if (rentType === '2') {
      const bedroom = form.getFieldValue('bedroom')
      if (!(+bedroom < 26 && bedroom > 0)) return message.warning('请输入1-26之间的数字')
      form.setFieldsValue({
        roomInfoList: Array.from({length: +bedroom}).map((_, i) => ({
          code: `Room-${String.fromCharCode(65 + i)}`,
          name: undefined,
          rentPrice: undefined,
          rentStatus: '0',
          selfKitchen: '0',
          selfBathroom: '0',
        })),
      })
    }
  }

  return (
    <>
      <Breadcrumb path={['首页', '房源房态', '添加房源']} />
      <div className={commonStyles.section}>
        <Steps current={1} labelPlacement={'vertical'} style={{padding: '0 149px'}}>
          <Steps.Step title='选择小区' disabled />
          <Steps.Step title='房源录入' disabled />
        </Steps>
      </div>
      <div className={`${commonStyles.flexFullSection}`}>
        <Form form={form} colon={false} labelCol={{span: 5}} onFinish={onSubmit}>
          <Row>
            <Col span={12}>
              <Form.Item name={'rentType'} label={'租赁方式'} rules={[{required: true, message: '请选择租赁方式'}]}>
                <Radio.Group onChange={({target}) => setRentType(target.value)}>
                  <Radio value={'1'}>分散整租</Radio>
                  <Radio value={'2'}>分散合租</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item name={'buildingNo'} label={'楼栋号'} rules={rules.buildingNo}>
                <Input placeholder={'请输入楼栋号'} />
              </Form.Item>
              <Form.Item name={'unitNo'} label={'单元号'}>
                <Input placeholder={'请输入单元号'} />
              </Form.Item>
              <Form.Item name={'houseNo'} label={'房号'} rules={rules.houseNo}>
                <Input placeholder={'请输入房号'} />
              </Form.Item>
              {rentType === '1' && (
                <>
                  {isChecked && (
                    <Form.Item name={'propertyCodeCustom'} label={'自定义房源编号'}>
                      <Input placeholder={'请输入自定义房源编号'} />
                    </Form.Item>
                  )}
                  <Form.Item
                    name={'rentStatus'}
                    label={'出租状态'}
                    rules={[{required: true, message: '请选择出租状态'}]}
                  >
                    <Radio.Group>
                      <Radio value={'0'}>未租</Radio>
                      <Radio value={'1'}>已租</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item name={'rentPrice'} label={'租金'}>
                    <Input placeholder={'请输入租金'} />
                  </Form.Item>
                </>
              )}
              <Form.Item label={'室/厅/卫/厨'} required>
                <Row gutter={10}>
                  <Col span={6}>
                    <Form.Item name={'bedroom'} style={{marginBottom: 0}} rules={rules.bedroom}>
                      <Input
                        placeholder={'请输入'}
                        suffix={'室'}
                        onBlur={onBedRoomInput}
                        onPressEnter={onBedRoomInput}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item name={'parlor'} style={{marginBottom: 0}} rules={rules.parlor}>
                      <Input placeholder={'请输入'} suffix={'厅'} />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item name={'bathroom'} style={{marginBottom: 0}} rules={rules.bathroom}>
                      <Input placeholder={'请输入'} suffix={'卫'} />
                    </Form.Item>
                  </Col>
                  {rentType === '1' ? (
                    <Col span={6}>
                      <Form.Item name={'kitchen'} style={{marginBottom: 0}} rules={rules.bathroom}>
                        <Input placeholder={'请输入'} suffix={'厨'} />
                      </Form.Item>
                    </Col>
                  ) : null}
                </Row>
              </Form.Item>
            </Col>
          </Row>
          {rentType === '2' && (
            <Form.List name={'roomInfoList'}>
              {(fields, {add, remove}) => {
                return (
                  <div className={styles.roomList}>
                    {fields.map(field => (
                      <div key={field.key} className={styles.roomItem}>
                        <img
                          src={Delete}
                          alt={'icon'}
                          className={styles.roomDelete}
                          onClick={() => {
                            remove(field.name)
                            const roomInfoList = form.getFieldValue('roomInfoList')
                            form.setFieldsValue({
                              bedroom: roomInfoList?.length,
                              roomInfoList: roomInfoList?.map((item, index) => ({
                                ...item,
                                code: `Room-${String.fromCharCode(65 + index)}`,
                              })),
                            })
                          }}
                        />
                        <div className={styles.roomBorder}>
                          <Form.Item
                            name={[field.name, 'code']}
                            fieldKey={[field.fieldKey, 'code']}
                            style={{marginBottom: 0}}
                            rules={rules.houseNo}
                          >
                            <Input style={{border: 'none'}} placeholder={'请输入房间号'} />
                          </Form.Item>
                          <Divider className={styles.roomDivider} />
                          {isChecked && (
                            <>
                              <Form.Item
                                name={[field.name, 'propertyCodeCustom']}
                                fieldKey={[field.fieldKey, 'propertyCodeCustom']}
                                style={{marginBottom: 0}}
                              >
                                <Input style={{border: 'none'}} placeholder={'请输入房源编号(选填)'} />
                              </Form.Item>
                              <Divider className={styles.roomDivider} />
                            </>
                          )}
                          <Form.Item
                            name={[field.name, 'name']}
                            fieldKey={[field.fieldKey, 'name']}
                            style={{marginBottom: 0}}
                          >
                            <Input style={{border: 'none'}} placeholder={'请输入房间名'} />
                          </Form.Item>
                          <Divider className={styles.roomDivider} />
                          <Form.Item
                            name={[field.name, 'rentPrice']}
                            fieldKey={[field.fieldKey, 'rentPrice']}
                            style={{marginBottom: 0}}
                            // rules={rules.rentPrice}
                          >
                            <Input style={{border: 'none'}} placeholder={'请输入租金'} />
                          </Form.Item>
                          <Divider className={styles.roomDivider} />
                          <Form.Item
                            name={[field.name, 'selfBathroom']}
                            fieldKey={[field.fieldKey, 'selfBathroom']}
                            style={{marginBottom: 0, paddingLeft: '10px'}}
                            label={'独立卫生间'}
                            labelCol={{span: 10}}
                            labelAlign={'left'}
                          >
                            <Radio.Group>
                              <Radio value={'0'}>无</Radio>
                              <Radio value={'1'}>有</Radio>
                            </Radio.Group>
                          </Form.Item>
                          <Divider className={styles.roomDivider} />
                          <Form.Item
                            name={[field.name, 'selfKitchen']}
                            fieldKey={[field.fieldKey, 'selfKitchen']}
                            style={{marginBottom: 0, paddingLeft: '10px'}}
                            label={'独立厨房'}
                            labelCol={{span: 8}}
                            labelAlign={'left'}
                          >
                            <Radio.Group>
                              <Radio value={'0'}>无</Radio>
                              <Radio value={'1'}>有</Radio>
                            </Radio.Group>
                          </Form.Item>
                          <Divider className={styles.roomDivider} />
                          <Form.Item
                            name={[field.name, 'rentStatus']}
                            fieldKey={[field.fieldKey, 'rentStatus']}
                            style={{marginBottom: 0, paddingLeft: '10px'}}
                            label={'出租状态'}
                            labelCol={{span: 8}}
                            labelAlign={'left'}
                          >
                            <Radio.Group>
                              <Radio value={'0'}>未租</Radio>
                              <Radio value={'1'}>已租</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </div>
                      </div>
                    ))}
                    <div className={styles.roomAdd}>
                      <Button
                        shape={'circle'}
                        type='primary'
                        ghost
                        icon={<PlusOutlined />}
                        onClick={() => {
                          add()
                          const roomInfoList = form.getFieldValue('roomInfoList')
                          // console.log('roomInfoList =>', roomInfoList)
                          form.setFieldsValue({
                            bedroom: roomInfoList?.length,
                            roomInfoList: roomInfoList?.map((item, index) => {
                              return {
                                ...item,
                                code: item?.code ? item.code : `Room-${String.fromCharCode(65 + index)}`,
                                rentStatus: item?.rentStatus ? item.rentStatus : '0',
                              }
                            }),
                          })
                        }}
                      />
                    </div>
                  </div>
                )
              }}
            </Form.List>
          )}

          <div className={styles.confirmAdd}>
            <Form.Item>
              <Button type={'primary'} htmlType={'submit'} loading={propertyModel.scatterPropertyInsert.useLoading()}>
                确认添加
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </>
  )
}
