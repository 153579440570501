import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import styles from './App.module.less'
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
  RouteProps,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom'
import {Affix, Avatar, Badge, Col, ConfigProvider, Dropdown, Layout, Menu, Row, Space, Typography} from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
// import logo from '@/assets/imgs/logo.png'
import logo from '@/assets/icons/logo.svg'
import {
  CaretDownOutlined,
  CustomerServiceOutlined,
  DashboardOutlined,
  TeamOutlined,
  DesktopOutlined,
} from '@ant-design/icons'

import Chart from './property/Chart'
import Login from './auth/Login'
import BillList from './financial/BillList'
import Contract from './contract/List'
import ContractDetail from './contract/ContractDetail'
import TenantRegister from './contract/TenantRegister'
import {userModel} from '@/store/models/User'
import {getImg} from '@/utils/util'
import TradingWater from './financial/TradingWater'
import Add from './property/Add'
import AddCondo from '@/pages/property/AddCondo'
import AddScatter from '@/pages/property/AddScatter'
import Test from '@/pages/test/Test'
import RenterList from '@/pages/contract/RenterList'
import SelectCommunity from '@/pages/property/SelectCommunity'
import AddSuccess from '@/pages/property/AddSuccess'
import Information from '@/pages/auth/Information'
import MyAccount from '@/pages/my/Account'
import Framework from '@/pages/team/Framework'
import Home from '@/pages/home/Home'
import AddRoom from '@/pages/property/AddRoom'
import DefaultPortrait from '@/assets/icons/default_portrait.svg'
import SettingPassword from '@/pages/my/Password'
import PaymentCode from '@/pages/my/PaymentCode'
import EnterpriseCer from '@/pages/team/EnterpriseCer'
import Quick from '@/pages/property/Quick'
import StewardServiceList from '@/pages/steward/StewardServiceList'
import {
  BellOutlined,
  ClearOutlined,
  FileTextOutlined,
  FundProjectionScreenOutlined,
  HomeOutlined,
  SettingOutlined,
  TransactionOutlined,
  UserAddOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons/lib'
import StewardServiceDetail from '@/pages/steward/StewardServiceDetail'
import ConfigContract from '@/pages/setting/ConfigContract'
import {DenyView, hasPermission, usePermission} from '@/ui/components/Permission'
import MessageList from '@/pages/home/MessageList'
import {messageModel} from '@/store/models/Message'
import PaymentRules from '@/pages/setting/PaymentRules'
import AddLandlordContract from '@/pages/property/AddLandlordContract'
import OwnerContract from '@/pages/contract/OwnerContract'
import ServiceAccount from '@/pages/my/ServiceAccount'
import PropertyDelivery from '@/pages/setting/PropertyDelivery'
import BlackList from '@/pages/setting/BlackList'
import KeepAccount from '@/pages/financial/KeepAccount'
import StewardBill from '@/pages/steward/StewardBill'
import {makeReady} from '@/utils/zhichi'
import OtherSettings from '@/pages/setting/OtherSettings'
import {Feedback} from '@/ui/components/Feedback'
import MeterSetting from '@/pages/meter/MeterSetting'
import MeterReading from '@/pages/meter/MeterReading'
import VacancyCostReport from '@/pages/financial/VacancyCostReport'
import OperationAnalyse from '@/pages/financial/OperationAnalyse'
import GoodsManagement from '@/pages/goods/GoodsManagement'
import DataStatistics from '@/pages/financial/DataStatistics'
import OverdueProperty from '@/pages/property/OverdueProperty'
import DeviceLock from '@/pages/device/DeviceLock'
import DeviceElectric from '@/pages/device/DeviceElectric'
import DeviceWater from '@/pages/device/DeviceWater'
import DeviceLockDetail from '@/pages/device/DeviceLockDetail'
import DeviceElectricDetail from '@/pages/device/DeviceElectricDetail'
import DeviceWaterDetail from '@/pages/device/DeviceWaterDetail'
import DeviceAuth from '@/pages/device/DeviceAuth'
import DeviceBind from '@/pages/device/DeviceBind'
import ContractReserve from '@/pages/contract/ContractReserve'
import ContractReserveDetail from '@/pages/contract/ContractReserveDetail'
import PropertyChange from '@/pages/contract/PropertyChange'
import AccountDetail from '@/pages/financial/AccountDetail'
import TemplateContract from '@/pages/setting/TemplateContract'
import TemplateContractAdd from '@/pages/setting/TemplateContractAdd'
import PopularizeProperty from '@/pages/property/PopularizeProperty'
import {startAnalysis} from '@/utils/analysis'
import TeamModal from '@/pages/property/components/TeamModal'
import CompanyAccount from '@/pages/financial/CompanyAccount'
import {propertyModel} from '@/store/models/Property'
import {useIsProjectCondo} from '@/common/bizHooks/useIsProjectCondo'
import ContractAccounting from '@/pages/contract/ContractAccounting'
import NygDeviceLock from '@/pages/nygDevice/NygDeviceLock'
import NygDeviceLockDetail from '@/pages/nygDevice/NygDeviceLockDetail'
import NygDeviceWater from '@/pages/nygDevice/NygDeviceWater'
import NygDeviceElectricDetail from '@/pages/nygDevice/NygDeviceElectricDetail'
import NygDeviceWaterDetail from '@/pages/nygDevice/NygDeviceWaterDetail'
import NygDeviceElectric from '@/pages/nygDevice/NygDeviceElectric'
import CleanServiceList from '@/pages/steward/Clean/CleanServiceList'
import CleanServiceDetail from '@/pages/steward/Clean/CleanServiceDetail'
interface MenuConfig {
  title: string
  key: string
  to?: string
  icon?: any
  permission?: string
  visible?: boolean // false 则不展示，空或者 true 展示
  subMenu?: MenuConfig[]
}

function useMenus() {
  const isProjectCondo = useIsProjectCondo()
  return useMemo(
    () =>
      [
        {
          title: '主页',
          key: '主页',
          to: '/home',
          icon: <HomeOutlined />,
        },
        {
          title: '房源房态',
          key: '房源房态',
          icon: <FundProjectionScreenOutlined />,
          subMenu: [
            {title: '房态图', key: '/chart', to: '/chart', permission: '002001001'},
            {title: '添加房源', key: '/add', to: '/add', permission: '002001002', visible: !isProjectCondo},
            {title: '推广房源', key: '/popularize', to: '/popularize', permission: '002003001'},
            {title: '空房快查', key: '/quick', to: '/quick', permission: '002002001'},
          ],
        },
        {
          title: '租约管理',
          key: '租约管理',
          icon: <FileTextOutlined />,
          subMenu: [
            {title: '租约列表', key: '/contract', to: '/contract', permission: '003001001'},
            {
              title: '租约账务',
              key: '/contract-accounting',
              to: '/contract-accounting',
              permission: '003004001',
              visible: isProjectCondo,
            },
            {title: '入住人列表', key: '/renter', to: '/renter', permission: '003002001'},
            {title: '预订单列表', key: '/reserve', to: '/reserve', permission: '003003001'},
          ],
        },
        {
          title: '财务管理',
          key: '财务管理',
          icon: <TransactionOutlined />,
          subMenu: [
            {title: '账户明细', key: '/account-detail', to: '/account-detail', permission: '004007001'},
            {title: '账单列表', key: '/bill', to: '/bill', permission: '004001001'},
            {title: '交易流水', key: '/trade', to: '/trade', permission: '004002001'},
            {title: '营业报表', key: '/operate', to: '/operate', permission: '004005001'},
            {title: '水电煤抄表', key: '/meter-reading', to: '/meter-reading', permission: '004003001'},
            {title: '空置成本报表', key: '/vacancy', to: '/vacancy', permission: '004006001'},
            {title: '物品管理', key: '/goods-management', to: '/goods-management', permission: '004004001'},
            {title: '数据统计', key: '/statistics', to: '/statistics', permission: '004006001'},
          ],
        },
        {
          title: '管家服务',
          key: '管家服务',
          icon: <ClearOutlined />,
          subMenu: [
            {title: '维修服务单', key: '/steward', to: '/steward', permission: '005001001'},
            {title: '保洁服务单', key: '/clean', to: '/clean', permission: '005001001'},
            {title: '服务费账户', key: '/serviceAccount', to: '/serviceAccount', permission: '009002001'},
            {title: '管家账单', key: '/stewardBill', to: '/stewardBill', permission: '009003001'},
          ],
        },
        {
          title: '团队管理',
          key: '团队管理',
          icon: <TeamOutlined />,
          subMenu: [
            {
              title: '团队架构',
              key: '/framework',
              to: '/framework',
              visible: !isProjectCondo,
              // , permission: '006001001'
            },
            // {title: '房源分配', key: '/condo2', to: '/condo2'},
          ],
        },
        {
          title: '智能设备',
          key: '智能设备',
          icon: <DashboardOutlined />,
          subMenu: [
            {title: '门锁', key: '/device-lock', to: '/device-lock'},
            {title: '电表', key: '/device-electric', to: '/device-electric'},
            {title: '水表', key: '/device-water', to: '/device-water'},
          ],
        },
        {
          title: '牛油果智能设备',
          key: '牛油果智能设备',
          icon: <DesktopOutlined />,
          subMenu: [
            {title: '门锁', key: '/nyg-device-lock', to: '/nyg-device-lock', visible: isProjectCondo},
            {title: '电表', key: '/nyg-device-electric', to: '/nyg-device-electric', visible: isProjectCondo},
            {title: '水表', key: '/nyg-device-water', to: '/nyg-device-water', visible: isProjectCondo},
          ],
        },
        {
          title: '设置',
          key: '设置',
          icon: <SettingOutlined />,
          // permission: PermissionEnum.PERMISSION_CONFIG_CONTRACT,
          subMenu: [
            {
              title: '财务科目设置',
              key: '/config-contract',
              to: '/config-contract',
              permission: '007001001',
              visible: !isProjectCondo,
            },
            {
              title: '支付规则设置',
              key: '/payment-rules',
              to: '/payment-rules',
              permission: '007002001',
              visible: !isProjectCondo,
            },
            {title: '物业交割设置', key: '/property-delivery', to: '/property-delivery', permission: '007003001'},
            {title: '其它设置', key: '/other-settings', to: '/other-settings'},
            {title: '添加黑名单', key: '/black-list', to: '/black-list', permission: '007004001'},
            {title: '水电煤设置', key: '/meter-setting', to: '/meter-setting', permission: '007005001'},
            {
              title: '合同模板设置',
              key: '/template-contract',
              to: '/template-contract',
              permission: '007006001',
              visible: !isProjectCondo,
            },
          ],
        },
      ] as MenuConfig[],
    [isProjectCondo]
  )
}

// 当前路径是否匹配传入的 menu key
const isMatch = (to: string, pathname?: string) => {
  pathname = pathname ?? window.location.pathname
  // 路由完全相等，或以一级路由开头
  return to === pathname || (to !== '/' && new RegExp(`^${to}/.+`).test(pathname))
}

function AppMenu() {
  const permissionCodeList = userModel.useData(data => data.userInfo?.permissionCodeList)

  const menus = useMenus()

  const renderMenu = useMemo(() => {
    return menus.reduce((prev, item) => {
      const menu = {...item}
      if (!menu.subMenu) {
        prev.push(menu)
      } else {
        menu.subMenu = menu.subMenu.filter(
          value => value.visible !== false && (!value.permission || hasPermission(value.permission, permissionCodeList))
        )
        if (menu.subMenu.length) {
          prev.push(menu)
        }
      }

      return prev
    }, [])
  }, [menus, permissionCodeList])

  // 根据当前url获取menu key
  const getSelectKey = useCallback(
    (pathname: string) => {
      for (const menu of menus) {
        if (menu.to && isMatch(menu.to, pathname)) return menu.key
        else if (menu.subMenu) {
          for (const subMenu of menu.subMenu) {
            if (isMatch(subMenu.to, pathname)) return subMenu.key
          }
        }
      }
    },
    [menus]
  )

  // 获取打开的顶级的 menu key
  const getOpenKey = useCallback(
    (selectKey: string) => {
      return menus.find(menu =>
        menu.to ? isMatch(menu.to, selectKey) : menu.subMenu?.some(subMenu => isMatch(subMenu.to, selectKey))
      )?.key
    },
    [menus]
  )

  const defaultSelectedKey = useMemo(() => getSelectKey(window.location.pathname), [getSelectKey])
  const [selectKey, setSelectKey] = useState(defaultSelectedKey)

  const defaultOpenKey = useMemo(() => getOpenKey(defaultSelectedKey), [defaultSelectedKey, getOpenKey])
  const [openKeys, setOpenKeys] = useState([defaultOpenKey])

  const history = useHistory()
  useEffect(
    function () {
      return history.listen((location, action) => {
        const currentSelectKey = getSelectKey(location.pathname)
        if (currentSelectKey) {
          setSelectKey(currentSelectKey)
          const openKey = getOpenKey(currentSelectKey)
          if (!openKeys.includes(openKey)) {
            setOpenKeys(prevState => [...prevState, openKey])
          }
        }
      })
    },
    [getOpenKey, getSelectKey, history, openKeys]
  )

  return (
    <Menu
      onOpenChange={(_openKeys: any) => setOpenKeys(_openKeys)}
      defaultOpenKeys={[defaultOpenKey]}
      defaultSelectedKeys={[defaultSelectedKey]}
      openKeys={openKeys}
      selectedKeys={[selectKey]}
      mode='inline'
      theme='light'
      style={{userSelect: 'none'}}
    >
      {renderMenu.map(menu =>
        !menu.subMenu ? (
          <Menu.Item key={menu.key} icon={menu.icon}>
            <Link to={menu.to}>{menu.title}</Link>
          </Menu.Item>
        ) : (
          <Menu.SubMenu key={menu.key} title={menu.title} icon={menu.icon}>
            {menu.subMenu.map(subMenu => (
              <Menu.Item key={subMenu.key}>
                <Link to={subMenu.to}>{subMenu.title}</Link>
              </Menu.Item>
            ))}
          </Menu.SubMenu>
        )
      )}
    </Menu>
  )
}

function App() {
  const [auth] = usePermission()

  useEffect(startAnalysis, [])

  const isProjectCondo = useIsProjectCondo()

  // 和 teamId 无关
  const teamId = userModel.useData(data => data.currentTeam?.teamId)
  useEffect(() => {
    if (teamId) {
      propertyModel.condoProjectListProject({})
    }
  }, [teamId])

  return (
    <ConfigProvider locale={zhCN}>
      <Router>
        <Switch>
          <Route exact path={'/login'} component={Login} />
          <PrivateRoute path={'/'}>
            <Layout style={{minHeight: '100vh'}}>
              <Affix offsetTop={0} style={{zIndex: 999}}>
                <Layout.Sider theme={'light'} className={styles.slider} width={257}>
                  <Link to={'/'} className={styles.headerContainer}>
                    <img src={logo} alt='牛油果房东' width={123} height={34} />
                  </Link>
                  <AppMenu />
                </Layout.Sider>
              </Affix>

              <Layout>
                <Affix offsetTop={0}>
                  <AppHeader />
                </Affix>
                <Layout.Content className={styles.content}>
                  <Route exact path={'/'}>
                    <Redirect to={'/home'} />
                  </Route>
                  <ScrollToTop />
                  <Route exact path={'/test'} component={Test} />
                  {/*首页*/}
                  {auth('001002001') ? (
                    <Route exact path={'/home'} component={Home} />
                  ) : (
                    <Route exact path={'/home'} component={DenyView} />
                  )}

                  {/*首页*/}
                  <Route exact path={'/messageList'} component={MessageList} />
                  {/*房态图*/}
                  <Route exact path={'/chart'} component={Chart} />
                  {/*/!*分散式房态图*!/*/}
                  {/*<Route exact path={'/scatter'} render={props => <Chart {...props} chartType={'scatter'} />} />*/}
                  {/*/!*集中式房态图*!/*/}
                  {/*<Route exact path={'/condo'} render={props => <Chart {...props} chartType={'condo'} />} />*/}
                  <Route exact path={'/roomAdd'} component={AddRoom} />
                  {/*推广房源*/}
                  <Route exact path={'/popularize'} component={PopularizeProperty} />
                  {/*空房快查*/}
                  <Route exact path={'/quick'} component={Quick} />
                  {/*添加房源*/}
                  <VisibleRoute visible={!isProjectCondo} exact path={'/add'} component={Add} />
                  <VisibleRoute visible={!isProjectCondo} exact path={'/add/community'} component={SelectCommunity} />
                  <VisibleRoute visible={!isProjectCondo} exact path={'/add/condo'} component={AddCondo} />
                  <VisibleRoute visible={!isProjectCondo} exact path={'/add/scatter'} component={AddScatter} />
                  {/*房源添加成功页面*/}
                  <Route exact path={'/success'} component={AddSuccess} />
                  {/*添加业主合同*/}
                  <Route exact path={'/landlord-contract'} component={AddLandlordContract} />
                  {/*账单列表*/}
                  <Route exact path={'/bill'} component={BillList} />
                  {/*交易流水*/}
                  <KeepAliveRoute permission={'004002001'} exact path={'/trade'} component={TradingWater} />
                  {/*/!*租约列表*!/*/}
                  <KeepAliveRoute
                    permission={'003001001'}
                    exact
                    path={'/contract'}
                    component={Contract}
                    exclude={['/landlord-contract']}
                  />
                  <VisibleRoute
                    visible={auth('003004001') && isProjectCondo}
                    exact
                    path={'/contract-accounting'}
                    component={ContractAccounting}
                  />
                  <Route exact path={'/contract/detail'} component={ContractDetail} />
                  {/*入住人列表*/}
                  <KeepAliveRoute permission={'003002001'} exact path={'/renter'} component={RenterList} />
                  {/*预订*/}
                  <Route exact path={'/contract/reserve'} component={ContractReserve} />
                  {/*预订列表*/}
                  <Route exact path={'/reserve'} render={props => <Contract {...props} isReserve />} />
                  {/*预订详情*/}
                  <Route exact path={'/reserve/detail'} component={ContractReserveDetail} />
                  {/*租客登记*/}
                  <Route exact path={'/contract/register'} component={TenantRegister} />
                  {/*我的资料*/}
                  <Route exact path={'/information'} component={Information} />
                  {/*我的账户*/}
                  <Route exact path={'/account'} component={MyAccount} />
                  {/*服务费账户*/}
                  <Route exact path={'/serviceAccount'} component={ServiceAccount} />
                  {/*管家账单*/}
                  <Route exact path={'/stewardBill'} component={StewardBill} />
                  {/*设置密码*/}
                  <Route exact path={'/password'} component={SettingPassword} />
                  {/*设置支付密码*/}
                  <Route exact path={'/paymentCode'} component={PaymentCode} />

                  {/*管家服务*/}
                  <KeepAliveRoute permission={'005001001'} exact path={'/steward'} component={StewardServiceList} />
                  <Route exact path={'/steward/detail'} component={StewardServiceDetail} />

                  {/*团队架构*/}
                  <Route exact path={'/framework'} component={Framework} />
                  {/*财务科目设置*/}
                  <VisibleRoute
                    visible={auth('007001001') && !isProjectCondo}
                    exact
                    path={'/config-contract'}
                    component={ConfigContract}
                  />
                  {/*支付规则设置*/}
                  <VisibleRoute visible={!isProjectCondo} exact path={'/payment-rules'} component={PaymentRules} />
                  {/*企业认证*/}
                  <Route exact path={'/enterprise'} component={EnterpriseCer} />
                  {/*<Switch>
                    <Route path={'*'}>
                      404 <Link to={'/'}>去首页</Link>
                    </Route>
                  </Switch>*/}
                  {/* 业主合同详情 */}
                  <Route exact path={'/contract/owner'} component={OwnerContract} />
                  {/* 物业交割设置 */}
                  <Route exact path={'/property-delivery'} component={PropertyDelivery} />
                  <Route exact path={'/black-list'} component={BlackList} />
                  <Route exact path={'/other-settings'} component={OtherSettings} />
                  {/* 水电煤设置 */}
                  <Route exact path={'/meter-setting'} component={MeterSetting} />
                  <Route exact path={'/meter-reading'} component={MeterReading} />
                  <Route exact path={'/vacancy'} component={VacancyCostReport} />
                  <Route exact path={'/operate'} component={OperationAnalyse} />
                  {/* 物品管理 */}
                  <Route exact path={'/goods-management'} component={GoodsManagement} />
                  <Route exact path={'/statistics'} component={DataStatistics} />
                  {/* 账单逾期房源 */}
                  <Route exact path={'/overdue-property'} component={OverdueProperty} />
                  {/* 智能设备 */}
                  <Route
                    exact
                    path={'/device-lock'}
                    render={() => (
                      <DeviceAuth>
                        <DeviceLock />
                      </DeviceAuth>
                    )}
                  />
                  <Route exact path={'/device-lock/detail'} component={DeviceLockDetail} />
                  <Route
                    exact
                    path={'/device-electric'}
                    render={() => (
                      <DeviceAuth>
                        <DeviceElectric />
                      </DeviceAuth>
                    )}
                  />
                  <Route exact path={'/device-electric/detail'} component={DeviceElectricDetail} />
                  <Route
                    exact
                    path={'/device-water'}
                    render={() => (
                      <DeviceAuth>
                        <DeviceWater />
                      </DeviceAuth>
                    )}
                  />
                  <Route exact path={'/device-water/detail'} component={DeviceWaterDetail} />
                  <Route exact path={'/device-bind'} component={DeviceBind} />
                  <PermissionRoute
                    permission={'003001017'}
                    exact
                    path={'/contract/property-change'}
                    component={PropertyChange}
                  />
                  <Route exact path={'/account-detail'} component={AccountDetail} />
                  {/* 牛油果智能设备 */}
                  <VisibleRoute exact visible={isProjectCondo} path={'/nyg-device-lock'} component={NygDeviceLock} />
                  <VisibleRoute
                    exact
                    visible={isProjectCondo}
                    path={'/nyg-device-lock/detail'}
                    component={NygDeviceLockDetail}
                  />
                  <VisibleRoute
                    exact
                    visible={isProjectCondo}
                    path={'/nyg-device-electric'}
                    component={NygDeviceElectric}
                  />
                  <VisibleRoute
                    exact
                    visible={isProjectCondo}
                    path={'/nyg-device-electric/detail'}
                    component={NygDeviceElectricDetail}
                  />
                  <VisibleRoute exact visible={isProjectCondo} path={'/nyg-device-water'} component={NygDeviceWater} />
                  <VisibleRoute
                    exact
                    visible={isProjectCondo}
                    path={'/nyg-device-water/detail'}
                    component={NygDeviceWaterDetail}
                  />
                  {/* 合同模板设置 */}
                  <VisibleRoute
                    visible={auth('007006001') && !isProjectCondo}
                    exact
                    path={'/template-contract'}
                    component={TemplateContract}
                  />
                  {/* 新增合同模板 */}
                  <PermissionRoute
                    permission={'007006002'}
                    exact
                    path={'/template-contract/add'}
                    component={TemplateContractAdd}
                  />
                  <Route exact path={'/company-account'} component={CompanyAccount} />
                  <KeepAliveRoute permission={'005001001'} exact path={'/clean'} component={CleanServiceList} />
                  <Route exact path={'/clean/detail'} component={CleanServiceDetail} />
                </Layout.Content>
              </Layout>
            </Layout>
          </PrivateRoute>
        </Switch>
      </Router>
    </ConfigProvider>
  )
}

export default App

function AppHeader() {
  const userInfo = userModel.useData(data => data.userInfo)
  const currentTeam = userModel.useData(data => data.currentTeam)
  const msgTotal = messageModel.useData(data => data.msgTotal)
  const [auth] = usePermission()
  const isProjectCondo = useIsProjectCondo()

  const accountRef = useRef(null)
  const feedbackRef = useRef(null)
  const teamModalRef = useRef(null)

  const quickMenu = useMemo<{when: boolean; title: string; link?: string; onClick?: () => void}[]>(
    () =>
      (!isProjectCondo
        ? [
            {when: userModel.canHandleProperty && auth('002001002'), title: '添加集中式房源', link: '/add/condo'},
            {when: userModel.canHandleProperty && auth('002001002'), title: '添加分散式房源', link: '/add/community'},
            {when: auth('008001001'), title: '记账', onClick: () => accountRef.current?.onShow()},
          ]
        : []
      ).filter(value => value.when),
    [auth, isProjectCondo]
  )

  return (
    <Layout.Header className={styles.header}>
      <Row justify={'space-between'}>
        <Col />
        <Row gutter={30}>
          <Col onClick={() => teamModalRef.current?.show()} style={{cursor: 'pointer'}}>
            <Space>
              <UserSwitchOutlined />
              {currentTeam.name}
              <CaretDownOutlined />
            </Space>
          </Col>
          {!!quickMenu.length && (
            <Dropdown
              overlay={
                <Menu>
                  {quickMenu.map(value => {
                    // console.log('value', value)
                    return (
                      <Menu.Item key={value.title}>
                        {value.link ? (
                          <Link to={value.link}>{value.title}</Link>
                        ) : (
                          <span onClick={value.onClick}>{value.title}</span>
                        )}
                      </Menu.Item>
                    )
                  })}
                </Menu>
              }
            >
              <Col>
                <Space>
                  <UserAddOutlined />
                  快速添加
                </Space>
              </Col>
            </Dropdown>
          )}

          <Col>
            <Link to={'/messageList'}>
              <Badge count={msgTotal} offset={[msgTotal >= 10 ? 10 : 5, 0]} overflowCount={99}>
                <Space>
                  <BellOutlined />
                  {msgTotal <= 0 && `消息`}
                </Space>
              </Badge>
            </Link>
          </Col>
          <Dropdown
            onVisibleChange={visible => visible && makeReady()}
            overlay={
              <Menu>
                <Menu.Item>
                  <a className={'zhiCustomBtn'}>在线客服</a>
                </Menu.Item>
                <Menu.Item>
                  <div className={styles.phone}>
                    <Typography.Text>客服电话：</Typography.Text>
                    <br />
                    <Typography.Text>400-168-1217</Typography.Text>
                  </div>
                </Menu.Item>
              </Menu>
            }
          >
            <Col>
              <Space>
                <CustomerServiceOutlined />
                客服咨询
              </Space>
            </Col>
          </Dropdown>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item>
                  <Link to={'/information'}>我的资料</Link>
                </Menu.Item>
                <Menu.Item>
                  <Link to={'/account'}>我的账户</Link>
                </Menu.Item>
                {/*{auth('009002001') && (
                  <Menu.Item>
                    <Link to={'/serviceAccount'}>服务费账户</Link>
                  </Menu.Item>
                )}
                {auth('009003001') && (
                  <Menu.Item>
                    <Link to={'/stewardBill'}>管家账单</Link>
                  </Menu.Item>
                )}*/}
                <Menu.Item onClick={() => feedbackRef.current.show()}>投诉建议</Menu.Item>
                <Menu.Item>
                  <Link to={'/password'}>修改登录密码</Link>
                </Menu.Item>
                <Menu.Item>
                  <Link to={'/paymentCode'}>修改支付密码</Link>
                </Menu.Item>
                <Menu.Item onClick={() => userModel.logout()}>退出登录</Menu.Item>
              </Menu>
            }
          >
            <Col>
              <Space>
                {userInfo.avatar ? (
                  <Avatar src={getImg(`${userInfo.avatar}?v=${Math.random()}`)} />
                ) : (
                  <Avatar src={DefaultPortrait} />
                )}
                {userInfo.certName}
                <CaretDownOutlined />
              </Space>
            </Col>
          </Dropdown>
        </Row>
      </Row>

      <KeepAccount ref={accountRef} />
      <Feedback ref={feedbackRef} />
      <TeamModal ref={teamModalRef} />
    </Layout.Header>
  )
}

type PrivateRouteProps = RouteProps

/**
 * 权限路由
 */
function PrivateRoute(props: PrivateRouteProps) {
  const token = userModel.useData(data => !!data.token)
  const location = useLocation()

  return token ? <Route {...props} /> : <Redirect to={{pathname: '/login', state: {from: location}}} />
}

type KeepAliveRouteProps = {
  permission?: string
  style?: React.CSSProperties
  className?: string
  exclude?: string[]
} & RouteProps

/**
 * 保持状态的Route
 */
function KeepAliveRoute({
  permission,
  style,
  className,
  path,
  exact,
  exclude /* 排除 exact 的副作用 */,
  ...rest
}: KeepAliveRouteProps) {
  const location = useLocation()
  const [isInit, setIsInit] = useState(false)
  const pathname = location.pathname

  const removeCache = exclude?.includes(pathname)
  useEffect(() => {
    if (pathname === path && !isInit) setIsInit(true)
    else if (removeCache && isInit) setIsInit(false)
  }, [pathname, path, removeCache, isInit])

  if (!hasPermission(permission)) return null

  return !isInit ? null : (
    <div className={className} style={{...style, ...(pathname !== path ? {display: 'none'} : {})}}>
      {/*path=*，防止Router内部自动销毁*/}
      <Route path={'*'} {...rest} />
    </div>
  )
}

type PermissionRouteProps = {
  permission: string
} & RouteProps

function PermissionRoute({permission, ...rest}: PermissionRouteProps) {
  // todo: redirect to /home
  return hasPermission(permission) ? <Route {...rest} /> : null
}

const VisibleRoute: React.FC<{visible: boolean} & RouteProps> = ({visible, ...rest}) => {
  return visible ? <Route {...rest} /> : null
}

function ScrollToTop() {
  const {pathname} = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}
