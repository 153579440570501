import React, {useState, useEffect, useRef, useCallback} from 'react'
import styles from './index.module.less'
import {
  Select,
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  Button,
  Table,
  Badge,
  Space,
  Typography,
  message,
  Modal,
  Divider,
  Tabs,
} from 'antd'
import {contractModel} from '@/store/models/Contract'
import {commonModel, useCodeArraySync} from '@/store/models/Common'
import {useLocation, useHistory} from 'react-router-dom'
import BillDetail from '@/pages/financial/components/BillDetail'
import ConfirmCollection from '@/pages/financial/components/ConfirmCollection'
import {downloadFile} from '@/utils/util'
import BillDivision from '@/pages/financial/components/BillDivision'
import {Permission, usePermission} from '@/ui/components/Permission'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import dayjs from 'dayjs'
import moment from 'moment'
import {useIsProjectCondo} from '@/common/bizHooks/useIsProjectCondo'

const {Option} = Select
const {Text} = Typography
const {RangePicker} = DatePicker
const {TabPane} = Tabs

export default function BillList() {
  const [auth] = usePermission()
  const location = useLocation<any>()
  const history = useHistory()
  const tradeType = location.state?.tradeType
  const billId = location.state?.billId
  const type = location.state?.type
  const label = location.state?.label
  const tag = location.state?.tag

  const isProjectCondo = useIsProjectCondo()

  const [selectTags, setSelectTags] = useState<any[]>(tag ? [tag] : [''])
  //支付方式
  const allPayWays = useCodeArraySync('bill.payWay')
  const [payWays, setPayWays] = useState<ReturnType<typeof useCodeArraySync>>([])
  useEffect(() => {
    if (!isProjectCondo) {
      contractModel.lessorConfigContractListSelected().then(({response: {data}}) => {
        setPayWays(data.payConfigList.map(item => ({id: item.payWay, label: item.payWayName})))
      })
    }
  }, [isProjectCondo])
  //账单大类
  const billCategory = useCodeArraySync('b')
  // hook 不在判断分支中，此写法可用？
  // const billType = {
  //   'b.a': useCodeArraySync('b.a'),
  //   'b.b': useCodeArraySync('b.b'),
  //   'b.c': useCodeArraySync('b.c'),
  //   'b.d': useCodeArraySync('b.d'),
  //   'b.e': useCodeArraySync('b.e'),
  //   'b.f': useCodeArraySync('b.f'),
  //   'b.g': useCodeArraySync('b.g'),
  //   'b.h': useCodeArraySync('b.h'),
  //   'b.i': useCodeArraySync('b.i'),
  //   'b.j': useCodeArraySync('b.j'),
  //   'b.k': useCodeArraySync('b.k'),
  // }
  //初始化账单子类
  const [billTypeCode, setBillTypeCode] = useState([])
  //初始化业主账单子类
  const [ownerTypeCode, setOwnerTypeCode] = useState([])
  //账单状态
  const billStatusList = useCodeArraySync('bill.status', {defaultItem: true})
  //获取账单列表
  const [tableData, setTableData] = useState<ContractBillListVO[]>([])
  //分页总数
  const [total, setTotal] = useState(0)
  const [pageNum, setPageNum] = useState(1)

  const [ownerBillList, setOwnerBillList] = useState([] as ContractLessorBillListQueryResultVO[])
  //查询业主列表参数
  const [ownerPageNum, setOwnerPageNum] = useState(1)
  const [form] = Form.useForm()
  const [ownerForm] = Form.useForm()
  const formRef = useRef(null)
  const ownerFormRef = useRef(null)
  const loading = contractModel.billQueryList.useLoading()
  const ownerLoading = contractModel.contractLessorBillList.useLoading()
  // 获取总账单
  const [billTotal, setBillTotal] = useState<ContractBillListCountVO>()

  //获取业主总账单
  const [ownerBillTotal, setOwnerBillTotal] = useState({} as ContractLessorBillStatisticsVO)
  // 账单详情弹窗ref
  const billDetailRef = useRef(null)
  // 确认收款弹窗ref
  const confirmCollectionRef = useRef(null)
  const ownerStatusArr = [
    {id: '', label: '全部'},
    {id: '1', label: '待付款'},
    {id: '2', label: '已付款'},
    {id: '3', label: '已作废'},
  ]
  const ownerStatusObj = {
    '1': '待付款',
    '2': '已付款',
    '3': '已作废',
  }
  const [activeStatus, setActiveStatus] = useState(ownerStatusArr[0].id as string)
  // 修改
  const [changeForm] = Form.useForm()
  const [alterAmt, setAlterAmt] = useState({
    show: false,
    billId: undefined,
    billAmtTotal: undefined,
    propertyTitle: undefined,
  })

  // 批量收款
  const [batchForm] = Form.useForm()
  const [batch, setBatch] = useState(false)
  const [selectBill, setSelectBill] = useState<ContractBillListVO[]>([])

  //批量选中的数据
  const [ids, setIds] = useState<any[]>([])
  // 账单拆分弹窗ref
  const billDivisionRef = useRef(null)

  const [tabKey, setTabKey] = useState(tradeType === 'lessor' ? '2' : '1')
  const [lesseeBool, setLesseeBool] = useState(true)
  const [lessorBool, setLessorBool] = useState(true)

  //租客账单列表
  const queryLesseeList = useCallback(
    function (pageNum = 1, pageSize = 10, args?: Partial<ContractBillListQueryDTO>) {
      const {billDate, ...rest} = form.getFieldsValue()
      const [billDateStart, billDateFinish] = billDate || []
      const params = {
        pageNum,
        pageSize,
        needBillDetail: true,
        billDateStart: type === 2 ? label : billDateStart && dayjs(billDateStart).format('YYYY-MM-DD HH:mm:ss'),
        billDateFinish: type === 2 ? label : billDateFinish && dayjs(billDateFinish).format('YYYY-MM-DD HH:mm:ss'),
        billId: tradeType === 'lessee' && lesseeBool ? billId : '',
        billStatus: selectTags.join(','),
        propertyCode: type === 1 ? label : '',
        ...rest,
        ...args,
      }
      contractModel.billQueryList(params as ContractBillListQueryDTO).then(({response: {data}}) => {
        setTableData(data)
      })
      contractModel.contractBillQueryListCount(params).then(({response: {data}}) => {
        setBillTotal(data)
        setTotal(data.amtTotalCount)
      })
    },
    [form, type, label, tradeType, lesseeBool, billId, selectTags]
  )

  const queryLessorList = useCallback(
    function (pageNum = 1, pageSize = 10, args?: Partial<ContractLessorBillListQueryDTO>) {
      const {time, ...rest} = ownerForm.getFieldsValue()
      const [billDateBegin, billDateEnd] = time || []
      const params: Partial<ContractLessorBillListQueryDTO> = {
        pageNum,
        pageSize,
        billDateBegin: billDateBegin && dayjs(billDateBegin).format('YYYY-MM-DD HH:mm:ss'),
        billDateEnd: billDateEnd && dayjs(billDateEnd).format('YYYY-MM-DD HH:mm:ss'),
        billId: tradeType === 'lessor' && lessorBool ? billId : '',
        billStatus: activeStatus,
        sortType: '2',
        ...rest,
        ...args,
      }
      contractModel.contractLessorBillList(params).then(({response: {data}}) => {
        setOwnerBillList(data)
      })
      contractModel.contractLessorBillStatistics(params).then(({response: {data}}) => {
        setOwnerBillTotal(data)
      })
    },
    [activeStatus, billId, lessorBool, ownerForm, tradeType]
  )

  useEffect(
    function () {
      if (location.state?.tag) {
        const propertyCode = location.state?.propertyCode
        if (propertyCode) location.state.propertyCode = undefined
        location.state.tag = undefined
        const billDateStart = '2020-01-01 00:00:00'
        const billDateFinish = dayjs().subtract(1, 'day').format('YYYY-MM-DD')
        queryLesseeList(1, 10, {billDateStart, billDateFinish: billDateFinish + ' 23:59:59', propertyCode})
        form.setFieldsValue({billId, billDate: [moment(billDateStart), moment(billDateFinish)]})
      } else {
        queryLesseeList(1, 10)
        form.setFieldsValue({billId})
      }
      queryLessorList(1, 10)
      ownerForm.setFieldsValue({billId})
    },

    [billId, form, location.state, ownerForm, queryLesseeList, queryLessorList]
  )

  //租客切换账单项目
  // function handleBillType(value) {
  //   form.setFieldsValue({billTypeCode: undefined})
  //   if (value) {
  //     setBillTypeCode(billType[value])
  //   } else {
  //     setBillTypeCode([])
  //   }
  // }

  //业主切换账单项目
  // function handleOwnerType(value) {
  //   ownerForm.setFieldsValue({billTypeCode: undefined})
  //   if (value) {
  //     setOwnerTypeCode(billType[value])
  //   } else {
  //     setOwnerTypeCode([])
  //   }
  // }
  //账单状态
  function showStatus(record) {
    let data = {} as {style: string; text: string}
    if (record.billStatus === '1' && record.overdue) data = {style: 'red', text: '逾期'}
    else if (record.billStatus === '2') data = {style: 'blue', text: '已收款'}
    else if (record.billStatus === '3') data = {style: '#80838D', text: '已作废'}
    else if (record.billStatus === '4') data = {style: '#80838D', text: '坏账'}
    else if (record.billStatus === '5') data = {style: '#80838D', text: '部分坏账'}
    else if (record.billStatus === '6') data = {style: '#80838D', text: '已拆分'}
    else data = {style: '#FFB240', text: '未收款'}
    return (
      <div>
        <Badge color={data.style} />
        <Text>{data.text}</Text>
      </div>
    )
  }

  //查询
  function onFinish() {
    queryLesseeList()
  }

  //业主列表查询
  function onOwnerFinish() {
    queryLessorList()
  }

  //重置
  function onReset() {
    history.replace('/bill', {})
    formRef.current.resetFields()
    setPageNum(1)
    setSelectTags([''])
    setLesseeBool(false)
    queryLesseeList(1, 10, {propertyCode: ''})
  }

  //业主列表重置
  function onOwnerReset() {
    ownerFormRef.current.resetFields()
    setOwnerPageNum(1)
    setActiveStatus('')
    setLessorBool(false)
  }
  // 查看详情、收款成功
  function onSuccess() {
    if (tabKey === '1') {
      queryLesseeList(pageNum, 10)
    } else {
      queryLessorList(ownerPageNum, 10)
    }
  }

  //导出所有结果
  function exportAllResults(pageNum = 1, pageSize = 10, args?: ContractBillListQueryDTO) {
    const {billDate, ...rest} = form.getFieldsValue()
    const [billDateStart, billDateFinish] = billDate || []
    const params: ContractBillListQueryDTO = {
      pageNum,
      pageSize,
      needBillDetail: true,
      billDateStart: billDateStart && dayjs(billDateStart).format('YYYY-MM-DD HH:mm:ss'),
      billDateFinish: billDateFinish && dayjs(billDateFinish).format('YYYY-MM-DD HH:mm:ss'),
      billId: tradeType === 'lessee' && lesseeBool ? billId : '',
      billStatus: selectTags.join(','),
      ...rest,
      ...args,
    }
    contractModel
      .billExcelPath({...params, pageable: false})
      .then(({response: {data}}) => downloadFile(data, '导出所有结果'))
  }

  //批量导出
  // function exportSelectResults() {
  //   if (!ids.length) return message.warning('请选择至少一条数据导出')
  //   contractModel.batchDataExcelPath(ids).then(({response: {data}}) => downloadFile(data, '批量导出'))
  // }

  const renderOperate: {
    when: (bill: ContractBillListVO) => boolean
    title: string
    onClick: (bill: ContractBillListVO, event: any) => any
  }[] = [
    {
      when: () => true,
      title: '查看',
      onClick: record => {
        billDetailRef.current.onDisplay()
        billDetailRef.current.onSetBillDetail(record, record?.billId)
      },
    },
    {
      when: record => record.billStatus === '1',
      title: '收款',
      onClick: record => {
        confirmCollectionRef.current.onDisplay()
        confirmCollectionRef.current.onSetBillDetail(record)
      },
    },
    {
      when: record => record.billStatus === '1',
      title: '拆分',
      onClick: record => {
        billDivisionRef.current.onDisplay()
        billDivisionRef.current.onSetInfo(record?.contractId, record?.billId, record?.billAmtUnpaid)
      },
    },
    {
      when: record => record.billStatus === '1',
      title: '修改',
      onClick: record =>
        setAlterAmt({
          show: true,
          billId: record.billId,
          billAmtTotal: record.billAmtTotal,
          propertyTitle: record.propertyTitle,
        }),
    },
  ]

  const columns = [
    {
      title: '房源',
      dataIndex: 'propertyTitle',
      key: 'propertyTitle',
      width: 200,
      align: 'center',
    },
    {
      title: '承租人',
      dataIndex: 'lesseeCertName',
      key: 'lesseeCertName',
      width: 80,
      align: 'center',
    },
    {
      title: '账单项目',
      dataIndex: 'billTypeName',
      key: 'billTypeNamess',
      width: 240,
      align: 'center',
      render: (text, record, index) => {
        return (
          <div>
            <p className={styles.billType}>{record.billTypeName}</p>
            {record.billCategory !== 'b.b' && (
              <p className={styles.billType}>
                {record.billBegin && record.billEnd
                  ? `${dayjs(record.billBegin).format('YYYY-MM-DD')}至${dayjs(record.billEnd).format('YYYY-MM-DD')}`
                  : '无账期'}
              </p>
            )}
          </div>
        )
      },
    },
    {
      title: '账单金额',
      dataIndex: 'billAmtTotal',
      key: 'billAmtTotal',
      width: 80,
      align: 'center',
    },
    {
      title: '应收款日',
      dataIndex: 'billDate',
      key: 'billDate',
      width: 120,
      align: 'center',
    },
    {
      title: '待收款',
      dataIndex: 'billAmtUnpaid',
      key: 'billAmtUnpaid',
      width: 100,
      align: 'center',
    },
    {
      title: '已收款',
      dataIndex: 'billAmtPaid',
      key: 'billAmtPaid',
      width: 240,
      align: 'center',
      render: (text, record, index) => {
        if (record?.billStatus === '2') {
          const {billPayTime, billPayAmt, billPayWayName} =
            (record?.billDetailList.length && record?.billDetailList[0]) || {}
          return (
            <Space>
              <Text>{billPayTime ? dayjs(billPayTime).format('YYYY-MM-DD') : ''}</Text>
              <Text>{billPayWayName}</Text>
              <Text>{billPayAmt}</Text>
            </Space>
          )
        } else {
          return
        }
      },
    },
    {
      title: '账单状态',
      dataIndex: 'billStatus',
      key: 'billStatus',
      width: 120,
      align: 'center',
      render: (text, record, index) => showStatus(record),
    },
    {
      title: '备注',
      dataIndex: 'billRemark',
      key: 'billRemark',
      width: 100,
      align: 'center',
    },
    {
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      fixed: 'right',
      width: 165,
      render: (text, record, index) => {
        if (!auth('003001004')) return null
        return (
          <>
            {renderOperate
              .filter(item => item.when(record))
              .map((_item, index) => {
                return (
                  <React.Fragment key={index}>
                    {index !== 0 && <Divider type={'vertical'} style={{margin: 0}} />}
                    <Button
                      key={index}
                      type={'link'}
                      size={'small'}
                      style={{padding: '0 5px'}}
                      onClick={event => _item.onClick(record, event)}
                    >
                      {_item.title}
                    </Button>
                  </React.Fragment>
                )
              })}
          </>
        )
      },
    },
  ]

  return (
    <div>
      <Breadcrumb
        path={['首页', '财务管理', '账单列表']}
        right={
          <>
            {tabKey === '1' ? (
              <Space>
                {auth('004001002') && !isProjectCondo && (
                  <Button
                    type={'primary'}
                    onClick={() => {
                      if (selectBill.length === 0) {
                        message.warning('请选择批量收款账单')
                      } else {
                        setBatch(true)
                      }
                    }}
                  >
                    批量收款
                  </Button>
                )}
                {auth('004001004') && (
                  <>
                    <Button onClick={() => exportAllResults()}>导出所有结果</Button>
                    {/*<Button onClick={exportSelectResults}>批量导出</Button>*/}
                  </>
                )}
              </Space>
            ) : null}
          </>
        }
      />
      <Tabs
        defaultActiveKey={tradeType === 'lessor' ? '2' : '1'}
        className={styles.tabs}
        onTabClick={index => setTabKey(index)}
      >
        <TabPane tab={'租客账单'} key={'1'}>
          <div className={styles.top}>
            <Form form={form} ref={formRef} onFinish={onFinish}>
              {/** 所属类目 */}
              <Form.Item label={'所属类目'} shouldUpdate style={{marginBottom: 0}}>
                {() => (
                  <Form.Item noStyle style={{marginBottom: 0}}>
                    {billStatusList.map(item => (
                      <Button
                        key={item.id}
                        type={selectTags.indexOf(item.id) > -1 ? 'primary' : 'text'}
                        size={'small'}
                        onClick={() => {
                          setPageNum(1)
                          // @ts-ignore
                          queryLesseeList(1, 10, {billStatus: item.id})
                          setSelectTags([item.id])
                        }}
                        style={{minWidth: '70px', margin: '0 10px'}}
                      >
                        {item.label}
                      </Button>
                    ))}
                  </Form.Item>
                )}
              </Form.Item>
              <Divider dashed style={{margin: '16px 0'}} />
              <Row gutter={20}>
                <Col span={8}>
                  <Form.Item name='billDate' label={'应收款日'}>
                    <RangePicker style={{width: '100%'}} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={'房源地址'} name='propertyTitle'>
                    <Input placeholder={'小区/公寓名/房间号'} style={{width: '100%'}} allowClear />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={'租约编号'} name={'contractId'}>
                    <Input placeholder={'请输入租约编号'} allowClear />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col span={8}>
                  <Form.Item label={'承租人姓名'} name={'lesseeName'}>
                    <Input placeholder={'请输入承租人姓名'} allowClear />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={'承租人手机'} name={'lesseeMobile'}>
                    <Input placeholder={'请输入承租人手机号'} allowClear />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={'收款方式'} name={'payWays'}>
                    <Select placeholder={'请选择'} style={{width: '100%'}} allowClear>
                      {allPayWays.map(item => (
                        <Option value={item.id} key={item.id}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col>
                  <Form.Item label={'账单项目'} name={'billCategory'} style={{marginBottom: 0}}>
                    <Select
                      placeholder={'请选择'}
                      style={{width: '151px'}}
                      onChange={(value: string) => {
                        form.setFieldsValue({billTypeCode: undefined})
                        const arr = commonModel.getCodeArraySync(value)
                        setBillTypeCode(arr)
                      }}
                      allowClear
                    >
                      {billCategory.map(item => (
                        <Option value={item.id} key={item.id}>
                          {item?.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name={'billTypeCode'} style={{marginBottom: 0}}>
                    <Select placeholder={'账单子项目'} style={{width: '151px'}} allowClear>
                      {billTypeCode.map(item => (
                        <Option value={item.id} key={item.id}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row justify={'end'}>
                <Col>
                  <Space size={'small'}>
                    <Button htmlType={'reset'} onClick={onReset}>
                      重置
                    </Button>
                    <Button type={'primary'} htmlType={'submit'}>
                      查询
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Form>
          </div>
          <div className={styles.tableList}>
            <Table
              scroll={{x: 1800}}
              rowKey={'billId'}
              loading={loading}
              // @ts-ignore
              columns={columns}
              dataSource={tableData}
              title={() => (
                <>
                  <Text strong style={{marginRight: '12px', marginLeft: '-6px'}}>
                    查询结果
                  </Text>
                  <Text>
                    总账单
                    <Text strong style={{color: '#017BFF'}}>
                      {billTotal?.amtTotal}
                    </Text>
                    {`元(${billTotal?.amtTotalCount}笔)`}
                  </Text>
                  <Text style={{marginLeft: '20px'}}>
                    已收
                    <Text strong style={{color: '#017BFF'}}>
                      {billTotal?.amtPaid}
                    </Text>
                    {`元(${billTotal?.amtTotalCount - billTotal?.amtUnpaidCount}笔)`}
                  </Text>
                  <Text style={{marginLeft: '20px'}}>
                    待收
                    <Text strong style={{color: '#017BFF'}}>
                      {billTotal?.amtUnpaid}
                    </Text>
                    {`元(${billTotal?.amtUnpaidCount}笔)`}
                  </Text>
                </>
              )}
              rowSelection={{
                selectedRowKeys: ids,
                onChange: (selectedRowKeys, selectRows) => {
                  setIds(selectedRowKeys)
                  setSelectBill(selectRows)
                },
                fixed: true,
              }}
              pagination={{
                total,
                current: pageNum,
                defaultPageSize: 10,
                position: ['bottomCenter'],
                onChange: page => {
                  setPageNum(page)
                  queryLesseeList(page, 10)
                },
                showSizeChanger: false,
              }}
            />
          </div>
        </TabPane>
        <TabPane tab={'业主账单'} key={'2'}>
          <div className={styles.top}>
            <Form form={ownerForm} ref={ownerFormRef} onFinish={onOwnerFinish}>
              <Form.Item label={'所属账目'} shouldUpdate style={{marginBottom: 0}}>
                {ownerStatusArr.map(item => (
                  <Button
                    type={activeStatus === item.id ? 'primary' : 'text'}
                    onClick={() => {
                      setActiveStatus(item.id)
                      queryLessorList(1, 10, {billStatus: item.id})
                      setOwnerPageNum(1)
                    }}
                    style={{minWidth: '70px', margin: '0 10px'}}
                    key={item.id}
                    size={'small'}
                  >
                    {item.label}
                  </Button>
                ))}
              </Form.Item>
              <Divider dashed style={{margin: '16px 0'}} />
              <Row gutter={20}>
                <Col span={8}>
                  <Form.Item label={'房源地址'} name={'propertyTitle'}>
                    <Input placeholder={'输入房源地址'} maxLength={210} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={'姓名'} name={'lessorCertName'}>
                    <Input placeholder={'输入姓名'} maxLength={210} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={'手机号'} name={'lessorMobile'}>
                    <Input placeholder={'输入手机号'} maxLength={210} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col span={8}>
                  <Form.Item label={'应付款日'} name={'time'}>
                    <RangePicker style={{width: '100%'}} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={'租约编号'} name={'contractId'}>
                    <Input placeholder={'输入租约编号'} />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item label={'账单项目'} name={'billCategory'}>
                    <Select
                      placeholder={'请选择'}
                      style={{width: '100%'}}
                      onChange={(value: string) => {
                        ownerForm.setFieldsValue({billTypeCode: undefined})
                        const arr = commonModel.getCodeArraySync(value)
                        setOwnerTypeCode(arr)
                      }}
                      allowClear
                    >
                      {billCategory.map(item => (
                        <Option value={item.id} key={item.id}>
                          {item?.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name={'billTypeCode'}>
                    <Select placeholder={'账单项目子类'} style={{width: '100%'}} allowClear>
                      {ownerTypeCode.map(item => (
                        <Option value={item.id} key={item.id}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row justify={'end'}>
                <Space>
                  <Button type={'primary'} htmlType={'submit'}>
                    查询
                  </Button>
                  <Button htmlType={'reset'} onClick={onOwnerReset}>
                    重置
                  </Button>
                </Space>
              </Row>
            </Form>
          </div>
          <div className={styles.tableList}>
            <Table
              scroll={{x: 1400}}
              rowKey={'billId'}
              dataSource={ownerBillList}
              loading={ownerLoading}
              rowSelection={{
                fixed: true,
              }}
              title={() => (
                <>
                  <Text strong style={{marginRight: '12px', marginLeft: '-6px'}}>
                    查询结果
                  </Text>
                  <Text>
                    总账单
                    <Text strong style={{color: '#017BFF'}}>
                      {ownerBillTotal?.totalSum}
                    </Text>
                    {`元(${ownerBillTotal?.totalCount}笔)`}
                  </Text>
                  <Text style={{marginLeft: '20px'}}>
                    已付
                    <Text strong style={{color: '#017BFF'}}>
                      {ownerBillTotal?.paySum}
                    </Text>
                    {`元(${ownerBillTotal?.payCount}笔)`}
                  </Text>
                  <Text style={{marginLeft: '20px'}}>
                    待付
                    <Text strong style={{color: '#017BFF'}}>
                      {ownerBillTotal?.unpaidSum}
                    </Text>
                    {`元(${ownerBillTotal?.unpaidCount}笔)`}
                  </Text>
                </>
              )}
              columns={[
                {
                  title: '账单状态',
                  key: 'billStatus',
                  dataIndex: 'billStatus',
                  width: 80,
                  align: 'center',
                  render: (text, record, index) => <Text>{ownerStatusObj[text]}</Text>,
                },
                {
                  title: '房源地址',
                  key: 'propertyTitle',
                  dataIndex: 'propertyTitle',
                  width: 200,
                  align: 'center',
                },
                {
                  title: '姓名',
                  key: 'lessorCertName',
                  dataIndex: 'lessorCertName',
                  width: 80,
                  align: 'center',
                },
                {
                  title: '手机号',
                  key: 'lessorMobile',
                  dataIndex: 'lessorMobile',
                  width: 100,
                  align: 'center',
                },
                {
                  title: '账单项目',
                  key: 'billCategoryName',
                  dataIndex: 'billCategoryName',
                  width: 150,
                  align: 'center',
                  render: (text, record, index) => <Text>{`${record.billCategoryName}-${record.billTypeName}`}</Text>,
                },
                {
                  title: '账单金额',
                  key: 'billAmtTotal',
                  dataIndex: 'billAmtTotal',
                  width: 80,
                  align: 'center',
                },
                {
                  title: '应付款日',
                  key: 'billDate',
                  dataIndex: 'billDate',
                  width: 120,
                  align: 'center',
                },
                {
                  title: '操作',
                  key: 'operation',
                  fixed: 'right',
                  dataIndex: 'operation',
                  width: 100,
                  render: (text, record, index) => {
                    return (
                      <div>
                        <Permission code={'004001003'}>
                          <Button
                            type={'link'}
                            size={'small'}
                            style={{padding: '0 5px'}}
                            onClick={() => {
                              billDetailRef.current.onDisplay()
                              billDetailRef.current.onSetBillDetail(record, record?.billId)
                            }}
                          >
                            查看
                          </Button>
                        </Permission>
                        {record?.billStatus === '1' && (
                          <Permission code={'003001014'}>
                            <Divider type={'vertical'} style={{margin: 0}} />
                            <Button
                              type={'link'}
                              size={'small'}
                              style={{padding: '0 5px'}}
                              onClick={() => {
                                confirmCollectionRef.current.onDisplay()
                                confirmCollectionRef.current.onSetBillDetail(record)
                              }}
                            >
                              付款
                            </Button>
                          </Permission>
                        )}
                      </div>
                    )
                  },
                },
              ]}
              pagination={{
                total: ownerBillTotal.totalCount,
                current: ownerPageNum,
                defaultPageSize: 10,
                position: ['bottomCenter'],
                onChange: pageNum => {
                  setOwnerPageNum(pageNum)
                  queryLessorList(pageNum, 10)
                },
                showSizeChanger: false,
              }}
            />
          </div>
        </TabPane>
      </Tabs>
      {/* 确认收款 */}
      <ConfirmCollection
        ref={confirmCollectionRef}
        onSuccess={onSuccess}
        billType={tabKey === '1' ? 'lessee' : 'lessor'}
      />
      {/* 查看 */}
      <BillDetail ref={billDetailRef} onSuccess={onSuccess} billType={tabKey === '1' ? 'lessee' : 'lessor'} />
      {/* 账单拆分 */}
      <BillDivision ref={billDivisionRef} onSuccess={onSuccess} />
      {/* 修改 */}
      <Modal
        centered
        visible={alterAmt.show}
        title={alterAmt.propertyTitle}
        onCancel={() => {
          setAlterAmt(prevState => ({...prevState, show: false}))
          changeForm.resetFields()
        }}
        onOk={async () => {
          await changeForm.validateFields()
          await contractModel.contractBillAlterAmt({
            billId: alterAmt.billId,
            billAmt: changeForm.getFieldValue('billAmt'),
            billRemark: changeForm.getFieldValue('billRemark'),
          })
          message.success('修改成功!')
          changeForm.resetFields()
          setAlterAmt(prevState => ({...prevState, show: false}))
          onSuccess()
        }}
      >
        <Form form={changeForm} labelCol={{span: 6}}>
          <Form.Item label={'原账单金额'}>
            <Typography.Text>{`￥${alterAmt.billAmtTotal}`}</Typography.Text>
          </Form.Item>
          <Row gutter={10}>
            <Col span={18}>
              <Form.Item
                name={'billAmt'}
                label={'实际待收金额'}
                labelCol={{span: 8}}
                rules={[{required: true, message: '请输入实际待收金额'}]}
              >
                <Input placeholder={'请输入实际待收金额'} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item shouldUpdate>
                {form1 => {
                  const billAmt = form1.getFieldValue('billAmt')
                  return (
                    <Typography.Text style={{color: '#FF5151'}}>
                      {!!billAmt &&
                        (alterAmt.billAmtTotal >= billAmt
                          ? `优惠￥${alterAmt.billAmtTotal - billAmt}`
                          : `加收￥${billAmt - alterAmt.billAmtTotal}`)}
                    </Typography.Text>
                  )
                }}
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name={'billRemark'} label={'账单备注'}>
            <Input.TextArea placeholder={'此备注会在租客端显示，请酌情填写'} />
          </Form.Item>
        </Form>
      </Modal>
      {/* 批量收款 */}
      <Modal
        centered
        visible={batch}
        title={'批量收款'}
        onCancel={() => setBatch(false)}
        confirmLoading={contractModel.billConfirm.useLoading()}
        onOk={async () => {
          await batchForm.validateFields()
          const resList = selectBill.filter(item => item.billStatus === '1')
          for (const _item of resList) {
            await contractModel.billConfirm({
              billId: _item.billId,
              billPayAmt: _item.billAmtTotal,
              billPayWayCode: batchForm.getFieldValue('billPayWayCode'),
              billPayTime: batchForm.getFieldValue('billPayTime').format('YYYY-MM-DD'),
              billDetailRemark: batchForm.getFieldValue('billDetailRemark'),
              imgIdList: [],
            })
          }
          setBatch(false)
          // await Promise.all(
          //   selectBill
          //     .filter(item => item.billStatus === '1')
          //     .map(_item => {
          //       contractModel.billConfirm({
          //         billId: _item.billId,
          //         billPayAmt: _item.billAmtTotal,
          //         billPayWayCode: batchForm.getFieldValue('billPayWayCode'),
          //         billPayTime: batchForm.getFieldValue('billPayTime').format('YYYY-MM-DD'),
          //         billDetailRemark: batchForm.getFieldValue('billDetailRemark'),
          //         imgIdList: [],
          //       })
          //     })
          // )
          setIds([])
          setSelectBill([])
          batchForm.resetFields()
          message.success('收款成功！')
          onSuccess?.()
        }}
      >
        <Form form={batchForm} labelCol={{span: 5}}>
          <Form.Item label={'收款方式'} name={'billPayWayCode'} rules={[{required: true, message: '请选择收款方式'}]}>
            <Select placeholder={'请选择'} style={{width: '238px'}} allowClear>
              {payWays.map(item => (
                <Option value={item.id} key={item.id}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={'收款日期'} name={'billPayTime'} rules={[{required: true, message: '请选择收款日期'}]}>
            <DatePicker style={{width: '238px'}} />
          </Form.Item>
          <Form.Item label={'收款备注'} name={'billDetailRemark'}>
            <Input.TextArea autoSize={{minRows: 3, maxRows: 3}} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}
