let isReady = false

const frameManual = () =>
  new Promise(resolve =>
    window.zc('frame_manual', () => {
      isReady = true
      resolve()
    })
  )

/**
 * 动态创建支持按钮
 * 按钮的 class 需设置为 zhiCustomBtn
 */
export const makeReady = async () => {
  if (!isReady) await frameManual()
  window.zc('dynamic_ready')
}
