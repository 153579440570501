import React, {useRef} from 'react'
import {Album} from '@/ui/components/Album'
import {Mapview} from '@/ui/components/Map'
import {Button, Typography, Form, Input, Space, Row, Col} from 'antd'

export default function Test() {
  const mapRef = useRef(null)

  const [form] = Form.useForm()
  function submit() {
    // 验证不到
    form
      .validateFields(['templateDTOS'])
      .then(value => {
        console.log('value', value)
      })
      .catch(error => console.log('error', error))
  }

  // `${field.key} - ${field.name} - ${field.fieldKey}`
  // 11 - 6 - 11 : field.name 为 index，field.key/fieldKey 自增

  return (
    <div>
      <Form form={form}>
        <Form.Item name={'name'} label={'测试'}>
          <Input />
        </Form.Item>
        <Form.List name={'templateDTOS'}>
          {(fields, {remove, add}) => (
            <>
              {fields.map(field => (
                <Space key={field.key} direction={'horizontal'}>
                  <Form.Item
                    label={`${field.key} - ${field.name} - ${field.fieldKey}`}
                    name={[field.name, 'templateName']}
                    fieldKey={[field.fieldKey, 'templateName']}
                    rules={[{required: true, message: '该项不能为空'}]}
                  >
                    <Input placeholder={'模板名称'} />
                  </Form.Item>
                  <Form.Item
                    label={`${field.key} - ${field.name} - ${field.fieldKey}`}
                    name={[field.name, 'deposit']}
                    fieldKey={[field.fieldKey, 'deposit']}
                    rules={[{required: true, message: '该项不能为空'}]}
                  >
                    <Input placeholder={'请输入定金金额'} />
                  </Form.Item>
                  {/*<div>{`${field.key} - ${field.name} - ${field.fieldKey}`}</div>*/}
                  <Button onClick={() => remove(field.name)}>删除</Button>
                </Space>
              ))}
              <Button onClick={() => add()}>增加</Button>
              <Button onClick={submit}>提交</Button>
            </>
          )}
        </Form.List>
      </Form>

      <Album limit={2} />
      <Button onClick={() => mapRef.current?.panToByPath('宝山')}>panto</Button>
      <div style={{width: 600}}>
        <Typography.Title level={4} style={{textAlign: 'center'}}>
          具体位置
        </Typography.Title>
        <Space size={19} direction={'vertical'} style={{padding: '18px 25px', width: '100%'}}>
          <div style={{position: 'relative'}}>
            <Mapview style={{height: 260}} ref={mapRef} onMapChange={event => console.log(event)} />
            <div
              style={{
                width: 20,
                height: 20,
                backgroundColor: 'red',
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            ></div>
          </div>

          <Form labelCol={{span: 4}}>
            <Row>
              <Col span={12} offset={12} pull={12}>
                <Form.Item
                  label='详细地址'
                  name='username'
                  rules={[{required: true, message: 'Please input your username!'}]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12} offset={12} pull={12}>
                <Form.Item
                  label='商圈'
                  name='password'
                  rules={[{required: true, message: 'Please input your password!'}]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={8} offset={16}>
                <Form.Item>
                  <Space style={{width: '100%', justifyContent: 'flex-end'}}>
                    <Button>取消</Button>
                    <Button type='primary' htmlType='submit'>
                      确定
                    </Button>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Space>
      </div>
    </div>
  )
}
