import {useCallback, useRef, useState} from 'react'
import {$api} from '@/store/service'
import {OrphanRequestOptions} from '@redux-model/web/core/utils/types'

/**
 * 自定义分页 hook
 */
export function usePagination<L = any>() {
  const [isReady, setIsReady] = useState(false)
  const [loading, setLoading] = useState(0)
  const [finish, setFinish] = useState(false)
  const pageNum = useRef(0)
  const [list, setList] = useState<L[]>([])
  const [total, setTotal] = useState<number>(0)
  const tempPageSize = 10

  const searchNext = useCallback(function ({
    url,
    data: body,
    config,
    initialSearch = false,
    listSelector = data => data,
  }: SearchParams) {
    pageNum.current = initialSearch ? 1 : pageNum.current + 1
    if (initialSearch) setFinish(false)
    setLoading(state => state + 1)
    return $api
      .postAsync<any>({
        uri: url,
        body: {pageNum: pageNum.current, pageSize: tempPageSize, pageable: true, ...body},
        ...config,
      })
      .then(({response: {data}}) => {
        const selectList = listSelector(data)
        const pageSize = body?.pageSize || tempPageSize
        if (selectList.length < pageSize) setFinish(true)
        if (initialSearch) setList(selectList)
        else setList(state => [...state, ...selectList])
        return data
      })
      .finally(() => {
        setIsReady(true)
        setTimeout(function () {
          setLoading(state => Math.max(0, state - 1))
        }, 300)
      })
  },
  [])

  const searchNextByModel = useCallback(function (
    fn,
    {
      data: body,
      initialSearch = false,
      listSelector = (data: any) => data,
    }: Pick<SearchParams, 'data' | 'initialSearch' | 'listSelector'>
  ) {
    pageNum.current = initialSearch ? 1 : pageNum.current + 1
    if (initialSearch) setFinish(false)
    setLoading(state => state + 1)
    return fn({pageNum: pageNum.current, pageSize: tempPageSize, pageable: true, ...body})
      .then(({response: {data, total: t}}) => {
        setTotal(t)
        const selectList = listSelector(data)
        const pageSize = body?.pageSize || tempPageSize
        if (selectList.length < pageSize) setFinish(true)
        if (initialSearch) setList(selectList)
        else setList(state => [...state, ...selectList])
        return data
      })
      .finally(() => {
        setIsReady(true)
        setTimeout(function () {
          setLoading(state => Math.max(0, state - 1))
        }, 300)
      })
  },
  [])

  return {
    searchNext,
    searchNextByModel,
    loading: loading > 0,
    list,
    finish,
    isReady,
    total,
    setList,
  }
}

interface SearchParams {
  url: string
  data?: any
  config?: OrphanRequestOptions
  initialSearch?: boolean
  listSelector?: (data: any) => any
}
