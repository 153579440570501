import React, {useState, useEffect, useImperativeHandle, useCallback} from 'react'
import {CloseCircleFilled} from '@ant-design/icons'
import styles from './index.module.less'
import {
  Modal,
  Button,
  Select,
  Form,
  DatePicker,
  Row,
  Col,
  Input,
  Typography,
  Space,
  Switch,
  InputNumber,
  Table,
  FormInstance,
  Radio,
  message,
} from 'antd'
import {PlusOutlined} from '@ant-design/icons'
import {useCodeArraySync} from '@/store/models/Common'
import moment from 'moment'
import {contractModel} from '@/store/models/Contract'
import {Model} from '@redux-model/web'
import {add} from '@/utils/amount'
import {propertyModel} from '@/store/models/Property'

const {Option} = Select
const {TextArea} = Input
const {Text} = Typography

interface PropertyCheckOutProps {
  onSuccess?: () => unknown
}

interface ContractBillDTO extends ContractCheckOutDetailDTO {
  _key: string
  checkOutRemark: string
  _billBegin: moment.Moment[]
}

interface FormType extends ContractCheckOutDTO {
  debtBillList: ContractBillDTO[]
  refundBillList: ContractBillDTO[]
  violationBillList: ContractBillDTO[]
}

// 临时数据的ID
const genTempId = () => `_temp_${Math.random()}`
// 列表数据的ID
const getListId = () => `${Math.random()}`
// 判断是否是临时ID
const isTempId = (id: string) => id.startsWith('_temp_')
// 格式化Moment类型
const getBillData = (value: Pick<ContractBillVO, 'billBegin' | 'billEnd'>) =>
  value.billBegin && value.billEnd ? [moment(value.billBegin), moment(value.billEnd)] : undefined

// 统计总额
const checkoutTotal = (dto: ContractBillDTO[] = [], field = 'checkOutAmt') =>
  dto.reduce((prev, item) => add(prev, item?.[field]), 0)
// 统计扣款总额
const checkoutCutTotal = (f: FormInstance<any>) =>
  checkoutTotal(f.getFieldValue('debtBillList')?.filter(value => !value.free))

// 统计退款总额
const checkoutRefundTotal = (f: FormInstance<any>) => checkoutTotal(f.getFieldValue('refundBillList'))

function billMap(billList: ContractBillVO[], isTemp = false) {
  return (
    billList?.map(item => ({
      _key: isTemp ? genTempId() : getListId(),
      billAmtTotal: item.billAmtTotal,
      billBegin: item.billBegin,
      billCategory: item.billCategory,
      billCategoryName: item.billCategoryName,
      billEnd: item.billEnd,
      billId: item.billId,
      billTypeCode: item.billTypeCode,
      billTypeName: item.billTypeName,
      checkOutAmt: item.checkOutAmt,
      checkOutAmtOrigin: item.checkOutAmtOrigin,
      checkOutRemark: item.checkOutRemark, //注释
      free: false, // 优惠免收
      _billBegin: getBillData({billBegin: item.billBegin, billEnd: item.billEnd}),
    })) || []
  )
}

function checkTypeByDate(d1: string, d2: string) {
  if (moment(d1).add(30, 'day').isBefore(moment(d2))) {
    return '1'
  } else if (moment(d1).isBefore(moment(d2))) {
    return '2'
  } else if (moment(d1).isSame(d2, 'month') && moment(d1).isSame(d2, 'day')) {
    return '3'
  } else return '4'
}

// 前端根据规则判断的退房 转换成后台对应的退房
const checkOutReasonCode = {'1': '3', '2': '2', '3': '1', '4': '3'}

function _PropertyCheckOut({onSuccess}: PropertyCheckOutProps, ref) {
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)

  const [step, setStep] = useState(0)

  //提示
  const [tipVisible, setTipVisible] = useState(false)
  const [show, setShow] = useState('')
  const [checkValue, setCheckValue] = useState({} as {contractId: string; contractEnd?: string; condoProject: boolean})
  const contractCheckOutReasonArr = useCodeArraySync('contract.checkOutReason')

  // 退房类型
  const [rForm] = Form.useForm()
  const [chooseVisible, setChooseVisible] = useState(false)

  // 提前退房时的房屋租金扣款
  // const [aheadIds, setAheadIds] = useState<string[]>([])
  // 违约退房时的违约金列表
  const [violationIds, setViolationIds] = useState<string[]>([])
  // 集中式项目欠款列表
  const [debtIds, setDebtIds] = useState<string[]>([])

  // 结账详情 1：违约 2：提前 3：正常 4：延迟
  const checkOutDetail = useCallback(
    (date: string, type?: '1' | '2' | '3' | '4') => {
      contractModel
        .contractCheckOutDetail({
          contractId: checkValue.contractId,
          billEndDate: date,
          checkOutReason: checkOutReasonCode[type],
          fetchMeterWallet: checkValue.condoProject,
        })
        .then(({response: {data}}) => {
          // console.log('data------------', data)
          //选取ContractBillDTO字段显示。
          const violationBillList = billMap(data?.violationBillList, true)
          const delayBillList = billMap(data?.delayBillList, true)
          // const aheadBillList = billMap(data?.aheadBillList, true)
          // setAheadIds(data?.aheadBillList?.map(item => item.billId))
          setViolationIds(data?.violationBillList?.map(item => item.billId))

          const debtBillList: ContractBillDTO[] = billMap(data?.debtBillList)
          const refundBillList: ContractBillDTO[] = billMap(data?.refundBillList)

          const otherBillList = [
            ...debtBillList,
            ...(type === '1' ? violationBillList : []),
            // ...(type === '2' ? aheadBillList : []),
            ...(type === '4' ? delayBillList : []),
          ]
          setDebtIds(otherBillList.map(item => item.billId))

          // 有type说明是集中式项目的
          form.setFieldsValue({
            contractId: checkValue.contractId,
            debtBillList: type ? otherBillList : debtBillList,
            refundBillList,
            violationBillList,
          } as FormType)
        })
    },
    [checkValue.condoProject, checkValue.contractId, form]
  )

  const [lessorConfig, setLessorConfig] = useState<LessorConfigVO>({billConfigList: [], payConfigList: []})

  // useEffect(() => {
  //   lessorModel.lessorConfigContractListSelected().then(({response: {data}}) => setLessorConfig(data))
  // }, [])

  useEffect(() => {
    let current = moment()
    // 初始化form 预计可入住时间 | 财务截止日期
    form.setFieldsValue({rentableTime: moment(), billEndDate: current})

    if (checkValue.contractId && show !== 'checkout') {
      if (checkValue.condoProject) {
        const contractEnd = checkValue.contractEnd

        // 8点之后+1天
        const limit = moment().set('hour', 20).set('minute', 0).set('second', 0)
        if (current.isSame(limit, 'month') && current.isSame(limit, 'day') && current.isAfter(limit)) {
          current = moment().add(1, 'day')
        }
        form.setFieldsValue({billEndDate: current})

        const type = checkTypeByDate(current.format('YYYY-MM-DD'), contractEnd)
        if (type === '2' && show === 'all') {
          rForm.setFieldsValue({date: current.format('YYYY-MM-DD')})
          setChooseVisible(true)
        } else {
          checkOutDetail(current.format('YYYY-MM-DD'), type)
          // 1：违约 3：到期 4：延迟
          form.setFieldsValue({checkOutReason: {'1': '4', '3': '1', '4': '5'}[type]})
        }
      } else {
        checkOutDetail(moment().format('YYYY-MM-DD'))
      }
    }
  }, [checkOutDetail, checkValue, form, rForm, show])

  useImperativeHandle(ref, () => ({
    onDisplay: () => {
      setVisible(true)
    },
    onHidden: () => {
      // 清空表单数据
      form.resetFields()
      // 清空显示内容
      setShow('')
      // 隐藏弹窗
      setVisible(false)
      // 清空contractId
      setCheckValue({contractId: null, contractEnd: null, condoProject: false})
    },
    // 退房 结账 退房&结账
    onShow: (type: 'checkout' | 'settleAccounts' | 'all') => {
      setShow(type)
      if (['checkout', 'settleAccounts'].includes(type)) {
        setStep(3)
      }
    },
    // 设置contractId
    onSetValue: ({
      communityCode,
      ...args
    }: {
      contractId: string
      condoProject: boolean
      contractEnd?: string
      communityCode?: string
    }) => {
      setCheckValue(args)
      propertyModel.projectConfig({condoId: communityCode}).then(({response: {data}}) => setLessorConfig(data))
    },
  }))

  /**
   * 租约结账明细查询
   */
  async function onDatePickerChange(date) {
    if (date) {
      /**
       * 项目配置的集中式公寓
       * // 1.大于30天 => 违约退房
       * // 小于30天 => 弹窗选择 => 1.违约退房 2.提前退房
       * // 正常退房
       * // 4.超过租期 => 延迟退房
       */
      if (checkValue.condoProject) {
        const contractEnd = checkValue.contractEnd
        const type = checkTypeByDate(date, contractEnd)
        if (type === '2') {
          rForm.setFieldsValue({date})
          setChooseVisible(true)
        } else checkOutDetail(date.format('YYYY-MM-DD'), type)
      } else {
        checkOutDetail(date.format('YYYY-MM-DD'))
      }
    }
  }

  const loading = Model.isLoading(
    contractModel.contractCheckout.useLoading(),
    contractModel.contractCheckoutThrowALease.useLoading()
  )

  function contractCheckoutParams() {
    const {billEndDate} = form.getFieldsValue(true)
    const store: FormType = form.getFieldsValue(true)
    store.cutAmt = checkoutTotal(form.getFieldValue('debtBillList').filter(({_key}) => isTempId(_key)))
    store.debtAmt = checkoutTotal(form.getFieldValue('debtBillList').filter(({_key, free}) => !isTempId(_key) && !free))
    store.refundAmt = checkoutRefundTotal(form)
    const cutTotal = checkoutCutTotal(form)
    store.cutBillList = store.debtBillList.filter(({_key}) => isTempId(_key))
    store.debtBillList = store.debtBillList.filter(({_key}) => !isTempId(_key))
    store.totalAmt = add(store.refundAmt, -cutTotal)
    return {...store, billEndDate: billEndDate.format('YYYY-MM-DD')} as ContractCheckOutDTO
  }

  /**
   * 结账
   */
  function contractSettleAccounts() {
    const params = contractCheckoutParams()
    contractModel.contractCheckout(params).then(() => {
      onSuccess && onSuccess()
      setTipVisible(false)
    })
  }

  /**
   * 退房&结账操作
   */
  function contractCheckOut() {
    if (show === 'checkout') {
      const {rentableTime, checkOutReason, checkOutRemark} = form.getFieldsValue(true)
      contractModel
        .contractCheckoutThrowALease({
          contractId: checkValue.contractId,
          rentableTime: rentableTime.format('YYYY-MM-DD'),
          checkOutReason: checkOutReason,
          checkOutRemark: checkOutReason === '3' ? checkOutRemark : '',
        })
        .then(() => {
          onSuccess && onSuccess()
        })
    } else {
      const totalAmt = add(checkoutRefundTotal(form), -checkoutCutTotal(form))
      if (totalAmt !== 0) {
        setTipVisible(true)
      } else {
        if (show === 'all') {
          const {rentableTime, checkOutReason, checkOutRemark} = form.getFieldsValue(true)
          contractModel
            .contractCheckoutThrowALease({
              contractId: checkValue.contractId,
              rentableTime: rentableTime.format('YYYY-MM-DD'),
              checkOutReason: checkOutReason,
              checkOutRemark: checkOutReason === '3' ? checkOutRemark : '',
            })
            .then(() => {
              contractSettleAccounts()
            })
        } else if (show === 'settleAccounts') {
          contractSettleAccounts()
        }
      }
    }
  }

  //确认退房
  function confirmCheckOut() {
    if (show === 'all') {
      const {
        rentableTime,
        checkOutReason,
        checkOutRemark,
        billEndDate,
        violationBillList,
        debtBillList,
        rfdRemark,
      } = form.getFieldsValue(true)
      // 如果是违约退房并且修改了违约金 => 需要申请
      if (checkValue.condoProject) {
        // console.log('----------------------1')
        const type = checkTypeByDate(billEndDate, checkValue.contractEnd)
        if (
          (type === '1' || checkOutReason === '4') &&
          violationBillList.some(
            item => debtBillList.find(value => value.billId === item.billId)?.checkOutAmt !== item.checkOutAmt
          )
        ) {
          // 找出违约金 && 看看是否修改了
          // 提交申请
          const params = contractCheckoutParams()
          contractModel
            .contractAuditSubmit({
              auditType: '3',
              contractCheckOutObject: params,
              contractId: checkValue.contractId,
              contractThrowObject: {
                checkOutReason,
                checkOutRemark,
                rentableTime,
                contractId: checkValue.contractId,
              },
              createdReason: rfdRemark,
              refundAmt: 0,
              reserveCancelObject: null,
            } as ContractAuditSubmitDTO)
            .then(() => {
              setTipVisible(false)
              onSuccess && onSuccess()
              message.success('违约退房申请已提交！')
            })
        } else {
          contractModel
            .contractCheckoutThrowALease({
              contractId: checkValue.contractId,
              rentableTime: rentableTime.format('YYYY-MM-DD'),
              checkOutReason: checkOutReason,
              checkOutRemark: checkOutReason === '3' ? checkOutRemark : '',
            })
            .then(() => {
              contractSettleAccounts()
            })
        }
      } else {
        contractModel
          .contractCheckoutThrowALease({
            contractId: checkValue.contractId,
            rentableTime: rentableTime.format('YYYY-MM-DD'),
            checkOutReason: checkOutReason,
            checkOutRemark: checkOutReason === '3' ? checkOutRemark : '',
          })
          .then(() => {
            contractSettleAccounts()
          })
      }
    } else {
      contractSettleAccounts()
    }
  }

  function addRow(listName: keyof Pick<FormType, 'debtBillList' | 'refundBillList'>) {
    const list = form.getFieldValue(listName) ?? []
    form.setFieldsValue({
      [listName]: [...list, {_key: genTempId()} as ContractBillDTO],
    })
  }

  return (
    <>
      <Modal
        width={900}
        centered
        title={'退房'}
        visible={visible}
        footer={null}
        onCancel={() => ref.current.onHidden()}
        afterClose={() => {
          setStep(0)
          form.resetFields()
        }}
      >
        <Form form={form} onFinish={contractCheckOut} initialValues={{debtBillList: [], refundBillList: []}}>
          {['checkout', 'all'].includes(show) && (
            <Form.Item shouldUpdate style={{marginBottom: 0}}>
              {() => (
                <section className={styles.checkOut}>
                  <Row>
                    <Col>
                      <Form.Item
                        name={'checkOutReason'}
                        label={'退房原因'}
                        rules={[{required: true, message: '请选择退房原因'}]}
                      >
                        <Select
                          allowClear
                          placeholder={'请选择'}
                          style={{width: '153px'}}
                          disabled={checkValue.condoProject && show === 'all'}
                        >
                          {contractCheckOutReasonArr.map(item => (
                            <Option value={item.id} key={item.id}>
                              {item.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col offset={1}>
                      <Form.Item
                        name={'rentableTime'}
                        label={'预计可入住时间'}
                        rules={[{required: true, message: '请选择预计可入住时间'}]}
                      >
                        <DatePicker style={{width: '224px'}} disabled={checkValue.condoProject && show === 'all'} />
                      </Form.Item>
                    </Col>
                  </Row>
                  {form.getFieldValue('checkOutReason') === '3' && (
                    <Form.Item
                      name={'checkOutRemark'}
                      label={'备注'}
                      rules={[{required: true, message: '请输入备注信息'}]}
                    >
                      <TextArea placeholder={'请输入备注信息'} style={{height: '95px'}} />
                    </Form.Item>
                  )}
                </section>
              )}
            </Form.Item>
          )}
          {['settleAccounts', 'all'].includes(show) && (
            <Form.Item
              name={'billEndDate'}
              label={'实际退房时间'}
              rules={[{required: true, message: '请选择实际退房时间'}]}
              style={{marginTop: show === 'settleAccounts' ? '20px' : 0}}
            >
              <DatePicker
                onChange={onDatePickerChange}
                style={{width: '224px'}}
                disabled={checkValue.condoProject && show === 'all'}
              />
            </Form.Item>
          )}

          {['settleAccounts', 'all'].includes(show) && (
            <>
              {step >= 1 && (
                <>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, nextValues) => prevValues.debtBillList !== nextValues.debtBillList}
                  >
                    {f => (
                      <Table
                        className={styles.table}
                        rowKey={'_key'}
                        dataSource={f.getFieldValue('debtBillList') as FormType['debtBillList']}
                        columns={[
                          {
                            title: '扣款费用类型',
                            dataIndex: 'billTypeCode',
                            width: 150,
                            render: (text, record, index) =>
                              isTempId(record._key) ? (
                                <Form.Item
                                  name={['debtBillList', index, 'billTypeCode']}
                                  style={{marginBottom: 0}}
                                  rules={[{required: true, message: '请选择扣款项目'}]}
                                >
                                  <Select
                                    placeholder={'请选择'}
                                    allowClear
                                    onChange={(value, option: any) => {
                                      const data: LessorConfigVO['billConfigList'][number] = option?.data
                                      f.setFields([
                                        {name: ['debtBillList', index, 'billTypeName'], value: data?.billTypeName},
                                        {name: ['debtBillList', index, 'billCategory'], value: data?.billCategory},
                                        {
                                          name: ['debtBillList', index, 'billCategoryName'],
                                          value: data?.billCategoryName,
                                        },
                                      ])
                                    }}
                                    disabled={debtIds.includes(record.billId)}
                                  >
                                    {lessorConfig?.billConfigList?.map(value => (
                                      <Select.Option key={value.billTypeCode} value={value.billTypeCode} data={value}>
                                        {value.billTypeName}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              ) : (
                                record.billTypeName
                              ),
                          },
                          {
                            title: '费用周期',
                            width: 280,
                            render: (text, record, index) =>
                              isTempId(record._key) ? (
                                <Form.Item
                                  name={['debtBillList', index, '_billBegin']}
                                  style={{marginBottom: 0}}
                                  rules={[{required: true, message: '请选择费用周期'}]}
                                >
                                  <DatePicker.RangePicker
                                    onChange={(value, format) => {
                                      f.setFields([
                                        {name: ['debtBillList', index, 'billBegin'], value: format?.[0]},
                                        {name: ['debtBillList', index, 'billEnd'], value: format?.[1]},
                                      ])
                                    }}
                                    disabled={debtIds.includes(record.billId)}
                                  />
                                </Form.Item>
                              ) : record.billBegin && record.billEnd ? (
                                [record.billBegin, record.billEnd].join('至')
                              ) : (
                                '无账期'
                              ),
                          },
                          {
                            title: '合计（元）',
                            render: (_, record, index) =>
                              isTempId(record._key) ? (
                                <Form.Item
                                  name={['debtBillList', index, 'checkOutAmt']}
                                  style={{marginBottom: 0}}
                                  rules={[{required: true, message: '请输入扣款金额'}]}
                                >
                                  <InputNumber
                                    min={0}
                                    step={100}
                                    placeholder={'扣款金额'}
                                    precision={2}
                                    style={{border: 'none'}}
                                    onChange={value =>
                                      form.setFields([{name: ['debtBillList', index, 'billAmtTotal'], value: value}])
                                    }
                                  />
                                </Form.Item>
                              ) : (
                                record.checkOutAmt
                              ),
                          },
                          {
                            title: '注释',
                            dataIndex: 'checkOutRemark',
                            width: 150,
                            render: (text, record) => (isTempId(record._key) ? '/' : text),
                          },
                          {
                            title: '操作',
                            width: 150,
                            render: (_, record, index) =>
                              isTempId(record._key) ? (
                                <div className={styles.handle}>
                                  /{/* 提前退房 aheadIds?.includes(record.billId) || 违约退房  */}
                                  {violationIds.includes(record.billId) ? null : (
                                    <CloseCircleFilled
                                      className={styles.close}
                                      onClick={() => {
                                        const list: FormType['debtBillList'] = form.getFieldValue('debtBillList')
                                        form.setFields([
                                          {name: 'debtBillList', value: list.filter(value => value !== record)},
                                        ])
                                      }}
                                    />
                                  )}
                                </div>
                              ) : (
                                <Form.Item
                                  label={'优惠免收'}
                                  colon={false}
                                  name={['debtBillList', index, 'free']}
                                  style={{marginBottom: 0}}
                                  // 组件 -> form
                                  // normalize={value => (value ? '1' : '0')}
                                  // form -> 组件
                                  getValueProps={value => ({checked: value})}
                                >
                                  <Switch />
                                </Form.Item>
                              ),
                          },
                        ]}
                        pagination={false}
                      />
                    )}
                  </Form.Item>
                  <Row>
                    <Col flex={'auto'}>
                      <Form.Item label='扣除租客金额' shouldUpdate>
                        {() => `￥${checkoutCutTotal(form)}`}
                      </Form.Item>
                    </Col>
                    <Col>
                      <Button
                        type={'link'}
                        icon={<PlusOutlined />}
                        style={{paddingRight: 0}}
                        onClick={() => addRow('debtBillList')}
                      >
                        添加扣款项目
                      </Button>
                    </Col>
                  </Row>
                </>
              )}

              {step >= 2 && (
                <>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, nextValues) => prevValues.refundBillList !== nextValues.refundBillList}
                  >
                    {f => (
                      <Table
                        rowKey={'_key'}
                        dataSource={f.getFieldValue('refundBillList') as FormType['refundBillList']}
                        columns={[
                          {
                            title: '退款费用类型',
                            dataIndex: 'billTypeCode',
                            width: 150,
                            render: (text, record, index) =>
                              isTempId(record._key) ? (
                                <Form.Item
                                  name={['refundBillList', index, 'billTypeCode']}
                                  style={{marginBottom: 0}}
                                  rules={[{required: true, message: '请选择扣款项目'}]}
                                >
                                  <Select
                                    placeholder={'请选择'}
                                    allowClear
                                    onChange={(value, option: any) => {
                                      const data: LessorConfigVO['billConfigList'][number] = option?.data
                                      f.setFields([
                                        {name: ['refundBillList', index, 'billTypeName'], value: data?.billTypeName},
                                        {name: ['refundBillList', index, 'billCategory'], value: data?.billCategory},
                                        {
                                          name: ['refundBillList', index, 'billCategoryName'],
                                          value: data?.billCategoryName,
                                        },
                                      ])
                                    }}
                                  >
                                    {lessorConfig?.billConfigList?.map(value => (
                                      <Select.Option key={value.billTypeCode} value={value.billTypeCode} data={value}>
                                        {value.billTypeName}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              ) : (
                                record.billTypeName
                              ),
                          },
                          {
                            title: '费用周期',
                            width: 280,
                            render: (text, record, index) =>
                              isTempId(record._key) ? (
                                <Form.Item
                                  name={['refundBillList', index, '_billBegin']}
                                  style={{marginBottom: 0}}
                                  rules={[{required: true, message: '请选择费用周期'}]}
                                >
                                  <DatePicker.RangePicker
                                    onChange={(value, format) => {
                                      f.setFields([
                                        {name: ['refundBillList', index, 'billBegin'], value: format?.[0]},
                                        {name: ['refundBillList', index, 'billEnd'], value: format?.[1]},
                                      ])
                                    }}
                                  />
                                </Form.Item>
                              ) : record.billBegin && record.billEnd ? (
                                [record.billBegin, record.billEnd].join('至')
                              ) : (
                                '无账期'
                              ),
                          },
                          {
                            title: '已收（元）',
                            render: (_, record, index) =>
                              isTempId(record._key) ? (
                                <Form.Item
                                  name={['refundBillList', index, 'billAmtTotal']}
                                  style={{marginBottom: 0}}
                                  rules={[{required: true, message: '请输入已收金额'}]}
                                >
                                  <InputNumber
                                    min={0}
                                    step={100}
                                    placeholder={'已收金额'}
                                    precision={2}
                                    style={{border: 'none'}}
                                  />
                                </Form.Item>
                              ) : (
                                record.billAmtTotal
                              ),
                          },
                          {
                            title: '注释',
                            dataIndex: 'checkOutRemark',
                            width: 150,
                            render: (text, record) => (isTempId(record._key) ? '/' : text),
                          },
                          {
                            title: '退还（元）',
                            render: (_, record, index) => (
                              <Form.Item
                                name={['refundBillList', index, 'checkOutAmt']}
                                style={{marginBottom: 0}}
                                rules={[{required: true, message: '请输入退还金额'}]}
                              >
                                <InputNumber
                                  min={0}
                                  step={100}
                                  placeholder={'退还金额'}
                                  precision={2}
                                  style={{border: 'none'}}
                                  readOnly={checkValue.condoProject}
                                />
                              </Form.Item>
                            ),
                          },
                          {
                            title: '',
                            render: (_, record, index) =>
                              isTempId(record._key) && (
                                <div className={styles.handle}>
                                  <CloseCircleFilled
                                    className={styles.close}
                                    onClick={() => {
                                      const list: FormType['refundBillList'] = form.getFieldValue('refundBillList')
                                      form.setFields([
                                        {name: 'refundBillList', value: list.filter(value => value !== record)},
                                      ])
                                    }}
                                  />
                                </div>
                              ),
                          },
                        ]}
                        pagination={false}
                      />
                    )}
                  </Form.Item>

                  <Row>
                    <Col flex={'auto'}>
                      <Form.Item label='应退租客金额' shouldUpdate>
                        {() => `￥${checkoutRefundTotal(form)}`}
                      </Form.Item>
                    </Col>
                    {/* 集中式项目不能 添加退款项目 */}
                    {!checkValue.condoProject && (
                      <Col>
                        <Button
                          type={'link'}
                          icon={<PlusOutlined />}
                          style={{paddingRight: 0}}
                          onClick={() => addRow('refundBillList')}
                        >
                          添加退款项目
                        </Button>
                      </Col>
                    )}
                  </Row>
                </>
              )}

              {step >= 3 && (
                <>
                  <Row>
                    <Col span={14}>
                      <Form.Item label={'总计'} shouldUpdate>
                        {() => {
                          const refundAmt = checkoutRefundTotal(form)
                          const cutAmt = checkoutCutTotal(form)
                          const totalAmt = add(refundAmt, -cutAmt)
                          return `￥${totalAmt}${
                            totalAmt > 0 ? `（退还给租客${totalAmt}元）` : `（向租客收取${Math.abs(totalAmt)}元）`
                          }`
                        }}
                      </Form.Item>
                    </Col>
                    <Form.Item noStyle shouldUpdate>
                      {() => {
                        const refundAmt = checkoutRefundTotal(form)
                        const cutAmt = checkoutCutTotal(form)
                        const totalAmt = add(refundAmt, -cutAmt)

                        return totalAmt > 0 ? (
                          <>
                            <Col span={10}>
                              <Form.Item
                                name={'rfdPayWayCode'}
                                label={'退款途径'}
                                rules={[{required: true, message: '请选择退款途径'}]}
                              >
                                <Select
                                  allowClear
                                  placeholder={'请选择退款途径'}
                                  onChange={(value, option) =>
                                    form.setFieldsValue({rfdPayWayName: option?.['children']})
                                  }
                                >
                                  {lessorConfig?.payConfigList?.map(value => (
                                    <Select.Option value={value.payWay} key={value.payWay}>
                                      {value.payWayName}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Form.Item name={'rfdRemark'} label={'备注信息'}>
                                <Input.TextArea placeholder={'请输入退款备注'} />
                              </Form.Item>
                            </Col>
                          </>
                        ) : null
                      }}
                    </Form.Item>
                  </Row>
                </>
              )}
            </>
          )}

          <section className={styles.footer}>
            {[0, 1, 2].includes(step) ? (
              <Button key={0} type={'primary'} onClick={() => setStep(prevState => prevState + 1)}>
                下一步
              </Button>
            ) : (
              <Button key={1} type={'primary'} htmlType={'submit'} loading={loading}>
                {show === 'checkout' ? '确认退房' : '确认结账'}
              </Button>
            )}
          </section>
        </Form>
      </Modal>

      {/* 提示弹框 */}
      <Modal
        visible={tipVisible}
        footer={null}
        title={<div style={{textAlign: 'left'}}>提示</div>}
        onCancel={() => setTipVisible(false)}
        zIndex={9999}
        centered
      >
        <div>
          <div>
            <Text>
              {add(checkoutRefundTotal(form), -checkoutCutTotal(form)) < 0
                ? `应收款￥${add(
                    checkoutRefundTotal(form),
                    -checkoutCutTotal(form)
                  )}元，如已收款请去账单页面确认收款，继续结账将产生坏账。`
                : `总计需要退还给租客￥${add(
                    checkoutRefundTotal(form),
                    -checkoutCutTotal(form)
                  )}元，继续将生成一笔退房退款流水。`}
            </Text>
          </div>
          <div style={{textAlign: 'right', marginTop: '20px'}}>
            <Space>
              <Button onClick={() => setTipVisible(false)}>取消</Button>
              <Button type={'primary'} onClick={() => confirmCheckOut()} loading={loading}>
                确认
              </Button>
            </Space>
          </div>
        </div>
      </Modal>

      {/* 选择弹框 */}
      <Modal
        centered
        visible={chooseVisible}
        title={'请选择退房类型'}
        onCancel={() => setChooseVisible(false)}
        onOk={() => rForm.submit()}
        zIndex={9999}
        afterClose={() => rForm.resetFields()}
      >
        <Form
          form={rForm}
          initialValues={{type: '1'}}
          onFinish={() => {
            const formValue = rForm.getFieldsValue(true)
            checkOutDetail(formValue['date'], formValue['type'] === '1' ? '1' : '2')
            setChooseVisible(false)
            // 根据选择 映射退房原因
            form.setFieldsValue({checkOutReason: formValue['type'] === '1' ? '4' : '2'})
          }}
        >
          <Form.Item name={'type'} label={'退房类型'}>
            <Radio.Group>
              <Radio value={'1'}>违约退房</Radio>
              <Radio value={'2'}>提前退房</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
const PropertyCheckOut = React.forwardRef(_PropertyCheckOut)
export default PropertyCheckOut
