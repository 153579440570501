import {Model} from '@redux-model/web'
import {$api} from '@/store/service'

export interface BillModel {}

class Bill extends Model<BillModel> {
  protected initReducer(): BillModel {
    return {}
  }

  // 提交账单
  userLessorBillConfirm = $api.action((args: Partial<LessorBillConfirmDTO>) =>
    this.post('/user/lessor/bill/confirm').body(args)
  )

  // 查询房东账单详情  |  object:{billId:账单Id}
  userLessorBillInfo = $api.action((args: {billId: string}) =>
    this.post<ApiResponse<LessorBillVO>>('/user/lessor/bill/info').body(args)
  )

  // 房东最近账单
  userLessorBillLatest = $api.action(() => this.post<ApiResponse<LessorBillVO>>('/user/lessor/bill/latest'))

  // 房东账单列表
  userLessorBillList = $api.action((args: Partial<LessorBillListDTO>) =>
    this.post<ApiResponse<LessorBillVO[]>>('/user/lessor/bill/list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // 审核账单
  userLessorBillPending = $api.action((args: {billId: string}) => this.post('/user/lessor/bill/pending').body(args))

  // 账单的房源列表查询(当期)
  userLessorBillPropertyList = $api.action((args: Partial<LessorBillPropertyQueryDTO>) =>
    this.post<ApiResponse<LessorBillPropertyVO[]>>('/user/lessor/bill/property-list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // 账单的房源列表查询(与上期比较)
  userLessorBillPropertyComparisonList = $api.action((args: Partial<LessorBillPropertyQueryDTO>) =>
    this.post<ApiResponse<LessorBillPropertyVO[]>>('/user/lessor/bill/property-comparison-list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      comparisonType: 0,
      ...args,
    })
  )

  // 账单的房源列表查询(与上期比较)Count  |  object:{addNum:新增,removeNum:删除,total:总数}
  userLessorBillPropertyComparisonTotal = $api.action((args: Partial<LessorBillPropertyQueryDTO>) =>
    this.post<ApiResponse>('/user/lessor/bill/property-comparison-total').body({
      comparisonType: 0,
      ...args,
    })
  )

  // 账单的房源对应的`团队信息`  |  object:{billId:账单Id,comparison:bool}
  userLessorBillPropertyTeamList = $api.action((args: {billId: string; comparison: boolean}) =>
    this.post<ApiResponse<PairModel[]>>('/user/lessor/bill/property-team-list').body(args)
  )

  // 账单的房源对应的`区域信息`  |  object:{billId:账单Id,comparison:bool}
  userLessorBillPropertyDistrictList = $api.action((args: {billId: string; comparison: boolean}) =>
    this.post<ApiResponse<PairModel[]>>('/user/lessor/bill/property-district-list').body(args)
  )

  // 房东账单合计
  userLessorBillStatistics = $api.action((args: Partial<LessorBillListDTO>) =>
    this.post<ApiResponse<LessorBillStatisticsVO>>('/user/lessor/bill/statistics').body(args)
  )

  // 房东APP首页-账单及组织余额提示
  lessorHomeBillAndBalance = $api.action(() => this.post<ApiResponse>('/lessor/home/bill-and-balance'))
}

export const billModel = new Bill()
