import React, {useCallback, useEffect, useState} from 'react'
import {Button, Col, DatePicker, Form, InputNumber, message, Modal, Row, Space, Table} from 'antd'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import DeviceTabs from '@/pages/device/components/DeviceTabs'
import DeviceYunDing from '@/pages/device/components/DeviceYunDing'
import DeviceBoardItem from '@/pages/device/components/DeviceBoardItem'
import DeviceTableHeader from '@/pages/device/components/DeviceTableHeader'
import deviceStyle from '@/pages/device/styles/device.module.less'
import {deviceModel} from '@/store/models/Device'
import moment from 'moment'
import {useLocation} from 'react-router-dom'
import {useHistory} from 'react-router'

const {RangePicker} = DatePicker

const dateFormat = 'YYYY-MM-DD HH:mm:ss'
const begin = moment().startOf('month').format(dateFormat)
const end = moment().endOf('month').format(dateFormat)
const initRecordParams = {begin, end, pageNum: 1, pageSize: 10, pageable: true}
const initChargeParams = {startTime: begin, endTime: end, pageNum: 1, pageSize: 10, pageable: true}

export default function DeviceElectricDetail() {
  const history = useHistory()
  const location = useLocation<{propertyCode: string; lesseeName: string; lesseeMobile: string}>()
  const {propertyCode, lesseeName, lesseeMobile} = location.state

  const [elemeterId, setElemeterId] = useState('')
  const [tabKey, setTabKey] = useState('1')

  // 电表详情
  const [elemeterInfo, setElemeterInfo] = useState({} as DeviceYunDingElemeterInfoVO)

  // 解绑电表
  const [unBind, setUnBind] = useState(false)
  // 修改价格
  const [updatePrice, setUpdatePrice] = useState(false)
  // 更新
  const [isUpdate, setIsUpdate] = useState(false)

  // 用电记录
  const [form] = Form.useForm()
  const [recordParams, setRecordParams] = useState<DeviceRecordDTO>({...initRecordParams, uuid: elemeterId})
  const [recordCount, setRecordCount] = useState(0)
  const [recordList, setRecordList] = useState<YunDingElemeterHistoryVO[]>([])

  // 充值记录
  const [chargeParams, setChargeParams] = useState<ChargeListDTO>({...initChargeParams, deviceId: elemeterId})
  const [chargeCount, setChargeCount] = useState(0)
  const [chargeList, setChargeList] = useState<ElemeterChargeListVO[]>([])

  // todo: networking
  useEffect(
    function () {
      // 房源绑定的水表列表
      deviceModel.deviceList({propertyCode, type: '2'}).then(({response: {data}}) => {
        const deviceId = data[0]?.deviceId
        setElemeterId(deviceId)
        setRecordParams(prevState => ({...prevState, uuid: deviceId}))
        setChargeParams(prevState => ({...prevState, deviceId}))
      })
    },
    [propertyCode]
  )

  const queryElemeterInfo = useCallback(
    function () {
      if (elemeterId) {
        deviceModel.deviceYundingElemeterInfo({elemeterId}).then(({response: {data}}) => {
          setElemeterInfo(data)
          setIsUpdate(false)
        })
      }
    },
    [elemeterId]
  )

  useEffect(
    function () {
      queryElemeterInfo()
    },
    [queryElemeterInfo]
  )

  useEffect(
    function () {
      if (tabKey === '1') {
        if (recordParams.uuid) {
          deviceModel.deviceYundingElemeterRecord(recordParams).then(({response: {data, total}}) => {
            setRecordCount(total)
            setRecordList(data)
          })
        }
      } else {
        if (chargeParams.deviceId) {
          deviceModel.deviceElemeterChargeList(chargeParams).then(({response: {data, total}}) => {
            setChargeCount(total)
            setChargeList(data)
          })
        }
      }
    },
    [chargeParams, recordParams, tabKey]
  )

  // todo: action
  const unBindLoading = deviceModel.deviceYundingDeviceUnbind.useLoading()
  const updatePriceLoading = deviceModel.deviceYundingElemeterUpdatePrice.useLoading()
  const updateLoading = deviceModel.deviceYundingElemeterInfo.useLoading()

  // 解绑电表
  const deviceUnbind = async () => {
    await deviceModel.deviceYundingDeviceUnbind({uuid: elemeterId})
    message.success('解绑成功')
    history.goBack()
  }

  // 更新电价
  const elemeterUpdatePrice = async () => {
    const price = form.getFieldValue('price')
    await deviceModel.deviceYundingElemeterUpdatePrice({elemeterId, price})
    message.success('修改成功！')
    setUpdatePrice(false)
    queryElemeterInfo()
  }

  return (
    <>
      <Breadcrumb path={['首页', '智能设备', '电表']} />
      <DeviceTabs
        tabList={[
          {title: '用电记录', key: '1'},
          {title: '充值记录', key: '2'},
        ]}
        onChange={activeKey => {
          if (activeKey === '1' && activeKey !== tabKey) setRecordParams({...initRecordParams, uuid: elemeterId})
          if (activeKey === '2' && activeKey !== tabKey) setChargeParams({...initChargeParams, deviceId: elemeterId})
          setTabKey(activeKey)
        }}
      />
      <div className={deviceStyle.deviceSection}>
        <div className={deviceStyle.tx}>
          <DeviceYunDing />
        </div>
        <div style={{flex: 1}}>
          <Row>
            <span className={deviceStyle.title}>智能电表</span>
          </Row>
          <Row gutter={30} style={{margin: '12px -15px'}}>
            <Col>
              <DeviceBoardItem
                label={'电表状态'}
                value={elemeterInfo.onoffLine === 1 ? '在线' : '离线'}
                status={elemeterInfo.onoffLine === 1 ? 'success' : 'error'}
              />
            </Col>
            <Col>
              <DeviceBoardItem label={'电费价格'} value={`${elemeterInfo.homeEleprice ?? ''}元/度`} />
            </Col>
            <Col>
              <DeviceBoardItem label={'剩余电量'} value={`${elemeterInfo.restAmount ?? ''}kWh`} />
            </Col>
          </Row>
          <Row gutter={30}>
            <Col>
              <DeviceBoardItem label={'承租人'} value={lesseeName} />
            </Col>
            <Col>
              <DeviceBoardItem label={'手机号'} value={lesseeMobile} />
            </Col>
            <Col>
              <DeviceBoardItem label={'房源地址'} value={elemeterInfo.title} />
            </Col>
          </Row>
          <Row justify={'end'} style={{margin: '30px 0 0 0'}}>
            <Space>
              <Button type={'primary'} onClick={() => setUpdatePrice(true)}>
                修改价格
              </Button>
              <Button onClick={() => setUnBind(true)} loading={unBindLoading}>
                解绑电表
              </Button>
              <Button
                onClick={() => {
                  setIsUpdate(true)
                  queryElemeterInfo()
                }}
                loading={isUpdate && updateLoading}
              >
                更新
              </Button>
            </Space>
          </Row>
        </div>
      </div>
      <div className={deviceStyle.formSection}>
        <Form
          form={form}
          onFinish={formValues => {
            const {time} = formValues
            if (time) {
              if (tabKey === '1') {
                const [begin, end] = time.map(item => moment(item).format(dateFormat))
                setRecordParams(prevState => ({...prevState, begin, end}))
              } else {
                const [startTime, endTime] = time.map(item => moment(item).format(dateFormat))
                setChargeParams(prevState => ({...prevState, startTime, endTime}))
              }
            } else {
              if (tabKey === '1') {
                setRecordParams(prevState => ({...prevState, begin: initRecordParams.begin, end: initRecordParams.end}))
              } else
                setChargeParams(prevState => ({
                  ...prevState,
                  startTime: initChargeParams.startTime,
                  endTime: initChargeParams.endTime,
                }))
            }
          }}
        >
          <Row>
            <Col span={7}>
              <Form.Item name={'time'} label={'查询时间'}>
                <RangePicker allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'}>
                查询
              </Button>
              <Button
                htmlType={'reset'}
                onClick={() => {
                  form.resetFields()
                  if (tabKey === '1') {
                    setRecordParams({...initRecordParams, uuid: elemeterId})
                  } else setChargeParams({...initChargeParams, deviceId: elemeterId})
                }}
              >
                重置
              </Button>
            </Space>
          </Row>
        </Form>
      </div>
      <div className={deviceStyle.tableSection}>
        {tabKey === '1' ? (
          <>
            <DeviceTableHeader total={recordCount} />
            <Table
              rowKey={'time'}
              columns={[
                {title: '公摊用电', dataIndex: 'poolingAmount'},
                {title: '房源用电', dataIndex: 'consumeAmount'},
                {title: '时间', dataIndex: 'time', render: time => moment(time).format(dateFormat)},
              ]}
              dataSource={recordList}
              pagination={{
                total: recordCount,
                current: recordParams.pageNum,
                pageSize: recordParams.pageSize,
                position: ['bottomCenter'],
                showSizeChanger: true,
                hideOnSinglePage: false,
                onChange: (pageNum, pageSize) =>
                  setRecordParams(prevState => ({
                    ...prevState,
                    pageSize,
                    pageNum: prevState.pageSize === pageSize ? pageNum : 1,
                  })),
              }}
            />
          </>
        ) : (
          <>
            <DeviceTableHeader total={chargeCount} />
            <Table
              rowKey={'insertTime'}
              columns={[
                {title: '日期', dataIndex: 'insertTime'},
                {title: '姓名', dataIndex: 'lesseeName'},
                {title: '充值金额', dataIndex: 'fee'},
              ]}
              dataSource={chargeList}
              pagination={{
                total: chargeCount,
                current: chargeParams.pageNum,
                pageSize: chargeParams.pageSize,
                position: ['bottomCenter'],
                showSizeChanger: true,
                hideOnSinglePage: false,
                onChange: (pageNum, pageSize) =>
                  setChargeParams(prevState => ({
                    ...prevState,
                    pageSize,
                    pageNum: prevState.pageSize === pageSize ? pageNum : 1,
                  })),
              }}
            />
          </>
        )}
      </div>

      {/* 解绑 */}
      <Modal visible={unBind} centered title={'解绑设备'} onCancel={() => setUnBind(false)} onOk={deviceUnbind}>
        解绑后您将无法在牛油果房东获取该电表的相关信息，是否确认要将该电表与牛油果房东对应房源进行解绑
      </Modal>

      {/* 电费设置 */}
      <Modal visible={updatePrice} centered title={'电费设置'} footer={null}>
        <Form form={form} onFinish={elemeterUpdatePrice}>
          <Row>
            <Form.Item name={'price'}>
              <InputNumber min={0} placeholder={'请输入'} style={{width: '210px'}} />
            </Form.Item>
            <span style={{margin: '10px'}}>元/度</span>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button
                htmlType={'reset'}
                onClick={() => {
                  form.resetFields(['price'])
                  setUpdatePrice(false)
                }}
              >
                取消
              </Button>
              <Button type={'primary'} htmlType={'submit'} loading={updatePriceLoading}>
                确定
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>
    </>
  )
}
