import React, {useCallback, useEffect, useState} from 'react'
import {Button, Col, Form, Input, message, Modal, Rate, Row, Select, Space, Tag, Typography} from 'antd'
import {useHistory, useLocation} from 'react-router'
import {getImg, isVideo} from '@/utils/util'
import {stewardModel} from '@/store/models/Steward'
import styles from './index.module.less'
import {useCodeArraySync} from '@/store/models/Common'
import {userModel} from '@/store/models/User'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import {PlayCircleFilled} from '@ant-design/icons'
import {usePermission} from '@/ui/components/Permission'
import CleanImagePreview from '@/pages/steward/components/CleanImagePreview'
import CleanVideoPreview from '@/pages/steward/components/CleanVideoPreview'
// import {Feedback} from '@/ui/components/Feedback'

const {Option} = Select
const orderStatusType = {
  '1': '待沟通',
  '2': '已沟通',
  '3': '上门中',
  '4': '服务中',
  '5': '已完成',
  '6': '已取消',
  '7': '待支付',
  '99': '待评价',
}
const resultType = {'1': '已完成服务', '2': '无需服务', '3': '无法完成服务'}

export default function StewardServiceDetail() {
  const history = useHistory()
  const location = useLocation<{orderId: string}>()
  const orderId = location.state?.orderId
  // const feedbackRef = useRef(null)

  const [auth] = usePermission()

  // 取消服务单原因
  const cancelReasonList = useCodeArraySync('steward.cancelType')

  // 服务单详情
  const [detail, setDetail] = useState({} as StewardOrderQueryVO)

  // 选中的图片
  const [selectImg, setSelectImg] = useState<{imgUrl: string; visible: boolean}>({imgUrl: '', visible: false})

  // 取消服务单表单
  const [form] = Form.useForm()
  const [showForm, setShowForm] = useState(false)

  // 服务单评价
  const [steRemark, setSteRemark] = useState({remarkScore: '5'} as {
    show: boolean
    remarkScore: string
    remarkResult: string
  })

  // 用户id
  const userId = userModel.useData(data => data?.userInfo?.userId)
  const isLessor = detail?.orderMakerType !== 'lessee'
  const isOwner = detail?.lessorId === userId

  const queryStewardDetail = useCallback(
    function () {
      stewardModel.stewardDetail({orderId}).then(({response: {data}}) => {
        if (data) {
          setDetail(data)
        }
      })
    },
    [orderId]
  )

  useEffect(() => {
    queryStewardDetail()
  }, [queryStewardDetail])

  function isImage(url: string) {
    return [/.png$/, /.jpe?g$/].some(item => item.test(url))
  }

  const [warrantyVisible, setWarrantyVisible] = useState(false)
  const [warrantyForm] = Form.useForm<StewardOrderWarrantyVO & {index: number}>()

  return (
    <>
      <Breadcrumb
        path={['首页', '管家服务管理', '服务单列表', '服务单详情']}
        // right={
        //   <Button
        //     onClick={() => {
        //       feedbackRef.current.show()
        //       feedbackRef.current.getId(orderId)
        //     }}
        //   >
        //     投诉建议
        //   </Button>
        // }
      />
      <div className={styles.pageDetail}>
        <div className={styles.table}>
          {/** 服务单状态 */}
          <Row className={styles.rowHeader}>服务单状态</Row>
          <Row className={styles.rowNormal}>服务单状态：{orderStatusType[detail.orderStatus]}</Row>

          {/** 管家处理 */}
          {detail.orderStatus !== '1' && detail.orderStatus !== '6' && detail.orderStatus !== '7' && (
            <Row className={styles.rowHeader}>管家处理</Row>
          )}
          {/*已沟通*/}
          {detail.orderStatus === '2' && (
            <Row className={styles.rowNormal}>
              <Col span={8}>管家姓名：{detail.stewardName}</Col>
              <div className={styles.vertical} />
              <Col span={8}>管家手机号：{detail.stewardMobile}</Col>
              <div className={styles.vertical} />
              <Col span={7}>约定上门时间：{detail.timeAppoint}</Col>
            </Row>
          )}
          {/*上门中*/}
          {detail.orderStatus === '3' && (
            <Row className={styles.rowNormal}>
              <Col span={5}>管家姓名：{detail.stewardName}</Col>
              <div className={styles.vertical} />
              <Col span={6}>管家手机号：{detail.stewardMobile}</Col>
              <div className={styles.vertical} />
              <Col span={6}>约定上门时间：{detail.timeAppoint}</Col>
              <div className={styles.vertical} />
              <Col span={6}>出发时间：{detail.timeSetOff}</Col>
            </Row>
          )}
          {/*服务中*/}
          {detail.orderStatus === '4' && (
            <>
              <Row className={styles.rowNormal}>
                <Col span={12}>管家姓名：{detail.stewardName}</Col>
                <div className={styles.vertical} />
                <Col span={11}>管家手机号：{detail.stewardMobile}</Col>
              </Row>
              <Row className={styles.rowNormal}>
                <Col span={8}>约定上门时间：{detail.timeAppoint}</Col>
                <div className={styles.vertical} />
                <Col span={8}>出发时间：{detail.timeSetOff}</Col>
                <div className={styles.vertical} />
                <Col span={7}>到达时间：{detail.timeArrived}</Col>
              </Row>
            </>
          )}
          {/*已取消*/}
          {detail.orderStatus === '6' && (
            <>
              <Row className={styles.rowHeader}>取消原因</Row>
              <Row className={styles.rowNormal}>取消原因：{detail.cancelType}</Row>
              <Row className={styles.rowNormal}>取消描述：{detail.cancelReason}</Row>
            </>
          )}
          {/*已完成 && 待评价 && 待支付*/}
          {['5', '7', '99'].includes(detail.orderStatus) && (
            <>
              <Row className={styles.rowNormal}>
                <Col span={8}>管家姓名：{detail.stewardName}</Col>
                <div className={styles.vertical} />
                <Col span={8}>管家手机号：{detail.stewardMobile}</Col>
                <div className={styles.vertical} />
                <Col span={7}>是否为自费单：{detail.feeNeedPay === '1' ? '是' : '否'}</Col>
              </Row>
              <Row className={styles.rowNormal}>
                <Col span={8}>约定上门时间：{detail.timeAppoint}</Col>
                <div className={styles.vertical} />
                <Col span={8}>出发时间：{detail.timeSetOff}</Col>
                <div className={styles.vertical} />
                <Col span={7}>到达时间：{detail.timeArrived}</Col>
              </Row>
              <Row className={styles.rowNormal}>处理结果：{resultType[detail.handleResultType]}</Row>
              {/*<Row className={styles.rowNormal}>处理备注：{detail.handleResult}</Row>*/}
              {/*{!!detail.resultNameList && (*/}
              {/*  <Row className={styles.rowNormal}>处理内容：{detail.resultNameList.join('、')}</Row>*/}
              {/*)}*/}
              {!!detail.extra?.posterImgUrl && (
                <Row className={styles.rowImg}>
                  海报：
                  {[{imgId: detail.extra?.posterImgUrl, imgUrl: detail.extra?.posterImgUrl}]?.map(item => {
                    if (isImage(item.imgUrl)) {
                      return (
                        <div key={item.imgId} className={styles.img}>
                          <img
                            src={getImg(item.imgUrl)}
                            width={88}
                            height={88}
                            onClick={() => setSelectImg({imgUrl: item.imgUrl, visible: true})}
                            alt={'图片'}
                          />
                        </div>
                      )
                    } else if (isVideo(item.imgUrl)) {
                      return (
                        <div
                          key={item.imgId}
                          className={styles.img}
                          onClick={() => setSelectImg({imgUrl: item.imgUrl, visible: true})}
                        >
                          <video src={getImg(item.imgUrl)} width={88} height={88} controls={false} />
                          <PlayCircleFilled
                            style={{position: 'absolute', left: '38px', top: '38px', fontSize: '28px', color: 'white'}}
                          />
                        </div>
                      )
                    } else return <div>无</div>
                  })}
                </Row>
              )}

              <Row className={styles.rowImg}>
                处理前照片：
                {detail.imgListOfStewardFront?.length ? (
                  <>
                    <CleanImagePreview imgList={detail.imgListOfStewardFront?.filter(item => isImage(item.imgUrl))} />
                    <CleanVideoPreview videoList={detail.imgListOfStewardFront?.filter(item => isVideo(item.imgUrl))} />
                  </>
                ) : (
                  <div>无</div>
                )}
              </Row>
              <Row className={styles.rowImg}>
                处理后照片：
                {detail.imgListOfStewardBack?.length ? (
                  <>
                    <CleanImagePreview imgList={detail.imgListOfStewardBack?.filter(item => isImage(item.imgUrl))} />
                    <CleanVideoPreview videoList={detail.imgListOfStewardBack?.filter(item => isVideo(item.imgUrl))} />
                  </>
                ) : (
                  <div>无</div>
                )}
              </Row>
              {!!detail.imgListOfHigh?.length && (
                <Row className={styles.rowImg}>
                  高空作业凭证：
                  <CleanImagePreview imgList={detail.imgListOfHigh.filter(item => isImage(item.imgUrl))} />
                </Row>
              )}
              <Row className={styles.rowNormal}>
                <Col span={12}>耗材使用：{detail.itemListV2?.map(item => `${item.name}x${item.num}`)?.join('、')}</Col>
                <div className={styles.vertical} />
                <Col span={11}>总费用：{detail.feeStaff ? `${detail.feeStaff}元` : ''}</Col>
              </Row>
            </>
          )}

          {/** 评价信息 */}
          {detail?.remarkScore ? (
            <>
              <Row className={styles.rowHeader}>评价信息</Row>
              <Row className={styles.rowNormal}>
                评价星级：
                <Rate defaultValue={detail.remarkScore} disabled />
              </Row>
              <Row className={styles.rowNormal}>评价内容：{detail.remarkResult}</Row>
            </>
          ) : null}

          {/** 详细信息 */}
          <Row className={styles.rowHeader}>
            <Space>
              <span>详细信息</span>
              {detail.cleanTag === 1 && !!detail.originOrderId && (
                <Button type={'link'} onClick={() => history.push('/steward/detail', {orderId: detail.originOrderId})}>
                  <span style={{textDecoration: 'underline'}}>原单信息</span>
                </Button>
              )}
              {detail.repairTag === 1 && detail.orderMakerType === 'lessor' && (
                <Button
                  type={'link'}
                  onClick={async () => {
                    const {
                      response: {data},
                    } = await stewardModel.stewardOrderWarrantyDetail({orderId: detail.originOrderId})
                    warrantyForm.setFieldsValue({...data, index: 0})
                    setWarrantyVisible(true)
                  }}
                >
                  <span style={{textDecoration: 'underline'}}>保修信息</span>
                </Button>
              )}
            </Space>
          </Row>
          <Row className={styles.rowNormal}>
            <Col span={11}>
              <Space size={10}>
                <span>
                  服务项目：
                  {[detail.categoryFirName, detail.categorySecName, detail.categoryThiName]
                    .filter(item => item)
                    .join('-')}
                </span>
                {detail.cleanTag === 1 && !!detail.originOrderId && <Tag color={'blue'}>返工</Tag>}
                {detail.repairTag === 1 && <Tag color={'blue'}>返修</Tag>}
              </Space>
            </Col>
            <div className={styles.vertical} />
            <Col span={12}>房源地址：{detail.propertyAddr}</Col>
          </Row>
          <Row className={styles.rowNormal}>需求描述：{detail.orderDesc}</Row>
          <Row className={styles.rowImg}>
            照片：
            <CleanImagePreview
              imgList={detail.imgListOfDetail?.map(item => ({imgId: item.imgId, imgUrl: item.imgUrl}))}
            />
            {/*{detail.imgListOfDetail?.map(item => {*/}
            {/*  return (*/}
            {/*    <div key={item.imgId} className={styles.img}>*/}
            {/*      <img*/}
            {/*        src={getImg(item.imgUrl)}*/}
            {/*        width={88}*/}
            {/*        height={88}*/}
            {/*        onClick={() => setSelectImg({imgUrl: item.imgUrl, visible: true})}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  )*/}
            {/*})}*/}
          </Row>

          {/** 订单信息 */}
          <Row className={styles.rowHeader}>订单信息</Row>
          <Row className={styles.rowNormal}>
            <Col span={6}>订单编号：{detail.orderId}</Col>
            <div className={styles.vertical} />
            <Col span={6}>下单时间：{detail.timeMake}</Col>
            <div className={styles.vertical} />
            <Col span={6}>
              下单人：{detail.orderMakerName}({{lessee: '租客', lessor: '房东'}[detail.orderMakerType]})
            </Col>
            <div className={styles.vertical} />
            <Col>完成时间：{detail.timeFinished}</Col>
          </Row>
          <Row className={styles.rowNormal}>
            <Col span={6}>服务核验码：{detail.verifyCode}</Col>
            <div className={styles.vertical} />
          </Row>
        </div>

        <div className={styles.backView}>
          {['1', '2'].includes(detail.orderStatus) && auth('010005001') && (
            <Button type={'primary'} onClick={() => setShowForm(true)}>
              取消服务单
            </Button>
          )}
          {detail.orderStatus === '5' && detail.remarkTag === '0' && isLessor && isOwner && (
            <Button type={'primary'} onClick={() => setSteRemark(prevState => ({...prevState, show: true}))}>
              去评价
            </Button>
          )}
        </div>

        {/** 预览图片 */}
        <Modal
          centered
          visible={selectImg.visible}
          title={'图片/视频预览'}
          footer={null}
          onCancel={() => setSelectImg(state => ({...state, visible: false}))}
        >
          {isImage(selectImg.imgUrl) ? (
            <img src={getImg(selectImg.imgUrl)} width='100%' />
          ) : (
            <video controls src={getImg(selectImg.imgUrl)} width='100%' />
          )}
        </Modal>

        {/** 取消服务单 */}
        <Modal
          centered
          visible={showForm}
          title={'取消服务单'}
          onCancel={() => setShowForm(false)}
          confirmLoading={stewardModel.stewardCancel.useLoading()}
          onOk={async () => {
            await form.validateFields()
            setShowForm(false)
            const params = form.getFieldsValue(true) as {cancelType: string; cancelReason: string}
            await stewardModel.stewardCancel({
              orderId,
              ...params,
            })
            message.success('取消成功')
            history.goBack()
          }}
        >
          <Form form={form} colon={false} labelCol={{span: 4}}>
            <Form.Item name={'cancelType'} label={'取消原因'} rules={[{required: true, message: '请选择取消原因'}]}>
              <Select placeholder={'请选择取消原因'}>
                {cancelReasonList.map((item, index) => (
                  // <Option value={item.id} key={index}>
                  <Option value={item.label} key={item.label}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name={'cancelReason'} label={'具体原因'}>
              <Input.TextArea placeholder={'请输入具体原因'} />
            </Form.Item>
          </Form>
        </Modal>

        {/** 评价 */}
        <Modal
          centered
          visible={steRemark.show}
          title={'评价'}
          onCancel={() => setSteRemark(prevState => ({...prevState, show: false}))}
          confirmLoading={stewardModel.stewardRemark.useLoading()}
          onOk={async () => {
            if (+steRemark.remarkScore <= 3 && !steRemark.remarkResult) {
              message.warning('请输入具体原因！')
              return
            }
            setSteRemark(prevState => ({...prevState, show: false}))
            await stewardModel.stewardRemark({orderId, ...steRemark})
            message.success('评价成功！')
            queryStewardDetail()
          }}
        >
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Typography.Text>您对这单管家服务是否满意？</Typography.Text>
            <Rate
              defaultValue={5}
              onChange={value => setSteRemark(prevState => ({...prevState, remarkScore: `${value}`}))}
            />
            <Input.TextArea
              placeholder={'请输入具体原因'}
              style={{marginTop: 20, width: 360, height: 110}}
              onChange={({target: {value}}) => setSteRemark(prevState => ({...prevState, remarkResult: value}))}
            />
          </div>
        </Modal>
      </div>

      {/*<Feedback ref={feedbackRef} />*/}

      {/* 保修 */}
      <Modal
        visible={warrantyVisible}
        title={'保修信息'}
        centered
        footer={null}
        onCancel={() => setWarrantyVisible(false)}
      >
        <Form form={warrantyForm}>
          <Form.Item label={'原服务单号'}>
            <Button
              type={'link'}
              style={{padding: 0}}
              onClick={() => {
                setWarrantyVisible(false)
                history.push('/steward/detail', {orderId: warrantyForm.getFieldValue('orderId')})
              }}
            >
              <span style={{textDecoration: 'underline'}}>{warrantyForm.getFieldValue('orderId')}</span>
            </Button>
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {form1 => {
              const {categoryFirName, categorySecName, categoryThiName, propertyAddr} = form1.getFieldsValue([
                'categoryFirName',
                'categorySecName',
                'categoryThiName',
                'propertyAddr',
              ])
              const itemList = form1.getFieldValue('itemList') ?? []
              const index = form1.getFieldValue('index')
              const item = itemList[index] ?? {}
              return (
                <>
                  <Form.Item label={'处理内容'}>
                    <Select
                      style={{width: '210px'}}
                      defaultValue={item.chargeId}
                      onChange={(_, option) => warrantyForm.setFieldsValue({index: option['key']})}
                    >
                      {itemList.map((item, index) => (
                        <Select.Option key={index} value={item.chargeId}>
                          {item.chargeName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label={'保修服务'}>
                    <Input
                      readOnly
                      value={`${categoryFirName}-${categorySecName}-${categoryThiName}`}
                      bordered={false}
                    />
                  </Form.Item>
                  <Form.Item label={'保修期限'}>
                    <Input readOnly value={`${item.warrantyTime}天`} bordered={false} />
                  </Form.Item>
                  <Form.Item label={'到期时间'}>
                    <Input readOnly value={item.endDate} bordered={false} />
                  </Form.Item>
                  <Form.Item label={'保修地址'}>
                    <Input readOnly value={propertyAddr} bordered={false} />
                  </Form.Item>
                  <Form.Item label={'保修范围'}>
                    <Input.TextArea
                      readOnly
                      value={item.warrantyRange}
                      bordered={false}
                      autoSize={{minRows: 1, maxRows: 10}}
                    />
                  </Form.Item>
                  <Form.Item label={'免责条款'}>
                    <Input.TextArea
                      readOnly
                      value={item.warrantyExceptions}
                      bordered={false}
                      autoSize={{minRows: 1, maxRows: 10}}
                    />
                  </Form.Item>
                </>
              )
            }}
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
