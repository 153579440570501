import React, {DependencyList, EffectCallback, useCallback, useEffect} from 'react'
import {userModel} from '@/store/models/User'

type CodeType = string | string[]

export function hasPermission(code: CodeType, permissionCodeList?: string[]) {
  permissionCodeList = permissionCodeList ?? userModel.data.userInfo?.permissionCodeList
  // permissionCodeList = []
  return Array.isArray(code)
    ? permissionCodeList?.some(value => code.includes(value))
    : permissionCodeList?.includes(code)
}

export function Permission({
  code,
  fallback = null,
  children = null,
}: {
  code?: CodeType
  children?: any
  fallback?: React.ReactNode
}) {
  const permissionList = userModel.useData(data => data.userInfo?.permissionCodeList)
  if (hasPermission(code, permissionList)) return children

  return fallback
}

export function usePermission() {
  const permissionList = userModel.useData(data => data.userInfo?.permissionCodeList)
  const auth = useCallback((code: CodeType) => hasPermission(code as any, permissionList), [permissionList])
  return [auth]
}

/**
 * 功能和 useEffect 相同，另外需要传入权限 code
 * @example
 ```ts
 usePermissionEffect(
   '001001001',
   () => console.log('aaaaaaaaaaaa'),
   []
 )
 ```
 */
export function usePermissionEffect(code: CodeType, effect: EffectCallback, deps?: DependencyList) {
  useEffect(() => {
    if (hasPermission(code)) {
      return effect()
    }
  }, deps)
}

export function DenyView() {
  return <div style={{textAlign: 'center'}}>暂无权限</div>
}
