import {Effects, Model} from '@redux-model/web'
import {$api} from '@/store/service'
import {userModel} from '@/store/models/User'

export interface ConfigModel {
  settingList: LessorConfigOtherSettingVO[]
}

class Config extends Model<ConfigModel> {
  protected initReducer(): ConfigModel {
    return {
      settingList: [],
    }
  }

  protected effects(): Effects<ConfigModel> {
    return [
      userModel.queryUser.onSuccess(state => {
        this.lessorConfigOtherSettingList()
      }),
    ]
  }

  // 其他设置列表
  lessorConfigOtherSettingList = $api.action(() =>
    this.post<ApiResponse<LessorConfigOtherSettingVO[]>>('/lessor/config/other-setting/list').onSuccess(
      (state, action) => {
        state.settingList = action.response.data
      }
    )
  )

  // 配置其他设置
  lessorConfigOtherSettingSet = $api.action((args: {key: string; checked: boolean}) =>
    this.post<ApiResponse<LessorConfigOtherSettingVO[]>>('/lessor/config/other-setting/set')
      .body(args)
      .onSuccess((state, action) => {
        state.settingList = action.response.data
      })
  )

  useCheckedStatus = () => {
    const list = this.data.settingList
    return !!list.find(item => item.label === '自定义房源编号')?.checked
  }
}

export const configModel = new Config()
