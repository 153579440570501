import React, {useRef, useState} from 'react'
import {Button, Col, DatePicker, Form, Input, message, Modal, Row, Space, Typography} from 'antd'
import moment from 'moment'
import {warehouseModel} from '@/store/models/Warehouse'
import {Album} from '@/ui/components/Album'
import {getImg} from '@/utils/util'
import {baseUrl} from '@/common/config'
import {Mapview} from '@/ui/components/Map'
import MapLocation from '@/assets/icons/map_location.svg'
import styles from '@/pages/goods/GoodsManagement/index.module.less'

export default function GoodsAddStore(props: {showAddStore: boolean; onCancel: () => void; onFinish: () => void}) {
  const {showAddStore, onCancel, onFinish} = props

  // 地图相关
  const mapRef = useRef(null)
  const [showMap, setShowMap] = useState(false)

  const [addStoreForm] = Form.useForm()
  const [storeAttachList, setStoreAttachList] = useState([])

  const storeInsertLoading = warehouseModel.lessorWarehouseInsert.useLoading()

  return (
    <>
      <Modal
        visible={showAddStore}
        centered
        title={'新增仓库'}
        footer={null}
        onCancel={() => {
          addStoreForm.resetFields()
          onCancel?.()
        }}
      >
        <Form
          form={addStoreForm}
          labelCol={{span: 4}}
          labelAlign={'left'}
          onFinish={async () => {
            const formValues = addStoreForm.getFieldsValue(true)
            if (!formValues.lat || !formValues.lng) return message.error('请拖动地图选择所在地址')
            const inserValue = {
              ...formValues,
              attachList: storeAttachList.map(item => item.imgUrl),
              insertTime: formValues.insertTime
                ? moment(formValues.insertTime).format('YYYY-MM-DD HH:mm:ss')
                : moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            }
            await warehouseModel.lessorWarehouseInsert(inserValue)
            message.success('操作成功！')
            addStoreForm.resetFields()
            onFinish?.()
          }}
        >
          <Form.Item name={'name'} label={'仓库名称'} rules={[{required: true, message: '请输入仓库名称'}]}>
            <Input placeholder={'请输入'} />
          </Form.Item>
          <Form.Item name={'insertTime'} label={'配置日期'}>
            <DatePicker defaultValue={moment(new Date())} style={{width: '100%'}} />
          </Form.Item>
          <Form.Item name={'address'} label={'所在地址'} rules={[{required: true, message: '请选择所在地址'}]}>
            <Input readOnly placeholder={'点击选择地址'} onClick={() => setShowMap(true)} />
          </Form.Item>
          <Form.Item label={'仓库照片'}>
            <Album
              fileList={storeAttachList.map(item => ({...item, uid: item.uid, url: getImg(item.imgUrl)}))}
              action={baseUrl + '/lessor/warehouse/upload'}
              onDone={fileList => {
                setStoreAttachList(fileList.map(item => ({uid: item.uid, imgUrl: item.imgUrl})))
              }}
            />
          </Form.Item>
          <Form.Item name={'remark'} label={'备注'}>
            <Input.TextArea placeholder={'请输入'} />
          </Form.Item>
          <Row justify={'end'}>
            <Space>
              <Button
                onClick={() => {
                  addStoreForm.resetFields()
                  onCancel?.()
                }}
              >
                取消
              </Button>
              <Button type={'primary'} htmlType={'submit'} loading={storeInsertLoading}>
                保存提交
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>

      <Modal
        title={''}
        width={650}
        visible={showMap}
        centered={true}
        closable={false}
        onOk={() => {
          if (!addStoreForm.getFieldValue('lat') || !addStoreForm.getFieldValue('lng')) {
            addStoreForm.setFieldsValue({address: ''})
          }
          setShowMap(false)
        }}
        onCancel={() => {
          if (!addStoreForm.getFieldValue('lat') || !addStoreForm.getFieldValue('lng')) {
            addStoreForm.setFieldsValue({address: ''})
          }
          setShowMap(false)
        }}
        destroyOnClose
      >
        <div style={{width: 600}}>
          <Typography.Title level={4} style={{textAlign: 'center'}}>
            具体位置
          </Typography.Title>
          <Space size={19} direction={'vertical'} style={{padding: '18px 25px', width: '100%'}}>
            <div style={{position: 'relative'}}>
              <Mapview
                style={{height: 330}}
                ref={mapRef}
                center={undefined}
                onMapChange={event => {
                  addStoreForm.setFieldsValue({address: event.address, lat: event.point.lat, lng: event.point.lng})
                }}
              />
              <img src={MapLocation} alt={'icon'} className={styles.location} />
            </div>
            <Row>
              <Col span={16}>
                <Form form={addStoreForm}>
                  <Form.Item label='详细地址' name='address'>
                    <Input
                      placeholder={'请输入详细地址回车搜索'}
                      onPressEnter={() => mapRef.current?.panToByPath(addStoreForm.getFieldValue('address'))}
                    />
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Space>
        </div>
      </Modal>
    </>
  )
}
