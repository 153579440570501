import moment from 'moment'
import {useCallback, useEffect, useRef} from 'react'

const dailyPrefix = 'daily'

export function useDaily(callback: () => void, key = 'useDaily') {
  const func = useRef(callback)
  useEffect(() => {
    func.current = callback
  }, [callback])

  const handle = useCallback(async () => {
    const dayKey = `${dailyPrefix}/${key}`
    const time = await localStorage.getItem(dayKey)
    if (!time || !moment(time).isSame(new Date(), 'date')) {
      await localStorage.setItem(dayKey, moment().format('YYYY-MM-DD'))
      func.current()
    }
  }, [key])

  useEffect(() => {
    handle()
  }, [handle])
}
