import React from 'react'
import {Badge} from 'antd'
import {PresetStatusColorType} from 'antd/lib/_util/colors'

export default function DeviceBoardItem(props: {label: string; value: string; status?: PresetStatusColorType}) {
  const {label, value, status} = props
  return (
    <>
      {!!status && <Badge status={status} />}
      <span style={{color: 'rgba(0, 0, 0, 0.45)'}}>{label}：</span>
      <span>{value}</span>
    </>
  )
}
