import React, {useImperativeHandle, useState} from 'react'
import {Button, Col, Form, Input, message, Modal, Row, Select} from 'antd'
import {contractModel} from '@/store/models/Contract'
import {useCodeArraySync} from '@/store/models/Common'
import {propertyModel} from '@/store/models/Property'

export interface CancelContractMethod {
  show: (params: {
    contractId: string
    title: string
    lesseeName: string
    contractTime: string
    reservePrice: number
    condoProject?: boolean
    communityCode: string
  }) => Promise<any>
}

export interface CancelContractProps {
  // visible?: boolean
  onSuccess?: () => any
}

function _CancelContract(props: CancelContractProps, ref) {
  const [reseverForm] = Form.useForm()
  const [reseverVisible, setReseverVisible] = useState(false)

  const [condoProject, setCondoProject] = useState(false)

  useImperativeHandle<any, CancelContractMethod>(ref, () => ({
    show: async ({condoProject, communityCode, ...params}) => {
      reseverForm.setFieldsValue({cutAmt: 0, rfdPayWayCode: '', rfdPayWayName: '', rfdRemark: '', ...params})
      setReseverVisible(true)
      setCondoProject(condoProject)

      const {
        response: {data},
      } = await propertyModel.projectConfig({condoId: communityCode})
      setBillPayWay(data.payConfigList.map(item => ({id: item.payWay, label: item.payWayName})))
    },
  }))

  const [billPayWay, setBillPayWay] = useState<ReturnType<typeof useCodeArraySync>>([])
  // useEffect(function () {
  //   contractModel.lessorConfigContractListSelected().then(({response: {data}}) => {
  //     setBillPayWay(data.payConfigList.map(item => ({id: item.payWay, label: item.payWayName})))
  //   })
  // }, [])

  // 取消预定
  async function reserveCancel() {
    const formValues = reseverForm.getFieldsValue(true)
    // 项目管理的集中式房源 只有一种情况需要走审批流程 退还金额 > 扣款金额
    const contractId = formValues?.contractId
    const cutAmt = formValues?.cutAmt
    const rfdPayWayCode = formValues?.rfdPayWayCode
    const rfdPayWayName = formValues?.rfdPayWayName
    const rfdRemark = formValues?.rfdRemark
    const reservePrice = formValues?.reservePrice
    const reserveCancelObject = {contractId, cutAmt, rfdPayWayCode, rfdPayWayName, rfdRemark}
    // console.log('reservePrice', reservePrice)
    // console.log('cutAmt', cutAmt)
    if (condoProject && reservePrice !== 0 && reservePrice - cutAmt > 0) {
      await contractModel.contractAuditSubmit({
        auditType: '1',
        contractCheckOutObject: null,
        contractId,
        contractThrowObject: null,
        refundAmt: reservePrice - cutAmt,
        createdReason: rfdRemark,
        reserveCancelObject,
      } as ContractAuditSubmitDTO)
      message.success('撤销申请已提交')
      setReseverVisible(false)
    } else {
      await contractModel.contractReserveCancel(reserveCancelObject)
      message.success('撤销预定成功！')
      setReseverVisible(false)
      // search(form.getFieldsValue(), true)
      props.onSuccess && props.onSuccess()
    }
  }

  return (
    <Modal
      visible={reseverVisible}
      destroyOnClose
      afterClose={() => reseverForm.resetFields()}
      onCancel={() => setReseverVisible(false)}
      width={974}
      title={'撤销预定'}
      footer={null}
    >
      <Form form={reseverForm} labelCol={{span: 4}} onFinish={reserveCancel}>
        <Row gutter={20}>
          <Col span={24}>
            <Form.Item label={'房源地址'} labelCol={{span: 2}} shouldUpdate>
              {form1 => form1.getFieldValue('title')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={'租客姓名'} shouldUpdate>
              {form1 => form1.getFieldValue('lesseeName')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={'租期'} shouldUpdate>
              {form1 => form1.getFieldValue('contractTime')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={'退还金额'} shouldUpdate>
              {form1 => form1.getFieldValue('reservePrice')}
            </Form.Item>
          </Col>
          <Form.Item shouldUpdate noStyle>
            {form1 =>
              form1.getFieldValue('reservePrice') !== 0 && (
                <>
                  <Col span={12}>
                    <Form.Item label={'扣款金额'} name={'cutAmt'} rules={[{required: true}]}>
                      <Input placeholder={'请输入扣款金额'} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item labelCol={{span: null}} label={'总计（退还给租客）'} shouldUpdate>
                      {/*{'定金 - 扣款（cutAmt）'}*/}
                      {form1 => form1.getFieldValue('reservePrice') - form1.getFieldValue('cutAmt')}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={'退款途径'} name={'rfdPayWayCode'} rules={[{required: true}]}>
                      <Select
                        allowClear
                        placeholder={'请选择退款途径'}
                        onChange={(_, item: any) => reseverForm.setFieldsValue({rfdPayWayName: item.children})}
                      >
                        {billPayWay.map(item => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </>
              )
            }
          </Form.Item>
          <Col span={24}>
            <Form.Item name={'rfdRemark'} label={'备注'} labelCol={{span: 2}}>
              <Input.TextArea placeholder={'您可以在这里输入退款备注，便于后期管理和复查'} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item style={{textAlign: 'center'}}>
          <Button htmlType={'submit'} type={'primary'} loading={contractModel.contractReserveCancel.useLoading()}>
            确认撤销
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export const CancelContract = React.forwardRef(_CancelContract)
