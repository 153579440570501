import React, {useCallback, useImperativeHandle, useMemo, useRef, useState} from 'react'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Skeleton,
  Space,
  Table,
  Tabs,
  Typography,
} from 'antd'
import styles from '@/pages/contract/TenantRegister/index.module.less'
import {AddItem} from '@/ui/components/AddItem'
import {contractModel} from '@/store/models/Contract'
import EditModal from '@/pages/setting/components/EditModel'
import {propertyModel} from '@/store/models/Property'
import {Model} from '@redux-model/web'
import {baseUrl} from '@/common/config'
import {getImg} from '@/utils/util'
import {Album} from '@/ui/components/Album'
import {useCodeArraySync} from '@/store/models/Common'
import moment from 'moment'

export type EquipmentDTOModel = Omit<ContractEquipmentDTO, 'contractId'> & {
  equipmentAmt?: number
  templateId?: string
  templateName?: string
}

interface EquipmentModalProps {
  rentType: string
  contractId?: string
  onSuccess?: (config: EquipmentDTOModel) => void
  title?: string
}

export interface EquipmentRef {
  show: (item: EquipmentDTOModel | null) => Promise<void>
  hide: () => void
}

export const EquipmentModal = React.forwardRef<EquipmentRef, EquipmentModalProps>((props, ref) => {
  const [visible, setVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState<{path: any[]; value: string}>(null)
  const rentType = props.rentType
  const goods = useMemo(
    () => [
      {name: 'equipmentList', title: '房间物品'},
      ...(rentType === '2' ? [{name: 'equipmentPublicList', title: '公区物品'}] : []),
    ],
    [rentType]
  )

  const [currentGood, setCurrentGood] = useState(goods[0].name)
  const [equipmentConfigList, setEquipmentConfigList] = useState<ContractEquipmentConfigVO[]>([])
  const eqptPayway = useCodeArraySync('eqpt.payway')

  const editModalRef = useRef(null)
  const [form] = Form.useForm()

  const modalLoading = Model.isLoading(
    contractModel.contractEquipmentConfigList.useLoading(),
    contractModel.contractEquipmentConfigInfo.useLoading(),
    contractModel.contractTemplate.useLoading()
  )

  const handle: EquipmentRef = {
    async show(item: EquipmentDTOModel | null) {
      setVisible(true)

      let configList = equipmentConfigList
      if (!configList.length) {
        const {
          response: {data},
        } = await contractModel.contractEquipmentConfigList({rentType})
        configList = data
        setEquipmentConfigList(data)
      }

      const store: EquipmentDTOModel = {
        equipmentList: [],
        equipmentPublicList: [],
        meterList: [],
        meterReadingTime: null,
        equipmentAmt: 0,
        templateId: '',
        templateName: rentType === '2' ? '默认合租模板' : '默认整租模板',
      }
      if (item) {
        Object.assign(store, {
          ...item,
          ...(item.meterReadingTime
            ? {
                meterReadingTime: moment(item.meterReadingTime),
              }
            : {}),
        })
      } else {
        // 拿默认模板数据，主要是水电煤读数
        const [template, info] = await Promise.all([
          contractModel.contractTemplate(rentType),
          !!configList.length && contractModel.contractEquipmentConfigInfo(configList[0].templateId),
        ])
        Object.assign(store, template.response?.data, info?.response?.data)

        // 检查是否需要给水电煤赋默认值
        await checkMeter(store)
      }
      form.setFieldsValue(store)
    },
    hide() {
      setVisible(false)
    },
  }

  const checkMeter = useCallback(
    async (store: EquipmentDTOModel) => {
      let queryMeter = true
      if (props.contractId) {
        const {
          response: {data},
        } = await contractModel.contractEquipmentInfo({contractId: props.contractId})
        if (data) {
          queryMeter = false
        }
      }
      if (queryMeter) {
        const [W, E, G, H] = await Promise.all([
          propertyModel.propertyMeterRecordList({meterType: 'W'}),
          propertyModel.propertyMeterRecordList({meterType: 'E'}),
          propertyModel.propertyMeterRecordList({meterType: 'G'}),
          propertyModel.propertyMeterRecordList({meterType: 'H'}),
        ])
        const codeMap: {[key: string]: PropertyMeterRecordVO[]} = {
          '1': W.response?.data,
          '2': E.response?.data,
          '3': G.response?.data,
          '4': H.response?.data,
        }

        Object.assign(store, {
          meterList: store.meterList.map(item => {
            if (item.location === '1') {
              const numNow = codeMap[item.code]?.[0]?.numNow
              return {...item, value: numNow !== undefined ? `${numNow}` : item.value ?? '0'}
            }
            return item
          }),
        })
      }
    },
    [props.contractId]
  )

  useImperativeHandle(ref, () => handle)

  async function saveConfig() {
    const config = form.getFieldsValue(true) as EquipmentDTOModel
    // 校验水电煤读数
    // 要有全有，要无全无
    if (config.meterReadingTime) {
      for (const item of config.meterList) {
        if (item.remark || item.attach) {
          if (!item.value || !item.payWay) {
            return message.error(`${item.locationName}${item.name}的底数或支付方式不能为空`)
          }
        } else if (item.value && !item.payWay) {
          return message.error(`${item.locationName}${item.name}的支付方式不能为空`)
        } else if (item.payWay && !item.value) {
          return message.error(`${item.locationName}${item.name}的底数不能为空`)
        }
      }
    } else {
      if (config.meterList.some(item => item.value || item.payWay || item.attach || item.remark)) {
        return message.error('交割时间不能为空')
      }
    }

    if (!equipmentConfigList.length) {
      await new Promise(resolve => {
        Modal.confirm({
          title: '提示',
          content: '您当前还没有物业交割模版，是否将当前选择物品添加为默认模版？',
          onOk() {
            contractModel
              .contractEquipmentConfigSave({
                equipmentAmt: config.equipmentAmt,
                equipmentList: config.equipmentList,
                equipmentPublicList: config.equipmentPublicList,
                rentType,
                templateName: config.templateName,
                templateId: config.templateId,
              })
              .then(() => resolve())
          },
          onCancel: () => resolve(),
        })
      })
    }
    props.onSuccess?.(config)
    handle.hide()
  }

  return (
    <>
      <Modal
        keyboard={false}
        maskClosable={false}
        afterClose={() => form.resetFields()}
        visible={visible}
        title={props.title}
        width={831}
        onOk={saveConfig}
        onCancel={handle.hide}
        destroyOnClose
        confirmLoading={contractModel.contractEquipmentConfigSave.useLoading()}
      >
        <Skeleton loading={modalLoading} active>
          <Form>
            <Form.Item
              colon={false}
              label='如果您不确定房间物品以水电煤读数，可稍后在租约详情添加'
              required
              style={{marginBottom: 0, fontSize: 12}}
            >
              <span />
            </Form.Item>
          </Form>
          <Tabs>
            <Tabs.TabPane tab={'房间物品'} key='1'>
              <Form form={form}>
                <Space style={{width: '100%'}}>
                  <Form.Item label='模板名称' name={'templateId'} required style={{marginBottom: 0}}>
                    <Select
                      placeholder={`默认${rentType === '2' ? '合租' : '整租'}模板`}
                      style={{width: 216}}
                      onChange={(value: string) => {
                        contractModel.contractEquipmentConfigInfo(value).then(({response: {data}}) => {
                          form.setFieldsValue(data)
                        })
                      }}
                    >
                      {equipmentConfigList.map(item => (
                        <Select.Option key={item.templateId} value={item.templateId}>
                          {item.templateName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {/*<Popover
                    title='规则'
                    placement={'bottomLeft'}
                    content={
                      <div className={styles.tip}>
                        <p>1.提交模版时将此模版作为默认模版</p>
                        <p>2.提交模版时将此模版作为默认模版</p>
                      </div>
                    }
                  >
                    <InfoCircleOutlined color='#FFAF25' />
                  </Popover>*/}
                </Space>

                {goods.map(config => (
                  <Form.List name={config.name} key={config.name}>
                    {(fields, {add, remove}) => {
                      return (
                        <>
                          <Space style={{marginTop: 16}}>
                            <Typography.Title level={3} style={{marginBottom: 0}}>
                              {config.title}
                            </Typography.Title>
                            {/*<Button type={'link'} onClick={() => add({})}>
                            <PlusOutlined />
                            添加
                          </Button>*/}
                          </Space>
                          <Row gutter={22}>
                            {fields.map(field => (
                              <Col key={field.key} span={8} style={{marginTop: 16}}>
                                <Form.Item noStyle shouldUpdate>
                                  {form1 => (
                                    <AddItem
                                      closeable={false}
                                      onClick={() => {
                                        setCurrentGood(config.name)
                                        editModalRef.current.onDisplay(
                                          form.getFieldValue([config.name, field.name]),
                                          field.name
                                        )
                                      }}
                                      onChange={value => {
                                        form1.setFields([
                                          {
                                            name: [config.name, field.name, 'amt'],
                                            value,
                                          },
                                        ])
                                      }}
                                      value={form1.getFieldValue([config.name, field.name, 'amt'])}
                                      title={form1.getFieldValue([config.name, field.name, 'name'])}
                                      // onClose={() => remove(field.name)}
                                    />
                                  )}
                                </Form.Item>
                              </Col>
                            ))}
                          </Row>
                        </>
                      )
                    }}
                  </Form.List>
                ))}
              </Form>
            </Tabs.TabPane>
            <Tabs.TabPane tab={'水电煤读数'} key='2'>
              <Form form={form}>
                <Form.Item name={'meterReadingTime'} label='交割时间' required>
                  <DatePicker placeholder='交割时间' style={{width: 216}} />
                </Form.Item>
                <Form.List name={'meterList'}>
                  {fields => (
                    <Table
                      rowKey={(_, index) => index}
                      pagination={false}
                      dataSource={form.getFieldValue('meterList')}
                      columns={[
                        {
                          title: '仪表',
                          dataIndex: 'name',
                          key: 'name',
                        },
                        {
                          title: '位置',
                          dataIndex: 'locationName',
                          key: 'locationName',
                        },
                        {
                          title: '底数',
                          dataIndex: 'value',
                          key: 'value',
                          render: (value, record, index) => (
                            <Form.Item noStyle name={[index, 'value']}>
                              <InputNumber placeholder={'请输入底数'} style={{border: 'none', width: '100%'}} />
                            </Form.Item>
                          ),
                        },
                        {
                          title: '支付方式',
                          dataIndex: 'payWay',
                          key: 'payWay',
                          width: 120,
                          render: (value, record, index) => (
                            <Form.Item noStyle name={[index, 'payWay']}>
                              <Select className={styles.selectBorderLess}>
                                {eqptPayway.map(item => (
                                  <Select.Option key={item.id} value={item.id}>
                                    {item.label}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          ),
                        },
                        {
                          title: '附件',
                          dataIndex: 'attach',
                          key: 'attach',
                          render: (_, record, index) => (
                            <Form.Item noStyle shouldUpdate>
                              {form1 => {
                                const path = ['meterList', index, 'attach']
                                const value = form1.getFieldValue(path)
                                return value ? (
                                  <Button
                                    style={{paddingLeft: 0}}
                                    type={'link'}
                                    onClick={() => setPreviewImage({path, value})}
                                  >
                                    查看
                                  </Button>
                                ) : (
                                  <Album
                                    listType={'text'}
                                    limit={1}
                                    multiple={false}
                                    action={baseUrl + '/contract/equipment/img/upload'}
                                    onDone={fileList => {
                                      form1.setFields([
                                        {
                                          name: path,
                                          value: fileList[0].imgUrl,
                                        },
                                      ])
                                    }}
                                    showUploadList={false}
                                  >
                                    <Button style={{paddingLeft: 0}} type={'link'}>
                                      添加图片
                                    </Button>
                                  </Album>
                                )
                              }}
                            </Form.Item>
                          ),
                        },
                        {
                          title: '备注',
                          dataIndex: 'remark',
                          key: 'remark',
                          render: (value, record, index) => (
                            <Form.Item noStyle name={[index, 'remark']}>
                              <Input placeholder={'请输入备注'} style={{border: 'none'}} />
                            </Form.Item>
                          ),
                        },
                      ]}
                    />
                  )}
                </Form.List>
              </Form>
            </Tabs.TabPane>
          </Tabs>
        </Skeleton>
      </Modal>

      <EditModal
        ref={editModalRef}
        onSuccess={(item, index) => {
          form.setFields([
            {
              name: [currentGood, index],
              value: {amt: 1, ...item},
            },
          ])
        }}
      />

      <Modal
        visible={!!previewImage}
        cancelText={'关闭'}
        onCancel={() => setPreviewImage(null)}
        onOk={() => {
          form.setFields([
            {
              name: previewImage.path,
              value: '',
            },
          ])
          setPreviewImage(null)
        }}
        okText={'删除'}
      >
        {!!previewImage?.value && <img alt='预览' style={{width: '100%'}} src={getImg(previewImage.value)} />}
      </Modal>
    </>
  )
})

EquipmentModal.defaultProps = {
  title: '添加物业交割',
}
