import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useAppLocation} from '@/common/hooks/useRouter'
import {cleanModel} from '@/store/models/Clean'
import {BindTitleBar} from '@/pages/device/DeviceBind'
import {Button, Descriptions, Form, Input, Modal, Rate, Row, Space, Tag, Upload} from 'antd'
import {service} from '@/utils/render'
import {useCodeSync} from '@/store/models/Common'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import {formatRangeDate, getImg} from '@/utils/util'
import {useCleanPreview} from '@/pages/steward/hooks/useCleanOrderPreview'

export default function CleanServiceDetail() {
  const location = useAppLocation<'/clean/detail'>()
  const orderId = location.state?.orderId

  const orderStatus = useCodeSync('clean.order.status')
  const [detail, setDetail] = useState({} as CleanOrderVO)

  const [show] = useRemark()
  const cleanPreview = useCleanPreview()

  const queryDetail = useCallback((id: string) => {
    cleanModel.cleanOrderGet({orderId: id}).then(({response: {data}}) => setDetail(data))
  }, [])

  useEffect(() => {
    queryDetail(orderId)
  }, [orderId, queryDetail])

  return (
    <>
      <Breadcrumb path={['首页', '管家服务', '保洁服务单', '服务单详情']} />
      <div style={{backgroundColor: '#fff', padding: '25px'}}>
        {detail.remarkTag === 1 && (
          <Descriptions title={<BindTitleBar title={'评价信息'} />} column={2} style={{marginBottom: 30}}>
            <Descriptions.Item label='评价星级'>
              <Rate value={detail.remarkScore} style={{fontSize: 13}} />
            </Descriptions.Item>
            <Descriptions.Item label='评价内容'>{detail.remarkResult}</Descriptions.Item>
          </Descriptions>
        )}

        {detail.orderStatus === '5' && (
          <Descriptions title={<BindTitleBar title={'取消信息'} />} column={2} style={{marginBottom: 30}}>
            <Descriptions.Item label='取消人'>{detail.cancelOperator}</Descriptions.Item>
            <Descriptions.Item label='取消时间'>{detail.cancelTime}</Descriptions.Item>
            <Descriptions.Item label='取消原因'>{detail.cancelReason}</Descriptions.Item>
            <Descriptions.Item label='取消描述'>{detail.cancelNotes}</Descriptions.Item>
          </Descriptions>
        )}

        {!!detail.serverName && (
          <Descriptions title={<BindTitleBar title={'保洁信息'} />} column={2} style={{marginBottom: 30}}>
            <Descriptions.Item label='保洁员'>
              {detail.serverName}({detail.serverMobile})
            </Descriptions.Item>
            <Descriptions.Item label='约定上门时间'>
              {formatRangeDate(detail.appointTimeBegin, detail.appointTimeEnd)}
            </Descriptions.Item>

            {['4', '6'].includes(detail.orderStatus) && (
              <>
                {/*<Descriptions.Item label='保洁前后照片/视频'>
                  <Button
                    style={{padding: 0, height: 'auto'}}
                    type={'link'}
                    onClick={() => {
                      // todo: 去客户端查看
                    }}
                  >
                    查看详情
                  </Button>
                </Descriptions.Item>*/}
                <Descriptions.Item label='收费服务'>
                  {detail.payItemList?.map(value => value.chargeName).join('、')}
                </Descriptions.Item>
                <Descriptions.Item label='垃圾清运（包）'>{detail.garbagePkgNum}</Descriptions.Item>
                {!!detail.amtCheck && <Descriptions.Item label='总费用'>{`${detail.amtCheck}元`}</Descriptions.Item>}
                {!!detail.amtCheckNotes && (
                  <Descriptions.Item label='扣款备注'>{detail.amtCheckNotes}</Descriptions.Item>
                )}
                <Descriptions.Item label='保洁前后照片/视频'>
                  <Button
                    type={'link'}
                    style={{padding: 0, height: 'auto'}}
                    onClick={() => cleanPreview({payloadList: detail.payloadList})}
                  >
                    <span style={{textDecoration: 'underline'}}>查看详情</span>
                  </Button>
                </Descriptions.Item>
              </>
            )}
          </Descriptions>
        )}

        <Descriptions title={<BindTitleBar title={'订单信息'} />} column={2} style={{marginBottom: 30}}>
          <Descriptions.Item label='订单编号'>{detail.orderId}</Descriptions.Item>
          <Descriptions.Item label='下单时间'>{detail.insertTime}</Descriptions.Item>
          <Descriptions.Item label='下单人'>
            {detail.orderMakerName}
            {detail.orderMakerType === 'lessor' ? '（房东）' : ''}
          </Descriptions.Item>
          <Descriptions.Item label='服务状态'>{orderStatus[detail.orderStatus]}</Descriptions.Item>
          {!!detail.finishTime && <Descriptions.Item label='完成时间'>{detail.finishTime}</Descriptions.Item>}
        </Descriptions>

        <Descriptions title={<BindTitleBar title={'详细信息'} />} column={2} style={{marginBottom: 30}}>
          <Descriptions.Item label='房源地址'>{detail.propertyAddr}</Descriptions.Item>
          <Descriptions.Item label='详细地址'>{detail.communityAddress}</Descriptions.Item>
          <Descriptions.Item label='收费方式'>{detail.saleContractType}</Descriptions.Item>
          <Descriptions.Item label='故障现象'>{detail.orderTags}</Descriptions.Item>
          <Descriptions.Item label='需求描述'>{detail.orderDesc}</Descriptions.Item>
          <Descriptions.Item label='服务项目'>
            <Space>
              {service.serviceTitle(detail)}
              {detail.orderType === '2' && <Tag color={'blue'}>返</Tag>}
            </Space>
          </Descriptions.Item>
          <Descriptions.Item label='照片'>
            {detail.mediaList?.length ? (
              <Upload
                listType='picture-card'
                disabled
                // itemRender={(originNode, file, fileList, actions) => {
                //   return originNode
                // }}
                fileList={detail.mediaList.map(value => {
                  return {
                    uid: value.imgId,
                    name: value.imgId,
                    url: getImg(value.imgUrl),
                  }
                })}
              />
            ) : (
              '空'
            )}
          </Descriptions.Item>
        </Descriptions>
        {['4', '6'].includes(detail.orderStatus) && detail.remarkTag === 0 && (
          <Row justify={'center'}>
            <Button
              type={'primary'}
              onClick={async () => {
                await show(orderId)
                queryDetail(orderId)
              }}
            >
              去评价
            </Button>
          </Row>
        )}
      </div>
    </>
  )
}

// 评价弹窗
export const useRemark = () => {
  const [form] = Form.useForm()
  const modal = useRef<ReturnType<typeof Modal.confirm>>(null)

  const loading = cleanModel.cleanOrderRemark.useLoading()

  useEffect(() => {
    modal.current?.update?.({okButtonProps: {loading}})
  }, [loading])

  const show = useCallback(
    (orderId: string) =>
      new Promise<void>((resolve, reject) => {
        modal.current = Modal.confirm({
          title: '评价',
          icon: null,
          width: 650,
          afterClose: () => {
            form.resetFields()
            modal.current = null
            reject('modal 取消')
          },
          onOk: async () => {
            await form.validateFields()
            const values = form.getFieldsValue(true)
            await cleanModel.cleanOrderRemark({orderId, ...values})
            resolve()
          },
          content: (
            <Form layout={'vertical'} form={form} initialValues={{tagIdList: [], remarkScore: 5}}>
              <Form.Item
                label={'您对这单是否满意？'}
                requiredMark={'optional'}
                labelCol={{style: {paddingBottom: 0}}}
                name={'remarkScore'}
                rules={[{required: true, message: '评分不能为空'}]}
              >
                <Rate />
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, nextValues) => prevValues.remarkScore !== nextValues.remarkScore}
              >
                {f => {
                  const isOptional = f.getFieldValue('remarkScore') >= 4
                  return (
                    <Form.Item
                      label={''}
                      name={'remarkResult'}
                      rules={[{required: !isOptional, message: '具体原因不能为空'}]}
                    >
                      <Input.TextArea placeholder={'请输入具体原因'} rows={4} />
                    </Form.Item>
                  )
                }}
              </Form.Item>
            </Form>
          ),
        })
      }),
    [form]
  )

  return [show]
}
