import {Effects, Model} from '@redux-model/web'
import {$api} from '@/store/service'
import {delay} from '@/utils/util'

// import {permission, PermissionEnum} from './permission'

export interface UserModel {
  token: string
  userInfo: UserInfo
  cityList: string[]
  isAgreedProtocol: boolean
  teamList: TeamTreeNodeVO[]
  currentTeam: TeamTreeNodeVO
  // permissionList: PermissionEnum[]
  // permissionCodeList: string[]
}

// 涉及通联支付的，需要在拿到数据使用之前将金额从分转化成元
class User extends Model<UserModel> {
  protected initReducer(): UserModel {
    return {
      token: '',
      userInfo: {} as UserInfo,
      cityList: [],
      isAgreedProtocol: false,
      teamList: [],
      currentTeam: {} as TeamTreeNodeVO,
      // permissionList: [] as PermissionEnum[],
      // permissionCodeList: [],
    }
  }

  // sessionStorage 的 key
  NEXT_TEAM = 'next_team'

  get canHandleProperty() {
    return this.data.currentTeam?.topLevel === false
  }

  // 用来监听其他model，不能监听自己
  protected effects(): Effects<UserModel> {
    return []
  }

  // 设置当前团队
  setCurrentTeam = this.action((state, payload: TeamTreeNodeVO) => {
    state.currentTeam = payload
    // this.setCurrentPermission(permission[payload?.teamRole])
  })

  clearUserInfo = this.action(state => {
    state.token = ''
    state.userInfo = {} as UserInfo
    state.teamList = []
    state.currentTeam = {} as TeamTreeNodeVO
    // state.permissionList = []
  })

  // 设置permission
  // setCurrentPermission = this.action((state, payload: PermissionEnum[]) => {
  //   state.permissionList = payload || []
  // })

  setAgreedProtocol = this.action((state, payload: boolean) => {
    state.isAgreedProtocol = payload
  })

  // 发送验证码
  sendVerifySms = $api.action((params: UserLoginDTO) => {
    return this.post('/user/send-verify-sms').body(params).throttle(1000, true)
  })

  login = $api.action((params: UserLoginDTO) => {
    return this.post<ApiResponse<string>>('/user/login')
      .body(params)
      .onSuccess((state, {response: {data}}) => {
        state.token = data
      })
  })

  // 查询用户信息
  queryUser = $api.action(() => {
    return this.post<ApiResponse<UserInfo>>('/user/query-user').onSuccess((state, action) => {
      state.userInfo = action.response.data
    })
  })

  // 登录并且查询用户信息
  loginAndReqUserInfo = async (params: UserLoginDTO) => {
    await this.login(params)
    // await delay(100)
    // 查询团队
    await this.queryMyTeam()
    return this.queryUser()
  }

  // 登出
  logout = async () => {
    await this.userLogout()
    await delay(100)
    this.clearUserInfo()
  }

  userLogout = $api.action(() => this.post<ApiResponse>('/user/logout').body({}).hideError(true))

  // 查询用户余额
  payBalance = $api.action(() => this.post<ApiResponse<Balance>>('/pay/balance'))

  //查询团队
  queryMyTeam = $api.action(() =>
    this.post<ApiResponse<TeamTreeNodeVO[]>>('/team/query-my-team').onSuccess((state, {response: {data}}) => {
      const nextTeam = sessionStorage.getItem(this.NEXT_TEAM)
      // 切换团队
      if (nextTeam && data.some(value => value.teamId === nextTeam)) {
        sessionStorage.removeItem(this.NEXT_TEAM)
        state.currentTeam = data.find(value => value.teamId === nextTeam)
        // 设置默认团队
      } else if (!this.data.currentTeam || data.every(item => item.teamId !== this.data.currentTeam?.teamId)) {
        state.currentTeam = data[0]
      }

      state.teamList = data
    })
  )
  // 银行卡-查询签约记录
  bankCardBindRecords = $api.action(() => this.post<ApiResponse<BankCardBindRecords[]>>('/pay/bank-card/bind-records'))

  // 银行卡-解绑银行卡
  bankCardUnBind = $api.action((cardNo: string) => this.post<ApiResponse>('/pay/bank-card/unbind').body({cardNo}))

  // 提现申请
  payWithdraw = $api.action((amount: number, bankCardNo: string) =>
    this.post<ApiResponse<WithdrawApply>>('/pay/withdraw').body({amount, bankCardNo})
  )

  // 支付确认（快捷支付、余额支付、提现）
  payConfirm = $api.action((bizOrderNo: string, verificationCode: string, tradeNo?: string) =>
    this.post<ApiResponse<WithdrawApply>>('/pay/confirm')
      .body({
        bizOrderNo,
        verificationCode,
        tradeNo,
      })
      .onSuccess(() => {
        setTimeout(() => this.payBalance(), 4000)
      })
  )

  // 云商通会员-发送验证码(绑定会员手机)
  memberSendVerificationCode = $api.action(() =>
    this.post<ApiResponse>('/pay/member/sendVerificationCode')
      .throttle(30 * 1000, true)
      .onSuccess(() => {
        this.queryUser()
      })
  )

  // 云商通会员-绑定手机号(校验验证码是否正确)
  memberBindPhone = $api.action((verificationCode: string) =>
    this.post<ApiResponse>('/pay/member/bindPhone').body({verificationCode})
  )

  // 银行卡-查询卡BIN
  bankCardBin = $api.action((acc: string) => this.post<ApiResponse<BankCardBin>>('/pay/bank-card/bin').body({acc}))

  // 银行卡-签约申请(发短信)
  bankCardBindApply = $api.action((args: BankCardBindApplyParams) =>
    this.post<ApiResponse<BankCardBindApply>>('/pay/bank-card/bind-apply').body(args)
  )

  // 银行卡-签约申请确认(短信验证码确认)
  bankCardBindApplyConfirm = $api.action((args: BankCardBindApplyConfirmParams) =>
    this.post<ApiResponse<BankCardBin>>('/pay/bank-card/bind-apply-confirm').body(args)
  )

  // 我的-查询客户经理
  lessorQueryManage = $api.action(() => this.post<ApiResponse<LessorQueryManage[]>>('/lessor/query-manage'))

  // 更新密码
  userUpdatePwd = $api.action((args: UserLoginDTO) => this.post<ApiResponse>('/user/update-pwd').body(args))

  // 忘记密码
  userForgetPwd = $api.action((args: UserLoginDTO) => this.post('/user/forget-pwd').body(args))

  // 支付密码是否存在
  payPwdExist = $api.action(() => this.post<ApiResponse>('/lessor/pay-pwd/exist'))

  // 支付密码修改
  payPwdChange = $api.action((payPwd: string, verifyCode: string) =>
    this.post<ApiResponse>('/lessor/pay-pwd/change').body({payPwd, verifyCode})
  )

  // 批量上传日志
  userLogInsert = $api.action((args: UserLogDTO[]) =>
    this.post<ApiResponse<Record<string, any>>>('/user/log/insert').body(args)
  )

  // 消息列表(包含消息总数)
  userMessageTypeList = $api.action((args: Partial<LessorTypeMessageListDTO>) =>
    this.post<ApiResponse<LessorMessageVO[]>>('/user/message/type-list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // protected onReducerCreated(store: Store) {
  //   setTimeout(() => {
  //     if (this.data?.token) {
  //       this.queryUser()
  //       this.queryMyTeam()
  //     }
  //   }, 1000)
  // }
}

export const userModel = new User()
