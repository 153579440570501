import {Model} from '@redux-model/web'
import {$api} from '@/store/service'
import {delay} from '@/utils/util'

export interface DemoModel {
  count: number
}

class Demo extends Model<DemoModel> {
  protected initReducer(): DemoModel {
    return {
      count: 1,
    }
  }

  // 同步 action
  addCount = this.action((state, countNum: number) => {
    state.count += countNum
  })

  // 异步 action
  addCountAsync = $api.action((countNum: number) => {
    return this.get('/api')
      .onSuccess(state => {
        state.count += countNum
      })
      .onFail(state => {
        state.count -= countNum
      })
  })

  // 独立异步请求（该请求没有 loading 状态）
  async addCountDep(countNum: number) {
    await delay(2000)

    // 用 changeReducer 更新  state
    this.changeReducer(state => {
      state.count += countNum
    })
    return 10
  }

  // 额外应用
  get countX4() {
    return this.data.count * 4
  }
}

export const demoModel = new Demo()
