import {Effects, Model} from '@redux-model/web'
import {$api} from '@/store/service'
import {userModel} from '@/store/models/User'

export interface MessageModel {
  msgTotal: number
}

class Message extends Model<MessageModel> {
  protected initReducer(): MessageModel {
    return {
      msgTotal: 0,
    }
  }

  protected effects(): Effects<MessageModel> {
    return [
      userModel.queryUser.onSuccess(state => {
        this.lessorMessageUnReadCount()
      }),
    ]
  }

  // 房东未读的消息总数
  lessorMessageUnReadCount = $api.action(() =>
    this.post<ApiResponse>('/user/message/unread-count').onSuccess((state, {response: {data}}) => {
      state.msgTotal = data
    })
  )

  // 房东消息列表
  lessorMessageHomepage = $api.action(() => this.post<ApiResponse<MessageHomepageVO[]>>('/user/message/homepage'))

  // 类别-消息列表
  lessorMessageTypeList = $api.action((args: Partial<LessorTypeMessageListDTO>) =>
    this.post<ApiResponse<LessorMessageVO[]>>('/user/message/type-list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // 消息已读
  lessorMessageRead = $api.action((args: {msgType?: string; msgId?: string}) =>
    this.post('/user/message/read')
      .body(args)
      .onSuccess(() => {
        this.lessorMessageUnReadCount()
      })
  )

  // 消息设置详情
  lessorMsgConfigInfo = $api.action(() => this.post<ApiResponse<LessorMsgConfigInfoVO>>('/lessor/msg-config/info'))

  // 消息设置
  lessorMsgConfigChange = $api.action((args: any) => this.post('/lessor/msg-config/change').body(args))
}

export const messageModel = new Message()
