import React from 'react'
import ReactDOM from 'react-dom'
import App from './pages/App'
import * as serviceWorker from './serviceWorker'
import {PersistGate} from '@redux-model/web'
import {Provider} from 'react-redux'
import {store} from '@/store'
import '@/utils/plugin'

import './index.less'
import AuthGate from '@/AuthGate'

ReactDOM.render(
  <Provider store={store}>
    <PersistGate>
      <AuthGate>
        <App />
      </AuthGate>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
