import React, {useCallback, useEffect, useRef, useState} from 'react'
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Tooltip,
  Typography,
} from 'antd'
import styles from './index.module.less'
import commonStyle from '@/assets/styles/common.module.less'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {contractModel} from '@/store/models/Contract'
import {ExclamationCircleFilled} from '@ant-design/icons'
import {useLocation} from 'react-router-dom'
import PropertyCheckOut from '@/pages/contract/components/PropertyCheckOut'
import Cancellation from '@/pages/contract/components/Cancellation'
import {hasPermission, Permission, usePermission} from '@/ui/components/Permission'
import {downloadBlob} from '@/utils/util'
import {CancelContract, CancelContractMethod} from '@/pages/contract/CancelContract'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import dayjs from 'dayjs'
import {CheckOut} from '@/pages/contract/components/CheckOut'
import {AuditButton} from '@/ui/bizComponents/AuditButton'
import {useApplyCheck} from '@/utils/lesseeApplyCheck'
import {ColumnsType} from 'antd/es/table'
import {useAppHistory} from '@/common/hooks/useRouter'
import {propertyModel} from '@/store/models/Property'

interface ContractListProps {
  isReserve?: boolean
}

//业主租约状态
const ownerStatus = [
  {id: '', label: '全部'},
  {id: '2', label: '已签约'},
  {id: '3', label: '已到期'},
  {id: '5', label: '已作废'},
]

const statusObject = {'2': '已签约', '3': '已到期', '5': '已作废'}

export default function ContractList(props: ContractListProps) {
  const [auth] = usePermission()
  const history = useAppHistory()
  const location = useLocation<{
    checkout: string
    communityCode?: string // 有 checkout 就有 communityCode
    contractId: string
    cancellation: string
    contractStatus: string
    contractEnd: string
    condoProject: boolean
  }>()
  const isReserve = props.isReserve
  const state = location.state
  const [list, setList] = useState([] as ContractVO[])

  const [total, setTotal] = useState(0)
  // 显示退房操作弹窗
  const [visible, setVisible] = useState(false)
  // 设置contractId
  const [checkValue, setCheckValue] = useState({
    contractId: '',
    contractEnd: '',
    condoProject: false,
    communityCode: '',
  })
  const [form] = Form.useForm()
  const propertyCheckOutRef = useRef(null)
  const cancellationRef = useRef(null)
  const [isInit, setIsInit] = useState(true)
  const [tabKey, setTabKey] = useState('1')
  const contractRef = useRef<CancelContractMethod>(null)
  let contractStatus = useCodeArraySync('contract.status', {defaultItem: true})
  const contractBrokerage = useCodeSync('contract.brokerage')
  const checkoutType = useCodeArraySync('contract.checkoutType')
  const checkoutTypeObj = useCodeSync('contract.checkoutType')

  const contractReserveTypeArr = useCodeArraySync('contract.reserveType')
  const contractReserveTypeObj = useCodeSync('contract.reserveType')

  const [applyCheck] = useApplyCheck()

  //退房确认
  const [contractVO, setContractVO] = useState<ContractVO>(null)
  // 预订单列表只要已预定和已撤销
  if (isReserve) contractStatus = contractStatus.filter(item => ['6', '7'].includes(item.id as string))
  // 租约列表过滤：全部、已预订、已撤销
  else contractStatus = contractStatus.filter(item => !['6', '7'].includes(item.id as string)) // 只要已预定和已撤销
  const contractStatusObj = useCodeSync('contract.status')
  const contractType = useCodeArraySync('contract.type')
  const contractTypeObj = useCodeSync('contract.type')
  const loading = contractModel.queryContractList.useLoading()

  //租客租约列表
  const queryContractList = useCallback(() => {
    const values = form.getFieldsValue(true)
    contractModel.queryContractList(values).then(({response: {data, total}}) => {
      setList(data)
      setTotal(total)
    })
  }, [form])

  useEffect(() => {
    const status = location.state?.contractStatus
    if (status) {
      location.state.contractStatus = undefined
      form.setFieldsValue({contractStatus: status, pageNum: 1})
      queryContractList()
    }
    if (isInit && !status) {
      setIsInit(false)
      queryContractList()
    }
  }, [form, isInit, location.state, queryContractList])

  const [ownerForm] = Form.useForm()
  const [ownerList, setOwnerList] = useState([] as ContractLessorListQueryResultVO[])
  const [ownerTotal, setOwnerTotal] = useState(0)
  const ownerLoading = contractModel.contractLessorList.useLoading()
  //业主租约列表
  const queryOwnerList = useCallback(() => {
    const values = ownerForm.getFieldsValue(true)
    contractModel.contractLessorList(values).then(({response: {data, total}}) => {
      setOwnerList(data)
      setOwnerTotal(total)
    })
  }, [ownerForm])

  useEffect(() => {
    queryOwnerList()
  }, [queryOwnerList])

  /**
   * 处理路由跳转传入参数state
   */
  useEffect(() => {
    if (state?.checkout) {
      onModalShow({
        contractId: state?.contractId,
        contractEnd: state?.contractEnd,
        condoProject: state?.condoProject,
        communityCode: state?.communityCode,
      })
      history.replace('/contract', {})
    }

    if (state?.cancellation) {
      onCancellation(state?.contractId)
      history.replace('/contract', {})
    }
  }, [history, state])

  /**
   * 显示退房操作弹窗
   */
  function onModalShow(args: {
    contractId: string
    contractEnd: string
    condoProject: boolean
    communityCode: string
    event?: any
  }) {
    const {event, ...value} = args
    setCheckValue(value)
    setVisible(true)
    event && event.stopPropagation()
  }

  /**
   * 退房操作&结账操作
   */
  function onCheckOut(args: {
    type: 'checkout' | 'settleAccounts' | 'all'
    event: React.MouseEvent
    contractId: string
    condoProject: boolean
    contractEnd?: string
    communityCode?: string
  }) {
    const {type, event, ...value} = args
    propertyCheckOutRef.current.onSetValue(value)
    setVisible(false)
    propertyCheckOutRef.current.onDisplay()
    propertyCheckOutRef.current.onShow(type)
    event.stopPropagation()
  }

  /**
   * 结账&退房成功
   */
  function onSuccess() {
    propertyCheckOutRef.current.onHidden()
    queryContractList()
  }

  /**
   * 租约作废
   */
  function onCancellation(contractId: string, event?: any) {
    Modal.confirm({
      centered: true,
      title: '提示',
      icon: <ExclamationCircleFilled />,
      content: '您确认要作废该租约吗？',
      onOk() {
        cancellationRef.current.onDisplay(contractId)
      },
    })
    event && event.stopPropagation()
  }

  //导出所有结果
  function exportAllResults() {
    const values = form.getFieldsValue(true)
    downloadBlob({url: '/contract/export-excel', body: {...values, pageable: false}})
  }

  const renderOperate: {
    when: (record: ContractVO) => boolean
    permission?: string
    title: string
    customer?: boolean
    preCheck?: (record: ContractVO) => void
    onClick: (record: ContractVO, event: any, condoProject?: boolean) => any
  }[] = [
    {
      when: () => !isReserve,
      permission: '002001013',
      title: '查看',
      onClick: record => history.push({pathname: '/contract/detail', state: {contractId: record.contractId}}),
    },
    {
      when: record =>
        record.contractStatus === '1' || (record.contractStatus === '2' && record.contractType === 'paper'),
      permission: '002001014',
      title: '作废',
      customer: true,
      preCheck: async record => await applyCheck(record.contractId),
      onClick: (record, event?: any) => onCancellation(record.contractId, event),
    },
    {
      when: record => record.contractStatus === '4' && record.checkOut === '0',
      permission: '003001002',
      title: '结账',
      customer: true,
      onClick: (record, event: any, condoProject) =>
        onCheckOut({
          event,
          type: 'settleAccounts',
          contractId: record.contractId,
          contractEnd: record.contractEnd,
          condoProject,
          communityCode: record.communityCode,
        }),
    },
    {
      when: record => record.contractStatus === '4' && record.checkOut === '1' && !record.checkOutAgainId,
      permission: '003001018',
      title: '退房确认',
      customer: true,
      onClick: (record, event?: any) => setContractVO(record),
    },
    {
      when: record => ['1', '2', '3'].includes(record.contractStatus) && !record.renewId,
      permission: '002001016',
      title: '退房',
      customer: true,
      preCheck: async record => await applyCheck(record.contractId),
      onClick: (record, event: any, condoProject) =>
        onModalShow({
          contractId: record.contractId,
          contractEnd: record.contractEnd,
          condoProject,
          event,
          communityCode: record.communityCode,
        }),
    },
    {
      when: record => record.contractStatus === '2' && !record.renewId,
      permission: '002001015',
      title: '续约',
      customer: true,
      preCheck: async record => await applyCheck(record.contractId),
      onClick: (record, event: any, condoProject: boolean) => {
        event.stopPropagation()
        if (condoProject && dayjs().add(60, 'day').isBefore(dayjs(record.contractEnd))) {
          return message.warning('距离租期结束超过60天，不可提前续约')
        }
        history.push('/contract/register', {
          from: 'renewal',
          contractId: record.contractId,
        })
      },
    },
    {
      when: record => record.contractStatus === '6',
      permission: '002001011',
      title: '签约',
      customer: true,
      onClick: async (record, event?: any) => {
        // event.stopPropagation()
        const {
          response: {data},
        } = await propertyModel.propertyDetail({propertyCode: record.propertyCode})
        if (data.contractId) return message.warning('当前房源租约未到期，不可进行签约操作！')
        history.push('/contract/register', {
          contractId: record.contractId,
          from: 'reserve-lease',
        })
      },
    },
    {
      when: record => record.contractStatus === '6',
      permission: '002001010',
      title: '撤销',
      customer: true,
      onClick: (record, event: any, condoProject: boolean) => {
        event.stopPropagation()
        contractRef.current?.show({
          contractId: record.contractId,
          contractTime: `${record.contractBegin}至${record.contractEnd}`,
          lesseeName: record.lesseeCertName,
          reservePrice: record.reservePricePaid === 1 ? record.reservePrice : 0,
          title: record.propertyTitle,
          condoProject, // todo: condoProject 是否还有必要
          communityCode: record.communityCode,
        })
      },
    },
    {
      // when: record => record.contractStatus === '6' || record.contractStatus === '7',
      when: record => ['6', '7'].includes(record.contractStatus),
      permission: '003003001',
      title: '查看',
      onClick: (record, event?: any) => {
        event.stopPropagation()
        history.push('/reserve/detail', {
          contractId: record.contractId,
          propertyTitle: record.propertyTitle,
        })
      },
    },
    {
      when: record =>
        ['1', '2'].includes(record.brokerageType) && //
        // record.contractStatus !== '7' && //
        record.brokeragePaid !== 1,
      permission: '003001020',
      title: '修改佣金',
      customer: true,
      onClick: ({brokerage, contractId}) => {
        Modal.confirm({
          icon: null,
          title: '修改佣金',
          content: (
            <Space style={{marginTop: 30}}>
              <InputNumber
                defaultValue={brokerage}
                style={{width: 120}}
                placeholder={'请输入'}
                onChange={value1 => (brokerage = value1)}
              />
              <span>元</span>
            </Space>
          ),
          onOk: async () => {
            await contractModel.contractUpdateBrokerage({contractId, brokerage})
            form.submit()
          },
        })
      },
    },
    {
      when: record =>
        ['1', '2'].includes(record.brokerageType) && //
        // record.contractStatus === '2' && //
        record.brokeragePaid !== 1,
      permission: '003001021',
      title: '确认佣金支付',
      onClick: ({brokerage, brokerageType, contractId}) => {
        Modal.confirm({
          icon: null,
          title: '确认佣金支付',
          okText: '确认已支付',
          content: (
            <Space style={{marginTop: 20}} direction={'vertical'}>
              <span>获客渠道：{contractBrokerage[brokerageType]}</span>
              <span>佣金金额：{brokerage}元</span>
            </Space>
          ),
          onOk: async () => {
            await contractModel.contractConfirmBrokerage({contractId})
            form.submit()
          },
        })
      },
    },
    {
      when: () => isReserve,
      permission: '003003002',
      title: '修改',
      onClick: record =>
        history.push('/reserve/detail', {
          contractId: record.contractId,
          propertyTitle: record.propertyTitle,
          mode: 'edit',
        }),
    },
  ]

  const dataColumns: ColumnsType<ContractVO> = isReserve
    ? [
        {
          title: '预定类型',
          dataIndex: 'reserveType',
          align: 'center',
          render: value => contractReserveTypeObj[value],
        },
        {title: '房源地址', dataIndex: 'propertyTitle', align: 'center'},
        {title: '承租人', dataIndex: 'lesseeCertName', align: 'center'},
        {title: '承租人手机号', dataIndex: 'lesseeMobile', align: 'center'},
        {
          title: '租期始末',
          dataIndex: 'contractDate',
          align: 'center',
          render: (_, record) => `${record.contractBegin ?? ''}至${record.contractEnd ?? ''}`,
        },
        {title: '定金（元）', dataIndex: 'reservePrice', align: 'center'},
        {
          title: '预订单状态',
          dataIndex: 'contractStatus',
          align: 'center',
          render: (text, record) =>
            `${contractStatusObj[text]}(${record.reservePricePaid === 1 ? '已支付' : '未支付'})`,
        },
      ]
    : [
        {title: '房源地址', dataIndex: 'propertyTitle', align: 'center'},
        {
          title: '备注',
          dataIndex: 'contractRemark',
          key: 'contractRemark',
          ellipsis: true,
          align: 'center',
          render: (_, record) =>
            record.contractRemark ? (
              <Tooltip title={record.contractRemark} placement='topLeft'>
                <span>{record.contractRemark}</span>
              </Tooltip>
            ) : (
              '/'
            ),
        },
        {title: '承租人', dataIndex: 'lesseeCertName', align: 'center'},
        {title: '承租人手机号', dataIndex: 'lesseeMobile', align: 'center'},
        {
          title: '获客渠道',
          dataIndex: 'brokerageType',
          key: 'brokerageType',
          render: value => contractBrokerage[value] ?? '/',
        },
        {
          title: '佣金（元）',
          dataIndex: 'brokerage',
          key: 'brokerage',
          render: value => value ?? '/',
        },
        {
          title: '佣金支付状态',
          dataIndex: 'brokeragePaid',
          key: 'brokeragePaid',
          render: (value, record) => (record.brokerage ? (value === 1 ? '已支付' : '未支付') : '/'),
        },
        {
          title: '租期始末',
          dataIndex: 'contractDate',
          align: 'center',
          render: (_, record) => `${record.contractBegin ?? ''}至${record.contractEnd ?? ''}`,
        },
        {title: '月租金（元）', dataIndex: 'rentPrice', align: 'center'},
        {
          title: '合同类型',
          dataIndex: 'contractType',
          align: 'center',
          render: text => contractTypeObj[text],
        },
        {
          title: '租约状态',
          dataIndex: 'contractStatus',
          align: 'center',
          render: (text, record) =>
            contractStatusObj[text] +
            (record.contractStatus === '4' && record.checkOutType ? `（${checkoutTypeObj[record.checkOutType]}）` : ''),
        },
      ]

  return (
    <>
      <Breadcrumb
        path={['首页', '租约管理', `${isReserve ? '预订单列表' : '租约列表'}`]}
        right={
          <>
            {!isReserve && tabKey === '1' && (
              <Space>
                {/*{auth('002001011') && (
                  <Link to={'/contract/register'}>
                    <Button type={'primary'}>租客登记</Button>
                  </Link>
                )}*/}
                {auth('003001010') && (
                  <>
                    <Button onClick={exportAllResults}>导出所有结果</Button>
                  </>
                )}
              </Space>
            )}
          </>
        }
      />
      <Tabs
        defaultActiveKey={'1'}
        className={styles.tabs}
        renderTabBar={isReserve ? () => null : undefined}
        onTabClick={index => setTabKey(index)}
      >
        <Tabs.TabPane tab={'租客租约'} key={'1'}>
          <Form
            form={form}
            onFinish={queryContractList}
            onReset={queryContractList}
            initialValues={{
              contractIdOriginNotNull: false,
              pageNum: 1,
              pageSize: 10,
              contractStatus: isReserve ? '6' : null,
              checkOutType: null,
            }}
          >
            <div className={commonStyle.section}>
              {/** 所属类目 */}
              <Form.Item label={'所属类目'} shouldUpdate style={{marginBottom: 0}}>
                {f =>
                  contractStatus.map(item => (
                    <Button
                      key={item.id}
                      type={f.getFieldValue('contractStatus') === item.id ? 'primary' : 'text'}
                      size={'small'}
                      onClick={() => {
                        f.setFieldsValue({contractStatus: item.id, pageNum: 1})
                        f.submit()
                      }}
                      style={{minWidth: '70px', margin: '0 10px'}}
                    >
                      {item.label}
                    </Button>
                  ))
                }
              </Form.Item>
              <Divider dashed style={{margin: '16px 0'}} />
              <Row gutter={20}>
                <Col span={8}>
                  <Form.Item label={'房源地址'} name={'propertyTitle'} style={{marginBottom: 0}}>
                    <Input allowClear placeholder={'小区/公寓/房间号'} />
                  </Form.Item>
                </Col>
                {!isReserve && (
                  <>
                    <Col span={8}>
                      <Form.Item name={'lesseeName'} label={'承租人姓名'}>
                        <Input allowClear placeholder={'请输入承租人姓名'} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name={'lesseeMobile'} label={'承租人电话'}>
                        <Input allowClear placeholder={'请输入承租人电话'} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name={'_contractBegin'} label={'租期起始'}>
                        <DatePicker.RangePicker
                          onChange={(_, dateStrings) => {
                            const [contractBeginStart, contractBeginFinish] = dateStrings
                            form.setFieldsValue({
                              contractBeginStart: contractBeginStart ? contractBeginStart + ' 00:00:00' : '',
                              contractBeginFinish: contractBeginFinish ? contractBeginFinish + ' 23:59:59' : '',
                            })
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name={'_contractEnd'} label={'租期结束'}>
                        <DatePicker.RangePicker
                          onChange={(_, dateStrings) => {
                            const [contractEndStart, contractEndFinish] = dateStrings
                            form.setFieldsValue({
                              contractEndStart: contractEndStart ? contractEndStart + ' 00:00:00' : '',
                              contractEndFinish: contractEndFinish ? contractEndFinish + ' 23:59:59' : '',
                            })
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name={'contractType'} label={'合同类型'}>
                        <Select allowClear placeholder={'请选择'} style={{width: '100%'}}>
                          {contractType.map(item => (
                            <Select.Option value={item.id} key={item.id}>
                              {item.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name={'_checkOutTime'} label={'实际退房日期'}>
                        <DatePicker.RangePicker
                          onChange={(_, dateStrings) => {
                            const [checkOutTimeStart, checkOutTimeFinish] = dateStrings
                            form.setFieldsValue({
                              checkOutTimeStart: checkOutTimeStart ? checkOutTimeStart + ' 00:00:00' : '',
                              checkOutTimeFinish: checkOutTimeFinish ? checkOutTimeFinish + ' 23:59:59' : '',
                            })
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </>
                )}
                <Col span={8}>
                  <Form.Item name={'_insertTime'} label={isReserve ? '创建时间' : '租约创建时间'}>
                    <DatePicker.RangePicker
                      onChange={(_, dateStrings) => {
                        const [insertTimeStart, insertTimeFinish] = dateStrings
                        form.setFieldsValue({
                          insertTimeStart: insertTimeStart ? insertTimeStart + ' 00:00:00' : '',
                          insertTimeFinish: insertTimeFinish ? insertTimeFinish + ' 23:59:59' : '',
                        })
                      }}
                    />
                  </Form.Item>
                </Col>
                {!isReserve && (
                  <Col span={8}>
                    <Form.Item name={'contractIdOriginNotNull'} colon={false} valuePropName={'checked'}>
                      <Checkbox onChange={() => form.submit()}>换房租约</Checkbox>
                    </Form.Item>
                  </Col>
                )}
                {isReserve && (
                  <Col span={8}>
                    <Form.Item label={'预定类型'} name={'reserveType'} colon={false}>
                      <Select placeholder={'请选择预定类型'} allowClear>
                        {contractReserveTypeArr.map(item => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}

                <Form.Item noStyle shouldUpdate>
                  {f =>
                    f.getFieldValue('contractStatus') === '4' && (
                      <Col span={8}>
                        <Form.Item name={'checkOutType'} label={'退房类型'}>
                          <Select placeholder={'请选择'}>
                            {checkoutType.map(value => (
                              <Select.Option key={value.id} value={value.id}>
                                {value.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    )
                  }
                </Form.Item>
              </Row>
              <Form.Item style={{marginBottom: 0}}>
                <div className={styles.searchOperate}>
                  <Space>
                    <Button htmlType={'reset'}>重置</Button>
                    <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                      查询
                    </Button>
                  </Space>
                </div>
              </Form.Item>
            </div>

            <div className={commonStyle.section}>
              <div style={{marginTop: '-24px'}} />
              <Form.Item
                noStyle
                shouldUpdate={(prev, next) => prev.pageNum !== next.pageNum || prev.pageSize !== next.pageSize}
              >
                {f => (
                  <Table
                    scroll={{x: 'max-content'}}
                    rowKey={'contractId'}
                    dataSource={list}
                    loading={loading}
                    title={() => (
                      <Typography.Text>
                        <Typography.Text strong style={{marginRight: '12px', marginLeft: '-16px'}}>
                          查询结果
                        </Typography.Text>
                        共计
                        <Typography.Text strong style={{color: '#017BFF'}}>
                          {total}
                        </Typography.Text>
                        条符合条件的结果
                      </Typography.Text>
                    )}
                    columns={[
                      ...dataColumns,
                      {
                        title: '操作',
                        fixed: 'right',
                        width: 200,
                        render: record => {
                          return (
                            <>
                              {renderOperate
                                .filter(item => item.when(record))
                                .filter(item => !item.permission || hasPermission(item.permission))
                                .map((item, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      {index !== 0 && <Divider type={'vertical'} style={{margin: 0}} />}
                                      {item.customer ? (
                                        <AuditButton
                                          key={index}
                                          type={'link'}
                                          size={'small'}
                                          style={{padding: '0 5px'}}
                                          preCheck={() => item.preCheck?.(record)}
                                          onClick={(event, condoProject) => item.onClick(record, event, condoProject)}
                                          contractId={record.contractId}
                                          rentType={record.rentType}
                                          communityCode={record.communityCode}
                                        >
                                          {item.title}
                                        </AuditButton>
                                      ) : (
                                        <Button
                                          key={index}
                                          type={'link'}
                                          size={'small'}
                                          style={{padding: '0 5px'}}
                                          onClick={event => item.onClick(record, event)}
                                        >
                                          {item.title}
                                        </Button>
                                      )}
                                    </React.Fragment>
                                  )
                                })}
                            </>
                          )
                        },
                      },
                    ]}
                    pagination={{
                      total,
                      pageSize: f.getFieldValue('pageSize'),
                      current: f.getFieldValue('pageNum'),
                      position: ['bottomCenter'],
                      onChange: (pageNum, pageSize) => {
                        f.setFieldsValue({pageNum, pageSize})
                        f.submit()
                      },
                      showSizeChanger: true,
                    }}
                  />
                )}
              </Form.Item>
            </div>
          </Form>
        </Tabs.TabPane>
        <Tabs.TabPane tab={'业主租约'} key={'2'}>
          <Form
            form={ownerForm}
            onFinish={queryOwnerList}
            onReset={queryOwnerList}
            initialValues={{contractStatus: '', pageNum: 1, pageSize: 10}}
          >
            <div className={commonStyle.section}>
              <Form.Item label={'所属类目'} shouldUpdate style={{marginBottom: 0}}>
                {f =>
                  ownerStatus.map((item, index) => {
                    return (
                      <Button
                        size={'small'}
                        type={f.getFieldValue('contractStatus') === item.id ? 'primary' : 'text'}
                        style={{minWidth: '70px', margin: '0 10px'}}
                        key={item.id}
                        onClick={() => {
                          f.setFieldsValue({contractStatus: item.id, pageNum: 1})
                          f.submit()
                        }}
                      >
                        {item.label}
                      </Button>
                    )
                  })
                }
              </Form.Item>
              <Divider dashed style={{margin: '16px 0'}} />
              <Row gutter={20}>
                <Col span={'8'}>
                  <Form.Item label={'房源地址'} name={'propertyTitle'}>
                    <Input placeholder={'小区/公寓/房间号'} allowClear />
                  </Form.Item>
                </Col>
                <Col span={'8'}>
                  <Form.Item label={'姓名'} name={'lessorCertName'}>
                    <Input placeholder={'输入姓名'} allowClear />
                  </Form.Item>
                </Col>
                <Col span={'8'}>
                  <Form.Item label={'电话'} name={'lessorMobile'}>
                    <Input placeholder={'输入电话'} allowClear />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col span={'8'}>
                  <Form.Item label={'租期起始'} name={'_contractBegin'}>
                    <DatePicker
                      placeholder={'租约起始日期'}
                      onChange={(_, dateString) => {
                        ownerForm.setFieldsValue({contractBegin: dateString ? dateString + ' 00:00:00' : ''})
                      }}
                      style={{width: '100%'}}
                    />
                  </Form.Item>
                </Col>
                <Col span={'8'}>
                  <Form.Item label={'租约结束'} name={'_contractEnd'}>
                    <DatePicker
                      placeholder={'租约结束日期'}
                      onChange={(_, dateString) => {
                        ownerForm.setFieldsValue({contractEnd: dateString ? dateString + ' 23:59:59' : ''})
                      }}
                      style={{width: '100%'}}
                    />
                  </Form.Item>
                </Col>
                <Col span={'8'}>
                  <Form.Item label={'录入时间'} name={'_insertTime'}>
                    <DatePicker.RangePicker
                      onChange={(_, dateStrings) => {
                        const [insertTimeBegin, insertTimeEnd] = dateStrings
                        ownerForm.setFieldsValue({insertTimeBegin, insertTimeEnd})
                      }}
                      showTime
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify={'end'}>
                <Space>
                  <Button type={'primary'} htmlType={'submit'} onClick={() => ownerForm.setFieldsValue({pageNum: 1})}>
                    查询
                  </Button>
                  <Button htmlType={'reset'}>重置</Button>
                </Space>
              </Row>
            </div>

            <div className={commonStyle.section}>
              <Form.Item
                noStyle
                shouldUpdate={(prev, next) => prev.pageNum !== next.pageNum || prev.pageSize !== next.pageSize}
              >
                {f => (
                  <Table
                    scroll={{x: 'max-content'}}
                    dataSource={ownerList}
                    rowKey={'contractId'}
                    loading={ownerLoading}
                    title={() => (
                      <Typography.Text>
                        <Typography.Text strong style={{marginRight: '12px', marginLeft: '-16px'}}>
                          查询结果
                        </Typography.Text>
                        共计
                        <Typography.Text strong style={{color: '#017BFF'}}>
                          {ownerTotal}
                        </Typography.Text>
                        条符合条件的结果
                      </Typography.Text>
                    )}
                    rowSelection={{fixed: true}}
                    columns={[
                      {
                        title: '状态',
                        dataIndex: 'contractStatus',
                        width: 90,
                        align: 'center',
                        render: text => statusObject[text],
                      },
                      {
                        title: '房源地址',
                        dataIndex: 'propertyTitle',
                        width: 220,
                        align: 'center',
                      },
                      {
                        title: '姓名',
                        dataIndex: 'lessorCertName',
                        width: 100,
                        align: 'center',
                      },
                      {
                        title: '手机号',
                        dataIndex: 'lessorMobile',
                        width: 120,
                        align: 'center',
                      },
                      {
                        title: '合同周期',
                        width: 200,
                        align: 'center',
                        render: record => `${record.contractBegin}-${record.contractEnd}`,
                      },
                      {
                        title: '租期',
                        width: 80,
                        align: 'center',
                        render: record =>
                          `${Math.ceil(dayjs(record.contractEnd).diff(dayjs(record.contractBegin), 'year', true))}年`,
                      },
                      {
                        title: '租金',
                        dataIndex: 'rentPrice',
                        width: 100,
                        align: 'center',
                      },
                      {
                        title: '操作',
                        width: 200,
                        fixed: 'right',
                        render: record => {
                          return (
                            <div>
                              {hasPermission('002001007') && (
                                <Button
                                  type={'link'}
                                  size={'small'}
                                  style={{padding: '0 5px'}}
                                  onClick={() => history.push('/contract/owner', {contractId: record.contractId})}
                                >
                                  查看
                                </Button>
                              )}
                              {record.contractStatus !== '5' && (
                                <Permission code={'003001013'}>
                                  <Divider type={'vertical'} style={{margin: 0}} />
                                  <Button
                                    type={'link'}
                                    size={'small'}
                                    style={{padding: '0 5px'}}
                                    onClick={event => {
                                      onCancellation(record.contractId, event)
                                    }}
                                  >
                                    作废
                                  </Button>
                                </Permission>
                              )}
                            </div>
                          )
                        },
                      },
                    ]}
                    pagination={{
                      total: ownerTotal,
                      pageSize: f.getFieldValue('pageSize'),
                      current: f.getFieldValue('pageNum'),
                      position: ['bottomCenter'],
                      onChange: (pageNum, pageSize) => {
                        f.setFieldsValue({pageNum, pageSize})
                        f.submit()
                      },
                      showSizeChanger: true,
                    }}
                  />
                )}
              </Form.Item>
            </div>
          </Form>
        </Tabs.TabPane>
      </Tabs>

      <Modal
        centered
        title={<div style={{textAlign: 'center'}}>退房操作</div>}
        visible={visible}
        cancelText={'退房，稍后结账'}
        okText={'退房并结账'}
        footer={null}
        onCancel={() => setVisible(false)}
      >
        <Typography.Text type='warning'>温馨提示</Typography.Text>
        <Typography.Paragraph>
          1、退房后，不可撤销；
          <br /> 2、确认退房后根据可入住时间，将释放房源为可租状态；
          <br /> 3、退房后请及时与承租人结算账务。
        </Typography.Paragraph>
        <Space className={styles.modalOperate}>
          <Permission code={'002001018'}>
            <Button onClick={event => onCheckOut({type: 'checkout', ...checkValue, event})}>退房，稍后结账</Button>
          </Permission>
          <Permission code={'002001017'}>
            <Button type={'primary'} onClick={event => onCheckOut({type: 'all', ...checkValue, event})}>
              退房并结账
            </Button>
          </Permission>
        </Space>
      </Modal>
      {/* 结账 */}
      <PropertyCheckOut ref={propertyCheckOutRef} onSuccess={() => onSuccess()} />
      {/* 作废 */}
      <Cancellation
        ref={cancellationRef}
        onSuccess={() => (tabKey === '1' ? queryContractList() : queryOwnerList())}
        contractType={tabKey === '1' ? 'lessee' : 'lessor'}
      />

      <CancelContract ref={contractRef} onSuccess={() => queryContractList()} />

      <CheckOut
        contractVO={contractVO}
        onCancel={() => {
          setContractVO(null)
          // 为了更新 checkoutType 状态
          queryContractList()
        }}
        onSuccess={() => {
          setContractVO(null)
          // 查询
          queryContractList()
        }}
      />
    </>
  )
}
