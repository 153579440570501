import React, {useEffect, useState} from 'react'
import {Button, Col, Form, Input, Row, Space, Table} from 'antd'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import deviceStyle from '@/pages/device/styles/device.module.less'
import DeviceTableHeader from '@/pages/device/components/DeviceTableHeader'
import {useHistory} from 'react-router'
import {deviceModel} from '@/store/models/Device'

export default function DeviceLock() {
  const history = useHistory()

  const [form] = Form.useForm()

  const [lockParams, setLockParams] = useState<Partial<DeviceListDTO>>({pageNum: 1, pageSize: 10})
  const [lockList, setLockList] = useState<DeviceListVO[]>([])
  const [lockCount, setLockCount] = useState(0)

  const lockLoading = deviceModel.deviceLockList.useLoading()

  useEffect(
    function () {
      deviceModel.deviceLockList(lockParams).then(({response: {data, total}}) => {
        setLockList(data)
        setLockCount(total)
      })
    },
    [lockParams]
  )

  return (
    <>
      <Breadcrumb
        path={['首页', '智能设备', '门锁']}
        right={
          <Button type={'primary'} onClick={() => history.push('/device-bind', {deviceType: 'lock'})}>
            绑定门锁
          </Button>
        }
      />
      <div className={deviceStyle.formSection}>
        <Form form={form} onFinish={formValues => setLockParams(prevState => ({...prevState, ...formValues}))}>
          <Row>
            <Col span={7}>
              <Form.Item name={'keyword'} label={'房源地址'}>
                <Input placeholder={'小区/公寓名/房间号'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name={'lesseeName'} label={'承租人姓名'}>
                <Input placeholder={'请输入姓名'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name={'lesseeMobile'} label={'承租人电话'}>
                <Input placeholder={'请输入电话'} allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'}>
                查询
              </Button>
              <Button
                htmlType={'reset'}
                onClick={() => {
                  form.resetFields()
                  setLockParams({pageNum: 1, pageSize: 10})
                }}
              >
                重置
              </Button>
            </Space>
          </Row>
        </Form>
      </div>
      <div className={deviceStyle.tableSection}>
        <DeviceTableHeader total={lockCount} />
        <Table
          rowKey={'lesseeId'}
          columns={[
            {title: '房源地址', dataIndex: 'title'},
            {title: '承租人', dataIndex: 'lesseeName'},
            {title: '承租人手机号', dataIndex: 'lesseeMobile'},
            {
              title: '操作',
              render: record => {
                const {propertyCode, lesseeName, lesseeMobile} = record
                return (
                  <Button
                    type={'link'}
                    style={{padding: 0}}
                    onClick={() => history.push('/device-lock/detail', {propertyCode, lesseeName, lesseeMobile})}
                  >
                    查看
                  </Button>
                )
              },
            },
          ]}
          loading={lockLoading}
          dataSource={lockList}
          pagination={{
            total: lockCount,
            current: lockParams.pageNum,
            pageSize: lockParams.pageSize,
            position: ['bottomCenter'],
            showSizeChanger: true,
            hideOnSinglePage: false,
            onChange: (pageNum, pageSize) =>
              setLockParams(prevState => ({
                ...prevState,
                pageSize,
                pageNum: prevState.pageSize === pageSize ? pageNum : 1,
              })),
          }}
        />
      </div>
    </>
  )
}
