import React, {useEffect, useRef, useState} from 'react'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import {ReserveBar} from '@/pages/contract/ContractReserve'
import {CancelContract, CancelContractMethod} from '@/pages/contract/CancelContract'
import styles from '@/pages/contract/ContractReserve/index.module.less'
import {Button, Col, DatePicker, Form, Input, Row, Space, Radio, Select, message} from 'antd'
import {contractModel} from '@/store/models/Contract'
import moment from 'moment'
import {useCodeSync} from '@/store/models/Common'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'

const timeParams = {
  '1': [1, 'month'],
  '2': [3, 'month'],
  '3': [6, 'month'],
  '4': [1, 'year'],
}

function dateFormatter(date: string, format = 'YYYY-MM-DD') {
  return moment(date).isValid() ? moment(date).format(format) : date
}

export default function ContractReserveDetail() {
  const history = useAppHistory()
  const location = useAppLocation<'/reserve/detail'>()
  const contractId = location.state?.contractId
  const propertyTitle = location.state?.propertyTitle
  const mode = location.state?.mode // 可能为编辑模式
  const isEditMode = mode === 'edit'

  const [form] = Form.useForm()

  const contractBrokerage = useCodeSync('contract.brokerage')

  const [info, setInfo] = useState({} as ContractVO)

  const contractRef = useRef<CancelContractMethod>(null)

  useEffect(() => {
    contractModel.contractQueryContract({contractId}).then(({response: {data}}) => {
      setInfo(data)
      form.setFieldsValue({
        contractRemark: data.contractRemark,
        rentPayType: data.rentPayType,
        rentPledgeType: data.rentPledgeType,
        ...(isEditMode
          ? {
              _contractBegin: [moment(data.contractBegin), moment(data.contractEnd)],
              contractBegin: data.contractBegin,
              contractEnd: data.contractEnd,
            }
          : {}),
      })
    })
  }, [contractId, form, isEditMode])

  const loading = contractModel.contractReserveEdit.useLoading()

  return (
    <>
      <div className={styles.breadcrumb}>
        <Breadcrumb path={['租约管理', '预订单列表', '预订单详情']} />
        <h2>预定详情</h2>
      </div>

      <Form
        form={form}
        className={styles.form}
        labelCol={{className: styles.label}}
        initialValues={{contractId}}
        onFinish={async () => {
          const values = form.getFieldsValue(true)
          await contractModel.contractReserveEdit(values)
          message.success('操作成功')
          history.goBack()
        }}
      >
        <div className={styles.body}>
          <ReserveBar label={'房源信息'} top={34} bottom={15} />

          <Form.Item label={'房源地址'} required>
            {propertyTitle}
          </Form.Item>

          <ReserveBar label={'预定信息'} top={30} bottom={15} />
          <Row gutter={10} style={{maxWidth: 414 * 2}}>
            <Col span={12}>
              <Form.Item label={'姓名'}>{info.lesseeCertName}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={'手机号'}>{info.lesseeMobile}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={'身份证号'}>{info.lesseeCertNo}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={'预定金额'}>{`${info.reservePrice ?? 0}元`}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={'获客渠道'}>{contractBrokerage[info.brokerageType]}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={'佣金'}>{`${info.brokerage ?? 0}元`}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={'收款状态'}>{info.reservePricePaid === 1 ? '已收款' : '未收款'}</Form.Item>
            </Col>
            {info.reservePricePaid === 1 && (
              <Col span={12}>
                <Form.Item label={'收款方式'}>{info.reserveBillPayWay}</Form.Item>
              </Col>
            )}
            {info.reservePricePaid === 1 && (
              <Col span={12}>
                <Form.Item label={'收款日期'}>{dateFormatter(info.reserveBillPayTime)}</Form.Item>
              </Col>
            )}
          </Row>

          <ReserveBar label={'约定信息'} top={30} bottom={15} />
          <Row gutter={10}>
            <Col flex={'0 0 414px'}>
              <Form.Item label={'房屋租金'}>{`${info.rentPrice ?? 0}元/月`}</Form.Item>
            </Col>
            {isEditMode ? (
              // 可编辑模式
              <Col flex={1}>
                <Form.Item label={'约定租期'} style={{marginBottom: 20}} required>
                  <Space>
                    <Form.Item noStyle name={'_contractBegin'} rules={[{required: true, message: '请选择约定租期'}]}>
                      <DatePicker.RangePicker
                        onChange={(_, formatString) => {
                          form.setFieldsValue({
                            contractBegin: formatString?.[0],
                            contractEnd: formatString?.[1],
                          })
                        }}
                      />
                    </Form.Item>
                    <Radio.Group
                      onChange={e => {
                        const value = e.target.value
                        const {contractBegin} = form.getFieldsValue(true)
                        const begin = contractBegin ? moment(contractBegin) : moment()
                        const end = moment(begin).add(timeParams[value][0], timeParams[value][1]).subtract(1, 'day')
                        const formatString = 'YYYY-MM-DD'
                        form.setFieldsValue({
                          _contractBegin: [begin, end],
                          contractBegin: begin.format(formatString),
                          contractEnd: end.format(formatString),
                        })
                      }}
                    >
                      <Radio.Button value='1'>1个月</Radio.Button>
                      <Radio.Button value='2'>3个月</Radio.Button>
                      <Radio.Button value='3'>6个月</Radio.Button>
                      <Radio.Button value='4'>1年</Radio.Button>
                    </Radio.Group>
                  </Space>
                </Form.Item>
              </Col>
            ) : (
              <Col span={12}>
                <Form.Item label={'约定租期'} style={{marginBottom: 20}}>{`${dateFormatter(
                  info.contractBegin
                )}至${dateFormatter(info.contractEnd)}`}</Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={10}>
            {/*改为可编辑的*/}
            <Col flex={'0 0 414px'}>
              {/*<Form.Item label={'付款方式'}>{`付${info.rentPayType}押${info.rentPledgeType}`}</Form.Item>*/}
              <Form.Item label={'付款方式'} style={{marginBottom: 20}} required>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label={'付'}
                      colon={false}
                      name={'rentPayType'}
                      required={false}
                      rules={[{required: true, message: '请选择付款方式'}]}
                    >
                      <Select allowClear placeholder={'请选择'}>
                        {Array.from(new Array(24).keys()).map(item => (
                          <Select.Option key={item + 1} value={item + 1}>
                            {item + 1}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={'押'}
                      colon={false}
                      name={'rentPledgeType'}
                      required={false}
                      rules={[{required: true, message: '请选择付款方式'}]}
                    >
                      <Select allowClear placeholder={'请选择'}>
                        {Array.from(new Array(13).keys()).map(item => (
                          <Select.Option key={item} value={item}>
                            {item === 0 ? '自定义' : item}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={'备注信息'} name={'contractRemark'} wrapperCol={{style: {flex: '0 0 453px'}}}>
                <Input.TextArea maxLength={200} placeholder={'请输入备注信息，输入后租客可见'} />
              </Form.Item>
            </Col>
          </Row>

          <ReserveBar label={'操作信息'} top={30} bottom={15} />
          <Row gutter={10} style={{maxWidth: 414 * 2}}>
            <Col span={12}>
              <Form.Item label={'预订操作人'}>{info.operatorName}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={'预订操作时间'}>{info.insertTime}</Form.Item>
            </Col>
          </Row>
          {info.contractStatus === '7' && (
            <>
              <ReserveBar label={'撤销信息'} top={30} bottom={15} />
              <Row gutter={10} style={{maxWidth: 414 * 2}}>
                <Col span={12}>
                  <Form.Item label={'撤销操作人'}>{info.checkOutDetail?.creatorName}</Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={'撤销操作时间'}>{info.checkOutDetail?.insertTime}</Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={'扣款金额'}>{`${info.checkOutDetail?.cutAmt}元`}</Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={'总计（退还租客）'}>{`${info.checkOutDetail?.totalAmt}元`}</Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={'退款途径'}>{info.checkOutDetail?.rfdPayWayName ?? '无'}</Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label={'备注信息'}>{info.checkOutDetail?.rfdRemark ?? '无'}</Form.Item>
                </Col>
              </Row>
            </>
          )}

          <Space size={12} style={{marginTop: '40px'}}>
            {/*{info.contractStatus === '6' && (
              <AuditButton
                type={'primary'}
                rentType={info.rentType}
                communityCode={info.communityCode}
                contractId={contractId}
                onClick={async (_, condoProject) => {
                  contractRef.current?.show({
                    contractId,
                    contractTime: `${info.contractBegin}至${info.contractEnd}`,
                    lesseeName: info.lesseeCertName,
                    reservePrice: info.reservePricePaid === 1 ? info.reservePrice : 0,
                    title: info.propertyTitle,
                    condoProject,
                    communityCode: info.communityCode,
                  })
                }}
              >
                取消预定
              </AuditButton>
            )}*/}
            {info.contractStatus === '6' && (
              <Button type={'primary'} htmlType={'submit'} loading={loading}>
                保存
              </Button>
            )}
            <Button onClick={() => history.goBack()}>返回</Button>
          </Space>
        </div>
      </Form>
      <CancelContract ref={contractRef} onSuccess={() => history.goBack()} />
    </>
  )
}
