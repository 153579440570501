import React, {
  CSSProperties,
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import {Button, Col, Divider, Drawer, Form, message, Row, Select, Space, Table, Tabs, Typography} from 'antd'
import {billModel} from '@/store/models/Bill'
import {downloadBlob, getImg} from '@/utils/util'
import {useCodeSync} from '@/store/models/Common'
import {stewardModel} from '@/store/models/Steward'
import dayjs from 'dayjs'
import {useHistory} from 'react-router-dom'
import StewardBillImage from '@/pages/steward/components/StewardBillImage'
import {configModel} from '@/store/models/Config'

const {Option} = Select
const {TabPane} = Tabs

function BillDetailSection(props: {title: string; style?: CSSProperties; children?: ReactNode}) {
  const {title, style, children} = props
  return (
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', ...style}}>
      <div style={{width: '6px', height: '14px', backgroundColor: '#007BFF', marginRight: '5px'}} />
      <Typography.Title level={4} style={{marginBottom: 0}}>
        {title}
      </Typography.Title>
      {children}
    </div>
  )
}

function StewardBillDetail(
  props: {
    onSubmit?: ({
      billName,
      billIdList,
      billPayAmt,
    }: {
      billName: string
      billIdList: string[]
      billPayAmt: number
    }) => void
    onPending?: () => void
  },
  ref
) {
  const history = useHistory()
  const billCode = useCodeSync('lessor.bill')
  const billTypeCode = useCodeSync('lessor.billType')
  const orderStatusCode = useCodeSync('steward.orderStatus')
  const comparisonTypeCode = useMemo(() => ({'0': '全部', '1': '增加', '-1': '减少'}), [])
  const comparisonTypeArray = useMemo(
    () => [
      {id: '0', label: '全部'},
      {id: '1', label: '增加'},
      {id: '-1', label: '减少'},
    ],
    []
  )

  const {onSubmit, onPending} = props
  const [billId, setBillId] = useState('')
  const [isExtr, setIsExtr] = useState(false)
  const [tabKey, setTabKey] = useState('1')

  // 账单详情 && 服务单详情
  const [billInfo, setBillInfo] = useState({} as LessorBillVO)
  const [stewardOrder, setStewardOrder] = useState({} as StewardOrderQueryVO)
  const [visible, setVisible] = useState(false)

  // 所属区域 && 所属团队
  const [teamList, setTeamList] = useState<PairModel[]>([])
  const [districtList, setDistrictList] = useState<PairModel[]>([])

  // 账单房源列表（当期）
  const [proForm] = Form.useForm()
  const [proParams, setProParams] = useState<Partial<LessorBillPropertyQueryDTO>>({
    pageNum: 1,
    pageSize: 10,
  })
  const [propertyTotal, setPropertyTotal] = useState(0)
  const [propertyList, setPropertyList] = useState<LessorBillPropertyVO[]>([])

  // 账单房源列表（与上期比较）
  const [comparisonList, setComparisonList] = useState<LessorBillPropertyVO[]>([])
  const [comparisonTotal, setComparisonTotal] = useState({} as {addNum: number; removeNum: number; total: number})

  // 预览图片
  const [imgPreview, setImgPreview] = useState({} as {imgUrl: string; visible: boolean})

  const propertyListLoading = billModel.userLessorBillPropertyList.useLoading()
  const comparisonListLoading = billModel.userLessorBillPropertyComparisonList.useLoading()

  // 是否需要显示房源编号
  const isChecked = configModel.useCheckedStatus()

  useImperativeHandle(ref, () => ({
    onOpen: (billId: string, isExtr: boolean) => {
      setBillId(billId)
      setVisible(true)
      setIsExtr(isExtr)
    },
    onClose: () => {
      setVisible(false)
      setBillId('')
    },
  }))

  // 账单详情
  const queryBillInfo = useCallback(async function (billId: string, isExtr = false) {
    const {
      response: {data},
    } = await billModel.userLessorBillInfo({billId})
    setBillInfo(data)

    // 如果是额外费用 查服务单详情
    if (isExtr) {
      stewardModel.stewardDetail({orderId: data.stewardOrderId}).then(({response: {data}}) => {
        setStewardOrder(data)
      })
    }
  }, [])

  useEffect(
    function () {
      if (billId) {
        queryBillInfo(billId, isExtr)
      }
    },
    [billId, isExtr, queryBillInfo]
  )

  // 账单的房源列表查询(当期)
  const queryBillPropertyList = useCallback(
    function (billId: string) {
      billModel.userLessorBillPropertyList({billId, ...proParams}).then(({response: {data, total}}) => {
        // console.log('PropertyList =>', data)
        setPropertyList(data)
        setPropertyTotal(total)
      })
    },
    [proParams]
  )

  // 账单的房源列表查询(与上期比较)
  const queryBillPropertyComparisonList = useCallback(
    function (billId: string) {
      billModel.userLessorBillPropertyComparisonList({billId, ...proParams}).then(({response: {data}}) => {
        // console.log('ComparisonList =>', data)
        setComparisonList(data)
      })
    },
    [proParams]
  )

  useEffect(
    function () {
      if (billId && (tabKey === '2' || tabKey === '3')) {
        queryBillPropertyList(billId)
      }

      if (billId && tabKey === '4') {
        queryBillPropertyComparisonList(billId)
      }
    },
    [billId, queryBillPropertyComparisonList, queryBillPropertyList, tabKey]
  )

  useEffect(
    function () {
      if (billId) {
        billModel.userLessorBillPropertyTeamList({billId, comparison: tabKey === '4'}).then(({response: {data}}) => {
          // console.log('TeamList ===>>>', data)
          setTeamList(data)
        })
      }
    },
    [billId, tabKey, teamList.length]
  )

  useEffect(
    function () {
      if (billId) {
        billModel
          .userLessorBillPropertyDistrictList({billId, comparison: tabKey === '4'})
          .then(({response: {data}}) => {
            // console.log('DistrictList ===>>>', data)
            setDistrictList(data)
          })
      }
    },
    [billId, districtList.length, tabKey]
  )

  useEffect(
    function () {
      if (billId && tabKey === '4') {
        billModel.userLessorBillPropertyComparisonTotal({billId}).then(({response: {data}}) => {
          // console.log('ComparisonTotal', data)
          setComparisonTotal(data)
        })
      }
    },
    [billId, tabKey]
  )

  function dateFormat(strDate: string, format = 'YYYY-MM-DD') {
    return dayjs(strDate).format(format) ?? ''
  }

  // 基础费用
  function renderBasic() {
    return (
      <>
        <Row>
          <Col span={12}>账单状态：{billCode[billInfo.billStatus] ?? ''}</Col>
          <Col span={12}>账单名称：{billInfo.billName ?? ''}</Col>
        </Row>
        <Row style={{marginTop: '10px'}}>
          <Col span={12}>账单类型：{billTypeCode[billInfo.billType]}</Col>
          <Col span={12}>
            账单周期：
            {[dateFormat(billInfo.billBegin), dateFormat(billInfo.billEnd)].filter(item => item).join('至')}
          </Col>
        </Row>
        <Row style={{marginTop: '10px'}}>
          <Col span={12}>账单生成日期：{billInfo.billCreateTime}</Col>
          <Col span={12}>账单金额：{billInfo.billAmtTotal}</Col>
        </Row>
        <Row style={{marginTop: '10px'}}>
          <Col span={12}>应付款日：{billInfo.billDate}</Col>
          {['2', '3'].includes(billInfo.billStatus) && <Col span={12}>付款方式：{billInfo.billPayWayName}</Col>}
        </Row>
        {['2', '3'].includes(billInfo.billStatus) && (
          <>
            <Row style={{marginTop: '10px'}}>
              <Col span={12}>付款日期：{billInfo.billPayTime}</Col>
            </Row>
            {!!billInfo.billDetailRemark && (
              <Row style={{marginTop: '10px'}}>
                <Col span={12}>备注：{billInfo.billDetailRemark}</Col>
              </Row>
            )}
            {!!billInfo.imgUrl && (
              <Row style={{marginTop: '10px'}} align={'top'}>
                <Typography.Text>照片凭证：</Typography.Text>
                <div
                  style={{padding: '7px', border: '1px solid rgba(0, 0, 0, 0.15)'}}
                  onClick={() => setImgPreview({imgUrl: billInfo.imgUrl, visible: true})}
                >
                  <img src={getImg(billInfo.imgUrl)} width={74} height={74} alt={'照片凭证'} />
                </div>
              </Row>
            )}
          </>
        )}
      </>
    )
  }

  // 额外费用
  function renderExtra() {
    return (
      <>
        <div style={{marginTop: '20px'}}>
          <Row>
            <Col span={12}>账单状态：{billCode[billInfo.billStatus] ?? ''}</Col>
            <Col span={12}>账单名称：{billInfo.billName ?? ''}</Col>
          </Row>
          <Row style={{marginTop: '10px'}}>
            <Col span={12}>账单类型：{billTypeCode[billInfo.billType]}</Col>
            <Col span={12}>账单生成日期：{billInfo.billCreateTime}</Col>
          </Row>
          <Row style={{marginTop: '10px'}}>
            <Col span={12}>账单金额：{billInfo.billAmtTotal}</Col>
            <Col span={12}>应付款日：{billInfo.billDate}</Col>
          </Row>
          {['2', '3'].includes(billInfo.billStatus) && (
            <>
              <Row style={{marginTop: '10px'}}>
                <Col span={12}>付款方式：{billInfo.billPayWayName}</Col>
                <Col span={12}>付款日期：{billInfo.billPayTime}</Col>
              </Row>
              {!!billInfo.billDetailRemark && (
                <Row style={{marginTop: '10px'}}>
                  <Col span={12}>备注：{billInfo.billDetailRemark}</Col>
                </Row>
              )}
              {!!billInfo.imgUrl && (
                <Row style={{marginTop: '10px'}} align={'top'}>
                  <Typography.Text>照片凭证：</Typography.Text>
                  <div
                    style={{padding: '7px', border: '1px solid rgba(0, 0, 0, 0.15)'}}
                    onClick={() => setImgPreview({imgUrl: billInfo.imgUrl, visible: true})}
                  >
                    <img src={getImg(billInfo.imgUrl)} width={74} height={74} alt={'照片凭证'} />
                  </div>
                </Row>
              )}
            </>
          )}
        </div>

        <div style={{margin: '30px 0 20px 0', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <div style={{width: '6px', height: '14px', backgroundColor: '#007BFF', marginRight: '5px'}} />
          <Typography.Title level={4} style={{marginBottom: 0}}>
            服务单信息
          </Typography.Title>
        </div>
        <div>
          <Row align={'middle'}>
            <Col span={12}>
              服务订单号：
              <Button
                type={'link'}
                style={{padding: 0}}
                onClick={() => history.push('/steward/detail', {orderId: stewardOrder.orderId})}
              >
                {stewardOrder.orderId}
              </Button>
            </Col>
            <Col span={12}>订单状态：{orderStatusCode[stewardOrder.orderStatus] ?? ''}</Col>
          </Row>
          <Row style={{marginTop: '10px'}}>
            <Col span={12}>
              订单类型：
              {[stewardOrder.categoryFirName, stewardOrder.categorySecName, stewardOrder.categoryThiName]
                .filter(item => item)
                .join('-') ?? ''}
            </Col>
            <Col span={12}>房源地址：{stewardOrder.propertyAddr ?? ''}</Col>
          </Row>
          <Row style={{marginTop: '10px'}}>
            <Col span={12}>
              管家姓名手机号：
              {[stewardOrder.stewardName, stewardOrder.stewardMobile].filter(item => item).join('/') ?? ''}
            </Col>
          </Row>
          <Row style={{marginTop: '10px'}} align={'top'}>
            <Typography.Text>处理后照片：</Typography.Text>
            {!!stewardOrder.imgListOfStewardBack?.length && (
              <div
                style={{padding: '7px', border: '1px solid rgba(0, 0, 0, 0.15)'}}
                onClick={() => setImgPreview({imgUrl: stewardOrder.imgListOfStewardBack[0].imgUrl, visible: true})}
              >
                <img
                  src={getImg(stewardOrder.imgListOfStewardBack[0].imgUrl)}
                  width={74}
                  height={74}
                  alt={'处理后照片'}
                />
              </div>
            )}
          </Row>
        </div>
      </>
    )
  }

  return (
    <>
      <Drawer
        visible={visible}
        title={'账单详情'}
        width={'50%'}
        bodyStyle={{padding: 0}}
        headerStyle={{borderBottomWidth: 0}}
        onClose={() => {
          setVisible(false)
          setBillId('')
        }}
      >
        {['1', '3'].includes(billInfo.billType) ? (
          <Tabs
            defaultActiveKey={'1'}
            tabBarExtraContent={
              <Button
                type={'link'}
                onClick={() => {
                  if (tabKey === '1') {
                    setVisible(false)
                    setBillId('')
                    onSubmit({
                      billName: billInfo.billName,
                      billIdList: [billInfo.billId],
                      billPayAmt: billInfo.billAmtTotal,
                    })
                  } else {
                    if (tabKey === '3' || tabKey === '2') {
                      downloadBlob({url: '/user/lessor/bill/property-list-excel', body: {...proParams, billId}})
                    }
                    if (tabKey === '4') {
                      downloadBlob({
                        url: '/user/lessor/bill/property-comparison-list-excel',
                        body: {
                          ...proParams,
                          billId,
                          comparisonType: proParams.comparisonType ? proParams.comparisonType : 0,
                        },
                      })
                    }
                  }
                }}
              >
                {tabKey === '1' ? (billInfo.billStatus === '1' ? '提交付款凭证' : '') : '导出'}
              </Button>
            }
            tabBarStyle={{paddingLeft: 24}}
            onChange={tabKey => {
              setTabKey(tabKey)
              setProParams({pageNum: 1, pageSize: 10})
            }}
          >
            {/** 基本信息 */}
            <TabPane tab={'基本信息'} key={'1'} style={{padding: '14px 24px'}}>
              <BillDetailSection title={'基本信息'} />
              <div style={{marginTop: '15px'}}>{renderBasic()}</div>
            </TabPane>

            {/** 房源信息 */}
            <TabPane tab={'房源信息'} key={'2'}>
              <div style={{padding: '10px 24px'}}>
                <Form
                  form={proForm}
                  onFinish={values => {
                    setProParams(prevState => ({
                      ...prevState,
                      ...values,
                      comparisonType: values.comparisonType ?? 0,
                      pageNum: 1,
                    }))
                  }}
                >
                  <Row>
                    <Col span={10}>
                      <Form.Item name={'districtCode'} label={'所属区域'}>
                        <Select placeholder={'请选择'}>
                          {districtList.map(item => (
                            <Option value={item.key} key={item.key}>
                              {item.value}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={10} offset={4}>
                      <Form.Item name={'teamId'} label={'所属团队'}>
                        <Select placeholder={'请选择'}>
                          {teamList.map(item => (
                            <Option value={item.key} key={item.key}>
                              {item.value}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  {tabKey === '4' && (
                    <Row>
                      <Col span={10}>
                        <Form.Item name={'comparisonType'} label={'状态选择'}>
                          <Select placeholder={'请选择'} style={{width: '100%'}}>
                            {comparisonTypeArray.map(item => (
                              <Option value={item.id} key={item.id}>
                                {item.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                  <Row justify={'end'}>
                    <Space>
                      <Button type={'primary'} htmlType={'submit'}>
                        查询
                      </Button>
                      <Button
                        onClick={() => {
                          proForm.resetFields()
                          setProParams({pageNum: 1, pageSize: 10})
                        }}
                      >
                        重置
                      </Button>
                    </Space>
                  </Row>
                </Form>
              </div>

              {/** 当期详情 */}
              <Tabs
                defaultActiveKey={'3'}
                tabBarStyle={{paddingLeft: 24}}
                onChange={tabKey => {
                  setTabKey(tabKey)
                  setProParams({pageNum: 1, pageSize: 10})
                }}
              >
                <TabPane tab={'当期详情'} key={'3'}>
                  <div style={{padding: '0 24px'}}>
                    <BillDetailSection title={'房源详情'}>
                      <Typography.Text
                        style={{marginLeft: '20px', color: '#000', fontSize: '14px', lineHeight: '19px'}}
                      >
                        房源总数：<Typography.Text style={{color: '#007BFF'}}>{propertyTotal}</Typography.Text>
                      </Typography.Text>
                    </BillDetailSection>
                    <Table
                      style={{marginTop: '20px'}}
                      rowKey={'propertyCode'}
                      dataSource={propertyList}
                      loading={propertyListLoading}
                      columns={[
                        {title: '房源code', dataIndex: 'propertyCode', key: 'propertyCode'},
                        isChecked && {
                          title: '自定义房源编号',
                          dataIndex: 'propertyCodeCustom',
                          key: 'propertyCodeCustom',
                          render: value => (
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                              <span>{value ?? '/'}</span>
                            </div>
                          ),
                        },
                        {title: '所属团队', dataIndex: 'teamName', key: 'teamName'},
                        {title: '所属区域', dataIndex: 'districtName', key: 'districtName'},
                        {title: '地址', dataIndex: 'propertyTitle', key: 'propertyTitle'},
                      ].filter(item => item)}
                      scroll={{x: 'max-content'}}
                      pagination={{
                        size: 'default',
                        total: propertyTotal,
                        current: proParams.pageNum,
                        pageSize: proParams.pageSize,
                        position: ['bottomCenter'],
                        showSizeChanger: true,
                        onChange: page => setProParams(prevState => ({...prevState, pageNum: page})),
                        onShowSizeChange: (_, size) =>
                          setProParams(prevState => ({...prevState, pageNum: 1, pageSize: size})),
                      }}
                    />
                  </div>
                </TabPane>

                {/** 与上期比较（补充的不展示） */}
                {billInfo.billType !== '3' && (
                  <TabPane tab={'与上期比较'} key={'4'}>
                    <div style={{padding: '0 24px'}}>
                      <BillDetailSection title={'房源详情'}>
                        <Typography.Text
                          style={{marginLeft: '20px', color: '#000', fontSize: '14px', lineHeight: '19px'}}
                        >
                          新增总数：
                          <Typography.Text style={{color: '#007BFF'}}>{comparisonTotal.addNum ?? 0}</Typography.Text>
                        </Typography.Text>
                        <Typography.Text
                          style={{marginLeft: '20px', color: '#000', fontSize: '14px', lineHeight: '19px'}}
                        >
                          减少总数：
                          <Typography.Text style={{color: '#007BFF'}}>{comparisonTotal.removeNum ?? 0}</Typography.Text>
                        </Typography.Text>
                      </BillDetailSection>
                      <Table
                        style={{marginTop: '20px'}}
                        rowKey={'propertyCode'}
                        dataSource={comparisonList}
                        loading={comparisonListLoading}
                        columns={[
                          {
                            title: '状态',
                            dataIndex: 'comparisonType',
                            key: 'comparisonType',
                            render: text => comparisonTypeCode[text] ?? '',
                          },
                          isChecked && {
                            title: '自定义房源编号',
                            dataIndex: 'propertyCodeCustom',
                            key: 'propertyCodeCustom',
                            render: value => (
                              <div style={{display: 'flex', justifyContent: 'center'}}>
                                <span>{value ?? '/'}</span>
                              </div>
                            ),
                          },
                          {title: '房源code', dataIndex: 'propertyCode', key: 'propertyCode'},
                          {title: '所属团队', dataIndex: 'teamName', key: 'teamName'},
                          {title: '所属区域', dataIndex: 'districtName', key: 'districtName'},
                          {title: '地址', dataIndex: 'propertyTitle', key: 'propertyTitle'},
                        ].filter(item => item)}
                        scroll={{x: 'max-content'}}
                        pagination={{
                          size: 'default',
                          total: comparisonTotal.total,
                          current: proParams.pageNum,
                          pageSize: proParams.pageSize,
                          position: ['bottomCenter'],
                          showSizeChanger: true,
                          onChange: page => setProParams(prevState => ({...prevState, pageNum: page})),
                          onShowSizeChange: (_, size) =>
                            setProParams(prevState => ({...prevState, pageNum: 1, pageSize: size})),
                        }}
                      />
                    </div>
                  </TabPane>
                )}
              </Tabs>
            </TabPane>
          </Tabs>
        ) : (
          <>
            <div
              style={{padding: '16px 24px 0', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}
            >
              <Typography.Title level={4}>操作</Typography.Title>
              {billInfo.billStatus === '4' && (
                <Button
                  type={'link'}
                  onClick={async () => {
                    await billModel.userLessorBillPending({billId: billInfo.billId})
                    message.success('审批成功！')
                    queryBillInfo(billInfo.billId)
                    onPending()
                  }}
                >
                  审批通过
                </Button>
              )}
            </div>
            <Divider style={{marginTop: '12px'}} />
            <div style={{padding: '0 24px'}}>
              <BillDetailSection title={'账单信息'} />
              {renderExtra()}
            </div>
          </>
        )}
      </Drawer>

      <StewardBillImage
        imgUrl={imgPreview.imgUrl}
        visible={imgPreview.visible}
        onCancel={() => setImgPreview(prevState => ({...prevState, visible: false}))}
      />
    </>
  )
}

export default forwardRef(StewardBillDetail)
