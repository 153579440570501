import React, {useState} from 'react'
import styles from '@/pages/contract/ContractDetail/index.module.less'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  // message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Typography,
} from 'antd'
import {Permission} from '@/ui/components/Permission'
import moment from 'moment'
import {useCodeSync, useCodeArraySync} from '@/store/models/Common'
import {Album, UploadButton} from '@/ui/components/Album'
import {baseUrl} from '@/common/config'
import {getImg} from '@/utils/util'

const {Option} = Select
const {Text} = Typography
const {RangePicker} = DatePicker

type CheckinList = ContractPersonCheckinUpsetDTO & Partial<Pick<ContractPersonCheckinVO, 'status' | 'personType'>>

export interface AddCheckinProps {
  contractId?: string // 如果有就传
  checkinList: CheckinList[] // 入住人信息
  // onCheckinList: (value: CheckinPersonList[]) => void

  onSubmit?: (value: CheckinList) => void
  onDelete?: (personId: string) => void
}

/**
 * 入住人管理
 */
export const AddCheckin: React.FC<AddCheckinProps> = props => {
  // const callback = useRef(props.onCheckinList)
  // useEffect(() => {
  //   callback.current = props.onCheckinList
  // }, [props])

  // const checkinList = useMemo(
  //   () => props.checkinList.map(value => ({...value, personId: value.personId ?? genTempPersonId()})),
  //   [props.checkinList]
  // )

  const gender = useCodeSync('gender')
  // 证件类型
  const contractCertType = useCodeSync('contract.certType')
  const certType = useCodeArraySync('contract.certType')
  // 星座
  const constellation = useCodeArraySync('constellation')
  // 本地户籍
  const householdRegister = useCodeArraySync('household.register')
  // 学历
  const education = useCodeArraySync('education')
  // 关系
  const relation = useCodeArraySync('relation')

  // 添加入住人表单
  const [form] = Form.useForm()

  // 是否显示表单
  const [showForm, setShowForm] = useState(false)

  // useEffect(() => {
  //   if (props.contractId) {
  //     contractModel.checkinPersonList(props.contractId).then(({response: {data}}) => {
  //       // setCheckinList(data)
  //       callback.current?.(data)
  //     })
  //   }
  // }, [props.contractId])
  //
  // /**
  //  * 查询入住人列表
  //  */
  // function personList() {
  //   if (props.contractId) {
  //     contractModel.checkinPersonList(props.contractId).then(({response: {data}}) => {
  //       // setCheckinList(data)
  //       callback.current?.(data)
  //     })
  //   }
  // }

  // // 入住人id
  // const [personId, setPersonId] = useState('')

  /**
   * 删除入住人
   */
  // async function onDelete(personId: string) {
  //   await contractModel.checkinPersonDelete(personId)
  //   message.success('删除成功！')
  //   personList()
  // }

  /**
   * 添加入住人
   */
  async function onSubmit() {
    const value: any = form.getFieldsValue(true)
    const [certEffectStart, certEffectEnd] = value.time ?? [undefined, undefined]
    const params: ContractPersonCheckinUpsetDTO = {
      ...value,
      contractId: props.contractId,
      checkinTime: moment(value.checkinTime).format('YYYY-MM-DD HH:mm:ss'),
      certEffectStart: certEffectStart ? moment(certEffectStart).format('YYYY-MM-DD HH:mm:ss') : '',
      certEffectEnd: certEffectEnd ? moment(certEffectEnd).format('YYYY-MM-DD HH:mm:ss') : '',
      birthday: value.birthday ? moment(value.birthday).format('YYYY-MM-DD HH:mm:ss') : '',
      laborContractStart: value.laborContractStart
        ? moment(value.laborContractStart).format('YYYY-MM-DD HH:mm:ss')
        : '',
    }
    props.onSubmit?.(params)

    setShowForm(false)
    //
    // await contractModel.checkinPersonUpsert(params)
    //
    // if (params.personId) {
    //   // await contractModel.checkinPersonUpsert(params)
    //   message.success('编辑成功')
    // } else {
    //   // await contractModel.checkinPersonUpsert(params)
    //   message.success('添加成功')
    // }
    // setShowForm(false)
    // personList()
  }

  return (
    <>
      <Permission code={'003002002'}>
        <div className={styles.header}>入住人基本信息</div>
        <div className={styles.content}>
          <div className={styles.between}>
            <Text>入住信息当前在住{props.checkinList.length}人</Text>
            <Permission code={'003001003'}>
              <Button type={'link'} onClick={() => setShowForm(true)}>
                添加入住人
              </Button>
            </Permission>
          </div>
          <Table
            rowKey={'personId'}
            className={styles.table}
            size={'middle'}
            dataSource={props.checkinList}
            columns={[
              {
                title: '入住人',
                dataIndex: 'name',
                key: 'name',
                render: (text, record: ContractPersonCheckinUpsetDTO | ContractPersonCheckinVO) => {
                  return (
                    <Space>
                      <Text>{text}</Text>
                      {'personType' in record && record?.personType === '1' ? <Tag color={'blue'}>承租人</Tag> : null}
                    </Space>
                  )
                },
              },
              {
                title: '性别',
                dataIndex: 'gender',
                key: 'gender',
                render: text => {
                  return gender[text]
                },
              },
              {title: '电话', dataIndex: 'mobile', key: 'mobile'},
              {
                title: '证件类型',
                dataIndex: 'certType',
                key: 'certType',
                render: text => <Text>{contractCertType[text]}</Text>,
              },
              {title: '证件号码', dataIndex: 'certNo', key: 'certNo'},
              {title: '入住时间', dataIndex: 'checkinTime', key: 'checkinTime'},
              ...(props.contractId
                ? [
                    {
                      title: '退住时间',
                      dataIndex: 'updateTime',
                      render: (text, record) => {
                        if (record.status === '0') {
                          return moment(text).format('YYYY-MM-DD')
                        }
                        return '/'
                      },
                    },
                  ]
                : []),
              {
                title: '操作',
                render: (_, record: CheckinList) => {
                  // 【【房东web】同住人退住】https://www.tapd.cn/40183245/prong/stories/view/1140183245001001732
                  // 删除原 003001003 权限
                  return (
                    props.contractId &&
                    record?.personType !== '1' &&
                    record.status === '1' && (
                      <Permission code={'003001019'}>
                        <Button
                          type={'link'}
                          onClick={() => {
                            Modal.confirm({
                              title: '提示',
                              content: '确认退住？',
                              icon: null,
                              onOk: () => props.onDelete?.(record.personId),
                            })
                          }}
                        >
                          退住
                        </Button>
                      </Permission>
                    )
                  )
                },
              },
            ]}
            pagination={false}
          />
        </div>
      </Permission>

      <Modal
        visible={showForm}
        centered={true}
        width={665}
        closable={true}
        onCancel={() => setShowForm(false)}
        footer={null}
        afterClose={() => form.resetFields()}
      >
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <span className={styles.tenantTitle}>{form.getFieldValue('personId') ? '编辑入住人' : '添加入住人'}</span>
        </div>
        <Form form={form} colon={false} onFinish={onSubmit} labelCol={{span: 5}} size={'small'}>
          {/** 入住人信息 */}
          <div className={styles.tenantBorder}>
            <div className={styles.tenantHeader}>
              <span className={styles.tenantHeaderTitle}>入住人信息</span>
            </div>
            <Row className={styles.tenantPadding}>
              <Col span={12}>
                <Form.Item
                  name={'name'}
                  label={'入住人姓名'}
                  labelCol={{span: 10}}
                  rules={[{required: true, message: '请输入入住人姓名'}]}
                  className={styles.tenantMargin}
                >
                  <Input placeholder={'请输入入住人姓名'} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={'gender'}
                  label={'入住人性别'}
                  labelCol={{span: 10}}
                  rules={[{required: true, message: '请选择入住人性别'}]}
                  className={styles.tenantMargin}
                >
                  <Radio.Group>
                    <Radio value={'1'}>男</Radio>
                    <Radio value={'2'}>女</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label={'证件类型'} className={styles.tenantMargin} required>
                  <Input.Group compact>
                    <Form.Item name={'certType'} noStyle rules={[{required: true, message: '请选择证件类型'}]}>
                      {/*入住人证件类型*/}
                      <Select placeholder='证件类型' style={{width: 120}}>
                        {certType.map(item => (
                          <Option value={item.id} key={item.id}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item name={'certNo'} noStyle rules={[{required: true, message: '请输入入住人证件号码'}]}>
                      <Input style={{width: '50%'}} placeholder='请输入入住人证件号码' />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item wrapperCol={{span: 10}} name={'adress'} label={'入住人住址'} className={styles.tenantMargin}>
                  <Input placeholder={'请输入住址'} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name={'time'} label={'证件有效期'} className={styles.tenantMargin}>
                  <RangePicker />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={'mobile'}
                  label={'手机号'}
                  labelCol={{span: 10}}
                  className={styles.tenantMargin}
                  rules={[{required: true, message: '请输入11位手机号'}]}
                >
                  <Input placeholder={'请输入11位手机号'} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={'checkinTime'}
                  label={'入住时间'}
                  labelCol={{span: 10}}
                  className={styles.tenantMargin}
                  rules={[{required: true, message: '请选择入驻时间'}]}
                >
                  <DatePicker format={'YYYY-MM-DD'} />
                </Form.Item>
              </Col>
            </Row>
          </div>

          {/** 其他资料 */}
          <div className={styles.tenantBorder}>
            <div className={styles.tenantHeader}>
              <span className={styles.tenantHeaderTitle}>其他资料</span>
            </div>
            <Row className={styles.tenantPadding}>
              <Col span={12}>
                <Form.Item name={'profession'} label={'职业'} labelCol={{span: 10}} className={styles.tenantMargin}>
                  <Input placeholder={'请输入职业'} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name={'hobby'} label={'兴趣爱好'} labelCol={{span: 10}} className={styles.tenantMargin}>
                  <Input placeholder={'请输入兴趣爱好'} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name={'constellation'} label={'星座'} labelCol={{span: 10}} className={styles.tenantMargin}>
                  <Select placeholder={'请选择星座'}>
                    {constellation.map(item => (
                      <Option value={item.id} key={item.id}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name={'birthday'} label={'生日'} labelCol={{span: 10}} className={styles.tenantMargin}>
                  <DatePicker />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label={'证件照片'} className={styles.tenantMargin}>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    {[
                      {imgType: '01', title: '正面照', keys: 'certFront'},
                      {imgType: '02', title: '反面照', keys: 'certBack'},
                      {imgType: '03', title: '手持半身', keys: 'certHold'},
                    ].map((item, index) => {
                      const {imgType, title, keys} = item
                      return (
                        <Form.Item shouldUpdate className={styles.tenantMargin} key={index}>
                          {() => (
                            <Album
                              action={baseUrl + '/contract/upload-img'}
                              data={{
                                imgType,
                              }}
                              showUploadList={false}
                              onDone={fileList => {
                                form.setFieldsValue({
                                  [keys]: fileList[0]?.imgUrl,
                                })
                              }}
                            >
                              {form.getFieldValue(keys) ? (
                                <img style={{width: 80, height: 80}} src={getImg(form.getFieldValue(keys))} alt='' />
                              ) : (
                                <UploadButton title={title} />
                              )}
                            </Album>
                          )}
                        </Form.Item>
                      )
                    })}
                  </div>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item wrapperCol={{span: 10}} name={'remark'} label={'备注'} className={styles.tenantMargin}>
                  <Input.TextArea rows={3} placeholder={'请输入备注信息'} />
                </Form.Item>
              </Col>
            </Row>
          </div>

          {/** 补充信息 */}
          <div className={styles.tenantBorder}>
            <div className={styles.tenantHeader}>
              <span className={styles.tenantHeaderTitle}>补充信息</span>
            </div>
            <Row className={styles.tenantPadding}>
              <Col span={12}>
                <Form.Item name={'household'} label={'户籍'} labelCol={{span: 10}} className={styles.tenantMargin}>
                  <Select placeholder={'请选择户籍'}>
                    {householdRegister?.map(item => (
                      <Option value={item.id} key={item.id}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name={'education'} label={'学历'} labelCol={{span: 10}} className={styles.tenantMargin}>
                  <Select placeholder={'请选择学历'}>
                    {education.map(item => (
                      <Option value={item.id} key={item.id}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name={'workplace'} label={'工作单位'} labelCol={{span: 10}} className={styles.tenantMargin}>
                  <Input placeholder={'请输入工作单位'} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={'laborContractStart'}
                  label={'劳动合同开始时间'}
                  labelCol={{span: 10}}
                  className={styles.tenantMargin}
                >
                  <DatePicker />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={'laborContractTerm'}
                  label={'劳动合同年限'}
                  labelCol={{span: 10}}
                  className={styles.tenantMargin}
                >
                  <Input placeholder={'请输入'} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={'acceptCode'}
                  label={'申请受理编号'}
                  labelCol={{span: 10}}
                  className={styles.tenantMargin}
                >
                  <Input placeholder={'请输入'} />
                </Form.Item>
              </Col>
            </Row>
          </div>

          {/** 补充信息 */}
          <div className={styles.tenantBorder}>
            <div className={styles.tenantHeader}>
              <span className={styles.tenantHeaderTitle}>补充信息</span>
            </div>
            <Row className={styles.tenantPadding}>
              <Col span={12}>
                <Form.Item
                  name={'emergPersonName'}
                  label={'紧急联系人姓名'}
                  labelCol={{span: 10}}
                  className={styles.tenantMargin}
                >
                  <Input placeholder={'请输入紧急联系人姓名'} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={'emergPersonRelation'}
                  label={'关系'}
                  labelCol={{span: 10}}
                  className={styles.tenantMargin}
                >
                  <Select placeholder={'请选择关系'}>
                    {relation.map(item => (
                      <Option value={item.id} key={item.id}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={'emergPersonMobile'}
                  label={'手机号'}
                  labelCol={{span: 10}}
                  className={styles.tenantMargin}
                >
                  <Input placeholder={'请输入手机号'} />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <Row style={{marginTop: 20}} justify={'center'}>
            <Space>
              <Button onClick={() => setShowForm(false)}>取消</Button>
              <Button type={'primary'} htmlType={'submit'}>
                确认
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

AddCheckin.defaultProps = {
  checkinList: [],
}
