import React, {useCallback} from 'react'
import {Badge, Col, Form, Modal, Row, Space, Switch, Tabs} from 'antd'
import CleanVideoPreview from '@/pages/steward/components/CleanVideoPreview'
import CleanImagePreview from '@/pages/steward/components/CleanImagePreview'

export function useCleanPreview() {
  return useCallback(({payloadList}: {payloadList: CleanOrderAttachPayloadDTO[]}) => {
    payloadList = payloadList || []
    const isSub = payloadList.every(item => item.subUpload)

    Modal.confirm({
      title: '保洁前后照片/视频',
      icon: null,
      closable: true,
      centered: true,
      okCancel: false,
      okType: 'link',
      okText: <span />,
      width: '75%',
      bodyStyle: {height: '600px', overflow: 'scroll'},
      okButtonProps: {
        onClick: () => null,
        style: {cursor: 'default'},
      },
      content: (
        <Tabs>
          {payloadList.map(item => {
            if (isSub) {
              return (
                <Tabs.TabPane tab={item.name} key={item.name}>
                  <Row>
                    <Col span={10} offset={2}>
                      <span style={{fontWeight: 500, color: 'rgba(0,0,0,0.85)'}}>保洁前照片/视频</span>
                    </Col>
                    <Col span={10}>
                      <span style={{fontWeight: 500, color: 'rgba(0,0,0,0.85)'}}>保洁后照片/视频</span>
                    </Col>
                  </Row>
                  {item.subList?.map((value, index1) => {
                    const mediaList = value.mediaList
                    return (
                      <Row key={index1} style={{marginTop: '15px'}}>
                        <Col span={2}>{value.name}</Col>
                        <Col span={10} style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                          <CleanImagePreview imgList={mediaList?.find(ele => ele.saveType === 1)?.imageList} />
                          <CleanVideoPreview videoList={mediaList?.find(ele => ele.saveType === 1)?.videoList} />
                        </Col>
                        <Col span={12} style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                          <CleanImagePreview imgList={mediaList?.find(ele => ele.saveType === 2)?.imageList} />
                          <CleanVideoPreview videoList={mediaList?.find(ele => ele.saveType === 2)?.videoList} />
                        </Col>
                      </Row>
                    )
                  })}
                </Tabs.TabPane>
              )
            } else {
              return (
                <Tabs.TabPane tab={item.name} key={item.name}>
                  <Row>
                    <Col span={10}>
                      <span style={{fontWeight: 500, color: 'rgba(0,0,0,0.85)'}}>保洁前照片/视频</span>
                    </Col>
                    <Col span={12}>
                      <span style={{fontWeight: 500, color: 'rgba(0,0,0,0.85)'}}>保洁后照片/视频</span>
                    </Col>
                  </Row>
                  <Row style={{marginTop: '15px'}}>
                    <Col span={10} style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                      <CleanImagePreview imgList={item.mediaList?.find(ele => ele.saveType === 1)?.imageList} />
                      <CleanVideoPreview videoList={item.mediaList?.find(ele => ele.saveType === 1)?.videoList} />
                    </Col>
                    <Col span={12} style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                      <CleanImagePreview imgList={item.mediaList?.find(ele => ele.saveType === 2)?.imageList} />
                      <CleanVideoPreview videoList={item.mediaList?.find(ele => ele.saveType === 2)?.videoList} />
                    </Col>
                  </Row>
                </Tabs.TabPane>
              )
            }
          })}
        </Tabs>
      ),
    })
  }, [])
}

const offlineQaResultCode = {'-1': '未处理', '0': '不合格', '1': '合格'}
const offlineQaResultStatusCode = {'-1': 'warning', '0': 'error', '1': 'success'}

export function useCleanOnlineQualified() {
  const [form] = Form.useForm()

  return useCallback(
    ({payloadList, isOnline = false}: {payloadList: CleanOrderAttachPayloadDTO[]; isOnline?: boolean}) => {
      payloadList = payloadList || []
      // console.log('payloadList', payloadList)
      Modal.confirm({
        title: (
          <Space size={30}>
            {isOnline ? '线上质检' : '现场质检'}
            <Space size={10}>
              <span style={{color: 'rgba(0, 0, 0, 0.85)', fontWeight: 400, fontSize: 14}}>仅显示不合格项</span>
              <Form form={form}>
                <Form.Item noStyle name={'isQualified'}>
                  <Switch defaultChecked={false} />
                </Form.Item>
              </Form>
            </Space>
          </Space>
        ),
        icon: null,
        closable: true,
        centered: true,
        okCancel: false,
        okType: 'link',
        okText: <span />,
        width: '75%',
        bodyStyle: {height: '600px', overflow: 'scroll'},
        okButtonProps: {
          onClick: () => null,
          style: {cursor: 'default'},
        },
        afterClose: () => form.resetFields(),
        content: (
          <Form form={form}>
            <Form.Item shouldUpdate noStyle>
              {form1 => {
                const isQualified = form1.getFieldValue('isQualified')
                const toRenderList = isQualified
                  ? payloadList?.reduce((acc, cur) => {
                      const subList = cur?.subList?.filter(value =>
                        isOnline ? value.onlineQaResult === 0 : value.offlineQaResult === 0
                      )
                      return acc.concat([{...cur, subList}])
                    }, [])
                  : payloadList
                const isSub = toRenderList?.every(item => item.subUpload)

                // console.log('toRenderList', toRenderList)

                return (
                  <Tabs>
                    {toRenderList.map((item, index) => {
                      if (isSub) {
                        return (
                          <Tabs.TabPane tab={item.name} key={index}>
                            <Row gutter={20}>
                              <Col span={6} offset={2}>
                                <span style={{fontWeight: 500, color: 'rgba(0,0,0,0.85)'}}>保洁前照片/视频</span>
                              </Col>
                              <Col span={6}>
                                <span style={{fontWeight: 500, color: 'rgba(0,0,0,0.85)'}}>保洁后照片/视频</span>
                              </Col>
                              {!isOnline && (
                                <Col span={4}>
                                  <span style={{fontWeight: 500, color: 'rgba(0,0,0,0.85)'}}>现场质检照片</span>
                                </Col>
                              )}
                              <Col span={4}>
                                <span style={{fontWeight: 500, color: 'rgba(0,0,0,0.85)'}}>不合格原因</span>
                              </Col>
                            </Row>

                            {item.subList?.map((value, index1) => {
                              const mediaList = value.mediaList
                              return (
                                <Row key={index1} style={{marginTop: '15px'}} gutter={20}>
                                  <Col span={2}>{value.name}</Col>
                                  <Col span={6} style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                                    <CleanImagePreview
                                      imgList={mediaList?.find(ele => ele.saveType === 1)?.imageList}
                                    />
                                    <CleanVideoPreview
                                      videoList={mediaList?.find(ele => ele.saveType === 1)?.videoList}
                                    />
                                  </Col>
                                  <Col span={6} style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                                    <CleanImagePreview
                                      imgList={mediaList?.find(ele => ele.saveType === 2)?.imageList}
                                    />
                                    <CleanVideoPreview
                                      videoList={mediaList?.find(ele => ele.saveType === 2)?.videoList}
                                    />
                                  </Col>
                                  {!isOnline && (
                                    <Col span={4} style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                                      <CleanImagePreview imgList={value.offlineImageList} />
                                    </Col>
                                  )}
                                  <Col span={4}>
                                    {isOnline
                                      ? value.onlineQaResult === 0 && value.onlineQaNotes
                                      : value.offlineQaResult === 0 && value.offlineQaNotes}
                                  </Col>
                                  <Col span={2}>
                                    {isOnline ? (
                                      <Badge
                                        status={offlineQaResultStatusCode[value.onlineQaResult]}
                                        text={offlineQaResultCode[value.onlineQaResult]}
                                      />
                                    ) : (
                                      <Badge
                                        status={offlineQaResultStatusCode[value.offlineQaResult]}
                                        text={offlineQaResultCode[value.offlineQaResult]}
                                      />
                                    )}
                                  </Col>
                                </Row>
                              )
                            })}
                          </Tabs.TabPane>
                        )
                      } else {
                        const isSign = item.name === '签到表'
                        return (
                          <Tabs.TabPane tab={item.name} key={index}>
                            {!isSign && (
                              <Row>
                                <Col span={12}>
                                  <span style={{fontWeight: 500, color: 'rgba(0,0,0,0.85)'}}>保洁前视频</span>
                                </Col>
                                <Col span={12}>
                                  <span style={{fontWeight: 500, color: 'rgba(0,0,0,0.85)'}}>保洁后视频</span>
                                </Col>
                              </Row>
                            )}

                            <Row style={{marginTop: '15px'}}>
                              <Col span={12} style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                                {isSign && (
                                  <CleanImagePreview
                                    imgList={item.mediaList?.find(ele => ele.saveType === 1)?.imageList}
                                  />
                                )}
                                <CleanVideoPreview
                                  videoList={item.mediaList?.find(ele => ele.saveType === 1)?.videoList}
                                />
                              </Col>
                              <Col span={12} style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                                {isSign && (
                                  <CleanImagePreview
                                    imgList={item.mediaList?.find(ele => ele.saveType === 2)?.imageList}
                                  />
                                )}
                                <CleanVideoPreview
                                  videoList={item.mediaList?.find(ele => ele.saveType === 2)?.videoList}
                                />
                              </Col>
                            </Row>
                            <Space direction={'vertical'} size={20} style={{marginTop: '20px'}}>
                              {item.subList?.map(value => (
                                <Space direction={'vertical'} key={value.name}>
                                  <Space>
                                    <span>{value.name}</span>
                                    {isOnline ? (
                                      <Badge
                                        status={offlineQaResultStatusCode[value.onlineQaResult]}
                                        text={offlineQaResultCode[value.onlineQaResult]}
                                      />
                                    ) : (
                                      <Badge
                                        status={offlineQaResultStatusCode[value.offlineQaResult]}
                                        text={offlineQaResultCode[value.offlineQaResult]}
                                      />
                                    )}
                                  </Space>
                                  {(value.offlineQaResult === 0 || value.onlineQaResult === 0) && (
                                    <>
                                      <Space>
                                        <span>不合格原因：</span>
                                        <span>{isOnline ? value.onlineQaNotes : value.offlineQaNotes}</span>
                                      </Space>
                                      <CleanImagePreview imgList={value.offlineImageList} />
                                    </>
                                  )}
                                </Space>
                              ))}
                            </Space>
                          </Tabs.TabPane>
                        )
                      }
                    })}
                  </Tabs>
                )
              }}
            </Form.Item>
          </Form>
        ),
      })
    },
    [form]
  )
}
