import {Model} from '@redux-model/web'
import {$api} from '@/store/service'
import {isProjectCondo} from '@/common/bizHooks/isProjectCondo'

// export const rentTypeMap = [
//   {title: '合租', key: '2'},
//   {title: '整租', key: '1'},
//   {title: '独栋', key: '3'},
// ]
export const rentTypeMap = [
  {title: '分散式房源', key: '1'}, // 1,2 整租、合租
  {title: '集中式公寓', key: '2'}, // 3 独栋
]

export interface PropertyModel {
  rentMode: string
  projects: CondoProjectVO[]
}

class Property extends Model<PropertyModel> {
  protected initReducer(): PropertyModel {
    return {
      rentMode: sessionStorage.getItem('rentMode') || rentTypeMap[0].key,
      projects: null,
    }
  }

  setRentMode = this.action((state, payload: string) => {
    state.rentMode = payload
  })

  /**
   * 查询录入过的小区（小区列表）
   */
  // propertyCommunityList = $api.action(
  //   (args: {communityType?: '1' | '2'; /*分散|集中*/ keyword?: string; teamId?: string}) =>
  //     this.post<ApiResponse<CommunityVO[]>>('/property/community-list').body(args)
  //   // .throttle(10 * 1000, true)
  // )

  propertyCommunityList = $api.action((args: Partial<PropertyCommunityInsertedDTO> = {}) =>
    this.post<ApiResponse<CommunityVO[]>>('/property/community-list').body(args)
  )

  // 房态图-房源详情-删除房源
  propertyDelete = $api.action((args: {propertyCode: string}) => this.post('/property/delete').body(args))

  // 房态图-房源详情-修改房源状态
  propertyRentStatusChange = $api.action((args: {propertyCode: string; rentStatus: string}) =>
    this.post('/property/rent-status/change').body(args)
  )

  // 房态图-房源详情-修改房源备注
  propertyRemarkUpdate = $api.action((args: {propertyCode: string; remark: string}) =>
    this.post('/property/remark/update').body(args)
  )

  // 房态图-房源详情-修改房源描述
  propertyDescUpdate = $api.action((args: {propertyCode: string; desc: string}) =>
    this.post('/property/desc/update').body(args)
  )

  // 省,市,区,商圈码表  |  type: 1:省 2:市 3:区 4:商圈,parentCode:父级code(省可空)
  queryCityCode = $api.action((args: {type: '1' | '2' | '3' | '4'; parentCode?: string}) =>
    this.post<ApiResponse<Record<string, any>[]>>('/common/query-city-code').body(args)
  )

  // 逆地理编码
  commonMapGeocoder = $api.action((args: {lat: number; lng: number}) =>
    this.post<ApiResponse<BaiduMapGeoCoderVO>>('/common/map/geocoder').body(args)
  )

  // 小区搜索
  communitySearch = $api.action((args: SysCommunitySearchDTO) =>
    this.post<ApiResponse<CommunityVO[]>>('/community/search').body(args)
  )

  /**
   * 发布房源-分散式房源-选择小区-查询录入过的小区
   * @deprecated
   */
  scatterCommunityList = $api.action((args: {limit?: number; offset?: number} & Partial<Pageable>) =>
    this.post<ApiResponse<CommunityVO[]>>('/scatter/community-list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  /**
   * 房态图-分散式房源列表
   * @deprecated
   */
  // scatterPropertyChartList = $api.action((args: Partial<PropertyChartDTO>) =>
  //   this.post<ApiResponse<ScatterPropertyChartVO[]>>('/scatter/property-chart/list').body({
  //     pageNum: 1,
  //     pageSize: 10,
  //     pageable: true,
  //     ...args,
  //   })
  // )

  // 房态图-分散式房源详情
  scatterPropertyChartDetail = $api.action((args: {propertyCode: string}) =>
    this.post<ApiResponse<ScatterPropertyChartDetail>>('/scatter/property/detail').body(args)
  )
  // 房态图-房态图列表
  propertyPropertyChartList = $api.action((args: Partial<PropertyChartDTO>) =>
    this.post<ApiResponse<PropertyChartVO[]>>('/property/property-chart/list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // 房态图-房源详情-修改门牌号
  scatterPropertyDetailUpdate = $api.action(
    (args: {propertyCode: string; buildingNo: string; unitNo: string; houseNo: string}) =>
      this.post('/scatter/property/detail/update').body(args)
  )

  // 分散式房源-房源信息
  scatterPropertyInfo = $api.action((args: {propertyCode: string; rentType: '1' | '2' | '3'}) =>
    this.post<
      ApiResponse<{
        rentType: '1' | '2' | '3'
        scatterJoinRentVO: JointPropertyDTO
        scatterWholeRentVO: WholePropertyInfoUpdate
      }>
    >('/scatter/property/info').body(args)
  )

  // 分散式房源-房源信息修改
  scatterPropertyUpdate = $api.action(args => this.post<ApiResponse>('/scatter/property/info/update').body(args))

  // 房态图-房源详情-编辑图片
  scatterPropertyImgUpload = $api.action((args: Partial<ScatterImgDTO>) =>
    this.post<ApiResponse<JointPropertyDTO | WholePropertyInfoUpdate>>('/scatter/property-public/img/update').body(args)
  )

  // 房态图-分散式房源（合租）-房源详情-添加房间
  scatterJointPropertyRoomInsert = $api.action((args: JointPropertyRoomDTO) =>
    this.post('/scatter/joint-property/room/insert').body(args)
  )

  // 发布房源-分散式房源-保存房源信息
  scatterPropertyInsert = $api.action((args: PropertyInfoDTO) =>
    this.post<ApiResponse>('/scatter/property/insert').body(args)
  )

  // 房态图-分散式房源（合租）-房源详情-添加房间-房间总数查询
  scatterJointPropertyRoomCount = $api.action((args: {propertyCode: string}) =>
    this.post<ApiResponse>('/scatter/joint-property/room/count').body(args)
  )

  // 集中式公寓：房态图
  // condoPropertyChartList = $api.action((args: Partial<PropertyChartDTO>) =>
  //   this.post<ApiResponse<ScatterPropertyChartList>>('/condo/property-chart/list').body({
  //     pageNum: 1,
  //     pageSize: 10,
  //     pageable: true,
  //     ...args,
  //   })
  // )

  // 房态图-集中式公寓房源详情
  condoPropertyDetail = $api.action((args: {propertyCode: string}) =>
    this.post<ApiResponse<CondoPropertyDetail>>('/condo/query/property/detail').body(args)
  )

  // 房态图-房源详情-模板列表
  condoTemplateList = $api.action((args: {templateId: string}) =>
    this.post<ApiResponse<PropertyChangeTemp>>('/condo/template/list').body(args)
  )

  // 房态图-房源详情-更换房间模板
  condoPropertyChangeTemplate = $api.action((args: {propertyCode: string; templateId: string}) =>
    this.post('/condo/property/change/template').body(args)
  )

  // 房态图-房源详情-更换房间模板-添加模板
  condoManageInsertTemplate = $api.action((args: CondoPropertyTemplateDTO[]) =>
    this.post('/condo/manage/insert/template').body(args)
  )

  // 集中式房源-房源信息
  condoPropertyInfo = $api.action((args: {propertyCode: string}) =>
    this.post<ApiResponse<CondoQueryPropertyInfo>>('/condo/query/property/info').body(args)
  )

  // 集中式房源-房源信息修改
  condoPropertyUpdate = $api.action(args => this.post('/condo/property/update').body(args))

  // 管理公寓-公寓信息
  condoManageInfo = $api.action((args: {condoId: string}) =>
    this.post<ApiResponse<CondoManageInfoVO>>('/condo/manage/info').body(args).metas(args.condoId)
  )

  // 管理公寓-公寓信息修改
  condoManageInfoUpdate = $api.action((args: CondoManageInfoUpdateDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/condo/manage/info/update').body(args)
  )

  // 公寓楼层信息  |  condoId
  condoManageInsertPropertyFloorList = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<CondoInsertPropertyFloorListVO[]>>('/condo/manage/insert-property/floor-list').body(args)
  )

  // 管理公寓-添加房间-房间信息设置-新增房间到公寓
  condoManageInsertProperty = $api.action((args: CondoInsertPropertyDTO) =>
    this.post<ApiResponse>('/condo/manage/insert-property').body(args)
  )

  // 管理公寓-添加房间-房间模板信息列表
  condoManageInsertPropertyTemplateList = $api.action((args: {condoId?: string}) =>
    this.post<ApiResponse<CondoManageTempVOList[]>>('/condo/manage/insert-property/template-list').body(args)
  )

  // 集中式房源-图片修改
  condoPropertyImgUpdate = $api.action((args: CondoPropertyImgDTO[]) =>
    this.post('/condo/property/img/update').body(args)
  )

  // 管理公寓-房间模板详情
  condoManageTemplateInfo = $api.action((args: {templateId: string}) =>
    this.post<ApiResponse<CondoManageTemplateInfoVO>>('/condo/manage/template/info').body(args)
  )

  // 管理公寓-房间模板修改
  condoManageTemplateUpdate = $api.action((args: CondoManageTemplateInfoVO) =>
    this.post('/condo/manage/template/update').body(args)
  )

  // 管理公寓-删除模板
  condoDeleteTemplate = $api.action((args: {templateId: string}) => this.post('/condo/delete/template').body(args))

  // 集中式房源-添加新公寓
  condoInsert = $api.action((args: CondoPropertyInsertDTO) => this.post('/condo/insert').body(args))

  // 首页房屋看板
  homepagePropertyStatistics = $api.action(() =>
    this.post<ApiResponse<HomepagePropertyBoardVO>>('/property/homepage/statistics')
  )

  // 管理公寓：公共区域图片列表
  condoPublicImgList = $api.action((condoId: string) =>
    this.post<ApiResponse<ImageResult[]>>('/condo/public/img/list').body({condoId})
  )

  // 管理公寓-公寓公共图片修改
  condoPublicImgUpdate = $api.action((args: (ImageResult & {condoId: string})[]) =>
    this.post<ApiResponse>('/condo/public/img/update').body(args)
  )

  // 房源查询建议
  propertySearchSuggestion = $api.action((args: Partial<PropertySuggestDTO>) =>
    this.post<ApiResponse<PropertyInfoVO[]>>('/property/search-suggestion').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // 房源搜索
  propertySearch = $api.action((args: Partial<PropertySearchDTO>) =>
    this.post<ApiResponse<PropertySearchVO[]>>('/property/search').body(args)
  )

  // 房东web-信息卡片
  propertyCard = $api.action((args: {propertyCode: string}) =>
    this.post<ApiResponse<PropertyInfoCardVO>>('/property/web/property/card').body(args)
  )
  // 查询房源详情  |  propertyCode
  propertyDetail = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<PropertyAllInfoVO>>('/property/detail').body(args)
  )

  // 房源更新
  propertyUpdate = $api.action((args: any) => this.post<ApiResponse>('/property/update').body(args))

  // 管理公寓-添加房间-房间模板信息列表
  templateList = $api.action((condoId: string) =>
    this.post<ApiResponse<CondoManageTempVO[]>>('/condo/manage/insert-property/template-list').body({
      condoId,
    })
  )

  // 空房快查
  propertyUnrentQuickSearch = $api.action((args: PropertyUnrentQuickSearchDTO) =>
    this.post<ApiResponse<PropertyUnrentQuickSearchVO[]>>('/property/unrent/quick-search').body({
      pageable: true,
      pageNum: 1,
      pageSize: 10,
      ...args,
    })
  )

  // 迁移房源对应的小区  |  from:待迁移小区code,to:目的迁移小区code
  propertyMigrateCommunity = $api.action((args: any) =>
    this.post<ApiResponse>('/property/migrate-community').body(args)
  )

  // 修改转租状态  |  object:{propertyCode:房源code,sublease:是否转租中（1：是、2：否）}
  propertySubleaseUpdate = $api.action((args: {propertyCode: string; sublease: string}) =>
    this.post<ApiResponse>('/property/sublease/update').body(args)
  )

  // 获取抄表配置
  propertyMeterGetConfig = $api.action((args: {teamId?: string; communityCode?: string; propertyCode?: string}) =>
    this.post<ApiResponse<PropertyMeterConfigVO>>('/property/meter/get-config').body(args)
  )

  // 设置抄表配置
  propertyMeterSetConfig = $api.action((args: Partial<PropertyMeterConfigSetDTO>) =>
    this.post('/property/meter/set-config').body(args)
  )

  // 设备列表
  propertyMeterList = $api.action((args: PropertyMeterQueryDTO) =>
    this.post<ApiResponse<PropertyMeterVO[]>>('/property/meter/list').body(args)
  )

  // 生成抄表记录
  propertyMeterRecordInsert = $api.action((args: PropertyMeterRecordInsertDTO[]) =>
    this.post('/property/meter/record/insert').body(args)
  )

  // 抄表记录列表
  propertyMeterRecordList = $api.action((args: Partial<PropertyMeterRecordQueryDTO>) =>
    this.post<ApiResponse<PropertyMeterRecordVO[]>>('/property/meter/record/list').body(args)
  )

  // 待生成账单-小区数据统计
  propertyMeterRecordCommunityCount = $api.action(() =>
    this.post<ApiResponse>('/property/meter/record/community-count')
  )

  // 抄表记录生成账单Bill
  propertyMeterRecordCreateBill = $api.action((args: string[]) =>
    this.post('/property/meter/record/create-bill').body(args)
  )

  // 丢弃抄表记录
  propertyMeterRecordDiscard = $api.action((args: string[]) => this.post('/property/meter/record/discard').body(args))

  // 查询账单逾期的房源
  propertyListPropertyByOverdueBill = $api.action((args: Partial<PropertyOverdueBillDTO>) =>
    this.post<ApiResponse<PropertyOverdueBillVO[]>>('/property/list-property-by-overdue-bill').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // 新增公寓分步校验
  condoInsertStepCheck = $api.action((args: Partial<CondoPropertyInsertDTO>) =>
    this.post<ApiResponse>('/condo/insert/step-check').body(args)
  )

  // 查询客户房源推广设置  |  object:{userId:用户id}
  lessorPropertyPromote = $api.action((args: {userId: string}) =>
    this.post<ApiResponse<LessorPropertyPromoteConfigVO>>('/lessor/property-promote').body(args)
  )

  // 推广房源列表
  propertyPromoteList = $api.action((args: PropertyForPromoteListDTO) =>
    this.post<ApiResponse<PropertyForPromoteVO[]>>('/property/promote/list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // 推广房源记录列表  |  object:{propertyCode:房源编号}
  propertyPromoteRecordList = $api.action((args: {propertyCode: string}) =>
    this.post<ApiResponse<PropertyPromoteRecordVO[]>>('/property/promote/record/list').body(args)
  )

  // 推广房源-房源自动推广
  propertyPromoteAutoConfig = $api.action((args: PropertyPromoteAutoConfigDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/property/promote/auto/config').body(args)
  )

  // 推广房源-房源下架
  propertyPromotePullOff = $api.action((args: string[]) =>
    this.post<ApiResponse<Record<string, any>>>('/property/promote/pull-off').body(args)
  )

  // 推广房源-房源上架
  propertyPromotePutOn = $api.action((args: string[]) =>
    this.post<ApiResponse<Record<string, any>>>('/property/promote/put-on').body(args)
  )

  // // 集中式项目-根据房源code查询项目配置  |  object:{propertyCode:房源code}
  // condoProjectGetProjectByPropertyCode = $api.action((args: {propertyCode: string}) =>
  //   this.post<ApiResponse<CondoProjectVO>>('/condo/project/get-project-by-property-code').body(args)
  // )

  // 集中式项目-查询所有的项目配置，和 teamId 无关
  condoProjectListProject = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<CondoProjectVO[]>>('/condo/project/list-project')
      .body(args)
      .onSuccess((state, action) => {
        state.projects = action.response.data
      })
  )

  // 集中式项目的配置(运营设置,房源优惠活动,营业科目[支付渠道,账单科目])  |  object:{condoId:公寓id}
  condoProjectGetConfigByCondoId = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<CondoProjectConfigAssemblyVO>>('/condo/project/get-config-by-condoId')
      .body(args)
      .throttle(1000 * 60 * 60, true)
  )

  // 聚合接口: 集中式项目 -> 新接口，非集中式公寓；分散式 -> 老接口
  // 集中式项目的配置(运营设置,房源优惠活动,营业科目[支付渠道,账单科目])
  projectConfig = $api.action((args: {condoId?: string}) => {
    return isProjectCondo()
      ? this.post<ApiResponse<LessorConfigVO>>('/condo/project/get-config-by-condoId').body({condoId: args.condoId})
      : this.post<ApiResponse<LessorConfigVO>>('/lessor/config/contract/list-selected')
  })

  // 集中式项目-根据房源code查询项目配置  |  object:{propertyCode:房源code}
  /**
   * @deprecated
   */
  condoProjectGetProjectByPropertyCode = $api.action((args: {propertyCode: string}) =>
    this.post<ApiResponse<CondoProjectVO>>('/condo/project/get-project-by-property-code')
      .body(args)
      .throttle(1000 * 60 * 60, true)
  )

  // 查询房东房源统计
  propertyStatisticsIncomeList = $api.action((args: PropertyIncomeStatisticsListDTO) =>
    this.post<ApiResponse<PropertyIncomeStatisticsVO[]>>('/property/statistics/income/list').body(args)
  )

  // 更新空置状态  |  object:{propertyCode:房源编号, emptyStatus:空置状态（1：空净、2：空脏）}
  propertyEmptyStatusUpdate = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/property/empty-status/update').body(args)
  )

  // 推广房源修改房源信息  |  object:{propertyCode:房源编号，decoration 装修情况（property.decoration），contactMobile 联系电话}
  propertyPromoteUpdateProperty = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<PropertyPromoteRecordVO[]>>('/property/promote/update-property').body(args)
  )
}

export const propertyModel = new Property()
