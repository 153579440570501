import {Model} from '@redux-model/web'
import {$api} from '@/store/service'

export interface TeamModel {}

class Team extends Model<TeamModel> {
  protected initReducer(): TeamModel {
    return {}
  }

  // 团队管理-团队架构
  // queryTeamTrees = $api.action(() => this.post<ApiResponse<TeamTreesVO[]>>('/team/query-my-team-trees'))

  // 团队管理-查询组织或团队成员
  queryTeamMember = $api.action((args: {teamId: string}) =>
    this.post<ApiResponse<TeamMemberInfo[]>>('/team/query-my-team-member').body(args)
  )

  // 团队管理-增加成员
  teamMemberUpsert = $api.action((args: TeamUpsertDTO) => this.post('/team/member/upsert').body(args))

  // 团队管理-新增组织
  teamPinsert = $api.action((args: {name: string}) => this.post('/team/p-insert').body(args))

  // 团队管理-新增团队
  teamCinsert = $api.action((args: {teamId: string; name: string}) => this.post('/team/c-insert').body(args))

  // 团队管理-重命名
  teamRename = $api.action((args: {teamId: string; name: string}) => this.post('/team/rename').body(args))

  // 团队管理-删除团队或组织
  teamDelete = $api.action((args: {teamId: string}) => this.post('/team/delete').body(args))

  // 团队管理-修改成员权限
  teamMemberRoleUpdate = $api.action(
    (args: {teamPid: string; teamRole: string; teamUserId: string; teamIdList: string[]}) =>
      this.post('/team/member/role/update').body(args)
  )

  // 团队管理-更换成员团队
  teamMemberTeamUpdate = $api.action((args: {teamIdList: string[]; teamUserId: string}) =>
    this.post('/team/member/team/update').body(args)
  )

  // 团队管理-删除成员
  teamMemberDelete = $api.action((args: {teamUserId: string}) => this.post('/team/member/delete').body(args))

  // 企业认证-记录-提交
  teamAuthRecordInsert = $api.action((args: TeamAuthSubmitDTO) => this.post('/team/auth/record/insert').body(args))

  // 企业认证-记录-详情
  teamAuthRecordDetail = $api.action((args: {recordId: string}) =>
    this.post<ApiResponse<TeamAuthRecordVO>>('/team/auth/record/detail').body(args)
  )

  // 查询组织账户余额
  teamBalance = $api.action(() => this.post<ApiResponse<number>>('/team/balance'))

  // // 扣款记录列表
  // teamBalanceRecordList = $api.action((args: Partial<CustomerRecordListDTO>) =>
  //   this.post<ApiResponse<CustomerRecordListVO[]>>('/team/balance/record/list').body(args)
  // )

  // // 充值记录
  // teamChargeList = $api.action((args: Partial<LessorChargeListDTO>) =>
  //   this.post<ApiResponse<LessorChargeVO[]>>('/team/charge/list').body(args)
  // )

  // // 对公账户服务费充值
  // teamChargeInsert = $api.action((args: LessorChargePendingDTO) => this.post('/team/charge/insert').body(args))

  // 组织成员-删除成员 | object:{userId:成员用户编号}
  teamV2MemberDelete = $api.action((args: object) =>
    this.post<ApiResponse<object>>('/team-v2/member/delete').body(args)
  )

  // 组织成员-成员详情 | object:{userId:成员用户编号}
  teamV2MemberDetail = $api.action((args: object) =>
    this.post<ApiResponse<TeamMemberVO>>('/team-v2/member/detail').body(args)
  )

  // 组织成员-编辑成员
  teamV2MemberEdit = $api.action((args: TeamMemberUpdateDTO) =>
    this.post<ApiResponse<object>>('/team-v2/member/edit').body(args)
  )

  // 组织成员-添加成员
  teamV2MemberInsert = $api.action((args: TeamMemberInsertDTO) =>
    this.post<ApiResponse<object>>('/team-v2/member/insert').body(args)
  )

  // 岗位角色-岗位列表
  teamV2RoleList = $api.action((args: object) => this.post<ApiResponse<TeamRoleVO[]>>('/team-v2/role/list').body(args))

  // 岗位角色-查询所有的权限列表树
  teamV2RoleListAllPermissionTree = $api.action((args: object) =>
    this.post<ApiResponse<TeamPermissionTreeNodeVO[]>>('/team-v2/role/list-all-permission-tree').body(args)
  )

  // 岗位角色-根据岗位查询成员列表 | notes:{teamRole:岗位编号}
  teamV2RoleListMember = $api.action((args: object) =>
    this.post<ApiResponse<TeamUserVO[]>>('/team-v2/role/list-member').body(args)
  )

  // 岗位角色-新增/编辑岗位
  teamV2RoleSave = $api.action((args: TeamRoleSaveDTO) =>
    this.post<ApiResponse<string>>('/team-v2/role/save').body(args)
  )

  // 组织架构-删除组织/团队 | object:{teamId:组织/团队id}
  teamV2TeamDelete = $api.action((args: object) => this.post<ApiResponse<object>>('/team-v2/team/delete').body(args))

  // 组织架构-组织架构tree
  teamV2TeamGetTree = $api.action((args: object) =>
    this.post<ApiResponse<TeamTreeNodeVO[]>>('/team-v2/team/get-tree').body(args)
  )

  // 组织架构-新增组织/团队 | object:{teamPid:组织id(可空),teamName:名称}
  teamV2TeamInsert = $api.action((args: object) => this.post<ApiResponse<object>>('/team-v2/team/insert').body(args))

  // 组织架构-根据组织查询成员列表 | object:{teamId:组织/团队id}
  teamV2TeamListMember = $api.action((args: {teamId?: string}) =>
    this.post<ApiResponse<TeamUserVO[]>>('/team-v2/team/list-member').body(args)
  )

  // 组织架构-重命名组织/团队 | object:{teamId:组织/团队id,teamName:名称}
  teamV2TeamRename = $api.action((args: object) => this.post<ApiResponse<object>>('/team-v2/team/rename').body(args))
}

export const teamModel = new Team()
