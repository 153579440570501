import React, {useCallback, useEffect, useState} from 'react'
import {Button, Form, Space, Table} from 'antd'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import nygDeviceStyle from '../device.module.less'
import DeviceTableHeader from '@/pages/device/components/DeviceTableHeader'
import {useAppHistory} from '@/common/hooks/useRouter'
import NygDeviceTableForm from '@/pages/nygDevice/components/NygDeviceTableForm'
import NygDeviceHistory from '@/pages/nygDevice/components/NygDeviceHistory'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {deviceModel} from '@/store/models/Device'

export default function NygDeviceLock() {
  const history = useAppHistory()

  const [form] = Form.useForm()
  const [total, setTotal] = useState(0)
  const [lockList, setLockList] = useState<Lock[]>([])

  const [deviceId, setDeviceId] = useState('')

  const queryLockList = useCallback(() => {
    const formValues = form.getFieldsValue(true)
    // console.log('formValues', formValues)
    deviceModel.deviceV2LockList({...formValues}).then(({response: {data, total}}) => {
      // console.log('data', data)
      // console.log('total', total)
      setLockList(data)
      setTotal(total)
    })
  }, [form])

  useEffect(() => {
    queryLockList()
  }, [queryLockList])

  const lockLoading = deviceModel.deviceV2LockList.useLoading()

  return (
    <div>
      <Breadcrumb path={['首页', '智能设备', '门锁']} />
      <NygDeviceTableForm form={form} onFinish={queryLockList} onReset={queryLockList} />
      <div className={nygDeviceStyle.tableSection}>
        <DeviceTableHeader total={total} />
        <Table
          rowKey={'deviceId'}
          columns={[
            {title: '设备名称/ID', dataIndex: 'deviceId'},
            {title: '房源地址', dataIndex: 'propertyTitle'},
            {title: '承租人', dataIndex: 'lesseeName', render: value => value ?? '/'},
            {title: '承租人手机号', dataIndex: 'lesseeMobile', render: value => value ?? '/'},
            {title: '上次查询时间', dataIndex: 'syncTime'},
            {
              title: '操作',
              dataIndex: 'deviceId',
              render: value => {
                return (
                  <Space>
                    <Button
                      type={'link'}
                      style={{padding: 0}}
                      onClick={() => {
                        history.push('/nyg-device-lock/detail', {deviceId: value})
                      }}
                    >
                      查看
                    </Button>
                    <Button
                      type={'link'}
                      style={{padding: 0}}
                      onClick={() => {
                        setDeviceId(value)
                      }}
                    >
                      历史信息
                    </Button>
                  </Space>
                )
              },
            },
          ]}
          dataSource={lockList}
          loading={lockLoading}
          pagination={getPaginationProps(form, total)}
        />
      </div>

      <NygDeviceHistory deviceId={deviceId} visible={!!deviceId} onCancel={() => setDeviceId('')} />
    </div>
  )
}
