import React, {useCallback, useEffect, useState} from 'react'
import {Button, Col, DatePicker, Divider, Form, Pagination, Row, Select, Space, Table, Tabs, Typography} from 'antd'
import {useCodeArraySync} from '@/store/models/Common'
import {contractModel} from '@/store/models/Contract'
import moment from 'moment'
import {contract} from '@/utils/render'

interface ContractDetailAccountingProps {
  contractId: string
  // 为了和外部导出功能关联
  form: ReturnType<typeof Form.useForm>[0]
}

const tabs = [
  {label: '账务明细', key: '0'},
  {label: '确认收入', key: '1'},
]

const ContractDetailAccounting: React.FC<ContractDetailAccountingProps> = props => {
  const form = props.form

  const operationType = useCodeArraySync('contract.account.operationType')

  const [activeKey, setActiveKey] = useState(tabs[0].key)

  const [dataSource, setDataSource] = useState<ContractAccountVO[]>([])
  const [total, setTotal] = useState(0)
  const loading = contractModel.contractAccountList.useLoading()
  const search = useCallback(async () => {
    const value: ContractAccountQueryDTO = form.getFieldsValue(true)
    const {
      response: {data, total},
    } = await contractModel.contractAccountList(value)
    setTotal(total)
    setDataSource(data)
  }, [form])

  useEffect(() => {
    search()
  }, [search])

  // 确认收入
  const [accountConfirm, setAccountConfirm] = useState<PairModel[]>([])
  useEffect(() => {
    contractModel
      .contractAccountGroupConfirmIncomeByMonth({contractId: props.contractId} as ContractAccountQueryDTO)
      .then(({response: {data}}) => setAccountConfirm(data))
  }, [props.contractId])

  return (
    <>
      <Form
        labelCol={{flex: '71px'}}
        initialValues={{pageNum: 1, pageSize: 10, contractId: props.contractId}}
        form={form}
        onFinish={search}
        onReset={search}
      >
        <Row>
          <Col span={24}>
            <Form.Item label={'账务'}>
              <Button.Group>
                {tabs.map(value => (
                  <Button
                    type={value.key === activeKey ? 'primary' : 'text'}
                    key={value.key}
                    onClick={() => setActiveKey(value.key)}
                  >
                    {value.label}
                  </Button>
                ))}
              </Button.Group>
            </Form.Item>
          </Col>
        </Row>
        <Tabs activeKey={activeKey} renderTabBar={() => null}>
          <Tabs.TabPane tab={tabs[0].label} key={tabs[0].key}>
            <div style={{marginBottom: 20 * 2}}>
              <Row gutter={30}>
                <Col span={12}>
                  <Form.Item label={'发生时间'}>
                    <Row gutter={10} wrap={false}>
                      <Col flex={1}>
                        <Form.Item
                          noStyle
                          name={'recordTimeBegin'}
                          getValueProps={value => ({value: value ? moment(value) : value})}
                          getValueFromEvent={(_, format) => format}
                        >
                          <DatePicker
                            style={{width: '100%'}}
                            showTime={{defaultValue: moment('00:00:00', 'HH:mm:ss')}}
                            disabledDate={(date: moment.Moment) => {
                              const recordTimeEnd = form.getFieldValue('recordTimeEnd')
                              if (recordTimeEnd) {
                                return date.isAfter(recordTimeEnd, 'date')
                              }
                              return false
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col flex={'30px'} style={{display: 'flex', alignItems: 'center'}}>
                        <Divider style={{margin: 0}} />
                      </Col>
                      <Col flex={1}>
                        <Form.Item
                          noStyle
                          name={'recordTimeEnd'}
                          getValueProps={value => ({value: value ? moment(value) : value})}
                          getValueFromEvent={(_, format) => format}
                        >
                          <DatePicker
                            style={{width: '100%'}}
                            showTime={{defaultValue: moment('00:00:00', 'HH:mm:ss')}}
                            disabledDate={(date: moment.Moment) => {
                              const recordTimeBegin = form.getFieldValue('recordTimeBegin')
                              if (recordTimeBegin) {
                                return date.isBefore(recordTimeBegin, 'date')
                              }
                              return false
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label={'收支'} name={'recordAmtSign'}>
                    <Select
                      placeholder={'请选择'}
                      allowClear
                      options={['+', '-'].map(value => ({label: value, value: value}))}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label={'操作类型'} name={'operationType'}>
                    <Select
                      placeholder={'请选择'}
                      allowClear
                      options={operationType.map(value => ({label: value.label, value: value.id}))}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify={'end'}>
                <Space>
                  <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                    查询
                  </Button>
                  <Button htmlType={'reset'}>重置</Button>
                </Space>
              </Row>
            </div>
            <div>
              <Table
                title={() => (
                  <Space size={21}>
                    <Typography.Title level={3} style={{marginBottom: 0}}>
                      查询结果
                    </Typography.Title>
                    <Typography.Text>
                      共搜索到
                      <span style={{color: '#007BFF'}}>{total}</span>
                      条数据
                    </Typography.Text>
                  </Space>
                )}
                loading={loading}
                scroll={{x: 'max-content'}}
                dataSource={dataSource}
                rowKey={'recordId'}
                columns={[
                  {title: '租约编号', dataIndex: 'contractId'},
                  {title: '房源标题', dataIndex: 'propertyTitle'},
                  {title: '承租人', dataIndex: 'lesseeName'},
                  {title: '账单科目', dataIndex: 'billTypeName'},
                  {title: '账单周期', render: (_, record) => `${record.billBegin}至${record.billEnd}`},
                  {title: '收支', dataIndex: 'recordAmt', render: text => contract.recordAmtName(text)},
                  {title: '金额（元）', dataIndex: 'recordAmt'},
                  {title: '发生时间', dataIndex: 'recordTime'},
                  {title: '支付通道', dataIndex: 'billPayWayName', render: text => text ?? '/'},
                  {title: '操作类型', dataIndex: 'operationTypeName'},
                  {title: '操作人', dataIndex: 'operatorName'},
                ]}
                pagination={false}
              />
              <Row justify={'center'} style={{marginTop: '24px'}}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prev, next) => prev.pageSize !== next.pageSize || prev.pageNum !== next.pageNum}
                >
                  {f => (
                    <Pagination
                      total={total}
                      current={f.getFieldValue('pageNum')}
                      pageSize={f.getFieldValue('pageSize')}
                      showSizeChanger
                      onChange={(pageNum, pageSize) => {
                        f.setFieldsValue({pageNum, pageSize})
                        search()
                      }}
                    />
                  )}
                </Form.Item>
              </Row>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab={tabs[1].label} key={tabs[1].key}>
            <div>
              <Table
                dataSource={accountConfirm}
                rowKey={'key'}
                columns={[
                  {title: '月份', dataIndex: 'key'},
                  {title: '金额（元）', dataIndex: 'value'},
                ]}
                pagination={false}
              />
            </div>
          </Tabs.TabPane>
        </Tabs>
      </Form>
    </>
  )
}

export default ContractDetailAccounting
