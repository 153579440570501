/**
 * 动态加载脚本
 */
export class Script {
  static loaded: {
    [src: string]: {
      script?: HTMLElement
      status: 'pending' | 'success'
    }
  } = {}

  static loadScript(src: string, checkSuccess?: () => boolean) {
    return Script.load('script', src, checkSuccess)
  }
  static loadLink(src: string, checkSuccess?: () => boolean) {
    return Script.load('link', src, checkSuccess)
  }

  static load(type: 'script' | 'link', src: string, validate?: () => boolean) {
    return new Promise<void>(resolve => {
      const srcItem = Script.loaded[src]
      if (!srcItem) {
        const element = document.createElement(type)
        if (element instanceof HTMLScriptElement) {
          element.type = 'text/javascript'
          element.src = src
        } else {
          element.rel = 'stylesheet'
          element.href = src
        }

        const checkSuccess = (res, rej, time = 0) => {
          // console.log('check', src, time)
          if (time > 1000 * 30) return rej()
          if (!validate || validate()) return res()
          requestAnimationFrame(time => checkSuccess(res, rej, time))
        }

        element.onload = function () {
          return new Promise(checkSuccess).then(() => {
            Script.loaded[src].status = 'success'
            delete Script.loaded[src].script
            resolve()
          })
        }
        Script.loaded[src] = {script: element, status: 'pending'}
        document.body.appendChild(element)
        return
      }

      if (srcItem.status === 'success') return resolve()

      const prevHandle = srcItem.script?.onload
      srcItem.script.onload = async function (ev) {
        await prevHandle?.call(this, ev)
        resolve()
      }
    })
  }
}
