import React, {useCallback, useEffect, useRef, useState} from 'react'
import {
  Affix,
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  List,
  message,
  Row,
  Select,
  Space,
  Steps,
  Tabs,
  Typography,
} from 'antd'
import {EditOutlined, PlusOutlined} from '@ant-design/icons/lib'
import Location from '@/assets/icons/location.svg'
import Delete from '@/assets/icons/delete.svg'
import {useHistory} from 'react-router-dom'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import {configModel} from '@/store/models/Config'
// import {useCodeArraySync} from '@/store/models/Common'
import {propertyModel} from '@/store/models/Property'
import {rules} from '@/utils/validate'
import chartStyles from '@/pages/property/Chart/index.module.less'
import {SelectPosition} from '@/pages/property/SelectPosition'

import commonStyles from '@/assets/styles/common.module.less'
import styles from './index.module.less'
import './index.less'

const {Text} = Typography
const {Option} = Select

interface FloorList {
  // floor: string
  floor: number // 楼层号
  extNum: string // 房间数量
  prefixEnabled?: boolean // 是否添加前缀
  propertyDTOS: {
    id: number
    houseNo: string
    rentStatus: string // '0' | '1'
  }[]
}

function maxHouseIndex(dtos: FloorList['propertyDTOS'] = [], floor = 1, prefix = '') {
  const prefixReg = new RegExp(`^${prefix}${floor}`)
  const houseNos = dtos
    .map(value => value.houseNo?.replace?.(prefixReg, ''))
    .filter(value => value)
    .map(value => +value)
  return houseNos.length ? Math.max(...houseNos) : 1
}

const padStart = (value: number) => `${value}`.padStart(2, '0')

function createProperty(index: number, floor = 1, prefix = '', rentStatus = '0'): FloorList['propertyDTOS'][number] {
  return {
    houseNo: `${prefix}${floor}${padStart(index)}`,
    rentStatus,
    id: Math.random(),
  }
}

export default function AddCondo() {
  // const supportCity = useCodeArraySync('city')

  const [current, setCurrent] = useState(0)
  const [form] = Form.useForm()
  const [itemForm] = Form.useForm()

  // 商圈
  const [bizCircle, setBizCircle] = useState([])

  const positionRef = useRef(null)

  // 公寓总高
  const [floors, setFloors] = useState([])

  const [selectIds, setSelectIds] = useState([])

  function toggleId(id) {
    if (selectIds.includes(id)) {
      setSelectIds(prevState => prevState.filter(item => item !== id))
    } else {
      setSelectIds(prevState => [...prevState, id])
    }
  }

  const [cityList, setCityList] = useState([])

  useEffect(() => {
    propertyModel.queryCityCode({type: '2'}).then(({response: {data}}) => {
      setCityList(data)
    })
  }, [])

  // 房间
  const [dataList, setDataList] = useState([])

  // 是否需要输入房源编号
  const isChecked = configModel.useCheckedStatus()

  // 增加/删除房间后，更新 extNum
  const setExtNum = useCallback(
    (name: number, length: number) => {
      form.setFields([{name: ['floorList', name, 'extNum'], value: length}])
    },
    [form]
  )

  function onFloorChange(fieldName: number) {
    const prefixEnabled: boolean = form.getFieldValue(['floorList', fieldName, 'prefixEnabled'])
    const prefix: string = prefixEnabled ? form.getFieldValue('prefix') : ''
    const skip4: boolean = form.getFieldValue('skip4')
    const floorList: FloorList[] = form.getFieldValue('floorList')
    const newList = floorList?.map((item, index) => {
      if (index !== fieldName) return item

      const floor = item.floor
      let offset = 0
      return {
        ...item,
        propertyDTOS: item.propertyDTOS.map((value, index) => {
          if (skip4 && `${index + 1 + offset}`.endsWith('4')) {
            offset++
          }
          return {...value, houseNo: `${prefix}${floor}${padStart(index + 1 + offset)}`}
        }),
      }
    })
    form.setFieldsValue({floorList: newList})
  }

  // 输入房间数
  function onExtNumChange(name: number) {
    const extNum = form.getFieldValue(['floorList', name, 'extNum'])
    const floor: number = form.getFieldValue(['floorList', name, 'floor'])
    const prefixEnabled: boolean = form.getFieldValue(['floorList', name, 'prefixEnabled'])
    const prefix = prefixEnabled ? form.getFieldValue('prefix') : ''
    const propertyDTOS: FloorList['propertyDTOS'] = form.getFieldValue(['floorList', name, 'propertyDTOS'])
    if (extNum) {
      if (+extNum > propertyDTOS.length) {
        const maxIndex = maxHouseIndex(propertyDTOS, floor, prefix)
        let offset = 0
        const skip4: boolean = form.getFieldValue('skip4')
        const leftDTOS = Array.from({length: +extNum - propertyDTOS.length}).map((_, index) => {
          if (skip4 && `${maxIndex + index + 1 + offset}`.endsWith('4')) {
            offset++
          }
          return createProperty(maxIndex + index + 1 + offset, floor, prefix)
        })
        form.setFields([{name: ['floorList', name, 'propertyDTOS'], value: [...propertyDTOS, ...leftDTOS]}])
      } else if (+extNum < propertyDTOS.length) {
        form.setFields([{name: ['floorList', name, 'propertyDTOS'], value: propertyDTOS.filter((_, n) => n < +extNum)}])
      }
    } else {
      form.resetFields([['floorList', name, 'extNum']]) // 消除警告
      form.setFields([{name: ['floorList', name, 'extNum'], value: propertyDTOS?.length}])
    }
  }

  function togglePrefix(name: number, checked: boolean) {
    const prefix = form.getFieldValue('prefix')
    const prefixReg = new RegExp(`^${prefix}`)

    const addHouseNo = (houseNo = '') => (houseNo.startsWith(prefix) ? houseNo : `${prefix}${houseNo}`)
    const removeHouseNo = (houseNo = '') => (houseNo.startsWith(prefix) ? houseNo.replace(prefixReg, '') : houseNo)

    if (prefix) {
      const propertyDTOS: FloorList['propertyDTOS'] = form.getFieldValue(['floorList', name, 'propertyDTOS'])
      const newDTOS = propertyDTOS.map(value => ({
        ...value,
        houseNo: checked ? addHouseNo(value.houseNo) : removeHouseNo(value.houseNo),
      }))
      form.setFields([{name: ['floorList', name, 'propertyDTOS'], value: newDTOS}])
    }
  }

  // 添加公寓 下一步
  function nextToTemplate() {
    form.validateFields().then(async () => {
      const formValues = form.getFieldsValue(true)
      const {floorList} = formValues
      if (!floorList || floorList?.length === 0) return message.warning('请添加楼层！')

      // 检查楼层号是否重复
      const floors = floorList?.map(item => item.floor)
      const isRepeat = floors.length !== new Set(floors).size
      if (isRepeat) return message.warning('楼层号重复!')

      // 检查房间号是否重复
      const propertys = floorList?.map(item => item.propertyDTOS)
      for (let i = 0; i < propertys.length; i++) {
        const list = propertys[i]
        const obj = {}
        for (let j = 0; j < list.length; j++) {
          const houseNo = list[j].houseNo
          if (obj[houseNo] === undefined) {
            obj[houseNo] = houseNo
          } else return message.warning(`房间号 ${houseNo} 重复！`)
        }
      }

      if (!formValues.lat) return message.warning('详情地址选择失败，请重新选择！')

      await propertyModel.condoInsertStepCheck({
        step: 1,
        ...formValues,
        propertyDTOS: floorList?.map(item => item.propertyDTOS?.map(ele => ({...ele, floor: item.floor})))?.flat(),
      })

      // 如果没有模板 创建一个默认的模板
      const templateDTOS = form.getFieldValue('templateDTOS')
      if (!templateDTOS || templateDTOS.length === 0) {
        form.setFieldsValue({templateDTOS: [{}]})
      }

      setCurrent(1)
    })
  }

  // 房间模板 下一步
  function nextToConfiguration() {
    form.validateFields().then(async () => {
      const formValues = form.getFieldsValue(true)
      const {templateDTOS, floorList} = formValues

      // 检验模板名是否重复
      const templateNames = templateDTOS?.map(item => item.templateName)
      const obj = {}
      for (let i = 0; i < templateNames.length; i++) {
        const name = templateNames[i]
        if (obj[name] === undefined) {
          obj[name] = name
        } else return message.warning(`模板名称：${name} 重复！`)
      }

      await propertyModel.condoInsertStepCheck({
        step: 1,
        ...formValues,
        propertyDTOS: floorList?.map(item => item.propertyDTOS?.map(ele => ({...ele, floor: item.floor})))?.flat(),
      })

      setDataList(() => floorList?.map(item => ({title: item.floor, data: item.propertyDTOS})))
      setCurrent(2)
    })
  }

  const history = useHistory()
  async function onFinish() {
    const value = form.getFieldsValue(true)
    const propertys = dataList
      .map(item => {
        return item.data.map(_item => {
          return {..._item, floor: item.title}
        })
      })
      .flat()

    if (!propertys.every(item => item.templateName)) return message.warning('请配置房间信息')

    const params = {...value, propertyDTOS: propertys}

    await propertyModel.condoInsert(params)
    message.success('添加成功！')
    history.push('/success', {chartType: 'condo'})
  }

  return (
    <>
      <Breadcrumb path={['首页', '房源房态', '添加房源']} />
      <div className={commonStyles.section}>
        <Steps
          current={current}
          labelPlacement={'vertical'}
          style={{padding: '0 149px'}}
          onChange={current1 => {
            if (current1 === 0) {
              form.setFieldsValue({templateDTOS: []})
            }
            setCurrent(current1)
          }}
        >
          <Steps.Step title='添加公寓' />
          <Steps.Step title='房间模板' disabled={current < 1} />
          <Steps.Step title='配置房间' disabled={current < 2} />
        </Steps>
      </div>
      <Tabs defaultActiveKey='0' activeKey={`${current}`} renderTabBar={() => null}>
        {/** 添加公寓 */}
        <Tabs.TabPane key='0'>
          <div className={commonStyles.flexFullSection}>
            <Form form={form} colon={false} labelCol={{span: 7}} initialValues={{prefix: ''}}>
              {/** 公寓名称 && 城市 && 详细地址 && 商圈 && 是否电梯房 && 公寓总高 */}
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item name={'condoName'} label={'公寓名称'} rules={[...rules.condoName, rules.condoNameAsync]}>
                    <Input placeholder='请输入小区/公寓名' />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name={'cityCode'} label={'城市'} rules={rules.cityCode}>
                    <Select
                      placeholder={'请选择所在城市'}
                      onChange={() => {
                        form.setFieldsValue({
                          condoAddress: undefined,
                          bizCircleCode: undefined,
                          lat: undefined,
                          lng: undefined,
                        })
                      }}
                      showSearch
                      filterOption={(inputValue, option: any) => option.children?.includes(inputValue)}
                    >
                      {cityList.map(item => (
                        <Option value={item.code} key={item.code}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name={'condoAddress'} label={'详细地址'} rules={rules.condoAddress}>
                    <Input
                      suffix={<img src={Location} alt={'icon'} />}
                      placeholder='地图标点选择'
                      onClick={() => positionRef.current.show(form.getFieldsValue(true))}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={'bizCircleCode'}
                    label={'商圈'}
                    // rules={rules.bizCircleCode}
                  >
                    <Select placeholder={'选择商圈'}>
                      {bizCircle?.map(item => (
                        <Option value={item.code} key={item.code}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name={'lift'} label={'是否电梯房'} rules={rules.lift}>
                    <Select placeholder={'请选择'}>
                      <Option value={'1'}>是</Option>
                      <Option value={'0'}>否</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name={'topFloor'} label={'公寓总高'} rules={rules.topFloor}>
                    <Select
                      placeholder='请输入公寓总高'
                      onChange={value => setFloors(() => Array.from({length: +value}).map((_, n) => n + 1))}
                    >
                      {Array.from({length: 99})
                        .map((_, i) => i + 1)
                        .map(item => (
                          <Option value={item} key={item}>
                            {item}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name={'condoContact'} label={'联系电话'} rules={rules.mobile}>
                    <Input placeholder={'请输入联系电话'} maxLength={11} allowClear />
                  </Form.Item>
                </Col>
                <Col span={8} offset={16} pull={16}>
                  <Form.Item name={'skip4'} label={'个性配置'} valuePropName={'checked'}>
                    <Checkbox>{`房间号去"4"`}</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8} offset={16} pull={16}>
                  <Form.Item name={'prefix'} label={'房间号前缀'}>
                    <Input maxLength={2} placeholder={'房间号前缀'} allowClear />
                  </Form.Item>
                </Col>
              </Row>

              <Divider />

              {/** 楼层列表 */}
              <Form.List name={'floorList'}>
                {(fields, {add, remove}) => {
                  return (
                    <>
                      {fields.map(field => (
                        <div className={styles.floorList} key={field.key}>
                          {/** 楼层号 && 房间数量 */}
                          <div className={styles.floorHeader}>
                            <Form.Item
                              label={'楼层号'}
                              name={[field.name, 'floor']}
                              fieldKey={[field.fieldKey, 'floor']}
                              rules={rules.floor}
                              className={styles.floorFormItem1}
                            >
                              <Select placeholder={'请选择'} onChange={() => onFloorChange(field.name)}>
                                {floors.map((item, index) => (
                                  <Option value={item} key={index}>
                                    {item}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              name={[field.name, 'extNum']}
                              fieldKey={[field.fieldKey, 'extNum']}
                              label={'房间数量'}
                              rules={[
                                {required: true, message: '房间数不能为空'},
                                {
                                  validator: (_, value, callback) => {
                                    if (!/^\d+$/.test(value)) callback('请输入整数')
                                    else callback()
                                  },
                                  message: '请输入整数',
                                },
                              ]}
                              className={styles.floorFormItem2}
                            >
                              <InputNumber
                                min={1}
                                max={200}
                                onBlur={() => onExtNumChange(field.name)}
                                onPressEnter={() => onExtNumChange(field.name)}
                                placeholder='请输入房间数量'
                                style={{width: '100%'}}
                              />
                            </Form.Item>
                            <Form.Item
                              label={' '}
                              valuePropName={'checked'}
                              name={[field.name, 'prefixEnabled']}
                              fieldKey={[field.fieldKey, 'prefixEnabled']}
                              className={styles.floorFormItem2}
                            >
                              <Checkbox onChange={e => togglePrefix(field.name, e.target.checked)}>房间号前缀</Checkbox>
                            </Form.Item>
                            <Button danger onClick={() => remove(field.name)} className={styles.floorDelete}>
                              删除
                            </Button>
                          </div>

                          {/** 房间列表 */}
                          <Form.List name={[field.name, 'propertyDTOS']}>
                            {(innerFields, {add: innerAdd, remove: innerRemove}) => {
                              const propertyDTOS: FloorList['propertyDTOS'] = form.getFieldValue([
                                'floorList',
                                field.name,
                                'propertyDTOS',
                              ])

                              return (
                                <div className={styles.roomList}>
                                  {innerFields.map(innerField => (
                                    <div key={innerField.key} className={styles.roomItem}>
                                      <img
                                        src={Delete}
                                        alt={'icon'}
                                        className={styles.roomDelete}
                                        onClick={() => {
                                          innerRemove(innerField.name)
                                          setExtNum(field.name, propertyDTOS.length - 1)
                                        }}
                                      />
                                      <div className={styles.roomBorder}>
                                        <Form.Item
                                          name={[innerField.name, 'houseNo']}
                                          fieldKey={[innerField.fieldKey, 'houseNo']}
                                          style={{marginBottom: 0}}
                                          rules={rules.houseNo}
                                        >
                                          <Input style={{border: 'none'}} placeholder={'请输入房间号'} />
                                        </Form.Item>
                                        <Divider className={styles.roomDivider} />
                                        <Form.Item
                                          name={[innerField.name, 'rentStatus']}
                                          fieldKey={[innerField.fieldKey, 'rentStatus']}
                                          style={{marginBottom: 0}}
                                        >
                                          <Select placeholder={'请选择出租状态'} bordered={false}>
                                            <Option value={'1'}>已租</Option>
                                            <Option value={'0'}>未租</Option>
                                          </Select>
                                        </Form.Item>
                                        {isChecked && (
                                          <>
                                            <Divider className={styles.roomDivider} />
                                            <Form.Item
                                              name={[innerField.name, 'propertyCodeCustom']}
                                              fieldKey={[innerField.fieldKey, 'propertyCodeCustom']}
                                              style={{marginBottom: 0}}
                                            >
                                              <Input style={{border: 'none'}} placeholder={'请输入房源编号(选填)'} />
                                            </Form.Item>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  ))}
                                  <div className={styles.roomAdd}>
                                    {+form.getFieldValue('floorList')?.[field.name]?.extNum < 199 && (
                                      <Button
                                        shape={'circle'}
                                        type='primary'
                                        ghost
                                        icon={<PlusOutlined />}
                                        onClick={() => {
                                          const floor = form.getFieldValue(['floorList', field.name, 'floor']) || 1
                                          const prefixEnabled: boolean = form.getFieldValue([
                                            'floorList',
                                            field.name,
                                            'prefixEnabled',
                                          ])
                                          const prefix = prefixEnabled ? form.getFieldValue('prefix') : ''
                                          const maxIndex = maxHouseIndex(propertyDTOS, floor, prefix)
                                          const skip4 = form.getFieldValue('skip4')
                                          let nextIndex = maxIndex + 1
                                          if (skip4 && `${nextIndex}`.endsWith('4')) {
                                            nextIndex++
                                          }

                                          innerAdd(createProperty(nextIndex, floor, prefix))
                                          setExtNum(field.name, propertyDTOS.length + 1)
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              )
                            }}
                          </Form.List>
                        </div>
                      ))}

                      <Button
                        type='primary'
                        ghost
                        icon={<PlusOutlined />}
                        onClick={async () => {
                          const floorList = form.getFieldValue('floorList')
                          const result = await form.validateFields(['topFloor'])

                          if (result.topFloor <= floorList?.length) return message.warn('楼层数不能超过公寓总高！')
                          add({extNum: '1', propertyDTOS: [createProperty(1)]})
                        }}
                      >
                        添加楼层
                      </Button>
                    </>
                  )
                }}
              </Form.List>
              <div className={styles.next}>
                <Button type={'primary'} style={{width: 120, height: 40}} onClick={nextToTemplate}>
                  下一步
                </Button>
              </div>
            </Form>
          </div>
        </Tabs.TabPane>

        {/** 房间模板 */}
        <Tabs.TabPane key='1'>
          <div className={commonStyles.flexFullSection}>
            <Form form={form} colon={false} labelAlign={'right'}>
              <Form.List name={'templateDTOS'}>
                {(fields, {add, remove}) => {
                  return (
                    <>
                      {fields.map(field => (
                        <div key={field.key} className={styles.template}>
                          <div className={styles.templateHeader}>
                            <Form.Item
                              name={[field.name, 'templateName']}
                              fieldKey={[field.fieldKey, 'templateName']}
                              style={{marginBottom: 0}}
                              rules={rules.templateName}
                            >
                              <Input placeholder={'模板名称'} prefix={<EditOutlined />} className={'templateName'} />
                            </Form.Item>
                            {field.fieldKey === 0 ? null : (
                              <Button danger onClick={() => remove(field.name)}>
                                删除
                              </Button>
                            )}
                          </div>
                          <Divider className={styles.templateDivider} />
                          <div className={styles.templateBody}>
                            <Row gutter={38}>
                              <Col span={6}>
                                <Form.Item
                                  label={'房间面积'}
                                  name={[field.name, 'floorage']}
                                  fieldKey={[field.fieldKey, 'floorage']}
                                  rules={rules.floorage}
                                >
                                  <InputNumber placeholder={'请输入房间面积'} style={{width: '100%'}} />
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item
                                  label={'定金'}
                                  name={[field.name, 'deposit']}
                                  fieldKey={[field.fieldKey, 'deposit']}
                                  rules={rules.deposit}
                                >
                                  <InputNumber placeholder={'请输入定金金额'} style={{width: '100%'}} />
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item
                                  label={'押金'}
                                  name={[field.name, 'rentPledge']}
                                  fieldKey={[field.fieldKey, 'rentPledge']}
                                  rules={rules.rentPledge}
                                >
                                  <InputNumber placeholder={'请输入押金金额'} style={{width: '100%'}} />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={38}>
                              <Col span={11}>
                                <Form.Item label={'室/厅/卫'}>
                                  <Row gutter={10}>
                                    <Col span={8}>
                                      <Form.Item
                                        name={[field.name, 'bedroom']}
                                        fieldKey={[field.fieldKey, 'bedroom']}
                                        rules={rules.bedroom}
                                      >
                                        <Input placeholder={'请输入'} suffix={'室'} />
                                      </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                      <Form.Item
                                        name={[field.name, 'parlor']}
                                        fieldKey={[field.fieldKey, 'parlor']}
                                        rules={rules.parlor}
                                      >
                                        <Input placeholder={'请输入'} suffix={'厅'} />
                                      </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                      <Form.Item
                                        name={[field.name, 'bathroom']}
                                        fieldKey={[field.fieldKey, 'bathroom']}
                                        rules={rules.bathroom}
                                      >
                                        <Input placeholder={'请输入'} suffix={'卫'} />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </Form.Item>
                              </Col>
                              <Col span={7}>
                                <Row gutter={10}>
                                  <Col span={12}>
                                    <Form.Item
                                      label={'付'}
                                      name={[field.name, 'rentPayType']}
                                      fieldKey={[field.fieldKey, 'rentPayType']}
                                      rules={rules.rentPayType}
                                    >
                                      <Input placeholder={'请输入'} />
                                    </Form.Item>
                                  </Col>
                                  <Col span={12}>
                                    <Form.Item
                                      label={'押'}
                                      name={[field.name, 'rentPledgeType']}
                                      fieldKey={[field.fieldKey, 'rentPledgeType']}
                                      rules={rules.rentPledgeType}
                                    >
                                      <Input placeholder={'请输入'} />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Col>
                              <Col span={6}>
                                <Form.Item
                                  label={'房租'}
                                  name={[field.name, 'rentPrice']}
                                  fieldKey={[field.fieldKey, 'rentPrice']}
                                  // rules={rules.rentPrice}
                                >
                                  <InputNumber placeholder={'请输入房租金额'} style={{width: '100%'}} />
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ))}
                      <Button type='primary' ghost icon={<PlusOutlined />} onClick={() => add()}>
                        添加模板
                      </Button>
                    </>
                  )
                }}
              </Form.List>
              <div className={styles.next}>
                <Form.Item>
                  <Button type={'primary'} style={{width: 120, height: 40}} onClick={nextToConfiguration}>
                    下一步
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </Tabs.TabPane>

        {/** 配置房间 */}
        <Tabs.TabPane key='2'>
          <Row gutter={20} style={{margin: 0}}>
            <Col flex='1' style={{paddingLeft: 0, paddingRight: 0}}>
              <Form form={itemForm}>
                <Form.Item noStyle shouldUpdate>
                  {() => (
                    <List
                      style={{backgroundColor: '#fff'}}
                      dataSource={dataList}
                      renderItem={(item: any) => (
                        <List.Item style={{paddingTop: 0}}>
                          <div className={chartStyles.listSection}>
                            <Space className={styles.configListSection}>
                              <Text>{`${item?.title}层`}</Text>

                              <Button
                                onClick={() => {
                                  setSelectIds(prevState =>
                                    Array.from(new Set([...prevState, ...item?.data.map(_item => _item.id)]))
                                  )
                                  itemForm.setFieldsValue(item?.data[0])
                                }}
                                type={'ghost'}
                              >
                                全选
                              </Button>

                              <Button
                                onClick={() => {
                                  const ids = item?.data.map(_item => _item.id)
                                  setSelectIds(prevState => prevState.filter(id => !ids?.includes(id)))
                                }}
                                type={'ghost'}
                              >
                                取消全选
                              </Button>
                            </Space>
                            <div className={styles.configListContainer}>
                              {item?.data?.map((_item, n) => {
                                const isActive = selectIds.includes(_item.id) // _item.id === itemForm.getFieldValue('id')
                                return (
                                  <div
                                    key={`${n}-${_item.houseNo}`}
                                    className={isActive ? styles.configListItemS : styles.configListItemN}
                                    onClick={() => {
                                      toggleId(_item.id)
                                      if (!isActive) itemForm.setFieldsValue(_item)
                                    }}
                                  >
                                    <div className={isActive ? styles.listItemHeaderS : styles.listItemHeaderN}>
                                      <div
                                        className={_item.rentStatus === '0' ? styles.headerSquarG : styles.headerSquarB}
                                      />
                                      <div>{_item.houseNo}</div>
                                    </div>
                                    <div className={styles.listItemBody}>
                                      {_item.templateName ? (
                                        <>
                                          <div>{_item.templateName}</div>
                                          <div>{`${_item.rentPrice}元/月`}</div>
                                        </>
                                      ) : (
                                        <div style={isActive ? {color: '#017BFF'} : {}}>未选择模板</div>
                                      )}
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </List.Item>
                      )}
                      header={
                        <div className={styles.configHeader}>
                          <div className={styles.headerTitle}>{form.getFieldValue('condoName')}</div>
                          <div className={styles.configHeader}>
                            <div className={styles.configHeader}>
                              <div className={styles.headerSquarG} />
                              <div className={styles.headerDesc}>未出租</div>
                            </div>
                            <div className={styles.configHeader}>
                              <div className={styles.headerSquarB} />
                              <div className={styles.headerDesc}>已出租</div>
                            </div>
                          </div>
                        </div>
                      }
                    />
                  )}
                </Form.Item>
              </Form>
              <Form form={form} onFinish={onFinish}>
                <div className={styles.confirmAdd}>
                  <Form.Item>
                    <Button type={'primary'} htmlType={'submit'} loading={propertyModel.condoInsert.useLoading()}>
                      确认添加
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </Col>

            <Affix offsetTop={0}>
              <Col flex='280px' className={chartStyles.side}>
                <Form
                  form={itemForm}
                  colon={false}
                  labelCol={{span: 6}}
                  onFinish={value => {
                    setDataList(prevState => {
                      return prevState.map(item => {
                        return {
                          ...item,
                          data: item.data.map(_item => {
                            if (selectIds.includes(_item.id)) {
                              return {
                                ..._item,
                                rentPrice: value.rentPrice || 0,
                                templateName: value.templateName,
                                rentStatus: value.rentStatus,
                              }
                            } else {
                              return _item
                            }
                          }),
                        }
                      })
                    })
                    itemForm.resetFields()
                    setSelectIds([])
                  }}
                >
                  <Form.Item name={'id'} noStyle>
                    <div />
                  </Form.Item>
                  <Col>
                    <Form.Item
                      name={'houseNo'}
                      label={'房间号'}
                      // rules={rules.houseNo}
                    >
                      <Input disabled placeholder={'请输入房间号'} />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      name={'rentStatus'}
                      label={'出租状态'}
                      // rules={[{required: true, message: '请选择出租状态'}]}
                    >
                      <Select placeholder={'请选择'}>
                        <Option value={'1'}>已租</Option>
                        <Option value={'0'}>未租</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      name={'templateName'}
                      label={'模板'}
                      // rules={rules.templateName}
                    >
                      <Select
                        placeholder={'请选择'}
                        onChange={value => {
                          const templateDTOS = form.getFieldValue('templateDTOS')
                          const template = templateDTOS?.find(item => item.templateName === value)
                          if (template && template.rentPrice) {
                            itemForm.setFieldsValue({rentPrice: template.rentPrice})
                          }
                        }}
                      >
                        {form.getFieldValue('templateDTOS')?.map((item, i) => (
                          <Option value={item?.templateName ?? ''} key={i}>
                            {item?.templateName ?? ''}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item name={'rentPrice'} label={'租金'}>
                      <InputNumber placeholder={'请输入租金'} style={{width: '100%'}} />
                    </Form.Item>
                  </Col>
                  <div className={styles.configSave}>
                    <Form.Item>
                      <Button type={'primary'} htmlType={'submit'}>
                        保存
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </Col>
            </Affix>
          </Row>
        </Tabs.TabPane>
      </Tabs>

      <SelectPosition
        ref={positionRef}
        onChange={(value, bizCircle) => {
          setBizCircle(bizCircle)
          form.setFieldsValue(value)
        }}
      />
    </>
  )
}
