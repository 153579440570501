import React, {useCallback, useEffect, useRef, useState} from 'react'
import styles from './index.module.less'

import echarts from 'echarts/lib/echarts'
import 'echarts/lib/chart/bar'
import 'echarts/lib/chart/line'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/title'
import 'echarts/lib/chart/pie'
import 'echarts/lib/component/legendScroll'
import 'echarts/lib/component/graphic'

import {Button, Col, DatePicker, Modal, Progress, Row, Space, Typography} from 'antd'
import {tradeModel} from '@/store/models/Trade'
import dayjs from 'dayjs'
import {propertyModel} from '@/store/models/Property'
import {contractModel} from '@/store/models/Contract'
import {stewardModel} from '@/store/models/Steward'
import {add} from '@/utils/amount'
import {userModel} from '@/store/models/User'
import {Link} from 'react-router-dom'
import {RightOutlined} from '@ant-design/icons'
import {useDaily} from '@/common/hooks/useDaily'
import {billModel} from '@/store/models/Bill'
import moment from 'moment'
import NygDeviceAbnormal from '@/pages/nygDevice/components/NygDeviceAbnormal'
import {useAppHistory} from '@/common/hooks/useRouter'
const {Text} = Typography

const labelMap = {
  in: {
    name: '进账',
    color: '#FCAF38',
  },
  out: {
    name: '出账',
    color: '#017BFF',
  },
}
const icon = 'path://M0 0 L8 0 L8 1 L0 1 Z'
const FORMAT = 'YYYY-MM-DD'

export default function Home() {
  const echartsContainerRef = useRef(null)
  const echartsRef = useRef<echarts.ECharts>(null)

  const echartsLessorTradeDomRef = useRef(null)
  const echartsLessorTradeRef = useRef<echarts.ECharts>(null)

  const echartsLesseeTradeDomRef = useRef(null)
  const echartsLesseeTradeRef = useRef<echarts.ECharts>(null)
  const [tradeDate, setTradeDate] = useState(moment())

  const [board, setBoard] = useState({
    propertyBoard: {} as HomepagePropertyBoardVO, // 待办 // 房源
    billBoard: {} as BillHomepageStatistics, // 账单
    // tradeBoard: {} as TradeHomepageStatistics, // 流水
    stewardBoard: {} as StewardOrderStatisticVO, // 服务单，累计服务单
    stewardList: {} as StewardListTotal, // 服务单，累计服务单
    // 服务单待沟通
    stewardBill: {} as {billAmt: number; billBasic: number; billCount: number; billExtra: number},
  })
  const [unreadMsg, setUnreadMsg] = useState({} as LessorMessageVO)
  const userInfo = userModel.useData(data => data.userInfo)
  const history = useAppHistory()
  const [dueBill, setDueBill] = useState({} as {billCount: number; billAmt: number})
  const [dueBalance, setDueBalance] = useState<{name: string; balance: number}[]>([])

  const init = useCallback(function () {
    propertyModel
      .homepagePropertyStatistics()
      .then(({response: {data}}) => setBoard(state => ({...state, propertyBoard: data})))
    contractModel
      .billHomepageStatistics()
      .then(({response: {data}}) => setBoard(state => ({...state, billBoard: data})))
    // tradeModel.homepageStatistics().then(({response: {data}}) => setBoard(state => ({...state, tradeBoard: data})))
    stewardModel
      .stewardOrderStatistics()
      .then(({response: {data}}) => setBoard(state => ({...state, stewardBoard: data})))
    stewardModel.stewardListTotal({}).then(({response: {data}}) => setBoard(state => ({...state, stewardList: data})))

    userModel
      .userMessageTypeList({pageSize: 1})
      .then(({response: {data}}) => setUnreadMsg(data[0] || ({} as LessorMessageVO)))

    tradeModel
      .lessorHomeBillAndBalanceStatic()
      .then(({response: {data}}) => setBoard(state => ({...state, stewardBill: data})))
  }, [])

  const tradeSearch = useCallback((tradeDate: string) => {
    echartsLessorTradeRef.current?.showLoading('default', {
      text: '加载中...',
      color: '#017BFF',
      textColor: '#2C3542',
    })
    echartsLesseeTradeRef.current?.showLoading('default', {
      text: '加载中...',
      color: '#017BFF',
      textColor: '#2C3542',
    })
    tradeModel
      .tradeHomepageStatisticsIncome({tradeDate})
      .then(({response: {data}}) => {
        const lessorBill = data.filter(item => item.tradePayWayCode !== '07')
        const lesseeBill = data.filter(item => item.tradePayWayCode === '07')
        const lessorBillData = lessorBill.map(item => ({
          value: item.tradeAmt,
          name: `${item.tradePayWayName}:${item.tradeAmt}`,
        }))
        const lesseeBillData = lesseeBill.map(item => ({
          value: item.tradeAmt,
          name: `${item.tradePayWayName}:${item.tradeAmt}`,
          itemStyle: {color: '#017BFF'},
        }))
        const lessorTradeTotal = lessorBill.length
          ? lessorBill.reduce((sum, item) => {
              return sum + item.tradeAmt
            }, 0)
          : 0

        const lesseeTradeTotal = lesseeBill.length
          ? lesseeBill.reduce((sum, item) => {
              return sum + item.tradeAmt
            }, 0)
          : 0

        //房东端
        echartsLessorTradeRef.current.setOption({
          tooltip: {
            trigger: 'item',
            formatter: `{b}`,
            show: lessorBill.length ? true : false,
          },
          legend: {
            show: lessorBill.length ? true : false,
            type: 'plain',
            orient: 'vertical',
            right: '20',
            itemWidth: 6,
            itemHeight: 6,
            top: 'middle',
            textStyle: {color: 'rgba(0, 0, 0, 0.65)', fontSize: 14},
            icon: 'circle',
          },
          series: [
            {
              type: 'pie',
              radius: ['45%', '60%'],
              avoidLabelOverlap: false,
              label: {
                normal: {
                  show: true,
                  position: 'center',
                  color: 'rgba(0, 0, 0, 0.45)',
                  formatter: '{title|总额}' + '\n\r' + '{total|' + '￥' + `${lessorTradeTotal}` + '}',
                  rich: {
                    title: {
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.45)',
                      fontWeight: 400,
                    },
                    total: {
                      fontSize: 22,
                      fontWeight: 600,
                      lineHeight: 30,
                      color: 'rgba(0, 0, 0, 0.85)',
                    },
                  },
                },
                emphasis: {
                  show: false,
                },
              },
              center: ['35%', '50%'],
              labelLine: {
                show: false,
              },
              data: lessorBillData.length ? lessorBillData : [{name: '无', value: 0, itemStyle: {color: '#F5F5F5'}}],
            },
          ],
        })

        //租客端
        echartsLesseeTradeRef.current.setOption({
          tooltip: {
            trigger: 'item',
            formatter: `{b}`,
            show: lesseeBill.length ? true : false,
          },
          legend: {
            type: 'plain',
            orient: 'vertical',
            right: '20',
            itemWidth: 6,
            itemHeight: 6,
            top: 'middle',
            textStyle: {color: 'rgba(0, 0, 0, 0.65)', fontSize: 14},
            show: lesseeBill.length ? true : false,
            icon: 'circle',
          },
          series: [
            {
              type: 'pie',
              radius: ['45%', '60%'],
              avoidLabelOverlap: false,
              label: {
                normal: {
                  show: true,
                  position: 'center',
                  formatter: '{title|总额}' + '\n\r' + '{total|' + '￥' + `${lesseeTradeTotal}` + '}',
                  rich: {
                    title: {
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.45)',
                      fontWeight: 400,
                    },
                    total: {
                      fontSize: 22,
                      fontWeight: 600,
                      lineHeight: 30,
                      color: 'rgba(0, 0, 0, 0.85)',
                    },
                  },
                },
                emphasis: {
                  show: true,
                },
              },
              center: ['35%', '50%'],
              labelLine: {
                show: false,
              },
              data: lesseeBillData.length ? lesseeBillData : [{name: '无', value: 0, itemStyle: {color: '#F5F5F5'}}],
            },
          ],
        })
      })
      .finally(() => {
        echartsLessorTradeRef.current?.hideLoading()
        echartsLesseeTradeRef.current?.hideLoading()
      })
  }, [])

  useEffect(() => {
    setTimeout(() => {
      echartsLessorTradeRef.current = echarts.init(echartsLessorTradeDomRef.current)
      echartsLesseeTradeRef.current = echarts.init(echartsLesseeTradeDomRef.current)
      tradeSearch(dayjs().format('YYYY-MM-DD'))
    })

    function onResize() {
      echartsLesseeTradeRef.current?.resize?.()
      echartsLessorTradeRef.current?.resize?.()
    }
    // setTimeout(onResize)
    window.addEventListener('tradeResize', onResize)

    return () => {
      window.removeEventListener('tradeResize', onResize)
    }
  }, [tradeSearch])

  const search = useCallback(function ([startDate, endDate]) {
    echartsRef.current?.showLoading('default', {
      text: '加载中...',
      color: '#017BFF',
      textColor: '#2C3542',
    })
    const tradeDateStart = dayjs(startDate)
    const tradeDateEnd = dayjs(endDate)
    const duration = tradeDateEnd.diff(tradeDateStart, 'day') + 1

    tradeModel
      .tradeHomepageStatisticsChart({
        begin: dayjs(tradeDateStart).format(FORMAT),
        end: dayjs(tradeDateEnd).format(FORMAT),
      })
      .then(({response: {data}}) => {
        const inTotal = data.reduce((sum, item) => {
          return sum + item.income
        }, 0)
        const outTotal = data.reduce((sum, item) => {
          return sum + item.payout
        }, 0)

        const dateArr = Array.from({length: duration}).map((_, index) => {
          return tradeDateStart.add(index, 'day').format(FORMAT)
        })

        const dataArr = dateArr.reduce(
          (result, currentDate) => {
            const currentData = data.filter(item => item.date === currentDate)[0]
            if (currentData) {
              result['in'].push(currentData.income)
              result['out'].push(Math.abs(currentData.payout))
            } else {
              result['in'].push(0)
              result['out'].push(0)
            }

            return result
          },
          {in: [], out: []}
        )

        const seriesData = Object.keys(dataArr).map(key => {
          const label = labelMap[key]
          return {
            name: label?.name,
            type: 'line',
            data: dataArr[key],
            symbolSize: 2,
            itemStyle: {
              normal: {
                color: label.color, //改变折线点的颜色
                lineStyle: {
                  color: label.color, //改变折线颜色
                },
              },
            },
          }
        })

        const rate = inTotal ? Math.round((add(inTotal, outTotal) / inTotal) * 100) : 0

        const top = 24
        echartsRef.current?.setOption({
          title: {
            text:
              `总进账：${inTotal}       总出账：${Math.abs(outTotal)}` + (inTotal ? `        毛利率：${rate}%` : ''),
            left: 20,
            top,
            textStyle: {
              color: '#8C8C8C',
              fontSize: 12,
            },
          },
          grid: {
            top: 82,
          },
          tooltip: {
            trigger: 'axis',
          },
          legend: {
            data: ['in', 'out'].map(item => ({
              name: labelMap[item]?.name,
              icon: icon,
            })),
            itemGap: 20,
            right: 90,
            top,
            textStyle: {
              color: '#2C3542',
            },
          },
          xAxis: {
            type: 'category',
            // data: dateArr.map(item => dayjs(item).format('MM-DD')),
            data: dateArr,
            axisLine: {
              lineStyle: {
                color: '#E9E9E9',
              },
            },
            axisLabel: {
              color: 'rgba(0, 0, 0, 0.65)',
              formatter: value => dayjs(value).format('MM-DD'),
            },
          },
          yAxis: {
            type: 'value',
            axisLine: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                type: 'dotted',
                color: '#E9E9E9',
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: 'rgba(0, 0, 0, 0.65)',
            },
          },
          series: seriesData,
        })
      })
      .finally(() => {
        echartsRef.current?.hideLoading()
      })
  }, [])

  useEffect(
    function () {
      setTimeout(() => {
        echartsRef.current = echarts.init(echartsContainerRef.current)
        const currentDate = dayjs()
        const before30 = currentDate.subtract(30, 'day')
        search([before30.format(FORMAT), currentDate.format(FORMAT)])
        init()
      })

      function onResize() {
        echartsRef.current?.resize?.()
      }
      // setTimeout(onResize)
      window.addEventListener('resize', onResize)

      return () => {
        window.removeEventListener('resize', onResize)
      }
    },
    [init, search]
  )

  const matters = [
    {title: '账单逾期房源', count: board.propertyBoard.propertyCountOfBillOverDue || 0, color: '#017BFF'},
    {title: '租约已到期', count: board.propertyBoard.propertyCountOfBecomingDue || 0, color: '#FCAF38'},
    {title: '预订单到期', count: board.propertyBoard.contractCountOfReserveDue || 0, color: '#FC5B38'},
  ]
  const services = [
    {title: '待沟通', count: board.stewardList.ORDER_STATUS_WAIT_COMMUNICATION, orderStatus: '1'},
    {title: '已沟通', count: board.stewardList.ORDER_STATUS_COMMUNICATED, orderStatus: '2'},
    {title: '上门中', count: board.stewardList.ORDER_STATUS_ON_THE_WAY, orderStatus: '3'},
    {title: '服务中', count: board.stewardList.ORDER_STATUS_SERVING, orderStatus: '4'},
    {title: '已完成', count: board.stewardList.ORDER_STATUS_FINISHED, orderStatus: '5'},
  ]

  const [visible, setVisible] = useState(false)

  //每日账单提醒
  useDaily(async () => {
    if (userInfo.userId) {
      billModel.lessorHomeBillAndBalance().then(({response: {data}}) => {
        const {billAmt, billCount, org} = data
        if (billCount || org?.length) {
          setVisible(true)
          setDueBill({billAmt, billCount})
          setDueBalance(org)
        }
      })
    }
  }, `${userInfo.userId}`)

  return (
    <Row gutter={20} wrap={false}>
      <Col flex='1 1 200px' style={{marginBottom: 20}}>
        <Space size={20} direction={'vertical'} style={{width: '100%'}}>
          <Link to={'/messageList'} className={styles.message}>
            <Typography.Text>{unreadMsg.body ? `✉️ ${unreadMsg.body}` : '暂无消息'}</Typography.Text>
            <RightOutlined style={{color: '#9EA0A7'}} />
          </Link>
          <Row gutter={20}>
            <Col span={12}>
              <div className={styles.section}>
                <div className={styles.sectionHeader}>待办事项</div>
                <div className={`${styles.sectionContent} ${styles.matterContainer}`}>
                  {matters.map(item => (
                    <div
                      key={item.title}
                      className={styles.matterItem}
                      onClick={() => {
                        if (item.title === '账单逾期房源') history.push({pathname: '/overdue-property'})
                        if (item.title === '租约已到期')
                          history.push({pathname: '/contract', state: {contractStatus: '3'}})
                        if (item.title === '预订单到期') history.push('/reserve')
                      }}
                    >
                      <Typography.Title>{item.count}</Typography.Title>
                      <div className={styles.matterLine} style={{backgroundColor: item.color}} />
                      <Typography.Text style={{color: '#8C8C8C'}}>{item.title}</Typography.Text>
                    </div>
                  ))}
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className={styles.section} style={{cursor: 'pointer'}} onClick={() => history.push('/stewardBill')}>
                <div className={styles.sectionHeader}>管家账单</div>
                <div className={styles.matterContainer} style={{justifyContent: 'center'}}>
                  <div className={styles.stewardBill}>
                    <Typography.Title>￥{board.stewardBill?.billAmt} </Typography.Title>
                    <span style={{color: '#8C8C8C'}}>待付金额</span>
                  </div>
                  <Space direction={'vertical'}>
                    <span style={{color: '#8C8C8C'}}>
                      账单笔数：
                      <span style={{color: '#333333'}}>{board.stewardBill?.billCount}</span>
                    </span>
                    <span style={{color: '#8C8C8C'}}>
                      额外费用账单：
                      <span style={{color: '#333333'}}>￥{board.stewardBill?.billExtra}</span>
                    </span>
                    <span style={{color: '#8C8C8C'}}>
                      基础费用账单：
                      <span style={{color: '#333333'}}>￥{board.stewardBill?.billBasic}</span>
                    </span>
                  </Space>
                </div>
              </div>
            </Col>
          </Row>
          <div className={styles.section}>
            <div className={styles.sectionHeader}>
              <span>账单收款</span>
              <DatePicker
                value={moment(tradeDate)}
                onChange={value => {
                  if (value) {
                    setTradeDate(value)
                    tradeSearch(moment(value).format('YYYY-MM-DD'))
                  } else {
                    setTradeDate(moment())
                    tradeSearch(moment().format('YYYY-MM-DD'))
                  }
                }}
                disabledDate={current => {
                  return current && current > moment()
                }}
              />
            </div>
            <div className={styles.tradeContent}>
              <div className={styles.tradeItemContent}>
                <span style={{padding: '20px 35px', fontSize: '14px', display: 'inline-block', fontWeight: 600}}>
                  房东端
                </span>
                <div style={{height: '300px', width: '100%'}}>
                  <div ref={echartsLessorTradeDomRef} style={{width: '100%', height: '100%'}} />
                </div>
              </div>
              <div className={styles.tradeItemContent}>
                <span style={{padding: '20px 35px', fontSize: '14px', display: 'inline-block', fontWeight: 600}}>
                  租客端
                </span>
                <div style={{height: '300px', width: '100%'}}>
                  <div ref={echartsLesseeTradeDomRef} style={{width: '100%', height: '100%'}} />
                </div>
              </div>
            </div>
          </div>

          <div className={styles.section}>
            <div className={styles.sectionHeader}>
              <span>交易流水</span>
              <DatePicker.RangePicker
                onChange={(_, dateString) => {
                  if (dateString && dateString.every(item => item)) {
                    search(dateString)
                  }
                }}
                defaultValue={[moment().subtract(30, 'day'), moment()]}
                // disabledDate={date => {
                //   console.log(date)
                //   return false
                // }}
              />
            </div>
            <div className={`${styles.sectionContent} ${styles.chart}`}>
              <div ref={echartsContainerRef} style={{width: '100%', height: '100%'}}></div>
            </div>
          </div>
        </Space>
      </Col>
      <Col flex={'0 1 388px'}>
        <Space size={20} direction={'vertical'} style={{width: '100%'}}>
          <div className={styles.section}>
            <div className={styles.sectionHeader}>服务单</div>
            <div className={styles.sectionContent}>
              <Row className={styles.service}>
                {services.map(item => (
                  <Col
                    flex={1}
                    key={item.title}
                    onClick={() => history.push('/steward', {orderStatus: item.orderStatus})}
                  >
                    <p className={styles.serviceCount}>{item.count}</p>
                    <p className={styles.serviceTitle}>{item.title}</p>
                  </Col>
                ))}
              </Row>
              <Row>
                <Col flex={1} className={styles.serviceBottom}>
                  <Text>累计服务单量：</Text>
                  <Text>{board.stewardBoard.serviceOrderNumTotal}</Text>
                </Col>
                <Col flex={1} className={styles.serviceBottom}>
                  <Text>30天服务单量：</Text>
                  <Text>{board.stewardBoard.serviceOrderNum30Days}</Text>
                </Col>
              </Row>
            </div>
          </div>

          <div className={styles.section} style={{cursor: 'pointer'}} onClick={() => history.push('/quick')}>
            <div className={styles.sectionHeader}>空置房源</div>
            <div className={styles.circle}>
              <Space>
                <Progress
                  width={94}
                  strokeWidth={7}
                  percent={board.propertyBoard.vacancyRate}
                  strokeColor={'#017BFF'}
                  type={'circle'}
                  format={percent => (
                    <Space direction={'vertical'}>
                      <Text className={styles.circlePercent}>{`${percent}%`}</Text>
                      <Text className={styles.circleTitle}>空置率</Text>
                    </Space>
                  )}
                />
                <Space direction={'vertical'} className={styles.circleContent}>
                  <div>
                    <Text className={styles.circleLabel}>空置房源数：</Text>
                    <Text className={styles.circleCount}>{board.propertyBoard.propertyUnRentNums}</Text>
                  </div>
                  <div>
                    <Text className={styles.circleLabel}>集中式公寓：</Text>
                    <Text className={styles.circleCount}>{board.propertyBoard.condoUnRentNums}</Text>
                  </div>
                  <div>
                    <Text className={styles.circleLabel}>分散式公寓：</Text>
                    <Text className={styles.circleCount}>{board.propertyBoard.scatterUnRentNums}</Text>
                  </div>
                </Space>
              </Space>
            </div>
          </div>

          <div
            className={styles.section}
            style={{cursor: 'pointer'}}
            onClick={() => history.push({pathname: '/bill', state: {tag: '1'}})}
          >
            <div className={styles.sectionHeader}>账单</div>
            <div className={styles.circle}>
              <Space>
                <Progress
                  width={94}
                  strokeWidth={7}
                  percent={+board.billBoard.overdueRate}
                  strokeColor={'#017BFF'}
                  type={'circle'}
                  format={percent => (
                    <Space direction={'vertical'}>
                      <Text className={styles.circlePercent}>{`${percent}%`}</Text>
                      <Text className={styles.circleTitle}>逾期率</Text>
                    </Space>
                  )}
                />
                <Space direction={'vertical'} className={styles.circleContent}>
                  <div>
                    <Text className={styles.circleLabel}>逾期账单总额：</Text>
                    <AmountText className={styles.circleCount}>{board.billBoard.overdueTotal}</AmountText>
                  </div>
                  <div>
                    <Text className={styles.circleLabel}>逾期账单数：</Text>
                    <Text className={styles.circleCount}>{board.billBoard.overdueCount}</Text>
                  </div>
                </Space>
              </Space>
            </div>
          </div>
        </Space>
      </Col>

      {/* 提醒弹框 */}
      <Modal title={'提示'} onCancel={() => setVisible(false)} footer={null} visible={visible} width={438} centered>
        <Space direction={'vertical'}>
          <Text style={{fontWeight: 600, fontSize: 14}}>{`${userInfo.certName}，您好，您当前：`}</Text>
          <>
            {!!dueBill.billCount && (
              <Space direction={'vertical'} size={0}>
                <Text style={{color: '#666666'}}>
                  逾期账单：<Text style={{color: '#000000', fontSize: 18, fontWeight: 600}}>{dueBill.billCount}</Text>
                  <Text style={{color: '#000000'}}>笔</Text>；
                </Text>
                <Text style={{color: '#666666'}}>
                  逾期金额：<Text style={{color: '#000000', fontSize: 18, fontWeight: 600}}>{dueBill.billAmt}</Text>
                  <Text style={{color: '#000000'}}>元</Text>；
                </Text>
              </Space>
            )}
            {!!dueBalance.length && (
              <Space direction={'vertical'} size={0}>
                {dueBalance.map((item, index) => (
                  <Text key={index} style={{color: '#666666'}}>
                    {item.name}服务费账户：
                    <Text style={{color: '#000000', fontSize: 18, fontWeight: 600}}>{item.balance}</Text>
                    <Text style={{color: '#000000'}}>元</Text>；
                  </Text>
                ))}
              </Space>
            )}
          </>
          <Text>为保证功能正常使用，请尽快处理逾期账单或欠费！处理结果 以账号信息为准，已处理请忽略。</Text>
        </Space>
        <Row justify={'end'} style={{marginTop: 40}}>
          <Space>
            <Button
              type={'primary'}
              onClick={() => history.push({pathname: dueBill.billCount ? '/stewardBill' : '/serviceAccount'})}
            >
              查看详情
            </Button>
            <Button onClick={() => setVisible(false)}>知道了</Button>
          </Space>
        </Row>
      </Modal>

      {/* 异常设备 */}
      <NygDeviceAbnormal />
    </Row>
  )
}

interface AmountTextProps {
  children?: string | number
  className?: string
}

function AmountText(props: AmountTextProps) {
  const text = props.children ? parseFloat(`${props.children}`).toLocaleString() : 0
  return <span className={props.className}>{text}</span>
}

// series: [
//   // {type: 'line', name: '进账', seriesLayoutBy: 'row' /*默认 column*/},
//   // {type: 'line', name: '出账', seriesLayoutBy: 'row'},
//
//   // {type: 'line', name: '07-07' /*默认 column*/},
//   // {type: 'line', name: '07-08'},
//   // {type: 'line', name: '07-09'},
// ],
