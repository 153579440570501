import React, {useImperativeHandle, useState} from 'react'
import {Button, Form, Input, message, Modal, Row, Select, Space, Typography} from 'antd'
import {Album} from '@/ui/components/Album'
import {useCodeArraySync} from '@/store/models/Common'
import {uploadFeedback} from '@/utils/util'

const {Option} = Select
const {TextArea} = Input
function _Feedback(props, ref) {
  const [visible, setVisible] = useState(false)
  const feedbackType = useCodeArraySync('feedback')
  const feedbackMode = useCodeArraySync('feedback.mode')
  const [form] = Form.useForm()
  const [orderId, setOrderId] = useState('')
  const [loading, setLoading] = useState(false)

  useImperativeHandle(ref, () => ({
    show: () => {
      setVisible(true)
    },
    getId: (id: string) => {
      setOrderId(id)
    },
  }))
  return (
    <Modal
      visible={visible}
      footer={null}
      title={
        <div style={{textAlign: 'center'}}>
          <Typography.Text style={{color: '#000000'}}>投诉建议</Typography.Text>
        </div>
      }
      onCancel={() => setVisible(false)}
      width={450}
      centered
      afterClose={() => {
        form.resetFields()
      }}
    >
      <Form
        form={form}
        labelCol={{span: 7}}
        wrapperCol={{span: 12}}
        onFinish={async () => {
          setLoading(true)
          const values = form.getFieldsValue(true)
          const body = new FormData()
          body.append('content', values.content)
          body.append('extra', orderId || '')
          body.append('feedbackMode', values.feedbackMode)
          body.append('feedbackType', values.feedbackType)
          body.append('source', 'lessor')
          values?.fileList?.forEach(item => body.append('fileList', item.originFileObj))
          await uploadFeedback({url: '/feedback/upload', body})
          message.success('提交成功')
          setVisible(false)
          setLoading(false)
        }}
        initialValues={{
          feedbackType: orderId ? 'service' : undefined,
          feedbackMode: orderId ? '2' : '1',
        }}
      >
        <Form.Item
          label={'问题反馈类型'}
          name={'feedbackType'}
          rules={[{required: true, message: '请选择问题反馈类型'}]}
        >
          <Select placeholder={'请选择'}>
            {feedbackType.map(item => (
              <Option value={item.id} key={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={'性质'} name={'feedbackMode'} rules={[{required: true, message: '请选择性质'}]}>
          <Select>
            {feedbackMode.map(item => (
              <Option value={item.id} key={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={'上传照片'} wrapperCol={{span: 15}}>
          <Album
            onChange={info =>
              form.setFieldsValue({
                fileList: info.fileList,
              })
            }
            fileList={[]}
          />
        </Form.Item>
        <Form.Item
          label={'问题建议'}
          name={'content'}
          wrapperCol={{span: 16}}
          rules={[{required: true, message: '请填写您遇到的问题'}]}
        >
          <TextArea placeholder={'请描述您遇到的问题'} maxLength={200} style={{height: 90}} />
        </Form.Item>
        <Row justify={'end'}>
          <Space>
            <Button
              onClick={() => {
                setVisible(false)
              }}
            >
              取消
            </Button>
            <Button type={'primary'} loading={loading} htmlType={'submit'}>
              保存
            </Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  )
}

export const Feedback = React.forwardRef(_Feedback)
