import React, {useEffect, useMemo, useState, useCallback, useRef} from 'react'
import commonStyles from '@/assets/styles/common.module.less'
import styles from './index.module.less'
import {
  Form,
  Row,
  Col,
  Input,
  List,
  Button,
  Popover,
  Typography,
  Space,
  AutoComplete,
  Modal,
  Radio,
  Skeleton,
  Tabs,
  Divider,
  Select,
  Affix,
  message,
  Card,
  Tag,
  DatePicker,
  // Table,
} from 'antd'
import {SearchOutlined, SwapRightOutlined} from '@ant-design/icons'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {propertyModel, rentTypeMap} from '@/store/models/Property'
import {usePagination} from '@/common/hooks/pagination'
import {
  chartJoint,
  combine,
  houseNoProperty,
  renderProperty,
  renderSectionList,
  RenderSectionResult,
} from '@/utils/render'
import {Link} from 'react-router-dom'
import {Album} from '@/ui/components/Album'
import {getImg} from '@/utils/util'
// import {PropertyConfigList} from '@/pages/property/components/PropertyConfigList'
import {Cost} from '@/pages/property/components/Cost'
import {rules} from '@/utils/validate'
import {Model} from '@redux-model/web'
import ImgEmpty from '@/assets/imgs/img_empty.svg'
import {checkPropertyPermission} from '@/utils/permissions'
import {usePermission, hasPermission, Permission} from '@/ui/components/Permission'
import {CancelContract, CancelContractMethod} from '@/pages/contract/CancelContract'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import {contractModel} from '@/store/models/Contract'
import dayjs from 'dayjs'
import {configModel} from '@/store/models/Config'
import PropertyEdit from '@/pages/property/components/PropertyEdit'
import {SelectPosition} from '@/pages/property/SelectPosition'
import Location from '@/assets/icons/location.svg'
import {useIsProjectCondo} from '@/common/bizHooks/useIsProjectCondo'
import {AuditButton} from '@/ui/bizComponents/AuditButton'
import {useAppHistory} from '@/common/hooks/useRouter'
import {useApplyCheck} from '@/utils/lesseeApplyCheck'
import ChargeModal from '@/pages/contract/ContractDetail/ChargeModal'
// import {warehouseModel} from '@/store/models/Warehouse'

const {Text, Title} = Typography
const {Option} = Select
const {RangePicker} = DatePicker

// interface ChartProps {
//   chartType: ChartType
// }

// const rentTypeMap = [
//   {title: '分散式房源', key: '1'}, // 1,2 整租、合租
//   {title: '集中式公寓', key: '2'}, // 3 独栋
// ]

// when 仅为 false 时才过滤，true 或 undefined 时不需要过滤
interface RenderItem {
  when?: boolean
  title: string
  // TODO：customer 可以去掉
  handles: {
    when?: boolean
    title: string
    onClick: (...args: any[]) => any
    loading?: boolean
    customer?: boolean
    preCheck?: () => void
  }[]
  labels: {label: string; value: any; when?: boolean}[]
}

export default function Chart() {
  const [auth] = usePermission()
  const [form] = Form.useForm()
  // const [formRoom] = Form.useForm()
  const [addForm] = Form.useForm()
  const [manageForm] = Form.useForm()
  const [templateForm] = Form.useForm()
  const history = useAppHistory()
  // const currentRentMode = propertyModel.useData(data => data.rentMode)
  const [renewForm] = Form.useForm()

  const [migrateVisible, setMigrateVisible] = useState(false)
  const [visible, setVisible] = useState(false)
  const [editProperty, setEditProperty] = useState<PropertyInfoCardVO>(null)
  const [communityList, setCommunityList] = useState<CommunityVO[]>([])
  // const [tabKey, setTabKey] = useState('1')
  const [tabCondo, setTabCondo] = useState('1')
  const [tabTemplate, setTabTemplate] = useState('1')
  const [manageVisible, setManageVisible] = useState(false)
  const [templateVisible, setTemplateVisible] = useState(false)
  const [remarkVisible, setRemarkVisible] = useState(false)
  const [contractRemark, setContractRemark] = useState('')
  const [contractId, setContractId] = useState('')
  const [propertyCode, setPropertyCode] = useState('')

  const isProjectCondo = useIsProjectCondo()
  const activeKey = isProjectCondo ? '2' : propertyModel.data.rentMode

  const positionRef = useRef(null)
  const supportCity = useCodeArraySync('city')
  // 商圈
  const [bizCircle, setBizCircle] = useState([])

  const [applyCheck] = useApplyCheck()
  // const [materialList, setMaterialList] = useState<{[key: string]: LessorWarehouseMaterialVO[]}>({})

  const [propertyStatusCode, setPropertyStatusCode] = useState('')
  const [propertyStatusForm] = Form.useForm()

  const houseNo = useCallback((form: PropertyChartVO) => {
    if (form.rentType === '3') {
      // 集中式 => X层+室号（模板名）
      return houseNoProperty.condoHouseNo({
        floor: form.floor,
        houseNo: form.houseNo,
        templateName: form.templateName,
      })
    } else {
      if (form.rentType === '1') {
        // 分散式整租 => 分散整租——X栋+X单元+室号
        return houseNoProperty.entireHouseNo({
          buildingNo: form.buildingNo,
          unitNo: form.unitNo,
          houseNo: form.houseNo,
        })
      } else {
        // 分散式合租 => X栋+X单元+室号+房间名（特殊命名，可没有）
        return houseNoProperty.jointHouseNo({
          buildingNo: form.buildingNo,
          unitNo: form.unitNo,
          houseNo: form.houseNo,
          roomName: form.roomName,
          roomCode: form.roomCode,
        })
      }
    }
  }, [])

  const contractRef = useRef<CancelContractMethod>(null)

  const [condoImgList, setCondoImgList] = useState<{imgId: string; imgUrl: string}[]>([])

  const [hisContract, setHisContract] = useState({visible: false, chartItem: {} as PropertyInfoCardVO})
  const [contractList, setContractList] = useState<ContractVO[]>([])

  // const businessTypeOption = useMemo(() => commonModel.getCodeArraySync('business.type'), [])
  const businessTypeOption = useCodeArraySync('business.type')
  const rentStatusOption = useCodeArraySync('rent.status')
  const picExistOption = useCodeArraySync('picExist')
  const contractStatus = useCodeSync('contract.status')
  // console.log('contractStatus', contractStatus)
  const communityOption = useMemo(() => communityList.map(item => ({id: item.code, label: item.name})), [communityList])
  // const rentCollectType = useCodeArraySync('rent.collect.type')
  // const occupancyStatus = useCodeArraySync('occupancy.status')
  // const occupancyStatusObj = useCodeSync('occupancy.status')

  const [cancelStatus, setCancelStatus] = useState({} as {[contractId: string]: boolean})

  const [searchValue, setSearchValue] = useState('')
  const [migrateForm, setMigrateForm] = useState({
    from: '',
    to: '',
  })
  const renderMigrate = {
    from: communityList.find(item => item.code === migrateForm.from),
    to: communityList.find(item => item.code === migrateForm.to),
  }

  function renderCommunity(item: CommunityVO) {
    return item ? (
      <>
        <p>所在地：{`${item?.cityName}-${item?.districtName}`}</p>
        <p>小区名称：{item?.name}</p>
        <p>小区地址：{item?.address}</p>
      </>
    ) : (
      <p className={styles.migrateEmpty}>请选择</p>
    )
  }

  async function migrateCommunity() {
    if (!migrateForm.from) return message.error('请选择原小区')
    if (!migrateForm.to) return message.error('请选择目标小区')
    if (migrateForm.from === migrateForm.to) return message.error('原小区不能是目标小区')
    await propertyModel.propertyMigrateCommunity(migrateForm)
    message.success('迁移成功')
    setMigrateVisible(false)
    search(true)
  }

  const migrateList = communityList
    .filter(item => (searchValue ? `${item.districtName}${item.name}`.includes(searchValue) : true))
    .map(item => ({
      // value: item.code,
      // label: `${item.districtName}-${item.name}`,
      value: `${item.districtName}-${item.name}`,
      label: (
        <div className={styles.migrate} onClick={event => event.stopPropagation()}>
          <Text>{`${item.districtName}-${item.name}`}</Text>
          <Space size={20}>
            <span
              className={`${styles.migrateColor1} ${item.code === migrateForm.from && styles.migrateSelected}`}
              onClick={() => setMigrateForm(prevState => ({...prevState, from: item.code}))}
            >
              设为原小区
            </span>
            <span
              className={`${styles.migrateColor2} ${item.code === migrateForm.to && styles.migrateSelected}`}
              onClick={() => setMigrateForm(prevState => ({...prevState, to: item.code}))}
            >
              设为目标小区
            </span>
          </Space>
        </div>
      ),
    }))

  // 房屋类型
  // const propertyType = useCodeArraySync('property.type')
  // 电梯
  // const lift = [
  //   {label: '是', value: '1'},
  //   {label: '否', value: '0'},
  // ]

  const pickerConfig = [
    {
      id: 'businessType',
      label: '业务状态',
      options: [{id: '', label: '全部'}, ...businessTypeOption.filter(item => item.id !== 'reserve')],
    },
    {
      id: 'rentStatus',
      label: '出租状态',
      options: [{id: '', label: '全部'}, ...rentStatusOption],
    },
    {id: 'picExist', label: '是否有照片', options: [{id: '', label: '全部'}, ...picExistOption]},
  ]

  const renderFilter = () => {
    return pickerConfig.map(config =>
      config.options.length ? (
        <Form.Item key={config.id} shouldUpdate label={config.label} labelCol={{span: 2}} style={{marginBottom: 0}}>
          {() => (
            <Form.Item name={config.id} style={{marginBottom: 0}}>
              <Radio.Group>
                {config.options.map(item => (
                  <Radio key={item.id} value={item.id} style={{marginLeft: '20px'}}>
                    {item.label}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
          )}
        </Form.Item>
      ) : null
    )
  }

  const {list, total, finish, loading, searchNextByModel} = usePagination<PropertyChartVO>()
  // 1:整租 2:合租
  // 3: condo， 集中公寓
  // 只需要做好 rentType 和 rentMode 的转换就没问题，逻辑没 app 那么复杂
  const rentType = list?.[0]?.rentType
  // const rentMode = useMemo(() => (list?.[0]?.rentType === '3' ? '2' : '1'), [list])

  const chartType = rentType === '3' ? 'condo' : 'scatter'
  useEffect(
    function () {
      propertyModel
        // .propertyCommunityList({communityType: props.chartType === 'condo' ? '2' : '1'})
        .propertyCommunityList({communityType: rentType === '3' ? '2' : '1'})
        .then(({response: {data}}) => setCommunityList(data))
    },
    [rentType]
  )

  const [dropList, setDropList] = useState({} as {[propertyCode: string]: PropertyInfoCardVO})

  const queryDropList = useCallback(function (propertyCode: string) {
    propertyModel
      .propertyCard({propertyCode})
      .then(({response: {data}}) => setDropList(prevState => ({...prevState, [propertyCode]: data})))
  }, [])
  const dropLoading = propertyModel.propertyCard.useLoading()

  const search = useCallback(
    function (initialSearch = false) {
      const cond = {
        // rentType: isCondo(props.chartType) ? '2' : '1',
        ...form.getFieldsValue(true),
      }
      // 房源添加时间
      if (cond.insertTime) {
        const [insertTimeBegin, insertTimeEnd] = cond.insertTime.map(item => dayjs(item).format('YYYY-MM-DD HH:mm:ss'))
        cond.insertTimeBegin = insertTimeBegin
        cond.insertTimeEnd = insertTimeEnd
      }
      // 数组转字符串
      const data = Object.keys(cond).reduce(
        (result, key) => ({
          [key]: Array.isArray(cond[key]) ? cond[key].join(',') : cond[key],
          ...result,
        }),
        {pageSize: 100}
      )
      // 出租状态 & 是否有照片全选的问题
      let {rentStatus, picExist} = data as PropertyChartDTO
      if (rentStatus && rentStatus.split(',').length === rentStatusOption.length) {
        rentStatus = ''
      }
      if (picExist && picExist.split(',').length === picExistOption.length) {
        picExist = ''
      }
      searchNextByModel(propertyModel.propertyPropertyChartList, {
        data: {...data, rentStatus, picExist},
        initialSearch,
      })

      // if (props.chartType === 'condo') {
      //   // 集中式房态图列表
      //   searchNextByModel(propertyModel.condoPropertyChartList, {
      //     data,
      //     initialSearch,
      //   })
      // } else {
      //   // 分散式房态图列表
      //   searchNextByModel(propertyModel.scatterPropertyChartList, {
      //     data,
      //     initialSearch,
      //   })
      // }
    },
    [form, picExistOption.length, rentStatusOption.length, searchNextByModel]
  )

  useEffect(function () {
    form.setFieldsValue({businessType: '', rentStatus: '', picExist: ''})
    search()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [searchResult, setSearchResult] = useState<PropertyInfoVO[]>([])
  const renderOptions = useMemo(() => searchResult.map(item => ({value: item.title, label: item.title})), [
    searchResult,
  ])

  async function handleInput(keyword: string) {
    // setSearchValue(keyword)
    if (keyword.length) {
      propertyModel.propertySearchSuggestion({keyword, rentType}).then(({response: {data}}) => setSearchResult(data))
    }
  }

  function getFloorList(listStr: string) {
    return (listStr || '')
      .split(',')
      .map(item => +item)
      .sort()
  }

  const renderList = useMemo(
    () =>
      // 公寓： 3
      // 集中式公寓
      rentType === '3'
        ? renderSectionList(list, {
            renderTitle: item => (isProjectCondo ? item.communityName : `${item.districtName}-${item.communityName}`),
            groupBy: item => item.communityCode,
            extProps: item => ({
              rentType: item.rentType,
              count: item.propertyCount,
              rentCount: item.rentPropertyCount,
              unRentRate: item.unRentRate,
              floorList: getFloorList(item.floorList), // 层
              communityCode: item.communityCode,
              condoId: item.condoId,
              districtName: item.districtName,
              buildingNo: item.buildingNo,
            }),
          }).map(list => ({
            ...list,
            data: renderSectionList(list.data, {
              renderTitle: item => `${item.buildingNo || '/'}栋 第${item.floor}层`,
              groupBy: item => `${item.buildingNo} ${item.floor}`,
              extProps: item => ({
                propertyCode: item.propertyCode,
              }),
            }),
          }))
        : // 合租
          // 分散式房源
          renderSectionList(list, {
            renderTitle: item => `${item.districtName}-${item.communityName}`,
            groupBy: item => item.communityCode,
            extProps: item => ({
              rentType: item.rentType,
              count: item.propertyCount,
              rentCount: item.rentPropertyCount,
              unRentRate: item.unRentRate,
              // condoPropertyCount: item.condoPropertyCount,
              districtName: item.districtName,
            }),
          }).map(
            list => ({
              ...list,
              data: renderSectionList(list.data, {
                // renderTitle: item => `${item.buildingNo}栋 - ${item.unitNo || ''}单元 - ${item.houseNo}`,
                // groupBy: item => item.buildingNo + `${item.unitNo || ''}` + item.houseNo,
                renderTitle: item =>
                  `${item.buildingNo}栋${item.unitNo ? ` - ${item.unitNo}单元` : ''}${
                    item.houseNo && item.rentType === '2' ? ` - ${item.houseNo}` : ''
                  }`,
                groupBy: item =>
                  item.rentType === '1'
                    ? `b${item.buildingNo}${item.unitNo ? `-u${item.unitNo}` : ''}`
                    : `b${item.buildingNo}${item.unitNo ? `-u${item.unitNo}` : ''}-h${item.houseNo}`,
                extProps: item => ({
                  rentType: item.rentType, // 用于显示整租/合租
                  buildingNo: item.buildingNo,
                  unitNo: item.unitNo || '',
                  houseNo: item.houseNo,
                  propertyCode: item.propertyCode,
                }),
              }),
              //   .sort((a, b) => {
              //   if (a.buildingNo !== b.buildingNo) return a.buildingNo - b.buildingNo
              //   return a.unitNo - b.unitNo
              // }),
            })
            // rentMode === '1'
            // ? renderSectionList(list, {
            //     renderTitle: item => `${item.districtName}-${item.communityName}`,
            //     groupBy: item => item.communityCode,
            //     extProps: item => ({
            //       // rentType: item.rentType,
            //       count: item.propertyCount,
            //       rentCount: item.rentPropertyCount,
            //       unRentRate: item.unRentRate,
            //       // condoPropertyCount: item.condoPropertyCount,
            //       districtName: item.districtName,
            //     }),
            //   }).map(list => ({
            //     ...list,
            //     data: renderSectionList(list.data, {
            //       renderTitle: item => `${item.buildingNo}栋 - ${item.unitNo || ''}单元 - ${item.houseNo}`,
            //       groupBy: item => item.buildingNo + `${item.unitNo || ''}` + item.houseNo,
            //       extProps: item => ({
            //         rentType: item.rentType,
            //         buildingNo: item.buildingNo,
            //         unitNo: item.unitNo || '',
            //         houseNo: item.houseNo,
            //       }),
            //     })
            //     //   .sort((a, b) => {
            //     //   if (a.buildingNo !== b.buildingNo) return a.buildingNo - b.buildingNo
            //     //   return a.unitNo - b.unitNo
            //     // }),
            //   }))
            // : // 整租
            //   renderSectionList(list, {
            //     renderTitle: item => `${item.communityName}`,
            //     groupBy: item => item.communityCode,
            //     extProps: item => ({
            //       rentType: item.rentType,
            //       count: item.propertyCount,
            //       rentCount: item.rentPropertyCount,
            //       unRentRate: item.unRentRate,
            //       // condoPropertyCount: item.condoPropertyCount,
            //       districtName: item.districtName,
            //     }),
            //   }),
          ),
    [isProjectCondo, list, rentType]
  )

  // 朝向
  // const orientation = useCodeArraySync('property.orientation')
  const orientationType = useCodeSync('property.orientation')
  // const rentTypeObj = commonModel.getCodeSync('rentType')

  const editLoading = Model.isLoading(
    propertyModel.propertyDetail.useLoading(),
    propertyModel.templateList.useLoading()
  )

  const manageLoading = propertyModel.condoManageInfo.useLoadings()

  // 是否需要显示房源编号
  const isChecked = configModel.useCheckedStatus()

  // 换房
  const chargeModalRef = useRef(null)

  // 弹出卡片 card 数据（比较详细） / 房态图数据
  const renderItem = function (chartItem = {} as PropertyInfoCardVO, property: PropertyChartVO) {
    const propertyRentType = property.rentType
    const chartStatus = property.propertyChartStatusList?.[0]?.chartStatus
    // 整租 | 合租
    const isJoin = propertyRentType === '2' // 是否是合租
    // 已录入状态 | 为录入状态
    // const isSign = ['expire.soon', 'overdue', 'expire', 'collect.soon'].includes(chartStatus)

    const isRentable = !chartItem.contractId && !chartItem.reserveContractId

    const payType = combine([
      chartItem.rentPayType && `付${chartItem.rentPayType}`,
      chartItem.rentPledgeType && `押${chartItem.rentPledgeType}`,
    ])

    // const isUnRent = property.propertyChartStatusList?.find(value =>
    //   ['unrent', 'fix', 'employee'].includes(value.chartStatus)
    // )
    // const firstChartStatus = isUnRent?.chartStatus

    // 装修、自住
    const isFixOrEmployee = chartItem.propertyChartStatusList?.some(value =>
      ['fix', 'employee'].includes(value.chartStatus)
    )

    const cancelable = cancelStatus[property.contractId]

    //是否添加了业主合同
    // const hasOwner = false

    // const less
    const lesseeName = chartItem.lesseeCertName || property.lesseeName

    const isProjectCondoType = chartItem.rentType === '3' && isProjectCondo
    function styleStatus() {
      // 默认值为 false，对应 filter false 的判断
      const status = {
        // 集中式项目状态
        condoFixOrEmployee: false,
        condoRent: false,
        condoReserve: false,
        condoUnrent: false,
      }
      if (isProjectCondoType) {
        // todo: 让中哥加上 occupancy_status 这个字段
        // 集中式项目公寓装修、自住房源
        if (isFixOrEmployee) return {...status, condoFixOrEmployee: true}
        // 集中式项目公寓预定房源
        if (chartItem.reserveStatus === '1') return {...status, condoReserve: true}
        // 集中式项目公寓在住房源
        if (chartItem.rentStatus === '1') return {...status, condoRent: true}
        // 集中式项目公寓空房源
        return {...status, condoUnrent: true}
      }

      return status
    }

    const {condoFixOrEmployee, condoRent, condoReserve, condoUnrent} = styleStatus()

    const list: RenderItem[] = [
      {
        title: '房源信息',
        handles: [
          // 集中式公寓：(未租、预定、出租状态不显示 | 装修，自住不显示)
          ...(!(condoUnrent || condoReserve || condoRent) && !condoFixOrEmployee
            ? [
                {
                  when: hasPermission('002001004'),
                  title: '编辑房源',
                  loading: editLoading,
                  onClick: async () => {
                    setEditProperty(chartItem)
                  },
                },
                {
                  when: !chartItem.contractLessorId && hasPermission('002001006'),
                  title: '添加业主合同',
                  onClick: () =>
                    history.push('/landlord-contract', {
                      propertyCode: chartItem.propertyCode,
                      chartType,
                    }),
                },
                {
                  when: !!chartItem.contractLessorId && hasPermission('002001007'),
                  title: '查看业主合同',
                  onClick: () => history.push('/contract/owner', {contractId: chartItem.contractLessorId}),
                },
              ]
            : []),

          {
            // isFixOrEmployee 包含 condoFixOrEmployee
            when: isRentable && hasPermission('002001009') && !isFixOrEmployee,
            title: '预定房源',
            customer: true,
            onClick: () =>
              history.push('/contract/reserve', {
                propertyCode: chartItem.propertyCode,
                communityName: chartItem.title,
                rentType: chartItem.rentType,
                rentPrice: chartItem.rentPrice,
                from: 'reserve',
                communityCode: chartItem.communityCode,
              }),
          },
          {
            // 没有"预定房源"就不会有"取消预定"和"查看预定详情"
            when: chartStatus === 'reserve' && hasPermission('002001010'),
            title: '取消预定',
            // Component: Button2,
            customer: true,
            onClick: async (_, condoProject: boolean) => {
              setPropertyCode(chartItem.propertyCode)
              const {
                response: {data},
              } = await contractModel.contractQueryContract({contractId: chartItem.reserveContractId})
              contractRef.current.show({
                contractId: chartItem.reserveContractId,
                contractTime: `${chartItem.contractBegin || property.contractBegin}至${
                  chartItem.contractEnd || property.contractEnd
                }`,
                lesseeName: lesseeName,
                reservePrice: data.reservePricePaid === 1 ? data.reservePrice : 0,
                title: chartItem.title,
                condoProject,
                communityCode: chartItem.communityCode,
              })
            },
          },
          {
            when: chartStatus === 'reserve' && hasPermission('003003001'),
            title: '查看预定详情',
            onClick: () =>
              history.push('/reserve/detail', {
                contractId: chartItem.reserveContractId,
                propertyTitle: chartItem.title,
              }),
          },
          {
            when: isJoin && hasPermission('002001008'),
            title: '添加房间',
            onClick: () => {
              addForm.setFieldsValue({
                // ...chartItem,
                code: chartItem.roomCode,
                propertyCode: chartItem.propertyCode,
                title: chartItem.houseNo,
                rentStatus: '0',
                selfKitchen: '0',
                selfBathroom: '0',
              })
              setVisible(true)
            },
          },
          {
            when: !chartItem.contractId && hasPermission('002001011') && !isFixOrEmployee,
            title: '租客登记',
            customer: true,
            onClick: async () => {
              if (chartItem.reserveStatus === '1') {
                // message
                Modal.confirm({
                  title: '提示',
                  content: (
                    <>
                      <Typography.Text>
                        {`是否将${lesseeName ? `${lesseeName}的` : '此'}预订单转为租约`}？
                      </Typography.Text>
                      <br />
                      <Typography.Text>如果要录入其他租约请先撤销预定</Typography.Text>
                    </>
                  ),
                  onOk: () =>
                    history.push('/contract/register', {
                      contractId: chartItem.reserveContractId,
                      from: 'reserve-lease',
                    }),
                })
              } else
                history.push('/contract/register', {
                  propertyCode: chartItem.propertyCode,
                  // communityName: chartItem.title,
                })
            },
          },
          ...(!isProjectCondoType
            ? [
                {
                  when: isRentable && hasPermission('002001005'),
                  title: '删除房源',
                  onClick: () => {
                    Modal.confirm({
                      content: '确认删除？',
                      zIndex: 99999,
                      onOk() {
                        propertyModel.propertyDelete({propertyCode: chartItem.propertyCode}).then(() => {
                          search(true)
                        })
                      },
                    })
                  },
                },
              ]
            : []),
          // {
          //   when: hasPermission(PermissionEnum.PERMISSION_EDIT_PROPERTY),
          //   title: '添加业主合同',
          //   loading: editLoading,
          //   onClick: () => {
          //     history.push('/landlord-contract', {propertyCode: chartItem.propertyCode, chartType: props.chartType})
          //   },
          // },
          //
          {
            when: hasPermission('002001012'),
            title: '历史租约',
            onClick: () => {
              contractModel
                .queryContractList({pageNum: 1, pageSize: 10, pageable: true, propertyCode: chartItem.propertyCode})
                .then(({response: {data}}) => {
                  // console.log('data ===>>>', data)
                  setContractList(data)
                  setHisContract({visible: true, chartItem})
                })
            },
          },
          {
            when: condoUnrent, // 集中式项目空房源才有"房源状态"
            title: '房源状态',
            onClick: () => {
              setPropertyStatusCode(chartItem.propertyCode)
            },
          },
          ...(!condoRent
            ? [
                {
                  when: !!property.contractId,
                  title: property.sublease === '1' ? '取消转租' : '转租房源',
                  onClick: async () => {
                    const isSublease = property.sublease === '1'
                    if (!isSublease) {
                      await applyCheck(chartItem.contractId)
                    }
                    Modal.confirm({
                      content: isSublease
                        ? `是否确认取消转租${houseNo(property)}？`
                        : `是否确认将${houseNo(property)}更改为转租中？`,
                      zIndex: 1100,
                      onOk: async () => {
                        await propertyModel.propertySubleaseUpdate({
                          propertyCode: property.propertyCode,
                          sublease: isSublease ? '0' : '1',
                        })
                        message.success('设置成功')
                        search(true)
                        queryDropList(property.propertyCode)
                      },
                    })
                  },
                },
                {
                  when: property.sublease === '1',
                  title: '立即转租',
                  onClick: () => {
                    const location = {
                      pathname: '/contract',
                      state: {contractId: chartItem.contractId, checkout: true, communityCode: chartItem.communityCode},
                    }
                    history.push(location)
                  },
                },
              ]
            : []),
        ],
        labels: isProjectCondoType
          ? [
              {label: '房源编号', value: chartItem.propertyCode},
              {when: condoFixOrEmployee, label: '房源添加时间', value: chartItem.insertTime},
              {when: condoRent || condoReserve || condoUnrent, label: '房型', value: property.templateName},
              {label: '户型', value: renderProperty.roomType(chartItem)},
              {label: '朝向', value: combine([chartItem.orientation && orientationType[chartItem.orientation]])},
              {label: '房间面积', value: combine([chartItem.floorage && `${chartItem.floorage}m²`])},
              {label: '租金', value: `${chartItem.rentPrice}元/月，${payType}`},
              {when: condoRent || condoReserve, label: '房源描述', value: chartItem.desc ?? '无'},
              {when: condoFixOrEmployee, label: '所属团队', value: chartItem.teamName},
            ]
          : [
              {label: '系统房源编号', value: chartItem.propertyCode},
              ...(isChecked ? [{label: '自定义房源编号', value: chartItem.propertyCodeCustom ?? '无'}] : []),
              {label: '房源添加时间', value: chartItem.insertTime},
              {label: '实际户型', value: renderProperty.roomType(chartItem)},
              ...(isJoin
                ? [
                    {label: '独立厨房', value: chartItem.selfKitchen === '1' ? '有' : '无'},
                    {label: '独立卫生间', value: chartItem.selfBathroom === '1' ? '有' : '无'},
                  ]
                : []),
              {label: '朝向', value: combine([chartItem.orientation && orientationType[chartItem.orientation]])},
              {label: '房间面积', value: combine([chartItem.floorage && `${chartItem.floorage}m²`])},
              {label: '对外标价', value: `${chartItem.rentPrice}元/月，${payType}`},
              {label: '所属团队', value: chartItem.teamName},
            ],
      },
      {
        when: !!chartItem.contractId,
        title: '租约信息',
        handles: [
          {
            when: hasPermission('002001013'),
            title: '租约详情',
            onClick: () => {
              const location = {
                pathname: '/contract/detail',
                state: {contractId: chartItem.contractId},
              }
              history.push(location)
            },
          },
          {
            when: cancelable && hasPermission('002001014'),
            title: '作废租约',
            customer: true,
            preCheck: async () => await applyCheck(chartItem.contractId),
            onClick: () => {
              const location = {
                pathname: '/contract',
                state: {contractId: chartItem.contractId, cancellation: true},
              }
              history.push(location)
            },
          },
          {
            when: cancelable && hasPermission('003001016') && !chartItem.contractRenewId,
            title: '租约修改',
            onClick: async () => {
              await applyCheck(chartItem.contractId)
              history.push({
                pathname: '/contract/register',
                state: {contractId: chartItem.contractId, from: 'alter'},
              })
            },
          },
          {
            when:
              ['1', '2', '3'].includes(chartItem.contractStatus) &&
              hasPermission('002001016') &&
              !chartItem.contractRenewId,
            title: '退房',
            customer: true,
            preCheck: async () => await applyCheck(chartItem.contractId),
            onClick: (_, condoProject: boolean) => {
              const location = {
                pathname: '/contract',
                state: {
                  contractId: chartItem.contractId,
                  contractEnd: chartItem.contractEnd,
                  communityCode: chartItem.communityCode,
                  checkout: true,
                  condoProject,
                },
              }
              history.push(location)
            },
          },
          {
            when: chartItem.contractStatus === '2' && hasPermission('002001015') && !chartItem.contractRenewId,
            title: '续约',
            customer: true,
            preCheck: async () => await applyCheck(chartItem.contractId),
            onClick: (_, condoProject: boolean) => {
              if (condoProject && dayjs().add(60, 'day').isBefore(dayjs(chartItem.contractEnd))) {
                return message.warning('距离租期结束超过60天，不可提前续约')
              }
              if (chartItem.reserveContractId) return message.warning('该房源已超前预订，不可进行续约!')
              history.push('/contract/register', {
                from: 'renewal',
                contractId: chartItem.contractId,
                rentType: chartItem.rentType,
                communityCode: chartItem.communityCode,
              })
            },
          },
          {
            when:
              chartItem.contractStatus === '2' &&
              dayjs().isBefore(dayjs(chartItem.contractEnd)) &&
              hasPermission('003001017') &&
              condoRent &&
              !chartItem.contractRenewId,
            title: '换房',
            onClick: async () => {
              await applyCheck(chartItem.contractId)
              chargeModalRef.current.onCharge({
                contractId: chartItem.contractId,
                rentType: chartItem.rentType,
                communityCode: chartItem.communityCode,
              })
            },
          },
          ...(!condoRent
            ? [
                {
                  when: hasPermission('002001019'),
                  title: '合同预览',
                  onClick: () => {
                    const location = {
                      pathname: '/contract/detail',
                      state: {contractId: chartItem.contractId, activeKey: 'contractInfo'},
                    }
                    history.push(location)
                  },
                },
              ]
            : []),
          {
            when:
              chartItem.contractStatus === '2' &&
              !chartItem.reserveContractId &&
              dayjs(chartItem.contractEnd).diff(dayjs(), 'day') >= 0 &&
              dayjs(chartItem.contractEnd).diff(dayjs(), 'day') < 30 &&
              !chartItem.contractRenewId,
            title: '预定锁房',
            onClick: () => {
              history.push('/contract/reserve', {
                propertyCode: chartItem.propertyCode,
                communityName: chartItem.title,
                rentType: chartItem.rentType,
                rentPrice: chartItem.rentPrice,
                communityCode: chartItem.communityCode,
                from: 'ahead-reverse',
              })
            },
          },
          {
            when: dayjs(chartItem.contractEnd).diff(dayjs(), 'day') < 30 && isProjectCondo,
            title: '续订锁房',
            onClick: async () => {
              let renewInfo = {} as ContractRenewVO
              if (chartItem.contractRenewId) {
                const {
                  response: {data},
                } = await contractModel.contractRenewDetail({renewId: chartItem.contractRenewId})
                renewInfo = data
              }
              renewForm.setFieldsValue({contractBegin: dayjs(chartItem.contractEnd).add(1, 'day')})
              Modal.confirm({
                title: '续订锁房',
                icon: null,
                content: (
                  <Form
                    form={renewForm}
                    onFinish={async formValues => {
                      const renewBeginTime = dayjs(formValues.contractBegin).format('YYYY-MM-DD HH:mm:ss')
                      const renewEndTime = dayjs(formValues.contractEnd).format('YYYY-MM-DD HH:mm:ss')
                      await contractModel.contractRenewInsert({
                        contractId: chartItem.contractId,
                        renewBeginTime,
                        renewEndTime,
                      })
                      message.success('操作成功！')
                      queryDropList(chartItem.propertyCode)
                    }}
                  >
                    <Form.Item label={'承租人'} style={{marginTop: '24px'}}>
                      {chartItem.lesseeCertName}
                    </Form.Item>
                    <Form.Item label={'手机号'}>{chartItem.lesseeMobile}</Form.Item>
                    {chartItem.contractRenewId ? (
                      <>
                        <Form.Item label={'租约起止'}>
                          {[renewInfo.renewBeginTime, renewInfo.renewEndTime].join('至')}
                        </Form.Item>
                        <Form.Item label={'操作人'}>{renewInfo.operatorName}</Form.Item>
                        <Form.Item label={'操作时间'}>{renewInfo.insertTime}</Form.Item>
                      </>
                    ) : (
                      <Form.Item noStyle shouldUpdate>
                        {f => (
                          <>
                            <Form.Item label={'约定租期'} required>
                              <Space size={12}>
                                <Form.Item name={'contractBegin'} noStyle>
                                  <DatePicker style={{width: '100%'}} placeholder={'开始日期'} disabled />
                                </Form.Item>
                                <SwapRightOutlined style={{color: 'rgba(0, 0, 0, 0.25)'}} />
                                <Form.Item name={'contractEnd'} noStyle>
                                  <DatePicker style={{width: '100%'}} placeholder={'结束日期'} disabled />
                                </Form.Item>
                              </Space>
                            </Form.Item>
                            <Space>
                              <Form.Item name={'month'} rules={[{required: true, message: '请选择租住月数'}]}>
                                <Radio.Group
                                  onChange={({target: {value}}) => {
                                    const contractBegin = f.getFieldValue('contractBegin')
                                    const begin = dayjs(contractBegin || chartItem.contractEnd)
                                    const end = dayjs(begin).add(value, 'month').subtract(1, 'day')
                                    f.setFieldsValue({contractBegin: begin, contractEnd: end})
                                  }}
                                >
                                  <Radio.Button value={1}>1个月</Radio.Button>
                                  <Radio.Button value={3}>3个月</Radio.Button>
                                  <Radio.Button value={6}>6个月</Radio.Button>
                                  <Radio.Button value={12}>1年</Radio.Button>
                                </Radio.Group>
                              </Form.Item>
                              <Form.Item name={'month'}>
                                <Select
                                  allowClear
                                  style={{minWidth: '100px'}}
                                  onSelect={(value: number) => {
                                    const contractBegin = f.getFieldValue('contractBegin')
                                    const begin = dayjs(contractBegin || chartItem.contractEnd)
                                    const end = dayjs(begin).add(value, 'month').subtract(1, 'day')
                                    f.setFieldsValue({contractBegin: begin, contractEnd: end})
                                  }}
                                  getPopupContainer={triggerNode => triggerNode.parentNode}
                                >
                                  {[2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(item => (
                                    <Option key={item} value={item}>
                                      {item}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                              月
                            </Space>
                          </>
                        )}
                      </Form.Item>
                    )}
                  </Form>
                ),
                okText: chartItem.contractRenewId ? '前往续约' : '确定',
                okCancel: !!chartItem.contractRenewId,
                cancelText: '取消续订',
                closable: true,
                width: '516px',
                centered: true,
                onOk: async () => {
                  if (chartItem.contractRenewId) {
                    //  前往续约
                    history.push('/contract/register', {
                      from: 'renewal',
                      contractId: chartItem.contractId,
                      rentType: chartItem.rentType,
                      communityCode: chartItem.communityCode,
                    })
                  } else {
                    // 续订锁房
                    await renewForm.validateFields()
                    renewForm.submit()
                  }
                },
                onCancel: async ({triggerCancel}) => {
                  if (!triggerCancel && chartItem.contractRenewId) {
                    await contractModel.contractRenewCancel({renewId: chartItem.contractRenewId})
                    message.success('操作成功！')
                    queryDropList(chartItem.propertyCode)
                  }
                },
                afterClose: () => renewForm.resetFields(),
                zIndex: 2000,
              })
            },
          },
        ],
        labels: isProjectCondoType
          ? [
              {label: '租约编号', value: chartItem.contractId},
              {label: '承租人', value: chartItem.lesseeCertName},
              {label: '承租人电话', value: chartItem.lesseeMobile},
              {label: '租约起始', value: `${chartItem.contractBegin}至${chartItem.contractEnd}`},
              {
                label: '租金',
                value: chartItem?.contractRentPriceDiscount ? (
                  <Text>
                    <Text delete style={{color: '#999'}}>
                      {chartItem?.contractRentPrice?.toFixed(2)}元，
                    </Text>
                    {chartItem?.contractRentPriceDiscount?.toFixed(2)}元
                  </Text>
                ) : (
                  `${chartItem?.contractRentPrice?.toFixed(2)}元`
                ),
              },
              {label: '租约备注', value: chartItem.contractRemark ?? '无'},
            ]
          : [
              {label: '租约编号', value: chartItem.contractId},
              {label: '承租人', value: chartItem.lesseeCertName},
              {label: '租金', value: `${chartItem?.contractRentPrice?.toFixed(2)}元`},
              {label: '租约状态', value: contractStatus[chartItem.contractStatus]},
              {label: '承租人电话', value: chartItem.lesseeMobile},
              {label: '租约起始', value: `${chartItem.contractBegin}至${chartItem.contractEnd}`},
              {
                label: '付款方式',
                value: combine([
                  chartItem.contractRentPayType && `付${chartItem.contractRentPayType}`,
                  chartItem.contractRentPledgeType && `押${chartItem.contractRentPledgeType}`,
                ]),
              },
            ],
      },
      {
        when: condoReserve,
        title: '预定信息',
        handles: [],
        labels: [{label: '预订人', value: `${chartItem.lesseeCertName}-${chartItem.lesseeMobile}`}],
      },
      {
        when: !!chartItem.contractId,
        title: '账单信息',
        handles: [
          {
            when: hasPermission('002001020'),
            title: '查看账单',
            onClick: () => {
              const location = {
                pathname: '/contract/detail',
                state: {contractId: chartItem.contractId, activeKey: 'billInfo'},
              }
              history.push(location)
            },
          },
          {
            when: hasPermission('002001021'),
            title: '添加账单',
            onClick: () => {
              const location = {
                pathname: '/contract/detail',
                state: {contractId: chartItem.contractId, activeKey: 'billInfo', addBill: true},
              }
              history.push(location)
            },
          },
        ],
        labels: [
          {label: '当前租金欠款（总）', value: chartItem.billAmtUnpaid},
          {label: '最近一笔租金付款日', value: chartItem.nextBillDate ?? '当前租约无租金账单'},
        ],
      },
      {
        when: !(condoRent || condoReserve),
        title: '房源备注',
        handles: [],
        labels: [{label: '', value: chartItem.remark ?? '无'}],
      },
      {
        when: !!chartItem.contractId && !condoRent,
        title: '租约备注',
        handles: [
          {
            title: '编辑备注',
            onClick: () => {
              setRemarkVisible(true)
              setContractRemark(chartItem.contractRemark)
              setContractId(chartItem.contractId)
              setPropertyCode(chartItem.propertyCode)
            },
          },
        ],
        labels: [
          {
            label: '',
            value: chartItem.contractRemark ?? '无',
          },
        ],
      },
    ]

    return list.map(item =>
      // handle.when !== false 不要动
      item.when !== false ? (
        <div key={item.title} className={styles.detail}>
          <div className={styles.detailLeft}>
            <Title level={4}>{item.title}</Title>
            {/*handle.when 仅为 false 时才过滤，true 或 undefined 时不需要过滤。以下同理*/}
            {item.labels
              .filter(value => value.when !== false)
              .map((label, index) => (
                <Text style={{width: 320}} key={`${label.label}-${index}`}>
                  {`${label.label ? `${label.label}：` : ''}`}
                  {React.isValidElement(label.value) ? label.value : `${label.value}`}
                </Text>
              ))}
          </div>
          <div className={styles.detailRight}>
            {/*以下*/}
            {item.handles
              .filter(value => value.when !== false)
              .map(handle =>
                handle.customer ? (
                  <AuditButton
                    loading={handle.loading}
                    key={handle.title}
                    type={'link'}
                    preCheck={handle.preCheck}
                    onClick={handle.onClick}
                    className={styles.detailLink}
                    rentType={chartItem.rentType}
                    communityCode={chartItem.communityCode}
                    propertyCode={chartItem.propertyCode}
                    contractId={chartStatus === 'reserve' ? chartItem.reserveContractId : chartItem.contractId}
                  >
                    {handle.title}
                  </AuditButton>
                ) : (
                  <Button
                    loading={handle.loading}
                    key={handle.title}
                    type={'link'}
                    onClick={handle.onClick}
                    className={styles.detailLink}
                  >
                    {handle.title}
                  </Button>
                )
              )}
          </div>
        </div>
      ) : null
    )
  }

  const renderCard = (property: PropertyChartVO) => {
    const cardRentType = property.rentType

    const isUnRent = property.propertyChartStatusList?.find(_item =>
      ['unrent', 'fix', 'employee'].includes(_item.chartStatus)
    )
    const chartStatusText = isUnRent ? {unrent: '空', fix: '装修', employee: '自住'}[isUnRent.chartStatus] : ''

    const getCardStatus = () => {
      // 装修，自住
      if (property.propertyChartStatusList.some(value => ['fix', 'employee'].includes(value.chartStatus))) return '3'
      // 未出租
      if (!property.contractId && !property.reserveContractId) return '1'

      return '2'
    }
    const cardStatus = getCardStatus()

    // 默认(2)：蓝，装修(3)：绿，未出租(1)：红
    const cardStyle = {
      '1': {property: styles.propertyUnRent, bottom: styles.propertyBottomUnRent},
      '2': {property: '', bottom: ''},
      '3': {property: styles.propertySelf, bottom: styles.propertyBottomSelf},
    }[cardStatus]

    return (
      <Popover
        key={property.propertyCode}
        placement={'right'}
        trigger='click'
        onVisibleChange={visible => {
          if (visible && !dropList[property.propertyCode]) {
            queryDropList(property.propertyCode)
          }
          if (visible && property.contractId) {
            contractModel
              .contractQueryCancelStatus({contractId: property.contractId})
              .then(({response: {data}}) => setCancelStatus(prev => ({...prev, [property.contractId]: !!data})))
          }
        }}
        destroyTooltipOnHide={{keepParent: false}}
        content={
          <div className={styles.propertyPopover}>
            <Skeleton active loading={dropLoading} className={styles.propertySkeleton}>
              <div className={styles.detail}>
                <div className={styles.detailLeft}>
                  <Title level={4}>{property.houseNo}</Title>
                  {dropList[property.propertyCode]?.propertyChartStatusList?.map(status => (
                    <div key={status.chartStatus} className={styles.detailLeftRow}>
                      {renderItemStatus[status.chartStatus]}
                      <Text>{status.chartStatusDesc}</Text>
                    </div>
                  ))}
                </div>
                <div className={styles.detailRight}>
                  <Button type={'text'} style={{fontWeight: 'bold'}}>
                    编辑操作
                  </Button>
                </div>
              </div>
              {renderItem(dropList[property.propertyCode], property)}
            </Skeleton>
          </div>
        }
      >
        <div className={`${styles.property} ${cardStyle.property}`}>
          <div className={styles.propertyWrapper}>
            <Row justify={'space-between'} align={'middle'}>
              <Col flex={'1 1 100px'} className={styles.cardRow}>
                <Title level={3} style={{marginBottom: 0}}>
                  {cardRentType === '2' ? chartJoint.roomName(property) : property.houseNo}
                  {/*{[communityTitle.renderWhole(property), chartJoint.roomName(property), property.houseNo][+cardRentType - 1]}*/}
                </Title>
              </Col>
              {/*<Col>{cardRentType !== '3' && <Text style={{minWidth: 30}}>{rentTypeObj[cardRentType]}</Text>}</Col>*/}
            </Row>

            <div className={styles.propertyTag}>
              {/*状态标签*/}
              {property.propertyChartStatusList?.map(status => renderItemStatus[status.chartStatus])}
            </div>
          </div>
          <div className={`${styles.propertyBottom} ${cardStyle.bottom}`}>
            {['1', '3'].includes(cardStatus) ? (
              !!isUnRent?.value && (
                <Text>
                  {chartStatusText}
                  <Text style={{color: '#FF2929'}}>{isUnRent?.value}</Text>天
                </Text>
              )
            ) : (
              <Text>{property.rentPrice ? `${property.rentPrice}元/月` : ''}</Text>
            )}
            <Text>{property.lesseeName}</Text>
          </div>
        </div>
      </Popover>
    )
  }

  const options = [
    {label: '未租', value: '0'},
    {label: '已租', value: '1'},
  ]

  async function addRoom() {
    const store: any = addForm.getFieldsValue(true)
    await propertyModel.scatterJointPropertyRoomInsert(store)
    message.success('添加成功')
    setVisible(false)
    search(true)
  }

  /**
   * 添加房源
   */
  function addProperty() {
    if (checkPropertyPermission()) {
      history.push('/add')
    }
  }

  return (
    <>
      <Breadcrumb
        path={['首页', '房源房态', `${rentType === '3' ? '集中式房态图' : '分散式房态图'}`]}
        right={
          !isProjectCondo && (
            <Space>
              {auth('002001002') && (
                <Button type={'primary'} onClick={() => addProperty()}>
                  添加房源
                </Button>
              )}
              {['1', '2'].includes(rentType) && auth('002001003') && (
                <Button onClick={() => setMigrateVisible(true)}>小区迁移</Button>
              )}
            </Space>
          )
        }
      />

      <Tabs
        tabBarStyle={{backgroundColor: '#fff', paddingLeft: 30}}
        activeKey={activeKey}
        onChange={activeKey => {
          propertyModel.setRentMode(activeKey)
          form.setFieldsValue({rentMode: activeKey, businessType: '', rentStatus: '', picExist: ''})
          form.resetFields(['communityCode', 'title', 'floor', 'insertTime'])
          search(true)
        }}
      >
        {(isProjectCondo ? rentTypeMap.filter(item => item.key !== '1') : rentTypeMap).map(item => (
          <Tabs.TabPane tab={item.title} key={item.key} />
        ))}
      </Tabs>

      <div style={{marginRight: -24, marginTop: -24, marginLeft: -24}}>
        {/*<TeamBar />*/}
        <div className={styles.content}>
          <div className={`${commonStyles.section}`} style={{flex: 1, backgroundColor: 'transparent'}}>
            <div className={`${commonStyles.section}`}>
              <Form
                form={form}
                labelAlign={'left'}
                initialValues={{
                  rentMode: activeKey, // rentMode 放在 form 里方便接口查询
                }}
              >
                <Row gutter={24} style={{marginRight: 0}}>
                  <Col span={7}>
                    <Form.Item name={'communityCode'} label={'小区'} style={{marginBottom: 0}}>
                      <Select
                        placeholder={'请选择小区'}
                        mode='multiple'
                        allowClear
                        maxTagCount={1}
                        showArrow={true}
                        style={{minWidth: 150}}
                        onChange={() => search(true)}
                        filterOption={(inputValue, option) => option.children.includes(inputValue)}
                      >
                        {communityOption.map(item => (
                          <Option key={item.id} value={item.id}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item name={'title'} label={'搜索'} style={{marginBottom: 0}}>
                      <AutoComplete
                        dropdownMatchSelectWidth={500}
                        options={renderOptions}
                        onSelect={() => search(true)}
                      >
                        <Input
                          allowClear
                          placeholder='请输入房源地址，门牌号，租客姓名/手机号'
                          suffix={<SearchOutlined onClick={() => search(true)} />}
                          onChange={({target: {value}}) => handleInput(value)}
                        />
                      </AutoComplete>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name={'insertTime'} label={'房源添加时间'}>
                      <RangePicker showTime />
                    </Form.Item>
                  </Col>
                  <div className={styles.sideTop}>
                    共计 <span className={styles.sideTopNum}>{total || 0}</span> 间符合条件的房源
                  </div>
                </Row>
                <Divider dashed style={{marginBottom: '8px', marginTop: '18px'}} />
                {renderFilter()}
                <Form.Item style={{textAlign: 'right', marginBottom: 0}}>
                  <Button loading={loading} type={'primary'} onClick={() => search(true)} style={{marginRight: '12px'}}>
                    查询
                  </Button>
                  <Button
                    onClick={() => {
                      form.resetFields(['communityCode', 'title', 'floor', 'insertTime'])
                      form.setFieldsValue({businessType: '', rentStatus: '', picExist: ''})
                      search(true)
                    }}
                  >
                    重置
                  </Button>
                </Form.Item>
              </Form>

              {/*{props.chartType === 'scatter' && (*/}
              {/*  <Button ghost type={'primary'} onClick={() => setMigrateVisible(true)}>*/}
              {/*    小区迁移*/}
              {/*  </Button>*/}
              {/*)}*/}
            </div>

            <List
              className={styles.list}
              loading={!list.length && loading}
              loadMore={
                <div style={{textAlign: 'center'}}>
                  {!finish ? (
                    <Button type={'link'} loading={loading} onClick={() => search()}>
                      {loading ? '加载中...' : '点击加载更多'}
                    </Button>
                  ) : (
                    <Text>暂无更多...</Text>
                  )}
                </div>
              }
              dataSource={renderList}
              rowKey={item => item.title}
              renderItem={(item: RenderSectionResult<RenderSectionResult<PropertyChartVO, string>, string>) => (
                <List.Item style={{paddingTop: 0}}>
                  <div className={styles.listSection}>
                    <Affix offsetTop={48}>
                      {/*头部*/}
                      <Space className={styles.listHeader}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                          <Title level={3} className={styles.listTitle} style={{marginBottom: 0}}>
                            {item.title}
                          </Title>
                          <Text>{`（合计房源：${item.count}间 出租房源：${item.rentCount}间。空置率：${item.unRentRate}%）`}</Text>
                        </div>
                        {item.rentType === '3' && (
                          <Space>
                            楼层
                            <Select
                              allowClear
                              style={{width: 100}}
                              value={form.getFieldValue('floor')}
                              onChange={floor => {
                                form.setFieldsValue({floor, communityCode: floor ? item.communityCode : undefined})
                                search(true)
                              }}
                              // onSelect={value => {
                              //   document
                              //     .getElementById(item.title + value)
                              //     .scrollIntoView({behavior: 'smooth', block: 'center'})
                              // }}
                            >
                              {item.floorList?.map(_item => (
                                <Select.Option key={_item} value={_item}>
                                  {`第${_item}层`}
                                </Select.Option>
                              ))}
                            </Select>
                            {!isProjectCondo && (
                              <>
                                {/*<Permission code={'002001008'}>*/}
                                {/*  <Link to={`./roomAdd?condoId=${item.condoId}&buildingNo=${item.buildingNo}`}>*/}
                                {/*    <Button type={'primary'} ghost>*/}
                                {/*      添加房间*/}
                                {/*    </Button>*/}
                                {/*  </Link>*/}
                                {/*</Permission>*/}
                                <Permission code={'002001022'}>
                                  <Button
                                    loading={manageLoading.pick(item.condoId)}
                                    type={'primary'}
                                    ghost
                                    onClick={async () => {
                                      const {
                                        response: {data},
                                      } = await propertyModel.condoManageInfo({condoId: item.condoId})
                                      const {
                                        response: {data: imgList},
                                      } = await propertyModel.condoPublicImgList(item.condoId)
                                      setCondoImgList(imgList)
                                      manageForm.setFieldsValue({...data, condoId: item.condoId})
                                      setManageVisible(true)
                                      const {
                                        response: {data: biz},
                                      } = await propertyModel.queryCityCode({type: '4', parentCode: data.districtCode})
                                      setBizCircle(biz)
                                    }}
                                  >
                                    管理公寓
                                  </Button>
                                </Permission>
                              </>
                            )}
                          </Space>
                        )}
                      </Space>
                    </Affix>
                    {/*{item.rentType === '1' ? (*/}
                    {/*  <div key={item.title} className={styles.listWrapper}>*/}
                    {/*    <div className={styles.listContainer}>{item.data.map(property => renderCard(property))}</div>*/}
                    {/*  </div>*/}
                    {/*) : (*/}
                    {/*  item.data.map(_item => {*/}
                    {/*    return (*/}
                    {/*      <div key={_item.title} className={styles.listWrapper}>*/}
                    {/*        /!*楼层*!/*/}
                    {/*        <p id={item.title + _item.title}>{_item.title}</p>*/}
                    {/*        <div className={styles.listContainer}>*/}
                    {/*          {_item.data.map((property: PropertyChartVO) => renderCard(property))}*/}
                    {/*        </div>*/}
                    {/*      </div>*/}
                    {/*    )*/}
                    {/*  })*/}
                    {/*)}*/}
                    {item.data.map(_item => {
                      const [buildingItem] = _item.title?.split(' ') ?? []
                      return (
                        <div key={_item.propertyCode} className={styles.listWrapper}>
                          <Space style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            {item.rentType !== '3' && <Tag color={'blue'}>{['整租', '合租'][+_item.rentType - 1]}</Tag>}
                            <Typography.Text id={item.title + _item.title}>{_item.title}</Typography.Text>
                            {!isProjectCondo && item.rentType === '3' && (
                              <>
                                <Permission code={'002001008'}>
                                  <Link
                                    to={`./roomAdd?condoId=${item.condoId}&buildingNo=${buildingItem?.slice(
                                      0,
                                      buildingItem.length - 1
                                    )}`}
                                  >
                                    <Button type={'primary'} ghost>
                                      添加房间
                                    </Button>
                                  </Link>
                                </Permission>
                              </>
                            )}
                          </Space>

                          <div className={styles.listContainer}>
                            {_item.data.map((property: PropertyChartVO) => renderCard(property))}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </List.Item>
              )}
            />
          </div>
        </div>
      </div>

      {/*添加房间*/}
      <Modal
        visible={visible}
        destroyOnClose
        afterClose={() => addForm.resetFields()}
        onCancel={() => setVisible(false)}
        footer={[
          <Button
            key={'submit'}
            type={'primary'}
            onClick={() => addForm.submit()}
            loading={propertyModel.scatterJointPropertyRoomInsert.useLoading()}
          >
            保存并提交
          </Button>,
        ]}
        zIndex={2000}
      >
        <Form form={addForm} onFinish={addRoom} labelCol={{span: 5}}>
          <Form.Item name={'title'}>
            <Title style={{textAlign: 'center'}} level={3}>
              {addForm.getFieldValue('title')}
            </Title>
          </Form.Item>
          <Form.Item label={'房间名称'} name={'name'} rules={[{max: 6, message: '房间名称不能超过6个字符'}]}>
            <Input />
          </Form.Item>
          <Form.Item label={'房租租金'} name={'rentPrice'}>
            <Input />
          </Form.Item>
          <Form.Item label={'出租状态'} name={'rentStatus'} rules={rules.rentStatus}>
            <Radio.Group options={options} />
          </Form.Item>
          <Form.Item name={'selfBathroom'} label={'独立厨房'} required>
            <Radio.Group>
              <Radio value={'0'}>无</Radio>
              <Radio value={'1'}>有</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name={'selfKitchen'} label={'独立卫生间'} required>
            <Radio.Group>
              <Radio value={'0'}>无</Radio>
              <Radio value={'1'}>有</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>

      {/*小区迁移*/}
      <Modal
        visible={migrateVisible}
        destroyOnClose
        width={641}
        footer={null}
        title={'小区迁移'}
        afterClose={() => setMigrateForm({from: '', to: ''})}
        onCancel={() => setMigrateVisible(false)}
      >
        <AutoComplete className={styles.migrateInput} options={migrateList}>
          <Input.Search
            value={searchValue}
            onChange={event => setSearchValue(event.target.value)}
            size={'large'}
            placeholder={'请输入要搜索的小区'}
            allowClear
          />
        </AutoComplete>

        <Row gutter={16}>
          <Col span={12}>
            <Card title='原小区' className={styles.migrateCard}>
              {renderCommunity(renderMigrate.from)}
            </Card>
          </Col>
          <Col span={12}>
            <Card title='目标小区' className={styles.migrateCard}>
              {renderCommunity(renderMigrate.to)}
            </Card>
          </Col>
        </Row>

        <div className={styles.migrateConfirm}>
          <Button
            type={'primary'}
            onClick={migrateCommunity}
            loading={propertyModel.propertyMigrateCommunity.useLoading()}
          >
            确认
          </Button>
        </div>
      </Modal>

      <PropertyEdit
        property={editProperty}
        onCancel={() => setEditProperty(null)}
        onConfirm={() => {
          setEditProperty(null)
          search()
        }}
      />

      {/** 公寓管理 */}
      <Modal
        visible={manageVisible}
        destroyOnClose
        afterClose={() => {
          manageForm.resetFields()
          setTabCondo('1')
        }}
        onCancel={() => setManageVisible(false)}
        width={800}
        bodyStyle={{padding: 0}}
        footer={null}
      >
        <Form
          form={manageForm}
          labelCol={{span: 5}}
          onFinish={async () => {
            const value: CondoManageInfoUpdateDTO = manageForm.getFieldsValue(true)
            await propertyModel.condoManageInfoUpdate(value)
            message.success('保存成功')
            setManageVisible(false)
            search(true)
          }}
        >
          <Form.Item noStyle>
            <Title style={{textAlign: 'center', lineHeight: '56px'}} level={3}>
              {manageForm.getFieldValue('condoName')}
            </Title>
          </Form.Item>
          <Tabs tabPosition={'left'} activeKey={tabCondo} onChange={setTabCondo} hideAdd={true}>
            <Tabs.TabPane tab={'基本信息'} key={'1'} className={styles.tabContainer}>
              <Row gutter={24}>
                <Col flex='1'>
                  <Form.Item name={'condoName'} label={'公寓名称'} rules={rules.condoName}>
                    <Input placeholder={'请输入公寓名称'} />
                  </Form.Item>
                  <Form.Item label={'城市'} name={'cityCode'} rules={rules.cityCode}>
                    <Select
                      placeholder={'请选择所在城市'}
                      onChange={() => {
                        manageForm.setFieldsValue({
                          condoAddress: undefined,
                          bizCircleCode: undefined,
                          lat: undefined,
                          lng: undefined,
                        })
                      }}
                    >
                      {supportCity.map(item => (
                        <Option value={item.id} key={item.id}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item name={'condoAddress'} label={'详细地址'} rules={rules.condoAddress}>
                    <Input
                      suffix={<img src={Location} alt={'icon'} />}
                      placeholder='地图标点选择'
                      onClick={() => positionRef.current.show(manageForm.getFieldsValue(true))}
                    />
                  </Form.Item>
                  <Form.Item
                    name={'bizCircleCode'}
                    label={'商圈'}
                    // rules={rules.bizCircleCode}
                  >
                    <Select placeholder={'选择商圈'}>
                      {bizCircle?.map(item => (
                        <Option value={item.code} key={item.code}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label={'电梯房'} name={'lift'} rules={rules.lift}>
                    <Radio.Group>
                      <Radio value={'1'}>是</Radio>
                      <Radio value={'2'}>否</Radio>
                    </Radio.Group>
                  </Form.Item>
                  {/*<Form.Item label={'栋总高'} name={'topFloor'} rules={rules.topFloor}>*/}
                  {/*  <Input placeholder={'请输入栋总高'} />*/}
                  {/*</Form.Item>*/}
                  <Form.Item label={'联系电话'} name={'condoContact'} rules={rules.mobile}>
                    <Input placeholder={'请输入联系电话'} />
                  </Form.Item>
                </Col>
                <Col flex='285px'>
                  <Form.Item shouldUpdate noStyle>
                    {() =>
                      condoImgList.length > 0 ? (
                        <img src={getImg(condoImgList[0]?.imgUrl)} alt='' className={styles.formImg} />
                      ) : (
                        <img src={ImgEmpty} alt={'imgEmpty'} className={styles.formImg} />
                      )
                    }
                  </Form.Item>

                  <div className={styles.formAlterBtn}>
                    <Button type={'primary'} ghost onClick={() => setTabCondo('3')}>
                      修改照片
                    </Button>
                  </div>
                </Col>
              </Row>
              <div className={styles.formSaveBtn}>
                <Button type={'primary'} htmlType={'submit'}>
                  保存并提交
                </Button>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab={'模板'} key={'2'} className={styles.tabContainer}>
              {manageForm.getFieldValue('condoManageTempVOList')?.map((item, index) => (
                <div className={styles.templateItem} key={item.templateId}>
                  <span>{`模板${index + 1}`}</span>
                  <span>{item.templateName}</span>
                  <span>{`共有${item.proNums}间房使用此模版`}</span>
                  <Button
                    type={'primary'}
                    ghost
                    onClick={async () => {
                      const {
                        response: {data},
                      } = await propertyModel.condoManageTemplateInfo({templateId: item.templateId})
                      templateForm.setFieldsValue({...data})
                      setManageVisible(false)
                      setTemplateVisible(true)
                    }}
                  >
                    编辑
                  </Button>
                </div>
              ))}
            </Tabs.TabPane>
            <Tabs.TabPane tab={'公共区域照片'} key={'3'} className={styles.tabContainer}>
              <Album
                data={{imageType: 'public', type: '2', video: '0'}}
                fileList={condoImgList?.map(item => ({...item, uid: item.imgUrl, url: getImg(item.imgUrl)}))}
                onDone={fileList => {
                  setCondoImgList(() => fileList.map(item => ({imgId: item.imgId, imgUrl: item.imgUrl})))
                }}
              />
              <div className={styles.formSaveBtn}>
                <Button
                  type={'primary'}
                  onClick={async () => {
                    await propertyModel.condoPublicImgUpdate(
                      condoImgList.map(item => ({...item, condoId: manageForm.getFieldValue('condoId')}))
                    )
                    setManageVisible(false)
                  }}
                  loading={propertyModel.condoPublicImgUpdate.useLoading()}
                >
                  保存并提交
                </Button>
              </div>
            </Tabs.TabPane>
          </Tabs>
        </Form>
      </Modal>
      {/** 模板管理 */}
      <Modal
        visible={templateVisible}
        destroyOnClose
        afterClose={() => {
          templateForm.resetFields()
          setTabTemplate('1')
        }}
        onCancel={() => setTemplateVisible(false)}
        width={974}
        bodyStyle={{padding: 0}}
        footer={null}
      >
        <Form
          form={templateForm}
          labelCol={{span: 5}}
          style={{height: 500}}
          onFinish={() => {
            templateForm.validateFields().then(async () => {
              const value = templateForm.getFieldsValue(true) as CondoManageTemplateInfoVO
              await propertyModel.condoManageTemplateUpdate({
                ...value,
                rentCollectDay: +value.rentCollectDay,
                rentCollectType: '1',
                imgList: value.imgList?.map(item => ({...item, templateId: value.templateId})),
              })
              setTemplateVisible(false)
            })
          }}
        >
          <Form.Item noStyle>
            <Title style={{textAlign: 'center', lineHeight: '56px'}} level={3}>
              {templateForm.getFieldValue('templateName')}
            </Title>
          </Form.Item>
          <Tabs tabPosition={'left'} activeKey={tabTemplate} onChange={setTabTemplate}>
            <Tabs.TabPane tab={'房源基本信息'} key={'1'} className={styles.tabContainer}>
              <Row gutter={24}>
                <Col flex='1'>
                  <Form.Item label={'模板名称'} name={'templateName'} rules={rules.topFloor}>
                    <Input placeholder={'请输入模板名称'} />
                  </Form.Item>
                  <Form.Item label={'室/厅/卫'} style={{marginBottom: 0}} required>
                    <Space align={'baseline'}>
                      <Form.Item name={'bedroom'} rules={rules.bedroom}>
                        <Input placeholder={'请输入室'} />
                      </Form.Item>
                      室
                      <Form.Item name={'parlor'} rules={rules.parlor}>
                        <Input placeholder={'请输入卫'} />
                      </Form.Item>
                      厅
                      <Form.Item name={'bathroom'} rules={rules.bathroom}>
                        <Input placeholder={'请输入厅'} />
                      </Form.Item>
                      卫
                    </Space>
                  </Form.Item>
                  <Form.Item label={'房间面积(㎡)'} name={'floorage'} rules={rules.floorage}>
                    <Input placeholder={'请输入房间面积'} />
                  </Form.Item>
                </Col>
                <Col flex='285px'>
                  <Form.Item shouldUpdate noStyle>
                    {() =>
                      templateForm.getFieldValue('imgList')?.filter(item => item.video === '0')?.length > 0 ? (
                        <img
                          src={getImg(
                            templateForm.getFieldValue('imgList').filter(item => item.video === '0')[0]?.imgUrl
                          )}
                          alt=''
                          className={styles.formImg}
                        />
                      ) : (
                        <img src={ImgEmpty} alt={'imgEmpty'} className={styles.formImg} />
                      )
                    }
                  </Form.Item>

                  <div className={styles.formAlterBtn}>
                    <Button type={'primary'} ghost onClick={() => setTabTemplate('4')}>
                      修改照片
                    </Button>
                  </div>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab='租金与付款方式' key='2' className={styles.tabContainer}>
              <Row>
                <Col span={12}>
                  <Form.Item label={'定金'} name={'deposit'} rules={rules.deposit}>
                    <Input placeholder={'请输入定金'} />
                  </Form.Item>
                  <Form.Item label={'收租日'} style={{marginBottom: 0}} required>
                    <Space align={'baseline'}>
                      每期提前
                      <Form.Item name={'rentCollectDay'} rules={rules.rentCollectDay}>
                        <Input placeholder={'请输入收租日'} />
                      </Form.Item>
                      天
                    </Space>
                  </Form.Item>
                  <Form.Item label={'付款方式'} style={{marginBottom: 0}} required>
                    <Space align={'baseline'}>
                      付
                      <Form.Item name={'rentPayType'} rules={rules.rentPayType}>
                        <Input placeholder={'请输入付款方式'} />
                      </Form.Item>
                      押
                      <Form.Item name={'rentPledgeType'} rules={rules.rentPledgeType}>
                        <Input placeholder={'请输入付款方式'} />
                      </Form.Item>
                    </Space>
                  </Form.Item>
                  <Form.Item label={'租金'} name={'rentPrice'}>
                    <Input placeholder={'请输入租金'} />
                  </Form.Item>
                  <Form.Item label={'押金'} name={'rentPledge'} rules={rules.rentPledge}>
                    <Input placeholder={'请输入押金'} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={16}>
                  <Form.Item shouldUpdate label={'其他费用'} labelCol={{span: 4}}>
                    {() => (
                      <Cost
                        costs={templateForm.getFieldValue('otherCharges')}
                        onCostChange={costs => templateForm.setFieldsValue({otherCharges: JSON.stringify(costs)})}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab='房源描述' key='3' className={styles.tabContainer}>
              <Row>
                <Col span={21}>
                  <Form.Item label={'房源描述'} name={'desc'}>
                    <Input.TextArea placeholder={'请输入房源描述'} autoSize={{minRows: 5, maxRows: 15}} />
                  </Form.Item>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab={'模板照片'} key={'4'}>
              <Form.Item shouldUpdate>
                {() => (
                  <>
                    <Album
                      accept={'image/jpeg, image/png, image/jpg'}
                      data={{imageType: 'property', type: '2', video: '0'}}
                      fileList={(templateForm.getFieldValue('imgList') as PropertyImgVO[])
                        ?.filter(item => item.video === '0')
                        ?.map(item => ({...item, uid: item.imgUrl, url: getImg(item.imgUrl)}))}
                      onDone={fileList => {
                        const imgList = (templateForm.getFieldValue('imgList') as PropertyImgVO[]) || []
                        templateForm.setFieldsValue({
                          imgList: [
                            ...fileList.map(item => ({video: '0', imgId: item.imgId, imgUrl: item.imgUrl})),
                            ...imgList.filter(item => item.video === '1'),
                          ],
                        })
                      }}
                    />
                    <>
                      <Divider />
                      <Title level={4}>视频</Title>
                      <Album
                        limit={1}
                        accept={'video/mp4'}
                        data={{imageType: 'property', type: '2', video: '1'}}
                        iconRender={() => (
                          <div
                            style={{display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'center'}}
                          >
                            <video
                              src={getImg(
                                (templateForm.getFieldValue('imgList') as PropertyImgVO[]).filter(
                                  item => item.video === '1'
                                )[0]?.imgUrl
                              )}
                            />
                          </div>
                        )}
                        fileList={(templateForm.getFieldValue('imgList') as PropertyImgVO[])
                          ?.filter(item => item.video === '1')
                          ?.map(item => ({...item, uid: item.imgId, imgId: item.imgId, url: getImg(item.imgUrl)}))}
                        onDone={fileList => {
                          const imgList =
                            (templateForm.getFieldValue('imgList') as PropertyImgVO[])?.filter(
                              item => item.video === '0'
                            ) || []
                          // console.log('fileListfileList', fileList)
                          // console.log('imgListimgList', imgList)
                          if (fileList.length) {
                            templateForm.setFieldsValue({
                              imgList: [
                                ...imgList,
                                ...fileList.map(item => ({imgId: item.imgId, imgUrl: item.imgUrl, video: '1'})),
                              ],
                            })
                          } else {
                            // 删除
                            templateForm.setFieldsValue({imgList})
                          }
                        }}
                        title={'上传视频'}
                        errorMsg={'只能上传1个视频'}
                      />
                    </>
                  </>
                )}
              </Form.Item>
              {tabTemplate === '4' && (
                <div className={styles.formSaveBtn}>
                  <Button
                    type={'primary'}
                    onClick={async () => {
                      await propertyModel.condoPropertyImgUpdate(
                        templateForm
                          .getFieldValue('imgList')
                          ?.map(item => ({...item, templateId: templateForm.getFieldValue('templateId')}))
                      )
                      setTemplateVisible(false)
                    }}
                  >
                    保存并提交
                  </Button>
                </div>
              )}
            </Tabs.TabPane>
          </Tabs>
          {tabTemplate !== '4' && (
            <div className={styles.formSaveBtn}>
              {tabTemplate === '1' && (
                <Button
                  type={'default'}
                  style={{marginRight: 10}}
                  onClick={async () => {
                    await propertyModel.condoDeleteTemplate({templateId: templateForm.getFieldValue('templateId')})
                    setTemplateVisible(false)
                  }}
                >
                  删除模板
                </Button>
              )}
              <Button type={'primary'} htmlType={'submit'}>
                保存并提交
              </Button>
            </div>
          )}
        </Form>
      </Modal>
      {/** 历史租约 */}
      <Modal
        visible={hisContract.visible}
        zIndex={2000}
        centered
        footer={null}
        closable={false}
        onCancel={() => setHisContract({visible: false, chartItem: {} as PropertyInfoCardVO})}
      >
        <div style={{maxHeight: 300, width: '100%', overflow: 'auto'}}>
          <List
            dataSource={contractList}
            renderItem={item => (
              <List.Item
                actions={[
                  <a
                    key={item.contractId}
                    onClick={() => {
                      if (hisContract.chartItem.reserveStatus === '1') {
                        // message
                        Modal.confirm({
                          title: '提示',
                          content: (
                            <>
                              <Typography.Text>{`是否将此预订单转为租约`}？</Typography.Text>
                              <br />
                              <Typography.Text>如果要录入其他租约请先撤销预定</Typography.Text>
                            </>
                          ),
                          zIndex: 3000,
                          centered: true,
                          onOk: () =>
                            history.push('/contract/register', {
                              contractId: hisContract.chartItem.reserveContractId,
                              from: 'reserve-lease',
                            }),
                        })
                      }
                    }}
                  >
                    {contractStatus[item.contractStatus]}
                  </a>,
                ]}
              >
                <div
                  style={{display: 'flex', flex: 1, flexDirection: 'column', cursor: 'pointer'}}
                  onClick={() => {
                    const location = {
                      pathname: '/contract/detail',
                      state: {contractId: item.contractId},
                    }
                    history.push(location)
                  }}
                >
                  <Space>
                    <Typography.Text>{item.lesseeCertName}</Typography.Text>
                    <Typography.Text>{` | `}</Typography.Text>
                    <Typography.Text>{item.lesseeMobile}</Typography.Text>
                  </Space>
                  <Space>
                    <Typography.Text>
                      {item.contractBegin}至{item.contractEnd}
                    </Typography.Text>
                    {item.contractStatus === '4' && (
                      <Typography.Text style={{color: '#FF3333'}}>{`(已退房：${dayjs(item.checkOutTime).format(
                        'YYYY-MM-DD'
                      )})`}</Typography.Text>
                    )}
                  </Space>
                  <Space>
                    <Typography.Text>{item.rentPrice}元/月</Typography.Text>
                    <Typography.Text>
                      付{item.rentPayType}押{item.rentPledgeType}
                    </Typography.Text>
                  </Space>
                </div>
                {/*<Typography.Text style={{color: '#017BFF'}}>{contractStatus[item.contractStatus]}</Typography.Text>*/}
              </List.Item>
            )}
          />
        </div>
      </Modal>
      <CancelContract
        ref={contractRef}
        onSuccess={() => {
          search(true)
          queryDropList(propertyCode)
        }}
      />

      {/* 租约备注 */}
      <Modal
        title={'租约备注'}
        width={445}
        visible={remarkVisible}
        footer={null}
        centered
        onCancel={() => {
          setRemarkVisible(false)
        }}
        zIndex={2000}
      >
        <Space align={'start'}>
          <Text>备注：</Text>
          <Input.TextArea
            value={contractRemark}
            placeholder={'请输入备注'}
            onChange={e => {
              setContractRemark(e.target.value)
            }}
            rows={3}
            style={{width: 300}}
            allowClear
            maxLength={200}
            showCount
          />
        </Space>
        <Row style={{marginTop: 30}} justify={'end'}>
          <Space>
            <Button onClick={() => setRemarkVisible(false)}>取消</Button>
            <Button
              type={'primary'}
              onClick={async () => {
                await contractModel.contractUpdateContractRemark({contractId, contractRemark})
                queryDropList(propertyCode)
                setRemarkVisible(false)
              }}
            >
              保存提交
            </Button>
          </Space>
        </Row>
      </Modal>

      <SelectPosition
        ref={positionRef}
        onChange={(value, bizCircle) => {
          setBizCircle(bizCircle)
          manageForm.setFieldsValue(value)
        }}
      />

      <ChargeModal ref={chargeModalRef} />

      <Modal
        visible={!!propertyStatusCode}
        zIndex={1999}
        width={445}
        title={'房源状态'}
        onOk={() => propertyStatusForm.submit()}
        onCancel={() => setPropertyStatusCode('')}
        afterClose={() => propertyStatusForm.resetFields()}
      >
        <Form
          form={propertyStatusForm}
          onFinish={async () => {
            const values = propertyStatusForm.getFieldsValue(true)
            await propertyModel.propertyEmptyStatusUpdate({
              propertyCode: propertyStatusCode,
              emptyStatus: values.emptyStatus,
            })
            setPropertyStatusCode('')
            search(true)
          }}
        >
          <Form.Item label={'状态'} name={'emptyStatus'}>
            <Select
              style={{width: 210}}
              getPopupContainer={triggerNode => triggerNode.parentNode}
              options={[
                {label: '空净', value: '1'},
                {label: '空脏', value: '2'},
              ]}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

// function isCondo(chartType: ChartType) {
//   return chartType === 'condo'
// }

interface StatusTagProps {
  backgroundColor: string
  color?: string
  text: string
}

const StatusTag = ({backgroundColor, color, text}: StatusTagProps) => (
  <div className={styles.tag} style={{backgroundColor, color}}>
    {text}
  </div>
)

const renderItemStatus = {
  unrent: null, // 空置
  expire: <StatusTag key={'#FFAB51'} backgroundColor={'#FFAB51'} text={'到'} />, // 到期
  pending: null, // 待录
  // 'collect.soon': <Icon key={'collect.soon'} name={'property_chart_tag_green'} width={48.5} height={18} />, // 待收租
  'collect.soon': null, // 待收租
  overdue: <StatusTag key={'#FF786C'} backgroundColor={'#FF786C'} text={'逾'} />, // 逾期
  'expire.soon': <StatusTag key={'#33BCFF'} backgroundColor={'#33BCFF'} text={'将'} />, // 快到期
  reserve: <StatusTag key={'#5CABFF'} backgroundColor={'#5CABFF'} text={'预'} />, // 预定
  sublease: <StatusTag key={'#1FBD85'} backgroundColor={'#1FBD85'} text={'转'} />, // 转租
  fix: <StatusTag key={'#10B691'} backgroundColor={'#10B691'} text={'装'} />, // 装修
  employee: <StatusTag key={'#FFCD45'} backgroundColor={'#FFCD45'} text={'自'} />, // 自住
  'unrent.clean': <StatusTag key={'8'} backgroundColor={'#10B691'} text={'净'} />, // 空净
  'unrent.dirty': <StatusTag key={'9'} backgroundColor={'#A1A1A1'} text={'脏'} />, // 空脏
}
