import React, {useEffect, useState} from 'react'
import {Modal, Table} from 'antd'
import {deviceModel} from '@/store/models/Device'

export default function NygDeviceHistory(props: {deviceId: string; visible: boolean; onCancel: () => void}) {
  const {deviceId, visible, onCancel} = props

  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [historyList, setHistoryList] = useState<DeviceV2HistoryVO[]>([])

  useEffect(() => {
    if (deviceId) {
      deviceModel
        .deviceV2DeviceHistoryList({deviceId, pageNum, pageSize, pageable: true} as DeviceV2ListDTO)
        .then(({response: {data, total}}) => {
          // console.log('data', data)
          setTotal(total)
          setHistoryList(data)
        })
    }
  }, [deviceId, pageNum, pageSize])

  const listLoading = deviceModel.deviceV2DeviceHistoryList.useLoading()

  return (
    <Modal visible={visible} title={'历史记录'} centered footer={null} onCancel={onCancel}>
      <Table
        rowKey={'historyId'}
        columns={[
          {title: '发送指令', dataIndex: 'command'},
          {title: '发送时间', dataIndex: 'reqTime'},
          {title: '返回结果', dataIndex: 'resStatus', render: value => ({'1': '成功', '0': '失败'}[value] ?? '/')},
          {title: '返回时间', dataIndex: 'resTime'},
        ]}
        dataSource={historyList}
        loading={listLoading}
        pagination={{
          total,
          pageSize,
          current: pageNum,
          position: ['bottomCenter'],
          hideOnSinglePage: false,
          showSizeChanger: true,
          onChange: (pageNum, pageSize) => {
            setPageNum(pageNum)
            setPageSize(pageSize)
          },
        }}
      />
    </Modal>
  )
}
