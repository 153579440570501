import React from 'react'
import {Button, Form, Input, message, Row} from 'antd'
import accountStyle from './account.module.less'
import {LockOutlined, UserOutlined} from '@ant-design/icons'
import {deviceModel} from '@/store/models/Device'
import {Breadcrumb} from '@/ui/components/Breadcrumb'

export default function DeviceAccountBind(props: {onSuccess?: () => void}) {
  const [form] = Form.useForm()

  const bindLoading = deviceModel.deviceYundingAccessBindSecret.useLoading()

  return (
    <>
      <Breadcrumb path={['首页', '智能设备', '登录']} />
      <div className={accountStyle.body}>
        <div className={accountStyle.content}>
          <div>
            <span className={accountStyle.content__title}>账号绑定</span>
          </div>
          <Form
            form={form}
            className={accountStyle.content__form}
            onFinish={async formValues => {
              const {clientId, clientSecret} = formValues
              await deviceModel.deviceYundingAccessBindSecret({clientId, clientSecret})
              message.success('绑定成功！')
              props.onSuccess?.()
            }}
          >
            <Form.Item name={'clientId'} rules={[{required: true, message: '请输入账号'}]}>
              <Input placeholder={'账户'} prefix={<UserOutlined style={{color: '#007BFF'}} />} />
            </Form.Item>
            <Form.Item name={'clientSecret'} rules={[{required: true, message: '请输入密码'}]}>
              <Input placeholder={'密码'} prefix={<LockOutlined style={{color: '#007BFF'}} />} />
            </Form.Item>
            <Form.Item>
              <Row justify={'end'}>
                <Button
                  type={'link'}
                  style={{padding: 0}}
                  onClick={() => message.warn('请联系销售人员申请系统对接，并获取client ID和client Secret。')}
                >
                  我还没有 client ID？
                </Button>
              </Row>
            </Form.Item>
            <Button type={'primary'} htmlType={'submit'} className={accountStyle.content__btn} loading={bindLoading}>
              确认
            </Button>
          </Form>
        </div>
      </div>
    </>
  )
}
