import React, {useCallback, useEffect, useState} from 'react'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  List,
  message,
  Modal,
  Popover,
  Radio,
  Result,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import {ExclamationCircleFilled} from '@ant-design/icons'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {propertyModel} from '@/store/models/Property'
import PropertyEdit from '@/pages/property/components/PropertyEdit'
import {userModel} from '@/store/models/User'
import {usePermission} from '@/ui/components/Permission'

const {Option} = Select

export default function PopularizeProperty() {
  const [auth] = usePermission()
  const userId = userModel.useData(data => data.userInfo.userId)

  const rentTypeArr = useCodeArraySync('rentType')
  const rentTypeCode = useCodeSync('rentType')
  const propertyOrientationCode = useCodeSync('property.orientation')
  const propertyDecoration = useCodeSync('property.decoration')
  const propertyDecorationArr = useCodeArraySync('property.decoration')
  const [promoteForm] = Form.useForm()
  const [promoteVisible, setPromoteVisible] = useState(false)

  // 是否开通了房源推广功能
  const [isPromote, setIsPromote] = useState(false)

  const [form] = Form.useForm()
  const [cForm] = Form.useForm()
  // 小区列表
  const [communityList, setCommunityList] = useState<CommunityVO[]>([])

  // 要推广的房源列表
  const [total, setTotal] = useState(0)
  const [reqParams, setReqParams] = useState<PropertyForPromoteListDTO>({
    pageNum: 1,
    pageSize: 10,
  } as PropertyForPromoteListDTO)
  const [promoteList, setPromoteList] = useState<PropertyForPromoteVO[]>([])

  const promoteListLoading = propertyModel.propertyPromoteList.useLoading()
  const putOnLoading = propertyModel.propertyPromotePutOn.useLoading()
  const putOffLoading = propertyModel.propertyPromotePullOff.useLoading()
  const recordListLoading = propertyModel.propertyPromoteRecordList.useLoading()
  const autoConfigLoading = propertyModel.propertyPromoteAutoConfig.useLoading()

  // 房源推广记录列表
  const [recordList, setRecordList] = useState<PropertyPromoteRecordVO[]>([])

  const [ruleVisible, setRuleVisible] = useState(false)
  const [recordVisible, setRecordVisible] = useState(false)
  const [editProperty, setEditProperty] = useState<PropertyForPromoteVO>(null)
  const [autoVisible, setAutoVisible] = useState(false)
  const [putOnVisible, setPutOnVisible] = useState(false)
  const [putOffVisible, setPutOffVisible] = useState(false)

  const [selectedRows, setSelectedRows] = useState<PropertyForPromoteVO[]>([])

  const promoteLoading = propertyModel.lessorPropertyPromote.useLoading()

  useEffect(() => {
    if (userId) {
      // 查询客户房源推广设置
      propertyModel.lessorPropertyPromote({userId}).then(({response: {data}}) => {
        setIsPromote(data.propertyPromote === '1')
        cForm.setFieldsValue({
          propertyPromoteAuto: data.propertyPromoteAuto,
          propertyPromoteAutoTypeList: data.propertyPromoteAutoTypeList,
        })
      })
    }
  }, [cForm, userId])

  useEffect(() => {
    if (isPromote) {
      // 小区列表
      propertyModel.propertyCommunityList({}).then(({response: {data}}) => setCommunityList(data))
    }
  }, [isPromote])

  // 推广房源列表
  const queryPropertyPromoteList = useCallback((params: PropertyForPromoteListDTO) => {
    propertyModel.propertyPromoteList(params).then(({response: {data, total}}) => {
      setPromoteList(data)
      setTotal(total)
    })
  }, [])

  useEffect(() => {
    if (isPromote) {
      queryPropertyPromoteList(reqParams)
    }
  }, [queryPropertyPromoteList, reqParams, isPromote])

  return (
    <>
      <Breadcrumb
        path={['首页', '房源房态', '推广房源']}
        right={
          isPromote && (
            <Space>
              {auth('002003005') && (
                <Button type={'primary'} onClick={() => setAutoVisible(true)}>
                  自动推广
                </Button>
              )}
              {auth('002003003') && (
                <Button
                  type={'primary'}
                  onClick={() => {
                    if (selectedRows.length === 0) return message.error('请选择房源！')
                    if (selectedRows.some(item => item.promoteStatus === '1'))
                      return message.error('您选择的房间中有已上架的房源！')
                    if (selectedRows.some(item => item.promotable === '0'))
                      return message.error('您选择的房间中有不可上架的房源！')
                    setPutOnVisible(true)
                  }}
                >
                  上架
                </Button>
              )}
              {auth('002003004') && (
                <Button
                  onClick={() => {
                    if (selectedRows.length === 0) return message.error('请选择房源！')
                    if (selectedRows.some(item => item.promoteStatus === '0'))
                      return message.error('您选择的房间中有已下架的房源！')
                    setPutOffVisible(true)
                  }}
                >
                  下架
                </Button>
              )}
            </Space>
          )
        }
      />

      {isPromote ? (
        <>
          <div className={commonStyles.section}>
            <Form
              form={form}
              initialValues={{promoteStatus: ''}}
              labelCol={{flex: '80px'}}
              onFinish={formValues => setReqParams(prevState => ({...prevState, ...formValues}))}
              onReset={() => {
                form.resetFields()
                setReqParams({pageNum: 1, pageSize: 10} as PropertyForPromoteListDTO)
              }}
            >
              <Row gutter={30}>
                <Col span={24}>
                  <Form.Item label={'推广状态'} shouldUpdate style={{marginBottom: 0}}>
                    {form1 => (
                      <Space size={25}>
                        {[
                          {label: '全部', key: ''},
                          {label: '未上架', key: '0'},
                          {label: '已上架', key: '1'},
                        ].map((item, idx) => (
                          <Button
                            key={idx}
                            size={'small'}
                            style={{padding: '0 19px'}}
                            type={form1.getFieldValue('promoteStatus') === item.key ? 'primary' : 'text'}
                            onClick={() => {
                              form1.setFieldsValue({promoteStatus: item.key})
                              setReqParams(prevState => ({
                                ...prevState,
                                promoteStatus: item.key,
                                pageNum: 1,
                                pageSize: 10,
                              }))
                            }}
                          >
                            {item.label}
                          </Button>
                        ))}
                      </Space>
                    )}
                  </Form.Item>
                  <Divider dashed style={{margin: '19px 0'}} />
                </Col>

                <Col span={8}>
                  <Form.Item name={'title'} label={'房源标题'}>
                    <Input placeholder={'请输入小区名/门牌号'} allowClear />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item name={'rentType'} label={'房源类型'}>
                    <Select placeholder={'请选择'} allowClear>
                      {rentTypeArr.map(item => (
                        <Option key={item.id} value={item.id}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item name={'communityCode'} label={'小区'}>
                    <Select placeholder={'请选择'} allowClear>
                      {communityList.map(item => (
                        <Option key={item.code} value={item.code}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label={'租金'}>
                    <Input.Group compact>
                      <Form.Item noStyle name={'rentPriceMin'}>
                        <Input style={{width: 85, textAlign: 'center'}} placeholder='请输入' />
                      </Form.Item>
                      <Input
                        style={{
                          width: '35px',
                          borderLeftWidth: 0,
                          borderRightWidth: 0,
                          pointerEvents: 'none',
                          backgroundColor: 'white',
                        }}
                        placeholder='→'
                        disabled
                      />
                      <Form.Item noStyle name={'rentPriceMax'}>
                        <Input style={{width: 85, textAlign: 'center', borderLeftWidth: 0}} placeholder='请输入' />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label={'装修'} name={'decoration'}>
                    <Select placeholder={'请选择'} allowClear>
                      {propertyDecorationArr.map(item => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label={'电梯房'} name={'lift'}>
                    <Select placeholder={'请选择'} allowClear>
                      <Select.Option value={'1'}>是</Select.Option>
                      <Select.Option value={'0'}>否</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row justify={'end'}>
                <Space>
                  <Button type={'primary'} htmlType={'submit'}>
                    查询
                  </Button>
                  <Button htmlType={'reset'}>重置</Button>
                </Space>
              </Row>
            </Form>
          </div>

          <div className={commonStyles.section}>
            <Table
              title={() => (
                <>
                  <Typography.Text strong style={{marginRight: '12px', marginLeft: '-16px'}}>
                    查询结果
                  </Typography.Text>
                  <Typography.Text>
                    共计
                    <Typography.Text strong style={{color: '#017BFF'}}>
                      {total}
                    </Typography.Text>
                    条符合条件的结果
                  </Typography.Text>
                  <Button
                    type={'link'}
                    icon={<ExclamationCircleFilled style={{color: '#FAAD14'}} />}
                    onClick={() => setRuleVisible(true)}
                  >
                    <span style={{textDecoration: 'underline'}}>上架推广规则</span>
                  </Button>
                </>
              )}
              scroll={{x: 'max-content'}}
              rowKey={'propertyCode'}
              loading={promoteListLoading}
              dataSource={promoteList}
              rowSelection={{
                fixed: true,
                selectedRowKeys: selectedRows.map(item => item.propertyCode),
                onChange: (_, rows) => setSelectedRows(rows),
              }}
              columns={[
                {
                  title: '状态',
                  render: ({promoteStatus, promotable, notPromotableReasonList}) => {
                    if (promoteStatus === '1') return '已上架'
                    if (promotable === '0') {
                      return (
                        <Space>
                          <span>未上架</span>
                          <Popover
                            content={
                              <>
                                {notPromotableReasonList?.map((item, index) => (
                                  <p key={index}>
                                    {index + 1}、{item}
                                  </p>
                                ))}
                              </>
                            }
                            trigger={'hover'}
                          >
                            <ExclamationCircleFilled style={{color: '#FAAD14'}} />
                          </Popover>
                        </Space>
                      )
                    }
                    return '未上架'
                  },
                },
                {title: '房源标题', dataIndex: 'title'},
                {
                  title: '出租状态',
                  dataIndex: 'rentStatus',
                  render: value => (value === '1' ? '已出租' : '未出租'),
                },
                {
                  title: '租约状态',
                  dataIndex: 'contractStatus',
                  render: value => (value === '1' ? '已录入' : '未录入'),
                },
                {title: '月租金（元）', dataIndex: 'rentPrice'},
                {title: '面积（㎡）', dataIndex: 'floorage'},
                {title: '装修', dataIndex: 'decoration', render: value => propertyDecoration[value]},
                {title: '电梯', dataIndex: 'lift', render: value => (value === '1' ? '是' : '否')},
                {
                  title: '朝向',
                  dataIndex: 'orientation',
                  render: value => propertyOrientationCode[value] ?? '/',
                },
                {
                  title: '付款方式',
                  render: ({rentPayType, rentPledgeType}) => {
                    if (rentPayType && rentPledgeType) {
                      return `付${rentPayType}押${rentPledgeType}`
                    }
                    return ''
                  },
                },
                {title: '房源类型', dataIndex: 'rentType', render: value => rentTypeCode[value]},
                {
                  fixed: 'right',
                  title: '操作',
                  dataIndex: 'propertyCode',
                  render: (value, record) => {
                    return (
                      <Space>
                        <Button
                          type={'link'}
                          style={{padding: 0}}
                          onClick={() => {
                            setPromoteVisible(true)
                            promoteForm.setFieldsValue({
                              propertyCode: value,
                              decoration: record.decoration,
                              contactMobile: record.contactMobile,
                            })
                          }}
                        >
                          修改信息
                        </Button>
                        <Button type={'link'} style={{padding: 0}} onClick={() => setEditProperty(record)}>
                          编辑
                        </Button>
                        {auth('002003002') && (
                          <Button
                            type={'link'}
                            style={{padding: 0}}
                            onClick={async () => {
                              const {
                                response: {data},
                              } = await propertyModel.propertyPromoteRecordList({propertyCode: value})
                              setRecordList(data)
                              setRecordVisible(true)
                            }}
                            loading={recordListLoading}
                          >
                            查看记录
                          </Button>
                        )}
                      </Space>
                    )
                  },
                },
              ]}
              pagination={{
                total,
                current: reqParams.pageNum,
                pageSize: reqParams.pageSize,
                position: ['bottomCenter'],
                onChange: (pageNum, pageSize) => {
                  setReqParams(prevState => ({
                    ...prevState,
                    pageSize,
                    pageNum: prevState.pageSize === pageSize ? pageNum : 1,
                  }))
                },
              }}
            />
          </div>

          {/* 房源推广规则 */}
          <Modal
            visible={ruleVisible}
            centered
            title={'房源推广规则'}
            footer={null}
            onCancel={() => setRuleVisible(false)}
          >
            <List
              header={
                <div style={{paddingBottom: '20px'}}>
                  <span style={{fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.65)'}}>
                    牛油果为房东提供便捷的房源推广业务，让牛油果租客随时查看您出租的房源。为了更好帮助您推广房源，请遵循以下房源推广规则。
                  </span>
                </div>
              }
              footer={<span />}
              dataSource={[
                {title: '出租状态', desc: '未出租、30天内租约到期'},
                {title: '房源照片', desc: '至少一张图片'},
                {title: '面积', desc: '面积必填且必须大于1m²'},
                {title: '楼层', desc: '所在楼层或总高不可为空'},
                {title: '月租金', desc: '租金必填且必须大于500元/月'},
                {title: '付款方式', desc: '付几押几，必填'},
                {title: '朝向', desc: '分散式房源必须有，集中式可没有'},
              ]}
              renderItem={({title, desc}) => (
                <List.Item>
                  <Row style={{width: '100%'}}>
                    <Col span={10}>{title}</Col>
                    <Col>{desc}</Col>
                  </Row>
                </List.Item>
              )}
            />
          </Modal>

          {/* 查看记录 */}
          <Modal
            visible={recordVisible}
            centered
            title={'查看记录'}
            footer={null}
            onCancel={() => setRecordVisible(false)}
          >
            <Table
              rowKey={'recordId'}
              columns={[
                {title: '时间', key: 'insertTime', dataIndex: 'insertTime'},
                {title: '方式', key: 'recordWay', dataIndex: 'recordWay'},
                {title: '操作原因', key: 'result', dataIndex: 'result'},
              ]}
              dataSource={recordList}
              pagination={{
                total: recordList.length,
                pageSize: 5,
                position: ['bottomCenter'],
                showSizeChanger: false,
              }}
            />
            <Row justify={'end'} style={{marginTop: '30px'}}>
              <Button onClick={() => setRecordVisible(false)}>关闭</Button>
            </Row>
          </Modal>

          {/* 编辑房源 */}
          <PropertyEdit
            property={editProperty}
            onCancel={() => setEditProperty(null)}
            onConfirm={() => {
              setEditProperty(null)
              queryPropertyPromoteList(reqParams)
            }}
          />

          {/* 自动推广设置 */}
          <Modal
            visible={autoVisible}
            centered
            title={'自动推广设置'}
            footer={null}
            onCancel={() => setAutoVisible(false)}
          >
            <Form
              form={cForm}
              labelCol={{flex: '100px'}}
              labelAlign={'left'}
              onFinish={async formValues => {
                const {propertyPromoteAuto, propertyPromoteAutoTypeList} = formValues
                await propertyModel.propertyPromoteAutoConfig({
                  propertyPromoteAuto,
                  propertyPromoteAutoTypeList: propertyPromoteAuto === '1' ? propertyPromoteAutoTypeList : [],
                } as PropertyPromoteAutoConfigDTO)
                message.success('设置成功！')
                setAutoVisible(false)
              }}
            >
              <Form.Item name={'propertyPromoteAuto'} label={'自动推广房源'}>
                <Radio.Group>
                  <Radio value={'0'}>关闭</Radio>
                  <Radio value={'1'}>开启</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item noStyle shouldUpdate>
                {form1 =>
                  form1.getFieldValue('propertyPromoteAuto') === '1' && (
                    <Form.Item name={'propertyPromoteAutoTypeList'} label={'合同类型'}>
                      <Checkbox.Group>
                        <Checkbox value={'1'}>未出租</Checkbox>
                        <Checkbox value={'2'}>30天内到期</Checkbox>
                      </Checkbox.Group>
                    </Form.Item>
                  )
                }
              </Form.Item>
              <Typography.Paragraph>
                提示：房源信息不完善的房源将无法进行自动推广，为了保证房源正常推广，请及时完善房源信息，具体要求见
                <Button
                  type={'link'}
                  style={{padding: 0}}
                  onClick={() => {
                    setAutoVisible(false)
                    setRuleVisible(true)
                  }}
                >
                  <span style={{textDecoration: 'underline'}}>上架推广原则</span>
                </Button>
                。
              </Typography.Paragraph>
              <Row justify={'end'}>
                <Space>
                  <Button onClick={() => setAutoVisible(false)}>取消</Button>
                  <Button type={'primary'} htmlType={'submit'} loading={autoConfigLoading}>
                    确认
                  </Button>
                </Space>
              </Row>
            </Form>
          </Modal>

          {/* 上架房源 */}
          <Modal visible={putOnVisible} title={'上架'} centered footer={null} onCancel={() => setPutOnVisible(false)}>
            <span>{`您已选择${selectedRows.length}套房源，是否确认上架？`}</span>
            <Row justify={'end'}>
              <Space>
                <Button onClick={() => setPutOnVisible(false)}>取消</Button>
                <Button
                  type={'primary'}
                  onClick={async () => {
                    await propertyModel.propertyPromotePutOn(selectedRows.map(item => item.propertyCode))
                    setPutOnVisible(false)
                    message.success('房源上架成功！')
                    setSelectedRows([])
                    queryPropertyPromoteList(reqParams)
                  }}
                  loading={putOnLoading}
                >
                  确定
                </Button>
              </Space>
            </Row>
          </Modal>

          {/* 下架房源 */}
          <Modal visible={putOffVisible} title={'下架'} centered footer={null} onCancel={() => setPutOffVisible(false)}>
            <span>{`您已选择${selectedRows.length}套房源，是否确认下架？`}</span>
            <Row justify={'end'}>
              <Space>
                <Button onClick={() => setPutOffVisible(false)}>取消</Button>
                <Button
                  type={'primary'}
                  onClick={async () => {
                    await propertyModel.propertyPromotePullOff(selectedRows.map(item => item.propertyCode))
                    setPutOffVisible(false)
                    message.success('房源下架成功！')
                    setSelectedRows([])
                    queryPropertyPromoteList(reqParams)
                  }}
                  loading={putOffLoading}
                >
                  确定
                </Button>
              </Space>
            </Row>
          </Modal>
        </>
      ) : promoteLoading ? null : (
        <div style={{width: '100%', height: '100%', backgroundColor: 'white', padding: '100px'}}>
          <Result status={'info'} title='尚未对您开放房源推广功能，如有需要请联系销售人员！' />
        </div>
      )}

      {/* 修改信息 */}
      <Modal
        title={'修改信息'}
        visible={promoteVisible}
        onCancel={() => setPromoteVisible(false)}
        centered
        afterClose={() => {
          promoteForm.resetFields()
        }}
        destroyOnClose
        footer={null}
      >
        <Form
          form={promoteForm}
          onFinish={async () => {
            const formValues = promoteForm.getFieldsValue(true)
            await propertyModel.propertyPromoteUpdateProperty(formValues)
            message.success('修改信息成功！')
            queryPropertyPromoteList(reqParams)
            setPromoteVisible(false)
          }}
        >
          <Form.Item label={'装修情况'} name={'decoration'} rules={[{required: true, message: '请选择装修情况'}]}>
            <Select placeholder={'请选择'}>
              {propertyDecorationArr.map(item => (
                <Select.Option value={item.id} key={item.id}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={'房东电话'} name={'contactMobile'} rules={[{required: true, message: '请输入房东号码'}]}>
            <Input placeholder={'请输入'} maxLength={11} />
          </Form.Item>
          <Row justify={'end'}>
            <Space>
              <Button onClick={() => setPromoteVisible(false)}>取消</Button>
              <Button type={'primary'} htmlType={'submit'}>
                确认
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>
    </>
  )
}
