import React, {useMemo} from 'react'
import commonStyle from '@/assets/styles/common.module.less'
import {Breadcrumb, Button, DatePicker, Form, Input, Radio, Select, Typography} from 'antd'
import {Cost} from '@/pages/property/components/Cost'
import {useCodeArraySync} from '@/store/models/Common'
import {Album} from '@/ui/components/Album'
import {baseUrl} from '@/common/config'
import dayjs from 'dayjs'
import moment from 'moment'
import {useHistory, useLocation} from 'react-router-dom'
import {contractModel} from '@/store/models/Contract'
import chartStyles from '@/pages/property/Chart/index.module.less'

const {Option} = Select
const {RangePicker} = DatePicker

export default function AddLandlordContract() {
  const history = useHistory()
  const location = useLocation<{propertyCode: string; chartType: 'condo' | 'scatter'}>()
  const {propertyCode, chartType} = location.state

  const [form] = Form.useForm()

  // 证件类型
  const contractCertType = useCodeArraySync('contract.certType')
  const rentIncrType = useCodeArraySync('contract.rentIncr')
  // 租金
  const rentPayType = useMemo(
    () => [
      {id: '1', label: '1月一付'},
      {id: '2', label: '2月一付'},
      {id: '3', label: '3月一付'},
      {id: '4', label: '4月一付'},
      {id: '6', label: '半年一付'},
      {id: '12', label: '一年一付'},
      {id: '0', label: '一次性付清'},
    ],
    []
  )

  // const [dates, setDates] = useState([])

  const subLoading = contractModel.contractLessorGenerateContract.useLoading()

  const addRules = {
    lessorCertName: [{required: true, message: '请输入业主姓名'}],
    lessorMobile: [
      {required: true, message: '请输入手机号'},
      {pattern: /^1[0-9]{10}$/, message: '手机号码不正确'},
    ],
    lessorCertType: [{required: true, message: '请选择证件类型'}],
    lessorCertNo: [form.getFieldValue('lessorCertType') === '01' ? {required: true, message: '请填写证件号'} : {}],
    contractTime: [{required: true, message: '请选择起租/退租时间'}],
    rentPayType: [{required: true, message: '请选择付款方式'}],
    rentPrice: [
      {
        required: true,
        async validator(_, value) {
          if (!value) throw new Error('请填写租金')
          else if (!(value >= 10 && value <= 999999.99)) throw new Error('请输入10-999999之间的数字')
        },
      },
    ],
    rentIncr: [{required: true, message: '请选择租金分阶类型'}],
    rentPledge: [
      {
        required: true,
        async validator(_, value) {
          if (!value) throw new Error('请填写押金')
          else if (!(value >= 10 && value <= 999999.99)) throw new Error('请输入10-999999之间的数字')
        },
      },
    ],
    rentCollectType: [{required: true, message: '请选择收租日类型'}],
    rentCollectDay: [{required: true, message: '请选择收租日'}],
    signTime: [{required: true, message: '请填写签约时间'}],
  }

  function onRentIncrList() {
    // 租金
    const rentPrice = form.getFieldValue('rentPrice')
    // console.log('rentPrice =>', rentPrice)
    // 列表
    const contractYear = form.getFieldValue('contractYear')
    const arr = contractYear ? new Array(+contractYear).fill('') : []
    // 租金分阶类型
    const rentIncr = form.getFieldValue('rentIncr')
    // console.log('rentIncr =>', rentIncr)

    // 更新表单
    if (rentPrice && rentIncr && arr.length) {
      if (rentIncr === '0' || rentIncr === '1') {
        form.setFieldsValue({rentIncrList: arr.map(() => ({sName: rentPrice}))})
      } else {
        // 递增金额/递增百分比
        const rentIncrNum = form.getFieldValue('rentIncrValue')
        // console.log('rentIncrNum =>', rentIncrNum)
        if (rentIncrNum) {
          // const incr = rentIncr === '2' ? +rentIncrNum : rentPrice * rentIncrNum * 0.01
          if (rentIncr === '2') {
            form.setFieldsValue({
              rentIncrList: arr.map((item, index) => ({sName: +rentPrice + index * +rentIncrNum})),
            })
          }
          if (rentIncr === '3') {
            const res = arr.reduce((previousValue, currentValue, currentIndex) => {
              if (previousValue.length === 0) {
                return [...previousValue, `${+rentPrice}`]
              } else {
                return [
                  ...previousValue,
                  `${+previousValue[currentIndex - 1] * rentIncrNum * 0.01 + +previousValue[currentIndex - 1]}`,
                ]
              }
            }, [])
            form.setFieldsValue({
              rentIncrList: res.map(item => ({sName: item})),
            })
          }
        }
      }
    }
  }

  function onYearChange() {
    const contractYear = form.getFieldValue('contractYear')
    const res = dayjs().add(contractYear, 'year').subtract(1, 'day')
    form.setFieldsValue({
      contractTime: [dayjs(), res],
    })
    onRentIncrList()
  }

  function onTimeChange() {
    const contractTime = form.getFieldValue('contractTime')
    const [t1, t2] = contractTime ? contractTime : []
    const contractBegin = moment(t1).format('YYYY-MM-DD')
    const contractEnd = moment(t2).format('YYYY-MM-DD')
    // 计算年
    const diffYear = dayjs(contractEnd).diff(dayjs(contractBegin), 'year')
    // 开始日期 + diffYear - 1天
    const res = dayjs(contractBegin).add(diffYear, 'year').subtract(1, 'day')
    // 结束日期超出的天数 算在下一年
    const diffDay = dayjs(contractEnd).diff(dayjs(res), 'day')
    form.setFieldsValue({contractYear: diffDay > 0 ? `${diffYear + 1}` : `${diffYear}`})
    onRentIncrList()
  }

  // function disabledDate(current) {
  //   if (!dates || dates.length === 0) {
  //     return false
  //   }
  //   const tooLate = dates[0] && current.diff(dates[0], 'year') < 3
  //   const tooEarly = dates[0] && current.diff(dates[0], 'year') > 5
  //   return tooEarly || tooLate
  // }

  return (
    <>
      <div
        style={{
          backgroundColor: '#fff',
          margin: '-24px -24px 20px',
          padding: '16px 24px 10px',
          borderTop: '1px #F5F5F5 solid',
        }}
      >
        <Breadcrumb className={chartStyles.Breadcrumb}>
          <Breadcrumb.Item>房源房态</Breadcrumb.Item>
          <Breadcrumb.Item>{chartType === 'condo' ? '集中式' : '分散式'}房态图</Breadcrumb.Item>
          <Breadcrumb.Item>添加业主合同</Breadcrumb.Item>
        </Breadcrumb>
        <Typography.Title level={3} style={{marginTop: '20px'}}>
          添加业主合同
        </Typography.Title>
      </div>
      <div className={commonStyle.section}>
        <Form
          form={form}
          labelCol={{span: 6}}
          onFinish={async () => {
            const formValue = form.getFieldsValue(true)
            const [contractBegin, contractEnd] = formValue.contractTime
            const billList = formValue.billList?.map(item => ({
              billAmtTotal: item.billAmtTotal,
              billCategory: item.category,
              billCategoryName: item.billCategoryName,
              billTypeCode: item.id,
              billTypeName: item.label,
              interval: item.interval,
            }))
            const rentIncrList = formValue.rentIncrList?.map(item => item.sName)
            const signTime = formValue.signTime
            const params = {
              ...formValue,
              billList: billList ?? [],
              rentIncrList,
              contractBegin: moment(contractBegin).format('YYYY-MM-DD'),
              contractEnd: moment(contractEnd).format('YYYY-MM-DD'),
              signTime: moment(signTime).format('YYYY-MM-DD'),
              propertyCode,
            }
            // console.log('params =>', params)
            await contractModel.contractLessorGenerateContract(params as ContractLessorGenerateDTO)
            history.goBack()
          }}
          style={{width: '80%'}}
        >
          <Form.Item label={'姓名'} name={'lessorCertName'} rules={addRules.lessorCertName}>
            <Input placeholder={'请输入业主姓名'} maxLength={18} />
          </Form.Item>
          <Form.Item label={'手机号'} name={'lessorMobile'} rules={addRules.lessorMobile}>
            <Input placeholder={'请输入手机号'} />
          </Form.Item>
          {/*身份证件*/}
          <Form.Item label={'身份证件'} required>
            <Form.Item name={'lessorCertType'} noStyle rules={addRules.lessorCertType}>
              <Select placeholder={'请选择证件类型'} style={{width: '25%'}}>
                {contractCertType.map(item => (
                  <Option key={item.id} value={item.id}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name={'lessorCertNo'} noStyle rules={addRules.lessorCertNo}>
              <Input placeholder={'证件号'} style={{width: 'calc(75% - 20px)', marginLeft: '20px'}} />
            </Form.Item>
          </Form.Item>
          {/*起租/退租*/}
          <Form.Item label={'起租/退租'} shouldUpdate required>
            {() => (
              <>
                <Form.Item name={'contractTime'} noStyle rules={addRules.contractTime}>
                  <RangePicker
                    format={'YYYY-MM-DD'}
                    onChange={() => onTimeChange()}
                    // onCalendarChange={value => setDates(value)}
                  />
                </Form.Item>
                <Form.Item name={'contractYear'} noStyle>
                  <Radio.Group style={{marginLeft: '5%'}} onChange={() => onYearChange()}>
                    <Radio.Button value={'3'}>3年</Radio.Button>
                    <Radio.Button value={'4'}>4年</Radio.Button>
                    <Radio.Button value={'5'}>5年</Radio.Button>
                    <Radio.Button value={'6'}>6年</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </>
            )}
          </Form.Item>

          {/*租金*/}
          <Form.Item label={'租金'} required>
            <Form.Item name={'rentPayType'} noStyle rules={addRules.rentPayType}>
              <Select placeholder={'请选择'} style={{width: '25%'}}>
                {rentPayType.map(item => (
                  <Option key={item.id} value={item.id}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name={'rentPrice'} noStyle rules={addRules.rentPrice}>
              <Input
                placeholder={'月租金（元）'}
                onChange={() => onRentIncrList()}
                style={{width: 'calc(75% - 20px)', marginLeft: '20px'}}
              />
            </Form.Item>
          </Form.Item>
          {/*租金分阶（免租期外置）*/}
          <Form.Item label={'租金分阶（免租期外置）'} shouldUpdate required>
            {() => {
              const f = form.getFieldValue('rentIncr')
              return (
                <>
                  <Form.Item name={'rentIncr'} noStyle rules={addRules.rentIncr}>
                    <Select
                      placeholder={'请选择'}
                      style={{width: '25%'}}
                      onChange={() => {
                        form.setFieldsValue({rentIncrValue: undefined})
                        onRentIncrList()
                      }}
                    >
                      {rentIncrType.map(item => (
                        <Option key={item.id} value={item.id}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {f === '2' && (
                    <Form.Item
                      name={'rentIncrValue'}
                      noStyle
                      rules={[
                        {required: true, message: '请填写金额'},
                        ({getFieldValue}) => ({
                          validator(rule, value) {
                            if (value < 10 || value > 999999) {
                              return Promise.reject('请输入10-999999之间的数字')
                            }
                            return Promise.resolve()
                          },
                        }),
                      ]}
                    >
                      <Input
                        placeholder={'请输入金额'}
                        onChange={() => onRentIncrList()}
                        style={{width: 'calc(75% - 20px)', marginLeft: '20px'}}
                      />
                    </Form.Item>
                  )}
                  {f === '3' && (
                    <Form.Item
                      name={'rentIncrValue'}
                      noStyle
                      rules={[
                        {required: true, message: '请填写百分比'},
                        ({getFieldValue}) => ({
                          validator(rule, value) {
                            if (value < 0 || value > 100) {
                              return Promise.reject('请输入0-100之间的数字')
                            }
                            return Promise.resolve()
                          },
                        }),
                      ]}
                    >
                      <Input
                        placeholder={'请输入百分比'}
                        onChange={() => onRentIncrList()}
                        addonAfter={'%'}
                        style={{width: 'calc(75% - 20px)', marginLeft: '20px'}}
                      />
                    </Form.Item>
                  )}
                </>
              )
            }}
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {form1 =>
              form1.getFieldValue('rentIncrList')?.length ? (
                <Form.Item label={' '} colon={false}>
                  <Form.List name={'rentIncrList'}>
                    {fields => {
                      return (
                        <div>
                          {fields.map((field, index) => (
                            <Form.Item
                              key={field.key}
                              name={[field.name, 'sName']}
                              fieldKey={[field.fieldKey, 'sName']}
                              noStyle
                            >
                              <Input
                                disabled={
                                  (form.getFieldValue('rentIncr') === '1' && index === 0) ||
                                  form.getFieldValue('rentIncr') !== '1'
                                }
                                addonBefore={`第${index + 1}年：`}
                                style={{
                                  maxWidth: 'calc(30%)',
                                  marginRight: (index + 1) % 3 === 0 ? 0 : '20px',
                                  marginTop: index >= 3 ? '20px' : 0,
                                }}
                              />
                            </Form.Item>
                          ))}
                        </div>
                      )
                    }}
                  </Form.List>
                </Form.Item>
              ) : null
            }
          </Form.Item>
          {/*其他费用*/}
          <Form.Item shouldUpdate label={'其他费用'} style={{marginLeft: '-24px'}}>
            {() => (
              <Cost
                costs={form.getFieldValue('billList')}
                addPos={'left'}
                onCostChange={costs => {
                  form.setFieldsValue({billList: costs})
                }}
              />
            )}
          </Form.Item>
          <Form.Item label={'押金'} name={'rentPledge'} rules={addRules.rentPledge}>
            <Input placeholder={'押金（元）'} />
          </Form.Item>
          {/*支付时间*/}
          <Form.Item label={'支付时间'} required>
            <Form.Item name={'rentCollectType'} noStyle rules={addRules.rentCollectType}>
              <Select placeholder={'请选择'} style={{width: 'calc(50% - 10px)'}}>
                <Option value={'1'}>每期提前</Option>
              </Select>
            </Form.Item>
            <Form.Item name={'rentCollectDay'} noStyle rules={addRules.rentCollectDay}>
              <Select placeholder={'请选择'} style={{width: 'calc(50% - 10px)', marginLeft: '20px'}}>
                {Array.from(new Array(31).keys()).map(item => (
                  <Option key={item + 1} value={item}>
                    {item}天
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form.Item>
          <Form.Item label={'签约时间'} name={'signTime'} rules={addRules.signTime}>
            <DatePicker style={{width: '100%'}} />
          </Form.Item>
          <Form.Item label={'合同编号/附件'} name={'originContractId'}>
            <Input placeholder={'合同编号（选填）'} maxLength={40} />
          </Form.Item>
          <Form.Item shouldUpdate label={'照片凭证'} extra={'可上传合同照片、房间物品照片或仪表度数照片等，最多20张'}>
            {() => (
              <Album
                limit={20}
                action={baseUrl + '/contract-lessor/upload-img'}
                data={{
                  imgType: '04',
                }}
                showUploadList={true}
                onDone={fileList => {
                  form.setFieldsValue({
                    imgIdList: fileList.map(item => item.imgId),
                  })
                }}
              />
            )}
          </Form.Item>
          <Form.Item label={'备注信息'} name={'remark'}>
            <Input.TextArea rows={4} placeholder={'请填写备注信息'} />
          </Form.Item>
          <Form.Item label={' '} colon={false}>
            <Button type={'primary'} htmlType={'submit'} loading={subLoading}>
              创建合同
            </Button>
            <Button htmlType={'reset'} style={{marginLeft: '12px'}} onClick={() => history.goBack()}>
              返回
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  )
}
