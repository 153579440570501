import {Model} from '@redux-model/web'
import {$api} from '@/store/service'

export interface DeviceModel {}

class Device extends Model<DeviceModel> {
  protected initReducer(): DeviceModel {
    return {}
  }

  // todo 公共
  // 查询是否已绑定云丁账号
  deviceYundingAccessStatus = $api.action(() => this.post<ApiResponse<boolean>>('/device/yunding-access/status'))

  // 绑定云丁账号
  deviceYundingAccessBindSecret = $api.action((args: {clientId: string; clientSecret: string}) =>
    this.post('/device/yunding-access/bind-secret').body(args)
  )

  // 房源绑定的设备列表
  deviceList = $api.action((args: {propertyCode: string; type: '1' | '2' | '3'}) =>
    this.post<ApiResponse<DeviceVO[]>>('/device/list').body(args)
  )

  // 解绑云丁门锁/水表/电表
  deviceYundingDeviceUnbind = $api.action((args: Partial<DeviceYunDingBindDTO>) =>
    this.post('/device/yunding-device/unbind').body(args)
  )

  // 查询待绑定的门锁（房源）列表
  deviceYundingLockList = $api.action((args: Partial<DeviceYunDingPropertyDTO>) =>
    this.post<ApiResponse<YunDingHomeBasicInfoVO[]>>('/device/yunding-lock/list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // todo 门锁
  // web-门锁列表
  deviceLockList = $api.action((args: Partial<DeviceListDTO>) =>
    this.post<ApiResponse<DeviceListVO[]>>('/device/lock/list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // 绑定云丁门锁
  deviceYundingLockBind = $api.action((args: DeviceYunDingBindDTO) => this.post('/device/yunding-lock/bind').body(args))

  // 云丁门锁详情
  deviceYundingLockInfo = $api.action((args: {lockId: string}) =>
    this.post<ApiResponse<DeviceYunDingLockInfoVO>>('/device/yunding-lock/info').body(args)
  )

  // 开门记录列表
  deviceLockOpenRecord = $api.action((args: DeviceLockRecordDTO) =>
    this.post<ApiResponse<YunDingLockEventVO[]>>('/device/lock/open/record').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // 授权记录列表
  deviceLockPasswordRecord = $api.action((args: DeviceLockRecordDTO) =>
    this.post<ApiResponse<DeviceLockPasswordLogVO[]>>('/device/lock/password/record').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // 授权管理列表
  deviceLockPasswordList = $api.action((args: {lockId: string}) =>
    this.post<ApiResponse<DeviceYunDingLockPasswordTokenVO[]>>('/device/lock/password/list').body(args)
  )

  // 获取动态密码
  deviceYundingLockDynamicPassword = $api.action((args: {lockId: string}) =>
    this.post<ApiResponse<DeviceYunDingLockPasswordPlaintextVO>>('/device/yunding-lock/dynamic-password').body(args)
  )

  // 添加授权
  deviceLockPasswordInsert = $api.action((args: {propertyCode: string; lockId: string}) =>
    this.post('/device/lock/password/insert').body(args)
  )

  // 删除授权
  deviceLockPasswordDelete = $api.action((args: {pwdId: string}) =>
    this.post('/device/lock/password/delete').body(args)
  )

  // 启用/禁用授权
  deviceLockPasswordEnable = $api.action((args: {pwdId: string; enable: boolean}) =>
    this.post('/device/lock/password/enable').body(args)
  )

  // 修改有效期
  deviceLockPasswordUpdate = $api.action((args: DeviceLockUpdatePasswordDTO) =>
    this.post<ApiResponse>('/device/lock/password/update').body(args)
  )

  // todo 电表
  // web-电表列表
  deviceElemeterList = $api.action((args: Partial<DeviceListDTO>) =>
    this.post<ApiResponse<DeviceListVO[]>>('/device/elemeter/list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // 绑定云丁电表
  deviceYundingElemeterBind = $api.action((args: DeviceYunDingBindDTO) =>
    this.post('/device/yunding-elemeter/bind').body(args)
  )

  // 查询电表详情
  deviceYundingElemeterInfo = $api.action((args: {elemeterId: string}) =>
    this.post<ApiResponse<DeviceYunDingElemeterInfoVO>>('/device/yunding-elemeter/info').body(args)
  )

  // 更新电价设置
  deviceYundingElemeterUpdatePrice = $api.action((args: Partial<DeviceYunDingElemeterUpdateDTO>) =>
    this.post('/device/yunding-elemeter/update-price').body(args)
  )

  // 更新公区分摊
  deviceYundingElemeterUpdatePooling = $api.action((args: Partial<DeviceYunDingElemeterUpdateDTO>) =>
    this.post<ApiResponse>('/device/yunding-elemeter/update-pooling').body(args)
  )

  // 电表强制断电或开启
  deviceYundingElemeterEnableState = $api.action((args: Partial<DeviceYunDingElemeterUpdateDTO>) =>
    this.post<ApiResponse>('/device/yunding-elemeter/enable-state').body(args)
  )

  // 用电明细
  deviceYundingElemeterRecord = $api.action((args: Partial<DeviceRecordDTO>) =>
    this.post<ApiResponse<YunDingElemeterHistoryVO[]>>('/device/yunding-elemeter/record').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // 房东充值记录
  deviceElemeterChargeList = $api.action((args: Partial<ChargeListDTO>) =>
    this.post<ApiResponse<ElemeterChargeListVO[]>>('/device/elemeter/charge/list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // todo 水表
  // web-水表列表
  deviceWatermeterList = $api.action((args: Partial<DeviceListDTO>) =>
    this.post<ApiResponse<DeviceListVO[]>>('/device/watermeter/list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // 绑定云丁水表
  deviceYundingWatermeterBind = $api.action((args: DeviceYunDingBindDTO) =>
    this.post('/device/yunding-watermeter/bind').body(args)
  )

  // 查询水表详情
  deviceYundingWatermeterInfo = $api.action((args: {watermeterId: string}) =>
    this.post<ApiResponse<DeviceYunDingWatermeterInfoVO>>('/device/yunding-watermeter/info').body(args)
  )

  // 更新水价设置
  deviceWatermeterUpdatePrice = $api.action((args: DeviceYunDingWatermeterUpdateDTO) =>
    this.post('/device/watermeter/update-price').body(args)
  )

  // 用水明细
  deviceWatermeterRecord = $api.action((args: Partial<DeviceRecordDTO>) =>
    this.post<ApiResponse<WatermeterRecordVO[]>>('/device/watermeter/record').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // 房东充值
  deviceWatermeterCharge = $api.action((args: DeviceWatermeterChargeDTO) =>
    this.post('/device/watermeter/charge').body(args)
  )

  // 充值记录
  deviceWatermeterChargeList = $api.action((args: ChargeListDTO) =>
    this.post<ApiResponse<WatermeterChargeListVO[]>>('/device/watermeter/charge/list').body(args)
  )

  // 查询水表详情  |  object:{watermeterId:水表id
  deviceYundingWatermeterRefresh = $api.action((args: {watermeterId: string}) =>
    this.post<ApiResponse<DeviceYunDingWatermeterInfoVO>>('/device/yunding-watermeter/refresh').body(args)
  )

  // todo: 牛油果智能设备-门锁
  // 门锁列表
  deviceV2LockList = $api.action((args: DeviceV2ListDTO) =>
    this.post<ApiResponse<Lock[]>>('/device/v2/lock/list').body(args)
  )

  // 门锁详情  |  lockId：门锁id
  deviceV2LockInfo = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Lock>>('/device/v2/lock/info').body(args)
  )

  // 授权操作记录
  deviceV2LockPasswordRecord = $api.action((args: DeviceV2LockPasswordRecordListDTO) =>
    this.post<ApiResponse<DeviceV2LockPasswordRecordVO[]>>('/device/v2/lock/password/record').body(args)
  )

  // 门锁开门记录
  deviceV2LockOpenRecord = $api.action((args: DeviceLockRecordDTO) =>
    this.post<ApiResponse<DeviceV2LockOpenRecordVO[]>>('/device/v2/lock/open/record').body(args)
  )

  // 授权管理列表  |  lockId：门锁id
  deviceV2LockPasswordList = $api.action((args: DeviceV2LockPasswordListDTO) =>
    this.post<ApiResponse<DeviceV2LockPasswordVO[]>>('/device/v2/lock/password/list').body(args)
  )

  // 删除授权  |  pwdId：授权Id
  deviceV2LockPasswordDelete = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/device/v2/lock/password/delete').body(args)
  )

  // 新增授权  |  lockId：门锁id
  deviceV2LockPasswordInsert = $api.action((args: DeviceV2LockPasswordInsertDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/device/v2/lock/password/insert').body(args)
  )

  // 新增并返回获取动态密码  |  lockId：门锁id
  deviceV2LockDynamicPassword = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<PairModel>>('/device/v2/lock/dynamic-password').body(args)
  )

  // todo: 牛油果智能设备-电表
  // 电表列表
  deviceV2EleMeterList = $api.action((args: DeviceV2ListDTO) =>
    this.post<ApiResponse<EleMeter[]>>('/device/v2/ele-meter/list').body(args)
  )

  // 查询电表详情  |  eleMeterId：电表id
  deviceV2EleMeterInfo = $api.action((args: {eleMeterId: string}) =>
    this.post<ApiResponse<EleMeter>>('/device/v2/ele-meter/info').body(args).metas(args.eleMeterId)
  )

  // 用电明细
  deviceV2EleMeterRecord = $api.action((args: DeviceV2MeterRecordListDTO) =>
    this.post<ApiResponse<DeviceV2MeterRecordVO[]>>('/device/v2/ele-meter/record').body(args)
  )

  // 电表更新抄表  |  eleMeterId：电表id
  deviceV2EleMeterRead = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/device/v2/ele-meter/read').body(args)
  )

  // 电表控制  |  eleMeterId：电表id,valveForce:-1:自动 1:通 0:断
  deviceV2EleMeterForce = $api.action((args: {eleMeterId: string; valveForce: -1 | 0 | 1}) =>
    this.post<ApiResponse<EleMeter>>('/device/v2/ele-meter/force').body(args).metas(args.valveForce)
  )

  // todo: 牛油果智能设备-水表
  // 水表列表
  deviceV2WaterMeterList = $api.action((args: DeviceV2ListDTO) =>
    this.post<ApiResponse<WaterMeter[]>>('/device/v2/water-meter/list').body(args)
  )

  // 查询水表详情  |  object:{waterMeterId:水表id
  deviceV2WaterMeterInfo = $api.action((args: {waterMeterId: string}) =>
    this.post<ApiResponse<WaterMeter>>('/device/v2/water-meter/info').body(args).metas(args.waterMeterId)
  )

  // 用水明细
  deviceV2WaterMeterRecord = $api.action((args: DeviceV2MeterRecordListDTO) =>
    this.post<ApiResponse<DeviceV2MeterRecordVO[]>>('/device/v2/water-meter/record').body(args)
  )

  // 水表更新抄表  |  object:{waterMeterId:水表id
  deviceV2WaterMeterRead = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/device/v2/water-meter/read').body(args)
  )

  // todo: 牛油果智能设备-水电余额
  // 水电余额记录
  deviceV2MeterWalletRecordList = $api.action((args: DeviceV2WalletRecordListDTO) =>
    this.post<ApiResponse<DeviceV2WalletRecordVO[]>>('/device/v2/meter/wallet/record/list').body(args)
  )

  // 水电余额充值
  deviceV2MeterWalletCharge = $api.action((args: DeviceV2WalletChargeBillDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/device/v2/meter/wallet/charge').body(args)
  )

  // 水电余额详情  |  recordId：记录id
  deviceV2MeterWalletRecordInfo = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<DeviceV2WalletRecordVO>>('/device/v2/meter/wallet/record/info').body(args)
  )

  // 水电余额  |  propertyCode：房源code
  deviceV2MeterWalletBalance = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<DeviceV2WalletVO>>('/device/v2/meter/wallet/balance').body(args)
  )

  // 查询设备历史
  deviceV2DeviceHistoryList = $api.action((args: DeviceV2ListDTO) =>
    this.post<ApiResponse<DeviceV2HistoryVO[]>>('/device/v2/device/history/list').body(args)
  )
}

export const deviceModel = new Device()
