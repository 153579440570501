import React, {useEffect, useState} from 'react'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import styles from '@/pages/contract/List/index.module.less'
import {Button, Col, Form, Input, Row, Select, Space, Table, Tabs, Typography} from 'antd'
import commonStyle from '@/assets/styles/common.module.less'
import {propertyModel} from '@/store/models/Property'
import {useHistory} from 'react-router-dom'

const {TabPane} = Tabs
const {Option} = Select

export default function OverdueProperty() {
  const history = useHistory()

  const [form] = Form.useForm()

  // 查看小区
  const [communityList, setCommunityList] = useState<CommunityVO[]>([])

  const [total, setTotal] = useState(0)
  const [cond, setCond] = useState<Partial<PropertyOverdueBillDTO>>({pageNum: 1, pageSize: 10})
  const [overdueList, setOverdueList] = useState<PropertyOverdueBillVO[]>([])

  useEffect(
    function () {
      propertyModel.propertyListPropertyByOverdueBill(cond).then(({response: {data, total}}) => {
        setTotal(total)
        setOverdueList(data)
      })
    },
    [cond]
  )

  useEffect(() => {
    propertyModel.propertyCommunityList({}).then(({response: {data}}) => {
      setCommunityList(data)
    })
  }, [])

  return (
    <>
      <Breadcrumb path={['首页', '房源房态', '账单逾期房源']} />
      <Tabs
        defaultActiveKey={'3'}
        className={styles.tabs}
        onTabClick={index => setCond(prevState => ({...prevState, rentMode: index === '3' ? undefined : index}))}
      >
        <TabPane tab={'全部'} key={'3'} />
        <TabPane tab={'分散式房源'} key={'1'} />
        <TabPane tab={'集中式公寓'} key={'2'} />
      </Tabs>

      <div className={commonStyle.section}>
        <Form
          form={form}
          onFinish={formValues => {
            setCond(prevState => ({...prevState, ...formValues}))
          }}
          labelAlign={'left'}
          labelCol={{span: 6}}
        >
          <Row>
            <Col span={7}>
              <Form.Item label={'小区'} name={'communityCode'}>
                <Select allowClear placeholder={'小区'} style={{width: '100%'}}>
                  {communityList.map(item => (
                    <Option value={item.code} key={item.code}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'房源地址'} name={'propertyTitle'}>
                <Input allowClear placeholder={'小区/公寓/房间号'} />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'逾期笔数'}>
                <Input.Group compact>
                  <Form.Item noStyle name={'billNumMin'}>
                    <Input allowClear style={{width: 'calc(50% - 15px)', textAlign: 'center'}} placeholder='最小笔数' />
                  </Form.Item>
                  <Input
                    style={{width: 30, borderLeft: 0, borderRight: 0, pointerEvents: 'none', backgroundColor: '#fff'}}
                    placeholder='-'
                    disabled
                  />
                  <Form.Item noStyle name={'billNumMax'}>
                    <Input
                      allowClear
                      style={{width: 'calc(50% - 15px)', textAlign: 'center', borderLeftWidth: 0}}
                      placeholder='最大笔数'
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={7}>
              <Form.Item label={'逾期金额'}>
                <Input.Group compact>
                  <Form.Item noStyle name={'billAmtMin'}>
                    <Input allowClear style={{width: 'calc(50% - 15px)', textAlign: 'center'}} placeholder='最小金额' />
                  </Form.Item>
                  <Input
                    style={{width: 30, borderLeft: 0, borderRight: 0, pointerEvents: 'none', backgroundColor: '#fff'}}
                    placeholder='-'
                    disabled
                  />
                  <Form.Item noStyle name={'billAmtMax'}>
                    <Input
                      allowClear
                      style={{width: 'calc(50% - 15px)', textAlign: 'center', borderLeftWidth: 0}}
                      placeholder='最大金额'
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'}>
                查询
              </Button>
              <Button
                htmlType={'reset'}
                onClick={() => {
                  setCond(prevState => ({pageNum: 1, pageSize: 10, rentMode: prevState.rentMode}))
                  form.resetFields()
                }}
              >
                重置
              </Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div className={commonStyle.section}>
        <Table
          scroll={{x: 'max-content'}}
          rowKey={'propertyCode'}
          dataSource={overdueList}
          loading={false}
          title={() => (
            <Typography.Text>
              <Typography.Text strong style={{marginRight: '12px', marginLeft: '-16px'}}>
                查询结果
              </Typography.Text>
              共计
              <Typography.Text strong style={{color: '#017BFF'}}>
                {total}
              </Typography.Text>
              条符合条件的结果
            </Typography.Text>
          )}
          columns={[
            {title: '房源地址', dataIndex: 'propertyTitle', key: 'propertyTitle'},
            {title: '承租人', dataIndex: 'lesseeCertName', key: 'lesseeCertName', align: 'center'},
            {title: '承租人手机号', dataIndex: 'lesseeMobile', key: 'lesseeMobile', align: 'center'},
            {title: '逾期账单笔数', dataIndex: 'billNum', key: 'billNum', align: 'center'},
            {title: '逾期账单金额', dataIndex: 'billAmt', key: 'billAmt', align: 'center'},
            {
              title: '操作',
              dataIndex: 'contractId',
              render: contractId => {
                return (
                  <Button
                    type={'link'}
                    style={{padding: 0}}
                    onClick={() =>
                      history.push({
                        pathname: '/contract/detail',
                        state: {contractId, activeKey: 'billInfo'},
                      })
                    }
                  >
                    查看
                  </Button>
                )
              },
            },
          ]}
          pagination={{
            total,
            current: cond.pageNum,
            pageSize: cond.pageSize,
            position: ['bottomCenter'],
            showSizeChanger: true,
            hideOnSinglePage: false,
            onChange: (page: number, pageSize?: number) => {
              setCond(prevState => ({...prevState, pageNum: prevState.pageSize === pageSize ? page : 1, pageSize}))
            },
          }}
        />
      </div>
    </>
  )
}
