import React, {useEffect, useState} from 'react'
import {Input, Space, Form, Select, Row, Col} from 'antd'
import {MinusCircleOutlined, PlusCircleOutlined} from '@ant-design/icons'
import {contractModel} from '@/store/models/Contract'
import {renderChargeItem} from '@/utils/render'

export interface CostProps {
  costs?: OtherCost[] | string
  addPos?: 'left' | 'right'
  onCostChange: (costs: OtherCost[]) => any
  // 如果是外部传入，至少传一个空数组 []
  billList?: OtherCost[]
}

const periodTypeList = [
  {label: '周期费用', id: '1'},
  {label: '一次性费用', id: '0'},
]

const typeMap = {
  '0': [{label: '首期收取', id: 1}],
  '1': [
    {label: '每月一笔', id: 1},
    {label: '两月一笔', id: 2},
    {label: '三月一笔', id: 3},
    {label: '半年一笔', id: 6},
  ],
}

export function Cost(props: CostProps) {
  const [form] = Form.useForm()

  const isPropBillList = !!props.billList

  const [_billList, setBillList] = useState<OtherCost[]>([])

  const billList = isPropBillList ? props.billList : _billList

  useEffect(() => {
    if (!isPropBillList) {
      contractModel.lessorConfigContractListSelected().then(({response: {data}}) => {
        setBillList(renderChargeItem(data.billConfigList))
      })
    }
  }, [isPropBillList])

  useEffect(
    function () {
      if (props.costs) {
        form.setFieldsValue({cost: typeof props.costs === 'string' ? JSON.parse(props.costs || '[]') : props.costs})
      }
      // 弹窗优化，其他费用不要默认值
      // else {
      //   form.setFieldsValue({cost: [{}]})
      // }
    },
    [form, props.costs]
  )

  return (
    <Form
      form={form}
      component={false}
      onValuesChange={() => {
        setTimeout(function () {
          typeof props.onCostChange === 'function' && props.onCostChange(form.getFieldsValue(true).cost as OtherCost[])
        }, 1000 / 60)
      }}
    >
      <Form.List name={'cost'}>
        {(fields, {add, remove}) => {
          if (!fields.length) return <PlusCircleOutlined color={'blue'} onClick={() => add({})} />
          return (
            <>
              {fields.map((field, index) => {
                const fieldItem = form.getFieldValue(['cost', field.name])

                return (
                  <Row gutter={20} key={index} style={{marginBottom: 10}}>
                    {props.addPos === 'left' && (
                      <Col style={{width: '14px'}}>
                        <Space style={{height: '100%'}}>
                          {index === 0 && <PlusCircleOutlined color={'blue'} onClick={() => add({})} />}
                        </Space>
                      </Col>
                    )}
                    <Col span={5}>
                      <Form.Item name={[field.name, 'type']} fieldKey={[field.fieldKey, 'type']} noStyle>
                        <Select
                          placeholder={'请选择'}
                          onSelect={value => {
                            form.setFields([
                              {name: ['cost', field.name, 'interval'], value: value === '1'},
                              {name: ['cost', field.name, 'intervalFreq'], value: undefined},
                            ])
                          }}
                        >
                          {periodTypeList.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item shouldUpdate noStyle>
                        {() => (
                          <Form.Item
                            name={[field.name, 'intervalFreq']}
                            fieldKey={[field.fieldKey, 'intervalFreq']}
                            noStyle
                          >
                            <Select placeholder={'请选择'}>
                              {typeof fieldItem?.interval === 'boolean'
                                ? typeMap[fieldItem.interval ? '1' : '0'].map(value => (
                                    <Select.Option key={value.id} value={value.id}>
                                      {value.label}
                                    </Select.Option>
                                  ))
                                : null}
                            </Select>
                          </Form.Item>
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item shouldUpdate noStyle>
                        {() => (
                          <Form.Item name={[field.name, 'id']} fieldKey={[field.fieldKey, 'id']} noStyle>
                            <Select
                              placeholder={'请选择'}
                              onSelect={value => {
                                const bill = billList.find(item => item.id === value)
                                form.setFields([
                                  {
                                    name: ['cost', field.name],
                                    value: {
                                      ...fieldItem,
                                      category: bill.category,
                                      id: bill.id,
                                      label: bill.label,
                                      name: bill.name,
                                    } as OtherCost,
                                  },
                                ])
                              }}
                            >
                              {billList.map(value => (
                                <Select.Option
                                  key={value.id}
                                  value={value.id}
                                  disabled={form
                                    .getFieldValue('cost')
                                    ?.map(value => value.id)
                                    ?.includes(value.id)}
                                >
                                  {value.label}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        )}
                      </Form.Item>
                    </Col>
                    {/*<Col span={5}>
                      <Form.Item shouldUpdate noStyle>
                        {() => {
                          const currentItem = form.getFieldValue('cost') ? form.getFieldValue('cost')[index] : {}
                          return (
                            <Form.Item name={[field.name, 'id']} fieldKey={[field.fieldKey, 'id']} noStyle>
                              <Select
                                placeholder={'请选择'}
                                onSelect={() => {
                                  const currentItem: OtherCost = form.getFieldValue(['cost', index])
                                  const currentChartge = currentItem.type
                                    ? otherCharges[currentItem.type]?.find(item => item.id === currentItem.id)
                                    : {}
                                  form.setFields([
                                    {
                                      name: ['cost', field.name],
                                      value: {...currentItem, ...currentChartge},
                                    },
                                  ])
                                }}
                              >
                                {otherCharges[currentItem?.type]?.map((item: OtherCost) => (
                                  <Select.Option
                                    key={item.id}
                                    value={item.id}
                                    disabled={form
                                      .getFieldsValue(true)
                                      .cost.map(_item => _item?.id)
                                      .includes(item.id)}
                                  >
                                    {item.label}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          )
                        }}
                      </Form.Item>
                    </Col>*/}
                    <Col span={5}>
                      <Form.Item shouldUpdate noStyle>
                        {() => (
                          <Form.Item
                            name={[field.name, 'billAmtTotal']}
                            fieldKey={[field.fieldKey, 'billAmtTotal']}
                            noStyle
                          >
                            <Input placeholder={'请输入金额'} />
                          </Form.Item>
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <Space style={{height: '100%'}}>
                        <MinusCircleOutlined onClick={() => remove(index)} />
                        {props.addPos !== 'left' && index === fields.length - 1 && (
                          <PlusCircleOutlined color={'blue'} onClick={() => add({})} />
                        )}
                      </Space>
                    </Col>
                  </Row>
                )
              })}
            </>
          )
        }}
      </Form.List>
    </Form>
  )
}
