import React, {useImperativeHandle, useRef, useState} from 'react'
import {Col, Form, Input, message, Modal, Radio, Row} from 'antd'
import {contractModel} from '@/store/models/Contract'
import {useAppHistory} from '@/common/hooks/useRouter'
import {checkContractAudit} from '@/ui/bizComponents/AuditButton'

interface ChargeModalParams {
  contractId: string
  rentType: string
  communityCode: string
}

function _ChargeModal(_, ref) {
  const [chargeVisible, setChargeVisible] = useState(false)
  const [chargeForm] = Form.useForm()
  const history = useAppHistory()
  const params = useRef<ChargeModalParams>(null)

  useImperativeHandle(
    ref,
    () => ({
      onCharge: async (props: ChargeModalParams) => {
        const {r1, r2} = await checkContractAudit(props)

        if (r1) {
          switch (r2?.auditStatus) {
            case '1': {
              // 审核中
              return message.warning(`'${r2?.auditTypeName}'正在审核中`)
            }
            case '2': {
              // 已通过
              return history.push('/contract/property-change', {
                feeTag: '1',
                contractId: props.contractId,
                communityCode: props.communityCode,
              })
            }
            // 没有审批或者已驳回
            case '3':
            default:
              params.current = props
              return setChargeVisible(true)
          }
        }
        history.push('/contract/property-change', {contractId: props.contractId})
      },
    }),
    [history]
  )

  return (
    <Modal
      zIndex={9999}
      visible={chargeVisible}
      centered
      title={'换房选择'}
      onCancel={() => setChargeVisible(false)}
      onOk={() => chargeForm.submit()}
      afterClose={() => chargeForm.resetFields()}
    >
      <Form
        form={chargeForm}
        onFinish={async formValues => {
          if (formValues['charge'] === '1') {
            history.push('/contract/property-change', {
              contractId: params.current.contractId,
              communityCode: params.current.communityCode,
            })
          } else {
            await contractModel.contractAuditSubmit({
              auditType: '2',
              contractCheckOutObject: null,
              contractId: params.current.contractId,
              contractThrowObject: null,
              createdReason: formValues['createdReason'],
              refundAmt: null,
              reserveCancelObject: null,
            } as ContractAuditSubmitDTO)
            message.success('免手续费换房申请已提交')
            setChargeVisible(false)
          }
        }}
        initialValues={{charge: '1'}}
      >
        <Row>
          <Col span={24}>
            <Form.Item name={'charge'} label={'换房手续费'} labelCol={{span: 4}}>
              <Radio.Group>
                <Radio value={'1'}>正常收取</Radio>
                <Radio value={'2'}>免手续费</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Form.Item noStyle shouldUpdate>
            {form1 =>
              form1.getFieldValue('charge') === '2' ? (
                <Col span={24}>
                  <Form.Item
                    name={'createdReason'}
                    label={'备注'}
                    labelCol={{span: 4}}
                    rules={[{required: true, message: '请输入备注'}]}
                  >
                    <Input.TextArea placeholder={'请输入'} maxLength={100} />
                  </Form.Item>
                </Col>
              ) : null
            }
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  )
}

const ChargeModal = React.forwardRef(_ChargeModal)
export default ChargeModal
