import React, {useState, useImperativeHandle, useEffect, useMemo} from 'react'
import {Modal, Form, Select, DatePicker, Typography, Button, InputNumber, message} from 'antd'
import styles from './index.module.less'
import {contractModel} from '@/store/models/Contract'
import {ExclamationCircleFilled} from '@ant-design/icons'
import dayjs from 'dayjs'

const {Option} = Select
const {Text} = Typography
const {confirm} = Modal

interface Props {
  onSuccess?: (billId?: string) => unknown
}

function BillDivision({onSuccess}: Props, ref) {
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const [contractId, setContractId] = useState('')
  const [billId, setBillId] = useState('')
  const [billAmtUnpaid, setBillAmtUnpaid] = useState(0)
  const [billBegin, setBillBegin] = useState('')
  const [billIntervalList, setBillIntervalList] = useState([] as ContractBillIntervalVO[])
  const loading = contractModel.billDivisionDivide.useLoading()
  const zhNum = useMemo(() => ['一', '二', '三', '四', '五', '六', '七', '八', '九'], [])

  useEffect(() => {
    if (contractId) {
      contractModel.contractBillInterval({contractId}).then(({response: {data}}) => {
        const res = data.filter(
          item =>
            item.entirety &&
            (dayjs(item.billBegin).isSame(dayjs(billBegin)) || dayjs(item.billBegin).isAfter(dayjs(billBegin)))
        )
        setBillIntervalList([
          {
            billBegin: '',
            billDate: '',
            billEnd: '',
            contractDetailId: '',
            contractId: '',
            entirety: true,
            interval: null,
            intervalName: '无账期',
          },
          ...res,
        ])
        const [item] = res
        form.setFieldsValue({division: [{billDate: dayjs(), intervalName: item?.intervalName ?? ''}, {}], count: 1})
      })
    }
  }, [billBegin, contractId, form])

  // useEffect(() => {
  //   form.setFieldsValue({division: [{}, {}], count: 1})
  // })

  useImperativeHandle(ref, () => ({
    onDisplay: () => {
      setVisible(true)
    },
    onSetInfo: (contractId: string, billId: string, billAmtUnpaid: number, billBegin: string) => {
      setContractId(contractId)
      setBillId(billId)
      setBillAmtUnpaid(billAmtUnpaid)
      setBillBegin(billBegin)
    },
    onHidden: () => {
      setVisible(false)
      setContractId('')
      setBillId('')
      setBillAmtUnpaid(0)
      setBillBegin('')
      form.resetFields()
    },
  }))

  function onChange(value) {
    const count = value + 1
    const list = []
    for (let i = 0; i < count; i++) {
      list.push({})
    }

    form.setFieldsValue({division: list})
  }

  function calculate(list: []) {
    return (
      list &&
      (billAmtUnpaid - list.reduce((tol, cur: any) => Number(tol) + Number(cur?.billAmtTotal || 0), 0)).toFixed(2)
    )
  }

  function onFinish() {
    const division = form.getFieldValue('division')
    if (calculate(division) !== '0.00') {
      message.error('拆分金额有误')
      return
    }
    const _list = division.map(item => ({
      billAmtTotal: item.billAmtTotal,
      billDate: item.billDate.format('YYYY-MM-DD'),
      billBegin: billIntervalList.find(_item => _item.intervalName === item.intervalName)?.billBegin,
      billEnd: billIntervalList.find(_item => _item.intervalName === item.intervalName)?.billEnd,
    }))
    confirm({
      title: '账单拆分',
      icon: <ExclamationCircleFilled />,
      content: '拆分后不可合并，是否确认拆分账单。',
      onOk() {
        contractModel.billDivisionDivide(billId, _list).then(() => {
          onSuccess && onSuccess(billId)
          ref.current.onHidden()
          message.success('账单拆分成功')
        })
      },
    })
  }

  return (
    <Modal
      title={<div style={{textAlign: 'center'}}>账单拆分</div>}
      destroyOnClose
      visible={visible}
      onCancel={() => ref.current.onHidden()}
      centered={true}
      width={460}
      footer={null}
    >
      <Form form={form} onFinish={onFinish} labelCol={{span: 8}}>
        <Form.Item label={'拆分数量'} name={'count'} rules={[{required: true, message: '请选择拆分数量'}]}>
          <Select style={{width: '200px'}} placeholder={'账单子项目'} onChange={onChange}>
            {zhNum.map((item, index) => (
              <Option value={index} key={index} disabled={index === 0}>
                {index + 1}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <div className={styles.content}>
          <Form.Item shouldUpdate noStyle>
            {() => (
              <Form.List name={'division'}>
                {fields => {
                  return (
                    <>
                      {fields.map((field, index) => (
                        <React.Fragment key={field.fieldKey}>
                          <Form.Item
                            label={`第${zhNum[index]}份金额`}
                            name={[field.name, 'billAmtTotal']}
                            fieldKey={[field.fieldKey, 'billAmtTotal']}
                            rules={[{required: true, message: `请输入第${zhNum[index]}份金额`}]}
                          >
                            <InputNumber
                              style={{width: '200px'}}
                              min={10 as number}
                              step={100}
                              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              parser={value => +value.replace(/(,*)/g, '')}
                              precision={2}
                            />
                          </Form.Item>
                          <Form.Item
                            label={'应收款日'}
                            name={[field.name, 'billDate']}
                            fieldKey={[field.fieldKey, 'billDate']}
                            rules={[{required: true, message: '请选择收款日期'}]}
                          >
                            <DatePicker style={{width: '200px'}} />
                          </Form.Item>
                          <Form.Item
                            label={'所属账期'}
                            name={[field.name, 'intervalName']}
                            fieldKey={[field.fieldKey, 'intervalName']}
                            rules={[{required: true, message: '请选择所属账期'}]}
                          >
                            <Select allowClear style={{width: '200px'}} placeholder={'请选择所属账期'}>
                              {billIntervalList.map(item => (
                                <Option value={item.intervalName} key={item.intervalName}>
                                  {item.intervalName}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </React.Fragment>
                      ))}
                    </>
                  )
                }}
              </Form.List>
            )}
          </Form.Item>
        </div>
        <Form.Item shouldUpdate>
          {() => (
            <Text className={styles.text}>
              剩余可分配金额￥
              {calculate(form.getFieldValue('division'))}
            </Text>
          )}
        </Form.Item>
        <footer className={styles.footer}>
          <Button type={'primary'} htmlType={'submit'} loading={loading}>
            保存
          </Button>
        </footer>
      </Form>
    </Modal>
  )
}

export default React.forwardRef(BillDivision)
