import React, {useState, useEffect, useMemo, useCallback} from 'react'
import styles from './index.module.less'
import {
  Select,
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  Button,
  Table,
  Typography,
  Statistic,
  Space,
  Modal,
  Divider,
  message,
  Tabs,
  Badge,
} from 'antd'
import {tradeModel} from '@/store/models/Trade'
import moment from 'moment'
import {commonModel, useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {useHistory, useLocation} from 'react-router-dom'
import {downloadFile} from '@/utils/util'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import {Permission, usePermission} from '@/ui/components/Permission'
import {useListener} from '@/common/hooks/listener'
import {add, toFixed} from '@/utils/amount'
import type {ColumnsType} from 'antd/lib/table/interface'
// import {contractModel} from '@/store/models/Contract'

const {Option} = Select
const {RangePicker} = DatePicker
const {Text} = Typography

const tabMap = {
  '0': {status: undefined, writeOff: undefined},
  '1': {status: undefined, writeOff: '1'},
  '2': {status: undefined, writeOff: '0'},
  '3': {status: '0', writeOff: undefined},
}

interface FormData {
  propertyTitle: string
  lesseeCertName: string
  lesseeMobile: string
  time: string[]
  tradePayWayCode: string
  writeOff: string
  tradeCategory: string
  tradeTypeCode: string
  creatorName: string
  tradeSource: string
}

export default function TradingWater() {
  const loading = tradeModel.tradeWebFlowList.useLoading()
  const history = useHistory()
  const location = useLocation<any>()
  const type = useMemo(() => location.state?.type, [location])
  const label = useMemo(() => location.state?.label, [location])
  const [tradeRemark, setTradeRemark] = useState('')
  const [activeKey, setActiveKey] = useState('0')
  //查询参数
  const [params, setParams] = useState<Partial<TradeListQueryWebDTO>>({
    pageNum: 1,
    pageSize: 10,
    pageable: true,
    propertyCode: type === 1 ? label : '',
    tradeDateStart: type === 2 ? label : '',
    tradeDateEnd: type === 2 ? label : '',
  })
  const [form] = Form.useForm()
  const [auth] = usePermission()
  //table列表
  const [tableList, setTableList] = useState<TradeVO[]>()
  //table总数
  const [total, setTotal] = useState<{[key: string]: number}>({})
  //进出账总数
  const [number, setNumber] = useState<TradeStatisticsWebVO>()
  //交易方式
  const tradeWays = useCodeArraySync('bill.payWay')
  // const [tradeWays, setTradeWays] = useState<ReturnType<typeof useCodeArraySync>>([])
  // useEffect(function () {
  //   contractModel.lessorConfigContractListAll().then(({response: {data}}) => {
  //     setTradeWays(data.payConfigList.map(item => ({id: item.payWay, label: item.payWayName})))
  //   })
  // }, [])
  const tradeTypeArray = useCodeArraySync('trade.source')
  const tradeTypeCode = useCodeSync('trade.source')
  //账单大类
  const tradeCategory = useCodeArraySync('b')

  const tradeWriteOffLoading = tradeModel.tradeWriteOff.useLoading()

  //批量选中
  const [selectedRows, setSelectedRows] = useState<typeof tableList>([])
  const selectedRowKeys = useMemo(() => selectedRows.map(value => value.tradeId), [selectedRows])

  //初始化账单子类
  const [billTypeCode, setBillTypeCode] = useState([])

  useEffect(() => {
    form.setFieldsValue({
      time: type === 2 ? [moment(label), moment(label)] : [],
    })
  }, [form, label, type])

  useListener('query-trade', (type: number, label: string) => {
    setParams(state => ({
      ...state,
      propertyCode: type === 1 ? label : '',
      tradeDateStart: type === 2 ? label : '',
      tradeDateEnd: type === 2 ? label : '',
      pageNum: 1,
    }))
    form.setFieldsValue({
      time: type === 2 ? [moment(label), moment(label)] : [],
    })
  })

  useEffect(() => {
    const flow = {...params, ...tabMap[activeKey]}
    tradeModel.tradeWebFlowList(flow).then(({response: {data, total}}) => {
      setTableList(data)
      setTotal(prev => ({...prev, [activeKey]: total}))
    })
    tradeModel.tradeWebStatistics(flow).then(({response: {data}}) => {
      setNumber(data)
    })
  }, [activeKey, params])
  const [visible, setVisible] = useState(false)
  const [modalData, setModalData] = useState({} as TradeVO)
  //查询
  function onFinish() {
    const formValues: FormData = form.getFieldsValue(true)
    let tradeDateStart = ''
    let tradeDateEnd = ''
    if (formValues?.time?.length > 0) {
      const result = formValues?.time.map(item => moment(item).format('YYYY-MM-DD HH:mm:ss'))
      tradeDateStart = result[0]
      tradeDateEnd = result[1]
    }
    setParams(state => ({
      ...state,
      pageNum: 1,
      tradeDateStart,
      tradeDateEnd,
      tradeSource: formValues.tradeSource || '',
      writeOff: formValues.writeOff || '',
      propertyTitle: formValues.propertyTitle || '',
      lesseeMobile: formValues.lesseeMobile || '',
      lesseeCertName: formValues.lesseeCertName || '',
      tradePayWayCode: formValues.tradePayWayCode || '',
      tradeCategory: formValues.tradeCategory || '',
      creatorName: formValues.creatorName || '',
      tradeTypeCode: formValues.tradeTypeCode || '',
    }))
  }
  //重置
  function onReset() {
    setParams({pageNum: 1, pageSize: 10})
    form.resetFields()
    setSelectedRows([])
  }

  //导出所有结果
  function exportAllResults() {
    tradeModel
      .tradeDataExcelPath({...params, ...tabMap[activeKey], pageable: false})
      .then(({response: {data}}) => downloadFile(data, '流水导出'))
  }

  //批量导出
  // function exportSelectResults() {
  //   if (!ids.length) return message.warning('请选择至少一条数据导出')
  //   tradeModel.tradeBatchDataExcelPath(ids).then(({response: {data}}) => downloadFile(data, '批量导出'))
  // }

  const columns: ColumnsType<TradeVO> = [
    {
      title: '进出账',
      dataIndex: 'tradeFlow',
      key: 'tradeFlow',
      width: 80,
      align: 'center',
      render: (text, record, index) => {
        return record?.tradeFlow === 'in' ? '进账' : '出账'
      },
    },
    {
      title: '房源/承租人',
      dataIndex: 'lesseeCertName',
      key: 'lesseeCertName',
      width: 260,
      align: 'center',
      render: (text, record, index) => {
        return [record?.propertyTitle, record?.lesseeCertName].filter(item => item).join('/')
      },
    },
    {
      title: '类型',
      dataIndex: 'tradeSource',
      key: 'tradeSource',
      width: 100,
      align: 'center',
      render: text => {
        return <Text>{tradeTypeCode[text]}</Text>
      },
    },
    {
      title: '项目',
      dataIndex: 'tradeTypeName',
      key: 'tradeTypeName',
      width: 140,
      align: 'center',
      render: (text, record, index) => {
        return (
          <div>
            <Text>{record?.tradeCategoryName}-</Text>
            <Text>{record?.tradeTypeName}</Text>
          </div>
        )
      },
    },
    {
      title: '金额',
      dataIndex: 'tradeAmt',
      key: 'tradeAmt',
      width: 100,
      align: 'center',
      render: text => toFixed(Math.abs(text)),
    },
    {
      title: '方式',
      dataIndex: 'tradePayWayName',
      key: 'tradePayWayName',
      width: 140,
      align: 'center',
    },
    {
      title: '收支日期',
      dataIndex: 'tradeDate',
      key: 'tradeDate',
      width: 140,
      align: 'center',
      render: (text, record, index) => {
        return moment(text).format('YYYY-MM-DD')
      },
    },
    {
      title: '交易人',
      dataIndex: 'creatorName',
      key: 'creatorName',
      width: 120,
      align: 'center',
      // render: text => {
      //   return <Tag color={'blue'}>{text}</Tag>
      // },
    },
    {
      title: '所属团队',
      dataIndex: 'teamName',
      key: 'teamName',
      width: 140,
      align: 'center',
    },
    {
      title: '状态',
      dataIndex: 'writeOff',
      key: 'writeOff',
      width: 80,
      align: 'center',
      render: (text, record, index) => tradeStatus(record),
    },
    {
      title: '编号',
      dataIndex: 'tradeId',
      key: 'tradeId',
      width: 200,
      align: 'center',
    },
    {
      title: '操作',
      dataIndex: 'opertion',
      key: 'opertion',
      fixed: 'right',
      width: 150,
      render: (text, record, index) => {
        return (
          <>
            <Permission code={'004002002'}>
              <Button
                type={'link'}
                style={{padding: '0 6px'}}
                onClick={() => {
                  setModalData(record)
                  setVisible(true)
                }}
                size={'small'}
              >
                详情
              </Button>
            </Permission>
            {record?.extraId ? (
              <Permission code={'004002003'}>
                <Divider type={'vertical'} style={{margin: 0}} />
                <Button
                  type={'link'}
                  style={{padding: '0 6px'}}
                  onClick={() => {
                    const location = {
                      pathname: './bill',
                      state: {billId: record?.extraId, tradeType: record.tradeSource === '1' ? 'lessee' : 'lessor'},
                    }
                    history.push(location)
                  }}
                  size={'small'}
                >
                  查看账务
                </Button>
              </Permission>
            ) : (
              ''
            )}
          </>
        )
      },
    },
  ]

  const tradeWriteOff = useCallback(async (tradeIds: string[]) => {
    await tradeModel.tradeWriteOff(tradeIds)
    setParams(prev => ({...prev}))
    setVisible(false)
    setSelectedRows(prevState => (prevState.length ? [] : prevState))
    message.success('核销成功')
  }, [])

  return (
    <div>
      <Breadcrumb
        path={['首页', '财务管理', '交易流水']}
        right={
          <Space>
            {auth('004002005') && (
              <Button
                type={'primary'}
                title={!selectedRowKeys.length ? '请选择需要核销的流水' : undefined}
                disabled={!selectedRowKeys.length}
                onClick={() => {
                  const total = selectedRows.reduce((prev, item) => add(prev, Math.abs(item.tradeAmt)), 0)
                  Modal.confirm({
                    icon: null,
                    title: '提示',
                    content: (
                      <Space className={styles.line} direction={'vertical'}>
                        <div>是否确认批量核销所选的流水？</div>
                        <div>
                          总计待核销流水<span className={styles.text}>{selectedRowKeys.length}</span>笔，合计
                          <span className={styles.text}>{toFixed(total)}</span>元
                        </div>
                      </Space>
                    ),
                    onOk: () => tradeWriteOff(selectedRowKeys),
                  })
                }}
              >
                批量核销
              </Button>
            )}
            {auth('004002004') && <Button onClick={exportAllResults}>导出所有结果</Button>}
          </Space>
        }
      />
      <Tabs
        tabBarStyle={{background: '#fff', paddingLeft: 20}}
        activeKey={activeKey}
        onChange={key => {
          setActiveKey(key)
          setParams(prev => ({...prev, pageNum: 1}))
        }}
      >
        <Tabs.TabPane tab={'全部'} key={'0'} />
        <Tabs.TabPane tab={'已核销'} key={'1'} />
        <Tabs.TabPane
          tab={
            <Badge count={total['2']} offset={[0, -8]}>
              <span>未核销</span>
            </Badge>
          }
          key={'2'}
        />
        <Tabs.TabPane tab={'已删除'} key={'3'} />
      </Tabs>
      <div className={styles.top}>
        <Form form={form} onFinish={onFinish} labelCol={{flex: '100px'}}>
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item label={'房源地址'} name={'propertyTitle'}>
                <Input placeholder={'小区/公寓名/房间号'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'承租人姓名'} name={'lesseeCertName'}>
                <Input placeholder={'请输入承租人姓名'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'承租人手机号'} name={'lesseeMobile'}>
                <Input placeholder={'请输入承租人手机号'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'交易日期'} name={'time'}>
                <RangePicker style={{width: '100%'}} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'交易方式'} name={'tradePayWayCode'}>
                <Select placeholder={'请选择'} style={{width: '100%'}} allowClear>
                  {tradeWays.map(item => {
                    return (
                      <Option value={item?.id} key={item?.id}>
                        {item?.label}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
            {/*<Col span={8}>*/}
            {/*  <Form.Item label={'交易状态'} name={'writeOff'}>*/}
            {/*    <Select placeholder={'请选择'} style={{width: '100%'}} allowClear>*/}
            {/*      <Option value={'0'}>未核销</Option>*/}
            {/*      <Option value={'1'}>已核销</Option>*/}
            {/*    </Select>*/}
            {/*  </Form.Item>*/}
            {/*</Col>*/}
            <Col span={8}>
              <Form.Item label={'交易人'} name={'creatorName'}>
                <Input placeholder={'请输入交易人姓名'} allowClear />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={'交易项目'}>
                <Row gutter={20}>
                  <Col span={8}>
                    <Form.Item name={'tradeSource'} noStyle>
                      <Select placeholder={'请选择'} style={{width: '100%'}}>
                        {tradeTypeArray.map(item => {
                          return (
                            <Option key={item.id} value={item.id}>
                              {item.label}
                            </Option>
                          )
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name={'tradeCategory'} noStyle>
                      <Select
                        placeholder={'请选择'}
                        style={{width: '100%'}}
                        onChange={(value: string) => {
                          form.setFieldsValue({tradeTypeCode: undefined})
                          const arr = commonModel.getCodeArraySync(value)
                          setBillTypeCode(arr)
                          // value ? setBillTypeCode(billType[value]) : setBillTypeCode([])
                        }}
                        allowClear
                      >
                        {tradeCategory.map(item => {
                          return (
                            <Option value={item?.id} key={item?.id}>
                              {item?.label}
                            </Option>
                          )
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name={'tradeTypeCode'} noStyle>
                      <Select placeholder={'请选择'} style={{width: '100%'}} allowClear>
                        {billTypeCode.map(item => {
                          return (
                            <Option value={item?.id} key={item?.id}>
                              {item?.label}
                            </Option>
                          )
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>

          <Row justify={'end'}>
            <Space>
              <Button htmlType={'reset'} onClick={onReset}>
                重置
              </Button>
              <Button type={'primary'} htmlType={'submit'}>
                查询
              </Button>
            </Space>
          </Row>
        </Form>
      </div>
      <div className={styles.table}>
        <Table
          rowSelection={{
            fixed: true,
            selectedRowKeys,
            onChange: (selectedRowKeys: React.Key[], selectedRows) => {
              setSelectedRows(selectedRows)
            },
            getCheckboxProps: record => ({
              disabled: record.writeOff === '1', // Column configuration not to be checked
              // name: 'record.tradeId',
            }),
          }}
          scroll={{x: 1700}}
          rowKey={'tradeId'}
          loading={loading}
          columns={columns}
          dataSource={tableList}
          title={() => {
            const isAll = ['0', '3'].includes(activeKey)
            return (
              <>
                <Text strong style={{marginRight: '12px', marginLeft: '-6px'}}>
                  查询结果
                </Text>
                <Text>
                  进出账合计
                  <Text strong style={{color: '#017BFF'}}>
                    {number?.amt}
                  </Text>
                  {isAll ? `元(${number?.amtCount}笔，待核销${number?.amtWriteOff}笔)` : `元(${number?.amtCount}笔)`}
                </Text>
                <Text style={{marginLeft: '20px'}}>
                  进账合计
                  <Text strong style={{color: '#017BFF'}}>
                    {number?.income}
                  </Text>
                  {isAll
                    ? `元(${number?.incomeCount}笔，待核销${number?.incomeWriteOff}笔)`
                    : `元(${number?.incomeCount}笔)`}
                </Text>
                <Text style={{marginLeft: '20px'}}>
                  出账合计
                  <Text strong style={{color: '#017BFF'}}>
                    {Math.abs(number?.payout)}
                  </Text>
                  {isAll
                    ? `元(${number?.payoutCount}笔，待核销${number?.payoutWriteOff}笔)`
                    : `元(${number?.payoutCount}笔)`}
                </Text>
              </>
            )
          }}
          footer={tableList => {
            const result = tableList.reduce((prev, cur) => {
              return prev + cur?.tradeAmt
            }, 0)
            const length = tableList?.length
            const numList = tableList.filter(item => {
              return item?.writeOff === '0'
            })
            const num = numList?.length
            return (
              <div style={{display: 'flex', alignItems: 'center'}}>
                <Text>本页合计</Text>
                <Statistic value={result} precision={2} valueStyle={{fontSize: '16px', color: '#F10000'}} />
                <Text>{['0', '3'].includes(activeKey) ? `元(${length}笔，待核销${num}笔)` : `元(${length}笔)`}</Text>
              </div>
            )
          }}
          pagination={{
            total: total[activeKey],
            defaultPageSize: 10,
            current: params.pageNum,
            position: ['bottomCenter'],
            onChange: page => {
              setParams(state => ({...state, pageNum: page}))
            },
            showSizeChanger: false,
          }}
        />
      </div>
      <Modal
        visible={visible}
        destroyOnClose
        title={<div style={{textAlign: 'center'}}>交易详情</div>}
        footer={null}
        onCancel={() => setVisible(false)}
        centered={true}
        afterClose={() => setTradeRemark('')}
      >
        <div className={styles.tradeDetail}>
          <div className={styles.topCard}>
            <Row justify={'space-between'}>
              <Col>
                <Statistic
                  valueStyle={{color: '#F10000', fontSize: '16px'}}
                  precision={2}
                  value={modalData?.tradeAmt}
                />
                <Text>{`${modalData?.tradeFlow === 'in' ? '进账' : '出账'}(元)`}</Text>
              </Col>
              <Col>
                <div>
                  <Space>
                    <Text>{moment(modalData?.tradeDate).format('YYYY-MM-DD')}</Text>
                    <Text>{modalData?.tradePayWayName}</Text>
                  </Space>
                </div>
                <Text>{[modalData?.propertyTitle, modalData?.lesseeCertName].filter(item => item).join('/')}</Text>
              </Col>
            </Row>
          </div>
          <div className={styles.content}>
            <Row gutter={[0, 8]}>
              <Col span={24}>
                <Space>
                  <Text>交易ID:</Text>
                  <Text>{modalData?.tradeId}</Text>
                </Space>
              </Col>
              <Col span={24}>
                <Space>
                  <Text>交易类型:</Text>
                  <Text>{modalData?.tradeSource === '1' ? '租客流水' : '房东流水'}</Text>
                </Space>
              </Col>
              <Col span={24}>
                <Space>
                  <Text>交易项目:</Text>
                  <Text>{`${modalData?.tradeCategoryName}-${modalData?.tradeTypeName}`}</Text>
                </Space>
              </Col>
              <Col span={24}>
                <Space>
                  <Text>交易人:</Text>
                  <Text>{modalData?.creatorName}</Text>
                </Space>
              </Col>
              <Col span={24}>
                <Space>
                  <Text>交易状态:</Text>
                  <Text>{tradeStatus(modalData)}</Text>
                </Space>
              </Col>
              <Col span={24}>
                <Space>
                  <Text>备注:</Text>
                  <Text>{modalData?.remark ?? '无'}</Text>
                </Space>
              </Col>
              <Col span={24}>
                <Space>
                  <Text>创建时间:</Text>
                  <Text>{modalData?.insertTime}</Text>
                </Space>
              </Col>
              {modalData.status === '1' ? (
                <Col span={24}>
                  <Space align={'start'}>
                    <Text>删除备注:</Text>
                    <Input.TextArea
                      maxLength={254}
                      value={tradeRemark}
                      placeholder={'请输入删除备注'}
                      onChange={event => setTradeRemark(event.target.value)}
                    />
                  </Space>
                </Col>
              ) : (
                <>
                  <Col span={24}>
                    <Space align={'start'}>
                      <Text style={{whiteSpace: 'nowrap'}}>删除备注:</Text>
                      <Text>{modalData.deletedRemark}</Text>
                    </Space>
                  </Col>
                  <Col span={24}>
                    <Space align={'start'}>
                      <Text>删除时间:</Text>
                      <Text>{modalData.deletedTime}</Text>
                    </Space>
                  </Col>
                </>
              )}
            </Row>
          </div>
          <Space style={{width: '100%', justifyContent: 'center'}}>
            {auth('004002006') && (
              <>
                {modalData.status === '1' && (
                  <Button
                    onClick={() => {
                      if (!tradeRemark) return message.error('请输入删除备注')

                      let code = ''
                      Modal.confirm({
                        icon: null,
                        title: '您正在删除流水，请输入支付密码',
                        content: <Input type={'password'} onChange={event => (code = event.target.value)} />,
                        onOk: async () => {
                          if (!code) return message.warn('请输入支付密码')
                          await tradeModel.tradeDelete({tradeId: modalData.tradeId, remark: tradeRemark, code})
                          setVisible(false)
                          setParams(prevState => ({...prevState}))
                        },
                      })
                    }}
                  >
                    删除流水
                  </Button>
                )}

                {modalData.status === '0' && (
                  <Button
                    onClick={() => {
                      let code = ''
                      Modal.confirm({
                        icon: null,
                        title: '您正在恢复流水，请输入支付密码',
                        content: <Input type={'password'} onChange={event => (code = event.target.value)} />,
                        onOk: async () => {
                          if (!code) return message.warn('请输入支付密码')

                          await tradeModel.tradeRecover({tradeId: modalData.tradeId, code})
                          setVisible(false)
                          setParams(prevState => ({...prevState}))
                        },
                      })
                    }}
                  >
                    恢复流水
                  </Button>
                )}
              </>
            )}

            {auth('004002005') && modalData?.writeOff === '0' && (
              <Button
                type={'primary'}
                loading={tradeWriteOffLoading}
                onClick={() => {
                  Modal.confirm({
                    icon: null,
                    title: '提示',
                    content: '是否确认核销该笔流水？',
                    onOk: () => tradeWriteOff([modalData.tradeId]),
                  })
                }}
              >
                核 销
              </Button>
            )}
          </Space>
        </div>
      </Modal>
    </div>
  )
}

function tradeStatus(vo: TradeVO) {
  return vo.status === '0' ? '已删除' : vo.writeOff === '1' ? '已核销' : '未核销'
}
