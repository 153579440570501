import React, {useState, useImperativeHandle, useEffect, useRef} from 'react'
import styles from './index.module.less'
import {Modal, Typography, Form, Statistic, Space, Row, Input, Button, message} from 'antd'
import dayjs from 'dayjs'
import {Album} from '@/ui/components/Album'
import {useCodeSync} from '@/store/models/Common'
import {baseUrl} from '@/common/config'
import {getImg} from '@/utils/util'
import {contractModel} from '@/store/models/Contract'
import Cancellation from '@/pages/contract/components/Cancellation'
import {Permission} from '@/ui/components/Permission'

const {TextArea} = Input
const {Text} = Typography

interface Props {
  onSuccess?: (billId?: string) => unknown
  billType?: 'lessee' | 'lessor'
}

function BillDetail({onSuccess, billType}: Props, ref) {
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const [billDetail, setBillDetail] = useState<ContractBill>()
  const [billId, setBillId] = useState('')
  const billStatus = useCodeSync('bill.status')
  const ownerStatusObj = {
    '1': '待付款',
    '2': '已付款',
    '3': '已作废',
  }
  const [imgList, setImgList] = useState([])
  const [dividedBillList, setDividedBillList] = useState([])
  const loading = contractModel.billUpdateRemark.useLoading()
  const cancellationRef = useRef(null)

  useEffect(() => {
    if (billId) {
      if (billType === 'lessor') {
        contractModel.contractLessorBillDetail(billId).then(({response: {data}}) => {
          const {imgList} = data
          setImgList(imgList)
        })
      } else {
        contractModel.billQuery(billId).then(({response: {data}}) => {
          const {imgList, billStatus, dividedBillList} = data
          setImgList(imgList)

          if (billStatus === '6') {
            setDividedBillList(dividedBillList)
          }
        })
      }
    }
  }, [billId, billType, form])

  useImperativeHandle(ref, () => ({
    onDisplay: () => {
      setVisible(true)
    },
    onSetBillDetail: (billDetail: ContractBill, billId?: string) => {
      setBillDetail(billDetail)
      form.setFieldsValue({remark: billDetail?.billRemark})
      if (billId) {
        setBillId(billId)
      } else {
        setImgList(billDetail?.imgList || [])
        setDividedBillList(billDetail?.dividedBillList || [])
      }
    },
    onHidden: () => {
      setVisible(false)
      setBillDetail({} as ContractBill)
      setBillId('')
      setImgList([])
      setDividedBillList([])
      form.resetFields()
    },
  }))

  function onFinish() {
    if (billType === 'lessor') {
      contractModel.contractLessorBillUpdateRemark({
        billId: billDetail?.billId,
        billRemark: form.getFieldValue('remark'),
        imgIdList: imgList.map(item => item.imgId),
      })
      onSuccess && onSuccess(billDetail?.billId)
      ref.current.onHidden()
    } else {
      contractModel
        .billUpdateRemark(
          billDetail?.billId,
          form.getFieldValue('remark'),
          imgList.map(item => item.imgId)
        )
        .then(() => {
          onSuccess && onSuccess(billDetail?.billId)
          ref.current.onHidden()
          message.success('账单详情更新成功')
        })
    }
  }

  return (
    <>
      <Modal
        title={
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '16px'}}>
            {`${billDetail?.billTypeName}:`}
            {billDetail?.billStatus === '1' ? (
              billDetail?.overdue ? (
                <>
                  {billType === 'lessor' ? '逾期待付款' : '逾期待收款'}
                  <Statistic
                    value={billDetail?.billAmtTotal}
                    precision={2}
                    suffix={'元'}
                    valueStyle={{fontSize: '16px'}}
                  />
                </>
              ) : (
                <>
                  {billType === 'lessor' ? '待付款' : '待收款'}
                  <Statistic
                    value={billDetail?.billAmtTotal}
                    precision={2}
                    suffix={'元'}
                    valueStyle={{fontSize: '16px'}}
                  />
                </>
              )
            ) : billType === 'lessor' ? (
              ownerStatusObj[billDetail?.billStatus]
            ) : (
              billStatus[billDetail?.billStatus]
            )}
          </div>
        }
        destroyOnClose
        visible={visible}
        onCancel={() => ref.current.onHidden()}
        centered={true}
        footer={null}
      >
        <Form size={'small'} form={form} onFinish={onFinish} className={styles.billDetail}>
          <Form.Item label={billType === 'lessor' ? '应付金额' : '应收金额'}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <Statistic
                value={billDetail?.billAmtTotal}
                precision={2}
                suffix={'元'}
                valueStyle={{fontSize: '16px', color: 'rgba(0,0,0,0.65)'}}
              />
              {!!billDetail?.billAmtOrigin && (
                <Typography.Text
                  style={{
                    fontSize: '12px',
                    color: 'rgba(0,0,0,0.25)',
                    textDecorationLine: 'line-through',
                    marginLeft: 5,
                  }}
                >{`￥${billDetail?.billAmtOrigin}元`}</Typography.Text>
              )}
            </div>
          </Form.Item>
          {!!billDetail?.billRemarkChange && <Form.Item label={'修改备注'}>{billDetail.billRemarkChange}</Form.Item>}
          <Form.Item label={billType === 'lessor' ? '应付款日' : '应收款日'}>
            <Text>{dayjs(billDetail?.billDate).format('YYYY-MM-DD')}</Text>
          </Form.Item>
          <Form.Item label={'起止日期'}>
            <Text>
              {billDetail?.billBegin && billDetail?.billEnd
                ? `${dayjs(billDetail?.billBegin).format('YYYY-MM-DD')}至${dayjs(billDetail?.billEnd).format(
                    'YYYY-MM-DD'
                  )}`
                : '无账期'}
            </Text>
          </Form.Item>
          {dividedBillList.length ? (
            <Form.Item label={'账单拆分'}>
              <Space direction={'vertical'}>
                {dividedBillList.map(item => (
                  <Space key={item.billId}>
                    <Text>{item.billTypeName}</Text>
                    <Text>{`￥ ${item.billAmtTotal.toFixed(2)}`}</Text>
                  </Space>
                ))}
              </Space>
            </Form.Item>
          ) : null}
          {billDetail?.billStatus === '1' ? (
            <>
              <Form.Item label={'图片凭证'}>
                <Album
                  fileList={imgList.map(item => ({
                    ...item,
                    uid: item.imgId,
                    url: getImg(billType === 'lessor' ? item.imgUrl : item.path),
                  }))}
                  action={baseUrl + (billType === 'lessor' ? '/contract-lessor/upload-img' : '/contract/upload-img')}
                  data={{imgType: '05'}}
                  onDone={fileList => {
                    setImgList(fileList)
                  }}
                />
              </Form.Item>
              <Form.Item label={'账单备注'} name={'remark'}>
                <TextArea autoSize={{minRows: 3, maxRows: 3}} style={{width: '290px', height: '95px'}} />
              </Form.Item>
              <Row justify={'center'}>
                <Space>
                  <Button type={'primary'} htmlType={'submit'} loading={loading}>
                    保存
                  </Button>
                  <Permission code={['003001008', '003001009']}>
                    <Button
                      onClick={() => {
                        cancellationRef.current.onDisplay(billDetail?.contractId, billDetail?.billId)
                        ref.current.onHidden()
                      }}
                    >
                      作废账单
                    </Button>
                  </Permission>
                </Space>
              </Row>
            </>
          ) : (
            <>
              <Form.Item label={'图片凭证'}>
                <Album
                  disabled
                  fileList={imgList.map(item => ({
                    ...item,
                    uid: item.imgId,
                    url: getImg(billType === 'lessor' ? item.imgUrl : item.path),
                  }))}
                />
              </Form.Item>
              <Form.Item label={'账单备注'}>
                <Text>{billDetail?.billRemark}</Text>
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
      <Cancellation ref={cancellationRef} onSuccess={billId => onSuccess(billId)} contractType={billType} />
    </>
  )
}

export default React.forwardRef(BillDetail)
