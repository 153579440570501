import React, {useCallback, useEffect, useState} from 'react'
import NygDeviceTimeForm from '@/pages/nygDevice/components/NygDeviceTimeForm'
import nygDeviceStyle from '@/pages/nygDevice/device.module.less'
import DeviceTableHeader from '@/pages/device/components/DeviceTableHeader'
import {Button, Descriptions, Drawer, Form, Table} from 'antd'
import DividerLine from '@/ui/components/DividerLine'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {deviceModel} from '@/store/models/Device'
import {downloadBlob} from '@/utils/util'

export default function NygDeviceWaterElectric({
  propertyCode,
  propertyTitle,
}: {
  propertyCode: string
  propertyTitle: string
}) {
  const [form] = Form.useForm()
  const [isCheck, setIsCheck] = useState(false)

  const [total, setTotal] = useState(0)
  const [recordList, setRecordList] = useState<DeviceV2WalletRecordVO[]>([])

  const queryMeterWalletRecordList = useCallback(() => {
    if (propertyCode) {
      const formValues = form.getFieldsValue(true)
      deviceModel.deviceV2MeterWalletRecordList({propertyCode, ...formValues}).then(({response: {data, total}}) => {
        setTotal(total)
        setRecordList(data)
      })
    }
  }, [form, propertyCode])

  useEffect(() => {
    queryMeterWalletRecordList()
  }, [queryMeterWalletRecordList])

  const [waterEleLoading, setWaterEleLoading] = useState(false)
  const [recordInfo, setRecordInfo] = useState({} as DeviceV2WalletRecordVO)

  const queryMeterWalletRecordInfo = useCallback((recordId: string) => {
    deviceModel.deviceV2MeterWalletRecordInfo({recordId}).then(({response: {data}}) => {
      setRecordInfo(data)
    })
  }, [])

  const recordLoading = deviceModel.deviceV2MeterWalletRecordList.useLoading()

  return (
    <>
      <NygDeviceTimeForm
        form={form}
        label={'额度变化时间'}
        onFinish={queryMeterWalletRecordList}
        onReset={queryMeterWalletRecordList}
        onExport={() => {
          const formValues = form.getFieldsValue(true)
          setWaterEleLoading(true)
          downloadBlob({
            url: '/device/v2/meter/wallet/record/list-excel',
            body: {...formValues, pageable: false, propertyCode},
          }).finally(() => {
            setWaterEleLoading(false)
          })
        }}
        exportLoading={waterEleLoading}
      />
      <div className={nygDeviceStyle.tableSection}>
        <DeviceTableHeader total={total} />
        <Table
          rowKey={'recordId'}
          columns={[
            {title: '类型', dataIndex: 'type', render: value => ({'1': '充值', '2': '扣费'}[value] ?? '/')},
            {title: '抄表时间', dataIndex: 'insertTime'},
            {title: '变化额度', dataIndex: 'balanceDiff'},
            {title: '变化后水电余额', dataIndex: 'balanceNew'},
            {
              title: '操作',
              dataIndex: 'recordId',
              render: recordId => {
                return (
                  <Button
                    type={'link'}
                    style={{padding: 0}}
                    onClick={() => {
                      queryMeterWalletRecordInfo(recordId)
                      setIsCheck(true)
                    }}
                  >
                    查看
                  </Button>
                )
              },
            },
          ]}
          dataSource={recordList}
          loading={recordLoading}
          pagination={getPaginationProps(form, total)}
        />
      </div>
      <Drawer title={'水电额度详情'} width={700} visible={isCheck} onClose={() => setIsCheck(false)}>
        <DividerLine title={'基本信息'} top={6} bottom={15} />
        {recordInfo.type === '1' ? (
          <Descriptions column={2}>
            <Descriptions.Item label={'详情ID'}>{recordInfo.recordId}</Descriptions.Item>
            <Descriptions.Item label={'房源地址'}>{propertyTitle}</Descriptions.Item>
            <Descriptions.Item label={'类型'}>充值</Descriptions.Item>
            <Descriptions.Item label={'充值类型'}>{recordInfo.chargeTypeDesc}</Descriptions.Item>
            <Descriptions.Item label={'充值平台'}>{recordInfo.chargePayWay}</Descriptions.Item>
            <Descriptions.Item label={'操作人员'}>{recordInfo.operator}</Descriptions.Item>
            <Descriptions.Item label={'备注'}>{recordInfo.remark}</Descriptions.Item>
            <Descriptions.Item label={'变化额度'}>{recordInfo.balanceDiff}</Descriptions.Item>
            <Descriptions.Item label={'变化后余额'}>{recordInfo.balanceNew}</Descriptions.Item>
          </Descriptions>
        ) : (
          <Descriptions column={2}>
            <Descriptions.Item label={'详情ID'}>{recordInfo.recordId}</Descriptions.Item>
            <Descriptions.Item label={'扣款方式'}>{recordInfo.chargeTypeDesc}</Descriptions.Item>
            <Descriptions.Item label={'类型'}>扣费</Descriptions.Item>
            <Descriptions.Item label={'抄表记录ID'}>{recordInfo.recordId}</Descriptions.Item>
            <Descriptions.Item label={'操作人员'}>{recordInfo.operator}</Descriptions.Item>
            <Descriptions.Item label={'变化后余额'}>{recordInfo.balanceNew}</Descriptions.Item>
            <Descriptions.Item label={'变化额度'}>{recordInfo.balanceDiff}</Descriptions.Item>
          </Descriptions>
        )}
      </Drawer>
    </>
  )
}
