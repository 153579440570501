import React, {useCallback, useEffect, useState} from 'react'
import {Button, DatePicker, Form, Input, InputNumber, message, Modal, Radio, Row, Select, Space} from 'antd'
import {warehouseModel} from '@/store/models/Warehouse'
import moment from 'moment'
import {Album} from '@/ui/components/Album'
import {baseUrl} from '@/common/config'
import {getImg} from '@/utils/util'
import {propertyModel} from '@/store/models/Property'

const {Option} = Select

export default function GoodsAddOperation(props: {
  // '1' 新增、查看  '2' 调拨
  operationType: '1' | '2'
  materialId?: string
  warehouseList: LessorWarehouseVO[]
  onCancel: () => void
  onFinish: () => void
}) {
  const {operationType, materialId, warehouseList, onCancel, onFinish} = props

  const [addGoodsForm] = Form.useForm()
  const [districtList, setDistrictList] = useState([])
  const [communityObj, setCommunityObj] = useState<{[key: string]: CommunityVO[]}>({})
  const [propertyList, setPropertyList] = useState([])

  const [receiptList, setReceiptList] = useState([])
  const [attachList, setAttachList] = useState([])

  // 仓库列表
  // const [warehouseList, setWarehouseList] = useState<LessorWarehouseVO[]>([])

  const goodsInsertLoading = warehouseModel.lessorWarehouseMaterialInsert.useLoading()
  const transLoading = warehouseModel.lessorWarehouseMaterialTrans.useLoading()

  // todo: networking
  useEffect(
    function () {
      if (operationType) {
        addGoodsForm.setFieldsValue({warehouseType: '2'})
      } else {
        setReceiptList([])
        setAttachList([])
      }
    },
    [addGoodsForm, operationType]
  )

  useEffect(function () {
    // 查询录入过的小区
    propertyModel.propertyCommunityList({}).then(({response: {data}}) => {
      // console.log('datadata', data)
      const obj = data.reduce((acc, item) => {
        if (item.districtCode in acc) {
          acc[item.districtCode] = [...acc[item.districtCode], item]
        } else acc[item.districtCode] = [item]
        return acc
      }, {})
      // console.log('obj', obj)
      setCommunityObj(obj)
      setDistrictList(
        Object.values(obj).map(item => ({districtName: item[0]?.districtName, districtCode: item[0]?.districtCode}))
      )
    })
  }, [])

  // useEffect(function () {
  //   warehouseModel.lessorWarehouseList().then(({response: {data}}) => {
  //     // console.log('data----', data)
  //     if (data) {
  //       setWarehouseList(data)
  //     }
  //   })
  // }, [])

  const handleSearch = useCallback((value: string, communityCode: string) => {
    // console.log('value', value)
    // console.log('communityCode', communityCode)
    if (value && communityCode) {
      propertyModel.propertyPropertyChartList({title: value, communityCode}).then(({response: {data}}) => {
        setPropertyList(data.map(item => ({label: item.title, value: item.propertyCode})))
      })
    } else setPropertyList([])
  }, [])

  // 回显
  useEffect(
    function () {
      if (operationType === '1' && materialId) {
        warehouseModel.lessorWarehouseMaterialInfo({materialId}).then(({response: {data}}) => {
          // console.log('info data', data)
          addGoodsForm.setFieldsValue({
            ...data,
            settingDate: moment(data.settingDate),
            warehouseType: data.warehouseName === '房源' ? '2' : '1',
          })
          setReceiptList(data.receiptList?.map(item => ({uid: item, imgUrl: item})))
          setAttachList(data.attachList?.map(item => ({uid: item, imgUrl: item})))

          if (data.warehouseName === '房源') {
            propertyModel.propertyDetail({propertyCode: data.warehouseId}).then(({response: {data: info}}) => {
              // console.log('propertyDetail info', info)
              addGoodsForm.setFieldsValue({
                communityCode: info.communityCode,
                districtCode: info.districtCode,
              })
            })
          }
        })
      }
    },
    [addGoodsForm, materialId, operationType]
  )

  // todo: render
  const renderLocFormItem = useCallback(function (form1) {
    return (
      <Form.Item name={'warehouseType'} label={'位置'} rules={[{required: true, message: '请选择位置'}]}>
        <Radio.Group onChange={() => form1.setFieldsValue({warehouseId: undefined})}>
          <Radio value={'2'}>房源</Radio>
          <Radio value={'1'}>仓库</Radio>
        </Radio.Group>
      </Form.Item>
    )
  }, [])

  const renderPropertyFormItem = form1 => {
    return (
      <>
        <Form.Item name={'districtCode'} label={'所在地址'} rules={[{required: true, message: '请选择所在区域'}]}>
          <Select
            placeholder={'请选择所在区域'}
            onChange={() => form1.setFieldsValue({communityCode: undefined, warehouseId: undefined})}
          >
            {districtList.map(item => (
              <Option key={item.districtCode} value={item.districtCode}>
                {item.districtName}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name={'communityCode'}
          label={' '}
          colon={false}
          rules={[{required: true, message: '请选择所在小区'}]}
          required={false}
        >
          <Select
            placeholder={'请选择所在小区'}
            onChange={() => {
              form1.setFieldsValue({warehouseId: undefined})
              setPropertyList([])
            }}
          >
            {communityObj[form1.getFieldValue('districtCode')]?.map(item => (
              <Option key={item.code} value={item.code}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {operationType === '1' && materialId ? (
          <Form.Item name={'warehouseAddress'} label={' '} colon={false}>
            <Input placeholder={'房源标题'} />
          </Form.Item>
        ) : (
          <Form.Item
            name={'warehouseId'}
            label={' '}
            colon={false}
            rules={[{required: true, message: '请输入房源标题搜索房间'}]}
            required={false}
          >
            <Select
              showSearch
              filterOption={false}
              placeholder={'请输入房源标题搜索房间'}
              options={propertyList}
              onSearch={value => handleSearch(value, form1.getFieldValue('communityCode'))}
              disabled={!form1.getFieldValue('districtCode') || !form1.getFieldValue('communityCode')}
            />
          </Form.Item>
        )}
      </>
    )
  }

  const renderStoreFormItem = useCallback(
    function (form1) {
      return (
        <>
          <Form.Item name={'warehouseId'} label={'仓库名称'}>
            <Select placeholder={'请选择'}>
              {warehouseList.map(item => (
                <Option key={item.warehouseId} value={item.warehouseId}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={'仓库地址'}>
            <Input
              disabled
              value={warehouseList.find(item => item.warehouseId === form1.getFieldValue('warehouseId'))?.address ?? ''}
              placeholder={'仓库地址'}
            />
          </Form.Item>
        </>
      )
    },
    [warehouseList]
  )

  return (
    <>
      {/* 新增物品 */}
      <Modal
        visible={operationType === '1'}
        centered
        title={'新增物品'}
        footer={null}
        onCancel={() => {
          addGoodsForm.resetFields()
          onCancel?.()
        }}
        destroyOnClose
      >
        <Form
          form={addGoodsForm}
          labelCol={{span: 4}}
          labelAlign={'left'}
          onFinish={async () => {
            const formValues = addGoodsForm.getFieldsValue(true)
            const isProperty = formValues.warehouseType === '2'
            let warehouseAddress, warehouseName
            if (isProperty) {
              warehouseAddress = propertyList.find(item => formValues.warehouseId === item.value)?.label
              warehouseName = '房源'
            } else {
              const item = warehouseList.find(item => item.warehouseId === formValues.warehouseId)
              warehouseAddress = item?.address
              warehouseName = item?.name
            }
            const inserValue = {
              attachList: attachList.map(item => item.imgUrl),
              receiptList: receiptList.map(item => item.imgUrl),
              warehouseAddress,
              warehouseName,
              ...formValues,
            }
            // console.log('inserValue', inserValue)
            await warehouseModel.lessorWarehouseMaterialInsert(inserValue)
            message.success('操作成功！')
            addGoodsForm.resetFields()
            onFinish?.()
          }}
          style={{pointerEvents: operationType === '1' && !!materialId ? 'none' : 'all'}}
        >
          <Form.Item name={'name'} label={'设备名称'} rules={[{required: true, message: '请输入设备名称'}]}>
            <Input placeholder={'请输入'} />
          </Form.Item>
          {/*显示用，不上传*/}
          <Form.Item name={'settingDate'} label={'配置日期'}>
            <DatePicker defaultValue={moment(new Date())} style={{width: '100%'}} disabled />
          </Form.Item>
          {renderLocFormItem(addGoodsForm)}
          <Form.Item noStyle shouldUpdate>
            {form1 =>
              form1.getFieldValue('warehouseType') === '2' ? renderPropertyFormItem(form1) : renderStoreFormItem(form1)
            }
          </Form.Item>
          <Form.Item name={'brand'} label={'品牌'}>
            <Input placeholder={'请输入'} />
          </Form.Item>
          <Form.Item name={'specification'} label={'型号'}>
            <Input placeholder={'请输入'} />
          </Form.Item>
          <Form.Item name={'price'} label={'单价'}>
            <Input placeholder={'请输入'} />
          </Form.Item>
          <Form.Item name={'num'} label={'数量'} rules={[{required: true, message: '请输入数量'}]}>
            <Input placeholder={'请输入'} />
          </Form.Item>
          <Form.Item label={'收据照片'}>
            <Album
              limit={9}
              showUploadList
              action={baseUrl + '/lessor/warehouse/upload'}
              fileList={receiptList.map(item => ({...item, uid: item.uid, url: getImg(item.imgUrl)}))}
              onDone={fileList => setReceiptList(fileList.map(item => ({uid: item.uid, imgUrl: item.imgUrl})))}
            />
          </Form.Item>
          <Form.Item label={'物品照片'}>
            <Album
              limit={9}
              showUploadList
              action={baseUrl + '/lessor/warehouse/upload'}
              fileList={attachList.map(item => ({...item, uid: item.uid, url: getImg(item.imgUrl)}))}
              onDone={fileList => setAttachList(fileList.map(item => ({uid: item.uid, imgUrl: item.imgUrl})))}
            />
          </Form.Item>
          <Form.Item name={'remark'} label={'备注'}>
            <Input.TextArea placeholder={'请输入'} />
          </Form.Item>
          {!materialId && (
            <Row justify={'end'}>
              <Space>
                <Button
                  onClick={() => {
                    addGoodsForm.resetFields()
                    onCancel?.()
                  }}
                >
                  取消
                </Button>
                <Button type={'primary'} htmlType={'submit'} loading={goodsInsertLoading}>
                  保存提交
                </Button>
              </Space>
            </Row>
          )}
        </Form>
      </Modal>

      {/* 调拨 */}
      <Modal
        visible={operationType === '2'}
        centered
        title={'调拨'}
        footer={null}
        onCancel={() => {
          addGoodsForm.resetFields()
          onCancel?.()
        }}
      >
        <Form
          form={addGoodsForm}
          labelCol={{span: 4}}
          labelAlign={'left'}
          onFinish={async () => {
            const formValues = addGoodsForm.getFieldsValue(true)
            const isProperty = formValues.warehouseType === '2'
            let warehouseAddress, warehouseName
            if (isProperty) {
              warehouseAddress = propertyList.find(item => formValues.warehouseId === item.value)?.label
              warehouseName = '房源'
            } else {
              const item = warehouseList.find(item => item.warehouseId === formValues.warehouseId)
              warehouseAddress = item?.address
              warehouseName = item?.name
            }
            const transValues = {
              ...formValues,
              materialId,
              warehouseAddress,
              warehouseName,
            }
            // console.log('transValues', transValues)
            await warehouseModel.lessorWarehouseMaterialTrans(transValues)
            message.success('操作成功！')
            addGoodsForm.resetFields()
            onFinish?.()
          }}
        >
          {renderLocFormItem(addGoodsForm)}
          <Form.Item name={'num'} label={'数量'}>
            <InputNumber placeholder={'请输入'} style={{width: '100%'}} />
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {form1 =>
              form1.getFieldValue('warehouseType') === '2' ? renderPropertyFormItem(form1) : renderStoreFormItem(form1)
            }
          </Form.Item>
          <Row justify={'end'}>
            <Space>
              <Button
                onClick={() => {
                  addGoodsForm.resetFields()
                  onCancel?.()
                }}
              >
                取消
              </Button>
              <Button type={'primary'} htmlType={'submit'} loading={transLoading}>
                保存提交
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>
    </>
  )
}
