import React, {useEffect, useImperativeHandle, useState} from 'react'
import {Col, Modal, Row, Table} from 'antd'
import {contractModel} from '@/store/models/Contract'

const initParams = {pageNum: 1, pageSize: 10}

function _ContractLogList(props: {contractId: string}, ref) {
  const contractId = props.contractId

  const [visible, setVisible] = useState(false)
  const [params, setParams] = useState(initParams)
  const [dataSource, setDataSource] = useState<ContractLogVO[]>([])

  const total = dataSource.length

  useEffect(() => {
    if (visible) {
      contractModel.contractLogList({contractId}).then(({response: {data}}) => {
        setDataSource(data)
      })
    }
  }, [contractId, visible])

  useImperativeHandle(ref, () => ({setVisible}), [])

  return (
    <Modal
      width={800}
      footer={null}
      visible={visible}
      title={'租约操作记录'}
      onCancel={() => setVisible(false)}
      afterClose={() => setParams(initParams)}
    >
      <Table
        title={() => (
          <Row justify={'space-between'}>
            <Col>租约编号 {contractId}</Col>
            <Col>共搜索到 {dataSource.length} 条数据</Col>
          </Row>
        )}
        scroll={{x: 'max-content'}}
        dataSource={dataSource}
        columns={[
          {title: '时间', align: 'center', width: 120, dataIndex: 'insertTime'},
          {title: '操作人', align: 'center', width: 80, dataIndex: 'operatorName'},
          {
            title: '事件',
            // align: 'center',
            dataIndex: 'extra',
            render: (_, record) => <pre>{record.extra}</pre>,
          },
        ]}
        pagination={{
          position: ['bottomCenter'],
          total,
          current: params.pageNum,
          pageSize: params.pageSize,
          showSizeChanger: true,
          onChange: (pageNum, pageSize) => setParams(prev => ({...prev, pageSize, pageNum})),
        }}
      />
    </Modal>
  )
}

export const ContractLogList = React.forwardRef(_ContractLogList)
