import React from 'react'
import {Button, DatePicker, Form, FormInstance, Row, Space} from 'antd'
import nygDeviceStyle from '@/pages/nygDevice/device.module.less'
import moment from 'moment'

export default function NygDeviceTimeForm(props: {
  form: FormInstance
  label: string
  onFinish: () => void
  onReset: () => void
  onExport?: () => void
  exportLoading?: boolean
}) {
  const {form, label, onFinish, onReset, onExport, exportLoading} = props
  return (
    <div className={nygDeviceStyle.formMargin}>
      <Form
        form={form}
        onFinish={onFinish}
        onReset={onReset}
        initialValues={{pageNum: 1, pageSize: 10, pageable: true}}
      >
        <Row>
          <Form.Item label={label} name={'_time'}>
            <DatePicker.RangePicker
              allowClear
              showTime={{defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')]}}
              onChange={(_, formatString) => {
                form.setFieldsValue({
                  begin: formatString?.[0],
                  end: formatString?.[1],
                })
              }}
            />
          </Form.Item>
        </Row>
        <Row justify={'end'}>
          <Space>
            {!!onExport && (
              <Button onClick={onExport} loading={exportLoading}>
                导出
              </Button>
            )}
            <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
              查询
            </Button>
            <Button htmlType={'reset'}>重置</Button>
          </Space>
        </Row>
      </Form>
    </div>
  )
}
