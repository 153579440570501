import React, {useCallback, useEffect, useState} from 'react'
import {Button, Col, DatePicker, Divider, Form, Input, Pagination, Row, Select, Space, Table, Typography} from 'antd'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import commonStyle from '@/assets/styles/common.module.less'
import {useCodeArraySync} from '@/store/models/Common'
import {contractModel} from '@/store/models/Contract'
import moment from 'moment'
import {downloadBlob} from '@/utils/util'
import {contract} from '@/utils/render'

export default function ContractAccounting() {
  const [form] = Form.useForm()
  const [dataSource, setDataSource] = useState<ContractAccountVO[]>([])
  const [total, setTotal] = useState(0)

  const operationType = useCodeArraySync('contract.account.operationType')

  const search = useCallback(async () => {
    const value: ContractAccountQueryDTO = form.getFieldsValue(true)
    const {
      response: {data, total},
    } = await contractModel.contractAccountList(value)
    setTotal(total)
    setDataSource(data)
  }, [form])

  useEffect(() => {
    search()
  }, [search])

  const loading = contractModel.contractAccountList.useLoading()
  const [exportLoading, setExportLoading] = useState(false)

  return (
    <>
      <Breadcrumb
        path={['首页', '租约管理', '租约账务']}
        right={
          <Button
            loading={exportLoading}
            onClick={() => {
              setExportLoading(true)
              downloadBlob({url: '/contract/account/list-excel', body: form.getFieldsValue(true)}).finally(() =>
                setExportLoading(false)
              )
            }}
          >
            导出
          </Button>
        }
      />
      <Form initialValues={{pageNum: 1, pageSize: 10}} form={form} onFinish={search} onReset={search}>
        <div className={commonStyle.section}>
          <Row gutter={30}>
            <Col span={12}>
              <Form.Item label={'房源标题'} name={'propertyTitle'}>
                <Input placeholder={'请输入房源标题'} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={'承租人'} name={'lesseeName'}>
                <Input placeholder={'请输入承租人'} />
              </Form.Item>
            </Col>
            <Col span={15}>
              <Form.Item label={'发生时间'}>
                <Row gutter={10} wrap={false}>
                  <Col flex={1}>
                    <Form.Item
                      noStyle
                      name={'recordTimeBegin'}
                      getValueProps={value => ({value: value ? moment(value) : value})}
                      getValueFromEvent={(_, format) => format}
                    >
                      <DatePicker
                        style={{width: '100%'}}
                        showTime={{defaultValue: moment('00:00:00', 'HH:mm:ss')}}
                        disabledDate={(date: moment.Moment) => {
                          const recordTimeEnd = form.getFieldValue('recordTimeEnd')
                          if (recordTimeEnd) {
                            return date.isAfter(recordTimeEnd, 'date')
                          }
                          return false
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col flex={'30px'} style={{display: 'flex', alignItems: 'center'}}>
                    <Divider style={{margin: 0}} />
                  </Col>
                  <Col flex={1}>
                    <Form.Item
                      noStyle
                      name={'recordTimeEnd'}
                      getValueProps={value => ({value: value ? moment(value) : value})}
                      getValueFromEvent={(_, format) => format}
                    >
                      <DatePicker
                        style={{width: '100%'}}
                        showTime={{defaultValue: moment('00:00:00', 'HH:mm:ss')}}
                        disabledDate={(date: moment.Moment) => {
                          const recordTimeBegin = form.getFieldValue('recordTimeBegin')
                          if (recordTimeBegin) {
                            return date.isBefore(recordTimeBegin, 'date')
                          }
                          return false
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item label={'操作类型'} name={'operationType'}>
                <Select
                  placeholder={'请选择'}
                  allowClear
                  options={operationType.map(value => ({label: value.label, value: value.id}))}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </div>
        <div className={commonStyle.section}>
          <Table
            title={() => (
              <Space size={21}>
                <Typography.Title level={3} style={{marginBottom: 0}}>
                  查询结果
                </Typography.Title>
                <Typography.Text>
                  共搜索到
                  <span style={{color: '#007BFF'}}>{total}</span>
                  条数据
                </Typography.Text>
              </Space>
            )}
            loading={loading}
            scroll={{x: 'max-content'}}
            dataSource={dataSource}
            rowKey={'recordId'}
            columns={[
              {title: '租约编号', dataIndex: 'contractId'},
              {title: '房源标题', dataIndex: 'propertyTitle'},
              {title: '承租人', dataIndex: 'lesseeName'},
              {title: '账单科目', dataIndex: 'billTypeName'},
              {title: '账单周期', render: (_, record) => `${record.billBegin ?? '-'}至${record.billEnd ?? '-'}`},
              {title: '收支', dataIndex: 'recordAmt', render: text => contract.recordAmtName(text)},
              {title: '金额（元）', dataIndex: 'recordAmt'},
              {title: '发生时间', dataIndex: 'recordTime'},
              {title: '支付通道', dataIndex: 'billPayWayName', render: text => text ?? '/'},
              {title: '操作类型', dataIndex: 'operationTypeName'},
              {title: '操作人', dataIndex: 'operatorName'},
            ]}
            pagination={false}
          />
          <Row justify={'center'} style={{marginTop: '24px'}}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) => prev.pageSize !== next.pageSize || prev.pageNum !== next.pageNum}
            >
              {f => (
                <Pagination
                  total={total}
                  current={f.getFieldValue('pageNum')}
                  pageSize={f.getFieldValue('pageSize')}
                  showSizeChanger
                  onChange={(pageNum, pageSize) => {
                    f.setFieldsValue({pageNum, pageSize})
                    search()
                  }}
                />
              )}
            </Form.Item>
          </Row>
        </div>
      </Form>
    </>
  )
}
