import React, {useEffect, useState} from 'react'
import {Tag} from 'antd'
import styles from './index.module.less'
import {commonModel} from '@/store/models/Common'

export interface CheckList extends Option {
  checked?: boolean
}

interface PropertyConfigListProps {
  rentType: RentType
  checkList?: CheckList[] | string
  onChecked?: (checkedList: CheckList[]) => any
}

export function PropertyConfigList(props: PropertyConfigListProps) {
  const [_checkList, setCheckList] = useState<CheckList[]>([])

  useEffect(
    function () {
      if (typeof props.checkList === 'string') {
        setCheckList(JSON.parse(props.checkList))
      } else if (Array.isArray(props.checkList)) {
        setCheckList(props.checkList)
      } else {
        setCheckList(
          commonModel.getCodeArraySync(
            {
              '1': 'pe.b',
              '2': 'pe.a',
              '3': 'pe.a',
            }[props.rentType]
          )
        )
      }
    },
    [props.checkList, props.rentType]
  )

  function onChange(id: string) {
    const newList = _checkList.map(item => (item.id === id ? {...item, checked: !item.checked} : item))
    typeof props.onChecked === 'function' && props.onChecked(newList)
    setCheckList(newList)
  }

  return (
    <div>
      {_checkList.map(item => (
        <Tag
          onClick={() => onChange(item.id)}
          key={item.id}
          color={item.checked ? 'blue' : ''}
          className={styles.formTag}
        >
          {item.label}
        </Tag>
      ))}
    </div>
  )
}
