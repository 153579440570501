import React, {useEffect} from 'react'
import {Button, Form, Input, message} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import {rules} from '@/utils/validate'
import {Model} from '@redux-model/web'
import {userModel} from '@/store/models/User'
import {useCountDown} from '@/common/hooks/countDown'
import styles from './index.module.less'
import {useHistory} from 'react-router'
import {Breadcrumb} from '@/ui/components/Breadcrumb'

export default function SettingPassword() {
  const history = useHistory()
  const [forgetForm] = Form.useForm()
  const {countDown, startCountDown} = useCountDown()
  const userInfo = userModel.useData(data => data.userInfo)
  const updateLoading = Model.isLoading(userModel.userUpdatePwd.useLoading())

  useEffect(function () {
    forgetForm.setFieldsValue({mobile: userInfo.mobile})
  }, [])

  const onUpdate = async values => {
    console.log('forget values =>', values)
    await userModel.userUpdatePwd({...values, source: 'lessor'})
    message.success('设置成功！')
    forgetForm.resetFields()
    history.push('/home')
  }

  async function verifySmsForUpdate() {
    await forgetForm.validateFields(['mobile'])
    await userModel.sendVerifySms({
      loginType: 'verifyCode',
      source: 'lessor',
      mobile: forgetForm.getFieldValue('mobile'),
    } as UserLoginDTO)
    startCountDown()
  }

  return (
    <>
      <Breadcrumb path={['首页', '修改登录密码']} />
      <div className={commonStyles.flexFullSection}>
        <div className={styles.update}>
          <Form form={forgetForm} labelCol={{span: 6}} onFinish={onUpdate} style={{width: '100%'}}>
            <Form.Item label={'手机号'} name='mobile' rules={rules.mobile}>
              <Input size={'large'} maxLength={11} autoFocus placeholder={'请输入账号'} disabled />
            </Form.Item>
            <Form.Item label={'验证码'} name='verifyCode' rules={rules.verifyCode}>
              <Input
                size={'large'}
                maxLength={6}
                placeholder={'请输入验证码'}
                suffix={
                  <Button type={'link'} size={'small'} onClick={() => countDown < 1 && verifySmsForUpdate()}>
                    {countDown > 0 ? `${countDown}s` : '获取验证码'}
                  </Button>
                }
              />
            </Form.Item>
            <Form.Item label={'设置新密码'} name='pwd' rules={[{required: true, message: '请输入密码'}]}>
              <Input.Password size={'large'} minLength={6} maxLength={18} placeholder={'请输入新密码'} />
            </Form.Item>
            <div className={styles.btnView}>
              <Button
                size={'large'}
                type='primary'
                htmlType='submit'
                loading={updateLoading}
                block
                className={styles.btn}
              >
                保存并提交
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  )
}
