export function combine(items: string[], split = '') {
  return items.filter(item => item).join(split)
}

export function format(temp, obj) {
  return temp.replace(/{(\w+)}/gi, (_, key) => obj[key] || '')
}

export const renderProperty = {
  detail(form: ScatterPropertyChartDetail) {
    return combine([form.rentPledgeType && `押${form.rentPledgeType}`, form.rentPayType && `付${form.rentPayType}`])
  },
  roomType(form: {bathroom: number; bedroom: number; parlor: number; kitchen}) {
    // roomType(form: Pick<PropertyWebPropertyCard, 'bathroom' | 'bedroom' | 'parlor'>) {
    return combine([
      form.bedroom && `${form.bedroom}室`,
      form.parlor && `${form.parlor}厅`,
      form.kitchen && `${form.kitchen}厨`,
      form.bathroom && `${form.bathroom}卫`,
    ])
  },
}

interface RenderSectionList<T, Title = any> {
  renderTitle: (item: T) => Title
  groupBy: (item: T) => any
  extProps?: (item: T) => object // Partial<T>
}

export type RenderSectionResult<T, Title = any> = {
  title: Title
  data: T[]
  [key: string]: any
}

export function renderSectionList<T, Title = any>(
  list: T[] = [],
  {renderTitle, groupBy, extProps}: RenderSectionList<T, Title>
): RenderSectionResult<T, Title>[] {
  if (!list || !list.length) return []
  const renderObj = list.reduce(
    (result, item) => {
      const groupKey = groupBy(item)
      if (!result.obj[groupKey]) {
        result.keys.push(groupKey)
        result.obj[groupKey] = {
          title: renderTitle(item),
          ...(typeof extProps === 'function' && extProps(item)),
          data: [],
        }
      }
      result.obj[groupKey].data.push(item)
      return result
    },
    {keys: [], obj: {}}
  )
  return renderObj.keys.map(key => renderObj.obj[key])
}

// export function getRenderSectionList<T extends ScatterPropertyChartList | CondoPropertyChartList>(
//   propertyList: T[] = []
// ): RenderList<T>[] {
//   const renderObj = propertyList.reduce((result, item: any) => {
//     if (!result[item.communityCode])
//       result[item.communityCode] = {
//         title: item.communityName,
//         count: item.propertyCount,
//         condoId: item.condoId,
//         districtName: item.districtName,
//         data: [],
//       }
//     result[item.communityCode].data.push(item)
//     return result
//   }, {} as RenderList<ScatterPropertyChartList>)
//   return Object.keys(renderObj).map(key => renderObj[key])
// }

export const houseNoProperty = {
  // 集中式 => X层+室号（模板名）
  condoHouseNo(params: {floor: number; houseNo: string; templateName: string}) {
    const {floor, houseNo, templateName} = params
    return [floor && `${floor}层`, houseNo && `${houseNo}室`, templateName && `(${templateName})`]
      .filter(item => item)
      .join('')
  },

  // 分散式整租 => 分散整租——X栋+X单元+室号
  entireHouseNo(params: {buildingNo: string; unitNo: string; houseNo: string}) {
    const {buildingNo, unitNo, houseNo} = params
    return [buildingNo && `${buildingNo}栋`, unitNo && `${unitNo}单元`, houseNo && `${houseNo}室`]
      .filter(item => item)
      .join('')
  },

  // 分散式合租 => X栋+X单元+室号+房间名（特殊命名，可没有）
  jointHouseNo(params: {buildingNo: string; unitNo: string; houseNo: string; roomName: string; roomCode: string}) {
    const {buildingNo, unitNo, houseNo, roomName, roomCode} = params
    return [
      buildingNo && `${buildingNo}栋`,
      unitNo && `${unitNo}单元`,
      houseNo && `${houseNo}`,
      roomCode && `-${roomCode}`,
      roomName && `(${roomName})`,
    ]
      .filter(item => item)
      .join('')
  },
}

export const renterRegister = {
  communityName(params: {
    districtName: string
    communityName: string
    buildingNo: string
    unitNo: string
    houseNo: string
  }) {
    const {districtName, communityName, buildingNo, unitNo, houseNo} = params
    return [
      districtName && `${districtName}-`,
      communityName && `${communityName}-`,
      buildingNo && `${buildingNo}栋`,
      unitNo && `${unitNo}单元`,
      houseNo && `${houseNo}号`,
    ]
      .filter(item => item)
      .join('')
  },
}

export const chartJoint = {
  apartName(params: {districtName: string; title: string}) {
    const {districtName, title} = params
    return [districtName, title].filter(item => item).join('-')
  },

  roomName(params: Pick<ScatterPropertyChartDetail, 'roomCode' | 'roomName'>) {
    const {roomCode, roomName} = params
    const tail = roomCode?.split('-').pop()
    return roomName || tail
  },
}

export const communityTitle = {
  // 集中：公寓名-X层+室号(houseNo)（模板名）
  condo(params: Pick<PropertyChartVO, 'houseNo' | 'templateName'>) {
    return [params.houseNo && `${params.houseNo}室`, params.templateName && `(${params.templateName})`]
      .filter(item => item)
      .join('')
  },
  // 1 分散 整租：小区名-X栋+X单元+室号
  scatterWhole(params: Pick<PropertyChartVO, 'unitNo' | 'houseNo'>) {
    return [params.unitNo && `${params.unitNo}单元`, params.houseNo && `${params.houseNo}室`]
      .filter(item => item)
      .join('-')
  },
  // 2 分散 合租：小区名-X栋+X单元+室号-room-A+房间名（有则展示，无为空）
  scatterJoin(params: Pick<PropertyChartVO, 'unitNo' | 'houseNo' | 'roomCode' | 'roomName'>) {
    return [
      params.unitNo && `${params.unitNo}单元`,
      params.houseNo && `${params.houseNo}室`,
      params.roomCode && params.roomCode,
      params.roomName && `(${params.roomName})`,
    ]
      .filter(item => item)
      .join('-')
  },

  renderWhole(params: Pick<PropertyChartVO, 'buildingNo' | 'unitNo' | 'houseNo'>) {
    return [
      params.buildingNo && `${params.buildingNo}栋`,
      params.unitNo && `${params.unitNo}单元`,
      params.houseNo && `${params.houseNo}`,
    ]
      .filter(item => item)
      .join('')
  },
}

export function renderChargeItem(list: LessorConfigListBillVO[], isInterval = false): OtherCost[] {
  list = list || []
  return list.map(item => ({
    category: item.billCategory,
    label: item.billTypeName,
    id: item.billTypeCode,
    interval: isInterval,
    billCategoryName: item.billCategoryName,
    name: item.billCategoryName,
  }))
}

// export function renderCharges(list: LessorConfigListBillVO[]) {
//   return {
//     周期费用: renderChargeItem(list, true),
//     首期一次性费用: renderChargeItem(list, false),
//   }
// }

export interface RenderList<T> {
  title: string
  condoId?: string
  count?: number
  districtName?: string
  data: T[]
}

// 租约、账务的
export const contract = {
  recordAmtName: (recordAmt: ContractAccountVO['recordAmt']) => (recordAmt > 0 ? '+' : '-'),
}

export const service = {
  // 服务单三级title
  serviceTitle: (item: Pick<StewardOrderQueryVO, 'categoryFirName' | 'categorySecName' | 'categoryThiName'>) =>
    [item.categoryFirName, item.categorySecName, item.categoryThiName].filter(value => value).join('-'),
}
