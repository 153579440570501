import {Model} from '@redux-model/web'
import {$api} from '@/store/service'

export interface WarehouseModel {}

class Warehouse extends Model<WarehouseModel> {
  protected initReducer(): WarehouseModel {
    return {}
  }

  // 房东仓库-新增仓库
  lessorWarehouseInsert = $api.action((args: Partial<LessorWarehouseDTO>) =>
    this.post('/lessor/warehouse/insert').body(args)
  )

  // 房东仓库-仓库列表
  lessorWarehouseList = $api.action(() => this.post<ApiResponse<LessorWarehouseVO[]>>('/lessor/warehouse/list'))

  // 房东仓库-删除物品  |  object:{materialId:库存Id}
  lessorWarehouseMaterialDelete = $api.action((args: {materialId}) =>
    this.post('/lessor/warehouse/material/delete').body(args)
  )

  // 房东仓库-报废物品  |  object:{materialId:库存Id}
  lessorWarehouseMaterialDestroy = $api.action((args: {materialId}) =>
    this.post('/lessor/warehouse/material/destroy').body(args)
  )

  // 房东仓库-物品详情  |  object:{materialId:库存Id}
  lessorWarehouseMaterialInfo = $api.action((args: {materialId}) =>
    this.post<ApiResponse<LessorWarehouseMaterialVO>>('/lessor/warehouse/material/info').body(args)
  )

  // 房东仓库-新增物品
  lessorWarehouseMaterialInsert = $api.action((args: Partial<LessorWarehouseMaterialDTO>) =>
    this.post('/lessor/warehouse/material/insert').body(args)
  )

  // 房东仓库-物品列表
  lessorWarehouseMaterialList = $api.action((args: Partial<LessorWarehouseMaterialListDTO>) =>
    this.post<ApiResponse<LessorWarehouseMaterialVO[]>>('/lessor/warehouse/material/list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // 房东仓库-调拨物品  |  object:{materialId:库存Id, num:数量, warehouseId:仓库id, warehouseType:仓库类型}
  lessorWarehouseMaterialTrans = $api.action((args: Partial<LessorWarehouseMaterialTransDTO>) =>
    this.post('/lessor/warehouse/material/trans').body(args)
  )
}

export const warehouseModel = new Warehouse()
