import React, {useState, useEffect} from 'react'
import {propertyModel} from '@/store/models/Property'
import {Cascader} from 'antd'
import {CascaderOptionType} from 'antd/lib/cascader'

interface ContractCascaderProps {
  onChangeValue?: (args: string[], dtos: [CommunityVO, PropertyChartVO]) => void
  placeholder?: string
}

interface OptionsProps extends CommunityVO {
  value: string
  label: string
  isLeaf: boolean
}

export default function ContractCascader({onChangeValue, placeholder}: ContractCascaderProps) {
  const [options, setOptions] = useState<OptionsProps[]>([])

  function onChange(value, args) {
    onChangeValue?.(value, args)
  }

  useEffect(() => {
    propertyModel.propertyCommunityList({}).then(({response: {data}}) => {
      setOptions(data.map(item => ({...item, value: item.code, label: item.name, isLeaf: false})))
    })
  }, [])

  function loadData(selectedOptions: CascaderOptionType[]) {
    const targetOption = selectedOptions[selectedOptions.length - 1]
    const {code} = targetOption
    targetOption.loading = true

    propertyModel
      .propertyPropertyChartList({
        communityCode: code,
        // contractExist: '0',
        pageable: false,
        // occupancyStatusList: ['1'],
        canMakeContract: true,
      })
      .then(({response: {data}}) => {
        targetOption.loading = false
        targetOption.children = data.map(_item => ({..._item, value: _item.propertyCode, label: _item.title}))
        setOptions([...options])
      })
  }

  function displayRender(label) {
    return label[label.length - 1]
  }

  return (
    <Cascader
      options={options}
      loadData={loadData}
      onChange={onChange}
      displayRender={displayRender}
      placeholder={placeholder ?? '请选择公寓/小区名'}
      style={{width: 440}}
    />
  )
}
