import {HttpService} from '@redux-model/web'
import {getModels} from '@/utils/globalServer'
import {baseUrl} from '@/common/config'
import {message} from 'antd'
import sha1 from 'crypto-js/sha1'
import hex from 'crypto-js/enc-hex'
import {aes} from '@/utils/aes'
import {HEADER_CHANNEL, HEADER_PLATFORM} from '@/common/constants'

/**
 * 传给后端 header 的基础字段
 */
export const createHeaders = () => ({
  'nyg8-auth': getModels()?.userModel.data?.token,
  'NYG8-TEAM-ID': getModels()?.userModel.data.currentTeam?.teamId,
  'nyg8-channel': HEADER_CHANNEL.LESSOR,
  'nyg8-platform': HEADER_PLATFORM.WEB,
})

const whiteList = ['13355556666', '13513887401']

let timer
export const $api = new HttpService({
  baseUrl,
  headers: () => ({
    ...createHeaders(),
    'Content-Type': 'application/json;charset=utf-8',
  }),
  ...(process.env.NODE_ENV === 'development'
    ? {}
    : {
        beforeSend: action => {
          if (whiteList.includes(getModels()?.userModel?.data?.userInfo?.mobile)) {
            return action
          }

          const body = JSON.stringify(action.body)
          const sign = hex.stringify(sha1(body))
          const timestamp = Date.now()
          const begin = Number(timestamp) % 9
          const key = hex.stringify(sha1(sign + timestamp)).substr(begin, 32)
          action.requestOptions.headers = {
            ...action.requestOptions.headers,
            'nyg8-sign': sign,
            'nyg8-timestamp': timestamp,
          }
          // @ts-ignore
          action.body = aes.encrypt(body, key)
        },
        transformSuccessData: (data, headers) => {
          const sign = headers['nyg8-sign']
          const timestamp = headers['nyg8-timestamp']
          const begin = Number(timestamp) % 9
          const key = hex.stringify(sha1(sign + timestamp)).substr(begin, 32)
          if (data?.code === 200 && sign && timestamp) {
            const body = aes.decrypt(data.data, key)
            try {
              data.data = JSON.parse(body)
            } catch {
              data.data = body
            }
          }
          return data
        },
      }),
  onRespondError(response, transform): void {
    if (response?.data.code !== 200) {
      transform.message = response.data.msg
      if (response.data.code === 401) {
        clearTimeout(timer)
        timer = setTimeout(() => {
          getModels()?.userModel.logout()
        }, 50)
      }
    }
  },
  onShowError(errorText, action: any): void {
    if (!action.hideError) {
      message.destroy()
      message.warn(errorText)
    }
  },
  onShowSuccess(): void {
    // console.log('onShowSuccess')
  },
  isSuccess(response) {
    if (['/user/logout'].includes(response.config.url)) return true
    return response?.data.code === 200
  },
})
