import {getModels} from '@/utils/globalServer'

/**
 * 是否是外部集中式公寓
 */
export function isProjectCondo() {
  const {userModel, propertyModel} = getModels()
  const owner = userModel?.data?.currentTeam?.owner
  return propertyModel?.data?.projects?.some(value => value.lessorId === owner)
}
