import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Breadcrumb} from '@/ui/components/Breadcrumb'
import {Button, Col, Divider, Form, Row, Select, Tabs, Typography, DatePicker, Space, Table, Radio} from 'antd'
import commonStyle from '@/assets/styles/common.module.less'
import styles from './index.module.less'
import {propertyModel} from '@/store/models/Property'
import {tradeModel} from '@/store/models/Trade'
import moment from 'moment'
import {contractModel} from '@/store/models/Contract'
import {useHistory} from 'react-router-dom'
import {downloadBlob} from '@/utils/util'
import {usePermission} from '@/ui/components/Permission'
import * as listener from '@/common/hooks/listener'

const {TabPane} = Tabs
const {Option} = Select
const {RangePicker} = DatePicker
const {Summary} = Table
interface DistrictProps {
  id: string
  label: string
  data: CommunityVO[]
}
export default function OperationAnalyse() {
  const [auth] = usePermission()
  const history = useHistory()
  //查看方式
  const queryTypeList = useMemo(
    () => [
      {id: 1, label: '按房源查看'},
      {id: 2, label: '按日期查看'},
    ],
    []
  )
  //所属类别
  const queryDimensionList = useMemo(
    () => [
      {id: 1, label: '交易类型'},
      {id: 2, label: '交易方式'},
    ],
    []
  )
  const [activeKey, setActiveKey] = useState('1')
  //流水选择小区
  const [tradeCommunityList, setTradeCommunityList] = useState<CommunityVO[]>([])
  //行政区
  const [districtList, setDistrictList] = useState<DistrictProps[]>([])
  const [tradeParams, setTradeParams] = useState({
    queryWay: 1,
    queryDimension: 1,
    // tradeDateEnd: moment().format('YYYY-MM-DD'),
    // tradeDateBegin: moment().subtract(30, 'days').format('YYYY-MM-DD'),
  } as TradeOperationListQueryDTO)
  const [tradeList, setTradeList] = useState<TradeOperationListVO[]>([])
  const [tradeTotal, setTradeTotal] = useState(0)
  const [tradeSum, setTradeSum] = useState({} as TradeOperationListVO)
  const [tradeForm] = Form.useForm()
  const [tradeColumns, setTradeColums] = useState([])
  const [operationActive, setOperationActive] = useState(1)
  const [queryDimensionActive, setQueryDimensionActive] = useState(1)
  const tradeLoading = tradeModel.tradeOperationList.useLoading()

  const [billForm] = Form.useForm()
  const [billLookType, setBillLookType] = useState(1)
  const [billParams, setBillParams] = useState({
    queryWay: 1,
    // billDateEnd: moment().format('YYYY-MM-DD'),
    // billDateBegin: moment().subtract(30, 'days').format('YYYY-MM-DD'),
  } as ContractBillOperationListQueryDTO)
  const [billCommunityList, setBillCommunityList] = useState<CommunityVO[]>([])
  const [billList, setBillList] = useState<ContractBillOperationListVO[]>([])
  const [billTotal, setBillTotal] = useState(0)
  const [billSum, setBillSum] = useState({} as ContractBillOperationListVO)
  const billLoading = contractModel.contractBillOperationList.useLoading()

  const queryColumns = useCallback(
    (queryWay: number, type: number) => {
      setTradeColums(
        [
          {
            title: queryWay === 1 ? '房源' : '日期',
            dataIndex: 'label',
            key: 'label',
          },
          {
            title: '进账总计',
            dataIndex: 'inTotal',
            key: 'inTotal',
          },
          {
            title: '出账总计',
            dataIndex: 'outTotal',
            key: 'outTotal',
          },
          {
            title: type === 1 ? '租客流水' : '牛油果平台',
            children: [
              {
                title: '进账',
                dataIndex: 'inType1',
                key: 'inType1',
              },
              {
                title: '出账',
                dataIndex: 'outType1',
                key: 'outType1',
              },
            ],
          },
          {
            title: type === 1 ? '业主流水' : '支付宝转账',
            children: [
              {
                title: '进账',
                dataIndex: 'inType2',
                key: 'inType2',
              },
              {
                title: '出账',
                dataIndex: 'outType2',
                key: 'outType2',
              },
            ],
          },
          {
            title: type === 1 ? '房源流水' : '微信转账',
            children: [
              {
                title: '进账',
                dataIndex: 'inType3',
                key: 'inType3',
              },
              {
                title: '出账',
                dataIndex: 'outType3',
                key: 'outType3',
              },
            ],
          },
          {
            title: type === 1 ? '其他流水' : '现金',
            children: [
              {
                title: '进账',
                dataIndex: 'inType4',
                key: 'inType4',
              },
              {
                title: '出账',
                dataIndex: 'outType4',
                key: 'outType4',
              },
            ],
          },
          {
            title: type === 1 ? '' : '银行转账',
            children: [
              {
                title: '进账',
                dataIndex: 'inType5',
                key: 'inType5',
              },
              {
                title: '出账',
                dataIndex: 'outType5',
                key: 'outType5',
              },
            ],
          },
          {
            title: type === 1 ? '' : '刷卡',
            children: [
              {
                title: '进账',
                dataIndex: 'inType6',
                key: 'inType6',
              },
              {
                title: '出账',
                dataIndex: 'outType6',
                key: 'outType6',
              },
            ],
          },
          {
            title: type === 1 ? '' : '其他方式',
            children: [
              {
                title: '进账',
                dataIndex: 'inType7',
                key: 'inType7',
              },
              {
                title: '出账',
                dataIndex: 'outType7',
                key: 'outType7',
              },
            ],
          },
          {
            title: '操作',
            fixed: 'right',
            dataInder: 'operation',
            key: 'operation',
            render: (text, record) => {
              if (!auth('004005002')) return null
              return (
                <Button
                  type={'link'}
                  onClick={() => {
                    history.push({
                      pathname: './trade',
                      state: {
                        type: operationActive,
                        label: operationActive === 1 ? record.code : record.label,
                      },
                    })
                    listener.emit('query-trade', operationActive, operationActive === 1 ? record.code : record.label)
                  }}
                >
                  查看
                </Button>
              )
            },
          },
        ].filter(item => item.title)
      )
    },
    [auth, history, operationActive]
  )

  useEffect(() => {
    queryColumns(operationActive, queryDimensionActive)
  }, [operationActive, queryColumns, queryDimensionActive])

  useEffect(() => {
    propertyModel.propertyCommunityList({}).then(({response: {data}}) => {
      if (data.length) {
        const keys = []
        const lists = data.reduce((result, item) => {
          if (keys.includes(item.districtCode)) {
            return result.map(_item =>
              _item.id === item.districtCode ? {..._item, data: [..._item.data, item]} : _item
            )
          } else {
            keys.push(item.districtCode)
            return [...result, {id: item.districtCode, label: item.districtName, data: [item]}]
          }
        }, [])
        setDistrictList(lists)
      }
    })
  }, [])

  //查询流水报表
  useEffect(() => {
    tradeModel.tradeOperationList(tradeParams).then(({response: {data}}) => setTradeList(data))
    tradeModel.tradeOperationCount(tradeParams).then(({response: {data}}) => setTradeTotal(data))
    tradeModel.tradeOperationSum(tradeParams).then(({response: {data}}) => setTradeSum(data))
  }, [tradeParams])

  //查询账单报表
  useEffect(() => {
    contractModel.contractBillOperationList(billParams).then(({response: {data}}) => setBillList(data))
    contractModel.contractBillOperationCount(billParams).then(({response: {data}}) => setBillTotal(data))
    contractModel.contractBillOperationSum(billParams).then(({response: {data}}) => setBillSum(data))
  }, [billParams])

  return (
    <>
      <Breadcrumb
        path={['首页', '财务管理', '营业报表']}
        right={
          auth('004005003') ? (
            <Button
              onClick={() => {
                if (activeKey === '1') {
                  downloadBlob({url: '/trade/operation/list-excel', body: tradeParams})
                } else {
                  downloadBlob({url: '/contract/bill/operation/list-excel', body: billParams})
                }
              }}
            >
              导出
            </Button>
          ) : null
        }
      />
      <Tabs className={styles.tabs} defaultActiveKey={'1'} onChange={key => setActiveKey(key)}>
        <TabPane key={1} tab={'流水报表'}>
          <div className={commonStyle.section}>
            <Form
              form={tradeForm}
              initialValues={{dateType: '1'}}
              onFinish={() => {
                const {tradeDate, districtCode, communityCode, writeOff, dateType} = tradeForm.getFieldsValue(true)
                const [tradeDateBegin, tradeDateEnd] = tradeDate?.length
                  ? tradeDate.map(item => moment(item).format('YYYY-MM-DD'))
                  : []
                if (operationActive === 1) {
                  setTradeParams(state => ({
                    ...state,
                    tradeDateBegin,
                    tradeDateEnd,
                    districtCode,
                    communityCode,
                    writeOff,
                    pageNum: 1,
                  }))
                } else {
                  if (dateType === '1') {
                    setTradeParams(state => ({...state, writeOff, pageNum: 1}))
                  } else {
                    setTradeParams(state => ({...state, tradeDateBegin, tradeDateEnd, pageNum: 1, writeOff}))
                  }
                }
              }}
            >
              <Form.Item label={'查看方式'} shouldUpdate style={{marginBottom: 0}}>
                {queryTypeList.map(item => (
                  <Button
                    key={item.id}
                    type={operationActive === item.id ? 'primary' : 'text'}
                    size={'small'}
                    style={{minWidth: '70px', margin: '0 10px'}}
                    onClick={() => {
                      setOperationActive(item.id)
                      tradeForm.resetFields()
                      if (item.id === 1) {
                        setTradeParams(state => ({
                          ...state,
                          queryWay: item.id,
                          pageNum: 1,
                          tradeDateBegin: '',
                          tradeDateEnd: '',
                          // tradeDateEnd: moment().format('YYYY-MM-DD'),
                          // tradeDateBegin: moment().subtract(30, 'days').format('YYYY-MM-DD'),
                        }))
                      } else {
                        setTradeParams(state => ({
                          ...state,
                          queryWay: item.id,
                          pageNum: 1,
                          tradeDateEnd: moment().format('YYYY-MM-DD'),
                          tradeDateBegin: moment().startOf('month').format('YYYY-MM-DD'),
                        }))
                      }
                    }}
                  >
                    {item.label}
                  </Button>
                ))}
              </Form.Item>
              <Divider dashed style={{margin: '16px 0'}} />
              <Form.Item label={'所属类别'} shouldUpdate style={{marginBottom: 0}}>
                {queryDimensionList.map(item => (
                  <Button
                    key={item.id}
                    type={queryDimensionActive === item.id ? 'primary' : 'text'}
                    size={'small'}
                    style={{minWidth: '70px', margin: '0 10px'}}
                    onClick={() => {
                      setQueryDimensionActive(item.id)
                      setTradeParams(state => ({...state, queryDimension: item.id}))
                    }}
                  >
                    {item.label}
                  </Button>
                ))}
              </Form.Item>
              <Divider dashed style={{margin: '16px 0'}} />
              <Row>
                {operationActive === 1 ? (
                  <>
                    <Col>
                      <Form.Item label={'房源地址'} name={'districtCode'} style={{marginBottom: 0}}>
                        <Select
                          style={{width: 100}}
                          placeholder={'行政区'}
                          onChange={(value: string) => {
                            setTradeCommunityList(districtList.find(item => item.id === value)?.data || [])
                            tradeForm.setFieldsValue({communityCode: undefined})
                          }}
                          allowClear
                        >
                          {districtList.map(item => (
                            <Option value={item.id} key={item.id}>
                              {item.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item style={{marginBottom: 0}} name={'communityCode'}>
                        <Select placeholder={'小区'} style={{width: 200, marginLeft: 10}} allowClear>
                          {tradeCommunityList.map(item => (
                            <Option value={item.code} key={item.code}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col offset={'1'}>
                      <Form.Item label={'实收款日'} name={'tradeDate'} style={{marginBottom: 0}}>
                        <RangePicker />
                      </Form.Item>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col>
                      <Form.Item label={'实收款日'} name={'dateType'}>
                        <Radio.Group
                          onChange={e => {
                            if (e.target.value === '2') {
                              const tradeDateBegin = moment().subtract(30, 'days')
                              const tradeDateEnd = moment()
                              tradeForm.setFieldsValue({tradeDate: [tradeDateBegin, tradeDateEnd]})
                            }
                          }}
                        >
                          <Radio value={'1'}>本月</Radio>
                          <Radio value={'2'}>历史</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Col style={{marginLeft: 10}}>
                      <Form.Item shouldUpdate style={{marginBottom: 0}}>
                        {form =>
                          form.getFieldValue('dateType') === '2' && (
                            <Form.Item name={'tradeDate'} style={{marginBottom: 0}}>
                              <RangePicker />
                            </Form.Item>
                          )
                        }
                      </Form.Item>
                    </Col>
                  </>
                )}

                <Col offset={1}>
                  <Form.Item label={'交易状态'} name={'writeOff'}>
                    <Select allowClear style={{width: 100}} placeholder={'全部'}>
                      <Option value={'0'}>未核销</Option>
                      <Option value={'1'}>已核销</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row justify={'end'}>
                <Space>
                  <Button type={'primary'} htmlType={'submit'}>
                    查询
                  </Button>
                  <Button
                    onClick={() => {
                      tradeForm.resetFields()
                      if (operationActive === 1) {
                        setTradeCommunityList([])
                        setTradeParams({
                          pageNum: 1,
                          queryWay: operationActive,
                          queryDimension: queryDimensionActive,
                          // tradeDateEnd: moment().format('YYYY-MM-DD'),
                          // tradeDateBegin: moment().subtract(30, 'days').format('YYYY-MM-DD'),
                        } as TradeOperationListQueryDTO)
                      } else {
                        setTradeParams({
                          pageNum: 1,
                          queryWay: operationActive,
                          queryDimension: queryDimensionActive,
                          tradeDateEnd: moment().format('YYYY-MM-DD'),
                          tradeDateBegin: moment().startOf('month').format('YYYY-MM-DD'),
                        } as TradeOperationListQueryDTO)
                      }
                    }}
                  >
                    重置
                  </Button>
                </Space>
              </Row>
            </Form>
          </div>
          <div className={commonStyle.section}>
            <Table
              rowKey={'code'}
              loading={tradeLoading}
              dataSource={tradeList}
              scroll={{x: 'max-content'}}
              columns={tradeColumns}
              pagination={{
                total: tradeTotal,
                defaultCurrent: 1,
                defaultPageSize: 10,
                current: tradeParams.pageNum,
                pageSize: tradeParams.pageSize,
                position: ['bottomCenter'],
                showSizeChanger: false,
                onChange: (pageNum, pageSize) => setTradeParams(state => ({...state, pageNum, pageSize})),
              }}
              summary={() => {
                return (
                  <Summary.Row>
                    <Summary.Cell index={0}>
                      <Typography.Text>总计</Typography.Text>
                    </Summary.Cell>
                    <Summary.Cell index={1}>
                      <Typography.Text>{tradeSum?.inTotal}</Typography.Text>
                    </Summary.Cell>
                    <Summary.Cell index={2}>
                      <Typography.Text>{tradeSum?.outTotal}</Typography.Text>
                    </Summary.Cell>
                    <Summary.Cell index={3}>
                      <Typography.Text>{tradeSum?.inType1}</Typography.Text>
                    </Summary.Cell>
                    <Summary.Cell index={4}>
                      <Typography.Text>{tradeSum?.outType1}</Typography.Text>
                    </Summary.Cell>
                    <Summary.Cell index={5}>
                      <Typography.Text>{tradeSum?.inType2}</Typography.Text>
                    </Summary.Cell>
                    <Summary.Cell index={6}>
                      <Typography.Text>{tradeSum?.outType2}</Typography.Text>
                    </Summary.Cell>
                    <Summary.Cell index={7}>
                      <Typography.Text>{tradeSum?.inType3}</Typography.Text>
                    </Summary.Cell>
                    <Summary.Cell index={8}>
                      <Typography.Text>{tradeSum?.outType3}</Typography.Text>
                    </Summary.Cell>
                    <Summary.Cell index={9}>
                      <Typography.Text>{tradeSum?.inType4}</Typography.Text>
                    </Summary.Cell>
                    <Summary.Cell index={10}>
                      <Typography.Text>{tradeSum?.outType4}</Typography.Text>
                    </Summary.Cell>
                    {tradeParams.queryDimension === 2 && (
                      <>
                        <Summary.Cell index={11}>
                          <Typography.Text>{tradeSum?.inType5}</Typography.Text>
                        </Summary.Cell>
                        <Summary.Cell index={12}>
                          <Typography.Text>{tradeSum?.outType5}</Typography.Text>
                        </Summary.Cell>
                        <Summary.Cell index={13}>
                          <Typography.Text>{tradeSum?.inType6}</Typography.Text>
                        </Summary.Cell>
                        <Summary.Cell index={14}>
                          <Typography.Text>{tradeSum?.outType6}</Typography.Text>
                        </Summary.Cell>
                        <Summary.Cell index={15}>
                          <Typography.Text>{tradeSum?.outType7}</Typography.Text>
                        </Summary.Cell>
                        <Summary.Cell index={16}>
                          <Typography.Text>{tradeSum?.outType7}</Typography.Text>
                        </Summary.Cell>
                      </>
                    )}
                  </Summary.Row>
                )
              }}
            />
          </div>
        </TabPane>
        <TabPane key={2} tab={'账单报表'}>
          <div className={commonStyle.section}>
            <Form
              form={billForm}
              initialValues={{dateType: '1'}}
              onFinish={() => {
                const {billDate, dateType, districtCode, communityCode} = billForm.getFieldsValue(true)
                const [billDateBegin, billDateEnd] = billDate?.length
                  ? billDate.map(item => moment(item).format('YYYY-MM-DD'))
                  : []
                if (billLookType === 1) {
                  setBillParams(state => ({
                    ...state,
                    districtCode,
                    communityCode,
                    billDateBegin,
                    billDateEnd,
                    pageNum: 1,
                  }))
                } else {
                  if (dateType === '2') {
                    setBillParams(state => ({...state, billDateBegin, billDateEnd, pageNum: 1}))
                  }
                }
              }}
            >
              <Form.Item label={'查看方式'} shouldUpdate style={{marginBottom: 0}}>
                {queryTypeList.map(item => (
                  <Button
                    key={item.id}
                    type={billLookType === item.id ? 'primary' : 'text'}
                    size={'small'}
                    style={{minWidth: '70px', margin: '0 10px'}}
                    onClick={() => {
                      setBillLookType(item.id)
                      billForm.resetFields()
                      if (item.id === 1) {
                        setBillParams(state => ({
                          ...state,
                          queryWay: item.id,
                          pageNum: 1,
                          billDateEnd: '',
                          billDateBegin: '',
                        }))
                      } else {
                        setBillParams(state => ({
                          ...state,
                          queryWay: item.id,
                          pageNum: 1,
                          billDateBegin: moment().startOf('month').format('YYYY-MM-DD'),
                          billDateEnd: moment().format('YYYY-MM-DD'),
                        }))
                      }
                    }}
                  >
                    {item.label}
                  </Button>
                ))}
              </Form.Item>
              <Divider dashed style={{margin: '16px 0'}} />
              <Row>
                {billLookType === 1 ? (
                  <>
                    <Col>
                      <Form.Item label={'房源地址'} name={'districtCode'} style={{marginBottom: 0}}>
                        <Select
                          allowClear
                          style={{width: 100}}
                          placeholder={'行政区'}
                          onChange={(value: string) => {
                            setBillCommunityList(districtList.find(item => item.id === value).data)
                            billForm.setFieldsValue({communityCode: undefined})
                          }}
                        >
                          {districtList.map(item => (
                            <Option value={item.id} key={item.id}>
                              {item.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col style={{marginLeft: 10}}>
                      <Form.Item style={{marginBottom: 0}} name={'communityCode'}>
                        <Select allowClear placeholder={'小区'} style={{width: 200}}>
                          {billCommunityList.map(item => (
                            <Option value={item.code} key={item.code}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col offset={1}>
                      <Form.Item label={'实收款日'} name={'billDate'}>
                        <RangePicker />
                      </Form.Item>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col>
                      <Form.Item label={'实收款日'} name={'dateType'}>
                        <Radio.Group
                          onChange={e => {
                            if (e.target.value === '2') {
                              const billDateBegin = moment().subtract(30, 'days')
                              const billDateEnd = moment()
                              billForm.setFieldsValue({billDate: [billDateBegin, billDateEnd]})
                            } else {
                              const billDateBegin = moment().startOf('month').format('YYYY-MM-DD')
                              const billDateEnd = moment().format('YYYY-MM-DD')
                              setBillParams(state => ({...state, billDateBegin, billDateEnd}))
                            }
                          }}
                        >
                          <Radio value={'1'}>本月</Radio>
                          <Radio value={'2'}>历史</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Col style={{marginLeft: 10}}>
                      <Form.Item shouldUpdate style={{marginBottom: 0}}>
                        {form =>
                          form.getFieldValue('dateType') === '2' ? (
                            <Form.Item name={'billDate'}>
                              <RangePicker />
                            </Form.Item>
                          ) : null
                        }
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Row>

              <Row justify={'end'}>
                <Space>
                  <Button type={'primary'} htmlType={'submit'}>
                    查询
                  </Button>
                  <Button
                    onClick={() => {
                      billForm.resetFields()
                      if (billLookType === 1) {
                        setBillCommunityList([])
                        setBillParams({
                          pageNum: 1,
                          pageSize: 10,
                          queryWay: billLookType,
                          // billDateEnd: moment().format('YYYY-MM-DD'),
                          // billDateBegin: moment().subtract(30, 'days').format('YYYY-MM-DD'),
                        } as ContractBillOperationListQueryDTO)
                      } else {
                        setBillParams({
                          pageNum: 1,
                          pageSize: 10,
                          queryWay: billLookType,
                          billDateBegin: moment().startOf('month').format('YYYY-MM-DD'),
                          billDateEnd: moment().format('YYYY-MM-DD'),
                        } as ContractBillOperationListQueryDTO)
                      }
                    }}
                  >
                    重置
                  </Button>
                </Space>
              </Row>
            </Form>
          </div>
          <div className={commonStyle.section}>
            <Table
              rowKey={'code'}
              loading={billLoading}
              dataSource={billList}
              pagination={{
                total: billTotal,
                defaultCurrent: 1,
                defaultPageSize: 10,
                current: billParams.pageNum,
                pageSize: billParams.pageSize,
                position: ['bottomCenter'],
                showSizeChanger: false,
                onChange: (pageNum, pageSize) => setBillParams(state => ({...state, pageNum, pageSize})),
              }}
              scroll={{x: 'max-content'}}
              columns={[
                {title: billLookType === 1 ? '房源' : '日期', dataIndex: 'label', key: 'label'},
                {title: '账单总额', dataIndex: 'billTotal', key: 'billTotal'},
                {title: '待收总额', dataIndex: 'unpaidTotal', key: 'unpaidTotal'},
                {
                  title: '租客账单',
                  children: [
                    {
                      title: '账单额',
                      dataIndex: 'billType1',
                      key: 'billType1',
                    },
                    {
                      title: '待收额',
                      dataIndex: 'unpaidType1',
                      key: 'unpaidType1',
                    },
                  ],
                },
                {
                  title: '押金账单',
                  children: [
                    {
                      title: '账单额',
                      dataIndex: 'billType2',
                      key: 'billType2',
                    },
                    {
                      title: '待收额',
                      dataIndex: 'unpaidType2',
                      key: 'unpaidType2',
                    },
                  ],
                },
                {
                  title: '水电煤暖网账单',
                  children: [
                    {
                      title: '账单额',
                      dataIndex: 'billType3',
                      key: 'billType3',
                    },
                    {
                      title: '待收额',
                      dataIndex: 'unpaidType3',
                      key: 'unpaidType3',
                    },
                  ],
                },
                {
                  title: '服务账单',
                  children: [
                    {
                      title: '账单额',
                      dataIndex: 'billType4',
                      key: 'billType4',
                    },
                    {
                      title: '待收额',
                      dataIndex: 'unpaidType4',
                      key: 'unpaidType4',
                    },
                  ],
                },
                {
                  title: '其他账单',
                  children: [
                    {
                      title: '账单额',
                      dataIndex: 'billType5',
                      key: 'billType5',
                    },
                    {
                      title: '待收额',
                      dataIndex: 'unpaidType5',
                      key: 'unpaidType5',
                    },
                  ],
                },
                {
                  title: '操作',
                  fixed: 'right',
                  dataIndex: 'operation',
                  key: 'operation',
                  render: (text, record) => {
                    if (!auth('004005002')) return null
                    return (
                      <Button
                        type={'link'}
                        onClick={() => {
                          history.push({
                            pathname: './bill',
                            state: {type: billLookType, label: billLookType === 1 ? record.code : record.label},
                          })
                        }}
                      >
                        查看
                      </Button>
                    )
                  },
                },
              ]}
              summary={() => {
                return (
                  <Summary.Row>
                    <Summary.Cell index={0}>
                      <Typography.Text>总计</Typography.Text>
                    </Summary.Cell>
                    <Summary.Cell index={1}>
                      <Typography.Text>{billSum?.billTotal}</Typography.Text>
                    </Summary.Cell>
                    <Summary.Cell index={2}>
                      <Typography.Text>{billSum?.unpaidTotal}</Typography.Text>
                    </Summary.Cell>
                    <Summary.Cell index={3}>
                      <Typography.Text>{billSum?.billType1}</Typography.Text>
                    </Summary.Cell>
                    <Summary.Cell index={4}>
                      <Typography.Text>{billSum?.unpaidType1}</Typography.Text>
                    </Summary.Cell>
                    <Summary.Cell index={5}>
                      <Typography.Text>{billSum?.billType2}</Typography.Text>
                    </Summary.Cell>
                    <Summary.Cell index={6}>
                      <Typography.Text>{billSum?.unpaidType2}</Typography.Text>
                    </Summary.Cell>
                    <Summary.Cell index={7}>
                      <Typography.Text>{billSum?.billType3}</Typography.Text>
                    </Summary.Cell>
                    <Summary.Cell index={8}>
                      <Typography.Text>{billSum?.unpaidType3}</Typography.Text>
                    </Summary.Cell>
                    <Summary.Cell index={9}>
                      <Typography.Text>{billSum?.billType4}</Typography.Text>
                    </Summary.Cell>
                    <Summary.Cell index={10}>
                      <Typography.Text>{billSum?.unpaidType4}</Typography.Text>
                    </Summary.Cell>
                    <Summary.Cell index={11}>
                      <Typography.Text>{billSum?.billType5}</Typography.Text>
                    </Summary.Cell>
                    <Summary.Cell index={12}>
                      <Typography.Text>{billSum?.unpaidType5}</Typography.Text>
                    </Summary.Cell>
                  </Summary.Row>
                )
              }}
            />
          </div>
        </TabPane>
      </Tabs>
    </>
  )
}
