import React from 'react'
import {Col, Row, Breadcrumb as AntBreadcrumb} from 'antd'
import styles from './index.module.less'

export interface BreadcrumbProps {
  right?: React.ReactNode
  path?: string[]
}

export function Breadcrumb(props: BreadcrumbProps) {
  return (
    <Row justify={'space-between'} align={'middle'} className={styles.container}>
      <Col>
        <AntBreadcrumb>
          {props.path?.map(item => (
            <AntBreadcrumb.Item key={item}>{item}</AntBreadcrumb.Item>
          ))}
        </AntBreadcrumb>
      </Col>
      {props.right && <Col>{props.right}</Col>}
    </Row>
  )
}
