import {setModels} from '@/utils/globalServer'

import {userModel, UserModel} from './User'
import {demoModel} from './Demo'
import {propertyModel, PropertyModel} from './Property'
import {commonModel, CommonModel} from './Common'
import {contractModel, ContractModel} from './Contract'
import {tempModel, TempModel} from './Temp'
import {messageModel, MessageModel} from './Message'
import {configModel, ConfigModel} from './Config'

export interface RootModel {
  // User 为 Class 的名字
  User: UserModel
  Property: PropertyModel
  Common: CommonModel
  Contract: ContractModel
  Temp: TempModel
  Message: MessageModel
  Config: ConfigModel
}

const models = {
  demoModel,
  userModel,
  propertyModel,
  commonModel,
  contractModel,
  tempModel,
  messageModel,
  configModel,
}
setModels(models)

export default models
