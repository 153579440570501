import {Model} from '@redux-model/web'
import {$api} from '@/store/service'

export interface ContractModel {
  count: number
}

class Contract extends Model<ContractModel> {
  protected initReducer(): ContractModel {
    return {
      count: 1,
    }
  }

  // 租约-计算租约计划
  contractCalculate = $api.action(args => this.post<ApiResponse<BillList[]>>('/contract/calculate').body(args))

  // 租约-计算租约计划（按组）
  contractCalculateGroup = $api.action((args: ContractCalculateDTO) =>
    this.post<ApiResponse<ContractCalculateGroupVO[]>>('/contract/calculate-group').body(args)
  )

  // 租约-创建（租客登记）
  generateContract = $api.action((args: Partial<GenerateContractParams>) =>
    this.post<ApiResponse<string>>('/contract/generate-contract').body(args)
  )

  // 租约-租约续约
  generateContractRenewal = $api.action((args: ContractGenerateDTO) =>
    this.post<ApiResponse<string>>('/contract/generate-contract-renewal').body(args)
  )

  // 租约列表相关信息(总数、快到期、电子合同、纸质合同)
  queryContractCount = $api.action(() => this.post<ApiResponse<ContractListCount>>('/contract/query-contract-count'))

  // 租约列表
  queryContractList = $api.action((args: Partial<ContractListQueryDTO>) =>
    this.post<ApiResponse<ContractVO[]>>('/contract/query-contract-list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // // 租约详情
  // contractQueryContract = $api.action((contractId: string) =>
  //   this.post<ApiResponse<ContractVO>>('/contract/query-contract').body({contractId})
  // )

  // 租约-详情[租约,子租约,承租人,退房结账,退房结账明细,租约计算明细]  |  object:{contractId:租约id}
  contractQueryContract = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<ContractVO>>('/contract/query-contract').body(args)
  )

  // 租约作废
  contractCancel = $api.action((contractId: string, code: string) =>
    this.post<ApiResponse>('/contract/cancel').body({
      contractId,
      code,
    })
  )

  // 租约退租（不结账）
  contractCheckoutThrowALease = $api.action((args: ContractThrowDTO) =>
    this.post<ApiResponse>('/contract/check-out/throw-a-lease').body({
      ...args,
    })
  )

  // 租约结账
  contractCheckout = $api.action((args: Partial<ContractCheckOutDTO>) =>
    this.post<ApiResponse>('/contract/check-out').body({
      ...args,
    })
  )

  // 租约-结账明细查询  |  object:{contractId:租约id}
  contractCheckOutDetail = $api.action((args: ContractCheckOutDetailQueryDTO) =>
    this.post<ApiResponse<ContractCheckOutDetailVO>>('/contract/check-out/detail').body(args)
  )

  // 租约账单列表
  billQueryByContractId = $api.action((contractId: string) =>
    this.post<ApiResponse<ContractBillListVO[]>>('/contract/bill/query-by-contract-id').body({
      contractId,
      needBillDetail: true,
    })
  )

  // 更新子租约图片
  contractUpdateImg = $api.action((contractDetailId: string, imgIdList: string[]) =>
    this.post<ApiResponse>('/contract/detail/update-img').body({
      contractDetailId,
      imgIdList,
    })
  )

  //账单列表
  billQueryList = $api.action((args: ContractBillListQueryDTO) =>
    this.post<ApiResponse<ContractBillListVO[]>>('/contract/bill/query-list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  //账单列表数据统计
  contractBillQueryListCount = $api.action((args: Partial<ContractBillListQueryDTO>) =>
    this.post<ApiResponse<ContractBillListCountVO>>('/contract/bill/query-list-count').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // 账单详情(包含备注、图片、账单明细)
  billQuery = $api.action((billId: string) =>
    this.post<ApiResponse<ContractBill>>('/contract/bill/query').body({billId})
  )

  // 账单更新备注
  billUpdateRemark = $api.action((billId: string, billRemark?: string, imgIdList?: string[]) =>
    this.post<ApiResponse>('/contract/bill/update-remark').body({
      billId,
      billRemark,
      imgIdList,
    })
  )

  // 账单确认收款
  billConfirm = $api.action((args: ContractBillConfirmDTO) =>
    this.post<ApiResponse>('/contract/bill/confirm').body({
      ...args,
    })
  )

  // 账单作废
  billCancel = $api.action((billId: string, code: string) =>
    this.post<ApiResponse>('/contract/bill/cancel').body({
      billId,
      code,
    })
  )

  // 账单明细作废
  billDetailCancel = $api.action((billDetailId: string, code: string) =>
    this.post<ApiResponse>('/contract/bill/detail/cancel').body({
      billDetailId,
      code,
    })
  )

  // 租约、账单作废发送作废短信
  contractCancelSms = $api.action((contractId: string) =>
    this.post<ApiResponse>('/contract/cancel-sms').body({
      contractId,
    })
  )

  // 账单明细详情
  // billDetailQuery = $api.action((billDetailId: string) =>
  //   this.post<ApiResponse<BillDetailItem>>('/contract/bill/detail/query').body({
  //     billDetailId,
  //   })
  // )

  // 账单-明细-详情  |  billDetailId:账单明细id
  contractBillDetailQuery = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<ContractBillDetailVO>>('/contract/bill/detail/query').body(args)
  )

  //查询交易流水列表
  flowList = $api.action((args: TradeListQueryDTO) =>
    this.post<ApiResponse<TradeListAndStatisticVO>>('/trade/flow/list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // 账单-查询租约的账期
  contractBillInterval = $api.action((args: {contractId: string}) =>
    this.post<ApiResponse<ContractBillIntervalVO[]>>('/contract/bill/query-interval-by-contract-id').body(args)
  )

  // 添加入住人
  checkinPersonUpsert = $api.action((args: ContractPersonCheckinUpsetDTO) =>
    this.post<ApiResponse>('/contract/checkin-person/upsert').body(args)
  )

  // 新增/编辑入住人(批量)
  contractCheckinPersonUpsertBatch = $api.action((args: ContractPersonCheckinUpsetDTO[]) =>
    this.post<ApiResponse<string[]>>('/contract/checkin-person/upsert-batch').body(args)
  )

  // 查询入住人列表  |  object:{contractId:租约id}
  contractCheckinPersonList = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<ContractPersonCheckinVO[]>>('/contract/checkin-person/list').body(args)
  )

  //入住人信息
  checkinPersonInfo = $api.action((personId: string) =>
    this.post<ApiResponse<ContractPersonCheckinVO>>('/contract/checkin-person/info').body({personId})
  )

  //删除入住人
  checkinPersonDelete = $api.action((personId: string) =>
    this.post<ApiResponse>('/contract/checkin-person/delete').body({personId})
  )

  // 承租人本人入住
  insertLessee = $api.action((contractId: string, boo: string) =>
    this.post<ApiResponse>('/contract/checkin-person/insert-lessee').body({contractId, boo})
  )

  //承租人是否入住
  checkinPersonLessee = $api.action((contractId: string) =>
    this.post<ApiResponse>('/contract/checkin-person/lessee').body({contractId})
  )

  //承租人是否入住
  queryLesseeInfo = $api.action((mobile: string) =>
    this.post<ApiResponse>('/contract/query-lessee-info').body({mobile})
  )

  // 账单-新增
  contractBillInsert = $api.action((args: Partial<ContractBillInsertDTO>) =>
    this.post('/contract/bill/insert').body(args)
  )

  //查询账户明细列表
  walletFlowList = $api.action((args: Partial<TradeListQueryDTO>) =>
    this.post<ApiResponse<TradeListAndStatisticVO>>('/trade/wallet-flow/list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // 钱包-流水列表(通联)-根据当前所选团队的客户
  tradeWalletFlowListByTeam = $api.action((args: TradeListQueryDTO) =>
    this.post<ApiResponse<TradeListAndStatisticVO>>('/trade/wallet-flow/list-by-team').body(args)
  )

  //查询近7日账单流水
  billStatisticsDaily = $api.action(() => this.post<ApiResponse>('/trade/bill/statistics/daily'))

  //查询近6月账单流水
  billStatisticsMonthly = $api.action(() => this.post<ApiResponse>('/trade/bill/statistics/monthly'))

  //查询近7日流水统计
  flowStatisticsDaily = $api.action(() => this.post<ApiResponse>('/trade/flow/statistics/daily'))

  //查询近6月流水统计
  flowStatisticsMonthly = $api.action(() => this.post<ApiResponse>('/trade/flow/statistics/monthly'))

  // 首页-账单看板
  billHomepageStatistics = $api.action(() =>
    this.post<ApiResponse<BillHomepageStatistics>>('/contract/bill/homepage/statistics')
  )

  //web入住人列表
  checkinPersonWebList = $api.action((args: ContractCheckinPersonListDTO) =>
    this.post<ApiResponse<ContractPersonCheckinVO[]>>('/contract/checkin-person/web/list').body(args)
  )

  //账单-流水数据Excel
  queryDataExcel = $api.action((args: ContractBillListQueryDTO) =>
    this.post<ApiResponse>('/contract/bill/query-data-excel').body({
      pageNum: 1,
      pageSize: 10,
      pageable: false,
      ...args,
    })
  )

  //账单-流水数据Excel表格文件路径返回
  billExcelPath = $api.action((args: ContractBillListQueryDTO) =>
    this.post<ApiResponse>('/contract/bill/data-excel-path').body({
      pageNum: 1,
      pageSize: 10,
      pageable: false,
      ...args,
    })
  )

  //账单-批量选中流水数据Excel表格文件路径返回
  batchDataExcelPath = $api.action((ids: string[]) =>
    this.post<ApiResponse>('/contract/bill/batch/data-excel-path').body({ids})
  )

  //租约列表导出所有结果excel
  contractDataExcelPath = $api.action((args: Partial<ContractListQueryDTO>) =>
    this.post<ApiResponse>('/contract/data-excel-path').body({
      pageNum: 1,
      pageSize: 10,
      pageable: false,
      ...args,
    })
  )

  //租约列表批量导出excel表格文件路径返回
  contractBatchDataExcelPath = $api.action((ids: string[]) =>
    this.post<ApiResponse>('/contract/batch/data-excel-path').body({ids})
  )

  // 账单拆分
  billDivisionDivide = $api.action((billId: string, itemList: BillDevisionDivideItem[]) =>
    this.post<ApiResponse>('/contract/bill/division/divide').body({billId, itemList})
  )

  //房东web入住人Excel路径返回
  checkinPersonExcelPath = $api.action((args: ContractCheckinPersonListDTO) =>
    this.post<ApiResponse>('/contract/checkin-person/web/data-excel-path').body({
      pageNum: 1,
      pageSize: 10,
      pageable: false,
      ...args,
    })
  )

  //房东web-批量选中入住人excel路径返回
  checkinPersonBatchPath = $api.action((ids: string[]) =>
    this.post<ApiResponse>('/contract/checkin-person/web/batch-path').body({ids})
  )

  // 房源预定(返回租约id)
  contractReserveMake = $api.action((args: ContractReserveMakeParams) =>
    this.post<ApiResponse<string>>('/contract/reserve/make').body(args)
  )

  // 房源预定V2
  contractReserveMakeV2 = $api.action((args: ContractReserveMakeDTO) =>
    this.post<ApiResponse<string>>('/contract/reserve/make-v2').body(args)
  )

  // 预定转租约
  contractReserveConvert = $api.action((args: ContractReserveMakeParams) =>
    this.post<ApiResponse>('/contract/reserve/convert').body(args)
  )

  // 撤销预定
  contractReserveCancel = $api.action((args: ContractReserveCancelParams) =>
    this.post<ApiResponse>('/contract/reserve/cancel').body(args)
  )

  // 账单修改金额
  contractBillAlterAmt = $api.action((args: ContractBillAlterDTO) => this.post('/contract/bill/alter-amt').body(args))

  // 查询配置列表(查询全部)
  lessorConfigContractListAll = $api.action(() =>
    this.post<ApiResponse<LessorConfigVO>>('/lessor/config/contract/list-all')
  )

  // 保存账单科目设置
  lessorConfigContractSaveBillConfig = $api.action((args: LessorConfigSaveBillDTO[]) =>
    this.post('/lessor/config/contract/save-bill-config').body(args)
  )

  // 保存支付渠道设置
  lessorConfigContractSavePayConfig = $api.action((args: LessorConfigSavePayDTO[]) =>
    this.post('/lessor/config/contract/save-pay-config').body(args)
  )

  /**
   * 查询配置列表(只返回自己配置项)
   * @deprecated: use lessorModel.lessorConfigContractListSelected instead
   */
  lessorConfigContractListSelected = $api.action(() =>
    this.post<ApiResponse<LessorConfigVO>>('/lessor/config/contract/list-selected').throttle(1000 * 10, true)
  )

  // 业主租约-租约创建
  contractLessorGenerateContract = $api.action((args: ContractLessorGenerateDTO) =>
    this.post('/contract-lessor/generate-contract').body(args)
  )
  // 支付规则设置-查询在线支付费率(当前所选团队)
  lessorConfigOnlineRateInfo = $api.action(() =>
    this.post<ApiResponse<LessorOnlineRateVO>>('/lessor/config/online-rate/info')
  )

  // 支付规则设置-设置费率比例
  lessorConfigOnlineRateSetting = $api.action((args: {onlineRateLessor: number; onlineRateLessee: number}) =>
    this.post('/lessor/config/online-rate/setting').body(args)
  )

  // 业主租约-列表查询
  contractLessorList = $api.action((args: Partial<ContractLessorListQueryDTO>) =>
    this.post<ApiResponse<ContractLessorListQueryResultVO[]>>('/contract-lessor/list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // 业主租约-账单-列表查询
  contractLessorBillList = $api.action((args: Partial<ContractLessorBillListQueryDTO>) =>
    this.post<ApiResponse<ContractLessorBillListQueryResultVO[]>>('/contract-lessor/bill/list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // 业主租约-账单-数据统计
  contractLessorBillStatistics = $api.action((args: Partial<ContractLessorBillListQueryDTO>) =>
    this.post<ApiResponse<ContractLessorBillStatisticsVO>>('/contract-lessor/bill/statistics').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // 业主租约-租约详情  |  object:{contractId:业主租约id,needBillDetail:是否需要账单明细}
  contractLessorDetail = $api.action((args: {contractId: string; needBillDetail?: boolean}) =>
    this.post<ApiResponse<ContractLessorVO>>('/contract-lessor/detail').body(args)
  )

  // 业主租约-账单-详情  |  object:{billId:账单id}
  contractLessorBillDetail = $api.action((billId: string) =>
    this.post<ApiResponse<ContractLessorBillVO>>('/contract-lessor/bill/detail').body({billId})
  )

  // 业主租约-账单-作废  |  object:{billId:账单id}
  contractLessorBillCancel = $api.action((billId: string) => this.post('/contract-lessor/bill/cancel').body({billId}))

  // 业主租约-账单-付款
  contractLessorBillConfirm = $api.action((args: ContractBillConfirmDTO) =>
    this.post('/contract-lessor/bill/confirm').body(args)
  )

  // 业主租约-账单-更新备注
  contractLessorBillUpdateRemark = $api.action((args: Partial<ContractBillUpdateRemarkDTO>) =>
    this.post('/contract-lessor/bill/update-remark').body(args)
  )

  // 业主租约-作废  |  object:{contractId:业主租约id}
  contractLessorCancel = $api.action((contractId: string) => this.post('/contract-lessor/cancel').body({contractId}))

  // 物业交割配置-保存配置
  contractEquipmentConfigSave = $api.action((args: ContractEquipmentConfigDTO) =>
    this.post('/contract/equipment/config/save').body(args)
  )

  // 查询物业交割配置-列表  |  object:{rentType:租房类型（1:整租 2:合租）}
  contractEquipmentConfigList = $api.action((args: Partial<ContractEquipmentConfigListDTO>) =>
    this.post<ApiResponse<ContractEquipmentConfigVO[]>>('/contract/equipment/config/list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // 查询物业交割配置-详情  |  object:{templateId:模板Id}
  contractEquipmentConfigInfo = $api.action((templateId: string) =>
    this.post<ApiResponse<ContractEquipmentConfigVO>>('/contract/equipment/config/info').body({templateId})
  )

  // 删除物业交割模板 |  object:{templateId:模板Id}
  contractEquipmentConfigDelete = $api.action((templateId: string) =>
    this.post('/contract/equipment/config/delete').body({templateId})
  )
  // 租约-租客登记-保存租约物业交割
  contractEquipmentAdd = $api.action((args: ContractEquipmentDTO) => this.post('/contract/equipment/add').body(args))

  // 获取物业交割默认模板
  contractTemplate = $api.action((rentType?: string) =>
    this.get<ApiResponse<ContractEquipmentDTO>>(
      rentType === '2'
        ? '/contractequipment/joint_template_default.json'
        : '/contractequipment/whole_template_default.json'
    )
  )

  // 租约-租客登记-查询租约物业交割
  contractEquipmentInfo = $api.action((args: {contractId: string}) =>
    this.post<ApiResponse<ContractEquipmentVO>>('/contract/equipment/info').body(args)
  )

  // 租约-新增黑名单
  contractBlacklistAdd = $api.action((args: Partial<ContractBlacklistDTO>) =>
    this.post('/contract/blacklist/add').body(args)
  )

  // 租约-删除黑名单
  contractBlacklistDelete = $api.action((blacklistId: string) =>
    this.post('/contract/blacklist/delete').body({blacklistId})
  )

  // 租约-查询黑名单列表  |  object:{rentType:租房类型（1:整租 2:合租 3:独栋）}
  contractBlacklistList = $api.action((args: Partial<ContractBlacklistQueryDTO>) =>
    this.post<ApiResponse<ContractBlcaklistVO[]>>('/contract/blacklist/list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )
  // 业主租约-账单-新增
  contractLessorBillInsert = $api.action((args: Partial<ContractBillInsertDTO>) =>
    this.post('/contract-lessor/bill/insert').body(args)
  )
  // 业主租约-账单明细-详情  |  billDetailId:账单明细id
  contractLessorBillDetailQuery = $api.action((args: {billDetailId: string}) =>
    this.post<ApiResponse<ContractLessorBillDetailVO>>('/contract-lessor/bill/detail/query').body(args)
  )

  // 营业报表-账单报表count
  contractBillOperationCount = $api.action((args: ContractBillOperationListQueryDTO) =>
    this.post<ApiResponse<null>>('/contract/bill/operation/count').body(args)
  )

  // 营业报表-账单报表
  contractBillOperationList = $api.action((args: ContractBillOperationListQueryDTO) =>
    this.post<ApiResponse<ContractBillOperationListVO[]>>('/contract/bill/operation/list').body({
      pageable: true,
      pageSize: 10,
      pageNum: 1,
      ...args,
    })
  )

  // 营业报表-账单报表sum
  contractBillOperationSum = $api.action((args: ContractBillOperationListQueryDTO) =>
    this.post<ApiResponse<ContractBillOperationListVO>>('/contract/bill/operation/sum').body(args)
  )

  // 租约-修改租约备注  |  object:{contractId:租约id,contractRemark:租约备注}
  contractUpdateContractRemark = $api.action((args: {contractId: string; contractRemark: string}) =>
    this.post('/contract/update-contract-remark').body(args)
  )

  // 租约-查询租约是否可以作废或修改  |  object:{contractId:租约id}
  contractQueryCancelStatus = $api.action((args: {contractId: string}) =>
    this.post<ApiResponse>('/contract/query-cancel-status').body(args)
  )

  // 租约-租约修改(先作废后新建)
  contractGenerateContractAlter = $api.action((args: ContractGenerateDTO) =>
    this.post<ApiResponse<string>>('/contract/generate-contract-alter').body(args)
  )

  // 换房-结转对比
  contractPropertyChangeCalculate = $api.action((args: ContractPropertyChangeDTO) =>
    this.post<ApiResponse<ContractPropertyChangeCalculateVO>>('/contract/property-change/calculate').body(args)
  )

  // 换房-确认换房
  contractPropertyChangeConfirm = $api.action((args: ContractPropertyChangeDTO) =>
    this.post<ApiResponse<string>>('/contract/property-change/confirm').body(args)
  )

  // 删除模板状态  |  object:{templateId:模板id}
  contractTemplateDelete = $api.action((args: {templateId: string}) =>
    this.post<ApiResponse<Record<string, any>>>('/contract/template/delete').body(args)
  )

  // 模板详情  |  object:{templateId:模板id}
  contractTemplateDetail = $api.action((args: {templateId: string}) =>
    this.post<ApiResponse<ContractTemplateVO>>('/contract/template/detail').body(args)
  )

  // 新增模板
  contractTemplateInsert = $api.action((args: ContractTemplateSaveDTO) =>
    this.post<ApiResponse<string>>('/contract/template/insert').body(args)
  )

  // 模板列表
  contractTemplateList = $api.action((args: Partial<ContractTemplateListQueryDTO>) =>
    this.post<ApiResponse<ContractTemplateVO[]>>('/contract/template/list').body({
      pageable: true,
      pageSize: 10,
      pageNum: 1,
      ...args,
    })
  )

  // 编辑模板
  contractTemplateUpdate = $api.action((args: ContractTemplateSaveDTO) =>
    this.post<ApiResponse<string>>('/contract/template/update').body(args)
  )

  // 更新模板状态(启用/禁用)  |  object:{templateId:模板id,templateStatus:模板状态}
  contractTemplateUpdateStatus = $api.action((args: {templateId: string; templateStatus: '0' | '1'}) =>
    this.post<ApiResponse<Record<string, any>>>('/contract/template/update-status').body(args)
  )

  // 租约-再次结账
  contractCheckOutAgain = $api.action((args: ContractCheckOutAgainDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/contract/check-out-again').body(args)
  )

  // 租约-修改租约的佣金金额  |  object:{contractId:租约id,brokerage:佣金费用}
  contractUpdateBrokerage = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<string>>('/contract/update-brokerage').body(args)
  )

  // 租约-再次结账-预操作(确认中)  |  object:{contractId:租约id}
  contractCheckOutAgainPre = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/contract/check-out-again-pre').body(args)
  )

  // 租约-修改承租人
  contractChangeLessee = $api.action((args: ContractChangeLesseeDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/contract/change-lessee').body(args)
  )

  // 租约-确认佣金支付  |  object:{contractId:租约id}
  contractConfirmBrokerage = $api.action((args: {contractId: string}) =>
    this.post<ApiResponse<string>>('/contract/confirm-brokerage').body(args)
  )

  // 租约操作审核查询(是否存在审核,审核是否通过)
  contractAuditGetAuditOrPass = $api.action((args: Partial<ContractAuditRecordQueryDTO>) =>
    this.post<ApiResponse<ContractAuditVO>>('/contract/audit/get-audit-or-pass').body(args)
  )

  // 租约操作审核提交
  contractAuditSubmit = $api.action((args: ContractAuditSubmitDTO) =>
    this.post<ApiResponse<string>>('/contract/audit/submit').body(args)
  )

  // 账务列表
  contractAccountList = $api.action((args: ContractAccountQueryDTO) =>
    this.post<ApiResponse<ContractAccountVO[]>>('/contract/account/list').body(args)
  )

  // 根据月份汇总`确认收款`(只传contractId)
  contractAccountGroupConfirmIncomeByMonth = $api.action((args: ContractAccountQueryDTO) =>
    this.post<ApiResponse<PairModel[]>>('/contract/account/group-confirm-income-by-month').body(args)
  )

  // 租约操作日志列表  |  object:{contractId:租约id}
  contractLogList = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<ContractLogVO[]>>('/contract/log/list').body(args)
  )

  // 是否需要申请进行`优惠审批`(非特定公寓或者无对应角色,不需要)  |  object:{condoId:公寓id}
  contractAuditNeedDiscountAudit = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<boolean>>('/contract/audit/need-discount-audit').body(args)
  )

  // 编辑预定
  contractReserveEdit = $api.action((args: ContractReserveEditDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/contract/reserve/edit').body(args)
  )

  // 新增续订锁房
  contractRenewInsert = $api.action((args: ContractRenewInsertDTO) =>
    this.post<ApiResponse<string>>('/contract/renew/insert').body(args)
  )

  // 续订锁房详情  |  object:{renewId:续订锁房记录id}
  contractRenewDetail = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<ContractRenewVO>>('/contract/renew/detail').body(args)
  )

  // 取消续订锁房  |  object:{renewId:续订锁房记录id}
  contractRenewCancel = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<string>>('/contract/renew/cancel').body(args)
  )
}

export const contractModel = new Contract()
