import {Effects, Model} from '@redux-model/web'
import {userModel} from '@/store/models/User'

export interface TempModel {
  communityInfo: Partial<ContractVO>
  condoPropertyInfo: Partial<CondoInsertDTO>
  checkout: {[key: string]: any}
  balance: Balance
  bankRecords: BankCardBindRecords[]

  form: any
}

class Temp extends Model<TempModel> {
  protected initReducer(): TempModel {
    return {
      communityInfo: {}, // 租约详情
      condoPropertyInfo: {},
      checkout: {}, // 结账

      balance: {} as Balance, // 余额
      bankRecords: [],

      form: {},
    }
  }

  protected effects(): Effects<TempModel> {
    return [
      userModel.payBalance.onSuccess((state, {response: {data}}) => {
        state.balance = Object.keys(data).reduce((result, key) => ({...result, [key]: data[key] / 100}), {} as Balance)
      }),
      userModel.bankCardBindRecords.onSuccess((state, {response: {data}}) => {
        state.bankRecords = data
      }),
    ]
  }

  setForm = this.action((state, payload) => {
    if (typeof payload === 'function') state.form = payload(state.form)
    else state.form = payload
  })
  updateForm = this.action((state, payload) => {
    if (typeof payload === 'function') state.form = {...state.form, ...payload(state.form)}
    else state.form = {...state.form, ...payload}
  })

  setCommunityInfo = this.action((state, communityInfo) => {
    state.communityInfo = communityInfo
  })

  // queryCommunityInfo = $api.action((contractId: string) =>
  //   this.post<ApiResponse<QueryContract>>('/contract/query-contract')
  //     .body({
  //       contractId,
  //     })
  //     .onSuccess((state, {response: {data}}) => {
  //       state.communityInfo = data
  //     })
  // )

  setCheckout = this.action((state, payload) => {
    state.checkout = payload
  })

  updateCheckout = this.action((state, payload) => {
    state.checkout = {...state.checkout, ...payload}
  })
}

export const tempModel = new Temp()
